import React, { useState, useEffect, /*useRef*/ } from 'react'
import HyperConsole from '../../hyper_console/hyper-console'
import SEPMap from '../SEPMap'
import './SepMapRoute.css'
import Navbar from '../component_navbar/Navbar'
import { Translation } from 'react-i18next'
import HyperMenu from '../../hyper_menu/HyperMenu'
import qs from 'qs'
import { UserUtil } from '../../user_management/util/user-util'
// import { UserUtil } from '../../user_management/util/user-util'
// import { TokenUtil } from '../../user_management/util/token-util'
// import { CookieUtil } from '../../user_management/cookies/cookie-util'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

export default function SepMapRoute (props) {
  const hookName = 'SepMapRoute'
  // let env = props.reduxState.env
  // const jwt = props.reduxState.user.jwt
  const [menu, setMenu] = useState(null)
  const getUserInfo = async () => {
    hycon.debug(`${hookName} getUserInfo`, { props })
    // check if the user is a "public visitor"
    const getPublicAccessToken = () => {
      try {
        if (
          props.location && props.location.search
        ) {
          let search = props.location.search
          search = search.substr(1, search.length)
          const parsed = qs.parse(search)
          const jwt = parsed.jwt
          return jwt
        } else {
          hycon.debug(`${hookName} getPublicAccessToken - no props.location and props.location.search`, { props })
          return null
        }
      } catch (e) {
        hycon.error(`${hookName} getPublicAccessToken`, { props, e })
        return null
      }
    }
    let publicAccessToken = getPublicAccessToken()
    if (publicAccessToken) {
      hycon.debug(`${hookName} getPublicTokenInfo - clearing user data in cookies and redux`, { props })

      await UserUtil.getPublicTokenInfo(props, publicAccessToken).then(async (data) => {
        hycon.debug(`${hookName} getPublicTokenInfo - got clientInfo out of public token`, { data })
        props.updateSEPContext({
          SEPContext: {
            ...props.SEPContext,
            user: {
              ...props.SEPContext.user,
              publicJWT: publicAccessToken,
              isUrlParsed: true,
              JWTInfo: data
            }
          }
        })
      })
    } else {
      hycon.debug(`${hookName} no public jwt token`, { props })
      props.updateSEPContext({
        SEPContext: {
          ...props.SEPContext,
          user: {
            ...props.SEPContext.user,
            publicJWT: null,
            isUrlParsed: true,
            JWTInfo: null,
          }
        }
      })
    }
  }

  useEffect(() => {
    // hycon.debug(`${hookName} useEffect`, { props })
    getUserInfo()
    return () => {
      // cleanup
      // hycon.debug(`${hookName} useEffect - cleanup`, { props })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Translation ns={['menu']}>
      {(t) => {
        return (
          <div className="Map">
            <HyperMenu
              {...props}
              ref={(c) => {
                setMenu(c)
              }}
              content={
                (
                  <div className="SepMapRoute">
                    <Navbar
                      {...props}
                      onMenuToggle={() => {
                        console.log("debug:onMenuToggle")
                        menu.setStateProperty('isOpen', !menu.getState().isOpen);
                      }}
                      isSearchDisplayed={true}
                    />
                    <SEPMap
                      {...props}
                    />
                  </div>
                )
              }
            />
          </div>
        )
      }}
    </Translation>
  )
}