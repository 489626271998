import React, { useState, useEffect } from 'react'
import HyperConsole from '../../hyper_console/hyper-console'
import './Confirmation.css'
import HyperMenu from '../../hyper_menu/HyperMenu'
import Navbar from '../../../components/sep_map/component_navbar/Navbar'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import qs from 'qs'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
const LOCIZE_PANEL_NS = 'favorites'
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

function Confirmation (props) {
  const hookName = 'Confirmation'
  const [menu, setMenu] = useState(null)
  const { t } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false })
  const [confirmResponse, setConfirmResponse] = useState(null)

  const performRequest = async () => {
    let search = props.location.search
    search = search.substr(1, search.length)
    const parsed = qs.parse(search)
    const jwt = parsed.jwt
    const uri = parsed.uri

    let endpoint = `${uri}/?jwt=${jwt}`
    let data = {}
    return await axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      hycon.debug(`${hookName} confirmNotification - response`, { response })
      return response
    })
    .catch((error) => {
      hycon.error(`${hookName} confirmNotification`, { error })
      throw error
    })
  }
  const confirmNotification = async () => {
    await performRequest()
    .then((res) => {
      setConfirmResponse(res)
    })
    .catch((error) => {
      setConfirmResponse(error.response)
    })
  }

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - constructor`, {})
    let search = props.location.search
    if (search && !confirmResponse) {
      confirmNotification()
    }
    //eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [props.location])

  return (
    <div className="favorites">
      <HyperMenu
        {...props}
        ref={(c) => {
          setMenu(c)
        }}
        content={(
          <div className={`menu-content-wrapper`}>
            <Navbar
              {...props}
              onMenuToggle={() => {
                menu.setStateProperty('isOpen', !menu.getState().isOpen)
              }}
              isSearchDisplayed={false}
            />
            {(() => {
              if (!confirmResponse) {
                return (
                  <h3 className={'Confirmation'}>
                    {t('favorites:email-notification-confirmation-loading')}
                  </h3>
                )
              } else if (confirmResponse && confirmResponse.status === 200) {
                return (
                  <h3 className={'Confirmation'}>
                    {t('favorites:email-notification-confirmation-success')}
                  </h3>
                )
              } else {
                return (
                  <h3 className={'Confirmation'}>
                    {t('favorites:email-notification-confirmation-error')}
                  </h3>
                )
              }
            })()}
          </div>
        )}
      />
    </div>
  )
}

export default Confirmation

