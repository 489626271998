import { CookieUtil } from '../cookies/cookie-util'
import HyperConsole from '../../hyper_console/hyper-console'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole

}class TokenUtilClass {
  constructor () {
    this.constants = {
      TOKEN_UPDATE_ERROR: 'Could not update the authentication token'
    }
  }

  updateTokenInReduxAndCookie (response, ctx) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} updateTokenInReduxAndCookie`, { response, ctx })
    try {
      return this.updateTokenByResponseInRedux(response, ctx).then((response) => {
        return this.updateTokenInCookie(response)
      })
    } catch (e) {
      hycon.debug(`${thisRef.constructor.name} updateTokenInReduxAndCookie - error`, e)
      throw e
    }
  }

  updateTokenInCookie (response) {
    let thisRef = this
    let jwt = response.headers.jwt
    if (!jwt) {
      hycon.info(`${thisRef.constructor.name} updateTokenInCookie - no jwt in response headers found. won't touch cookie`, { jwt, response })
    } else {
      hycon.info(`${thisRef.constructor.name} updateTokenInCookie - jwt in response headers found. will override the cookie`, { jwt, response })
      let oldUserCookie = null
      try {
        oldUserCookie = JSON.parse(CookieUtil.getCookie(CookieUtil.constants.names.USER))
        hycon.debug(`${thisRef.constructor.name} updateTokenInCookie - parse oldUserCookie - ok`, { oldUserCookie })
      } catch (e) {
        hycon.debug(`${thisRef.constructor.name} updateTokenInCookie - no valid user cookie found`, {})
        oldUserCookie = {}
      }
      try {
        let newUserCookie = Object.assign({}, oldUserCookie)
        newUserCookie.jwt = jwt
        hycon.info(`${thisRef.constructor.name} updateTokenInCookie - updating the user cookie`, { oldUserCookie, newUserCookie })
        CookieUtil.setCookie(CookieUtil.constants.names.USER, JSON.stringify(newUserCookie))
      } catch (e) {
        hycon.error(`${thisRef.constructor.name} updateTokenInCookie - error`, e)
        throw e
      }
    }
  }

  updateTokenByResponseInRedux (response, ctx) {
    let thisRef = this
    try {
      return new Promise((res, rej) => {
        hycon.debug(`${thisRef.constructor.name} updateTokenByResponseInRedux`, { response })
        let jwt = response.headers.jwt
        if (jwt) {
          hycon.debug(`${thisRef.constructor.name} updateTokenByResponseInRedux - jwt found in response headers`, { jwt })
          let previousUser = ctx.props.reduxState.user
          let newUser = Object.assign(previousUser, { jwt })
          ctx.props.reduxDispatch('updateUser', newUser)
          res(response)
        } else {
          hycon.debug(`${thisRef.constructor.name} updateTokenByResponseInRedux - no jwt found in response headers`, { jwt })
          res(response)
        }
      })
    } catch (e) {
      hycon.error(`${thisRef.constructor.name} updateTokenByResponseInReduxs`, {})
      throw new Error(thisRef.constants.TOKEN_UPDATE_ERROR)
    }
  }

  updateTokenInRedux (jwt, ctx) {
    let thisRef = this
    try {
      return new Promise((res, rej) => {
        hycon.debug(`${thisRef.constructor.name} updateTokenInRedux`, jwt)
        let previousUser = ctx.props.reduxState.user
        let newUser = Object.assign({}, previousUser, { jwt })
        ctx.props.reduxDispatch('updateUser', newUser)
        res(newUser)
      })
    } catch (e) {
      hycon.error(`${thisRef.constructor.name} updateToken`, {})
      throw new Error(thisRef.constants.TOKEN_UPDATE_ERROR)
    }
  }

  updateUserInRedux (newUser, ctx) {
    let thisRef = this
    try {
      return new Promise((res, rej) => {
        hycon.debug(`${thisRef.constructor.name} updateUserInRedux`, newUser)
        let previousToken = null;
        try {
          previousToken = ctx.props.reduxState.user.jwt
        }catch (e) {
          hycon.warn(`${thisRef.constructor.name} updateUserInRedux - no previous token`, ctx)
        }
        let updatedUser = {
          user: {
            user: newUser,
            jwt: previousToken
          }
        };
        ctx.props.reduxDispatch('updateUser', updatedUser)
        res(updatedUser)
      })
    } catch (e) {
      hycon.error(`${thisRef.constructor.name} updateUserInRedux`, {})
      throw new Error("could not update the user in redux")
    }
  }

  test () {
    let thisRef = this
    hycon.info(`${thisRef.constructor.name} test`, {})
  }
}

export const TokenUtil = new TokenUtilClass()
