import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

import HyperConsole from '../../../../hyper_console/hyper-console'

import {
  Button,
  Icon,
  Loader,
  Dimmer
} from 'semantic-ui-react'

import {Button as MUIButton} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close'

import './BuildingInfo.css'

import axios from 'axios/index'
import { GoogleAnalytics } from '../../../../google_analytics/GoogleAnalytics'
import { withTranslation } from 'react-i18next'
// import env from '../../../../../env/env'
import * as L from 'leaflet'
import ApartmentViewer from "./ApartmentViewer";
import {WixUtil} from "../../../../../util/wix-util";

const RENDER_OVERVIEW = 'RENDER_OVERVIEW'
const RENDER_DETAIL = 'RENDER_DETAIL'
const RENDER_SETTINGS = 'RENDER_SETTINGS'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

const trans = (ctx, key) => {
  return ctx.props.i18n.t(key)
}

function HookRenovationPressure (props) {
  const hookName = 'HookRenovationPressure'
  useEffect(() => {
    // hycon.debug(`${hookName} useEffect - constructor`, { props })
  }, [])
  let map = (pressureValue) => {
    // 0-0.04==Niedrig, 0.04-0.08==Mässig, 0.08-0.15==Hoch, >0.15==Sehr hoch
    let pressure = parseFloat(pressureValue)
    if (pressure >= 0 && pressure < 0.2) {
      return props.i18n.t('panel_building_info:label-renovation-pressure-low')
    } else if (pressure >= 0.2 && pressure < 0.5) {
      return props.i18n.t('panel_building_info:label-renovation-pressure-mid')
    } else if (pressure >= 0.5 && pressure < 1) {
      return props.i18n.t('panel_building_info:label-renovation-pressure-high')
    } else if (pressure >= 1) {
      return props.i18n.t('panel_building_info:label-renovation-pressure-veryhigh')
    } else {
      return props.i18n.t('panel_building_info:label-renovation-pressure-not-available')
    }
  }

  const getPressureComponent = () => {
    try {
      if (!props.gwr) {
        hycon.debug(`${hookName} getPressureComponent - debug - loading`, { props })
        return <span>{props.i18n.t('panel_building_info:label-renovation-pressure-loading')}</span>
      } else {
        hycon.debug(`${hookName} getPressureComponent - debug`, { props })
        return <span>{map(props.gwr.housingEgids.renovationsdruck)}</span>
      }
    } catch (e) {
      hycon.warn(`${hookName} getPressureComponent - warn`, e)
      return <span>{props.i18n.t('panel_building_info:label-renovation-pressure-not-available')}</span>
    }
  }
  return (
    <div className={'renovation-pressure'}>
      {getPressureComponent()}
    </div>
  )
}

class BuildingInfo extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isMounted: false,
      isLoading: false,
      rendering: RENDER_OVERVIEW,
      gwr: null,
      addressMarkerGroup: null,
      addressPortalComponents: [],
      isTouching: false,
      touchStartEvent: null,
      touchEndEvent: null,
      touches: [],
      minergie: null,
      isMapListenersInitialized: false
    }
    this.setIsLoading = this.setIsLoading.bind(this)
    hycon.debug(`${this.constructor.name} constructor`, { props: this.props })
  }

  static getDerivedStateFromProps (props, state) {
    hycon.debug(`Card getDerivedStateFromProps`, { props, state })
    if (!state) {
      hycon.debug(`Card getDerivedStateFromProps - only props have been updated`, { props, state })
    }
    return state
  }

  componentDidMount () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} componentDidMount`, { props: this.props })
    thisRef.setStateProperty('isMounted', true)
  }

  shouldComponentUpdate (nextProps, nextState) {
    // let thisRef = this;
    hycon.debug(`${this.constructor.name} shouldComponentUpdate`, { nextProps, props: this.props, nextState })
    return true
  }

  getOverview () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} getOverview`, { props: this.props })
    let selectedBuildings = thisRef.props.reduxState.selectedBuildings
    let nrOfBuildings = selectedBuildings.length
    if (nrOfBuildings > 0) {
      return (
        <div className={`overview`}>
          {
            thisRef.props.content.overview.iconComponent ? (
              <div>
                {thisRef.props.content.overview.iconComponent}
              </div>
            ) : null
          }
          <div>
            <h1>{nrOfBuildings}</h1>
            <h3>{thisRef.props.i18n.t('panel_building_info:label-number')}</h3>
          </div>
        </div>
      )
    } else {
      return (
        <div className={`overview`}>
          {
            thisRef.props.content.overview.iconComponent ? (
              <div>
                {thisRef.props.content.overview.iconComponent}
              </div>
            ) : null
          }
          <div>
            <h1>{thisRef.props.content.overview.title}</h1>
          </div>
        </div>
      )
    }
  }

  updateZIndex (index) {
    let thisRef = this

    hycon.debug(`${this.constructor.name} updateZIndex`, index)
    let addressPointZIndexArray = thisRef.state.addressPointZIndexArray

    addressPointZIndexArray = addressPointZIndexArray.map(() => {
      return thisRef.state.addressPointZIndexBase
    })

    addressPointZIndexArray[index] = thisRef.state.addressPointZIndexBase + 1

    hycon.debug(`${this.constructor.name} updateZIndex`, addressPointZIndexArray)
    return thisRef.setStateProperty(
      'addressPointZIndexArray',
      addressPointZIndexArray
    )
  }

  getDetailView () {
    hycon.debug(`${this.constructor.name} getDetailView`)
    let thisRef = this
    const selectedBuildings = thisRef.props.reduxState.selectedBuildings
    const gwr = thisRef.state.gwr
    // const jwt = thisRef.props.reduxState.user.jwt;

    let buildingIds = []
    selectedBuildings.forEach((selectedBuilding) => {
      let buildings = selectedBuilding.buildings
      buildings.forEach((building) => {
        buildingIds.push(building.id)
      })
    })

    let components = []

    let getAddresses = (gwr, gwrIndex, titleIndex) => {
      hycon.debug(`${this.constructor.name} rendering getAddresses`, { gwr, gwrIndex, titleIndex })
      let addresses = []
      try {
        gwr.housingEgids.housingEdids.forEach((housingEdid, edidIndex) => {
          let strasse = housingEdid.strasse
          let eingangnummer = housingEdid.eingangnummer
          let ort = housingEdid.ort
          let plz = housingEdid.plz
          addresses.push(
            <div
              className={'address-focus-row'}
              key={edidIndex}
              onClick={() => {
                hycon.debug(`${this.constructor.name} clicked address focus`, { housingEdid, edidIndex })
              }}
            >
              <div>{`${strasse} ${eingangnummer}, ${plz} ${ort}`}</div>
              <div id={`focus-button-${gwr.egid}-${edidIndex}`}/>
              {/*<div>{`focus-button-${titleIndex}-${gwrIndex}-${edidIndex}`}</div>*/}
            </div>
          )
        })
        return addresses
      } catch (e) {
        hycon.warn(e)
        return null
      }
    }

    const getHousingEdidsComponent = (housingEdids, egid) => {
      try {
        let comps = []
        housingEdids.forEach((edid, edidIndex) => {
          comps.push(
            <div key={edidIndex} className={'edids'}>
              <h4>{trans(thisRef, 'panel_building_info:label-edid')} {/*{edid.id} */} {edid.edid}</h4>
              <div className={'edids-item'}>
                <span>
                  <span
                    className={'bold'}>{trans(thisRef, 'panel_building_info:label-egaid')}
                  </span>
                  &nbsp;{edid.egaid}
                </span>
              </div>
              <div className={'edids-item'}>
                <span>
                  <span
                    className={'bold'}>{trans(thisRef, 'panel_building_info:label-adresse')}
                  </span>&nbsp;{edid.strasse} {edid.eingangnummer}, {edid.plz} {edid.ort}
                </span>
              </div>
              <div className={'edids-item'}>
                <ApartmentViewer ewids={edid?.housingEwids || []}/>
              </div>
            </div>
          )
        })
        return (<div className={'edids-container'}>{comps}</div>)
      } catch (e) {
        hycon.warn(e)
      }
    }

    let getMinergieComponent = (id, gwr) => {
      let thisRef = this;
      let minergieArray = this.state.minergie[id]
      hycon.debug(`${this.constructor.name} getMinergieComponent`, { id, minergieArray })
      let comps = []
      /*
      buildingIdSep: 13619574
      buildingInfo: "Schule" --> Bezeichnung des Standards
      canton: "FR"
      certificate: "FR-859" ---> Baustandard
      ebf: 387 ---> Energiebezugsfläche [m2]
      feature: null
      featureId: "129314"
      id: 20579
      lastUpdated: "2018-11-20T16:58:15.8903370+00:00"
      links: (2) [{…}, {…}]
      place: "Fétigny"
      postCode: "1532"
      rowVersionString: ""
      standard: "Minergie" ---> Minergie-Standard, Baustandard
      street: "Pré-de-Ville"
      streetNumber: "12"
      swisstopoUri: "https://api3.geo.admin.ch/rest/services/api/MapServer/ch.bfe.minergiegebaeude/129314"
       */
      let validAttributes = [
        'buildingInfo',
        'certificate',
        'ebf',
        'standard',
      ]
      let attrMap = {
        buildingInfo: thisRef.props.i18n.t('panel_building_info:minergie-buildingInfo'),
        ebf: thisRef.props.i18n.t('panel_building_info:minergie-ebf'),
        standard: thisRef.props.i18n.t('panel_building_info:minergie-standard'),
      }
      minergieArray.forEach((minergie, index) => {
        let minergieProps = []
        let minergieByProp = {}
        hycon.log('getMinergieComponent', minergie)
        let propIndex = 0
        for (let p in minergie) {
          if (
            minergie.hasOwnProperty(p) &&
            validAttributes.includes(p)
          ) {
            propIndex++
            minergieProps.push(<div key={propIndex} className={'skinny'}><span
              className={'fat'}>{attrMap[p]}:&nbsp; </span>{minergie[p] || 'Nicht vorhanden.'}</div>)
            minergieByProp[`${p}`] = <div key={propIndex} className={'skinny'}><span
              className={'fat'}>{attrMap[p]}:&nbsp; </span>{minergie[p] || 'Nicht vorhanden.'}</div>
          }
        }
        let customSortArray = []
        customSortArray.push(minergieByProp['standard'])
        customSortArray.push(minergieByProp['buildingInfo'])
        customSortArray.push(minergieByProp['ebf'])
        if (
          minergie['addressMatching'] === 1 &&
          minergie['egid'] &&
          minergie['egid'] === gwr['egid']
        ) {
          comps.push(
            <div key={index} className={'minergie'}>
              <h4>
                {thisRef.props.i18n.t('panel_building_info:minergie-standard')}
              </h4>
              {customSortArray}
            </div>
          )
        }
      })
      return comps
    }

    const getGWRByBuildingId = (id, buildingIndex, titleIndex) => {
      // let index = buildingIds.indexOf(id);
      try {
        if (!gwr) return
        let filteredGwr = gwr.filter((gwr) => {
          return gwr.buildingIdSep === id
        })

        let gwrComps = []
        let display = (input, expression = null) => {
          try {
            let string = input
            if (
              !string ||
              typeof string === 'undefined' ||
              (typeof string === 'undefined' && string.trim() === '')
            ) {
              return '-'
            } else {
              if(expression){
                return expression();
              } else {
                return string
              }
            }
          } catch (e) {
            hycon.warn(e)
            return 'ERROR'
          }
        }
        hycon.debug(`${this.constructor.name} rendering gwr filter`, { gwr, filteredGwr })
        filteredGwr.forEach((gwr, gwrIndex) => {
          // let i = gwrIndex + 1;
          gwrComps.push((
            <div key={gwrIndex + 1} className={'rowcontainer'}>
              <h2>{trans(thisRef, 'panel_building_info:label-head-egid')} {gwr['egid']}</h2>
              <div className={'row'}>
                <div>{trans(thisRef, 'panel_building_info:label-gebaeudekategorie')}&nbsp;</div>
                <div>{display(
                    gwr.housingEgids.gebKategorieShort,
                    () => trans(thisRef, `dynamic_panel_marketsense_v4:value-gebKategorieShort-${gwr.housingEgids.gebKategorieShort}`)
                )}</div>
              </div>
              <div className={'row'}>
                {gwr.housingEgids.gebKlasse ? (
                  <React.Fragment>
                    <div>{trans(thisRef, 'panel_usage:label-building-classes')}&nbsp;</div>
                    <div>{trans(thisRef, `panel_usage:label-building-classes-${gwr.housingEgids.gebKlasse}`)}</div>
                  </React.Fragment>
                ) : null}
              </div>
              <div className={''} style={{ display: 'block' }}>
                <div style={{ fontWeight: 'bold' }}>{trans(thisRef, 'panel_building_info:label-adressen')}&nbsp;</div>
                <div className={'addresses'}>
                  {getAddresses(gwr, gwrIndex, titleIndex)}
                </div>
              </div>
              <div className={'row'}>
                <div>{trans(thisRef, 'panel_building_info:label-gemeinde-nummer')}&nbsp;</div>
                <div>{display(gwr.housingEgids['gemNr'])}</div>
              </div>
              <div className={'row'}>
                <div>{trans(thisRef, 'panel_building_info:label-egid')}&nbsp;</div>
                <div>{display(gwr['egid'])}</div>
              </div>
              <div className={'row'}>
                <div>{trans(thisRef, 'panel_building_info:label-grundbruch-kreis')}&nbsp;</div>
                <div>{display(gwr.housingEgids['gbKreis'])}</div>
              </div>
              <div className={'row'}>
                <div>{trans(thisRef, 'panel_building_info:label-parzellen-nr')}&nbsp;</div>
                <div>{display(gwr.housingEgids['parzNr'])}</div>
              </div>
              <div className={'row'}>
                <div>{trans(thisRef, 'panel_building_info:label-egrid')}&nbsp;</div>
                <div>{display(gwr.housingEgids['egrid'])}</div>
              </div>
              <div className={'row'}>
                <div>{trans(thisRef, 'panel_building_info:label-oereb')}&nbsp;</div>
                {(()=>{
                  let link = null;
                  try{
                    link = gwr.housingEgids['oerebLink'];
                  }catch (e){
                    hycon.warn("can't get the oerebLink", {gwr, e});
                    link = null;
                  }
                  if(link && link.trim().length > 0){
                    return (
                      <div>
                        <a href={gwr.housingEgids['oerebLink']} target={"_blank"} rel={"noopener noreferrer"}>{trans(thisRef, 'panel_building_info:value-oereb')}</a>
                      </div>
                    )
                  } else {
                    return (
                        <div>{trans(thisRef, 'panel_building_info:label-oereb-not-available')}&nbsp;</div>
                    )
                  }
                })()}
              </div>
              <div className={'row'}>
                <div>{trans(thisRef, 'panel_building_info:label-amtliche-gebaeude-nr')}&nbsp;</div>
                <div>{display(gwr.housingEgids['amtGebnr'])}</div>
              </div>
              <div className={'row'}>
                <div>{trans(thisRef, 'panel_building_info:label-gebaeudestatus')}&nbsp;</div>
                <div>
                  {display(
                      gwr.housingEgids['gebStatus'],
                      () => trans(thisRef, `dynamic_panel_marketsense_v4:value-gebStatus-${gwr.housingEgids['gebStatus']}`)
                  )}
                </div>
              </div>
              <div className={'row'}>
                <div>{trans(thisRef, 'panel_building_info:label-baujahr')}&nbsp;</div>
                <div>{display(gwr.housingEgids['baujahr1'] || '-')} | {display(gwr.housingEgids['baujahr2'] || '-')}</div>
              </div>
              <div className={'row'}>
                <div>{trans(thisRef, 'panel_building_info:label-renovationsjahre')}&nbsp;</div>
                <div>{display(gwr.housingEgids['renovation1'] || '-')} | {display(gwr.housingEgids['renovation2'] || '-')}</div>
              </div>
              <div className={'row'}>
                <div>{trans(thisRef, 'panel_building_info:label-baugesuch')}&nbsp;</div>
                <div>{display(gwr.housingEgids['baugesuch'] || '-')}</div>
              </div>
              <div className={'row'}>
                <div>{trans(thisRef, 'panel_building_info:label-gebaeudevolumen')}&nbsp;</div>
                <div>
                  {(() => {
                    if (gwr.housingEgids['gebVol']) {
                      return Math.round(gwr.housingEgids['gebVol']).toLocaleString(thisRef.props.i18n.language)
                    } else {
                      return gwr.housingEgids['gebVol']
                    }
                  })()}
                </div>
              </div>
              <div className={'row'}>
                <div>{trans(thisRef, 'panel_building_info:label-gebaeudeflaeche')}&nbsp;</div>
                <div>
                  {(() => {
                    if (gwr.housingEgids['gebFlaeche']) {
                      return gwr.housingEgids['gebFlaeche'].toLocaleString(thisRef.props.i18n.language)
                    } else {
                      return gwr.housingEgids['gebFlaeche']
                    }
                  })()}
                </div>
              </div>
              <div className={'row'}>
                <div>{trans(thisRef, 'panel_building_info:label-anzahl-geschosse')}&nbsp;</div>
                <div>{display(gwr.housingEgids['anzahlGeschosse'])}</div>
              </div>
              <div className={'row'}>
                <div>{trans(thisRef, 'panel_building_info:label-parzellenflaeche')}&nbsp;</div>
                <div>{(()=>{
                  try {
                    return parseInt(gwr.housingEgids[`parzFlaeche`]).toLocaleString(thisRef.props.i18n.language)
                  }catch (e){
                    return "-"
                  }
                })()}</div>
              </div>
              <div className={'row'}>
                <div>{trans(thisRef, 'panel_building_info:label-renovation-pressure')}:&nbsp;</div>
                <HookRenovationPressure gwr={gwr} {...thisRef.props}/>
              </div>
              {(()=>{
                let comps = getMinergieComponent(id, gwr);
                if(comps.length > 0){
                  return (
                    <div className={'row'}>
                      <div className={'minergie-container'}>
                        {comps}
                      </div>
                    </div>
                  )
                }
              })()}
              {getHousingEdidsComponent(gwr.housingEgids.housingEdids, gwr['egid'])}
            </div>
          ))
        })
        if (gwrComps.length === 0) {
          return (
            <div style={{ textAlign: 'center' }}>
              {trans(thisRef, 'panel_building_info:label-no-data')}
            </div>
          )
        } else {
          return gwrComps
        }
      } catch (e) {
        hycon.warn(e)
      }
    }
    selectedBuildings.forEach((selectedBuilding, selectedBuildingIndex) => {
      let titleIndex = selectedBuildingIndex + 1
      let buildings = selectedBuilding.buildings
      buildings.forEach((building, buildingIndex) => {
        components.push((
          <div
            className={'rowcontainer-wrapper'}
            key={selectedBuildingIndex}
          >
            <h1>{trans(thisRef, 'panel_building_info:label-panel-name')} {titleIndex}</h1>
            {getGWRByBuildingId(building.id, buildingIndex, titleIndex)}
          </div>
        ))
      })
    })

    if (components.length > 0) {
      return <div className={`detail`}>{components}</div>
    } else {
      return <div className={`detail`}>
        <div className={'empty-selection'}>
          {trans(thisRef, 'panel_building_info:label-help-text')}
        </div>
      </div>
    }
  }

  onPanelNavigation (view) {
    let thisRef = this
    hycon.debug(`${this.constructor.name} onPanelNavigation`, view)
    thisRef.updateAddressMarkers()
  }

  setStateProperty (propertyName, propertyValue) {
    let thisRef = this
    return new Promise((res) => {
      thisRef.setState((p) => {
        const obj = {}
        obj[propertyName] = propertyValue
        return Object.assign({}, p, obj)
      }, () => {
        hycon.debug(`${this.constructor.name} setStateProperty - newState`, { newState: thisRef.state })
        if (propertyName === 'rendering') {
          thisRef.onPanelNavigation(propertyValue)
        }
        res()
      })
    })
  }

  setIsLoading (isLoading) {
    hycon.debug(`${this.constructor.name} setIsLoading`, { isLoading })
    let thisRef = this
    return thisRef.setStateProperty('isLoading', isLoading)
  }

  renderState () {
    let thisRef = this
    let rendering = thisRef.state.rendering
    let dashboardPortal = document.getElementById(`dashboard-portal`)
    if (!thisRef.state.isMounted) return null
    let lang = thisRef.props.i18n.language

    let getString = (i18nextKey) => {
      return thisRef.props.i18n.t(i18nextKey)
    }

    if (rendering === RENDER_OVERVIEW) {
      return (
        <div className={`Card BuildingInfo`}>
          <div className={'controls'}>
            <h4 className={'title'}>{thisRef.props.title}</h4>
            <MUIButton
              onClick={() => {
                let id = thisRef.props.id
                hycon.debug('Clicked card info ' + id)
                window.open(`${WixUtil.getLangBaseUrl(lang.toLowerCase())}/gebaeude`, '_blank');
              }}
            >
              <InfoIcon></InfoIcon>
            </MUIButton>
          </div>
          <div
            className={'body'}
            onClick={() => {
              let id = thisRef.props.id
              hycon.debug('Clicked card detail' + id)
              GoogleAnalytics.sendGenerigEvent('panel-detail-building', 'sep-panel-detail', 'Panel Interaction', true)
              thisRef.setStateProperty('rendering', RENDER_DETAIL)
              Promise.all([
                thisRef.props.setParentState('isPortalVisible', true),
                thisRef.props.setParentState('isTilesVisible', false)
              ])
              const event = new CustomEvent('kibana-event', { detail: {
                  type: "panel-detail",
                  eventInfo: {
                    info: "Building-Info panel has been opened."
                  }
                }
              });
              window.dispatchEvent(event);
            }}
          >
            {thisRef.getOverview()}
            {
              thisRef.state.isLoading ? (
                <Dimmer active>
                  <Loader size='massive'>Loading {thisRef.props.title}</Loader>
                </Dimmer>
              ) : null
            }
          </div>
        </div>
      )
    } else if (rendering === RENDER_DETAIL) {
      let detail = (
        <div className={`Card BuildingInfo`}>
          <div className={'controls'}>
            <h4 className={'title'}>{getString('dashboard:toolbar-details-label')} {thisRef.props.title}</h4>
            <MUIButton
              onClick={() => {
                let id = thisRef.props.id
                hycon.debug('Clicked card close ' + id)
                thisRef.setStateProperty('rendering', RENDER_OVERVIEW)
                Promise.all([
                  thisRef.props.setParentState('isPortalVisible', false),
                  thisRef.props.setParentState('isTilesVisible', true)
                ])
              }}
            >
              <CloseIcon/>
            </MUIButton>
          </div>
          <div
            className={'body'}
            onClick={() => {
              // let id = thisRef.props.id;
              // hycon.debug("Clicked card " + id);
            }}
          >
            {thisRef.getDetailView()}
            {
              thisRef.state.isLoading ? (
                <Dimmer active>
                  <Loader size='massive'>Loading {thisRef.props.title}</Loader>
                </Dimmer>
              ) : null
            }
          </div>
          {/*{thisRef.renderAddressPoints()}*/}
        </div>
      )
      return ReactDOM.createPortal(detail, dashboardPortal)
    } else if (rendering === RENDER_SETTINGS) {
      let settings = (
        <div className={`Card BuildingInfo`}>
          <div className={'controls'}>
            <h4 className={'title'}>{getString('dashboard:toolbar-settings-label')} {thisRef.props.title}</h4>
            <Button
              icon
              size={'large'}
              onClick={() => {
                let id = thisRef.props.id
                hycon.debug('Clicked card close ' + id)
                thisRef.setStateProperty('rendering', RENDER_OVERVIEW)
                thisRef.props.setParentState('isPortalVisible', false)
                thisRef.props.setParentState('isTilesVisible', true)
              }}
            >
              <Icon name='close'/>
            </Button>
          </div>
          <div
            className={'body'}
            onClick={() => {
              // let id = thisRef.props.id;
              // hycon.debug("Clicked card " + id);
            }}
          >
            <div className={'settings'}>
              <div>Dieses Panel bietet keine Einstellungen.</div>
            </div>
            {
              thisRef.state.isLoading ? (
                <Dimmer active>
                  <Loader size='massive'>Loading {thisRef.props.title}</Loader>
                </Dimmer>
              ) : null
            }
          </div>
        </div>
      )
      return ReactDOM.createPortal(settings, dashboardPortal)
    } else {
      throw new Error('unconsistent rendering state')
    }
  }

  render () {
    let thisRef = this
    return (
      <React.Fragment>
        {thisRef.renderState()}
        {thisRef.state.addressPortalComponents}
      </React.Fragment>
    )
  }

  getMinergie () {

    let thisRef = this
    const selectedBuildings = thisRef.props.reduxState.selectedBuildings
    const jwt = thisRef.props.reduxState.user.jwt

    let buildingIds = []
    selectedBuildings.forEach((selectedBuilding) => {
      let buildings = selectedBuilding.buildings
      buildings.forEach((building) => {
        buildingIds.push(building.id)
      })
    })

    let requests = []
    buildingIds.forEach((id) => {
      let endpoint = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/api/buildings-minergie?buildingidsep=[in]${id}`
      let req = axios({
        method: 'get',
        url: endpoint,
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        }
      })
      .then(function (response) {
        hycon.debug(`${thisRef.constructor.name} getMinergie`, response)
        response.data.buildingId = id
        return response
      })
      .catch((e) => {
        hycon.error(`${thisRef.constructor.name} getMinergie`, e)
        // UserUtil.authenticationRedirect(e.response, thisRef);
      })
      requests.push(req)
    })

    let getMinergieRequest = () => {
      return Promise.all(requests).then((values) => {
        let mergedData = {}
        values.forEach((val) => {
          mergedData[val.data.buildingId] = val.data
        })
        hycon.debug(`${thisRef.constructor.name} getMinergie mergedResponses`, {
          mergedData,
          singleResponses: values
        })
        return { data: mergedData }
      })
    }
    return getMinergieRequest()
  };

  initMapMoveListener () {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} initMapMoveListener`, thisRef)
    thisRef.props.reduxState.map.map.on('move zoom', thisRef.onMapMoveZoom, thisRef)
    hycon.debug(`${thisRef.constructor.name} initMapMoveListener - add listeners`, thisRef)
  }

  removeMapMoveListener () {
    let thisRef = this
    if (thisRef.props.reduxState.map.map) {
      hycon.debug(`${thisRef.constructor.name} removeMapMoveListener`, thisRef)
      thisRef.props.reduxState.map.map.off('move zoom', thisRef.onMapMoveZoom, thisRef)
      hycon.debug(`${thisRef.constructor.name} removeMapMoveListener - removed listeners`, thisRef)
    }
  }

  onMapMoveZoom () {
    let thisRef = this
    thisRef.updateAddressMarkers()
  }

  updateAddressMarkers () {
    let thisRef = this
    let rendering = thisRef.state.rendering
    let markers = []
    let addressComponents = []
    let gwrArray = thisRef.state.gwr || []
    hycon.debug(`${this.constructor.name} rendering gwrArray`, { gwr: thisRef.state.gwr, gwrArray })

    let newAddressMarkerGroup = L.layerGroup(markers)
    if (thisRef.state.addressMarkerGroup) {
      thisRef.props.reduxState.map.map.removeLayer(thisRef.state.addressMarkerGroup)
    }

    if (
      rendering === RENDER_DETAIL
      || rendering === RENDER_SETTINGS
    ) {
      gwrArray.forEach((gwrElement, gwrIndex) => {
        for (let edidElementIndex = 0; edidElementIndex < gwrElement.housingEgids.housingEdids.length; edidElementIndex++) {
          let edidElement = gwrElement.housingEgids.housingEdids[edidElementIndex]
          let lat = edidElement.lat
          let lng = edidElement.long
          let divIcon = L.divIcon({
            className: `custom-addresspoint-marker marker-portal-${gwrElement.egid}-${edidElementIndex}`,
            html: `<div id="leaflet-marker-portal-${gwrElement.egid}-${edidElementIndex}"></div>`,
            iconSize: [30, 42],
            iconAnchor: [15, 42]
          })

          let marker = L.marker([lat, lng], { icon: divIcon, riseOnHover: true })
          marker.on('click', (e) => {
            hycon.debug(`${this.constructor.name} custom marker click`, { e })
          })
          markers.push(marker)

          hycon.debug(`${this.constructor.name} rendering gwrArray - loop`, {
            gwrElement,
            gwrIndex,
            edidElement,
            edidElementIndex
          })
          addressComponents.push(
            <AddressComponent
              gwrIndex={gwrIndex}
              gwrElement={gwrElement}
              edidElementIndex={edidElementIndex}
              shouldDisplayPins={thisRef.props.reduxState.map.map.getZoom() >= 18 && thisRef.state.rendering === RENDER_DETAIL}
              marker={marker}
              portalNumber={gwrIndex}
              edid={edidElement}
            />
          )
        }
      })

      newAddressMarkerGroup = L.layerGroup(markers)
      thisRef.setStateProperty('addressMarkerGroup', newAddressMarkerGroup)
      newAddressMarkerGroup.addTo(thisRef.props.reduxState.map.map)

      let addressPortalComponents = []
      addressComponents.forEach((component, index) => {
        hycon.debug(`${this.constructor.name} rendering gwrArray - portal`, {
          addressComponents,
          egid: component.props.gwrElement.egid,
          edidElementIndex: component.props.edidElementIndex
        })
        let target = document.getElementById(`leaflet-marker-portal-${component.props.gwrElement.egid}-${component.props.edidElementIndex}`)
        if (target) {
          hycon.debug(`${this.constructor.name} creating pin portal`, { index, component, target })
          addressPortalComponents.push(ReactDOM.createPortal(component, target))
        }
      })
      thisRef.setStateProperty('addressPortalComponents', addressPortalComponents)
    }

    return newAddressMarkerGroup
  }

  getGWR () {
    let thisRef = this
    const selectedBuildings = thisRef.props.reduxState.selectedBuildings
    const jwt = thisRef.props.reduxState.user.jwt

    let buildingIds = []
    selectedBuildings.forEach((selectedBuilding) => {
      let buildings = selectedBuilding.buildings
      buildings.forEach((building) => {
        buildingIds.push(building.id)
      })
    })

    let requests = []
    buildingIds.forEach((id) => {
      let endpoint = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/api/gwrbuildings?buildingidsep=[in]${id}`
      let req = axios({
        method: 'get',
        url: endpoint,
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        }
      })
      .then(function (response) {
        hycon.debug(`${thisRef.constructor.name} getGWRRequest`, response)
        return response
      })
      .catch((e) => {
        hycon.error(`${thisRef.constructor.name} getGWRRequest`, e)
        // UserUtil.authenticationRedirect(e.response, thisRef);
      })
      requests.push(req)
    })

    let getGWRRequest = () => {
      // https://staging.services.swissenergyplanning.ch/api/gwrbuildings?buildingidsep=[in]22818514,22818515
      // selectedBuildings[""0""].buildings[""0""].id
      return Promise.all(requests).then((values) => {
        let mergedData = []
        values.forEach((val) => {
          mergedData = mergedData.concat(val.data)
        })
        hycon.debug(`${thisRef.constructor.name} getGWRRequest mergedResponses`, {
          mergedData,
          singleResponses: values
        })
        return { data: mergedData }
      })
    }

    return getGWRRequest()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} componentDidUpdate`, {
      prevProps,
      newProps: this.props,
      prevState,
      snapshot
    })

    const old_selectedBuildings = prevProps.reduxState.selectedBuildings
    const new_selectedBuildings = thisRef.props.reduxState.selectedBuildings

    if (JSON.stringify(old_selectedBuildings) !== JSON.stringify(new_selectedBuildings)) {
      hycon.debug(`${thisRef.constructor.name} componentDidUpdate - new selectedBuildings`, {
        old_selectedBuildings,
        new_selectedBuildings
      })

      thisRef.setIsLoading(true)
      thisRef.getMinergie()
      .then((minergieData) => {
        hycon.log('got minergieData', minergieData)
        thisRef.setStateProperty('minergie', minergieData.data)
        return thisRef.getGWR().then((res) => {
          return thisRef.setStateProperty('gwr', res.data)
        })
      })
      .then(() => {
        return thisRef.setIsLoading(false)
      })
      .then(() => {
        return thisRef.updateAddressMarkers()
      })
      .catch(() => {
        return thisRef.setIsLoading(false)
      })
    } else {
      hycon.debug(`${thisRef.constructor.name} componentDidUpdate - no new selectedBuildings`, {
        old_selectedBuildings,
        new_selectedBuildings
      })
    }

    if (
      prevState.isMounted &&
      prevProps.reduxState.map.map &&
      prevState.isMapListenersInitialized === false
    ) {
      hycon.debug(`${thisRef.constructor.name} componentDidUpdate - initMapMoveListener`, {
        thisRef, isMapListenersInitialized: prevState.isMapListenersInitialized
      })
      return thisRef.setStateProperty('isMapListenersInitialized', true).then(() => {
        thisRef.initMapMoveListener()
      })
    }
  }

  componentDidCatch (error, errorInfo) {
    hycon.debug(`${this.constructor.name} componentDidCatch`, { error, errorInfo })
  }

  componentWillUnmount () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} componentWillUnmount`, { props: this.props })
    thisRef.removeMapMoveListener()
  }
}

class AddressComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.onToggle = this.onToggle.bind(this)
    hycon.debug(`${this.constructor.name} constructor`, { props: this.props })
  }

  getComponent () {
    let thisRef = this
    if (thisRef.state.isOpen) {
      return <span>{`${thisRef.props.edid.strasse + ' ' + thisRef.props.edid.eingangnummer}`}</span>
    } else {
      return <span>{`${thisRef.props.edid.eingangnummer}`}</span>
    }
  }

  setIsOpen (isOpen) {
    let thisRef = this
    thisRef.setState((prevState) => {
      return {
        ...prevState, isOpen
      }
    }, () => {

    })
  }

  onToggle (e) {
    let thisRef = this
    e.preventDefault()
    thisRef.setIsOpen(!thisRef.state.isOpen)
  }

  renderFocusButtons () {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} renderFocusButtons`, { ...thisRef.props })
    let target = document.getElementById(`focus-button-${thisRef.props.gwrElement.egid}-${thisRef.props.edidElementIndex}`)
    if (target) {
      return ReactDOM.createPortal(<i className="fas fa-eye" onClick={thisRef.onToggle}/>, target)
    }
  }

  render () {
    let thisRef = this
    let shouldDisplayPins = thisRef.props.shouldDisplayPins
    hycon.debug(`${this.constructor.name} rendering`, {
      props: this.props,
      gwrIndex: thisRef.props.gwrIndex,
      edidIndex: thisRef.props.edidElementIndex
    })
    if (
      shouldDisplayPins
    ) {
      return (
        <div className={'custom-pin-address'} onClick={thisRef.onToggle}>
          {thisRef.getComponent()}
          {thisRef.renderFocusButtons()}
        </div>
      )
    } else {
      return null
    }
  }
}

export default withTranslation(['panel_building_info', 'panel_usage', "dynamic_panel_marketsense_v4"])(BuildingInfo)

