import React, { useState, useEffect, useContext, useRef } from 'react'
import HyperConsole from '../../hyper_console/hyper-console'

import './Controls.css'

import { Button, Icon } from 'semantic-ui-react'
import { GoogleAnalytics } from '../../google_analytics/GoogleAnalytics'
import { useTranslation, withTranslation } from 'react-i18next'
import axios from 'axios'
import moment from 'moment'
import iziToast from 'izitoast'
import 'izitoast/dist/css/iziToast.css'
import { GlobalContext } from '../../../index'
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button as MUIButton } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { themes } from '../../mui/themes';
import CircularProgress from '@mui/material/CircularProgress';
import * as turf from '@turf/turf'
import Leaflet from 'leaflet'


const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}
function getIzitoastColor (props){
  const color = props.appStateServer.clients[0] === "sep" ? themes.geoimpact.palette.primary.main : themes.praedictio.palette.primary.main;
  return color;
}

function Favorites (props) {
  const hookName = 'Favorites'
  const updateEeventName = 'favorites-update'
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let buildings = getBuildings()
    hycon.debug(`${hookName} getBuildings - buildings`, { buildings, props })
    return () => {
      // cleanup function
      hycon.debug(`${hookName} getBuildings - cleanup`, {})
    }
  })

  // util
  const dispatchUpdateEvent = async () => {
    const event = new Event(updateEeventName)
    // Dispatch the event.
    document.body.dispatchEvent(event)
  }

  const getBuildings = () => {
    let selectedBuildings = props.reduxState.selectedBuildings
    let buildings = selectedBuildings.map(
      (selectedBuilding) => {
        let building = selectedBuilding.buildings[0]
        // inject the coordinates that the user clicked when selecting the building on the map
        let geoJSON = selectedBuilding.geoJSON
        building.mapClickGeoJSON = geoJSON
        return building
      }
    )
    // hycon.debug(`${hookName} getBuildings`, { buildings })
    return buildings
  }

  const addFavorites = async () => {
    if (isLoading) {
      return Promise.resolve('a request is already pending')
    }
    setIsLoading(true)

    let selectionGroupName = moment().unix()

    let buildings = getBuildings()
    let addFavoritesPromises = buildings.map(async building => {
      return await postFavoriteAddress(building, selectionGroupName)
    })
    let addFavoritesResponse = await Promise.all(addFavoritesPromises)
    .then((favoritesResponses) => {
      return favoritesResponses
    })
    .then(async (favoritesResponses) => {
      // connect the favorite to all known event types
      let addAllEventTypesToAddressesByGroupResponses = await addAllEventTypesToAddressesByGroup(selectionGroupName)
      hycon.debug(`${hookName} addFavorites - addAllEventTypesToAddressesByGroupResponses`, { addAllEventTypesToAddressesByGroupResponses })

      return { favoritesResponses, addAllEventTypesToAddressesByGroupResponses }
    })
    .then(async ({ favoritesResponses }) => {
      hycon.debug(`${hookName} addFavorites - ok`, { favoritesResponses })
      let totalAddressedAdded = 0
      favoritesResponses.forEach((response) => {
        if (
          response.status === 200 ||
          response.status === 201
        ) {
          totalAddressedAdded = totalAddressedAdded + response.data.length;
        } else {
          hycon.debug(`${hookName} addFavorites - invalid`, { response })
        }
      })
      let message = `${totalAddressedAdded} ${props.i18n.t('favorites:notification-favorites-added')}`
      iziToast.show({
        timeout: 3000,
        theme: 'dark',
        class: "loading",
        color: getIzitoastColor(props),
        zindex: 5,
        message: `${message}`,
        position: 'topCenter',
        drag: false,
      })
    })
    dispatchUpdateEvent()
    setIsLoading(false)
    hycon.debug(`${hookName} addFavorites`, { addFavoritesResponse })
  }

  const addAllEventTypesToAddressesByGroup = async (selectionGroupName) => {
    let eventTypesResponse = await getEventTypes()
    let eventTypes = eventTypesResponse.data
    let promises = []
    eventTypes.forEach((eventType) => {
      let request = addAddressesOfBuildingByGroup(selectionGroupName, eventType)
      promises.push(request)
    })

    let addTypesRespones = await Promise.all(promises)
    hycon.debug(`${hookName} addAllEventTypesToAddressesByGroup`, { addTypesRespones, selectionGroupName, eventTypes })
    return addTypesRespones
  }

  const addAddressesOfBuildingByGroup = async (group, eventType) => {
    let env = props.reduxState.env
    const jwt = props.reduxState.user.jwt
    let endpoint = `${env.API_GATEWAY_BASE}/api/favoriteaddresses-by-selectiongroup/${group}/eventtypes/${eventType.id}`
    let data = {}
    hycon.debug(`${hookName} addAddressesOfBuildingByGroup`, {})
    return await axios(
      {
        method: 'post',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${hookName} addAddressesOfBuildingByGroup`, { error })
    })
  }

  const postFavoriteAddress = async (building, selectionGroupName) => {
    let env = props.reduxState.env
    const jwt = props.reduxState.user.jwt
    let endpoint = `${env.API_GATEWAY_BASE}/api/favoriteaddresses-by-building/${building.id}/${selectionGroupName}`
    let data = {}
    hycon.debug(`${hookName} addFavorites`, {})
    return await axios(
      {
        method: 'post',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${hookName} postFavoriteAddress`, { error })
      return error.response
    })
  }

  const getEventTypes = async () => {
    let env = props.reduxState.env
    const jwt = props.reduxState.user.jwt
    let endpoint = `${env.API_GATEWAY_BASE}/api/eventtypesforaddresses`
    let data = {}
    hycon.debug(`${hookName} getEventTypes`, {})
    return await axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${hookName} getEventTypes`, { error })
    })
  }
  if(props.isOpen === false) return null;
  return(
    <div className={"Favorites"}>
      <Button
        loading={isLoading}
        primary
        icon
        size={'big'}
        onClick={addFavorites}
      >
        <i className="far fa-star"/>
      </Button>
    </div>
  );
}


const emotion = {
  paper: {
    borderRadius: '0px'
  },
  button: {
    background: 'var(--gi_color_dark_primary_color)',
    '&:hover': {
      background: 'var(--gi_color_accent_color)',
    },
    borderRadius: '0px',
    color: 'var(--gi_color_alt_text)',
  },
  buttonClose: {
    background: 'var(--gi_color_secondary_text)',
    '&:hover': {
      background: 'var(--gi_color_accent_color)',
    },
    borderRadius: '0px',
    color: 'var(--gi_color_alt_text)',
  }
};
export function getRestoreLink (props){
  try {
    let layerName = props.reduxState.map.mapState.selectedBaseLayer.name;
    let points = [];
    props.reduxState.selectedBuildings.forEach((buildingsElement) => {
      let point = {
        lat: buildingsElement.buildings[0].coordinates.latitude,
        lng: buildingsElement.buildings[0].coordinates.longitude
      };
      points.push(point);
    });
    let bounds = props.reduxState.map.map.getBounds();
    let state = {
      mapState: {
        selectedBaseLayer: {
          name: layerName
        },
        selection: {
          points: points
        },
        bounds: bounds
      }
    };
    return `${window.location.origin}/map/?state=${encodeURIComponent(JSON.stringify(state))}`
  } catch (e) {
    hycon.warn(e);
    // throw new Error(`Can't copy restore link. Reason: ${e.message}`);
  }
}
export function getSEPLink (
    props, coordinates = {}
){
  try {
    let layerName = props.reduxState.map.mapState.selectedBaseLayer.name;
    let point = {
      lat: coordinates.lat,
      lng: coordinates.lng
    };

    // make a bounding box around a buffer for a given point;
    const p = turf.point([point.lat, point.lng]);
    let buffer = turf.buffer(p, 50, {units: 'meters'});
    let bbox = turf.bbox(buffer);
    let poly = turf.bboxPolygon(bbox);

    let corner1 = props.reduxState.map.Leaflet.latLng(poly.geometry.coordinates[0][0][0], poly.geometry.coordinates[0][0][1])
    let corner2 = props.reduxState.map.Leaflet.latLng(poly.geometry.coordinates[0][2][0], poly.geometry.coordinates[0][2][1])

    let bounds =  props.reduxState.map.Leaflet.latLngBounds(corner1, corner2)

    let state = {
      mapState: {
        selectedBaseLayer: {
          name: layerName
        },
        selection: {
          points: [point]
        },
        bounds: bounds
      }
    };
    return `${window.location.origin}/map/?state=${encodeURIComponent(JSON.stringify(state))}`
  } catch (e) {
    hycon.warn(e);
    // throw new Error(`Can't copy restore link. Reason: ${e.message}`);
  }
}

export function getSEPLinkByLayerName (
    layerName, coordinates = {}
){
  try {
    let point = {
      lat: coordinates.lat,
      lng: coordinates.lng
    };

    // make a bounding box around a buffer for a given point;
    const p = turf.point([point.lat, point.lng]);
    let buffer = turf.buffer(p, 50, {units: 'meters'});
    let bbox = turf.bbox(buffer);
    let poly = turf.bboxPolygon(bbox);

    let corner1 = Leaflet.latLng(poly.geometry.coordinates[0][0][0], poly.geometry.coordinates[0][0][1])
    let corner2 = Leaflet.latLng(poly.geometry.coordinates[0][2][0], poly.geometry.coordinates[0][2][1])

    let bounds =  Leaflet.latLngBounds(corner1, corner2)

    let state = {
      mapState: {
        selectedBaseLayer: {
          name: layerName
        },
        selection: {
          points: [point]
        },
        bounds: bounds
      }
    };
    return `${window.location.origin}/map/?state=${encodeURIComponent(JSON.stringify(state))}`
  } catch (e) {
    hycon.warn(e);
    // throw new Error(`Can't copy restore link. Reason: ${e.message}`);
  }
}

function Share (props) {
  const hookName = 'Share';
  let LOCIZE_PANEL_NS = "controls_map";
  const { t, /*i18n*/ } = useTranslation(LOCIZE_PANEL_NS, {useSuspense: false});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [link, setLink] = useState(false);
  const textareaRef = useRef();
  useEffect(() => {
    hycon.debug(`${hookName} useEffect - clipboard init`, { props })
    // eslint-disable-next-line
  }, [])
  const getShortUrl = async () => {
    const response = await axios.request({
      method: 'post',
      url: 'https://links.energyapps.ch/api/UrlShortener?code=sqZYWjYZ9uXhlWHlswA9VGe9IU/NrNnpY/niA/JGaOxAnwTs9EgZsQ==',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: JSON.stringify({
        url: getRestoreLink(props),
      }),
    });
    try {
      return response.data.ShortUrl;
    } catch (e){
      hycon.error(`${hookName} getShortUrl - error`, { e })
      return null;
    }
  };
  const getDialog = () => {
    if(!isDialogOpen) return null;
    return (
      <Dialog
        css={{
          paper: emotion.paper
        }}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        aria-labelledby="copy-dialog-title"
        open={isDialogOpen}
      >
        <DialogTitle>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: "space-between"}}>
            <h3 style={{display: 'flex', margin: '0px', justifyContent: 'center', alignItems: 'center'}}>
              {t(`${LOCIZE_PANEL_NS}:copy-to-clip-title`)}
            </h3>
            <IconButton
              aria-label="delete"
              onClick={() => {
                setIsDialogOpen(false)
              }}
              size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {(()=>{
            if(link){
              return (
                  <DialogContentText>
                    <textarea
                        id={`share-clipboard-dialog-textarea`}
                        value={link}
                        data-link={link}
                        readOnly={true}
                        ref={textareaRef}
                        style={{ display: 'flex', flexDirection: 'row', flex: 1, minHeight: '50px', margin: '0px', width: '100%' }}
                    />
                  </DialogContentText>
              )
            } else {
              return (
                  <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                    <CircularProgress />
                  </div>
              )
            }
          })()}
          <MUIButton
            disabled={!link}
            css={{
              root: emotion.button
            }}
            id={`share-clipboard-dialog-button`}
            onClick={async () => {
              hycon.debug(`${hookName} copying link`, { link })
              /* Select the text field */
              textareaRef.current.select();
              textareaRef.current.setSelectionRange(0, 99999); /* For mobile devices */
              /* Copy the text inside the text field */
              document.execCommand("copy");
              iziToast.show({
                timeout: 3000,
                theme: 'dark',
                color: getIzitoastColor(props),
                zindex: 1301,
                message: `${t(`${LOCIZE_PANEL_NS}:message-link-copied-ok`)}`,
                position: 'topCenter',
                drag: false,
              })
              setIsDialogOpen(false)
            }}
          >
            {t(`${LOCIZE_PANEL_NS}:copy-button-text`)}
          </MUIButton>
        </DialogContent>
      </Dialog>
    );
  }
  return(
    <div className={"Share"}>
      {(()=>{
        if(props.isOpen){
          return (
            <Button
              primary
              icon
              size={"big"}
              id={`share-clipboard-button`}
              onClick={async () => {
                setIsDialogOpen(true);
                let link = await getShortUrl();
                setLink(link);
              }}
            >
              <i className="fas fa-share-alt"/>
            </Button>
          )
        }
      })()}
      {getDialog()}
    </div>
  );
}

export function AdvancedControls (props) {
  const hookName = 'AdvancedControls'
  const [isOpen, setIsOpen] = useState(false)

  const shouldBeDisplayed = () => {
    if (
      (
        props.reduxState.session &&
        props.reduxState.session.user &&
        props.reduxState.session.user.role === 'Guest'
      )
      &&
      (
        props.reduxState.session &&
        props.reduxState.session.user &&
        (props.reduxState.session.user.mandant === 'default' || props.reduxState.session.user.mandant === '')
      )
    ) {
      return false
    }
    return true
  }

  const getControls = () => {
    return(
      <div className={"row"}>
        <Favorites {...props} isOpen={isOpen}/>
        <Share {...props} isOpen={isOpen}/>
      </div>
    );
  }


  if (shouldBeDisplayed()) {
    return (
      <div className={'AdvancedControls'}>
        <div className={"row"}>
          <Button
            primary
            active={isOpen}
            icon
            size={'big'}
            onClick={() => {
              setIsOpen(!isOpen)
            }}
            onBlur={(e) => {
              hycon.debug(`${hookName} onBlur`, {e});
              setTimeout(() => {
                setIsOpen(false);
              }, 500)
            }}
          >
            <i className="fas fa-ellipsis-v"/>
          </Button>
        </div>
        <div className={"row"}>
          {getControls()}
        </div>
      </div>
    )
  } else {
    return null
  }
}

function MapSelectionControl (props) {
  const hookName = "MapSelectionControl";
  const [isOpen, setIsOpen] = useState(false);

  const getSelector = () => {
    if (isOpen) {
      if(
        props.SEPContext &&
        props.SEPContext.user &&
        props.SEPContext.user.publicJWT
      ){
        return (
          <div className={"selector"}>
            <Button
              primary
              onClick={() => {
                GoogleAnalytics.sendGenerigEvent(
                  "select-swisstopo-grey",
                  "sep-basemap-select",
                  "Map Controls",
                  true
                );
                props.onMapChange(props.maps.Leaflet);
                props.onLayerChange(props.maps.Leaflet.layers.grey)
              }}
            >
              {props.i18n.t("controls_map:select-map-color-grey")}
            </Button>
            <Button
              primary
              onClick={() => {
                GoogleAnalytics.sendGenerigEvent(
                  "select-swisstopo-color",
                  "sep-basemap-select",
                  "Map Controls",
                  true
                );
                props.onMapChange(props.maps.Leaflet);
                props.onLayerChange(props.maps.Leaflet.layers.color)
              }}
            >
              {props.i18n.t("controls_map:select-map-color-color")}
            </Button>
            <Button
              primary
              onClick={() => {
                GoogleAnalytics.sendGenerigEvent(
                  "select-swisstopo-amtliche-vermessung",
                  "sep-basemap-select",
                  "Map Controls",
                  true
                );
                props.onMapChange(props.maps.Leaflet);
                props.onLayerChange(props.maps.Leaflet.layers.cadastral)
              }}
            >
              {props.i18n.t("controls_map:select-map-av")}
            </Button>
            {/*<Button
              primary
              onClick={() => {
                GoogleAnalytics.sendGenerigEvent(
                  "select-swisstopo-google-satellite",
                  "sep-basemap-select",
                  "Map Controls",
                  true
                );
                props.onMapChange(props.maps.Google);
                props.onLayerChange(props.maps.Google.layers.satellite)
              }}
            >
              {props.i18n.t("controls_map:select-map-google-satellite")}
            </Button>*/}
          </div>
        );
      } else {
        return (
          <div className={"selector"}>
            <Button
              primary
              onClick={() => {
                GoogleAnalytics.sendGenerigEvent(
                  "select-swisstopo-grey",
                  "sep-basemap-select",
                  "Map Controls",
                  true
                );
                props.onMapChange(props.maps.Leaflet);
                props.onLayerChange(props.maps.Leaflet.layers.grey)
              }}
            >
              {props.i18n.t("controls_map:select-map-color-grey")}
            </Button>
            <Button
              primary
              onClick={() => {
                GoogleAnalytics.sendGenerigEvent(
                  "select-swisstopo-color",
                  "sep-basemap-select",
                  "Map Controls",
                  true
                );
                props.onMapChange(props.maps.Leaflet);
                props.onLayerChange(props.maps.Leaflet.layers.color)
              }}
            >
              {props.i18n.t("controls_map:select-map-color-color")}
            </Button>
            <Button
              primary
              onClick={() => {
                GoogleAnalytics.sendGenerigEvent(
                  "select-swisstopo-amtliche-vermessung",
                  "sep-basemap-select",
                  "Map Controls",
                  true
                );
                props.onMapChange(props.maps.Leaflet);
                props.onLayerChange(props.maps.Leaflet.layers.cadastral)
              }}
            >
              {props.i18n.t("controls_map:select-map-av")}
            </Button>
            <Button
              primary
              onClick={() => {
                GoogleAnalytics.sendGenerigEvent(
                  "select-swisstopo-google-satellite",
                  "sep-basemap-select",
                  "Map Controls",
                  true
                );
                props.onMapChange(props.maps.Google);
                props.onLayerChange(props.maps.Google.layers.satellite)
              }}
            >
              {props.i18n.t("controls_map:select-map-google-satellite")}
            </Button>
          </div>
        );
      }
    }
  }

  return (
    <div className="MapSelectionControl">
      <div className={"row"}>
        <Button
          className="buttonMapLayer"
          primary
          active={isOpen}
          icon
          size={"big"}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          onBlur={(e) => {
            hycon.debug(`${hookName} onBlur`, {e});
            setTimeout(() => {
              setIsOpen(false);
            }, 500)
          }}
        >
          <Icon name='map'/>
        </Button>
      </div>
      <div className={"row"}>
        {getSelector()}
      </div>
    </div>
  );
}

function Controls(props){
  const hookName = "Controls";
  const globalContext = useContext(GlobalContext)
  let LOCIZE_PANEL_NS = "notification_map";
  const { t, /*i18n*/ } = useTranslation(LOCIZE_PANEL_NS, {useSuspense: false});
  if(
    props.SEPContext &&
    props.SEPContext.user &&
    props.SEPContext.user.publicJWT
  ){
    return (
      <div className="Controls">
        <div className={'control zoomin'}>
          <Button
            primary
            icon
            size={'big'}
            onClick={(e) => {
              GoogleAnalytics.sendGenerigEvent('zoomin', 'sep-zoom', 'Map Controls', true)
              e.target.blur()
              if(props.reduxState.map.map !== null){
                props.reduxState.map.map.zoomIn(1);
              }
            }}
          >
            <Icon name='plus'/>
          </Button>
        </div>
        <div className={'control zoomout'}>
          <Button
            primary
            icon
            size={'big'}
            onClick={(e) => {
              GoogleAnalytics.sendGenerigEvent('zoomout', 'sep-zoom', 'Map Controls', true)
              e.target.blur()
              if(props.reduxState.map.map !== null){
                props.reduxState.map.map.zoomOut(1);
              }
            }}
          >
            <Icon name='minus'/>
          </Button>
        </div>
        <div className={'control'}>
          <MapSelectionControl {...props}/>
        </div>
      </div>
    )
  } else {
    return (
      <div className="Controls">
        <div className={'control zoomin'}>
          <Button
            primary
            icon
            size={'big'}
            onClick={(e) => {
              GoogleAnalytics.sendGenerigEvent('zoomin', 'sep-zoom', 'Map Controls', true)
              e.target.blur()
              if(props.reduxState.map.map !== null){
                props.reduxState.map.map.zoomIn(1);
              }
            }}
          >
            <Icon name='plus'/>
          </Button>
        </div>
        <div className={'control zoomout'}>
          <Button
            primary
            icon
            size={'big'}
            onClick={(e) => {
              GoogleAnalytics.sendGenerigEvent('zoomout', 'sep-zoom', 'Map Controls', true)
              e.target.blur()
              if(props.reduxState && props.reduxState.map && props.reduxState.map.map !== null){
                props.reduxState.map.map.zoomOut(1);
              }
            }}
          >
            <Icon name='minus'/>
          </Button>
        </div>
        <div className={'control trash-selection'}>
          <Button
            primary
            disabled={globalContext.selection.isLocked}
            size={"big"}
            onClick={(e) => {
              hycon.debug(`${hookName} renderSelectionControls onClick`, {e});
              try {
                let toasts = document.querySelectorAll('.loading-delete-selection');
                toasts.forEach((toast) => {
                  iziToast.hide({}, toast);
                })
              } catch (e) {
                hycon.warn('iziToast error', e)
              }
              iziToast.show({
                timeout: 3000,
                theme: 'dark',
                class: "loading-delete-selection",
                color: getIzitoastColor(props),
                zindex: 5,
                message: `${t(`${LOCIZE_PANEL_NS}:building-selection-deleted`)}`,
                position: 'topCenter',
                drag: false,
              })
              let event = new Event('sep_map-overlay_controls:trash-selection');
              // Dispatch the event.
              window.dispatchEvent(event);
            }}
          >
            <Icon name='trash'/>
            <br/>
            <span>({props.reduxState.selectedBuildings.length})</span>
          </Button>
        </div>
        <div className={'control'}>
          <MapSelectionControl {...props}/>
        </div>
        <div className={'control'}>
          <AdvancedControls {...props}/>
        </div>
      </div>
    )
  }
}

export default withTranslation(["controls_map"])(Controls)

