import React, {Component} from 'react';
import HyperConsole from "../../../hyper_console/hyper-console";
import "./Reset.css";
import {Button, Form} from 'semantic-ui-react';
import axios from "axios/index";
import {UserUtil} from "../../util/user-util";
import { withTranslation } from 'react-i18next'
import SupportRequest from "../../../reusable/SupportRequest";


const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV;
let hycon = null;
if (REACT_APP_GI_ENV === "development") {
	hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
} else {
	hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
}

class Reset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isDisabled: false,
            touched: {
                password: false,
                passwordRepeat: false
            },
            passwordValidationRequests: [],
            passwordValidation: {
                errors: []
            },
            messages: [],
            errors: {
                password: false,
                passwordRepeat: false
            }
        };

        hycon.debug(`${this.constructor.name} constructor`, {props: this.props});
    }

    componentDidMount() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} componentDidMount`, {props: this.props});

        thisRef.loadRecaptchaScript();
    }


    static getDerivedStateFromProps(props, state) {
        hycon.debug(`${"Reset"} getDerivedStateFromProps`, {props, state});
        if (!state) {
            hycon.debug(`${"Reset"} getDerivedStateFromProps - only props have been updated`, {props, state});
        }
        return state;
    }

    shouldComponentUpdate(nextProps, nextState) {

        hycon.debug(`${this.constructor.name} shouldComponentUpdate`, {nextProps, props: this.props, nextState});
        return true;
    }

    cancelPendingRequests() {
        let thisRef = this;
        // hycon.debug(`${this.constructor.name} cancelPendingRequests`, {thisRef, props: this.props});

        thisRef.state.passwordValidationRequests.forEach((source) => {
            // hycon.debug(`${this.constructor.name} cancelPendingRequests - canceling request nr ${i}`, {source, i});
            try {
                source.cancel();
            } catch (e) {
                /* hycon.info(`${this.constructor.name} cancelPendingRequests - error - canceling request nr ${i}`, {
                    source,
                    i
                });
                */
            }
        })
    }

    checkPassword() {
        let thisRef = this;
        let env = thisRef.props.reduxState.env;
        let password = thisRef.state.password;
        if(!this.state.touched.password || password === ""){
            return thisRef.setStateProperty("passwordValidation", Object.assign({}, thisRef.state.passwordValidation, {errors: []})).then(() => {
                return Promise.resolve();
            });
        }
        return new Promise((res, rej) => {
            const endpoint = `${env.API_GATEWAY_BASE}/api/password-isvalid?part=${password}`;
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            let cancelTokenSavePromise = new Promise(() => {
                return thisRef.setStateProperty("passwordValidationRequests", thisRef.state.passwordValidationRequests.concat([source]));
            });

            let axiosPromise = axios(
                {
                    method: 'get',
                    url: endpoint,
                    cancelToken: source.token,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': "application/json",
                    }
                    /*
                    ,
                    data: JSON.stringify({})
                    */
                }
            )
                .then((response) => {
                    // hycon.info(`${this.constructor.name} checkPassword`, {state: thisRef.state});

                    if (response.data.success) {
                        thisRef.setStateProperty("passwordValidation", Object.assign({}, thisRef.state.passwordValidation, {errors: []}))
                    } else {
                        thisRef.setStateProperty("passwordValidation", Object.assign({}, thisRef.state.passwordValidation, {errors: [response.data.message]}))
                    }

                    return res(response);
                })
                .catch((error) => {
                    // hycon.warn(`${this.constructor.name} checkPassword - error`, {state: thisRef.state});
                    return rej(error);
                });
            return cancelTokenSavePromise.then(() => {
                return axiosPromise
            });
        });
    }

    isFormComplete() {
        let thisRef = this;
        // hycon.debug(`${thisRef.constructor.name} isFormComplete`, {});
        let isComplete = (
            (!thisRef.state.errors.password && thisRef.state.touched.password) &&
            (!thisRef.state.errors.passwordRepeat && thisRef.state.touched.passwordRepeat) &&
            (thisRef.state.passwordValidation.errors.length === 0)
        );
        return isComplete;
    }

    isAllTouched() {
        let thisRef = this;
        // hycon.debug(`${thisRef.constructor.name} isAllTouched`, {});
        let isAllTouched = (
            thisRef.state.oldPassword &&
            thisRef.state.password &&
            thisRef.state.passwordRepeat
        );

        return isAllTouched;
    }

    resetFields() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} resetFields`, {thisRef});
        return Promise.all([
            thisRef.setStateProperty("oldPassword", ""),
            thisRef.setStateProperty("password", ""),
            thisRef.setStateProperty("passwordRepeat", "")
        ]);
    }

    reset() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} edit`, {thisRef});

        let token = window.location.href.split("confirmUri=")[1].split("token=")[1].split("&userid=")[0];
        let userId = window.location.href.split("confirmUri=")[1].split("token=")[1].split("&userid=")[1];

        let url = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/api/confirm-recovery?userid=${userId}&token=${token}&password=${thisRef.state.password}`;
        let jwt = thisRef.props.reduxState.user.jwt;
        return thisRef.setStateProperty("isLoading", true).then(() => {
            return axios(
                {
                    method: 'post',
                    url: url,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': "application/json",
                        'Authorization': `Bearer ${jwt}`
                    }
                }
            )
                .then((response) => {
                    hycon.info(`${thisRef.constructor.name} edit - ok`, {response});
                    if(response.data.success === false){
                        hycon.info(`${thisRef.constructor.name} edit - not so ok`, {response});
                        thisRef.setStateProperty("messages", thisRef.state.messages.concat([
                            `${thisRef.props.i18n.t("recover:label-reset-error-1")}<br/>` +
                            `${response.data.message}<br/><br/><br/><br/><br/><br/>`
                        ]), {
                            reset: true,
                            resetValue: [],
                            delta: 4000
                        });
                        // thisRef.resetFields();
                        return thisRef.setStateProperty("isLoading", false);
                    } else {
                        thisRef.setStateProperty("messages", thisRef.state.messages.concat([
                            `${thisRef.props.i18n.t("recover:label-reset-ok-1")}<br/>` +
                            `${thisRef.props.i18n.t("recover:label-reset-ok-2")}`
                        ]), {reset: true, resetValue: [], delta: 4000}).then(() => {
                            thisRef.props.history.push("/user/signin/");
                        });
                        thisRef.resetFields();
                        thisRef.setStateProperty("isLoading", false);
                        return response;
                    }
                })
                .catch((e) => {
                    hycon.error(`${thisRef.constructor.name} edit - error`, e);
                    UserUtil.authenticationRedirect(e.response, thisRef);
                    thisRef.setStateProperty("messages", thisRef.state.messages.concat([
                        `${thisRef.props.i18n.t("recover:label-reset-error-1")}<br/>` +
                        `${e.message}<br/><br/><br/><br/><br/><br/>`
                    ]), {
                        reset: true,
                        resetValue: [],
                        delta: 4000
                    });
                    // thisRef.resetFields();
                    thisRef.setStateProperty("isLoading", false);
                });
        });
    }

    sendResetRequest() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} sendEditRequest`, {state: thisRef.state});
        thisRef.reset()
            .then(() => {
                hycon.debug(`${this.constructor.name} sendResetRequest - ok`);
            })
            .catch((e) => {
                hycon.debug(`${this.constructor.name} sendResetRequest - error`, e);
            });
    }

    getFormValidElements() {
        let thisRef = this;
        let validElements = {
            oldPassword: !thisRef.state.errors.oldPassword,
            password: !thisRef.state.errors.password,
            passwordRepeat: !thisRef.state.errors.passwordRepeat
        };
        validElements.isAllValid = (
            validElements.oldPassword &&
            validElements.password &&
            validElements.passwordRepeat
        );
        hycon.debug(`${thisRef.constructor.name} getFormValidElements`, {validElements});
        return validElements;
    }

    validate(validation = {}) {
        let thisRef = this;
        let promises = [];

        if (validation.oldPassword) {
            if (validation.oldPassword.length > 0) {
                promises.push(thisRef.setStateProperty("errors", Object.assign({}, thisRef.state.errors, {oldPassword: true})));
            } else {
                promises.push(thisRef.setStateProperty("errors", Object.assign({}, thisRef.state.errors, {oldPassword: false})));
            }
        }

        if (validation.password) {
            if (thisRef.state.password !== thisRef.state.passwordRepeat && validation.password) {
                promises.push(thisRef.setStateProperty("errors", Object.assign({}, thisRef.state.errors, {passwordRepeat: true})));
            } else {
                promises.push(thisRef.setStateProperty("errors", Object.assign({}, thisRef.state.errors, {passwordRepeat: false})));
            }
        }

        thisRef.checkPassword()
            .then(() => {
                // hycon.debug(`${this.constructor.name} validate - checkPassword - ok`, {newState: thisRef.state});
                promises.push(thisRef.setStateProperty("errors", Object.assign({}, thisRef.state.errors, {password: false})));
            })
            .catch(() => {
                // hycon.debug(`${this.constructor.name} validate - checkPassword - err`, {newState: thisRef.state});
                promises.push(thisRef.setStateProperty("errors", Object.assign({}, thisRef.state.errors, {password: true})));
            });

        return Promise.all(promises);
    }

    setStateProperty(propertyName, propertyValue, opts) {
        let thisRef = this;

        if (opts) {
            let isValid = (
                typeof opts.reset !== "undefined" &&
                typeof opts.resetValue !== "undefined" &&
                typeof opts.delta !== "undefined"
            );
            if (!isValid) {
                throw new Error('options object is not defined as expected. expeected something like {reset: <bool>, resetValue: <object>, delta: <number>}');
            }
        }

        let resetPromise = Promise.resolve();
        if (
            opts &&
            opts.reset === true
        ) {
            resetPromise = new Promise((res, rej) => {
                setTimeout(() => {
                    try {
                        thisRef.setStateProperty("messages", opts.resetValue);
                        res();
                    } catch (e) {
                        hycon.error(`${this.constructor.name} setStateProperty - resetPromise - error`, {newState: thisRef.state});
                        rej(e)
                    }
                }, opts.delta);
            });
        }

        let setPromise = new Promise((res) => {
            hycon.debug(`${this.constructor.name} setStateProperty - setPromise - new promise`, {newState: thisRef.state, propertyName, propertyValue, opts});
            thisRef.setState((p) => {
                const obj = {};
                obj[propertyName] = propertyValue;
                return Object.assign({}, p, obj);
            }, () => {
                hycon.debug(`${this.constructor.name} setStateProperty - setPromise - ok`, {newState: thisRef.state});
                res();
            })
        });

        return Promise.all([resetPromise, setPromise]);
    }

    recaptchaExecute() {
        let thisRef = this;
        let key = thisRef.props.reduxState.env.GOOGLE_RECAPTCHA_SITE_KEY;
        return new Promise((res) => {
            let grecaptcha = window.grecaptcha;
            grecaptcha.ready(() => {
                grecaptcha.execute(key, {action: 'homepage'}).then((token) => {
                    hycon.info(`${thisRef.constructor.name} recaptchaExecute - google says you are not a bot`, {token});
                    return thisRef.setStateProperty("recaptcha-token", token).then(() => {
                        res();
                    });
                });
            });
        });
    }

    loadRecaptchaScript () {
        let thisRef = this
        hycon.debug(`${thisRef.constructor.name} loadRecaptchaScript`, {})

        let key = thisRef.props.reduxState.env.GOOGLE_RECAPTCHA_SITE_KEY

        let scriptId = "google-recaptcha-script";
        let script = document.createElement('script')
        script.id=scriptId;
        script.type = 'text/javascript'
        script.src = `https://www.google.com/recaptcha/api.js?render=${key}`

        let recaptchaScript = document.getElementById(scriptId);
        if(!recaptchaScript){
            document.getElementsByTagName('head')[0].appendChild(script)
        }
    }

    render() {
        let thisRef = this;
        return (
            <div className="Reset">
                <div className={`reset-form`}>
                    <div className={"heading"}>
                        <img className={"image"}
                             alt={""}
                             src={`https://geoimpactstorage.blob.core.windows.net/public/logo/sep/Logo-SEP-web.png`}/>
                        <h1>{thisRef.props.i18n.t("recover:label-reset-1")}</h1>
                    </div>
                    <Form loading={thisRef.state.isLoading}>
                        <Form.Field>
                            <label>{thisRef.props.i18n.t("recover:label-new-password")}</label>
                            <input
                                placeholder={thisRef.props.i18n.t("recover:label-new-password")}
                                type={"password"}
                                autoComplete={`new-password`}
                                onChange={(event) => {
                                    let val = event.target.value;
                                    hycon.debug(`${thisRef.constructor.name} onChange`, {event, value: val});
                                    thisRef.setStateProperty("password", val)
                                        .then(() => {
                                            return thisRef.setStateProperty("touched", Object.assign({}, thisRef.state.touched, {password: true}));
                                        })
                                        .then(() => {
                                            thisRef.validate({password: true});
                                        });
                                }}
                                onBlur={() => {
                                    hycon.debug(`${thisRef.constructor.name} onBlur`, {});
                                    thisRef.validate({password: true});
                                }}
                                value={thisRef.state.password}
                            />
                        </Form.Field>
                        <div className={"password-hints"}>
                            {(() => {
                                if (thisRef.state.passwordValidation.errors.length > 0) {
                                    let errorsString = thisRef.state.passwordValidation.errors[thisRef.state.passwordValidation.errors.length - 1];
                                    let errors = errorsString.split("|");
                                    let items = [];
                                    errors.forEach((e, i) => {
                                        items.push((
                                            <li key={i}>{e}</li>
                                        ));
                                    });
                                    return <ul>{items}</ul>
                                } else {
                                    return "";
                                }
                            })()}
                        </div>
                        <Form.Field error={thisRef.state.errors.passwordRepeat}>
                            <label>{thisRef.props.i18n.t("recover:label-repeat-new-password")}</label>
                            <input
                                placeholder={thisRef.props.i18n.t("recover:label-repeat-new-password-placeholder")}
                                type={"password"}
                                onChange={(event) => {
                                    let val = event.target.value;
                                    hycon.debug(`${thisRef.constructor.name} onChange`, {event, value: val});
                                    thisRef.setStateProperty("passwordRepeat", val)
                                        .then(() => {
                                            return thisRef.setStateProperty("touched", Object.assign({}, thisRef.state.touched, {passwordRepeat: true}));
                                        })
                                        .then(() => {
                                            thisRef.validate({password: true});
                                        });
                                }}
                                onBlur={() => {
                                    hycon.debug(`${thisRef.constructor.name} onBlur`, {});
                                    thisRef.validate({password: true});
                                }}
                                value={thisRef.state.passwordRepeat}
                            />
                        </Form.Field>
                        <div className={`column-flex`}>
                            <Button
                                primary
                                type='submit'
                                disabled={(() => {
                                    let isFormComplete = thisRef.isFormComplete();
                                    return !isFormComplete;
                                })()}
                                onClick={() => {
                                    thisRef.validate()
                                        .then(() => {
                                            let validElements = thisRef.getFormValidElements();
                                            let isAllValid = validElements.isAllValid;
                                            return isAllValid;
                                        })
                                        .then((isAllValid) => {
                                            if (isAllValid) {
                                                hycon.debug(`${thisRef.constructor.name} form looks good`, {});
                                                thisRef.recaptchaExecute().then(() => {
                                                    return thisRef.sendResetRequest();
                                                });
                                            } else {
                                                hycon.debug(`${thisRef.constructor.name} please check your form`, {});
                                            }
                                        });
                                }}
                            >
                                {thisRef.props.i18n.t("recover:label-reset-2")}
                            </Button>
                        </div>
                    </Form>
                    <div className={"footer"}>
                        <h5>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: (
                                        () => {
                                            if (thisRef.state.messages.length > 0) {
                                                return `<div>${thisRef.state.messages[thisRef.state.messages.length - 1]}</div>`
                                            }
                                        }
                                    )()
                                }}
                            />
                        </h5>
                        <SupportRequest {...thisRef.props}/>
                    </div>
                </div>
            </div>
        );
    }

    componentDidUpdate() {

        hycon.debug(`${this.constructor.name} componentDidUpdate`, {props: this.props});
    }

    componentWillUnmount() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} componentWillUnmount`, {props: this.props});
        thisRef.cancelPendingRequests();
    }
}

export default withTranslation(["recover"])(Reset);
