import { CookieUtil } from '../cookies/cookie-util'
import { TokenUtil } from '../util/token-util'
import axios from 'axios/index'
import HyperConsole from '../../hyper_console/hyper-console'
import jwt_decode from "jwt-decode";

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

class UserUtilClass {
  constructor () {
    this.constants = {
      USER_UPDATE_ERROR: 'Could not update the authentication token',
      defaultPermissions: {
        selectedClientIds: [
          0
        ],
        clients: [
          {
            id: 0,
            name: 'default',
            selectedRoleIds: [
              0
            ],
            roles: [
              { id: 0, name: 'Anonymous' },
              { id: 1, name: 'Guest' },
              { id: 2, name: 'User' }
            ]
          }
        ]
      }
    }
  }

  getLegacyRole (ctx) {
    let role = null
    try {
      if (
        ctx.props.reduxState &&
				ctx.props.reduxState.session &&
				ctx.props.reduxState.session.user &&
				ctx.props.reduxState.session.user.role
      ) {
        role = ctx.props.reduxState.session.user.role
      }
    } catch (e) {
      hycon.warn('No legacy user role')
      hycon.warn(e)
    }
    if (
      role == null || role.trim() === ''
    ) {
      role = null
      return role
    }
    return role.trim()
  }

  getUserFromRedux (ctx) {
    return ctx.props.reduxState.user
  }

  updateUserCookie (newReduxUser) {
    let thisRef = this
    /* updates local storage with the newReduxUser that is stored in redux */
    let key = CookieUtil.constants.names.USER
    hycon.debug(`${thisRef.constructor.name} updateUserCookie`, { newReduxUser })
    let oldUserCookie = null
    try {
      oldUserCookie = JSON.parse(CookieUtil.getCookie(key))
    } catch (e) {
      hycon.debug(`${thisRef.constructor.name} updateUserCookie - no valid cookie found`, {})
      oldUserCookie = {}
    }

    try {
      let newUserCookie = Object.assign({}, oldUserCookie, newReduxUser)
      CookieUtil.setCookie(key, JSON.stringify(newUserCookie))
      hycon.debug(`${thisRef.constructor.name} updateUserCookie - updating ${key} cookie with`, { newUserCookie })
      let updatedUserCookieString = CookieUtil.getCookie(key)
      hycon.debug(`${thisRef.constructor.name} updateUserCookie - updated ${key} cookie with`, { updatedUserCookieString })
    } catch (e) {
      hycon.error(`${thisRef.constructor.name} updateUserCookie - error`, e)
      throw e
    }
  }

  deletePersistenUser () {
    /* deletes the user cookie */
    let thisRef = this
    try {
      CookieUtil.deleteCookie(CookieUtil.constants.names.USER)
      hycon.info(`${thisRef.constructor.name} deletePersistenUser - ok`)
    } catch (e) {
      hycon.error(`${thisRef.constructor.name} deletePersistenUser - error`, e)
      throw e
    }
  }

  updateReduxUser (newReduxUser, ctx, force = false) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} updateReduxUser`, { newReduxUser, ctx })
    if (force) {
      return new Promise((res, rej) => {
        ctx.props.reduxDispatch('updateUser', newReduxUser)
        res(newReduxUser)
      })
    } else {
      return new Promise((res, rej) => {
        hycon.debug(`${thisRef.constructor.name} updateReduxUser - updating with`, { newReduxUser })
        let previousUser = {}
        try {
          previousUser = ctx.props.reduxState.user
          let newUser = Object.assign({}, previousUser, newReduxUser)
          hycon.debug(`${thisRef.constructor.name} updateReduxUser - merged user`, { newUser })
          ctx.props.reduxDispatch('updateUser', newUser)
          res(newUser)
        } catch (e) {
          console.debug(`${thisRef.constructor.name} updateReduxUser - no previous user in redux`, { props: ctx.props })
          rej(e)
        }
      })
    }
  }

  async updateSession (newSession, ctx, force = false) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} updateSession`, { newSession, ctx })
    if (force) {
      return await new Promise((res, rej) => {
        ctx.props.reduxDispatch('updateSession', newSession)
        res(newSession)
      })
    } else {
      return await new Promise((res, rej) => {
        hycon.debug(`${thisRef.constructor.name} updateSession - updating with`, { newSession })
        let previousSession = {}
        try {
          previousSession = ctx.props.reduxState.session
          let mergedSession = Object.assign({}, previousSession, newSession)
          hycon.debug(`${thisRef.constructor.name} updateSession - merged session`, { mergedSession })
          ctx.props.reduxDispatch('updateSession', mergedSession)
          res(mergedSession)
        } catch (e) {
          console.debug(`${thisRef.constructor.name} updateSession - no previous session in redux`, { props: ctx.props })
          rej(e)
        }
      })
    }
  }

  getUserFromCookieAndUpdateRedux (ctx) {
    let thisRef = this
    return new Promise((res, rej) => {
      let userCookieString = CookieUtil.getCookie(CookieUtil.constants.names.USER)
      let user = null
      try {
        userCookieString = CookieUtil.getCookie(CookieUtil.constants.names.USER)
      } catch (e) {
        hycon.warn(`${thisRef.constructor.name} getUserFromCookieAndUpdateRedux - warning`, e)
      }
      if (!userCookieString) {
        // if there is no user stored in the cookies
        hycon.info(`${thisRef.constructor.name} getUserFromCookieAndUpdateRedux - no user found in cookies`, { userCookieString })
        res(null)
      } else {
        try {
          user = JSON.parse(userCookieString)
          hycon.info(`${thisRef.constructor.name} getUserFromCookieAndUpdateRedux - parsed user from cookies`, { user })
        } catch (e) {
          hycon.warn(`${thisRef.constructor.name} getUserFromCookieAndUpdateRedux - warning - could not parse the user string`, {
            e,
            userCookieString
          })
        }
      }
      if (user) {
        return thisRef.updateReduxUser(user, ctx)
          .then(() => {
            /* updated redux */
            let newUserFromRedux = ctx.props.reduxState.user
            hycon.info(`${thisRef.constructor.name} getUserFromCookieAndUpdateRedux - updated user in redux`, { newUserFromRedux })
            return TokenUtil.updateTokenInRedux(user.jwt, ctx)
          }).then((newUserWithUpdatedToken) => {
            hycon.info(`${thisRef.constructor.name} getUserFromCookieAndUpdateRedux - updated token (inside user) in redux`, { newUserWithUpdatedToken })
            res(newUserWithUpdatedToken)
          })
      } else {
        res(null)
      }
    })
  }

  updateUserInReduxAndCookies (user, ctx) {
    let thisRef = this
    try {
      /* updates redux */
      return thisRef.updateReduxUser(user, ctx)
        .then(() => {
          hycon.info(`${thisRef.constructor.name} updateUserInReduxAndCookies - updated redux`, { user, ctx })
          /* updates cookies */
          thisRef.updateUserCookie(user)
          hycon.info(`${thisRef.constructor.name} updateUserInReduxAndCookies - updated cookies`, {
            user,
            ctx
          })
        })
    } catch (e) {
      hycon.error(`${thisRef.constructor.name} updateUserInReduxAndCookies`, {})
      throw new Error(thisRef.constants.USER_UPDATE_ERROR)
    }
  }

  deleteUserInReduxAndCookies (ctx) {
    let thisRef = this
    hycon.info(`${thisRef.constructor.name} deleteUserInReduxAndCookies`, { ctx })
    try {
      /* updates redux. force is used to avoid the mergin of the user object */
      return thisRef.updateReduxUser({}, ctx, true)
        .then(() => {
          /* deletes cookies */
          thisRef.deletePersistenUser()
        }).catch(() => {
          /* deletes cookies */
          thisRef.deletePersistenUser()
        })
    } catch (e) {
      hycon.error(`${thisRef.constructor.name} deleteUserInReduxAndCookies - error`, { e, ctx })
      throw new Error(thisRef.constants.USER_UPDATE_ERROR)
    }
  }

  logout (ctx) {
    let thisRef = this
    hycon.debug(`${ctx.constructor.name} logout`, ctx)
    let env = ctx.props.reduxState.env
    const jwt = ctx.props.reduxState.user.jwt

    return new Promise((res, rej) => {
      const endpoint = `${env.API_GATEWAY_BASE}/api/logout`
      return axios(
        {
          method: 'get',
          url: endpoint,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`
          }
        }
      )
        .then((response) => {
          return TokenUtil.updateTokenInReduxAndCookie(response, ctx).then(() => {
            return response
          })
        })
        .then((response) => {
          thisRef.deleteUserInReduxAndCookies(ctx)
          hycon.info(`${this.constructor.name} logout - ok`, { state: ctx.state })
          return res(response)
        })
        .catch((error) => {
          thisRef.deleteUserInReduxAndCookies(ctx)
          hycon.warn(`${this.constructor.name} logout - error`, { state: ctx.state, error })
          return rej(error)
        })
    })
  }

  isLoggedIn (ctx) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} isLoggedIn`, { ctx, thisRef })

    let env = null
    let jwt = null
    try {
      env = ctx.props.reduxState.env
      jwt = ctx.props.reduxState.user.jwt
    } catch (e) {
      console.warn('isLoggedIn - user is not logged in', e)
      return Promise.resolve(false)
    }

    return new Promise((res) => {
      const endpoint = `${env.API_GATEWAY_BASE}/api/login-status`
      return axios(
        {
          method: 'get',
          url: endpoint,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`
          }
        }
      )
        .then((response) => {
          hycon.debug(`${this.constructor.name} isLoggedIn - response`, { response })
          return response
        })
        .then((response) => {
          hycon.info(`${this.constructor.name} isLoggedIn`, { response })
          if (
            response.data.success &&
						response.data.operation === 'Check login status' &&
						response.data.message === 'Logged in'
          ) {
            res(true)
          } else if (
            response.data.success &&
						response.data.operation === 'Check login status' &&
						response.data.message === 'Logged out'
          ) {
            res(false)
          }
        }).catch((e) => {
          hycon.warn(`${this.constructor.name} isLoggedIn - error`, { e })
          res(false)
        })
    })
  }
  async checkLoginStatus (jwt, env) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} isLoggedIn`, { jwt, env })
    return axios(
        {
          method: 'get',
          url: `${env.API_GATEWAY_BASE}/api/authinfo`,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`
          }
        }
    )
        .then((response) => {
          hycon.debug(`${this.constructor.name} getAuthInfo - response`, { response })
          return true;
        })
        .catch((e) => {
          hycon.warn(`${this.constructor.name} getAuthInfo - error`, { e })
          return false
        })
  }
  async getMandantInfo (jwt, env) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} isLoggedIn`, { jwt, env })
    return axios(
        {
          method: 'get',
          url: `${env.API_GATEWAY_BASE}/api/authinfo`,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`
          }
        }
    );
  }

  authenticationRedirect (response, ctx) {
    let thisRef = this
    hycon.debug(`${ctx.constructor.name} authenticationRedirect`, { response, ctx })
    if (response.status === 401) {
      hycon.debug(`${ctx.constructor.name} authenticationRedirect - got 401`, { response, ctx })
      return thisRef.isLoggedIn(ctx)
        .then((isLoggedIn) => {
          hycon.debug(`${ctx.constructor.name} authenticationRedirect - isLoggedIn`, { isLoggedIn })
          if (!isLoggedIn) {
            return thisRef.deleteUserInReduxAndCookies(ctx)
              .then(() => {
                CookieUtil.deleteCookie(CookieUtil.constants.names.USER)
                hycon.debug(`${ctx.constructor.name} authenticationRedirect - kickout with delete`, {})
                let continuePath = `${ctx.props.history.location.pathname}${ctx.props.history.location.search}`;
                hycon.debug(`${ctx.constructor.name} authenticationRedirect - continuePath`, { continuePath })
                ctx.props.history.push(`/user/signin/?continuePath=${encodeURIComponent(continuePath)}`)
              })
              .catch((e) => {
                hycon.debug(`${ctx.constructor.name} authenticationRedirect - error - delete USER cookie + kickout`, { e })
                CookieUtil.deleteCookie(CookieUtil.constants.names.USER)
                let continuePath = `${ctx.props.history.location.pathname}${ctx.props.history.location.search}`;
                hycon.debug(`${ctx.constructor.name} authenticationRedirect - continuePath`, { continuePath })
                ctx.props.history.push(`/user/signin/?continuePath=${encodeURIComponent(continuePath)}`)
              })
          }
        })
        .catch((e) => {
          hycon.debug(`${ctx.constructor.name} authenticationRedirect - isLoggedIn - error`, { e })
          // if some other error happens kick him out anyway
          return thisRef.deleteUserInReduxAndCookies(ctx)
            .then(() => {
              CookieUtil.deleteCookie(CookieUtil.constants.names.USER)
              hycon.debug(`${ctx.constructor.name} authenticationRedirect - kickout with delete`, {})
              let continuePath = `${ctx.props.history.location.pathname}${ctx.props.history.location.search}`;
              hycon.debug(`${ctx.constructor.name} authenticationRedirect - continuePath`, { continuePath })
              ctx.props.history.push(`/user/signin/?continuePath=${encodeURIComponent(continuePath)}`)
            })
            .catch((e) => {
              hycon.debug(`${ctx.constructor.name} authenticationRedirect - error - delete USER cookie + kickout`, { e })
              CookieUtil.deleteCookie(CookieUtil.constants.names.USER)
              let continuePath = `${ctx.props.history.location.pathname}${ctx.props.history.location.search}`;
              hycon.debug(`${ctx.constructor.name} authenticationRedirect - continuePath`, { continuePath })
              ctx.props.history.push(`/user/signin/?continuePath=${encodeURIComponent(continuePath)}`)
            })
        })
    } else {
      hycon.debug(`${ctx.constructor.name} authenticationRedirect - was authenticated`, { response, ctx })
    }
  }

  async injectUserinfoIntoRedux (user, context) {
    // if there is a user it should look like {jwt: ... , user: {...}} otherwise it is null
    hycon.debug(`${this.constructor.name} injectUserinfoIntoRedux - injecting userinfo intto redux`, user)
    let thisRef = this
    if (user) {
      hycon.debug(`${this.constructor.name} injectUserinfoIntoRedux - user found`, user)
      await thisRef.getUserInfo(context).then(async (userInfo) => {
        hycon.debug(`${this.constructor.name} injectUserinfoIntoRedux - user found - userinfo`, userInfo)
        await UserUtil.getClientInfo(context).then(async (clientInfo) => {
          if (clientInfo[0] && clientInfo[1]) {
            let cleanClientInfo = [
              { data: clientInfo[0].data },
              { data: clientInfo[1].data }
            ]
            await thisRef.updateSession(
              {
                user: userInfo,
                clientInfo: cleanClientInfo
              },
              context,
              false
            )
          }
        })
      })
    } else {
      hycon.debug(`${this.constructor.name} injectUserinfoIntoRedux - no user found`, user)
    }
  }

  getUserInfo (context) {
    hycon.info(`${this.constructor.name} getUserInfo`, { context, reduxState: context.props.reduxState })
    let env = context.props.reduxState.env
    let jwt = context.props.reduxState.user.jwt
    if (typeof context.props.reduxState.user.user === 'undefined') {
      return Promise.resolve(null)
    } else {
      let id = context.props.reduxState.user.user.id
      const endpoint = `${env.API_GATEWAY_BASE}/api/userinfo/${id}`
      return axios(
        {
          method: 'get',
          url: endpoint,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`
          }
        }
      )
        .then((response) => {
          hycon.info(`${this.constructor.name} getUserInfo - response - token refresh`, {
            state: context.state,
            response
          })
          return TokenUtil.updateTokenInReduxAndCookie(response, context).then(() => {
            return response
          })
        })
        .then((userDataResponse) => {
          hycon.info(`${this.constructor.name} getUserInfo - response`, {
            state: context.state,
            userDataResponse
          })
          let data = userDataResponse.data
          return data
        })
        .catch((error) => {
          hycon.warn(`${this.constructor.name} getUserInfo - error`, error)
        })
    }
  }

  test () {
    let thisRef = this
    hycon.info(`${thisRef.constructor.name} test`, {})
  }

  // CLIENT AND ROLES

  async getClientInfo (context) {
    let thisRef = this
    hycon.info(`${this.constructor.name} getClientInfo`, { context, reduxState: context.props.reduxState })
    let allInfo = Promise.all([
      thisRef.getCurrentMandant(context),
      thisRef.getAuthInfo(context)
    ]).then((responses) => {
      hycon.info(`${this.constructor.name} getClientInfo -  responses`, { responses })
      return responses
    })
    return await allInfo
  }

  async getPublicTokenInfo(props, publicJwt){
    let env = props.reduxState.env
    const endpoint = `${env.API_GATEWAY_BASE}/api/authinfo`
    return await axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${publicJwt}`
        }
      }
    )
    .then((response) => {
      hycon.debug(`${this.constructor.name} getPublicTokenInfo - response`, { response })
      return response.data;
    })
    .catch((e) => {
      hycon.warn(`${this.constructor.name} getPublicTokenInfo - error`, { e })
      return null
    })
  }

  getCurrentMandant (context) {
    hycon.info(`${this.constructor.name} getCurrentMandant`, { context, reduxState: context.props.reduxState })
    let env = context.props.reduxState.env
    let jwt = context.props.reduxState.user.jwt
    const endpoint = `${env.API_GATEWAY_BASE}/api/current-mandant`
    return axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      }
    )
      .then((response) => {
        hycon.debug(`${this.constructor.name} getCurrentMandant - response`, { response })
        return response
      }).catch((e) => {
        hycon.warn(`${this.constructor.name} getCurrentMandant - error`, { e })
        return null
      })
  }
  async getMandant (jwt, env) {
    return axios(
        {
          method: 'get',
          url: `${env.API_GATEWAY_BASE}/api/current-mandant`,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`
          }
        }
    )
  }

  getAuthInfo (context) {
    hycon.info(`${this.constructor.name} getAuthInfo`, { context, reduxState: context.props.reduxState })
    let env = context.props.reduxState.env
    let jwt = context.props.reduxState.user.jwt
    const endpoint = `${env.API_GATEWAY_BASE}/api/authinfo`
    return axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      }
    )
      .then((response) => {
        hycon.debug(`${this.constructor.name} getAuthInfo - response`, { response })
        return response
      })
      .catch((e) => {
        hycon.warn(`${this.constructor.name} getAuthInfo - error`, { e })
        return null
      })
  }

  hasClientAndRole (ctx, testClient, testRole) {
    let thisRef = this
    let clientInfo = null
    let client = null
    let role = null
    hycon.debug(`${thisRef.constructor.name} hasClinetAndRole`, { ctx, testClient, testRole })
    if (
      !ctx.props.reduxState.session.clientInfo
    ) {
      clientInfo = null
    } else {
      clientInfo = ctx.props.reduxState.session.clientInfo
    }

    let isUserInRedux = false
    try {
      client = clientInfo[0].data.name.trim()
      role = clientInfo[1].data.filter((e) => {
        return e.key === 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
      })[0].value.trim()
      isUserInRedux = true
    } catch (e) {
      hycon.info('no client and mandant in redux')
      isUserInRedux = false
    }
    if (!isUserInRedux) {
      if (
        testClient === 'default' &&
				testRole === 'Anonymous'
      ) {
        return true
      } else {
        return false
      }
    } else {
      if (client === '') {
        // treat an empty client as "default" client
        client = 'default'
      }
      return (
        testClient === client &&
				testRole === role
      )
    }
  }

  parseJWT(jwtString){
    return jwt_decode(jwtString);
  }
}

export const UserUtil = new UserUtilClass()
