import { FAVORITE_UPDATE_EVENT, reducerActions } from '../constants';

const FavoriteUtils = {
  compareFavoritesByLocation: (a, b) => {
    // Since there could be Address or Region or Country we need to
    // check first which object is that
    let first; let
      last;
    // Address has 'street' property
    if (Object.prototype.hasOwnProperty.call(a, 'street')) {
      first = `${a.street} ${a.houseNumber}, ${a.swissZipCode} ${a.town}`;
      // Region does not have 'street' property, but it has 'swissZipCode'
    } else if (a.swissZipCode !== null) {
      first = `${a.swissZipCode} ${a.town}`;
      // If it is not Address or Region it should be Country
    } else {
      first = `${a.name}`;
    }

    if (Object.prototype.hasOwnProperty.call(b, 'street')) {
      last = `${b.street} ${b.houseNumber}, ${b.swissZipCode} ${b.town}`;
    } else if (b.swissZipCode !== null) {
      last = `${b.swissZipCode} ${b.town}`;
    } else {
      last = `${b.name}`;
    }

    first = first.toLowerCase();
    last = last.toLowerCase();

    return first.localeCompare(last);
  },
  compareFavoritesByDate: (a, b) => {
    const first = new Date(a.lastUpdated).getTime();
    const last = new Date(b.lastUpdated).getTime();

    if (first < last) {
      return 1;
    }
    if (first > last) {
      return -1;
    }
    return 0;
  },
  reducer: (state, action) => {
    if (action.type === reducerActions.REPLACE) {
      const newState = { ...state, ...action.payload.newState };
      return newState;
    } if (action.type === reducerActions.REMOVE_LOADING_FAVORITES) {
      const newState = {
        ...state,
        loadingFavorites: state.loadingFavorites
          .filter((favoriteId) => !action.payload.favoriteIdArray.includes(favoriteId)),
      };
      return newState;
    } if (action.type === reducerActions.ADD_LOADING_FAVORITES) {
      const newState = {
        ...state,
        loadingFavorites: [
          ...new Set([...state.loadingFavorites, ...action.payload.favoriteIdArray]),
        ],
      };
      return newState;
    }
    throw new Error('unsupported reducer action');
  },
  updateFavoriteCounter: () => {
    const event = new Event(FAVORITE_UPDATE_EVENT);
    // Dispatch the event.
    document.body.dispatchEvent(event);
  },
};

export default FavoriteUtils;
