import React, { useState, useContext, useReducer, useEffect } from 'react'
import './index.css';
import AppContainer from "./containers/AppContainer";

import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/reducers';
import {Provider} from 'react-redux';
import logger from 'redux-logger'
import initialStore from './store/initialStore';
import {getI18nInstance} from "./i18n/i18n";
import env from "./env/env";
import {logger as customLogger} from "./components/logger/logger";
import HyperConsole from './components/hyper_console/hyper-console';
import { createRoot } from 'react-dom/client';
import SEPContext from "./contexts/sep-context/SEPContext";
import LocationProvider from "./contexts/location/location-context";
import MapProvider from "./contexts/map/map-context";

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
    hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
    hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

customLogger.setLevels(env);

function getReduxStore(env) {
    if (env.REACT_APP_GI_ENV === "production") {
        return createStore(rootReducer, initialStore);
        // return createStore(rootReducer, initialStore, applyMiddleware(thunk));
    }
    else if (env.REACT_APP_GI_ENV === "staging") {
        return createStore(rootReducer, initialStore);
        // return createStore(rootReducer, initialStore, applyMiddleware(thunk));
    }
    else if (env.REACT_APP_GI_ENV === "development") {
        return createStore(rootReducer, initialStore, applyMiddleware(logger, thunk));
        // return createStore(rootReducer, initialStore, applyMiddleware( thunk));
    }
    else if (env.REACT_APP_GI_ENV === "test") {
        // return createStore(rootReducer, initialStore);
        return createStore(rootReducer, initialStore, applyMiddleware(thunk));
    }
    else if (env.REACT_APP_GI_ENV === "experimental") {
        // return createStore(rootReducer, initialStore);
        return createStore(rootReducer, initialStore, applyMiddleware(thunk));
    } else {
        throw new Error(`Unsupported env var REACT_APP_GI_ENV: ${env.REACT_APP_GI_ENV}`,);
    }
}
initialStore.env = env;
let store = getReduxStore(env);

const defaultGlobalContext = {
    selection: {
        isLocked: false
    }
}
export const GlobalContext = React.createContext(defaultGlobalContext)
const actions = {
    REPLACE: "REPLACE"
}
const globalContextReducer = (state, action) => {
    const fnName = 'reducer'
    hycon.debug(`${fnName}`, { info: action.info, state, action })
    if (action.type === actions.REPLACE) {
        let newState = {
            ...state,
            ...action.payload
        }
        return newState
    }
    else {
        hycon.error(`${fnName} unsupported reducer action`, { state, action })
        throw new Error(`${fnName} unsupported reducer action`)
    }
}

function App() {
    const fnName = "App";
    const [sepContextValue, setSepContextValue] = useState(useContext(SEPContext));
    const updateSEPContext = (newSepContext) => {
        // console.debug(`App - updateSEPContext`, {newSepContext});
        setSepContextValue(newSepContext);
    };
    const [i18n, setI18n] = useState(null);
    const [appStateServer, setAppState] = useState(null);

    const [globalContext, dispatch] = useReducer(globalContextReducer, defaultGlobalContext);
    useEffect(()=>{
        async function setupLocize(){
            function getAppState(){
                let appStateServer = {
                    serverRequest: {

                    },
                    clients: [
                        new URLSearchParams(window.location.search).get("client") || "sep"
                    ]
                };
                try {
                    appStateServer = JSON.parse(document.getElementById("app-state-server").dataset.appStateServer);
                    console.info("got the app state from the server.", appStateServer);
                }catch (e){
                    console.warn("could not get the app state from the server. using default values", appStateServer);
                }
                return appStateServer;
            }
            let myAppState = getAppState();
            let myI18n = await getI18nInstance(myAppState.clients[0]);
            console.debug(`${fnName} - useEffect - [] - added i18n`, {myI18n, myAppState});
            setAppState(myAppState);
            setI18n(myI18n);
        }
        dispatch({
            type: actions.REPLACE,
            payload: {
                actions,
                dispatch
            }
        });
        setupLocize();
    }, [])
    const ReduxApp = (
        <SEPContext.Provider value={{...sepContextValue, updateSEPContext: updateSEPContext}}>
            <LocationProvider>
                <MapProvider>
                    <GlobalContext.Provider value={globalContext}>
                        <Provider store={store}>
                            <SEPContext.Consumer>
                                {(context) => (
                                    <AppContainer i18n={i18n} {...context} appStateServer={appStateServer}/>
                                )}
                            </SEPContext.Consumer>
                        </Provider>
                    </GlobalContext.Provider>
                </MapProvider>
            </LocationProvider>
        </SEPContext.Provider >
    );
    if(i18n){
        return ReduxApp;
    }
    return null;
}
export {
    SEPContext
}
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);


