import React, {Component} from 'react';
import ReactDOMServer from 'react-dom/server';
import HyperConsole from "../../hyper_console/hyper-console";
import "./SignIn.css";
import {Button, Form} from 'semantic-ui-react';
import axios from "axios/index";
import {TokenUtil} from "../util/token-util";
import {UserUtil} from "../util/user-util";
import {GoogleAnalytics} from "../../google_analytics/GoogleAnalytics";
import { withTranslation } from 'react-i18next'
import qs from 'qs'
import SupportRequest from "../../reusable/SupportRequest";

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV;
let hycon = null;
if (REACT_APP_GI_ENV === "development") {
	hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
} else {
	hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
}

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isSuccess: false,
            messages: [],
            isGoogleRecaptchaLoaded: false,
            isSubmitEnabled: true,
            errors: {
                email: false,
                password: false
            }
        };

        // hycon.debug(`${this.constructor.name} constructor`, {props: this.props});
    }

    componentDidMount() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} componentDidMount`, {props: this.props});
        console.log(`${this.constructor.name} componentDidMount`, {props: this.props});

        thisRef.loadRecaptchaScript();
        // TokenUtil.test();
        thisRef.displayUnauthorizedMessage();
    }


    static getDerivedStateFromProps(props, state) {
        // hycon.debug(`${"SignIn"} getDerivedStateFromProps`, {props, state});
        if (!state) {
            // hycon.debug(`${"SignIn"} getDerivedStateFromProps - only props have been updated`, {props, state});
        }
        return state;
    }

    shouldComponentUpdate() {

        // hycon.debug(`${this.constructor.name} shouldComponentUpdate`, {nextProps, props: this.props, nextState});
        return true;
    }

    signin() {
        let thisRef = this;
        let env = thisRef.props.reduxState.env;
        return new Promise((res, rej) => {
            const endpoint = `${env.API_GATEWAY_BASE}/api/login`;
            return axios(
                {
                    method: 'post',
                    url: endpoint,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': "application/json",
                    },
                    data: JSON.stringify({
                        email: thisRef.state.email,
                        password: thisRef.state.password
                    })
                }
            )

                .then((response) => {
                    hycon.info(`${this.constructor.name} signin`, {state: thisRef.state, response});
                    return res(response);
                })
                .catch((error) => {
                    hycon.warn(`${this.constructor.name} signin`, {state: thisRef.state, error});
                    if (thisRef.isUserLockedOut(error.response.data)) {
                        thisRef.setStateProperty("messages", thisRef.state.messages.concat([
                          thisRef.props.i18n.t("sign_in:message-signin-ok")
                        ]));
                        thisRef.setStateProperty("isLoading", false);
                        thisRef.setStateProperty("isSuccess", true);
                        thisRef.setStateProperty("isSubmitEnabled", false).then(() => {
                            let link = (
                                <div>
                                    Ihr Account wurde aus Sicherheitsgründen gesperrt. Klicken Sie
                                    {" "}
                                    <a href={"/user/recover/"}>
                                        hier
                                    </a>
                                    , um Ihren Account wiederherzustellen.
                                </div>
                            );
                            thisRef.setStateProperty("messages", thisRef.state.messages.concat([
                                ReactDOMServer.renderToString(link)
                            ]));
                        });
                        return rej(new Error("User account locked for security reasons."));
                    } else {
                        return rej(error);
                    }
                });
        });
    }

    getUserData() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} getUserData`, {state: thisRef.state});
        let env = thisRef.props.reduxState.env;
        let email = thisRef.state.email;
        let jwt = thisRef.props.reduxState.user.jwt;
        return new Promise((res, rej) => {
            const endpoint = `${env.API_GATEWAY_BASE}/api/userinfo-by-name/${email}`;
            return axios(
                {
                    method: 'get',
                    url: endpoint,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': "application/json",
                        "Authorization": `Bearer ${jwt}`
                    },
                    data: JSON.stringify({
                        email: thisRef.state.email,
                        password: thisRef.state.password
                    })
                }
            )
                .then((response) => {
                    hycon.info(`${this.constructor.name} getUserData - response`, {state: thisRef.state, response});
                    if (response && response.data && response.data.userName) {
                        hycon.debug(`${this.constructor.name} analytics`, response.data);
                        GoogleAnalytics.sendGenerigEvent(
                            `new-login from ${response.data.userName}`,
                            "sep-signin",
                            "User Management",
                            false
                        );
                    }
                    return TokenUtil.updateTokenInReduxAndCookie(response, thisRef).then(() => {
                        return response;
                    });
                })
                .then((userDataResponse) => {
                    hycon.info(`${this.constructor.name} getUserData - response`, {
                        state: thisRef.state,
                        userDataResponse
                    });
                    let userDataToStore = Object.assign({}, {
                        userName: userDataResponse.data.userName,
                        id: userDataResponse.data.id,
                        role: userDataResponse.data.role,
                        jwt: userDataResponse.headers.jwt
                    });
                    return UserUtil.updateReduxUser({user: userDataToStore}, thisRef)
                        .then((newReduxUser) => {
                            UserUtil.updateUserCookie(newReduxUser);
                            return userDataResponse;
                        }).then((userDataToStore) => {
                            res(userDataToStore)
                        });
                })
                .catch((error) => {
                    hycon.warn(`${this.constructor.name} getUserData`, {state: thisRef.state});
                    UserUtil.authenticationRedirect(error.response, thisRef);
                    return rej(error);
                });
        });
    }

    isUserLockedOut(responseData) {
        let thisRef = this;
        hycon.info(`${thisRef.constructor.name} detectLockedOutUser `, {responseData});
        // {"message":"Locked out. Account disabled","operation":"Login","success":false}
        try {
            return (responseData.success === false && responseData.message === "Locked out. Account disabled");
        } catch (e) {
            hycon.info(`${thisRef.constructor.name} isUserLockedOut - error `, {responseData});
            return false;
        }
    }

    continueNavigation(){
        let thisRef = this;
        try {
            let search = thisRef.props.history.location.search
            search = search.substr(1, search.length)
            hycon.info(`${thisRef.constructor.name} continueNavigation `, {search});
            const parsed = qs.parse(search, { arrayFormat: 'brackets' })
            hycon.info(`${thisRef.constructor.name} continueNavigation `, {search, parsed});
            let continuePath = parsed.continuePath;
            if(continuePath){
                hycon.info(`${thisRef.constructor.name} continueNavigation `, {search, continuePath, parsed});
                hycon.debug(`${thisRef.constructor.name} continueNavigation - continuePath`, { continuePath })
                thisRef.props.history.push(`${continuePath}`)
            } else {
                thisRef.props.history.push(`/map/`);
            }
        } catch (e) {
            hycon.warn(`${thisRef.constructor.name} continueNavigation - could not detect continue link`, {e,  history: thisRef.props.history});
        }
    }

    submit() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} submit`, {state: thisRef.state});
        thisRef.setStateProperty("messages", thisRef.state.messages.concat([
            thisRef.props.i18n.t("sign_in:message-sign-in-loading")
        ]));
        thisRef.setStateProperty("isLoading", true);
        thisRef.setStateProperty("isSubmitEnabled", false);
        thisRef.signin()
            .then((response) => {
                return TokenUtil.updateTokenInReduxAndCookie(response, thisRef).then(() => {
                    return response
                });
            })
            .then((response) => {
                hycon.debug(`${this.constructor.name} submit ok`, response);
                thisRef.setStateProperty("messages", thisRef.state.messages.concat([
                  thisRef.props.i18n.t("sign_in:message-signin-ok")
                ]));
                thisRef.setStateProperty("isLoading", false);
                thisRef.setStateProperty("isSuccess", true);
                return response;
            })
            .then(() => {
                return thisRef.getUserData();
            })
            .then((userDataResponse) => {
                // filter out the unnecessary user data because the user is gonna be saved in the cookies too.
                // The cookie can only store about 4000 chars only.
                /*
                address: null
                appsettings: "{"profiles":{"default":{"panels":{"construction_zo"
                familyName: null
                id: 29
                isConfirmed: false
                lastLogin: "2018-12-03T08:31:53.4782400+00:00"
                lastUpdated: "2018-12-03T08:31:53.4782420+00:00"
                links: [{…}]
                mandant: null
                preName: null
                properties: "{}"
                role: "Demo"
                rowVersionString: "NDRlMzZjYmItNWI0YS00NjViLWIzYzQtNWYzZjI4NzFmOTVj"
                userName: "ggcaponetto@gmail.com"
                */
                let userDataToStore = Object.assign({}, {
                    userName: userDataResponse.data.userName,
                    id: userDataResponse.data.id,
                    role: userDataResponse.data.role,
                    jwt: userDataResponse.headers.jwt
                });
                hycon.debug(`${this.constructor.name} getUserData - submit - inject`, userDataToStore);
                return UserUtil.updateUserInReduxAndCookies({user: userDataToStore}, thisRef)
                    .then(() => {
                        hycon.debug(`${this.constructor.name} getUserData - submit - about inject to redux`, {user: {user: userDataToStore}});
                        return {user: userDataToStore};
                    });
            })
            .then(async (user) => {
                hycon.debug(`${this.constructor.name} getUserData - submit - injecting to redux`, user);
                await UserUtil.injectUserinfoIntoRedux(user, thisRef);
                let mandant;
                if(
                    user?.user?.jwt
                    && thisRef.props.SEPContext.env
                ){
                    mandant = (await UserUtil.getMandant(user?.user?.jwt, thisRef.props.SEPContext.env)).data;
                }
                const newSEPContext = {
                    SEPContext: {
                        ...thisRef.props.SEPContext,
                        user: {
                            ...thisRef.props.SEPContext.user,
                            jwt: user.user && user.user.jwt,
                            jwtParsed: UserUtil.parseJWT(user.user.jwt),
                            mandant
                        }
                    }
                };
                thisRef.props.updateSEPContext(newSEPContext)
                hycon.debug(`${this.constructor.name} getUserData - submit - injecting to redux - redirect`, thisRef);
                thisRef.setStateProperty("isSubmitEnabled", false)
                    .then(() => {
                        thisRef.setStateProperty("messages", thisRef.state.messages.concat([
                            thisRef.props.i18n.t("sign_in:message-map-redirect")
                        ]))
                    .then(() => {
                        return thisRef.continueNavigation();
                    });
                });
            })
            .catch((e) => {
                hycon.debug(`${this.constructor.name} submit error`, e);
                if (e.message === "User account locked for security reasons.") {
                    return thisRef.setStateProperty("isLoading", false).then(() => {
                        return thisRef.setStateProperty("isSubmitEnabled", true);
                    });
                }
                if (e.response.status === 401 || e.response.status === 422) {
                    thisRef.setStateProperty("messages", thisRef.state.messages.concat([
                      thisRef.props.i18n.t("sign_in:message-wrong-credentials")
                    ]), {
                        reset: true,
                        resetValue: [],
                        delta: 5000
                    });
                } else {
                    thisRef.setStateProperty("messages", thisRef.state.messages.concat([`${e.message}`]), {
                        reset: true,
                        resetValue: [],
                        delta: 5000
                    });
                }
                thisRef.setStateProperty("isLoading", false).then(() => {
                    return thisRef.setStateProperty("isSubmitEnabled", true);
                });
            });
    }

    getFormValidElements() {
        let thisRef = this;
        let validElements = {
            email: !thisRef.state.errors.email,
            password: !thisRef.state.errors.password
        };
        validElements.isAllValid = (
            validElements.email &&
            validElements.password
        );
        // hycon.debug(`${thisRef.constructor.name} getFormValidElements`, {validElements});
        return validElements;
    }

    validate(validation = {}) {
        let thisRef = this;
        let promises = [];

        if (validation.email) {
            if (!thisRef.state.email) {
                promises.push(thisRef.setStateProperty("errors", Object.assign({}, thisRef.state.errors, {email: true})));
            } else {
                promises.push(thisRef.setStateProperty("errors", Object.assign({}, thisRef.state.errors, {email: false})));
            }
        }
        return Promise.all(promises);
    }

    setStateProperty(propertyName, propertyValue, opts) {
        let thisRef = this;


        if (opts) {
            let isValid = (
                typeof opts.reset !== "undefined" &&
                typeof opts.resetValue !== "undefined" &&
                typeof opts.delta !== "undefined"
            );
            if (!isValid) {
                throw new Error('options object is not defined as expected. expeected something like {reset: <bool>, resetValue: <object>, delta: <number>}');
            }
        }

        let resetPromise = Promise.resolve();
        if (
            opts &&
            opts.reset === true
        ) {
            resetPromise = new Promise((res, rej) => {
                setTimeout(() => {
                    try {
                        thisRef.setStateProperty("messages", opts.resetValue);
                        res();
                    } catch (e) {
                        rej(e)
                    }
                }, opts.delta);
            });
        }

        let setPromise = new Promise((res) => {
            thisRef.setState((p) => {
                const obj = {};
                obj[propertyName] = propertyValue;
                return Object.assign({}, p, obj);
            }, () => {
                // hycon.debug(`${this.constructor.name} setStateProperty - newState`, {newState: thisRef.state});
                res();
            })
        });

        return Promise.all([setPromise, resetPromise]);
    }

    recaptchaExecute() {
        let thisRef = this;
        let key = thisRef.props.reduxState.env.GOOGLE_RECAPTCHA_SITE_KEY;
        return new Promise((res) => {
            let grecaptcha = window.grecaptcha;
            grecaptcha.ready(() => {
                grecaptcha.execute(key, {action: 'homepage'}).then((token) => {
                    // hycon.info(`${thisRef.constructor.name} recaptchaExecute - google says you are not a bot`, {token});
                    return thisRef.setStateProperty("recaptcha-token", token).then(() => {
                        res();
                    });
                });
            });
        });
    }

    loadRecaptchaScript () {
        let thisRef = this
        hycon.debug(`${thisRef.constructor.name} loadRecaptchaScript`, {})

        let key = thisRef.props.reduxState.env.GOOGLE_RECAPTCHA_SITE_KEY

        let scriptId = "google-recaptcha-script";
        let script = document.createElement('script')
        script.id=scriptId;
        script.type = 'text/javascript'
        script.src = `https://www.google.com/recaptcha/api.js?render=${key}`

        let recaptchaScript = document.getElementById(scriptId);
        if(!recaptchaScript){
            document.getElementsByTagName('head')[0].appendChild(script);
            document.getElementById(scriptId).addEventListener('load', () => {
                // google recaptcha is loaded
                thisRef.setStateProperty("isGoogleRecaptchaLoaded", true);
            })
        } else {
            thisRef.setStateProperty("isGoogleRecaptchaLoaded", true);
        }
    }

    displayUnauthorizedMessage() {
        let thisRef = this;
        hycon.debug(`${thisRef.constructor.name} displayUnauthorizedMessage`, {thisRef});
        if (
            thisRef.props.location &&
            thisRef.props.location.state &&
            thisRef.props.location.state.unauthorized
        ) {
            let message = "Bitte melden Sie sich neu an.";
            window.alert(message);
        }
    }

    render() {
        let thisRef = this;
        return (
            <div className="SignIn">
                <div className={`signin-form`}>
                    <div className={"heading"}>
                        <img className={"image"}
                             alt={""}
                             src={`https://geoimpactstorage.blob.core.windows.net/public/logo/${thisRef.props.appStateServer.clients[0]}/Logo-SEP-web.png`}/>
                        <h1>{thisRef.props.i18n.t("sign_in:label-signin")}</h1>
                    </div>
                    <Form loading={thisRef.state.isLoading} className={`isSuccess-${thisRef.state.isSuccess}`}>
                        <Form.Field error={thisRef.state.errors.email}>
                            <label>{thisRef.props.i18n.t("sign_in:label-email")}</label>
                            <input
                                placeholder='E-Mail'
                                type={"email"}
                                autoComplete={`on`}
                                onPaste={(event) => {
                                    let val = event.target.value;
                                    hycon.debug(`${thisRef.constructor.name} onChange`, {value: val});
                                    thisRef.setStateProperty("email", val).then(() => {
                                        thisRef.validate({email: true});
                                    });
                                }}
                                onChange={(event) => {
                                    let val = event.target.value;
                                    hycon.debug(`${thisRef.constructor.name} onChange`, {value: val});
                                    thisRef.setStateProperty("email", val).then(() => {
                                        thisRef.validate({email: true});
                                    });
                                }}
                                onBlur={() => {
                                    // hycon.debug(`${thisRef.constructor.name} onBlur`, {});
                                    thisRef.validate({email: true});
                                }}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>{thisRef.props.i18n.t("sign_in:label-password")}</label>
                            <input
                                placeholder='Passwort'
                                type={"password"}
                                autoComplete={`on`}
                                onPaste={(event) => {
                                    let val = event.target.value;
                                    hycon.debug(`${thisRef.constructor.name} onChange`, {value: val});
                                    thisRef.setStateProperty("password", val).then(() => {
                                        thisRef.validate({password: true});
                                    });
                                }}
                                onChange={(event) => {
                                    let val = event.target.value;
                                    hycon.debug(`${thisRef.constructor.name} onChange`, {value: val});
                                    thisRef.setStateProperty("password", val).then(() => {
                                        thisRef.validate({password: true});
                                    });
                                }}
                                onBlur={() => {
                                    // hycon.debug(`${thisRef.constructor.name} onBlur`, {});
                                    thisRef.validate({password: true});
                                }}
                            />
                        </Form.Field>
                        <div className={`column-flex`}>
                            <div className={"recover"}>
                                <h5>
                                    {thisRef.props.i18n.t("sign_in:label-password-lost-1")}
                                    {" "}
                                    <span className={"link-btn"} onClick={() => {
                                        thisRef.props.history.push("/user/recover/");
                                    }}>
                                        {thisRef.props.i18n.t("sign_in:label-password-lost-2")}
                                    </span>
                                    {" "}
                                    {thisRef.props.i18n.t("sign_in:label-password-lost-3")}
                                </h5>
                            </div>
                        </div>
                        <div className={`column-flex`}>
                            <div className={"recover"}>
                                <h5>
                                    {thisRef.props.i18n.t("sign_in:label-no-account-1")}
                                    {" "}
                                    <span className={"link-btn"} onClick={() => {
                                        thisRef.props.history.push("/user/signup/");
                                    }}>
                                        {thisRef.props.i18n.t("sign_in:label-no-account-2")}
                                    </span>
                                    {" "}
                                    {thisRef.props.i18n.t("sign_in:label-no-account-3")}
                                </h5>
                            </div>
                        </div>
                        <div className={`column-flex login-button`}>
                            <Button
                                primary
                                type='submit'
                                disabled={thisRef.state.isSubmitEnabled === false || thisRef.state.isGoogleRecaptchaLoaded === false}
                                onClick={() => {
                                    thisRef.validate()
                                        .then(() => {
                                            let validElements = thisRef.getFormValidElements();
                                            let isAllValid = validElements.isAllValid;
                                            return isAllValid;
                                        })
                                        .then((isAllValid) => {
                                            if (isAllValid) {
                                                hycon.debug(`${thisRef.constructor.name} form looks good`, {});
                                                thisRef.recaptchaExecute().then(() => {
                                                    return thisRef.submit();
                                                });
                                            } else {
                                                hycon.debug(`${thisRef.constructor.name} please check your form`, {});
                                            }
                                        });
                                }}
                            >
                                {thisRef.props.i18n.t("sign_in:label-signin")}
                            </Button>
                        </div>
                    </Form>
                    <div className={"footer"}>
                        <h5>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: (
                                        () => {
                                            if (thisRef.state.messages.length > 1) {
                                                return thisRef.state.messages[thisRef.state.messages.length - 1]
                                            }
                                        }
                                    )()
                                }}
                            />
                        </h5>
                        <SupportRequest {...thisRef.props}/>
                    </div>
                </div>
            </div>
        );
    }

    componentDidUpdate() {
        let thisRef = this;
        hycon.debug(`${thisRef.constructor.name} componentDidUpdate`, {props: this.props});
    }

    componentWillUnmount() {
        // hycon.debug(`${this.constructor.name} componentWillUnmount`, {props: this.props});
    }
}

export default withTranslation(["sign_in"])(SignIn);
