import { combineReducers } from 'redux'
import {
  UPDATE_I18N, UPDATE_MAP_EVENTS, UPDATE_MAP_SELECTION, UPDATE_MULTI_MAP_EVENTS,
  UPDATE_REDUX_STATE, UPDATE_SELECTED_BUILDINGS, UPDATE_USER, UPDATE_USER_POSITION,
  UPDATE_MAP_OBJECTS, UPDATE_SESSION
} from '../actions/actions'

import initialStore from '../store/initialStore'

const i18nReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_I18N:
      return Object.assign({}, action.newState)
    default:
      return state
  }
}

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return Object.assign({}, action.newState)
    default:
      return state
  }
}

const mapObjectsReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MAP_OBJECTS:
      return Object.assign({}, action.newState)
    default:
      return state
  }
}

const mapEventsReducer = (state = [], action) => {
  switch (action.type) {
    case UPDATE_MAP_EVENTS:
      return [].concat(action.newState)
    default:
      return state
  }
}

const multiMapReducer = (state = [], action) => {
  switch (action.type) {
    case UPDATE_MULTI_MAP_EVENTS:
      return [].concat(action.newState)
    default:
      return state
  }
}

const userPositionsReducer = (state = [], action) => {
  switch (action.type) {
    case UPDATE_USER_POSITION:
      return [].concat(action.newState)
    default:
      return state
  }
}

const mapSelectionReducer = (state = [], action) => {
  switch (action.type) {
    case UPDATE_MAP_SELECTION:
      return [].concat(action.newState)
    default:
      return state
  }
}

const selectedBuildingsReducer = (state = [], action) => {
  switch (action.type) {
    case UPDATE_SELECTED_BUILDINGS:
      return [].concat(action.newState)
    default:
      return state
  }
}

const sessionReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SESSION:
      return Object.assign({}, action.newState)
    default:
      return state
  }
}

const bypassReducer = (state = {}, action) => {
  return state
}

const reducer = combineReducers({
  env: bypassReducer,
  i18n: i18nReducer,
  user: userReducer,
  mapEvents: mapEventsReducer,
  multiMapEvents: multiMapReducer,
  mapSelection: mapSelectionReducer,
  selectedBuildings: selectedBuildingsReducer,
  userPositions: userPositionsReducer,
  map: mapObjectsReducer,
  session: sessionReducer
})

const rootReducer = (state = initialStore, action) => {
  if (action.type === UPDATE_REDUX_STATE) {
    return {
      ...action.state
    }
  } else {
    return reducer(state, action)
  }
}

export default rootReducer
