import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  List,
  ListSubheader,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  ListItem,
  ListItemText,
  ListItemButton,
  Alert,
  Collapse,
  LinearProgress,
  Checkbox,
  Divider,
} from '@mui/material';
import { LOCIZE_PANEL_NS, REACT_APP_GI_ENV } from '../../constants';
import { useFavoriteCtx } from '../../FavoriteProvider';
import HyperConsole from '../../../hyper_console/hyper-console';
import FavoriteApi from '../../services/FavoriteApi';
import SEPContext from '../../../../contexts/sep-context/SEPContext';

let hycon = null;
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
}

export default function ModalFormNotification(props) {
  const { targetFavorites, updateContext, onModalClose } = props;
  const hookName = 'NotificationForm';
  const favoriteContext = useFavoriteCtx();
  const { t } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false });
  const [selectedAddressEvents, setSelectedAddressEvents] = useState([]);
  const [selectedRegionEvents, setSelectedRegionEvents] = useState([]);
  const [filterStringOfAddressEvents, setFilterStringOfAddressEvents] = useState('');
  const [filterStringOfRegionEvents, setFilterStringOfRegionEvents] = useState('');
  const [dataAddressEventTypes, setDataAddressEventTypes] = useState([]); // from backend
  const [dataRegionEventTypes, setDataRegionEventTypes] = useState([]); // from backend
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessMessageOpen, setIsSuccessMessageOpen] = useState(false);
  const { user: { jwt }, env } = useContext(SEPContext).SEPContext;

  const updateEventTypes = useCallback(async () => {
    try {
      const eventTypes = await Promise.all([
        FavoriteApi.getEventTypesForAddresses(jwt, env),
        FavoriteApi.getEventTypesForRegions(jwt, env),
      ]);
      setDataAddressEventTypes(eventTypes[0].data.sort((a, b) => {
        const translationA = t(`events:${a.descriptionLocize}`);
        const translationB = t(`events:${b.descriptionLocize}`);
        return translationA.localeCompare(translationB);
      }));
      setDataRegionEventTypes(eventTypes[1].data.sort((a, b) => {
        const translationA = t(`events:${a.descriptionLocize}`);
        const translationB = t(`events:${b.descriptionLocize}`);
        return translationA.localeCompare(translationB);
      }));
      return eventTypes;
    } catch (e) {
      return null;
    }
  }, []);

  useEffect(() => {
    updateEventTypes().catch((error) => {
      hycon.error(`${hookName} updateEventTypes - eventTypesForAddresses or getEventTypesForRegions`, error);
    });
  }, [updateEventTypes]);

  useEffect(() => {
    const isGroupEditMode = favoriteContext.isGroupEditMode(favoriteContext);
    const tAddressFav = targetFavorites.filter((f) => f.favoriteType === 'ADDRESS');
    const tRegionFav = targetFavorites.filter((f) => f.favoriteType === 'REGION');
    const addressEvents = tAddressFav.flatMap((type) => type.eventTypes).map((type) => type.id);
    const regionEvents = tRegionFav.flatMap((type) => type.eventTypes).map((type) => type.id);
    const uniqueAddressEvents = [...new Set(addressEvents)];
    const uniqueRegionEvents = [...new Set(regionEvents)];

    if (!isGroupEditMode) {
      setSelectedAddressEvents(uniqueAddressEvents);
      setSelectedRegionEvents(uniqueRegionEvents);
    } else {
      // compare the length of the favorites and the length of the events
      // if they are equal, then the event is selected
      const resultAddressEvents = [];
      for (let i = 0; i < uniqueAddressEvents.length; i += 1) {
        const listOfSharedEvents = addressEvents.filter((e) => e === uniqueAddressEvents[i]);
        if (listOfSharedEvents.length === tAddressFav.length) {
          resultAddressEvents.push(uniqueAddressEvents[i]);
        }
      }
      setSelectedAddressEvents(resultAddressEvents);

      const resultRegionEvents = [];
      for (let i = 0; i < uniqueRegionEvents.length; i += 1) {
        const lengthOfEventType = regionEvents.filter((e) => e === uniqueRegionEvents[i]).length;
        if (lengthOfEventType === tRegionFav.length) {
          resultRegionEvents.push(uniqueRegionEvents[i]);
        }
      }
      setSelectedRegionEvents(resultRegionEvents);
    }
  }, [targetFavorites]);

  /* eslint-disable consistent-return, no-shadow, no-await-in-loop */
  const updateNotifications = async () => {
    const isGroupEditMode = favoriteContext.isGroupEditMode(favoriteContext);
    const targetAddressFavorites = targetFavorites
      .filter((f) => f.favoriteType === favoriteContext.favoriteTypes.ADDRESS);
    const targetRegionFavorites = targetFavorites
      .filter((f) => f.favoriteType === favoriteContext.favoriteTypes.REGION);

    const getAllEventTypeIdsOfSelection = () => {
      const addressEventTypesIds = [];
      const regionEventTypesIds = [];

      const targetAddressFavorites = targetFavorites
        .filter((f) => f.favoriteType === favoriteContext.favoriteTypes.ADDRESS);
      const targetRegionFavorites = targetFavorites
        .filter((f) => f.favoriteType === favoriteContext.favoriteTypes.REGION);

      targetAddressFavorites.forEach((f) => {
        f.eventTypes.forEach((et) => {
          addressEventTypesIds.push(et.id);
        });
      });

      targetRegionFavorites.forEach((f) => {
        f.eventTypes.forEach((et) => {
          regionEventTypesIds.push(et.id);
        });
      });

      const uniqueAddressEventTypesIds = [...new Set(addressEventTypesIds)];
      const uniqueRegionEventTypesIds = [...new Set(regionEventTypesIds)];

      return { uniqueAddressEventTypesIds, uniqueRegionEventTypesIds };
    };

    if (isGroupEditMode) {
      const targetGroup = targetFavorites[0].group;
      const allTargetRecipientEmails = [];
      targetFavorites.forEach((f) => {
        f.recipients.forEach((r) => {
          allTargetRecipientEmails.push(r.email);
        });
      });
      const addressEventTypesIds = getAllEventTypeIdsOfSelection().uniqueAddressEventTypesIds;
      const regionEventTypesIds = getAllEventTypeIdsOfSelection().uniqueRegionEventTypesIds;

      if (targetAddressFavorites.length > 0) {
        for (let i = 0; i < addressEventTypesIds.length; i += 1) {
          const eventTypeId = addressEventTypesIds[i];
          try {
            await FavoriteApi.deleteEventTypeOfAddressByGroup(jwt, env, eventTypeId, targetGroup);
          } catch (e) {
            hycon.error(`${hookName} deleteEventTypeOfAddressByGroup`, { e });
            return null;
          }
        }
      }

      if (targetRegionFavorites.length > 0) {
        for (let i = 0; i < regionEventTypesIds.length; i += 1) {
          const eventTypeId = regionEventTypesIds[i];
          try {
            await FavoriteApi.deleteEventTypeOfRegionByGroup(jwt, env, eventTypeId, targetGroup);
          } catch (e) {
            hycon.error(`${hookName} deleteEventTypeOfRegionByGroup`, { e });
            return null;
          }
        }
      }

      if (targetAddressFavorites.length > 0) {
        for (let i = 0; i < selectedAddressEvents.length; i += 1) {
          const eventTypeId = selectedAddressEvents[i];
          try {
            await FavoriteApi.addEventTypeOfAddressByGroup(jwt, env, eventTypeId, targetGroup);
          } catch (e) {
            hycon.error(`${hookName} addEventTypeOfAddressByGroup`, { e });
            return null;
          }
        }
      }

      if (targetRegionFavorites.length > 0) {
        for (let i = 0; i < selectedRegionEvents.length; i += 1) {
          const eventTypeId = selectedRegionEvents[i];
          try {
            await FavoriteApi.addEventTypeOfRegionByGroup(jwt, env, eventTypeId, targetGroup);
          } catch (e) {
            hycon.error(`${hookName} addEventTypeOfRegionByGroup`, { e });
            return null;
          }
        }
      }
    } else {
      const deleteExistingEventTypesForAddress = async (favorite) => {
        // then remove the event type
        for (let i = 0; i < favorite.eventTypes.length; i += 1) {
          const eventType = favorite.eventTypes[i];
          try {
            await FavoriteApi
              .deleteEventTypeFromAddressFavorite(jwt, env, favorite.id, eventType.id);
          } catch (e) {
            hycon.error(`${hookName} deleteEventTypeFromAddressFavorite`, { e });
            return null;
          }
        }
      };
      const deleteExistingEventTypesForRegion = async (favorite) => {
        for (let i = 0; i < favorite.eventTypes.length; i += 1) {
          const eventType = favorite.eventTypes[i];
          try {
            await FavoriteApi
              .deleteEventTypeFromRegionFavorite(jwt, env, favorite.id, eventType.id);
          } catch (e) {
            hycon.error(`${hookName} deleteEventTypeFromRegionFavorite`, { e });
            return null;
          }
        }
      };
      const addEventTypesToFavoriteAddress = async (favorite) => {
        for (let i = 0; i < selectedAddressEvents.length; i += 1) {
          const addressEventTypeId = selectedAddressEvents[i];
          try {
            await FavoriteApi
              .addEventTypeToAddressFavorite(jwt, env, favorite.id, addressEventTypeId);
          } catch (e) {
            hycon.error(`${hookName} addEventTypeToAddressFavorite`, { e });
            return null;
          }
        }
      };
      const addEventTypesToFavoriteRegion = async (favorite) => {
        for (let i = 0; i < selectedRegionEvents.length; i += 1) {
          const regionEventTypeId = selectedRegionEvents[i];
          try {
            await FavoriteApi
              .addEventTypeToRegionFavorite(jwt, env, favorite.id, regionEventTypeId);
          } catch (e) {
            hycon.error(`${hookName} addEventTypeToRegionFavorite`, { e });
            return null;
          }
        }
      };

      for (let i = 0; i < targetAddressFavorites.length; i += 1) {
        const favorite = targetAddressFavorites[i];
        await deleteExistingEventTypesForAddress(favorite);
        await addEventTypesToFavoriteAddress(favorite);
      }
      for (let i = 0; i < targetRegionFavorites.length; i += 1) {
        const favorite = targetRegionFavorites[i];
        await deleteExistingEventTypesForRegion(favorite);
        await addEventTypesToFavoriteRegion(favorite);
      }
    }
  };
  /* eslint-enable consistent-return, no-shadow, no-await-in-loop */

  const isNotificationActive = (events, id) => events.indexOf(id) !== -1;

  const isCountrySelected = () => {
    const isCountry = targetFavorites.some((f) => f.favoriteType === 'REGION' && (
      f.name.toLowerCase() === 'schweiz' || f.name.toLowerCase() === 'svizzera' || f.name.toLowerCase() === 'suisse'
    ) && f.swissZipCode === null);

    return isCountry;
  };

  const getContent = () => {
    const targetAddressFavorites = targetFavorites
      .filter((f) => f.favoriteType === favoriteContext.favoriteTypes.ADDRESS);
    const targetRegionFavorites = targetFavorites
      .filter((f) => f.favoriteType === favoriteContext.favoriteTypes.REGION);
    const components = [];
    if (targetAddressFavorites.length > 0) {
      components.push((
        <>
          <DialogTitle>
            {t('favorites:notification-address')}
          </DialogTitle>
          <DialogContent
            dividers
            sx={{
              padding: 0,
              ...(dataAddressEventTypes.length <= 4 && {
                padding: '16px',
              }),
              height: '50vh',
            }}
          >
            <List subheader={dataAddressEventTypes.length > 4 ? (
              <ListSubheader
                sx={{
                  paddingTop: '16px',
                  boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
                }}
                key="address-list-subheader"
              >
                <FormControl component="fieldset" fullWidth>
                  <FormGroup aria-label="position" row sx={{ justifyContent: 'space-between' }}>
                    <TextField
                      label={t('favorites:table-advanced-filter')}
                      id="notification-address-filter"
                      size="small"
                      sx={{
                        flexGrow: '1',
                      }}
                      onChange={(event) => {
                        const { value } = event.target;
                        setFilterStringOfAddressEvents(value);
                      }}
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          color="primary"
                          checked={selectedAddressEvents.length === dataAddressEventTypes.length}
                          onChange={(event) => {
                            if (event.target.checked) {
                              setSelectedAddressEvents(dataAddressEventTypes.map((et) => et.id));
                            } else {
                              setSelectedAddressEvents([]);
                            }
                          }}
                          className="toggle-all-notifications"
                        />
                      )}
                      label={t('favorites:table-advanced-filter-turn-all')}
                      labelPlacement="start"
                      disabled={filterStringOfAddressEvents !== ''}
                    />
                  </FormGroup>
                </FormControl>
                <Collapse
                  in={isSuccessMessageOpen}
                  sx={{
                    position: 'absolute',
                    width: 'calc(100% - 32px)',
                    top: '80px',
                    left: '16px',
                    zIndex: '50',
                  }}
                >
                  <Alert variant="filled" severity="success">{t('various:settings-successfully-saved')}</Alert>
                </Collapse>
              </ListSubheader>
            ) : null}
            >
              {dataAddressEventTypes.length <= 4 && (
                <Collapse
                  in={isSuccessMessageOpen}
                  sx={{
                    position: 'absolute',
                    width: 'calc(100% - 32px)',
                    top: '16px',
                    left: '16px',
                    zIndex: '50',
                  }}
                >
                  <Alert variant="filled" severity="success">{t('various:settings-successfully-saved')}</Alert>
                </Collapse>
              )}
              {dataAddressEventTypes.filter((notification) => {
                const translatedEventLabel = t(`events:${notification.descriptionLocize}`);
                if (filterStringOfAddressEvents.length === 0) {
                  return true;
                }
                return translatedEventLabel.toLowerCase()
                  .indexOf(filterStringOfAddressEvents.toLowerCase()) !== -1;
              }).map((notification) => (
                <>
                  <ListItem
                    key={`item-address-${notification.id}-${notification.descriptionLocize}`}
                    sx={{ padding: 0, zIndex: 0 }}
                  >
                    <ListItemButton
                      onClick={() => {
                        if (selectedAddressEvents.indexOf(notification.id) !== -1) {
                          setSelectedAddressEvents(selectedAddressEvents
                            .filter((id) => id !== notification.id));
                        } else {
                          setSelectedAddressEvents([...selectedAddressEvents, notification.id]);
                        }
                      }}
                    >
                      <ListItemText
                        id={`switch-list-label-address-${notification.id}`}
                        primary={t(`events:${notification.descriptionLocize}`)}
                      />
                      <Checkbox
                        edge="end"
                        inputProps={{
                          'aria-labelledby': `switch-list-label-address-${notification.id}`,
                        }}
                        checked={isNotificationActive(selectedAddressEvents, notification.id)}
                        className="notification-toggle"
                        sx={{
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider component="li" />
                </>
              ))}
            </List>
          </DialogContent>
        </>
      ));
    }
    if (targetRegionFavorites.length > 0) {
      const isCountry = isCountrySelected();
      components.push((
        <>
          <DialogTitle>
            {t('favorites:notification-region')}
            {isCountry && (
            <>
              <br />
              <strong style={{
                color: 'red',
                fontSize: '12px',
              }}
              >
                {t('favorites:switzerland-can-only-have-campaign-events')}
              </strong>
            </>
            )}
          </DialogTitle>
          <DialogContent
            dividers
            sx={{
              padding: 0,
              ...(dataRegionEventTypes.length <= 4 && {
                padding: '16px',
              }),
              height: '50vh',
            }}
          >
            <List subheader={dataRegionEventTypes.length > 4 ? (
              <ListSubheader
                sx={{
                  paddingTop: '16px',
                  boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
                }}
                key="region-list-subheader"
              >
                <FormControl component="fieldset" fullWidth>
                  <FormGroup aria-label="position" row sx={{ justifyContent: 'space-between' }}>
                    <TextField
                      label={t('favorites:table-advanced-filter')}
                      id="notification-region-filter"
                      size="small"
                      sx={{
                        flexGrow: '1',
                      }}
                      onChange={(event) => {
                        const { value } = event.target;
                        setFilterStringOfRegionEvents(value);
                      }}
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          color="primary"
                          checked={dataRegionEventTypes.length === selectedRegionEvents.length}
                          onChange={(event) => {
                            if (event.target.checked) {
                              setSelectedRegionEvents(dataRegionEventTypes.map((et) => et.id));
                            } else {
                              setSelectedRegionEvents([]);
                            }
                          }}
                          className="toggle-all-notifications"
                        />
                      )}
                      label={t('favorites:table-advanced-filter-turn-all')}
                      labelPlacement="start"
                      disabled={filterStringOfRegionEvents !== '' || isCountry}
                    />
                  </FormGroup>
                </FormControl>
                <Collapse
                  in={isSuccessMessageOpen}
                  sx={{
                    position: 'absolute',
                    width: 'calc(100% - 32px)',
                    top: '80px',
                    left: '16px',
                    zIndex: '50',
                  }}
                >
                  <Alert variant="filled" severity="success">{t('various:settings-successfully-saved')}</Alert>
                </Collapse>
              </ListSubheader>
            ) : null}
            >
              {dataRegionEventTypes.length <= 4 && (
              <Collapse
                in={isSuccessMessageOpen}
                sx={{
                  position: 'absolute',
                  width: 'calc(100% - 32px)',
                  top: '16px',
                  left: '16px',
                  zIndex: '50',
                }}
              >
                <Alert variant="filled" severity="success">{t('various:settings-successfully-saved')}</Alert>
              </Collapse>
              )}
              {dataRegionEventTypes.filter((notification) => {
                const translatedEventLabel = t(`events:${notification.descriptionLocize}`);
                if (filterStringOfRegionEvents.length === 0) {
                  return true;
                }
                return translatedEventLabel.toLowerCase()
                  .indexOf(filterStringOfRegionEvents.toLowerCase()) !== -1;
              }).map((notification) => (
                <>
                  <ListItem
                    key={`item-region-${notification.id}-${notification.descriptionLocize}`}
                    sx={{ padding: 0, zIndex: 0 }}
                  >
                    <ListItemButton
                      onClick={() => {
                        if (selectedRegionEvents.indexOf(notification.id) !== -1) {
                          setSelectedRegionEvents(selectedRegionEvents
                            .filter((id) => id !== notification.id));
                        } else {
                          setSelectedRegionEvents([...selectedRegionEvents, notification.id]);
                        }
                      }}
                      disabled={!notification.identificator.includes('Campaign') && isCountry}
                    >
                      <ListItemText id={`switch-list-label-region-${notification.id}`} primary={t(`events:${notification.descriptionLocize}`)} />
                      <Checkbox
                        edge="end"
                        inputProps={{
                          'aria-labelledby': `switch-list-label-region-${notification.id}`,
                        }}
                        checked={selectedRegionEvents.indexOf(notification.id) !== -1}
                        className="notification-toggle"
                        sx={{
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider component="li" />
                </>
              ))}
            </List>
          </DialogContent>
        </>
      ));
    }
    return components;
  };

  return (
    <>
      {getContent()}
      {isLoading && (
        <LinearProgress />
      )}
      <DialogActions>
        <Button onClick={(event, data) => {
          onModalClose(event, data);
        }}
        >
          {t('favorites:button-modal-cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={async () => {
            setIsLoading(true);
            await updateNotifications();
            await updateContext();
            setIsLoading(false);
            setIsSuccessMessageOpen(true);
            setTimeout(() => {
              setIsSuccessMessageOpen(false);
            }, 4000);
          }}
        >
          {t('favorites:button-modal-notification-update')}
        </Button>
      </DialogActions>
    </>
  );
}
