import React from 'react';
import env from '../../env/env';

export default React.createContext(
  {
    SEPContext: {
      user: {
        publicJWT: null,
        isUrlParsed: false,
      },
      env,
    },
  },
);
