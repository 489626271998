import React, { Component } from 'react';
 import HyperConsole from "../../hyper_console/hyper-console";
import "./AccountConfirm.css";

import axios from "axios";
import { withTranslation } from 'react-i18next'
import SupportRequest from "../../reusable/SupportRequest";

const URI = require('urijs');
let hycon = new HyperConsole({isEnabled: false, name:__filename}).myConsole;

class AccountConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [
                props.i18n.t("sign_up:confirm-processing-1")
            ]
        };

        hycon.debug(`${this.constructor.name} constructor`, {props: this.props});
    }

    componentDidMount() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} componentDidMount`, {props: this.props});

        let confirmUri = thisRef.props.location.search.split(/\?(.+)/)[1].split(/=(.+)/)[1];

        thisRef.setStateProperty("messages", thisRef.state.messages.concat([thisRef.props.i18n.t("sign_up:confirm-processing-2")])).then(() => {
            return thisRef.confirm(confirmUri).then(() => {
                return thisRef.setStateProperty("messages", thisRef.state.messages.concat([thisRef.props.i18n.t("sign_up:confirm-processing-done")]));
            }).then(() => {
                hycon.debug(`${this.constructor.name} componentDidMount - redirecting`);
                setTimeout(() => {
                    let thisRef = this;
                    const location = {
                        pathname: "/user/signin/",
                        state: {}
                    };
                    thisRef.props.history.push(location);
                }, 3000);
            }).catch((e) => {
                // hycon.error(`${thisRef.constructor.name} componentDidMount - confirm - error`, e);
                return thisRef.setStateProperty("messages", thisRef.state.messages.concat(
                    [
                        `${thisRef.props.i18n.t("sign_up:confirm-processing-done")}<br/> Error: ${e.message}.`
                    ]
                )).then(() => {
                    hycon.error(`${this.constructor.name} componentDidMount - error`, e);
                });
            });
        });


    }


    static getDerivedStateFromProps(props, state) {
        hycon.debug(`${"AccountConfirm"} getDerivedStateFromProps`, {props, state});
        if(!state){
            hycon.debug(`${"AccountConfirm"} getDerivedStateFromProps - only props have been updated`, {props, state});
        }
        return state;
    }

    shouldComponentUpdate(nextProps, nextState) {

        hycon.debug(`${this.constructor.name} shouldComponentUpdate`, {nextProps, props: this.props, nextState});
        return true;
    }

    setStateProperty(propertyName, propertyValue) {
        let thisRef = this;
        return new Promise((res) => {
            thisRef.setState((p) => {
                const obj = {};
                obj[propertyName] = propertyValue;
                return Object.assign({}, p, obj);
            }, () => {
                hycon.debug(`${this.constructor.name} setStateProperty - newState`, {newState: thisRef.state});
                res();
            })
        });
    }

    render() {
        let thisRef = this;
        return (
            <div className={"AccountConfirm"}>
                <h1>{thisRef.props.i18n.t("sign_up:confirm-header")}</h1>
                <h3>
                    <div dangerouslySetInnerHTML={{__html: thisRef.state.messages[thisRef.state.messages.length-1]}}/>
                </h3>
                <SupportRequest {...thisRef.props}/>
            </div>
        );
    }

    parseUrl(confirmUri){
        let thisRef = this;
        hycon.debug(`${this.constructor.name} parseUrl`, {thisRef, confirmUri});
        try {
            let parsedQuery  = URI.parseQuery(confirmUri);
            let parsed = {parsedQuery};
            hycon.debug(`${this.constructor.name} parseUrl parsed`, {parsed});
            return parsed;
        }catch (e) {
            hycon.error(e);
            throw new Error("Confirmation link is broken.");
        }
    }

    confirm(confirmUri){
        let thisRef = this;
        hycon.debug(`${this.constructor.name} confirm`, {thisRef, confirmUri});

        let parsedQuery = thisRef.parseUrl(confirmUri).parsedQuery;
        let token = parsedQuery.token;

        let userid = parsedQuery.userid;
        let confirmEndpoint = Object.keys(parsedQuery)[0];
        hycon.debug(`${this.constructor.name} confirm data`, {confirmEndpoint, token, userid, parsedQuery});


        return new Promise((res, rej) => {
            const endpoint = `${confirmEndpoint}`;
            return axios(
                {
                    method: 'post',
                    url: endpoint,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': "application/json",
                    },
                    data: JSON.stringify({
                        token, userid
                    })
                }
            )
                .then((response) => {
                    hycon.info(`${this.constructor.name} confirm`, {state: thisRef.state});
                    return res(response);
                })
                .catch((error) => {
                    hycon.warn(`${this.constructor.name} confirm error`, {error});
                    return rej(error);
                });
        });
    }

    componentDidUpdate() {

        hycon.debug(`${this.constructor.name} componentDidUpdate`, {props: this.props});
    }

    componentWillUnmount() {

        hycon.debug(`${this.constructor.name} componentWillUnmount`, {props: this.props});
    }

    componentDidCatch(error, errorInfo) {
        let thisRef = this;
        hycon.debug(`${thisRef.constructor.name} componentDidCatch`, {error, errorInfo});
        throw error;
    }
}

export default withTranslation(["sign_up"])(AccountConfirm);

