import Locize from 'i18next-locize-backend';
import env from "../env/env"
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HyperConsole from '../components/hyper_console/hyper-console'
import setupLocales from './moment-setup'
import LanguageDetector from 'i18next-browser-languagedetector';
setupLocales();
const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}
export async function getI18nInstance(client = "sep"){
  hycon.log("getI18nInstance - getting locize for client", client);
  let config = {
    fallbackLng: 'de-CH',
    supportedLngs: [
        'de-CH',
        'it-CH',
        'fr-CH'
    ],
    ns: [
      "documedia",
      "dynamic_panel_demo",
      "dynamic_energysimulation",
      "dynamic_panel_head",
      "dynamic_panel_marketsense",
      "dynamic_panel_marketsense_v4",
      "cookies",
      "home",
      "legacy",
      "menu",
      "settings",
      "sign_in",
      "sign_up",
      "search_bar",
      "notification_map",
      "controls_map",
      "panel_building_info",
      "panel_coming_soon",
      "panel_construction_zone",
      "panel_electricity",
      "panel_demo",
      "panel_preview",
      "panel_solar_electricity",
      "panel_usage",
      "panel_zev",
      "panel_energy_project",
      "panel_innovenergy_batteries",
      "panel_preview",
      "panel_solar_electricity",
      "panel_usage",
      "panel_zev",
      "profile",
      "recover",
      "reusable_autocomplete",
      "search_bar",
      "settings",
      "sign_in",
      "sign_up",
      "various",
      "dashboard",
      "favorites",
      "feedback",
      "events",
      "ewz_solarfourcompany",
      "lead_management"
    ],
    backend: {
      projectId: env.LOCIZE_PROJECT_ID,
      apiKey: env.LOCIZE_API_KEY,
      version: env.LOCIZE_PROJECT_VERSION,
      referenceLng: 'de-CH' // as described in https://docs.locize.com/integration/instrumenting-your-code
    },
    interpolation: {
      escapeValue: false
    },
    // LanguageDetector options
    detection: {
      order: ['navigator'],
    }
  };
  // Each time the user changes the display language,
  // this should also be set in the <html> tag.
  i18n.on('languageChanged', function(lng) {
    document.documentElement.setAttribute("lang", lng);
  });
  if(client === "sep"){
    hycon.log("getI18nInstance - getting locize for client", { client, config });
    await i18n
    .use(LanguageDetector) // or any other implementation
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(Locize)
    .init(config);
    return i18n;
  } else if(client === "praedictio"){
    /* refactoring needed: remove praedictio */
    hycon.log("getI18nInstance - getting locize for client", { client, config });
    // apply locize overrides
    config.backend.version = "praedictio";
    config.backend.apiKey = "d1912090-623d-479c-b76f-75bc563d138e"
    await i18n
    .use(LanguageDetector) // or any other implementation
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(Locize)
    .init(config);
    return i18n;
  }
}






