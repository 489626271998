import React, { useState, useEffect, /*useRef*/ } from 'react'
// import ReactDOMServer from 'react-dom/server'

import HyperConsole from '../hyper_console/hyper-console'
import './Events.css'
import HyperMenu from '../hyper_menu/HyperMenu'
import Navbar from '../../components/sep_map/component_navbar/Navbar'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import env from '../../env/env'
import moment from 'moment'
// import axios from 'axios';
import { Button, Loader, Pagination, Icon/*, Progress*/ } from 'semantic-ui-react'
import { Button as MUIButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import TextField from '@mui/material/TextField'
import * as L from 'leaflet'
import ReactDOMServer from 'react-dom/server'
import * as turf from '@turf/turf'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'develoment') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

function Filter (props) {
  const hookName = 'Filter'
  // const [filter, setFilter] = useState('')
  return (
    <div className={'filter'}>
      <TextField
        style={{ minWidth: '200px' }}
        variant="outlined"
        onChange={(event) => {
          let value = event.target.value
          hycon.debug(`${hookName} onChange`, { event, value, props })
          // setFilter(value)
          props.onFilterChange(value)
        }}
        placeholder={props.i18n.t('favorites:table-advanced-filter')}
        type={'text'}
      />
    </div>
  )
}

export function Events (props) {
  const hookName = 'Events'
  const updateEeventName = 'events-update'
  const maxPerPage = 50
  const eventMacroTypes = {
    ADDRESS: 'ADDRESS',
    REGION: 'REGION'
  }
  const [menu, setMenu] = useState(null)
  const [favorites, setFavorites] = useState([])
  const [filter, setFilter] = useState('')
  const [selectedPage, setSelectedPage] = useState(1)

  const [events, setEvents] = useState([])
  const [completeEvents, setCompleteEvents] = useState([])
  const [filteredEvents, setFilteredEvents] = useState([])
  const [fetchEventsCount, setFetchEventsCount] = useState(0)
  const [isLoadingEvents, setIsLoadingEvents] = useState(false)
  const [loadingEventIdArray, setLoadingEventId] = useState([])

  const getFilteredEvents = (completeEventsArray) => {
    return completeEventsArray.filter((completeEvent, i) => {
      let propsToFilter = `${completeEvent.renderedEvent.innerText}`
      // hycon.debug(`${hookName} getFilteredEvents`, { completeEvent, filter, propsToFilter })
      let filterTokens = filter.toLowerCase().split(' ').map((token) => {
        return token.trim()
      })

      let foundTokens = []
      let notFoundTokens = []
      filterTokens.forEach((token) => {
        let hasToken = propsToFilter.toLowerCase().includes(token)
        if (!hasToken) {
          notFoundTokens.push(token)
        } else {
          foundTokens.push(token)
        }
      })
      // hycon.debug(`${hookName} getFilteredEvents`, { filter, propsToFilter, foundTokens, notFoundTokens })
      return foundTokens.length === filterTokens.length
    })
  }
  const getPaginationElements = (eventsArray) => {
    // hook in the paginator
    let minIndex = (selectedPage - 1) * maxPerPage
    let maxIndex = minIndex + (maxPerPage - 1)

    let newEvents = eventsArray.filter((completeEvent, index) => {
      return index >= minIndex && index <= maxIndex
    })
    hycon.debug(`${hookName} getPaginationElements`, {
      newEvents,
      eventsArray,
      minIndex,
      maxIndex,
      selectedPage,
      maxPerPage
    })
    return newEvents
  }
  const fetchFavorites = async () => {
    let favoritesResponse = await getFavoriteAddresses()
    setFavorites(favoritesResponse.data)
  }
  const fetchEvents = async () => {
    let addressEventsResponse = await getEvents()
    let regionEventsResponse = await getRegionalEvents()
    hycon.debug(`${hookName} fetchEvents`, { addressEventsResponse, regionEventsResponse })
    // setEvents([...addressEventsResponse.data])
    setEvents([...regionEventsResponse.data, ...addressEventsResponse.data])
  }
  const fetchEventTriggers = async () => {
    const jwt = props.reduxState.user.jwt
    const getTriggerByBaseUri = async (event) => {
      let triggerBaseUri = event.triggerBaseUri
      let triggerBaseUriEndpoint = `${triggerBaseUri}?id=${event.triggerId}`
      return axios(
        {
          method: 'get',
          url: triggerBaseUriEndpoint,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`
          }
        }
      )
      .then((response) => {
        return response.data[0]
      })
      .catch((error) => {
        hycon.error(`${hookName} getCompleteEventInfo - trigger`, { error })
      })
    }
    const getTrigger = async (event) => {
      // hycon.info(`${hookName} getTrigger`, { event })
      // make a distinction between new and old events based on triggerObject
      if (event.triggerObject === null) {
        // hycon.info(`${hookName} getTrigger - old`, { event })
        return await getTriggerByBaseUri(event)
      } else {
        try {
          let trigger = JSON.parse(event.triggerObject)
          trigger.id = event.triggerId
          trigger.recordType = event.triggerType
          // hycon.info(`${hookName} getTrigger - new`, { event, trigger })
          return await trigger
        } catch (e) {
          hycon.info(`${hookName} getTrigger - error - new`, { event })
          throw e
        }
      }
    }
    let uniqueEventsByTrigger = events.filter((event, index, events) => {
      return events.map(mapObject => mapObject.triggerId).indexOf(event.triggerId) === index
    })
    // hycon.debug(`${hookName} fetchEventTriggers - uniqueEventByType`, { uniqueEventsByTrigger })
    let promises = uniqueEventsByTrigger.map((event) => getTrigger(event))
    // hycon.debug(`${hookName} fetchEventTriggers - uniqueEventByType`, { uniqueEventsByTrigger, promises })
    let triggers = await Promise.all(promises).then((triggers) => triggers)
    // hycon.debug(`${hookName} fetchEventTriggers - uniqueEventByType`, { uniqueEventsByTrigger, promises, triggers })
    return triggers
  }
  const fetchEventTypes = async () => {
    const jwt = props.reduxState.user.jwt
    const getType = (event) => {
      let eventTypeBaseUri = event.eventTypeBaseUri
      let eventTypeBaseUriEndpoint = `${eventTypeBaseUri}?id=${event.eventTypeId}`
      return axios(
        {
          method: 'get',
          url: eventTypeBaseUriEndpoint,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`
          }
        }
      )
      .then((response) => {
        return response.data[0]
      })
      .catch((error) => {
        hycon.error(`${hookName} getEventInfos - eventTypeBaseUri`, { error })
      })
    }
    let uniqueEventsByType = events.filter((event, index, events) => {
      return events.map(mapObject => mapObject.eventTypeId).indexOf(event.eventTypeId) === index
    })
    // hycon.debug(`${hookName} fetchEventTypes - uniqueEventByType`, { uniqueEventsByType })
    let promises = uniqueEventsByType.map((event) => getType(event))
    // hycon.debug(`${hookName} fetchEventTypes - uniqueEventByType`, { uniqueEventsByType, promises })
    let types = await Promise.all(promises).then((types) => types)
    // hycon.debug(`${hookName} fetchEventTypes - uniqueEventByType`, { uniqueEventsByType, promises, types })
    return types
  }
  const fetchAll = async () => {
    await fetchFavorites()
    await fetchEvents()
    setFetchEventsCount(fetchEventsCount + 1)
  }
  const getCompleteEventInfo = async (event, triggers, types) => {
    const getFavoriteByBaseUri = async (event) => {
      const jwt = props.reduxState.user.jwt
      let favoriteBaseUriEndpoint = `${event.favoriteRegionBaseUri}?id=${event.favoriteRegionId}`
      return await axios(
        {
          method: 'get',
          url: favoriteBaseUriEndpoint,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`
          }
        }
      )
      .then((response) => {
        return response.data[0]
      })
      .catch((error) => {
        hycon.error(`${hookName} getCompleteEventInfo - trigger`, { error })
      })
    }
    const getFavorite = async (event, macroType) => {
      if (macroType === eventMacroTypes.ADDRESS) {
        if (event.addressObject !== null) {
          let favorite = JSON.parse(event.addressObject)
          /*hycon.debug(`${hookName} getCompleteEventInfo - getFavorite (address with addressObject)`, {
            event,
            favorite
          })*/
          return await favorite
        } else {
          const favorite = favorites.filter(favorite => favorite.id === event.favoriteAddressId)[0]
          /*hycon.debug(`${hookName} getCompleteEventInfo - getFavorite (address without addressObject)`, {
            event,
            favorite
          })*/
          return await favorite
        }
      } else if (macroType === eventMacroTypes.REGION) {
        if (event.regionObject !== null) {
          let favorite = JSON.parse(event.regionObject)
          // hycon.debug(`${hookName} getCompleteEventInfo - getFavorite (region with regionObject)`, { event, favorite })
          return await favorite
        } else {
          let favorite = await getFavoriteByBaseUri(event)
          /*hycon.debug(`${hookName} getCompleteEventInfo - getFavorite (region without regionObject)`, {
            event,
            favorite
          })*/
          return await favorite
        }
      }
    }
    const getMacroType = (event) => {
      let macroType
      if (typeof event.favoriteAddressId !== 'undefined') {
        macroType = eventMacroTypes.ADDRESS
      } else if (typeof event.favoriteRegionId !== 'undefined') {
        macroType = eventMacroTypes.REGION
      }
      return macroType
    }
    const getTrigger = async (event) => {
      if (event.triggerObject !== null) {
        let trigger = JSON.parse(event.triggerObject)
        /*hycon.debug(`${hookName} getCompleteEventInfo - getTrigger (with triggerObject)`, {
          event,
          trigger
        })*/
        return await trigger
      } else {
        const trigger = triggers.filter(trigger => trigger.id === event.triggerId)[0]
        /*hycon.debug(`${hookName} getCompleteEventInfo - getTrigger (without triggerObject)`, {
          event,
          trigger
        })*/
        return await trigger
      }
    }
    const trigger = await getTrigger(event)
    const type = types.filter(eventType => eventType.id === event.eventTypeId)[0]
    const macroType = getMacroType(event)
    const favorite = await getFavorite(event, macroType)
/*    hycon.debug(`${hookName} getCompleteEventInfo - all`, {
      event,
      trigger,
      type,
      favorite,
      macroType,
      triggers,
      types
    })*/
    return { event, trigger, type, favorite, macroType }
  }

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - language`, { lang: props.i18n.language, props })
    moment.locale(props.i18n.language.toLowerCase())
    hycon.debug(`${hookName} useEffect - language`, { test: moment().format('LLLL') })
  }, [props.i18n.language, props])

  useEffect(() => {
    // hycon.debug(`${hookName} useEffect - filter`, { fetchEventsCount, isLoadingEvents })
    let filteredEvents = getFilteredEvents(completeEvents)
    setFilteredEvents(filteredEvents)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeEvents, filter])

  useEffect(() => {
    hycon.debug(`${hookName} - useEffect - mount`, { fetchEventsCount, isLoadingEvents })
    fetchAll().catch((e) => {
      hycon.error('could not fetch the needed data', e)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    hycon.debug(`${hookName} - useEffect - SEPContext changed`, { props })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.SEPContext])

  useEffect(() => {
    hycon.debug(`${hookName} - useEffect - events`, {})
    const getCompleteEventInfos = async (eventArray) => {
      let types = await fetchEventTypes()
      let triggers = await fetchEventTriggers()
      hycon.debug(`${hookName} - events changed`, {
        triggers, types
      })
      let completeEvents = []
      for (let i = 0; i < eventArray.length; i++) {
        let event = eventArray[i]
        completeEvents.push(await getCompleteEventInfo(event, triggers, types))
      }
      return completeEvents
    }
    const sortEventsByDate = (completeEvents) => {
      let sortedCompleteEvents = completeEvents
      sortedCompleteEvents.sort((a, b) => {
        // hycon.debug(`${hookName} - sorting`, { a, b })
        let dateA = moment(a.event.lastUpdated)
        let dateB = moment(b.event.lastUpdated)

        if (dateA.isBefore(dateB)) {
          return 1
        }
        if (dateA.isAfter(dateB)) {
          return -1
        } else {
          return 0
        }
      })
      return sortedCompleteEvents
    }
    setIsLoadingEvents(true)
    getCompleteEventInfos(events).then((completeEvents) => {
      hycon.debug(`${hookName} - events changed`, {
        completeEvents
      })
      completeEvents = completeEvents.map((completeEvent, i) => {
        let reactComponent = getEventListComponent(completeEvent, i)
        let elementToFilter = document.createElement('div')
        elementToFilter.innerHTML = ReactDOMServer.renderToString(reactComponent)
        return {
          ...completeEvent,
          renderedEvent: elementToFilter
        }
      })
      setCompleteEvents(sortEventsByDate(completeEvents))
      setIsLoadingEvents(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events])

  // axios
  const getFavoriteAddresses = async () => {
    let env = props.reduxState.env
    const jwt = props.reduxState.user.jwt
    let endpoint = `${env.API_GATEWAY_BASE}/api/favoriteaddresses`
    let data = {}
    hycon.debug(`${hookName} addFavorites`, {})
    return await axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${hookName} getFavoriteAddresses`, { error })
    })
  }

  const deleteEvent = async (completeEvent) => {
    let env = props.reduxState.env
    const jwt = props.reduxState.user.jwt

    let endpoint
    if (completeEvent.macroType === eventMacroTypes.ADDRESS) {
      endpoint = `${env.API_GATEWAY_BASE}/api/eventsforaddresses/${completeEvent.event.id}`
    } else if (completeEvent.macroType === eventMacroTypes.REGION) {
      endpoint = `${env.API_GATEWAY_BASE}/api/eventsforregions/${completeEvent.event.id}`
    }

    let data = {}
    hycon.debug(`${hookName} deleteEvent ${completeEvent.macroType}`, {})
    return await axios(
      {
        method: 'delete',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${hookName} deleteEvent ${completeEvent.macroType}`, { error })
    })
  }

  const deleteAllEventsForMandant = async () => {
    let env = props.reduxState.env
    const jwt = props.reduxState.user.jwt
    let endpointAddressDelete = `${env.API_GATEWAY_BASE}/api/eventsforaddresses/`
    let data = {}
    hycon.debug(`${hookName} deleteEvent`, {})
    let addressDeleteResponse = await axios(
      {
        method: 'delete',
        url: endpointAddressDelete,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      hycon.debug(`${hookName} deleteAllEventsForMandant`, { response })
      return response
    })
    .catch((error) => {
      hycon.debug(`${hookName} deleteAllEventsForMandant`, { error })
    })
    let endpointRegionDelete = `${env.API_GATEWAY_BASE}/api/eventsforregions/`
    let regionDeleteResponse = await axios(
      {
        method: 'delete',
        url: endpointRegionDelete,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      hycon.debug(`${hookName} deleteAllEventsForMandant`, { response })
      return response
    })
    .catch((error) => {
      hycon.debug(`${hookName} deleteAllEventsForMandant`, { error })
    })
    return { addressDeleteResponse, regionDeleteResponse }
  }

  const getEvents = async () => {
    let env = props.reduxState.env
    const jwt = props.reduxState.user.jwt
    let endpoint = `${env.API_GATEWAY_BASE}/api/eventsforaddresses`
    /*let data = {}*/
    hycon.debug(`${hookName} getEvents`, {})
    return await axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }/*,
        data: JSON.stringify(data)*/
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${hookName} getEvents`, { error })
    })
  }

  const getRegionalEvents = async () => {
    let env = props.reduxState.env
    const jwt = props.reduxState.user.jwt
    let endpoint = `${env.API_GATEWAY_BASE}/api/eventsforregions`
    return await axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }/*,
        data: JSON.stringify(data)*/
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${hookName} getRegionalEvents`, { error })
    })
  }

  const getEventListComponentDetail = (completeEvent) => {
    let trigger = completeEvent.trigger
    let event = completeEvent.event
    let dynamicObject = completeEvent.favorite
    let macroType = completeEvent.macroType

    // hycon.debug(`${hookName} getEventListComponentDetail`, { event, trigger, dynamicObject, macroType })
    let eventImageUrl
    let recordInfo
    const getEventShell = (content) => {
      return (
        <div className={'container'}>
          <div className={'column icon'}>
            <div
              className={'image'}
              style={{
                backgroundImage: `url('${eventImageUrl}')`
              }}
            />
          </div>
          <div className={'column data'}>
            {content}
            <div className={'event-release'}>
              {event.userSpecific ? (
                <div>
                  {props.i18n.t(`events:event-released-by`)}&nbsp;
                  <a href={`mailto:${event.userOrigin}`}>{event.userOrigin}</a>&nbsp;
                  {props.i18n.t(`events:event-released-by-tenant`)} {event.mandantOrigin}&nbsp;
                  {props.i18n.t(`events:event-released-for`)}&nbsp;
                  <a href={`mailto:${event.user}`}>{event.user}</a>
                </div>
              ) : null}
            </div>
          </div>
          <div className={'recipient'} style={{ display: 'none' }}>{JSON.stringify(event.emailRecipient) || ''}</div>
        </div>
      )
    }
    const isRegionEvent = macroType === eventMacroTypes.REGION;
    const isCampaignEvent = (dynamicObject) => {
      return isRegionEvent && (
          dynamicObject.swissZipCode === null
          && dynamicObject.town === null
          && dynamicObject.campaign !== undefined
          && dynamicObject.campaign !== null
      )
    }
    const getTriggeredByComponents = (detail) => {
      if(isCampaignEvent(detail.dynamicObject)){
        return (
            <>
              {props.i18n.t('events:message-ausgeloest-durch')}&nbsp;
              {props.i18n.t(`events:event-type-campaign`)}:&nbsp;
            </>
        )
      } else {
        return (
            <>
              {props.i18n.t('events:message-ausgeloest-durch')}&nbsp;
              {props.i18n.t(`events:event-type-${macroType.toLowerCase()}`)}:&nbsp;
            </>
        )
      }
    }
    if (macroType === eventMacroTypes.ADDRESS) {
      if (event.triggerType === 'record_type1') {
        // Bauvorhaben
        eventImageUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Baugesuche.png`
        let translatedRecordTitle = props.i18n.t(`panel_construction_zone:${trigger.recordTitle}`);
        let originalRecordTitle = trigger.recordTitle;
        let recordTitle = translatedRecordTitle !== `panel_construction_zone:${originalRecordTitle}` ? translatedRecordTitle : originalRecordTitle;
        recordInfo = trigger.recordInfo;
        let content = (
          <div className={'event-content'}>
            <div>{props.i18n.t(`events:${event.triggerType}`)}</div>
            <h4>{recordTitle}</h4>
            {(() => {
              if (recordInfo) {
                return <div>{recordInfo}</div>
              }
            })()}
            <div>{props.i18n.t(`events:${event.statusTextLocize}`)}</div>
            <div>
              {props.i18n.t('events:message-ausgeloest-durch')}&nbsp;
              {props.i18n.t(`events:event-type-${macroType.toLowerCase()}`)}:&nbsp;
              {`${dynamicObject.street} ${dynamicObject.houseNumber}, ${dynamicObject.swissZipCode} ${dynamicObject.town}`}
            </div>
            <div>{moment(event.lastUpdated).format('LLLL')}</div>
          </div>
        )
        return getEventShell(content)
      } else if (event.triggerType === 'record_type2') {
        eventImageUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Gebaeudenutzung.png`
        let translatedRecordTitle = props.i18n.t(`panel_usage:${trigger.recordTitle}`);
        let originalRecordTitle = trigger.recordTitle;
        let recordTitle = translatedRecordTitle !== `panel_usage:${originalRecordTitle}` ? translatedRecordTitle : originalRecordTitle;
        recordInfo = trigger.recordInfo;
        let content = (
          <div className={'event-content'}>
            <div>{props.i18n.t(`events:${event.triggerType}`)}</div>
            <h4>{recordTitle}</h4>
            {(() => {
              if (recordInfo) {
                return <div>{recordInfo}</div>
              }
            })()}
            <div>{props.i18n.t(`events:${event.statusTextLocize}`)}</div>
            <div>
              {props.i18n.t('events:message-ausgeloest-durch')}&nbsp;
              {props.i18n.t(`events:event-type-${macroType.toLowerCase()}`)}:&nbsp;
              {`${dynamicObject.street} ${dynamicObject.houseNumber}, ${dynamicObject.swissZipCode} ${dynamicObject.town}`}
            </div>
            <div>{moment(event.lastUpdated).format('LLLL')}</div>
          </div>
        )
        return getEventShell(content)
      } else if (event.triggerType === 'record_type3') {
        eventImageUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Gebaeudeinfo.png`
        let translatedRecordTitle = props.i18n.t(`panel_usage:${trigger.recordTitle}`);
        let originalRecordTitle = trigger.recordTitle;
        let recordTitle = translatedRecordTitle !== `panel_usage:${originalRecordTitle}` ? translatedRecordTitle : originalRecordTitle;
        recordInfo = trigger.recordInfo;
        let content = (
          <div className={'event-content'}>
            <div>{props.i18n.t(`events:${event.triggerType}`)}</div>
            <h4>{recordTitle}</h4>
            {(() => {
              if (recordInfo) {
                return <div>{recordInfo}</div>
              }
            })()}
            <div>{props.i18n.t(`events:${event.statusTextLocize}`)}</div>
            <div>
              {getTriggeredByComponents({event, dynamicObject, trigger})}
              {`${dynamicObject.street} ${dynamicObject.houseNumber}, ${dynamicObject.swissZipCode} ${dynamicObject.town}`}
            </div>
            <div>{moment(event.lastUpdated).format('LLLL')}</div>
          </div>
        )
        return getEventShell(content)
      } else if(event.triggerType === 'record_type5'){
        eventImageUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Parzelle.png`
        let translatedRecordTitle = props.i18n.t(`panel_construction_zone:${trigger.recordTitle}`);
        let originalRecordTitle = trigger.recordTitle;
        let recordTitle = translatedRecordTitle !== `panel_construction_zone:${originalRecordTitle}` ? translatedRecordTitle : originalRecordTitle;
        recordInfo = trigger.recordInfo;
        let content = (
          <div className={'event-content'}>
            <div>{props.i18n.t(`events:${event.triggerType}`)}</div>
            <h4>{recordTitle}</h4>
            {(() => {
              if (recordInfo) {
                return <div>{recordInfo}</div>
              }
            })()}
            <div>{props.i18n.t(`events:${event.statusTextLocize}`)}</div>
            <div>
              {getTriggeredByComponents({event, dynamicObject, trigger})}
              {`${dynamicObject.street} ${dynamicObject.houseNumber}, ${dynamicObject.swissZipCode} ${dynamicObject.town}`}
            </div>
            <div>{moment(event.lastUpdated).format('LLLL')}</div>
          </div>
        )
        return getEventShell(content)
      }
      hycon.debug(`${hookName} - new uncategorized address event to display`, { event, trigger, dynamicObject });
    } else if (macroType === eventMacroTypes.REGION) {
      if (event.triggerType === 'record_type1') {// Bauvorhaben
        eventImageUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Baugesuche.png`
        let translatedRecordTitle = props.i18n.t(`panel_construction_zone:${trigger.recordTitle}`);
        let originalRecordTitle = trigger.recordTitle;
        let recordTitle = translatedRecordTitle !== `panel_construction_zone:${originalRecordTitle}` ? translatedRecordTitle : originalRecordTitle;
        recordInfo = trigger.recordInfo;
        let content = (
          <div className={'event-content'}>
            <div>{props.i18n.t(`events:${event.triggerType}`)}</div>
            <h4>{recordTitle}</h4>
            {(() => {
              if (recordInfo) {
                return <div>{recordInfo}</div>
              }
            })()}
            <div>{props.i18n.t(`events:${event.statusTextLocize}`)}</div>
            <div>
              {getTriggeredByComponents({event, dynamicObject, trigger})}
              {isCampaignEvent(dynamicObject) ? dynamicObject.campaign : dynamicObject.name}
            </div>
            <div>{moment(event.lastUpdated).format('LLLL')}</div>
          </div>
        )
        return getEventShell(content)
      } else if (event.triggerType === 'record_type2') {
        eventImageUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Gebaeudenutzung.png`
        let translatedRecordTitle = props.i18n.t(`panel_usage:${trigger.recordTitle}`);
        let originalRecordTitle = trigger.recordTitle;
        let recordTitle = translatedRecordTitle !== `panel_usage:${originalRecordTitle}` ? translatedRecordTitle : originalRecordTitle;
        recordInfo = trigger.recordInfo;
        let content = (
          <div className={'event-content'}>
            <div>{props.i18n.t(`events:${event.triggerType}`)}</div>
            <h4>{recordTitle}</h4>
            {(() => {
              if (recordInfo) {
                return <div>{recordInfo}</div>
              }
            })()}
            <div>{props.i18n.t(`events:${event.statusTextLocize}`)}</div>
            <div>
              {getTriggeredByComponents({event, dynamicObject, trigger})}
              {isCampaignEvent(dynamicObject) ? dynamicObject.campaign : dynamicObject.name}
            </div>
            <div>{moment(event.lastUpdated).format('LLLL')}</div>
          </div>
        )
        return getEventShell(content)
      } else if (event.triggerType === 'record_type3') {// Address
        eventImageUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Gebaeudeinfo.png`
        let translatedRecordTitle = props.i18n.t(`panel_usage:${trigger.recordTitle}`);
        let originalRecordTitle = trigger.recordTitle;
        let recordTitle = translatedRecordTitle !== `panel_usage:${originalRecordTitle}` ? translatedRecordTitle : originalRecordTitle;
        recordInfo = trigger.recordInfo;
        // hycon.debug(`${hookName} getEventListComponentDetail - debug`, { event, trigger, dynamicObject })
        let content = (
          <div className={'event-content'}>
            <div>{props.i18n.t(`events:${event.triggerType}`)}</div>
            <h4>{recordTitle}</h4>
            {(() => {
              if (recordInfo) {
                return <div>{recordInfo}</div>
              }
            })()}
            <div>{props.i18n.t(`events:${event.statusTextLocize}`)}</div>
            <div>
              {getTriggeredByComponents({event, dynamicObject, trigger})}
              {isCampaignEvent(dynamicObject) ? dynamicObject.campaign : dynamicObject.name}
            </div>
            <div>{moment(event.lastUpdated).format('LLLL')}</div>
          </div>
        )
        return getEventShell(content)
      } else if (event.triggerType === 'record_type4') {// Address
        eventImageUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/marketsense/v_2/MarketSense_dashboard.png`
        let translatedRecordTitle = props.i18n.t(`panel_usage:${trigger.recordTitle}`);
        let originalRecordTitle = trigger.recordTitle;
        let recordTitle = translatedRecordTitle !== `panel_usage:${originalRecordTitle}` ? translatedRecordTitle : originalRecordTitle;
        recordInfo = trigger.recordInfo;
        // hycon.debug(`${hookName} getEventListComponentDetail - debug`, { event, trigger, dynamicObject })
        let content = (
          <div className={'event-content'}>
            <div>{props.i18n.t(`events:${event.triggerType}`)}</div>
            <h4>{recordTitle}</h4>
            {(() => {
              if (recordInfo) {
                return <div>{recordInfo}</div>
              }
            })()}
            <div>{props.i18n.t(`events:${event.statusTextLocize}`)}</div>
            <div>
              {getTriggeredByComponents({event, dynamicObject, trigger})}
              {isCampaignEvent(dynamicObject) ? dynamicObject.campaign : dynamicObject.name}
            </div>
            <div>{moment(event.lastUpdated).format('LLLL')}</div>
          </div>
        )
        return getEventShell(content)
      } else if(event.triggerType === 'record_type5'){
        eventImageUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Parzelle.png`
        let translatedRecordTitle = props.i18n.t(`panel_construction_zone:${trigger.recordTitle}`);
        let originalRecordTitle = trigger.recordTitle;
        let recordTitle = translatedRecordTitle !== `panel_construction_zone:${originalRecordTitle}` ? translatedRecordTitle : originalRecordTitle;
        recordInfo = trigger.recordInfo;
        let content = (
          <div className={'event-content'}>
            <div>{props.i18n.t(`events:${event.triggerType}`)}</div>
            <h4>{recordTitle}</h4>
            {(() => {
              if (recordInfo) {
                return <div>{recordInfo}</div>
              }
            })()}
            <div>{props.i18n.t(`events:${event.statusTextLocize}`)}</div>
            <div>
              {getTriggeredByComponents({event, dynamicObject, trigger})}
              {isCampaignEvent(dynamicObject) ? dynamicObject.campaign : dynamicObject.name}
            </div>
            <div>{moment(event.lastUpdated).format('LLLL')}</div>
          </div>
        )
        return getEventShell(content)
      }
      hycon.debug(`${hookName} - new uncategorized region event to display`, { event, trigger, dynamicObject });
      return null
    }
  }

  // util
  const dispatchUpdateEvent = async () => {
    const event = new Event(updateEeventName)
    // Dispatch the event.
    document.body.dispatchEvent(event)
  }

  const getMapLink = (event) => {
    try {
      let points = []
      let point = {
        lat: event.lat,
        lng: event.long
      }
      points.push(point)

      /*let selectionGeoJSON = {
        'type': 'FeatureCollection',
        'features': [{
          'type': 'Feature', // single feature
          'properties': {},
          'geometry': { // unique geometry member
            'type': 'GeometryCollection', // the geometry can be a GeometryCollection
            'geometries': points.map((point) => {
              return { // each array item is a geometry object
                'type': 'Point',
                'coordinates': [
                  point.lng,
                  point.lat
                ]
              }
            })
          }
        }]
      }*/
      // let geoJSON = L.geoJSON(selectionGeoJSON)

      let selectionPoint = turf.point([point.lng, point.lat]);
      let bufferedSelectionPoint = turf.buffer(selectionPoint, 0.200, {units: 'kilometers'});
      let geoJSON = L.geoJSON(bufferedSelectionPoint)

      hycon.debug(`${hookName} circleBuffer - debug`, { event, selectionPoint, bufferedSelectionPoint, geoJSON})

      let state = {
        mapState: {
          selectedBaseLayer: {
            name: 'MAP_SWISSTOPO_GREY_URL'
          },
          selection: {
            points: points
          },
          bounds: {
            _southWest: geoJSON.getBounds()._southWest,
            _northEast: geoJSON.getBounds()._northEast
          }
        }
      }
      return `/map/?state=${JSON.stringify(state)}`
    } catch (e) {
      hycon.warn(e)
      throw new Error(`Can't obtain share link. Reason: ${e.message}`)
    }
  }

  const getEventListComponent = (completeEvent, i = 0) => {
    let isEventLoading = loadingEventIdArray.includes(completeEvent.event.id)
    return (
      <div className={'event'} key={i}>
        <div className={'column info event-col'}>
          <div className={'event-data'}>
            {getEventListComponentDetail(completeEvent)}
          </div>
        </div>
        <div className={'action-container event-col'}>
          <div className={'column action'}>
            <Button
              loading={isEventLoading}
              onClick={() => {
                hycon.debug(`${hookName} onClick`)
                let link = getMapLink(completeEvent.event)
                hycon.debug(`${hookName} onClick`, { link })
                props.history.push(link)
              }}
            >
              <i
                className="fas fa-map-marker-alt"
              />
            </Button>
          </div>
          <div className={'column action'}>
            <Button
              loading={isEventLoading}
              onClick={async () => {
                hycon.debug(`${hookName} onClick`)
                setLoadingEventId(loadingEventIdArray.concat([completeEvent.event.id]))
                await deleteEvent(completeEvent)
                await fetchAll()
                // await fetchEvents(false)
                await dispatchUpdateEvent()
                setLoadingEventId(loadingEventIdArray.filter((id) => id !== completeEvent.event.id))
              }}
            >
              <i
                className="fas fa-trash"
              />
            </Button>
          </div>
        </div>
      </div>
    )
  }

  const getEventsList = () => {
    hycon.debug(`${hookName} getEventList`, { events, fetchEventsCount, isLoadingEvents })
    if (
      !isLoadingEvents
      && fetchEventsCount > 0
      && completeEvents.length === 0
    ) {
      return (
        <div className={'event'}>
          {props.i18n.t('events:table-message-no-events')}
        </div>
      )
    }
    if (
      isLoadingEvents
    ) {
      return (
        <React.Fragment>
          <Loader indeterminate/>
        </React.Fragment>
      )
    }

    let paginationEvents = getPaginationElements(filteredEvents)
    hycon.debug(`${hookName} - getEventsList`, { completeEvents, filteredEvents, paginationEvents })

    let eventComponents = paginationEvents.map((completeEvent, i) => {
      return getEventListComponent(completeEvent, i)
    })
    return eventComponents
  }
  const getContent = () => {
    if (
      props.reduxState
      && props.reduxState.session
      && props.reduxState.session.user
      && props.reduxState.session.user.mandant === 'guest'
      && props.reduxState.session.user.role === 'Guest'
    ) {
      return (
        <div>
          <h5 style={{ textAlign: 'center', marginTop: '15px' }}>{props.i18n.t(`events:license-needed`)}</h5>
        </div>
      )
    } else {
      return (
        <div className={'events-container-absolute'}>
          <div className={'page-title'}>
            <h1>{props.t('events:button-my-events')}</h1>
          </div>
          <div className={'controls'}>
            <div className={'advanced'}>
              <div className={'column filter'}>
                <Filter
                  {...props}
                  onFilterChange={async (data) => {
                    hycon.debug(`${hookName} onFilterChange - ok`, { data })
                    setFilter(data)
                    setSelectedPage(1)
                  }}
                />
              </div>
              <div className={'column delete'}>
                <MUIButton
                  variant="contained"
                  color={'primary'}
                  style={{ background: 'red' }}
                  startIcon={<DeleteIcon/>}
                  onClick={async () => {
                    let userWantsToDelete = window.confirm(props.i18n.t('events:table-advanced-delete-all-events-are-you-sure'))
                    if (userWantsToDelete) {
                      hycon.debug(`${hookName} onClick`, { userWantsToDelete })
                      let deleteAllResponseValues = await deleteAllEventsForMandant()
                      hycon.debug(`${hookName} onClick - deleteAllResponseValues`, {
                        userWantsToDelete,
                        deleteAllResponseValues
                      })
                      await fetchAll()
                      await dispatchUpdateEvent()
                    }
                  }}
                >
                  {props.i18n.t('favorites:table-advanced-delete-all')}
                </MUIButton>
              </div>
            </div>
          </div>

          <div className={'controls paginator'}>
            <div className={'advanced'}>
              <div className={'pagination-container'}>
                <Pagination
                  activePage={selectedPage}
                  siblingRange={1}
                  ellipsisItem={{ content: <Icon name='ellipsis horizontal'/>, icon: true }}
                  firstItem={{ content: <Icon name='angle double left'/>, icon: true }}
                  lastItem={{ content: <Icon name='angle double right'/>, icon: true }}
                  prevItem={{ content: <Icon name='angle left'/>, icon: true }}
                  nextItem={{ content: <Icon name='angle right'/>, icon: true }}
                  totalPages={Math.ceil(filteredEvents.length / maxPerPage)}
                  onPageChange={(event, data) => {
                    hycon.debug(`${hookName} onPageChange`, { data })
                    setSelectedPage(data.activePage)
                    // scroll up
                    document.querySelectorAll('.event-list')[0].scrollTop = 0
                    // smooth scroll example
                    // document.querySelectorAll(".event-list")[0].scrollTo({ top: 0, behavior: 'smooth' })
                  }}
                />
              </div>
            </div>
          </div>
          {/*<div className={'controls event-progressbar'}>
                  <Progress
                    style={{ width: '100%', margin: '5px' }}
                    size={'tiny'}
                    percent={(completeEvents.length / events.length) * 100}
                    indicating={(() => {
                      if (completeEvents.length === events.length) {
                        return false
                      } else {
                        return true
                      }
                    })()}
                    autoSuccess={true}
                  />
                </div>*/}
          <div className={'event-list'}>
            {getEventsList()}
          </div>
        </div>
      )
    }
  }
  return (
    <div className="favorites">
      <HyperMenu
        {...props}
        ref={(c) => {
          setMenu(c)
        }}
        content={(
          <div className={`menu-content-wrapper`}>
            <Navbar
              {...props}
              onMenuToggle={() => {
                menu.setStateProperty('isOpen', !menu.getState().isOpen)
              }}
              isSearchDisplayed={false}
            />
            <div className={'events-container'}>
              {getContent()}
            </div>
          </div>
        )}
      />
    </div>
  )
}

export default withTranslation(['favorites', 'events', 'panel_construction_zone', 'panel_usage'])(Events)

