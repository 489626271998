import React, {Component, useCallback, useEffect, useState} from 'react'
import HyperConsole from '../../../../hyper_console/hyper-console'
import ReactDOM from 'react-dom'
import { UserUtil } from '../../../../user_management/util/user-util'
import { useTranslation } from 'react-i18next'
import {
  Loader,
  Dimmer,
  Table
} from 'semantic-ui-react'

import {Button as MUIButton} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close'

import './Elcom.css'

import axios from 'axios'
import { GoogleAnalytics } from '../../../../google_analytics/GoogleAnalytics'
import moment, { fn } from 'moment'
import { withTranslation } from 'react-i18next'
import { Accordion as MUIAccordion } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Table as MUITable } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { jsx, css } from '@emotion/react'
import {WixUtil} from "../../../../../util/wix-util";
const emotionCss = css`
  margin-top: 0px;
  margin-bottom: 0px;
  .MuiAccordionSummary-content.Mui-expanded {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

const RENDER_OVERVIEW = 'RENDER_OVERVIEW'
const RENDER_DETAIL = 'RENDER_DETAIL'
const RENDER_SETTINGS = 'RENDER_SETTINGS'

const round = (input) => {
  try {
    return Math.round(input * 10) / 10
  } catch (e) {
    hycon.warn(e)
    return 'Loading...'
  }
}

export class Detail extends Component {
  constructor (props) {
    super(props)
    this.state = {}

    hycon.debug(`${this.constructor.name} constructor`, { props: this.props })
  }

  componentDidMount () {

    hycon.debug(`${this.constructor.name} componentDidMount`, { props: this.props })
  }

  static getDerivedStateFromProps (props, state) {
    hycon.debug(`${'Detail'} getDerivedStateFromProps`, { props, state })
    if (!state) {
      hycon.debug(`${'Detail'} getDerivedStateFromProps - only props have been updated`, { props, state })
    }
    return state
  }

  shouldComponentUpdate (nextProps, nextState) {

    hycon.debug(`${this.constructor.name} shouldComponentUpdate`, { nextProps, props: this.props, nextState })
    return true
  }

  render () {
    let thisRef = this
    return (
      <div className="Detail">
        <div>
          {(()=>{
            if(thisRef.props.reduxState.selectedBuildings.length > 0){
              return (
                <OperatorList
                  {...thisRef.props}
                  buildingsData={thisRef.props.buildingsData || []}
                />
              )
            } else {
              return (
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                  {thisRef.props.t(`panel_electricity:label-help-text`)}
                </div>
              )
            }
          })()}
        </div>
      </div>
    )
  }

  componentDidUpdate () {
    hycon.debug(`${this.constructor.name} componentDidUpdate`, { props: this.props })
  }

  componentWillUnmount () {

    hycon.debug(`${this.constructor.name} componentWillUnmount`, { props: this.props })
  }
}

class Elcom extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {
        elcom: null
      },
      isMounted: false,
      isLoading: false,
      rendering: RENDER_OVERVIEW
    }
    this.setIsLoading = this.setIsLoading.bind(this)
    hycon.debug(`${this.constructor.name} constructor`, { props: this.props })
  }

  componentDidMount () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} componentDidMount`, { props: this.props })
    thisRef.setStateProperty('isMounted', true);
    // update the data and state
    return thisRef.setStateProperty('isLoading', true).then(() => {
      return thisRef.update().then((elcomData) => {
        hycon.debug(`${thisRef.constructor.name} componentDidUpdate - elcomData`, { elcomData })
        let data = Object.assign({}, thisRef.state.data)
        data.elcom = elcomData
        return thisRef.setStateProperty('data', data)
      })
    }).then(() => {
      return thisRef.setStateProperty('isLoading', false)
    }).catch(() => {
      return thisRef.setStateProperty('isLoading', false)
    })
  }

  static getDerivedStateFromProps (props, state) {
    hycon.debug(`Card getDerivedStateFromProps`, { props, state })
    if (!state) {
      hycon.debug(`Card getDerivedStateFromProps - only props have been updated`, { props, state })
    }
    return state
  }

  shouldComponentUpdate (nextProps, nextState) {

    hycon.debug(`${this.constructor.name} shouldComponentUpdate`, { nextProps, props: this.props, nextState })
    return true
  }

  async getDistrictByPoint (lng, lat) {
    let thisRef = this
    let props = thisRef.props
    let hookName = thisRef.constructor.name
    let env = props.reduxState.env
    const jwt = props.reduxState.user.jwt
    let endpoint = `${env.API_GATEWAY_BASE}/api/district-by-point?x=${lng}&y=${lat}&srid=4326`
    let data = {}
    hycon.debug(`${hookName} getDistrictByPoint`, {})
    return await axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${hookName} getDistrictByPoint`, { error })
    })
  }

  async getElcom (bfsNummer, year) {
    let thisRef = this
    let props = thisRef.props
    let hookName = thisRef.constructor.name
    let env = props.reduxState.env
    const jwt = props.reduxState.user.jwt
    let endpoint = `${env.API_GATEWAY_BASE}/api/elcom/${bfsNummer}?year=${year}`
    let data = {}
    hycon.debug(`${hookName} getElcom`, {})
    return await axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      hycon.debug(`${hookName} getElcom - response`, { response, bfsNummer, year })
      return response
    })
    .catch((error) => {
      hycon.debug(`${hookName} getElcom`, { error })
      return null
    })
  }

  getOverview () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} getOverview`, { props: this.props })
    return <OverviewAverage {...thisRef.props} buildingsData={thisRef.state.buildingsData} />
  }

  /*getDetailView(detailComponent) {
      hycon.debug(`${this.constructor.name} getDetailView`);
      return <div className={`detail`}>{detailComponent}</div>;
  }*/

  setStateProperty (propertyName, propertyValue) {
    let thisRef = this
    return new Promise((res) => {
      thisRef.setState((p) => {
        const obj = {}
        obj[propertyName] = propertyValue
        return Object.assign({}, p, obj)
      }, () => {
        hycon.debug(`${this.constructor.name} setStateProperty - newState`, { newState: thisRef.state })
        res()
      })
    })
  }

  setIsLoading (isLoading) {
    hycon.debug(`${this.constructor.name} setIsLoading`, { isLoading })
    let thisRef = this
    return thisRef.setStateProperty('isLoading', isLoading)
  }

  renderState () {
    let thisRef = this
    let rendering = thisRef.state.rendering
    let dashboardPortal = document.getElementById(`dashboard-portal`)
    if (!thisRef.state.isMounted) return null
    let lang = thisRef.props.i18n.language

    let getString = (i18nextKey) => {
      return thisRef.props.i18n.t(i18nextKey)
    }

    if (rendering === RENDER_OVERVIEW) {
      return (
        <div className={`Card Elcom`}>
          <div className={'controls'}>
            <h4 className={'title'}>{thisRef.props.title}</h4>
            <MUIButton
              onClick={() => {
                let id = thisRef.props.id
                hycon.debug('Clicked card info ' + id)
                window.open(`${WixUtil.getLangBaseUrl(lang.toLowerCase())}/strom`, '_blank');
              }}
            >
              <InfoIcon/>
            </MUIButton>
          </div>
          <div
            className={'body'}
            onClick={() => {
              let id = thisRef.props.id
              hycon.debug('Clicked card detail' + id)
              GoogleAnalytics.sendGenerigEvent('panel-detail-strom', 'sep-panel-detail', 'Panel Interaction', true)
              thisRef.setStateProperty('rendering', RENDER_DETAIL)
              Promise.all([
                thisRef.props.setParentState('isPortalVisible', true),
                thisRef.props.setParentState('isTilesVisible', false)
              ])
              const buildingSelectionEvent = new CustomEvent('kibana-event', { detail: {
                  type: "panel-detail",
                  eventInfo: {
                    info: "Electricity panel has been opened."
                  }
                }
              });
              window.dispatchEvent(buildingSelectionEvent);
            }}
          >
            {thisRef.getOverview()}
            {
              thisRef.state.isLoading ? (
                <Dimmer active>
                  <Loader size='massive'>Loading {thisRef.props.title}</Loader>
                </Dimmer>
              ) : null
            }
          </div>
        </div>
      )
    } else if (rendering === RENDER_DETAIL) {
      let detail = (
        <div className={`Card Elcom`}>
          <div className={'controls'}>
            <h4 className={'title'}>{getString('dashboard:toolbar-details-label')} {thisRef.props.title}</h4>
            <MUIButton
              onClick={() => {
                let id = thisRef.props.id
                hycon.debug('Clicked card close ' + id)
                thisRef.setStateProperty('rendering', RENDER_OVERVIEW)
                Promise.all([
                  thisRef.props.setParentState('isPortalVisible', false),
                  thisRef.props.setParentState('isTilesVisible', true)
                ])
              }}
            >
              <CloseIcon/>
            </MUIButton>
          </div>
          <div
            className={'body'}
            onClick={() => {
              // let id = thisRef.props.id;
              // hycon.debug("Clicked card " + id);
            }}
          >
            <div className={'detail'}>
              <Detail
                ctx={thisRef}
                {...thisRef.props}
                buildingsData={thisRef.state.buildingsData}
              />
            </div>
            {
              thisRef.state.isLoading ? (
                <Dimmer active>
                  <Loader size='massive'>Loading {thisRef.props.title}</Loader>
                </Dimmer>
              ) : null
            }
          </div>
        </div>
      )
      return ReactDOM.createPortal(detail, dashboardPortal)
    } else if (rendering === RENDER_SETTINGS) {
      let settings = (
        <div className={`Card Elcom`}>
          <div className={'controls'}>
            <h4 className={'title'}>{getString('dashboard:toolbar-settings-label')} {thisRef.props.title}</h4>
            <MUIButton
              onClick={() => {
                let id = thisRef.props.id
                hycon.debug('Clicked card close ' + id)
                thisRef.setStateProperty('rendering', RENDER_OVERVIEW)
                thisRef.props.setParentState('isPortalVisible', false)
                thisRef.props.setParentState('isTilesVisible', true)
              }}
            >
              <CloseIcon/>
            </MUIButton>
          </div>
          <div
            className={'body'}
            onClick={() => {
              // let id = thisRef.props.id;
              // hycon.debug("Clicked card " + id);
            }}
          >
            <div className={'settings'}>
              <div>Dieses Panel bietet keine Einstellungen.</div>
            </div>
            {
              thisRef.state.isLoading ? (
                <Dimmer active>
                  <Loader size='massive'>Loading {thisRef.props.title}</Loader>
                </Dimmer>
              ) : null
            }
          </div>
        </div>
      )
      return ReactDOM.createPortal(settings, dashboardPortal)
    } else {
      throw new Error('unconsistent rendering state')
    }
  }

  async getGWR (buildingIdSep) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} getGWR`, buildingIdSep)
    const jwt = thisRef.props.reduxState.user.jwt

    let endpoint = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/api/gwrbuildings?buildingidsep=[in]${buildingIdSep}`
    return await axios({
      method: 'get',
      url: endpoint,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${jwt}`,
      }
    })
    .then(function (response) {
      hycon.debug(`${thisRef.constructor.name} getGWRRequest`, response)
      return response
    })
    .catch((e) => {
      hycon.error(`${thisRef.constructor.name} getGWRRequest`, e)
      UserUtil.authenticationRedirect(e.response, thisRef)
    })
  }

  getBuildingsData (buildings) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} getBuildingsData`, {
      buildings
    })

    let pvReq = (url) => {
      hycon.debug(`${thisRef.constructor.name} elcomReq - pvReq`, { url })
      let jwt = thisRef.props.reduxState.user.jwt
      return axios({
        method: 'get',
        url: url,
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        }
      })
      .then(function (response) {
        hycon.debug(`${thisRef.constructor.name} getBuildingsData - pvReq - ok`)
        return response
      })
      .catch((e) => {
        hycon.debug(`${thisRef.constructor.name} getBuildingsData - pvReq - error`, { e })
        let response = { data: {} }
        hycon.debug(`${thisRef.constructor.name} getBuildingsData - pvReq - forced ok`)
        return Promise.resolve(response)
      })
    }

    let elcomReq = (url) => {
      hycon.debug(`${thisRef.constructor.name} getBuildingsData - elcomReq`, { url })
      let jwt = thisRef.props.reduxState.user.jwt
      return axios({
        method: 'get',
        url: url,
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        }
      })
      .then(function (response) {
        hycon.debug(`${thisRef.constructor.name} getBuildingsData - elcomReq - ok`)
        return response
      })
      .catch((e) => {
        hycon.debug(`${thisRef.constructor.name} getBuildingsData - elcomReq - error`, { e })
        UserUtil.authenticationRedirect(e.response, thisRef)
        return Promise.reject('elcomReq rejection')
      })
    }

    let getDistricts = async (building) => {
      hycon.debug(`${thisRef.constructor.name} getDistricts`, { building })
      let gwr = await thisRef.getGWR(building.buildings[0].id)
      hycon.debug(`${thisRef.constructor.name} getDistricts - districts`, { gwr })
      if (gwr.data.length > 0) {
        return gwr.data.map((element) => {
          return element.housingEgids.gemNr
        })
      } else {
        // fallback
        hycon.debug(`${thisRef.constructor.name} getDistricts - districts fallback`, { gwr, building })
        let targetBuilding = building.buildings[0]
        let districtResponse = await this.getDistrictByPoint(targetBuilding.coordinates.longitude, targetBuilding.coordinates.latitude)
        hycon.debug(`${thisRef.constructor.name} getDistricts - districts fallback`, {
          districtResponse,
          gwr,
          building
        })
        let bfsNummer = districtResponse.data[0].bfsNummer
        return [bfsNummer]
        /*let year = parseInt(moment().format('YYYY'), 10)
        let attempts = 2
        let elcomResponse = null
        for (let i = 0; i < attempts; i++) {
          let tempElcomResponse = await this.getElcom(bfsNummer, year - i)
          if (tempElcomResponse.data) {
            elcomResponse = tempElcomResponse
            i = attempts
          }
        }
        hycon.debug(`${thisRef.constructor.name} getDistricts - districts fallback`, {
          elcomResponse,
          gwr,
          building
        })*/
      }
    }

    let districtPromises = []
    buildings.forEach((building, selectionIndex) => {
      hycon.debug(`${thisRef.constructor.name} getBuildingsData - building`, { building })
      let districtPromise = getDistricts(building).then((districts) => {
        hycon.debug(`${thisRef.constructor.name} getBuildingsData - building - districtReq`, { building, districts })
        let allPromises = []
        districts.forEach((district) => {
          let bfsNummer = district
          let urlPv = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/ocelot-api/ews/${bfsNummer}`
          let urlElcom = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/ocelot-api/elcom/${bfsNummer}`
          let promisePv = pvReq(urlPv)
          let promiseElcom = elcomReq(urlElcom)
          let allPromise = Promise.all([
            promiseElcom,
            promisePv
          ]).then(values => {
            hycon.debug(`${thisRef.constructor.name} getBuildingsData - all data`, { values })
            let response = { data: {} }
            response.data.selectionIndex = selectionIndex
            response.data.building = building
            values.forEach((value) => {
              let data = value.data
              for (let p in data) {
                if (
                  data.hasOwnProperty(p)
                ) {
                  response.data[`${p}`] = data[p]
                }
              }
            })
            hycon.debug(`${thisRef.constructor.name} getBuildingsData - all data - returning response`, { response })
            return response
          })
          allPromises.push(allPromise)
        })
        return Promise.all(allPromises).then((values) => {
          return values
        })
      })
      districtPromises.push(districtPromise)
    })
    return Promise.all(districtPromises).then((values) => {
      hycon.debug(`${thisRef.constructor.name} getBuildingsData - values`, {
        values
      })
      return values.reduce((acc, current) => {
        return acc.concat(current)
      }, [])
    }).catch((error) => {
      hycon.error(`${thisRef.constructor.name} getBuildingsData - error`, {
        error
      })
    })
  }

  update () {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} update`, { thisRef })

    let selectedBuildings = thisRef.props.reduxState.selectedBuildings

    let buildingsDataPromise = thisRef.getBuildingsData(selectedBuildings).then((buildingsData) => {
      hycon.debug(`${thisRef.constructor.name} update - buildingsData`, { buildingsData })
      return thisRef.setStateProperty('buildingsData', buildingsData)
    })
    return buildingsDataPromise
  }

  render () {
    let thisRef = this

    return thisRef.renderState()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} componentDidUpdate`, {
      prevProps,
      newProps: this.props,
      prevState,
      snapshot
    })

    const old_selectedBuildings = prevProps.reduxState.selectedBuildings
    const new_selectedBuildings = thisRef.props.reduxState.selectedBuildings
    if(old_selectedBuildings.length === new_selectedBuildings.length) return ;
    hycon.debug(`${thisRef.constructor.name} componentDidUpdate - new selectedBuildings`, {
      old_selectedBuildings,
      new_selectedBuildings
    })
    // update the data and state
    return thisRef.setStateProperty('isLoading', true).then(() => {
      return thisRef.update().then((elcomData) => {
        hycon.debug(`${thisRef.constructor.name} componentDidUpdate - elcomData`, { elcomData })
        let data = Object.assign({}, thisRef.state.data)
        data.elcom = elcomData
        return thisRef.setStateProperty('data', data)
      })
    }).then(() => {
      return thisRef.setStateProperty('isLoading', false)
    }).catch(() => {
      return thisRef.setStateProperty('isLoading', false)
    })
  }
  componentWillUnmount () {

    hycon.debug(`${this.constructor.name} componentWillUnmount`, { props: this.props })
  }
}

const getValidPvTarifForBuilding = (pvTarifForBuilding) => {
  hycon.debug(`getValidPvTarifForBuilding`, { pvTarifForBuilding });
  let validPvTarifForBuilding = pvTarifForBuilding.map(pvTarifForBuildingElement => {
    let filteredPvTarifForBuildingData = pvTarifForBuildingElement.data.filter((subElement) => {
      return isValidPvTarifData(subElement);
    })
    return { ...pvTarifForBuildingElement, data: filteredPvTarifForBuildingData}
  }).filter(element => element.data.length > 0);
  hycon.debug(`getValidPvTarifForBuilding`, { validPvTarifForBuilding, pvTarifForBuilding });
  return validPvTarifForBuilding;
};
const isValidPvTarifData = (pvTarifForBuildingElement) => {
  const isValidElement = (
    pvTarifForBuildingElement
    && pvTarifForBuildingElement.energyValues
    && (
      pvTarifForBuildingElement.energyValues.power1 !== null
      || pvTarifForBuildingElement.energyValues.power2 !== null
      || pvTarifForBuildingElement.energyValues.power3 !== null
      || pvTarifForBuildingElement.energyValues.power4 !== null
    )
  );
  return isValidElement;
};
const getAccordionData = (elcomByBuildingSelection, currentYear, previousYear) => {
  const fnName = "getAccordionData";
  let accordionData = [];
  for(let property in elcomByBuildingSelection){
    let buildingSelection = parseInt(property, 10);
    elcomByBuildingSelection[property].forEach(dataSet => {
      let currentYearNrElcomArray = dataSet[`elcom${currentYear}`].reduce((acc, curr) => {
        if(!acc.includes(curr.nrElcom)){
          return [...acc, curr.nrElcom]
        }
        return acc;
      }, [])
      let previousYearNrElcomArray = dataSet[`elcom${previousYear}`].reduce((acc, curr) => {
        if(!acc.includes(curr.nrElcom)){
          return [...acc, curr.nrElcom]
        }
        return acc;
      }, [])
      let allElcomForBuilding = [
        { year: currentYear, nrElcom: currentYearNrElcomArray, data: dataSet[`elcom${currentYear}`]},
        { year: previousYear, nrElcom: previousYearNrElcomArray, data: dataSet[`elcom${previousYear}`]}
      ]

      let currentYearPvTarif = dataSet[`pvtarif${currentYear}`].reduce((acc, curr) => {
        let nrElcom = parseInt(curr.nrElcom, 10);
        if(!acc.includes(nrElcom)){
          return [...acc, nrElcom]
        }
        return acc;
      }, [])
      let previousYearPvTarif = dataSet[`pvtarif${previousYear}`].reduce((acc, curr) => {
        let nrElcom = parseInt(curr.nrElcom, 10);
        if(!acc.includes(nrElcom)){
          return [...acc, nrElcom]
        }
        return acc;
      }, [])
      let allPvTarifForBuilding = [
        { year: currentYear, nrElcom: currentYearPvTarif, data: dataSet[`pvtarif${currentYear}`]},
        { year: previousYear, nrElcom: previousYearPvTarif, data: dataSet[`pvtarif${previousYear}`]}
      ]
      let allValidPvTarifForBuilding = getValidPvTarifForBuilding(allPvTarifForBuilding);

      let match = null;
      allElcomForBuilding.forEach(elcomForBuilding => {
        elcomForBuilding.nrElcom.forEach(nrElcomInElcom => {
          allValidPvTarifForBuilding.forEach(pvTarifForBuilding => {
            pvTarifForBuilding.nrElcom.forEach(nrElcomInPvtarif => {
              hycon.debug(`${fnName} - useEffect - [props.buildingsData] - comparing`, { nrElcomInElcom, nrElcomInPvtarif, elcomForBuilding, pvTarifForBuilding });
              if(
                nrElcomInElcom === nrElcomInPvtarif
              ){
                // match
                let key = {
                  elcomForBuilding, pvTarifForBuilding, isLooseMatch: false, elcomNr: [nrElcomInElcom]
                };

                match = {
                  isLooseMatch: false,
                  elcomForBuilding,
                  pvTarifForBuilding,
                  nrElcomInElcom,
                  nrElcomInPvtarif,
                  buildingSelection,
                  key: JSON.stringify(key)
                }
                accordionData.push(match)
              }
            })
          })
        })
      })
      // handle the fallback cases where there is no match in pvtarif
      if(match === null){
        hycon.debug(`${fnName} - useEffect - [props.buildingsData] - handling fallback`, { allElcomForBuilding, allPvTarifForBuilding, match, buildingSelection,});
        let elcomForBuilding = allElcomForBuilding.filter(elcomForBuilding => elcomForBuilding.year === currentYear)[0]
          || allElcomForBuilding.filter(elcomForBuilding => elcomForBuilding.year === previousYear)[0];

        let pvTarifForBuilding = allValidPvTarifForBuilding.filter(pvTarifForBuilding => pvTarifForBuilding.year === currentYear)[0]
          || allPvTarifForBuilding.filter(pvTarifForBuilding => pvTarifForBuilding.year === previousYear)[0]
          || null;
        let nrElcom = [];
        let key = {
          elcomForBuilding, pvTarifForBuilding, isLooseMatch: false, elcomNr: []
        };
        match = {
          isLooseMatch: true,
          elcomForBuilding,
          pvTarifForBuilding,
          nrElcom,
          buildingSelection,
          key: JSON.stringify(key)
        }
        accordionData.push(match)
      }
      hycon.debug(`${fnName} - useEffect - [props.buildingsData] - `, { allElcomForBuilding, allPvTarifForBuilding, match, buildingSelection,});
    })
  }
  // discard the matches with lower years
  let latestElcomYear = 0;
  let latestPVTarifYear = 0;
  accordionData.forEach((accordionDataElement) => {
    latestElcomYear = Math.max(latestElcomYear, accordionDataElement.elcomForBuilding.year);
    latestPVTarifYear = Math.max(latestPVTarifYear, accordionDataElement.pvTarifForBuilding.year);
  });
  let newAccordionData = accordionData.filter((accordionDataElement) => {
    if(
      (
        accordionDataElement.elcomForBuilding.year === latestElcomYear
        && accordionDataElement.pvTarifForBuilding.year === latestPVTarifYear
      )
      || accordionDataElement.isLooseMatch
    ){
      return true
    }
    return false
  })
  hycon.debug(`${fnName} - useEffect - [props.buildingsData] - newAccordionData`, { newAccordionData, accordionData});
  return newAccordionData;
}
function OverviewAverage(props){
  const fnName = "OverviewAverage"
  const { t, /*i18n*/ } = useTranslation("panel_electricity", { useSuspense: false })
  const [avergageComponent, setAverageComponent] = useState(null);
  const getIconStyle = (name) => {
    // let env = thisRef.props.reduxState.env;
    let base = `https://geoimpactstorage.blob.core.windows.net`
    let itemStyle = {
      backgroundImage: `url(${base}/halley/components/dashboard/tile-icons/${name})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      height: '65px',
      width: '65px'
    }
    hycon.debug(`${fnName} - getStyle`, { itemStyle })
    return itemStyle
  }
  const getAverage = () => {
    const groupedAccordionData = getGroupedAccordionData(fnName, props.buildingsData);

    let h4Values = [];
    let h4BuildingIndexes = [];
    for(let property in groupedAccordionData){
      // they are all the same, so just pick the first
      let element = groupedAccordionData[property][0];
      element.elcomForBuilding.data.forEach((elcom) => {
        if (elcom.kategorie === 'H4') {
          let value = parseFloat(elcom['totalExklMwst'])
          if(!h4BuildingIndexes.includes(element.buildingSelection)){
            h4Values.push({ buildingIndex: element.buildingSelection, value })
            h4BuildingIndexes.push(element.buildingSelection);
          }
        }
      })
    }
    if(h4Values.length > 0){
      let average = (h4Values.reduce((acc, curr) => {return acc + curr.value}, 0)/h4Values.length).toFixed(2)
      return (
        <React.Fragment>
          <h1>{average}</h1>
          <h3>
            {t('panel_electricity:label-panel-overview-unit')}
          </h3>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <h3>
          {t('panel_electricity:label-no-tarif-available')}
        </h3>
      </React.Fragment>
    )
  }
  useEffect(()=>{
    // hycon.debug(`${fnName} - useEffect - [props.buildingsData]`, { props });
    // set the average
    let newAverageComponent = null
    let buildings = props.reduxState.selectedBuildings
    let buildingsNr = buildings.length
    if (buildingsNr === 0) {
      newAverageComponent = (
        <div className={`overview`}>
          <div>
            <div className={`icon`} style={getIconStyle('Strom.png')}/>
          </div>
          <div>
            <h1>{t('panel_electricity:label-panel-overview-title')}</h1>
          </div>
        </div>
      )
    }
    else if (buildingsNr === 1) {
      newAverageComponent = (
        <div className={`overview`}>
          <div>
            <div className={`icon`} style={getIconStyle('Strom.png')}/>
          </div>
          <div>
            {(() => {
              try {
                return (
                  <div>
                    {getAverage()}
                  </div>
                )
              }
              catch (e){
                // overview fallback
                return (
                  <div>
                    <h3>
                      {t('panel_electricity:label-no-tarif-available')}
                    </h3>
                  </div>
                )
              }
            })()}
          </div>
        </div>
      )
    }
    else if (buildingsNr > 1) {
      newAverageComponent = (
        <div className={`overview`}>
          <div>
            <div className={`icon`} style={getIconStyle('Strom.png')}/>
          </div>
          <div>
            {(() => {
              try {
                return (
                  <div>
                    {getAverage()}
                  </div>
                )
              } catch (e){
                // overview fallback
                return (
                  <div>
                    <h3>
                      {t('panel_electricity:label-no-tarif-available')}
                    </h3>
                  </div>
                )
              }
            })()}
          </div>
        </div>
      )
    }
    setAverageComponent(newAverageComponent)
    // eslint-disable-next-line
  }, [props.buildingsData, props.reduxState.selectedBuildings, t])
  return avergageComponent;
}

const getGroupedAccordionData = (fnName, buildingsData) => {
  let currentYear = parseInt(moment().format('YYYY'), 10)
  let previousYear = currentYear - 1;

  let elcomByBuildingSelection = buildingsData.reduce((acc, curr) => {
    hycon.debug(`${fnName} - useEffect - [props.buildingsData] - `, { acc, curr });
    acc[`${curr.data.selectionIndex}`] = [...(acc[`${curr.data.selectionIndex}`]|| []), curr.data]
    return acc;
  }, {})
  hycon.debug(`${fnName} - useEffect - [props.buildingsData] - `, { elcomByBuildingSelection });

  const accordionData = getAccordionData(elcomByBuildingSelection, currentYear, previousYear);
  let groupedAccordionData = {};
  // group the data by the key (combination of elcom and pvtarif)
  groupedAccordionData = accordionData.reduce((acc, curr) => {
    acc[`${curr.key}`] = [...(acc[`${curr.key}`]|| []), curr];
    return acc;
  }, {})
  hycon.debug(`${fnName} - useEffect - [props.buildingsData] - accordionData`, { accordionData, groupedAccordionData });

  return groupedAccordionData;
}

const borderStyle = {
  borderRadius: "0px",
  border: "1px solid rgba(0, 0, 0, 0.1)",
  boxShadow: 'none'
}
function OperatorList(props){
  const fnName = "OperatorList"
  const [accordions, setAccordions] = useState([]);
  const { t } = useTranslation("panel_electricity", { useSuspense: false });

  const setAccordionComponents = useCallback(() => {
    const groupedAccordionData = getGroupedAccordionData(fnName, props.buildingsData);

    let newAccordions = [];
    for(let property in groupedAccordionData){
      // they are all the same, so just pick the first
      let element = groupedAccordionData[property][0];
      let filteredElcomData = [];
      let filteredPvTarifData = [];
      element.elcomForBuilding.data.forEach((dataElement) => {
          let isSameElcomNr = parseInt(dataElement.nrElcom, 10) === parseInt(element.nrElcomInElcom, 10);
          // hycon.debug(`${fnName} - useEffect - [props.buildingsData] - accordionData - filtering - dataElement`, { isSameElcomNr, dataElement, element, accordionData, groupedAccordionData });
          if(isSameElcomNr){
            filteredElcomData.push(dataElement);
          }
        }
      )
      element.pvTarifForBuilding.data.forEach((dataElement) => {
          let isSameElcomNr = parseInt(dataElement.nrElcom, 10) === parseInt(element.nrElcomInElcom, 10);
          // hycon.debug(`${fnName} - useEffect - [props.buildingsData] - accordionData - filtering - dataElement`, { isSameElcomNr, dataElement, element, accordionData, groupedAccordionData });
          if(isSameElcomNr){
            filteredPvTarifData.push(dataElement);
          }
        }
      )
      hycon.debug(`${fnName} - useEffect - [props.buildingsData] - accordionData - filtering`, { element, filteredElcomData, filteredPvTarifData });

      let buildingsSelectionNumbers = groupedAccordionData[property].reduce((acc, curr) => {
        if(!acc.includes(curr.buildingSelection)){
          return [...acc, curr.buildingSelection];
        }
        return acc;
      }, []).map(selectionNumber => selectionNumber + 1);

      let cellStyle = {
        borderRight: "1px solid rgba(0, 0, 0, 0.1)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
      };

      let headerCellStyle = {
        padding: '0px',
        paddingTop: '4px',
        paddingBottom: '4px'
      };

      const getRowByNr = (nr, energyValues) => {
        try {
          return (
            <Table.Row key={nr}>
              <Table.Cell style={cellStyle}>
                {`> ${energyValues[`power${nr}`]}`}
              </Table.Cell>
              <Table.Cell style={cellStyle}>
                {round(energyValues[`energy${nr}`])}
              </Table.Cell>
              <Table.Cell style={cellStyle}>
                {round(energyValues[`eco${nr}`])}
              </Table.Cell>
              <Table.Cell style={cellStyle}>
                {(() => {
                  let value_1 = parseFloat(energyValues[`energy${nr}`])
                  let value_2 = parseFloat(energyValues[`eco${nr}`])
                  let sum = value_1 + value_2
                  if (isNaN(sum)) {
                    throw new Error('No valid data')
                  }
                  return round(value_1 + value_2)
                })()}
              </Table.Cell>
            </Table.Row>
          )
        } catch (e) {
          hycon.warn(`${fn} getRowByNr - error getting power/energy/eco_${nr} values`, { e })
        }
      }

      // if there is no match, populate the filter back with the original data
      filteredPvTarifData = filteredPvTarifData.length === 0 ? element.pvTarifForBuilding.data : filteredPvTarifData;
      filteredElcomData = filteredElcomData.length === 0 ? element.elcomForBuilding.data : filteredElcomData;
      if(
          element.elcomForBuilding !== null
          /*&& filteredPvTarifData.length > 0*/
          && filteredElcomData.length > 0
      ){
        newAccordions.push((
            <MUIAccordion
                style={{...borderStyle, marginTop: '5px'}}
                key={property}
            >
              <AccordionSummary
                  css={emotionCss}
                  expandIcon={<ExpandMoreIcon />}
              >
                <div style={{flexDirection: 'column'}}>
                  <div>
                    <h3>
                      {filteredElcomData[0].netzbetreiber}
                    </h3>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>
                      {t('panel_electricity:label-building')}
                    </span>
                    <span>
                      {` ${buildingsSelectionNumbers.join(", ")}`}
                    </span>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{flexDirection: 'column', flex: 1}}>
                  <div className={"section"} style={{ display: 'flex', flexDirection: 'column', flex: 1}}>
                    <h3>
                      {t('panel_electricity:label-network-operator')}
                    </h3>
                    <span>{filteredElcomData[0].netzbetreiber}
                    </span>
                  </div>
                  <div className={"section"} style={{ display: 'flex', flexDirection: 'column', flex: 1}}>
                    <h3>
                      {t('panel_electricity:label-strombezug')} {`(${t('panel_electricity:label-tarifjahr')} ${element.elcomForBuilding.year})`}
                    </h3>
                    <h4 style={{marginTop: '8px', marginBottom: '8px'}}>
                      {t('panel_electricity:label-bezugstarif')}
                    </h4>
                    <TableContainer
                        style={borderStyle}
                        component={Paper}
                    >
                      <MUITable
                          aria-label="simple table"
                          stickyHeader
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell style={headerCellStyle}>
                              {t('panel_electricity:table-category')}
                            </TableCell>
                            <TableCell style={headerCellStyle}>
                              {t('panel_electricity:table-net')}
                            </TableCell>
                            <TableCell style={headerCellStyle}>
                              {t('panel_electricity:table-energy')}
                            </TableCell>
                            <TableCell style={headerCellStyle}>
                              {t('panel_electricity:table-abgabe')}
                            </TableCell>
                            <TableCell style={headerCellStyle}>
                              {t('panel_electricity:table-kev')}
                            </TableCell>
                            <TableCell style={headerCellStyle}>
                              {t('panel_electricity:table-total')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(()=>{
                            return element.elcomForBuilding.data.map((element, index) => {
                              return (
                                  <Table.Row key={`elcom_${index}`}>
                                    <Table.Cell style={cellStyle}>{element.kategorie}</Table.Cell>
                                    <Table.Cell style={cellStyle}>{round(element.netznutzung, 2)}</Table.Cell>
                                    <Table.Cell style={cellStyle}>{round(element.energie, 2)}</Table.Cell>
                                    <Table.Cell style={cellStyle}>{round(element.abgabe, 2)}</Table.Cell>
                                    <Table.Cell style={cellStyle}>{round(element.kev, 2)}</Table.Cell>
                                    <Table.Cell style={cellStyle}>{round((
                                        element.netznutzung +
                                        element.energie +
                                        element.abgabe +
                                        element.kev
                                    ), 2)}</Table.Cell>
                                  </Table.Row>
                              )
                            })
                          })()}
                        </TableBody>
                      </MUITable>
                    </TableContainer>
                  </div>
                  <div className={"section"} style={{ display: 'flex', flexDirection: 'column', flex: 1}}>
                    {(()=>{
                      if(element.pvTarifForBuilding !== null && filteredPvTarifData.length > 0){
                        let link = null;
                        if(filteredPvTarifData[0].energyValues !== null){
                          link = (
                              <a href={`${filteredPvTarifData[0].energyValues.link}`}
                                 target={`_blank`}>{filteredPvTarifData[0].energyValues.nomEw}
                              </a>
                          )
                        } else {
                          link = (
                              <div>
                                {JSON.stringify(filteredElcomData[0].netzbetreiber)}
                              </div>
                          )
                        }
                        return (
                            <React.Fragment>
                              <h3 style={{display: 'block', textAlign: 'start'}}>
                                {t('panel_electricity:label-stromeinspeisung')} -&nbsp;{link}&nbsp; ({t('panel_electricity:label-tarifjahr')} {element.pvTarifForBuilding.year})
                              </h3>
                              <h4 style={{marginTop: '8px', marginBottom: '8px'}}>{t('panel_electricity:label-einspeisetarif')}</h4>
                              <TableContainer
                                  style={borderStyle}
                                  component={Paper}
                              >
                                <MUITable
                                    aria-label="simple table"
                                    stickyHeader
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell style={headerCellStyle}>
                                        {t('panel_electricity:table-leistungsklasse')}
                                      </TableCell>
                                      <TableCell style={headerCellStyle}>
                                        {t('panel_electricity:table-energy')}
                                      </TableCell>
                                      <TableCell style={headerCellStyle}>
                                        {t('panel_electricity:table-hkn')}
                                      </TableCell>
                                      <TableCell style={headerCellStyle}>
                                        {t('panel_electricity:table-total')}
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {getRowByNr(1, filteredPvTarifData[0].energyValues)}
                                    {getRowByNr(2, filteredPvTarifData[0].energyValues)}
                                    {getRowByNr(3, filteredPvTarifData[0].energyValues)}
                                    {getRowByNr(4, filteredPvTarifData[0].energyValues)}
                                  </TableBody>
                                </MUITable>
                              </TableContainer>
                              <h3 style={{marginTop: '15px', display: 'block', textAlign: 'start'}}>
                                {t('panel_electricity:label-more-infos')} ({t('panel_electricity:label-tarifjahr')} {element.pvTarifForBuilding.year})
                              </h3>
                              <div>
                                {(()=>{
                                  if(filteredPvTarifData[0].energyValues !== null){
                                    return filteredPvTarifData[0].energyValues.explText ?
                                        filteredPvTarifData[0].energyValues.explText :
                                        ` ${t('panel_electricity:no-infos-available')}`
                                  } else {
                                    return t('panel_electricity:no-infos-available')
                                  }
                                })()}
                              </div>
                            </React.Fragment>
                        )
                      } else {
                        // (element.pvTarifForBuilding !== null && filteredPvTarifData.length > 0) is false
                        return (
                          <React.Fragment>
                            <h3>
                              {t('panel_electricity:label-no-pv-tarif-available')}
                            </h3>
                          </React.Fragment>
                        )
                      }
                    })()}
                  </div>
                </div>
              </AccordionDetails>
            </MUIAccordion>
        ))
      } else {
        newAccordions.push((
            <MUIAccordion
                style={{...borderStyle, marginTop: '5px'}}
                key={property}
            >
              <AccordionSummary
                  css={emotionCss}
                  expandIcon={<ExpandMoreIcon />}
              >
                <div style={{flexDirection: 'column'}}>
                  <div>
                    <h3>
                      {t('panel_electricity:label-no-tarif-available')}
                    </h3>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>
                      {t('panel_electricity:label-building')}
                    </span>
                    <span>
                      {` ${buildingsSelectionNumbers.join(", ")}`}
                    </span>
                  </div>
                </div>
              </AccordionSummary>
            </MUIAccordion>
        ))
      }
    }
    setAccordions(newAccordions)
  }, [t, props]);
  useEffect(()=>{
    hycon.debug(`${fnName} - useEffect - [props.buildingsData] - setAccordionComponents`, { props });
    setAccordionComponents();
  }, [props.buildingsData, props.reduxState.selectedBuildings, setAccordionComponents, props]);
  return (
    <div className={"operator-list"}>
      {accordions}
    </div>
  );
}

export default withTranslation([
  'panel_electricity'
])(Elcom)
