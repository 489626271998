import {IconButton} from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import {useLocationProvider} from '../../contexts/location/location-context';
import HyperConsole from '../../components/hyper_console/hyper-console';

const { REACT_APP_GI_ENV } = process.env;
let hycon = null;
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
}

export default function MapCurrentLocationButton(props) {
    const {onNewWatchLocation, onNewLocation, sx} = props;
    const {startWatcher, getLocation, status, permission, positions } = useLocationProvider();
    const getAvalableLocation = async () => {
        let location = await getLocation(onNewLocation, {
          enableHighAccuracy: true,
          timeout: 5000,
          /*
          * A positive long value indicating the maximum age in milliseconds of a possible cached position that is
          *  acceptable to return. If set to 0, it means that the device cannot use a cached position and must attempt
          *  to retrieve the real current position. If set to Infinity the device must return a cached position
          *  regardless of its age. Default: last 2 hour
          * */
          maximumAge: 1000 * 60 * 60 * 2
        });
        hycon.debug("got location", location);

        // optionally start the location tracker
      let watcherId = await startWatcher(onNewWatchLocation)
      hycon.debug("got watcher", location);
    };
    return (
        <>
            {(() => {
                if (permission === null) {
                    return (
                        <IconButton sx={sx} disabled={true}>
                            <CircularProgress size={18}/>
                        </IconButton>
                    );
                } else {
                    return (
                        <IconButton
                            onClick={async () => {
                            await getAvalableLocation()
                        }}
                            sx={sx}
                            disabled={permission?.state === "denied"}
                        >
                            <MyLocationIcon/>
                        </IconButton>
                    );
                }

            })()}
        </>
    )
}
