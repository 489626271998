import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import FavoriteSelectionBox from '../favorite-selection-box/FavoriteSelectionBox';
import { useFavoriteCtx } from '../../FavoriteProvider';
import { LOCIZE_PANEL_NS, REACT_APP_GI_ENV, reducerActions } from '../../constants';
import HyperConsole from '../../../hyper_console/hyper-console';

import './FavoriteActionBar.css';
import FavoriteUtils from '../../services/FavoriteUtils';
import FavoriteApi from '../../services/FavoriteApi';
import SEPContext from '../../../../contexts/sep-context/SEPContext';

let hycon = null;
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
}

export default function FavoriteActionBar() {
  const hookName = 'FavoriteActionBar';
  const favoriteContext = useFavoriteCtx();
  const { t } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false });
  const [selectedFavorites, setSelectedFavorites] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectionUniqueGroups, setSelectionUniqueGroups] = useState({
    uniqueAddressGroup: [], uniqueRegionGroup: [], mergedUniqueGroup: [],
  });
  const targetFavorites = favoriteContext.getTargetFavorites(favoriteContext);
  const { user: { jwt }, env } = useContext(SEPContext).SEPContext;

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - constructor`, {});
    const getUniqueGroups = (favoritesArray) => {
      // filter the recipients to an array contianing only one recipients per favoriteType and group
      const uniqueAddressGroup = favoritesArray
        .filter((favorite) => favorite.favoriteType === favoriteContext.favoriteTypes.ADDRESS)
        .reduce((acc, current) => {
          if (!acc.includes(current.group)) {
            return [...acc, current.group];
          }
          return acc;
        }, []);
      const uniqueRegionGroup = favoritesArray
        .filter((favorite) => favorite.favoriteType === favoriteContext.favoriteTypes.REGION)
        .reduce((acc, current) => {
          if (!acc.includes(current.group)) {
            return [...acc, current.group];
          }
          return acc;
        }, []);
      const mergedUniqueGroup = [...uniqueAddressGroup, ...uniqueRegionGroup]
        .reduce((acc, current) => {
          if (!acc.includes(current)) {
            return [...acc, current];
          }
          return acc;
        }, []);
      hycon.debug(`${hookName} getUniqueGroups`, {
        uniqueRegionGroup,
        uniqueAddressGroup,
        mergedUniqueGroup,
      });
      return { uniqueAddressGroup, uniqueRegionGroup, mergedUniqueGroup };
    };
    const currentSelectedFavorites = (favoriteContext.favorites || [])
      .filter((favorite) => (favoriteContext.selection || [])
        .includes(favorite.id));
    const currentUniqueGroups = getUniqueGroups(currentSelectedFavorites || []);
    hycon.debug(`${hookName} useEffect - favoriteContext`, {
      currentUniqueGroups,
      currentSelectedFavorites,
    });
    setSelectionUniqueGroups(currentUniqueGroups);
    setSelectedFavorites(currentSelectedFavorites);
  }, [favoriteContext]);
  const getDeleteComponent = () => {
    if (selectedFavorites.length > 0) {
      return (
        <div className="action delete-component">
          <i
            className="fas fa-trash"
            onClick={async () => {
              const alertText = `${selectedFavorites.length} ${t('favorites:are-you-sure-you-want-delete-selected-1')} ${t('favorites:are-you-sure-you-want-delete-selected-2')}`;
              const isOk = window.confirm(alertText);
              hycon.debug(`${hookName} onClick - isOk`, { selectedFavorites, isOk });
              if (isOk) {
                hycon.debug(`${hookName} onClick - isOk`, { selectedFavorites, isOk });
                favoriteContext.dispatch({
                  type: reducerActions.ADD_LOADING_FAVORITES,
                  info: 'onGroupDropdownChange - add loadingFavorites',
                  payload: {
                    favoriteIdArray: selectedFavorites.map((f) => f.id),
                  },
                });
                const deletePromiseArray = selectedFavorites.map(async (favorite) => {
                  const deletePromise = await FavoriteApi.deleteGenericFavorite(jwt, env, favorite);
                  return deletePromise;
                });

                const loadingFavoritesIdArray = [];
                selectedFavorites.forEach((favorite) => {
                  loadingFavoritesIdArray.push(favorite.id);
                });

                await Promise.all(deletePromiseArray);

                FavoriteUtils.updateFavoriteCounter();

                const {
                  mergedFavorites,
                  mergedGroups,
                } = await favoriteContext.getFavorites();
                favoriteContext.dispatch({
                  type: reducerActions.REPLACE,
                  info: 'getDeleteComponent - onClick',
                  payload: {
                    newState: {
                      favorites: mergedFavorites,
                      groups: mergedGroups,
                    },
                  },
                });
                favoriteContext.dispatch({
                  type: reducerActions.REMOVE_LOADING_FAVORITES,
                  info: 'onGroupDropdownChange - add loadingFavorites',
                  payload: {
                    favoriteIdArray: selectedFavorites.map((f) => f.id),
                  },
                });
              }
            }}
          />
        </div>
      );
    }
    return null;
  };
  const getGroupEditComponent = () => {
    if (selectedFavorites.length > 0) {
      return (
        <div className="action">
          <i
            className="fas fa-object-group"
            onClick={() => {
              favoriteContext.dispatch({
                type: reducerActions.REPLACE,
                info: 'getGroupEditComponent - onClick',
                payload: {
                  newState: {
                    modals: [
                      { type: 'group', favoriteIdArray: selectedFavorites.map((f) => f.id) },
                    ],
                  },
                },
              });
            }}
          />
        </div>
      );
    }
    return null;
  };
  const getNotificationComponent = () => {
    if (
      targetFavorites.length === 1
            || (targetFavorites.length > 0 && favoriteContext.isGroupEditMode(favoriteContext))
    ) {
      return (
        <div className="action">
          <i
            className="fas fa-bell"
            onClick={() => {
              favoriteContext.dispatch({
                type: reducerActions.REPLACE,
                info: 'getGroupEditComponent - onClick',
                payload: {
                  newState: {
                    modals: [
                      { type: 'notification', favoriteIdArray: selectedFavorites.map((f) => f.id) },
                    ],
                  },
                },
              });
            }}
          />
        </div>
      );
    }
    return null;
  };
  const getRecipientComponent = () => {
    if (
      targetFavorites.length === 1
            || (targetFavorites.length > 0 && favoriteContext.isGroupEditMode(favoriteContext))
    ) {
      return (
        <div className="action">
          <i
            className="fas fa-users"
            onClick={() => {
              favoriteContext.dispatch({
                type: reducerActions.REPLACE,
                info: 'modal',
                payload: {
                  newState: {
                    modals: [
                      { type: 'recipient', favoriteIdArray: selectedFavorites.map((f) => f.id) },
                    ],
                  },
                },
              });
            }}
          />
        </div>
      );
    }
    return null;
  };
  const getSelectionComponent = () => (
    <div className="action">
      <FavoriteSelectionBox />
    </div>
  );
  return (
    <div className={hookName}>
      <div className="actionbar-container">
        {getSelectionComponent()}
        {getGroupEditComponent()}
        {getNotificationComponent()}
        {getRecipientComponent()}
        {getDeleteComponent()}
      </div>
    </div>
  );
}
