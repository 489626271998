import React, {
  useState, useEffect, useContext, useCallback, useRef,
} from 'react';
import axios from 'axios';
import {
  Autocomplete, InputAdornment,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import { debounce } from 'lodash';
/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import { jsx, css } from '@emotion/react';
import HyperConsole from '../../hyper_console/hyper-console';
import { useMap } from '../../../contexts/map/map-context';
import MapCurrentLocationButton from '../../reusable/MapCurrentLocationButton';
import SEPContext from '../../../contexts/sep-context/SEPContext';
// eslint-disable-next-line import/no-cycle
import SearchOption from './SearchOption';
import AutocompletePaper from '../../mui/styled/AutocompletePaper';

const { REACT_APP_GI_ENV } = process.env;
let hycon = null;
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
}

const getApproximateCoordinates = async (searchString, jwt, env) => {
  const response = await axios(
    {
      method: 'get',
      url: `${env.API_GATEWAY_BASE}/google-geocode/json?address=${encodeURIComponent(searchString)}&components=country:CH`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return response;
};
const getGooglePlace = async (placeId, jwt, env) => {
  const response = await axios(
    {
      method: 'get',
      url: `${env.API_GATEWAY_BASE}/google-places/details/json?place_id=${encodeURIComponent(placeId)}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return response;
};
/* SEARCH */
const search = function search(options) {
  const promises = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const endpoint of options.endpoints) {
    promises.push(axios({
      ...endpoint,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${options.jwt}`,
        ...(endpoint.headers || {}),
      },
    }));
  }
  return promises;
};

const { CancelToken } = axios;
const LOCIZE_PANEL_NS_SEARCH = 'search_bar';
export default function Search() {
  const sepContext = useContext(SEPContext).SEPContext;
  const { maps } = useMap();
  const { t } = useTranslation(LOCIZE_PANEL_NS_SEARCH, { useSuspense: false });
  const hookName = 'Search';

  const [types] = useState({
    REGION_TOWN: {
      GROUP: 'SEP',
      GROUP_PRIORITY: 4,
      NAME: 'REGION_TOWN',
    },
    REGION_PLZ: {
      GROUP: 'SEP',
      GROUP_PRIORITY: 3,
      NAME: 'REGION_PLZ',
    },
    ADDRESS: {
      GROUP: 'SEP',
      GROUP_PRIORITY: 1,
      NAME: 'ADDRESS',
    },
    PARCEL: {
      GROUP: 'SEP',
      GROUP_PRIORITY: 2,
      NAME: 'PARCEL',
    },
    GOOGLE: {
      GROUP: 'GOOGLE',
      GROUP_PRIORITY: 5,
      NAME: 'GOOGLE',
    },
    GOOGLE_AUTOCOMPLETE: {
      GROUP: 'GOOGLE_AUTOCOMPLETE',
      GROUP_PRIORITY: 6,
      NAME: 'GOOGLE_AUTOCOMPLETE',
    },
  });
  const [searchString, setSearchString] = useState('');
  const [isLoading] = useState(false);
  const source = useRef(CancelToken.source());

  const [results, setResults] = useState([]);

  const [options, setOptions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selection, setSelection] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const searchMarkerRef = useRef(null);

  useEffect(() => {
    hycon.debug('options have been updated', {
      options,
    });
  }, [options]);

  useEffect(() => {
    hycon.debug('results have been updated', {
      results,
    });
    let newOptions = [];
    results.forEach((result) => {
      try {
        if (
          result.config.meta.name === types.ADDRESS.NAME
                    && !!result.data.error === false
        ) {
          (result?.data?.rows || []).forEach((row) => {
            newOptions.push(
              {
                type: result.config.meta.name,
                group: result.config.meta.group,
                groupPriority: result.config.meta.groupPriority,
                timestamp: result.config.meta.timestamp,
                row,
              },
            );
          });
        } else if (
          result.config.meta.name === types.PARCEL.NAME
                    && !!result.data.error === false
        ) {
          (result?.data?.rows || []).forEach((row) => {
            newOptions.push(
              {
                type: result.config.meta.name,
                group: result.config.meta.group,
                groupPriority: result.config.meta.groupPriority,
                timestamp: result.config.meta.timestamp,
                row,
              },
            );
          });
        } else if (
          result.config.meta.name === types.GOOGLE.NAME
        ) {
          (result?.data?.results || []).forEach((googleResult) => {
            newOptions.push(
              {
                type: result.config.meta.name,
                group: result.config.meta.group,
                groupPriority: result.config.meta.groupPriority,
                timestamp: result.config.meta.timestamp,
                row: googleResult,
              },
            );
          });
        } else if (
          result.config.meta.name === types.GOOGLE_AUTOCOMPLETE.NAME
        ) {
          (result?.data?.predictions || []).forEach((prediction) => {
            newOptions.push(
              {
                type: result.config.meta.name,
                group: result.config.meta.group,
                groupPriority: result.config.meta.groupPriority,
                timestamp: result.config.meta.timestamp,
                row: prediction,
              },
            );
          });
        } else if (
          result.config.meta.name === types.REGION_TOWN.NAME
        ) {
          (result?.data || []).forEach((regionResult) => {
            newOptions.push(
              {
                type: result.config.meta.name,
                group: result.config.meta.group,
                groupPriority: result.config.meta.groupPriority,
                timestamp: result.config.meta.timestamp,
                row: regionResult,
              },
            );
          });
        } else if (
          result.config.meta.name === types.REGION_PLZ.NAME
        ) {
          (result?.data || []).forEach((regionResult) => {
            newOptions.push(
              {
                type: result.config.meta.name,
                group: result.config.meta.group,
                groupPriority: result.config.meta.groupPriority,
                timestamp: result.config.meta.timestamp,
                row: regionResult,
              },
            );
          });
        }
      } catch (e) {
        hycon.error(e);
      }
    });

    const detectMatches = (option) => {
      const matches = [];
      if (
        [
          types.PARCEL.NAME,
          types.ADDRESS.NAME,
        ].includes(option.type)
      ) {
        const matchesFromServer = option.row.match.split('|');
        matchesFromServer.forEach((match) => {
          const fieldValue = option.row.fields[match];
          matches.push({ key: match, value: fieldValue });
        });
      }
      return { ...option, matches };
    };

    newOptions = newOptions
      .filter((option) => {
        const isCountry = (option?.row?.types || []).includes('country');
        const isGoogle = [types.GOOGLE.NAME, types.GOOGLE_AUTOCOMPLETE.NAME].includes(option.type);
        if (isGoogle && isCountry) {
          return false;
        }
        return true;
      })
      .map((option) => detectMatches(option))
      .map((option) => {
        let label = JSON.stringify(option.row, null, 4);
        let chipLabel = option.type;
        if (option.type === types.ADDRESS.NAME) {
          label = `${option.row.fields.address}`;
          // we reverse the options order because apache lucene puts the most relevant at index 0
          option.matches.reverse().forEach((match) => {
            if (
              [
                'egid',
                'egrid',
                'egaid',
                'parz_nr',
              ].includes(match.key)
            ) {
              label = `${option.row.fields.address} (${t(`${LOCIZE_PANEL_NS_SEARCH}:res-address-${match.key}`)} ${option.row.fields[match.key]})`;
            } else {
              label = `${option.row.fields.address}`;
            }
          });
          chipLabel = option.type;
        } else if (option.type === types.PARCEL.NAME) {
          label = `${t(`${LOCIZE_PANEL_NS_SEARCH}:res-parcel`)} ${option.row.fields.parz_nr}, ${option.row.fields.districts.split('|').join('/')}`;
          // we reverse the options order because apache lucene puts the most relevant at index 0
          option.matches.reverse().forEach((match) => {
            if (match.key === 'parz_nr') {
              label = `${t(`${LOCIZE_PANEL_NS_SEARCH}:res-parcel`)} ${option.row.fields.parz_nr}, ${option.row.fields.districts.split('|').join('/')}`;
            } else if (
              [
                'egrid',
              ].includes(match.key)
                            && ![
                              'town',
                              'districts',
                            ].includes(match.key)
            ) {
              label = `${t(`${LOCIZE_PANEL_NS_SEARCH}:res-parcel`)} ${option.row.fields.parz_nr}, ${option.row.fields.districts.split('|').join('/')} (${t(`${LOCIZE_PANEL_NS_SEARCH}:res-parcel-${match.key}`)} ${option.row.fields[match.key]})`;
            }
          });
          chipLabel = option.type;
        } else if (option.type === types.GOOGLE.NAME) {
          label = `${option.row.name} (${option.row.formatted_address})`;
          chipLabel = option.type;
        } else if (option.type === types.GOOGLE_AUTOCOMPLETE.NAME) {
          label = `${option.row.description}`;
          chipLabel = option.type;
        } else if (option.type === types.REGION_PLZ.NAME) {
          label = `${option.row.swissZipCode} ${option.row.town}`;
          chipLabel = 'PLZ';
        } else if (option.type === types.REGION_TOWN.NAME) {
          label = `${option.row.swissZipCode} ${option.row.town}`;
          chipLabel = 'PLZ';
        }
        return {
          ...option,
          label,
          chipLabel,
        };
      })
    /* eslint-disable */
            /*
            * The results can be sorted arbitrarely because we start all requests in parallel.
            * In order to maintain a constant sorting in the dropdown items, the sorting has to be implemented.
            * Sorting:  Adressen, Parzellen, PLZ, Google
            * */
            /* eslint-enable */
      .sort((a, b) => a.groupPriority - b.groupPriority);

    const groupedOptions = newOptions.reduce((acc, curr) => {
      acc[curr.groupPriority] = acc[curr.groupPriority] !== undefined
        ? acc[curr.groupPriority].concat([curr])
        : [curr];
      return acc;
    }, {});

    // All the options are sorted based on groupPriority.
    // All options of a given priority can be additionally
    // sorted with a custom logic.
    let tempNewOptions = [];
    Object.keys(groupedOptions).forEach((key) => {
      const subGroup = groupedOptions[key];
      tempNewOptions = tempNewOptions.concat(subGroup);
    });
    const globalSorting = (a, b) => {
      // const aisEgidMatch = a.matches.some((match) => match.key === 'egid');
      // const aisEgaidMatch = a.matches.some((match) => match.key === 'egaid');
      const aisEgridMatch = a.matches.some((match) => match.key === 'egrid');

      // const bisEgidMatch = b.matches.some((match) => match.key === 'egid');
      // const bisEgaidMatch = b.matches.some((match) => match.key === 'egaid');
      const bisEgridMatch = b.matches.some((match) => match.key === 'egrid');

      const aIsParcel = a.type === types.PARCEL.NAME;
      // const bIsParcel = b.type === types.PARCEL.NAME;

      if (aisEgridMatch && bisEgridMatch && aIsParcel) {
        return -1;
      }
      return 0;
    };
    const sortedNewOptions = tempNewOptions.sort(globalSorting);
    setOptions(sortedNewOptions);
  }, [results]);

  const fetchOptions = async (event, string, reason) => {
    hycon.debug('fetchOptions', {
      event,
      string,
      reason,
      sepContext,
    });
    const timestamp = Date.now();
    source.current.cancel('Operation canceled by the user.');
    source.current = CancelToken.source();
    const replacedOptions = {
      lat: null,
      lng: null,
      string,
      endpoints: [
        {
          meta: {
            name: types.GOOGLE.NAME,
            group: types.GOOGLE.GROUP,
            groupPriority: types.GOOGLE.GROUP_PRIORITY,
            timestamp,
          },
          url: `${sepContext.env.API_GATEWAY_BASE}/google-geocode/json?address=${encodeURIComponent(string)}&components=country:CH`,
          method: 'get',
          cancelToken: source.current.token,
        },
        {
          meta: {
            name: types.GOOGLE_AUTOCOMPLETE.NAME,
            group: types.GOOGLE.GROUP,
            groupPriority: types.GOOGLE_AUTOCOMPLETE.GROUP_PRIORITY,
            timestamp,
          },
          method: 'get',
          url: `${sepContext.env.API_GATEWAY_BASE}/google-places-autocomplete/json?input=${encodeURIComponent(string)}&types=address&components=country:ch`,
          cancelToken: source.current.token,
        },
        {
          meta: {
            name: types.ADDRESS.NAME,
            group: types.ADDRESS.GROUP,
            groupPriority: types.ADDRESS.GROUP_PRIORITY,
            timestamp,
          },
          url: `${sepContext.env.API_GATEWAY_BASE}/api/marketsense/searchaddress-advanced`,
          method: 'post',
          cancelToken: source.current.token,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: (() => {
            const formData = new FormData();
            formData.append('searchtext', string);
            return formData;
          })(),
        },
        {
          meta: {
            name: types.PARCEL.NAME,
            group: types.PARCEL.GROUP,
            groupPriority: types.PARCEL.GROUP_PRIORITY,
            timestamp,
          },
          url: `${sepContext.env.API_GATEWAY_BASE}/api/marketsense/searchparcel`,
          method: 'post',
          cancelToken: source.current.token,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: (() => {
            const formData = new FormData();
            formData.append('searchtext', string);
            return formData;
          })(),
        },
        {
          meta: {
            name: types.REGION_TOWN.NAME,
            group: types.REGION_TOWN.GROUP,
            groupPriority: types.REGION_TOWN.GROUP_PRIORITY,
            timestamp,
          },
          url: `${sepContext.env.API_GATEWAY_BASE}/api/addresspoints-find-town-swisszipcode/${string}`,
          method: 'get',
          cancelToken: source.current.token,
        },
        {
          meta: {
            name: types.REGION_PLZ.NAME,
            group: types.REGION_PLZ.GROUP,
            groupPriority: types.REGION_PLZ.GROUP_PRIORITY,
            timestamp,
          },
          url: `${sepContext.env.API_GATEWAY_BASE}/api/addresspoints-find-swisszipcode-town/${string}`,
          method: 'get',
          cancelToken: source.current.token,
        },
      ],
      env: sepContext.env,
      jwt: sepContext.user.jwt,
    };
    const searchPromises = search(replacedOptions);
    setResults([]);
    searchPromises.map((searchPromise) => searchPromise.then((res) => {
      hycon.debug(`fetchOptions - promises - resolved (${res.config.meta.name})`, {
        res,
      });
      /* Every main endpoint could trigger additional request. This is the case for Google results
      * where we make an additional request to fetch the Place Detail by place_id */
      if ([types.GOOGLE.NAME].includes(res.config.meta.name)) {
        // const placeIds = null;
      }
      setResults((p) => [...p, res]);
    }));
    hycon.debug('fetchOptions - promises', {
      searchPromises,
    });
  };

  const debouncedFetchOptions = useCallback(debounce(fetchOptions, 400, {
    leading: false,
    trailing: true,
  }), []);
  const renderOptionsGroup = (params) => (
    <div>
      {
                params.children.map((child, childIndex) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`${params.key}_${childIndex}`}>{child}</div>
                ))
            }
    </div>
  );

  const filterOptions = (myOptions) => myOptions;
  const changeMarkerPosition = (coords) => {
    if (searchMarkerRef.current) {
      maps.main.map.removeLayer(searchMarkerRef.current);
    }
    const svgString = `
      <svg width="70" height="100" xmlns="http://www.w3.org/2000/svg">
          <metadata id="metadata2822">image/svg+xml</metadata>
          <g>
              <title>background</title>
              <rect x="-1" y="-1" width="72" height="102" id="canvas_background" fill="none" />
          </g>
          <g>
              <title>Layer 1</title>
              <path d="m34.96515,99.35066c-0.21626,-0.52746 -4.70929,-4.93912 -7.92679,-8.94788c-19.06234,-25.41221 -40.31534,-49.02389 -15.49846,-77.7181c10.98839,-10.65954 24.53781,-11.26764 38.46711,-6.29901c39.2213,22.788 10.8246,59.96254 -7.821,84.40073l-7.22086,8.56426l0.00001,0l-0.00001,0z" id="path4127" 
              fill="rgba(3, 169, 244, 0.82)" stroke="null"
               />
              <path stroke="null" id="svg_1" d="m52.204,55.34367l-9.65583,-10.0154c1.30081,-2.22486 2.06369,-4.8278 2.06504,-7.61907c-0.00136,-8.14191 -6.35772,-14.73639 -14.20325,-14.7392c-7.84688,0.00281 -14.20596,6.59729 -14.20596,14.73779c0,8.13629 6.35908,14.73077 14.20596,14.73077c2.69241,0 5.2019,-0.79128 7.34688,-2.14054l9.65854,10.01681l4.78862,-4.97116zm-30.5813,-17.63588c0.00813,-5.03441 3.9336,-9.10466 8.78591,-9.1159c4.84959,0.01124 8.77778,4.08149 8.78591,9.1159c-0.00949,5.03159 -3.93631,9.10185 -8.78591,9.11309c-4.8523,-0.01124 -8.77778,-4.08149 -8.78591,-9.11309z" fill-opacity="null" stroke-opacity="null" stroke-width="10" fill="#ffffff" />
          </g>
      </svg>
    `;
    const iconUrl = `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`;
    hycon.debug(`${hookName} updateLayer`, {
      iconUrl,
    });
    const blueIcon = window.L.icon({
      iconUrl,
      iconSize: [36, 52], // size of the icon
      iconAnchor: [18, 52], // point of the icon which will correspond to marker's location
    });
    const searchMarker = window.L.marker([coords.latitude, coords.longitude], { icon: blueIcon });
    searchMarkerRef.current = searchMarker.addTo(maps.main.map);
  };

  // eslint-disable-next-line no-shadow
  const onOptionClick = async (event, options = { type: 'default', option: null }) => {
    hycon.debug('onOptionClick', {
      event, options,
    });
    if ([types.REGION_PLZ.NAME, types.REGION_TOWN.NAME].includes(options.option.type)) {
      try {
        /* eslint-disable */
        /*
        * Gets the first google result from the dropdown. This is used to navigate to the coordinates provided by google when
        * the user clicks on REGION_TOWN or REGION_PLZ options. The alternative would be to perform a rough reverse geocode.
        * */
        /* eslint-enable */
        const approximateCoordinatesResponse = await getApproximateCoordinates(
          options.option.label,
          sepContext.user.jwt,
          sepContext.env,
        );
        const { geometry } = approximateCoordinatesResponse.data.results[0];
        const coords = { latitude: geometry.location.lat, longitude: geometry.location.lng };
        maps.main.map.setView([coords.latitude, coords.longitude], 15);
        changeMarkerPosition(coords);
      } catch (e) {
        hycon.warn("onOptionClick - can't set the map view", {
          event, options, e,
        });
      }
    } else if ([
      types.GOOGLE.NAME,
      types.GOOGLE_AUTOCOMPLETE.NAME,
    ].includes(options.option.type)) {
      try {
        /*
        * Gets the Google-Place detail in order to retrieve the coordinates.
        * */
        const googlePlaceResponse = await getGooglePlace(
          options.option.row.place_id,
          sepContext.user.jwt,
          sepContext.env,
        );
        const { geometry } = googlePlaceResponse.data.result;
        const coords = { latitude: geometry.location.lat, longitude: geometry.location.lng };
        maps.main.map.setView([coords.latitude, coords.longitude], 15);
        changeMarkerPosition(coords);
      } catch (e) {
        hycon.warn("onOptionClick - can't set the map view", {
          event, options, e,
        });
      }
    } else {
      try {
        const coordsArray = options.option.row.fields.lat_long.split(';').map((v) => parseFloat(v));
        const coords = { latitude: coordsArray[0], longitude: coordsArray[1] };
        maps.main.map.setView([coords.latitude, coords.longitude], 15);
        changeMarkerPosition(coords);
      } catch (e) {
        hycon.warn("onOptionClick - can't set the map view", {
          event, options, e,
        });
      }
    }
    setSelection(options.option);
    setIsOpen(false);
    setSearchString(options.option.label);
  };

  const onCrosshairPositionReceived = (coords) => {
    hycon.debug('onCrosshairPositionReceived', {
      coords,
    });
    try {
      maps.main.map.setView([coords.latitude, coords.longitude], 15);
      changeMarkerPosition(coords);
    } catch (e) {
      hycon.warn("onCrosshairPositionReceived - can't set the map view", {
        coords, options, e,
      });
    }
  };

  const renderOption = (props, option) => (
    <SearchOption
      sepContext={sepContext}
      searchString={searchString}
      option={option}
      onOptionClick={onOptionClick}
      types={types}
    />
  );
  /* eslint-disable react/no-unknown-property, react/jsx-props-no-spreading */
  return (
    <div
      className="Search"
      style={{ width: '100%' }}
      css={css`
                  div.MuiAutocomplete-root {
                      background: transparent !important;
                  }
                  .MuiInputBase-root {
                     background: white !important;
                  }
                `}
    >
      <Autocomplete
        sx={{
          maxWidth: '800px',
          minWidth: '320px',
          width: '100%',
        }}
        value={searchString}
        blurOnSelect
        disableCloseOnSelect={false}
        PaperComponent={AutocompletePaper}
        onOpen={() => {
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        open={isOpen}
        noOptionsText={t(`${LOCIZE_PANEL_NS_SEARCH}:search-no-options`)}
        loading={isLoading}
        style={{ background: 'white' }}
        fullWidth
        disablePortal
        className="sep-search"
        options={options}
        groupBy={(option) => option.group}
        renderGroup={(params) => (
          <div style={{ width: '100%' }} key={`${params.key}`}>
            <Divider>{t(`${LOCIZE_PANEL_NS_SEARCH}:search-group-${params.group}`)}</Divider>
            {renderOptionsGroup(params)}
          </div>
        )}
        renderOption={renderOption}
        filterOptions={filterOptions}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.label === value.label}
        onInputChange={(event, string, reason) => {
          setSearchString(string);
          setResults([]);
          debouncedFetchOptions(event, string, reason);
        }}
        renderInput={(params) => (
          <TextField
            placeholder={t(`${LOCIZE_PANEL_NS_SEARCH}:search-placeholder`)}
            type="text"
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="end">
                  <MapCurrentLocationButton
                    sx={{ padding: 0 }}
                    onNewWatchLocation={(res) => {
                      hycon.debug('button: new location from watch', res);
                    }}
                    onNewLocation={(res) => {
                      hycon.debug('button: new location', res);
                      onCrosshairPositionReceived(res.coords);
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </div>
  /* eslint-enable react/no-unknown-property, react/jsx-props-no-spreading */
  );
}
