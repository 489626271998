import { useEffect } from 'react'
import HyperConsole from '../hyper_console/hyper-console'
import { withTranslation } from 'react-i18next'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}


function RecaptchaController (props) {
  const hookName = 'RecaptchaController'

  const hideRecaptcha = () => {
    try{
      let badges = document.querySelectorAll(".grecaptcha-badge");
      badges.forEach((badge) => {
        if(
          badge &&
          badge.getAttribute("data-style") === "bottomright" &&
          badge.style.visibility !== "hidden"
        ){
          badge.style.visibility = "hidden";
          hycon.debug(`${hookName} hideRecaptcha - hide`)
        } else {
          hycon.debug(`${hookName} hideRecaptcha - already hidden`)
        }
      })
    }catch (e) {
      hycon.error(`${hookName} useEffect`, e)
    }
  }

  useEffect(() => {
    hycon.debug(`${hookName} useEffect`, {})
    hideRecaptcha();
  })
  return null
}

export default withTranslation()(RecaptchaController)

