import React, { useState, useEffect, useCallback } from 'react'
import HyperConsole from '../hyper_console/hyper-console'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import * as L from 'leaflet'
import NonTiledLayer from "leaflet.nontiledlayer/dist/NonTiledLayer";
import "leaflet/dist/leaflet.css"
import "leaflet-draw/dist/leaflet.draw-src.js"
import "leaflet-draw/dist/leaflet.draw-src.css";
import './SEPMap.css'
import Controls from './overlay_controls/Controls'
import { OverlayDashboard } from './overlay_dashboard/OverlayDashboard'
import AlmostNewSelection from './component_selection/Selection'
import DeepLink from './overlay_deeplink/DeepLink'
import RemoteController from './component_remote/remote_controller/RemoteController'
import OpenController from './component_open/OpenController'
import {useMap} from "../../contexts/map/map-context";

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

function Google (props) {
  const hookName = 'Google'
  const mapId = 'sep-map-google'
  let env = props.reduxState.env
  const jwt = props.reduxState.user.jwt
  const initGoogleMap = () => {
    hycon.debug(`${hookName} initGoogleMap`, { props, mapId })
    try {
      let googleMap = new window.google.maps.Map(document.getElementById(mapId), {
        center: { lat: 46.948484, lng: 8.358491 },
        zoom: 8,
        mapTypeId: 'satellite',
        disableDefaultUI: true,
        /*zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false*/
      })
      googleMap.setTilt(0)
      props.onGoogleMapReady(googleMap)
    } catch (e){
      hycon.error(`${hookName} initGoogleMap`, { e, props, mapId })
    }
  }
  useEffect(() => {
    window.initGoogleMap = initGoogleMap;
    return () => {
      // cleanup
      try {
        // document.getElementById(mapId).outerHTML = "";
        // document.getElementById('google-maps').outerHTML = "";
        hycon.debug(`${hookName} initGoogleMap - cleared the old dom container and head script tag`, { props, mapId })
      }catch (e){
        hycon.err(`${hookName} initGoogleMap - cleared the old dom container and head script tag`, { e, props, mapId })
      }
    }
  }, [])

  useEffect(() => {
    hycon.debug(`${hookName} initGoogleMap - props changed`, { props, mapId })
    const addScriptToHead = (jwt) => {
      let hasScript = document.getElementById("google-maps") !== null;
      hycon.debug(`${hookName} loading google maps in headd.`, { hasScript })
      if(hasScript === false){
        let script = document.createElement('script')
        script.id = "google-maps"
        script.type = 'text/javascript'
        script.async = true
        script.defer = true
        script.src = `${env.API_GATEWAY_BASE}/google-maps-api/js?jwt=${jwt}&callback=initGoogleMap`
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        window.initGoogleMap();
      }
    }

    let myMapJWT;
    if(
        !jwt &&
        props.SEPContext &&
        props.SEPContext.user &&
        props.SEPContext.user.publicJWT
    ){
      myMapJWT = props.SEPContext.user.publicJWT
    } else {
      myMapJWT = jwt;
    }
    if(myMapJWT){
      addScriptToHead(myMapJWT);
    }
  }, [
      props?.SEPContext?.user?.jwt,
      props.location.pathname
  ])

  return (
    <div
      id={mapId}
    >

    </div>
  )
}

function Leaflet (props) {
  const hookName = 'Leaflet'
  const mapId = 'sep-map-leaflet'
  const { maps, setMaps } = useMap();
  const [layers, setLayers] = useState([])
  const [leafletMap, setLeafletMap] = useState(null)
  const [googleMap, setGoogleMap] = useState(null)
  const [lastDisplayMutation, setLastDisplayMutation] = useState(null)

  const syncMaps = useCallback(() => {
    // hycon.debug(`${hookName} syncMaps`, { leafletMap, googleMap })
    try {
      let leafletCenterLatLng = leafletMap.getCenter()
      let leafletZoom = leafletMap.getZoom();
      let leafletBounds = leafletMap.getBounds();

      /*googleMap.fitBounds({
        east: leafletBounds._northEast.lng,
        north: leafletBounds._northEast.lat,
        south: leafletBounds._southWest.lat,
        west: leafletBounds._southWest.lng
      }, {bottom: 0, left: 0, right: 0, top: 0});*/

      if(Number.isInteger(leafletZoom)){
        googleMap.panTo(leafletCenterLatLng);
        googleMap.setZoom(leafletZoom);
      } else {
        let roundedZoom = Math.round(leafletZoom); // prevents 404 on google satellite tiles
        hycon.warn(`${hookName} syncMaps - rounding zoom level`, { googleMap, leafletZoom, leafletBounds, leafletCenterLatLng })
        googleMap.panTo(leafletCenterLatLng);
        googleMap.setZoom(roundedZoom);
      }
      // hycon.debug(`${hookName} syncMaps`, { googleMap, leafletZoom, leafletBounds, leafletCenterLatLng })
    } catch (e) {
      hycon.error(`${hookName} syncMaps`, e)
    }
  }, [leafletMap, googleMap])

  useEffect(() => {
    // adresses issue https://github.com/facebook/react/issues/14536;
    if(leafletMap){
      console.log('style changed - leaflet invalidation', {leafletMap, lastDisplayMutation});
      leafletMap.invalidateSize();
      if(googleMap){
        syncMaps()
      }
    }
  }, [leafletMap, lastDisplayMutation, googleMap, syncMaps]);

  useEffect(() => {
    const observe = () => {
      let target = document.querySelector(`.App`);
      let width = target.style.width;
      console.log('tracking style changes of', {target, width});
      let observer = new MutationObserver((mutations) => {
        mutations.forEach((mutationRecord) => {
          console.log('style changed because of suspense load', { mutationRecord});
          setLastDisplayMutation(width)
        });
      });
      observer.observe(target, { attributes : true, attributeFilter : ['style'] });
      return observer
    }
    // adresses issue https://github.com/facebook/react/issues/14536;
    let observer = observe();
    return () => {
      observer.disconnect();
    }
  }, [])

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - props`, { props, mapId })
    if (
      leafletMap === null &&
      props.SEPContext &&
      props.SEPContext.user &&
      props.SEPContext.user.publicJWT
    ){
      // setup all maps for public sep
      hycon.debug(`${hookName} useEffect - setupMap (public SEP access)`, { props, mapId });
      try {
        setupMap()
      } catch (e){
        hycon.warn("map init error", e);
      }
    }

    if (
      leafletMap === null &&
      props.reduxState &&
      props.reduxState.user &&
      props.reduxState.user.jwt
    ){
      // setup all maps for private sep
      hycon.debug(`${hookName} useEffect - setupMap (private SEP access)`, { props, mapId })
      try {
        setupMap()
      } catch (e){
        hycon.warn("map init error", e);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  useEffect(() => {
    // hycon.debug(`${hookName} useEffect - init map sync`, { props, mapId })
    if(leafletMap && googleMap){
      initMapSync()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leafletMap, googleMap])

  useEffect(() => {
    // hycon.debug(`${hookName} useEffect - selectedLayer`, { props, mapId })
    if (leafletMap) {
      if (props.selectedMap.name === props.maps.Leaflet.name) {
        if (props.selectedLayer === props.maps.Leaflet.layers.grey) {
          leafletMap.removeLayer(layers)
          setLayer(props.selectedMap, props.selectedLayer)
        }
        if (props.selectedLayer === props.maps.Leaflet.layers.color) {
          leafletMap.removeLayer(layers)
          setLayer(props.selectedMap, props.selectedLayer)
        }
        if (props.selectedLayer === props.maps.Leaflet.layers.cadastral) {
          leafletMap.removeLayer(layers)
          setLayer(props.selectedMap, props.selectedLayer)
        }
      } else if (props.selectedMap.name === props.maps.Google.name) {
        if (props.selectedLayer === props.maps.Google.layers.satellite) {
          // remove leaflet layers
          leafletMap.removeLayer(layers)
          setLayer(props.selectedMap, props.selectedLayer)
        }
      }
    }
    // eslint-disable-next-line
  }, [props.selectedLayer, props.selectedMap])

  const modifyLeafletHeaders = () => {
    // https://github.com/Esri/esri-leaflet/issues/743
    L.TileLayer.WMS_Headers = L.TileLayer.WMS.extend({
      setUrl: function (url, noRedraw) {
        hycon.warn('modifyLeafletHeaders setUrl', { url, noRedraw })
        if (this._url === url && noRedraw === undefined) {
          noRedraw = true
        }

        this._url = url

        if (!noRedraw) {
          this.redraw()
        }
        return this
      },
      _removeTile: function (key) {
        hycon.warn('modifyLeafletHeaders _removeTile', { key, this: this, L })
        var tile = this._tiles[key]
        if (!tile) {
          hycon.warn('modifyLeafletHeaders _removeTile - no tile', { key, tile, this: this, L })
          return
        }
        // Cancels any pending http requests associated with the tile
        // unless we're on Android's stock browser,
        // see https://github.com/Leaflet/Leaflet/issues/137
        if (!L.Browser.androidStock) {
          hycon.warn('modifyLeafletHeaders _removeTile - android', { key, tile, this: this, L })
          tile.el.setAttribute('src', L.Util.emptyImageUrl)
        }
        return L.GridLayer.prototype._removeTile.call(this, key)
      },
      _tileOnError: function (done, tile, e) {
        hycon.warn('modifyLeafletHeaders _tileOnError', { done, tile, e, this: this, L })
        var errorUrl = this.options.errorTileUrl
        if (errorUrl && tile.getAttribute('src') !== errorUrl) {
          tile.src = errorUrl
        }
        done(e, tile)
      },
      _abortLoading: function () {
        hycon.warn('modifyLeafletHeaders _abortLoading', { this: this, L })
        let mapZoom = this._map._zoom
        if (
          mapZoom >= 18
        ) {
          hycon.warn('modifyLeafletHeaders _abortLoading - zoom', { mapZoom, this: this, L })
        }

        var i, tile
        for (i in this._tiles) {
          if (this._tiles[i].coords.z !== this._tileZoom) {
            hycon.warn('modifyLeafletHeaders _abortLoading - z coord !== tileZoom', {
              tileZoom: this._tileZoom,
              this: this,
              L
            })
            tile = this._tiles[i].el

            tile.onload = L.Util.falseFn
            tile.onerror = L.Util.falseFn

            if (!tile.complete) {
              tile.src = L.Util.emptyImageUrl
              hycon.warn('modifyLeafletHeaders _abortLoading - removed tile', { tile, this: this, L })
              L.DomUtil.remove(tile)
              delete this._tiles[i]
            }
          }
        }
      },
      _tileOnLoad: function (done, tile) {
        hycon.debug('modifyLeafletHeaders _tileOnLoad', { tile, done, this: this, L })
        // For https://github.com/Leaflet/Leaflet/issues/3332
        if (L.Browser.ielt9) {
          setTimeout(L.Util.bind(done, this, null, tile), 0)
        } else {
          done(null, tile)
        }
      },
      _getZoomForUrl: function () {
        var zoom = this._tileZoom,
          maxZoom = this.options.maxZoom,
          zoomReverse = this.options.zoomReverse,
          zoomOffset = this.options.zoomOffset

        if (zoomReverse) {
          zoom = maxZoom - zoom
        }
        let finalZoom = zoom + zoomOffset
        return finalZoom
        /*
        hycon.debug("modifyLeafletHeaders _getZoomForUrl", {zoom, maxZoom, zoomReverse, zoomOffset, finalZoom});
        if(finalZoom > 18){
          return 18;
        } else {
          return finalZoom;
        }*/
      },
      _tileReady: function (coords, err, tile) {
        hycon.debug('modifyLeafletHeaders _tileReady', { tile, coords, err, this: this, L })
        if (
          !this._map ||
          (tile && tile.getAttribute('src') === L.Util.emptyImageUrl)
        ) {
          hycon.debug('modifyLeafletHeaders _tileReady - no src or map', {
            tile,
            coords,
            err,
            this: this,
            L,
            src: tile.getAttribute('src'),
            emptyImageUrl: L.Util.emptyImageUrl
          })
          return
        } else {
          hycon.debug('modifyLeafletHeaders _tileReady - src found', {
            tile,
            coords,
            err,
            this: this,
            L,
            src: tile.getAttribute('src'),
            emptyImageUrl: L.Util.emptyImageUrl
          })
          return L.GridLayer.prototype._tileReady.call(this, coords, err, tile)
        }
      },
      getTileUrl: function (coords) {

        let { getParamString } = L.Util

        var tileBounds = this._tileCoordsToNwSe(coords),
          crs = this._crs,
          bounds = new L.Bounds(crs.project(tileBounds[0]), crs.project(tileBounds[1])),
          min = bounds.min,
          max = bounds.max,
          bbox = (this._wmsVersion >= 1.3 && this._crs === L.CRS.EPSG4326 ?
            [min.y, min.x, max.y, max.x] :
            [min.x, min.y, max.x, max.y]).join(','),
          url = L.TileLayer.prototype.getTileUrl.call(this, coords)
        let tileUrl = url +
          getParamString(this.wmsParams, url, this.options.uppercase) +
          (this.options.uppercase ? '&BBOX=' : '&bbox=') + bbox
        hycon.debug('modifyLeafletHeaders getTileUrl', { tileUrl, coords, this: this, L })
        return tileUrl

      },
      createTile (coords, done) {
        const url = this.getTileUrl(coords)
        hycon.debug('modifyLeafletHeaders createTile', url)
        const img = document.createElement('img')
        axios(
          {
            method: 'get',
            url: url,
            responseType: 'blob',
            headers: {
              /*'Authorization': `Bearer ${jwt}`,*/
              /* 'Referer': 'https://energyapps.ch' */
            }
          }
        )
        .then((response) => {
          hycon.debug('modifyLeafletHeaders response', response)
          img.src = URL.createObjectURL(response.data)
          done(null, img)
        })
        .catch(e => {
          hycon.error('modifyLeafletHeaders error', e)
          // img.src = URL.createObjectURL(response.data);
          done(null, img)
        })
        return img
      }
    })
    L.tileLayer.wms_headers = (url, options) => new L.TileLayer.WMS_Headers(url, { ...options })
  }

  const getLayerGroup = (targetBaseLayer) => {
    hycon.info(`${hookName} getLayerGroup`, targetBaseLayer)
    let subLayers = targetBaseLayer.layers
    let layerArray = []
    for (let p in subLayers) {
      if (subLayers.hasOwnProperty(p)) {
        let subLayer = subLayers[p]
        let type = subLayer.type
        let url = subLayer.url
        let options = subLayer.options
        if (type === 'wms') {
          let layer = L.tileLayer.wms(url, options)
          layerArray.push(layer)
        } else if (type === 'wmts') {
          modifyLeafletHeaders()
          L.tileLayer.wms_headers(url, options)
          let layer = L.tileLayer(url, options)
          layerArray.push(layer)
        } else if(type === "nontiled-wms"){
          let layer = new NonTiledLayer.WMS(url, options);
          layerArray.push(layer)
        }
      }
    }
    let layerGroup = L.layerGroup(layerArray)
    hycon.info(`${hookName} getLayerGroup`, { layerArray, layerGroup })
    return layerGroup
  }

  const setupMap = () => {
    hycon.debug(`${hookName} setupMap`, { L })
    let LEAFLET_MAX_NATIVE_ZOOM_LEVEL = 18
    let {MAP_SWISSTOPO_GREY_URL, MAP_SWISSTOPO_COLOR_URL, MAP_CADASTRAL_COLOR_URL, MAP_SWISSTOPO_STREETNAMES} = getLeafletLayerUrls();
    let baseLayers = {
      'MAP_SWISSTOPO_GREY_URL': {
        layers: {
          'MAP_SWISSTOPO_GREY_URL': {
            type: 'wmts',
            url: MAP_SWISSTOPO_GREY_URL,
            options: {
              maxZoom: 21,
              maxNativeZoom: LEAFLET_MAX_NATIVE_ZOOM_LEVEL,
              opacity: 1
            }
          },
          'MAP_SWISSTOPO_STREETNAMES': {
            type: 'wms',
            url: MAP_SWISSTOPO_STREETNAMES,
            options: {
              layers: 'ch.swisstopo.amtliches-strassenverzeichnis',
              format: 'image/png',
              transparent: true,
              bgcolor: 'ffffff',
              opacity: 0.82,
              maxZoom: 21,
              maxNativeZoom: LEAFLET_MAX_NATIVE_ZOOM_LEVEL,
              className: 'custom-layer MAP_SWISSTOPO_STREETNAMES',
              minZoom: 18
            }
          }
        }
      },
      'MAP_SWISSTOPO_COLOR_URL': {
        layers: {
          'MAP_SWISSTOPO_COLOR_URL': {
            type: 'wmts',
            url: MAP_SWISSTOPO_COLOR_URL,
            options: {
              maxZoom: 21,
              maxNativeZoom: LEAFLET_MAX_NATIVE_ZOOM_LEVEL,
              opacity: 1
            }
          },
          'MAP_SWISSTOPO_STREETNAMES': {
            type: 'wms',
            url: MAP_SWISSTOPO_STREETNAMES,
            options: {
              layers: 'ch.swisstopo.amtliches-strassenverzeichnis',
              format: 'image/png',
              transparent: true,
              bgcolor: 'ffffff',
              opacity: 0.82,
              maxZoom: 21,
              maxNativeZoom: LEAFLET_MAX_NATIVE_ZOOM_LEVEL,
              className: 'custom-layer MAP_SWISSTOPO_STREETNAMES',
              minZoom: 18
            }
          }
        }
      },
      'MAP_CADASTRAL_COLOR_URL': {
        layers: {
          'MAP_CADASTRAL_COLOR_URL': {
            type: 'nontiled-wms',
            url: MAP_CADASTRAL_COLOR_URL,
            options: {
              layers: "ch.kantone.cadastralwebmap-farbe",
              maxZoom: 21,
              maxNativeZoom: LEAFLET_MAX_NATIVE_ZOOM_LEVEL,
              opacity: 1
            }
          }
        }
      },
      'MAP_GOOGLE_SATELLITE': {
        layers: {
          'MAP_GOOGLE_SATELLITE': {
            type: null,
            url: null,
            options: null
          }
        }

      }
    }

    let mapOptions = {
      zoomDelta: 1,
      zoomSnap: 1,
      minZoom: 7,
      // maxZoom: LEAFLET_MAX_ZOOM_LEVEL,
      maxZoom: 21,
      wheelPxPerZoomLevel: 60,
      scrollWheelZoom: true,
      zoomControl: false,
      attributionControl: false,
      doubleClickZoom: false,
      inertia: true,
      maxBounds: [[49.152970, 3.847413], [44.653024, 14.626486]],
      editable: true,
      preferCanvas: true
    }

    const defaultPosition = [46.948484, 8.358491]
    let map = L.map(mapId, mapOptions)
    map.setView(defaultPosition, 9, {animate: false});

    let targetBaseLayer = null
    let selectedBaseLayerName = null
    if (props.selectedMap.name === props.maps.Leaflet.name) {
      targetBaseLayer = baseLayers.MAP_SWISSTOPO_GREY_URL
      selectedBaseLayerName = props.maps.Leaflet.layerNames.MAP_SWISSTOPO_GREY_URL
    } else if (props.selectedMap.name === props.maps.Leaflet.name) {
      targetBaseLayer = baseLayers.MAP_SWISSTOPO_COLOR_URL
      selectedBaseLayerName = props.maps.Leaflet.layerNames.MAP_SWISSTOPO_COLOR_URL
    } else if (props.selectedMap.name === props.maps.Leaflet.name) {
      targetBaseLayer = baseLayers.MAP_CADASTRAL_COLOR_URL
      selectedBaseLayerName = props.maps.Leaflet.layerNames.MAP_CADASTRAL_COLOR_URL
    } else if (props.selectedMap.name === props.maps.Google.name) {
      targetBaseLayer = baseLayers.MAP_GOOGLE_SATELLITE
      selectedBaseLayerName = props.maps.Google.layerNames.MAP_GOOGLE_SATELLITE
    }
    let baseLayersGroup = getLayerGroup(targetBaseLayer)

    setLayers(baseLayersGroup)

    baseLayersGroup.addTo(map)

    props.reduxDispatch('updateMapObjects', {
      map: map,
      Leaflet: L,
      mapState: {
        selectedBaseLayer: {
          name: selectedBaseLayerName
        }
      }
    })
    setMaps({...maps, main: {
        map: map,
        mapState: {
          selectedBaseLayer: {
            name: selectedBaseLayerName
          }
        }
      }})
    setLeafletMap(map);
  }

  const getLeafletLayerUrls = () => {
    // proxy setting
    // let baseWMTS = "https://map-proxy.exoscale.swissenergyplanning.ch/wmts-proxy";
    // let baseWMS = "https://map-proxy.exoscale.swissenergyplanning.ch/wms-proxy";
    // let MAP_SWISSTOPO_GREY_URL = `${baseWMTS}/1.0.0/ch.swisstopo.pixelkarte-grau/default/current/3857/{z}/{x}/{y}.jpeg`
    // let MAP_SWISSTOPO_STREETNAMES = `${baseWMS}/?`
    // let MAP_SWISSTOPO_COLOR_URL = `${baseWMTS}/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg`
    // let MAP_CADASTRAL_COLOR_URL = `${baseWMTS}/1.0.0/ch.kantone.cadastralwebmap-farbe/default/current/3857/{z}/{x}/{y}.png`

    // https://wms.geo.admin.ch/
    let baseWMTS = "https://wmts.geo.admin.ch";
    let baseWMS = "https://wms.geo.admin.ch";
    let MAP_SWISSTOPO_GREY_URL = `${baseWMTS}/1.0.0/ch.swisstopo.pixelkarte-grau/default/current/3857/{z}/{x}/{y}.jpeg`
    let MAP_SWISSTOPO_STREETNAMES = `${baseWMS}/?`
    let MAP_SWISSTOPO_COLOR_URL = `${baseWMTS}/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg`
    let MAP_CADASTRAL_COLOR_URL = `${baseWMS}/?`

    return {
      MAP_SWISSTOPO_GREY_URL, MAP_SWISSTOPO_COLOR_URL, MAP_CADASTRAL_COLOR_URL, MAP_SWISSTOPO_STREETNAMES
    }
  }

  const setLayer = (selectedMap, selectedLayer) => {
    let LEAFLET_MAX_NATIVE_ZOOM_LEVEL = 18
    let {MAP_SWISSTOPO_GREY_URL, MAP_SWISSTOPO_COLOR_URL, MAP_CADASTRAL_COLOR_URL, MAP_SWISSTOPO_STREETNAMES} = getLeafletLayerUrls();
    let baseLayers = {
      'MAP_SWISSTOPO_GREY_URL': {
        layers: {
          'MAP_SWISSTOPO_GREY_URL': {
            type: 'wmts',
            url: MAP_SWISSTOPO_GREY_URL,
            options: {
              maxZoom: 21,
              maxNativeZoom: LEAFLET_MAX_NATIVE_ZOOM_LEVEL,
              opacity: 1
            }
          },
          'MAP_SWISSTOPO_STREETNAMES': {
            type: 'wms',
            url: MAP_SWISSTOPO_STREETNAMES,
            options: {
              layers: 'ch.swisstopo.amtliches-strassenverzeichnis',
              format: 'image/png',
              transparent: true,
              bgcolor: 'ffffff',
              opacity: 0.82,
              maxZoom: 21,
              maxNativeZoom: LEAFLET_MAX_NATIVE_ZOOM_LEVEL,
              className: 'custom-layer MAP_SWISSTOPO_STREETNAMES',
              minZoom: 18
            }
          }
        }
      },
      'MAP_SWISSTOPO_COLOR_URL': {
        layers: {
          'MAP_SWISSTOPO_COLOR_URL': {
            type: 'wmts',
            url: MAP_SWISSTOPO_COLOR_URL,
            options: {
              maxZoom: 21,
              maxNativeZoom: LEAFLET_MAX_NATIVE_ZOOM_LEVEL,
              opacity: 1
            }
          },
          'MAP_SWISSTOPO_STREETNAMES': {
            type: 'wms',
            url: MAP_SWISSTOPO_STREETNAMES,
            options: {
              layers: 'ch.swisstopo.amtliches-strassenverzeichnis',
              format: 'image/png',
              transparent: true,
              bgcolor: 'ffffff',
              opacity: 0.82,
              maxZoom: 21,
              maxNativeZoom: LEAFLET_MAX_NATIVE_ZOOM_LEVEL,
              className: 'custom-layer MAP_SWISSTOPO_STREETNAMES',
              minZoom: 18
            }
          }
        }
      },
      'MAP_CADASTRAL_COLOR_URL': {
        layers: {
          'MAP_CADASTRAL_COLOR_URL': {
            type: 'nontiled-wms',
            url: MAP_CADASTRAL_COLOR_URL,
            options: {
              layers: "ch.kantone.cadastralwebmap-farbe",
              maxZoom: 21,
              maxNativeZoom: LEAFLET_MAX_NATIVE_ZOOM_LEVEL,
              opacity: 1
            }
          }
        }
      },
      'MAP_GOOGLE_SATELLITE': {
        layers: {
          'MAP_GOOGLE_SATELLITE': {
            type: null,
            url: null,
            options: null
          }
        }

      }
    }

    let targetBaseLayer = null
    let selectedBaseLayerName = null
    if (selectedMap.name === props.maps.Leaflet.name) {
      if (selectedLayer === props.maps.Leaflet.layers.grey) {
        targetBaseLayer = baseLayers.MAP_SWISSTOPO_GREY_URL
        selectedBaseLayerName = props.maps.Leaflet.layerNames.MAP_SWISSTOPO_GREY_URL
      }
      if (selectedLayer === props.maps.Leaflet.layers.color) {
        targetBaseLayer = baseLayers.MAP_SWISSTOPO_COLOR_URL
        selectedBaseLayerName = props.maps.Leaflet.layerNames.MAP_SWISSTOPO_COLOR_URL
      }
      if (selectedLayer === props.maps.Leaflet.layers.cadastral) {
        targetBaseLayer = baseLayers.MAP_CADASTRAL_COLOR_URL
        selectedBaseLayerName = props.maps.Leaflet.layerNames.MAP_CADASTRAL_COLOR_URL
      }
    } else if (selectedMap.name === props.maps.Google.name) {
      if (selectedLayer === props.maps.Google.layers.satellite) {
        targetBaseLayer = baseLayers.MAP_GOOGLE_SATELLITE
        selectedBaseLayerName = props.maps.Google.layerNames.MAP_GOOGLE_SATELLITE
      }
    }
    let baseLayersGroup = getLayerGroup(targetBaseLayer)
    setLayers(baseLayersGroup)
    baseLayersGroup.addTo(leafletMap)

    props.reduxDispatch('updateMapObjects', {
      ...props.reduxState.map,
      mapState: {
        ...props.reduxState.map.mapState,
        selectedBaseLayer: {
          name: selectedBaseLayerName
        }
      }
    })
    setMaps({...maps, main: {
        ...(maps.main || {}),
        mapState: {
          selectedBaseLayer: {
            name: selectedBaseLayerName
          }
        }
      }})
  }

  const initMapSync = () => {
    hycon.debug(`${hookName} initMapSync`, { leafletMap, googleMap })
    if (leafletMap && googleMap) {
      hycon.debug(`${hookName} initMapSync - all maps ready`, { leafletMap, googleMap })
      leafletMap.on('move zoom zoomanim resize', () => {
        syncMaps()
      })
      // initial sync
      syncMaps()
    } else {
      hycon.debug(`${hookName} initMapSync - maps not ready`, { leafletMap, googleMap })
    }
  }

  const getMapByPath = () => {
    if(props.location.pathname === "/open/map/"){
      return (
        <React.Fragment>
          <div className={'map-stack leaflet'}>
            {/*<div
              className={`map-layer google ${props.selectedMap.name === props.maps.Google.name ? 'selected-map-layer-google' : 'selected-map-layer-leaflet'}`}
            >
              <Google
                {...props}
                onGoogleMapReady={(googleMap) => {
                  hycon.debug(`${hookName} onGoogleMapReady`, { googleMap })
                  setGoogleMap(googleMap)
                }}
              />
            </div>*/}
            <div
              className={`map-layer leaflet ${props.selectedMap.name === props.maps.Google.name ? 'selected-map-layer-google' : 'selected-map-layer-leaflet'}`}
            >
              <div
                id={mapId}
                className={`Leaflet`}
              >

              </div>
            </div>
            <div className={`overlay-layer controls`}>
              <Controls {...props}/>
            </div>
            <div className={`overlay-layer dashboard`}>
              <OverlayDashboard {...props}/>
            </div>
            {/*<div className={`component-selection`}>
              <Selection {...props}/>
            </div>*/}
            <div className={`overlay-layer deeplink`}>
              <DeepLink {...props} leafletMap={leafletMap}/>
            </div>
            <div className={`component-open-controller`}>
              <OpenController {...props} leafletMap={leafletMap} onMapChange={props.onMapChange} onLayerChange={props.onLayerChange}/>
            </div>
          </div>
        </React.Fragment>
      )
    } else if(props.location.pathname === "/open/remote/"){
      return (
        <React.Fragment>
          <div className={'map-stack leaflet'}>
            <div
              className={`map-layer google ${props.selectedMap.name === props.maps.Google.name ? 'selected-map-layer-google' : 'selected-map-layer-leaflet'}`}
            >
              <Google
                {...props}
                onGoogleMapReady={(googleMap) => {
                  hycon.debug(`${hookName} onGoogleMapReady`, { googleMap })
                  setGoogleMap(googleMap)
                }}
              />
            </div>
            <div
              className={`map-layer leaflet ${props.selectedMap.name === props.maps.Google.name ? 'selected-map-layer-google' : 'selected-map-layer-leaflet'}`}
            >
              <div
                id={mapId}
                className={`Leaflet`}
              >

              </div>
            </div>
            <div className={`overlay-layer controls`}>
              <Controls {...props}/>
            </div>
            <div className={`component-remote-controller`}>
              <RemoteController {...props} leafletMap={leafletMap}/>
            </div>
            {/*<div className={`overlay-layer dashboard`}>
              <OverlayDashboard {...props}/>
            </div>*/}
            {/*<div className={`component-selection`}>
              <Selection {...props}/>
            </div>*/}
            {/*<div className={`overlay-layer deeplink`}>
              <DeepLink {...props} leafletMap={leafletMap}/>
            </div>*/}
          </div>
        </React.Fragment>
      )
    }
  }

  const getMapByUser = () => {
    hycon.debug(`${hookName} getMapByUser`, { props });
    if(
      props.SEPContext &&
      props.SEPContext.user &&
      props.SEPContext.user.publicJWT
    ){
      hycon.debug(`${hookName} getMapByUser - (public)`, { props });
      return getMapByPath();
    } else {
      hycon.debug(`${hookName} getMapByUser - (not public)`, { props });
      return (
        <React.Fragment>
          <div className={'map-stack leaflet'}>
            <div
              className={`map-layer google ${props.selectedMap.name === props.maps.Google.name ? 'selected-map-layer-google' : 'selected-map-layer-leaflet'}`}
            >
              <Google
                {...props}
                onGoogleMapReady={(googleMap) => {
                  hycon.debug(`${hookName} onGoogleMapReady`, { googleMap })
                  setGoogleMap(googleMap)
                }}
              />
            </div>
            <div
              className={`map-layer leaflet ${props.selectedMap.name === props.maps.Google.name ? 'selected-map-layer-google' : 'selected-map-layer-leaflet'}`}
            >
              <div
                id={mapId}
                className={`Leaflet`}
              >

              </div>
            </div>
            <div className={`overlay-layer controls`}>
              <Controls {...props}/>
            </div>
            <div className={`overlay-layer dashboard`}>
              <OverlayDashboard {...props}/>
            </div>
            <div className={`component-selection`}>
              <AlmostNewSelection {...props}/>
            </div>
            <div className={`overlay-layer deeplink`}>
              <DeepLink {...props} leafletMap={leafletMap}/>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  return getMapByUser();
}

function SEPMap (props) {
  const hookName = 'SEPMap'

  const maps = {
    'Leaflet': {
      name: 'Leaflet',
      layerNames: {
        MAP_SWISSTOPO_GREY_URL: 'MAP_SWISSTOPO_GREY_URL',
        MAP_SWISSTOPO_COLOR_URL: 'MAP_SWISSTOPO_COLOR_URL',
        MAP_CADASTRAL_COLOR_URL: 'MAP_CADASTRAL_COLOR_URL'
      },
      layers: {
        grey: 'grey',
        color: 'color',
        cadastral: 'cadastral',
      }
    },
    'Google': {
      name: 'Google',
      layerNames: {
        MAP_GOOGLE_SATELLITE: 'MAP_GOOGLE_SATELLITE'
      },
      layers: {
        satellite: 'satellite'
      }
    }
  }
  // const [selectedMap, setSelectedMap] = useState(maps.Leaflet);
  const [selectedMap, setSelectedMap] = useState(maps.Leaflet)
  const [selectedLayer, setSelectedLayer] = useState(maps.Leaflet.layers.grey)

  const onMapChange = (selectedMap) => {
    hycon.debug(`${hookName} onMapChange`, { selectedMap, props })
    setSelectedMap(selectedMap)
  }
  const onLayerChange = (selectedLayer) => {
    hycon.debug(`${hookName} onLayerChange`, { selectedLayer, props })
    setSelectedLayer(selectedLayer)
  }

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - SEPContext`, { props })
    const jwt = props.reduxState.user.jwt;
    let hasPublicToken = (
      props.SEPContext &&
      props.SEPContext.user &&
      props.SEPContext.user.publicJWT
    )

    if(
      !!jwt === false &&
      !!hasPublicToken === false &&
      props.SEPContext.user.isUrlParsed === true
    ){
      // redirect to signin
      let continuePath = `${props.history.location.pathname}${props.history.location.search}`;
      hycon.debug(`${hookName} authenticationRedirect - continuePath`, { continuePath, location: props.history.location })
      props.history.push(`/user/signin/?continuePath=${encodeURIComponent(continuePath)}`)
    }
    return () => {
      // hycon.debug(`${hookName} useEffect`, { props })
    }
  // eslint-disable-next-line
  }, [props.reduxState.user.jwt])

  return (
    <div className="SEPMap">
      <Leaflet
        {...props}
        maps={maps}
        selectedMap={selectedMap}
        selectedLayer={selectedLayer}
        onMapChange={onMapChange}
        onLayerChange={onLayerChange}
      />
    </div>
  )
}

export default withTranslation(['map'])(SEPMap)

