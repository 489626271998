import React, {
  useState, useEffect, useContext, useReducer,
} from 'react';
import './Favorites.css';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle } from '@mui/material';
import HyperConsole from '../hyper_console/hyper-console';
import Navbar from '../sep_map/component_navbar/Navbar';
import 'izitoast/dist/css/iziToast.css';
import SEPContext from '../../contexts/sep-context/SEPContext';
import { FavoriteContext } from './FavoriteProvider';
import FavoriteList from './components/favorite-list/FavoriteList';
import FavoriteFilter from './components/favorite-filter/FavoriteFilter';
import {
  LOCIZE_PANEL_NS, reducerActions, REACT_APP_GI_ENV,
} from './constants';
import FavoriteActionBar from './components/favorite-action-bar/FavoriteActionBar';
import FavoritePagination from './components/favorite-pagination/FavoritePagination';
import FavoriteModals from './components/favorite-modals/FavoriteModals';
import FavoriteApi from './services/FavoriteApi';
import HyperMenu from '../hyper_menu/HyperMenu';
import FavoriteSort from './components/favorite-sort/FavoritesSort';
import FavoriteUtils from './services/FavoriteUtils';

let hycon = null;
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
}

function Favorites(props) {
  const { history } = props;
  const hookName = 'Favorites';
  const [menu, setMenu] = useState(null);
  const [amountOfFavorites, setAmountOfFavorites] = useState(null);
  const { t } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false });
  const [isLoading, setIsLoading] = useState(false);
  const { user: { jwt }, env } = useContext(SEPContext).SEPContext;

  const getFavorites = async () => {
    const [addressResponse, regionsResponse, sortingSettings] = await Promise.all([
      FavoriteApi.getFavoriteAddresses(jwt, env),
      FavoriteApi.getFavoriteRegions(jwt, env),
      FavoriteApi.getUserFavoriteSortingMethod(jwt, env),
    ]);

    const mergedFavorites = [
      ...addressResponse.data.map((element) => ({
        ...element,
        favoriteType: 'ADDRESS',
      })),
      ...regionsResponse.data.map((element) => ({
        ...element,
        favoriteType: 'REGION',
      })),
    ].sort(
      sortingSettings.sort === 'alphabetically' ? FavoriteUtils.compareFavoritesByLocation : FavoriteUtils.compareFavoritesByDate,
    );

    setAmountOfFavorites(mergedFavorites.length);

    const [addressGroupsResponse, regionGroupsResponse] = await Promise.all([
      FavoriteApi.getFavoriteAddressGroups(jwt, env),
      FavoriteApi.getFavoriteRegionGroups(jwt, env),
    ]);

    const mergedGroups = [...addressGroupsResponse.data, ...regionGroupsResponse.data];

    return {
      mergedFavorites,
      mergedGroups,
    };
  };

  const getTargetFavorites = (context) => {
    const selectedFavorites = (context.favorites || [])
      .filter((favorite) => (context.selection || []).includes(favorite.id));
    const modals = context.modals || [];
    const modal = modals[modals.length - 1];
    let clickedFavorites = [];
    if (modal) {
      clickedFavorites = context.favorites.filter((f) => modal.favoriteIdArray.includes(f.id));
    }
    if (clickedFavorites.length > 0) {
      return clickedFavorites;
    }
    return selectedFavorites;
  };

  const isGroupEditMode = (context) => {
    const targetFavorites = getTargetFavorites(context);
    const groups = targetFavorites.map((f) => f.group);
    const uniqueGroups = [...new Set(groups)];
    if (uniqueGroups.length > 1) {
      return false;
    }
    const targetGroup = uniqueGroups[0];
    const favoritesOfTargetGroup = targetFavorites.filter((f) => f.group === targetGroup);
    const allFavoritesOfTargetGroup = context.favorites.filter((f) => f.group === targetGroup);
    return (
      favoritesOfTargetGroup.length === allFavoritesOfTargetGroup.length
    );
  };

  const [state, dispatch] = useReducer(FavoriteUtils.reducer, {
    favoriteTypes: {
      ADDRESS: 'ADDRESS', REGION: 'REGION',
    },
    favorites: [],
    selectedPage: 1,
    groups: [],
    paginationFavorites: [],
    filterFavorites: [],
    targetFavorites: [],
    selection: [],
    modals: [],
    loadingFavorites: [],
    notificationInfo: {
      isActive: false,
      state: {},
    },
    notificationEdit: {
      isActive: false,
      state: {},
    },
    dispatch: () => {},
    getTargetFavorites,
    isGroupEditMode,
    getFavorites,
  });

  const loadInitialState = async () => {
    hycon.debug(`${hookName} useEffect - constructor - loadInitialState`, { state });
    setIsLoading(true);
    const {
      mergedFavorites,
      mergedGroups,
    } = await getFavorites();
    dispatch({
      type: reducerActions.REPLACE,
      payload: {
        newState: {
          ...state,
          favorites: mergedFavorites,
          groups: mergedGroups,
          dispatch,
        },
      },
    });
    setIsLoading(false);
  };

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - constructor`, { state });
    loadInitialState();
  }, []);

  const getContent = () => {
    if (
      props.reduxState
      && props.reduxState.session
      && props.reduxState.session.user
      && props.reduxState.session.user.mandant === 'guest'
      && props.reduxState.session.user.role === 'Guest'
    ) {
      return (
        <div>
          <h5 style={{ textAlign: 'center', marginTop: '15px' }}>{t(`${LOCIZE_PANEL_NS}:license-needed`)}</h5>
        </div>
      );
    }
    return (
      <>
        {amountOfFavorites >= 8000
              && (
              <div className="alert-wrapper">
                <div className="alert-container">
                  <Alert severity="warning" style={{ width: '100%' }}>
                    <AlertTitle>{t('exceeded-max-amount-of-favorites', { ns: 'favorites' })}</AlertTitle>
                    {t('please-contact-administration', { ns: 'favorites' })}
                  </Alert>
                </div>
              </div>
              )}
        <FavoriteFilter />
        <FavoriteActionBar />
        <FavoriteSort />
        <FavoritePagination maxPerPage={50} />
        <FavoriteList history={history} isLoading={isLoading} />
        <FavoriteModals />
      </>
    );
  };

  return (
    <div className="favorites">
      <HyperMenu
        {...props}
        ref={(c) => {
          setMenu(c);
        }}
        content={
          (
            <div className="menu-content-wrapper">
              <Navbar
                {...props}
                onMenuToggle={() => {
                  menu.setStateProperty('isOpen', !menu.getState().isOpen);
                }}
                isSearchDisplayed={false}
              />
              <div className="Favorites">
                <FavoriteContext.Provider value={state}>
                  {getContent()}
                </FavoriteContext.Provider>
              </div>
            </div>
          )
        }
      />
    </div>
  );
}

export default Favorites;
