import io from 'socket.io-client'

export default class RemoteLogger {
  constructor () {
    let remoteLoggerHost = `http://${window.location.hostname}:5000`
    console.info(`HyperConsole connecting to socket on ${remoteLoggerHost}`)
    this.socket = io(remoteLoggerHost)
  }

  emit (...args) {
    console.log('emitting', args)

    let stringJson = null
    try {
      stringJson = JSON.stringify(args)
    } catch (e) {
      // console.warn("can't send log", args);
    }
    if (stringJson !== null) {
      this.socket.emit('message', stringJson)
    }
  }
}
