import { createTheme } from '@mui/material/styles';

const geoimpact = createTheme({
  palette: {
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba( 0, 0, 0, 0.12)"
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#689F38',
    },
    'light_primary': {
      main: '#DCEDC8',
    },
    'light_transparent_primary': {
      main: 'rgba(220,237,200, 0.76)',
    },
    secondary: {
      main: '#0e72b5',
    },
    'accent_transparent': {
      main: 'rgba(14, 114, 181, 0.76)',
    },
    error: {
      main: '#ff0000',
    },
    warning: {
      main: '#ffaa00',
    }
  },
});
const praedictio = createTheme({
  palette: {
    ...geoimpact.palette,
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#84018A',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    'light_primary': {
      main: 'rgba(193,0,202,0.76)',
    },
    'light_transparent_primary': {
      main: 'rgb(132,1,138, 0.76)',
    },
    secondary: {
      main: '#F2C94C',
    },
    'accent_transparent': {
      main: 'rgb(242,201,76, 0.76)',
    },
  },
});

export const themes = {
  geoimpact, praedictio
}
