import React, {Component} from 'react';
import HyperConsole from "../../../../hyper_console/hyper-console";
import {panelController} from "./panel-controller";
import "./RoofDetail.css";


import {
    Button,
    Icon,
} from 'semantic-ui-react';

import axios from "axios";
import {UserUtil} from "../../../../user_management/util/user-util";
import { withTranslation } from 'react-i18next'

let hycon = new HyperConsole({isEnabled: false, name:__filename}).myConsole;

const getLowestVertex = (geometriesObject) => {
    // geometriesObject.coordinates[""0""][""0""]
    let coords = geometriesObject.coordinates[0][0];

    // let minLat = -90;
    let maxLat = 90;

    // let minLng = -180;
    let maxLng = 180;

    let targetLat = maxLat;
    let targetLng = maxLng;
    coords.forEach((coord) => {
        let lat = coord[1];
        let lng = coord[0];
        if (lat < targetLat) {
            targetLat = lat;
        }
        if (lng < targetLng) {
            targetLng = lng;
        }
    });
    return {targetLat, targetLng};
};

const trans = (ctx, key) => {
    return ctx.props.i18n.t(key);
};

class RoofDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layers: {},
            mapState: {},
            isDisplayed: false,
            isMounted: false,
            listeners: null,
        };
        let thisRef = this;
        hycon.debug(`${this.constructor.name} constructor`, {props: thisRef.props});
    }

    setStateProperty(propertyName, propertyValue) {
        let thisRef = this;
        return new Promise((res) => {
            thisRef.setState((p) => {
                const obj = {};
                obj[propertyName] = propertyValue;
                return Object.assign({}, p, obj);
            }, () => {
                hycon.debug(`${this.constructor.name} setStateProperty - newState`, {newState: thisRef.state});
                res();
            })
        });
    }

    setMapState() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} setMapState`);
        let internalState = panelController.getInternalState();
        let map = internalState.map.map;
        let center = map.getCenter();
        let zoom = map.getZoom();
        return thisRef.setStateProperty("mapState", {center, zoom});
    }

    resetMapToParentState() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} resetMapToParentState`);
        let internalState = panelController.getInternalState();
        let map = internalState.map.map;

        try {
            let center = thisRef.state.mapState.center;
            let zoom = thisRef.state.mapState.zoom;
            map.flyTo(center, zoom);
        } catch (e) {
            hycon.warn(e);
        }
    }

    componentDidMount() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} componentDidMount`, {props: thisRef.props});
        return thisRef.setStateProperty("isMounted", true).then(() => {
            let listeners = thisRef.initListeners(
                window,
                () => {
                    // on enter
                    if (thisRef.state.isMounted) {
                        hycon.debug(`${this.constructor.name} componentDidMount onEnter`, {props: thisRef.props});
                        thisRef.setStateProperty("isDisplayed", true);
                    }
                },
                () => {
                    // on leave
                    if (thisRef.state.isMounted) {
                        hycon.debug(`${this.constructor.name} componentDidMount onLeave`, {props: thisRef.props});
                        thisRef.setStateProperty("isDisplayed", false);
                    }
                }
            );

            let observer = {
                notify: () => {
                    hycon.debug(`${this.constructor.name} componentDidMount notify`, {props: thisRef.props});
                    // hide everything
                    let internalState = panelController.getInternalState();
                    thisRef.setOverviewRoofsDisplayed(false, internalState);
                    thisRef.setBestRoofsDisplayed(false, internalState);

                    thisRef.setMapState().then(() => {
                        thisRef.focusRoof();
                    });
                }
            };
            panelController.subscribe(0, observer);

            thisRef.setStateProperty("listeners", listeners);
            thisRef.props.panelController.dispatchSubdetailEnter(window, {});
        });
    }


    static getDerivedStateFromProps(props, state) {
        hycon.debug(`${"RoofDetail"} getDerivedStateFromProps`, {props, state});
        if (!state) {
            hycon.debug(`${"RoofDetail"} getDerivedStateFromProps - only props have been updated`, {props, state});
        }
        return state;
    }

    shouldComponentUpdate(nextProps, nextState) {
        // let thisRef = this;
        hycon.debug(`${this.constructor.name} shouldComponentUpdate`, {nextProps, props: this.props, nextState});
        return true;
    }

    initListeners(listenerObject, onEnter, onLeave) {
        let thisRef = this;
        hycon.debug(`RoofDetail panel-controller: initListeners`, {listenerObject, onEnter, onLeave});
        let leaveFn = function (e) {
            hycon.debug(`RoofDetail panel-controller: processing panel event`, {e});
            onLeave();
        };
        let enterFn = function (e) {
            hycon.debug(`RoofDetail panel-controller: processing panel event`, {e});
            onEnter();
            thisRef.setMapState().then(() => {
                thisRef.focusRoof();
            });
        };

        listenerObject.addEventListener("panel-subdetail-enter", enterFn);
        listenerObject.addEventListener("panel-subdetail-leave", leaveFn);
        return {enterFn, leaveFn};
    };

    removeListeners(listenerObject, listeners) {
        hycon.debug(`RoofDetail panel-controller: removeListeners`, {listenerObject, listeners});
        try {
            listenerObject.removeEventListener("panel-subdetail-enter", listeners.enterFn);
            listenerObject.removeEventListener("panel-subdetail-leave", listeners.leaveFn);
        } catch (e) {
            hycon.warn(e);
        }
    };

    bringLayerToFront() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} bringLayerToFront`, {props: this.props, state: thisRef.state});
        try {
            // thisRef.state.layers.bestRoofGroup.bringToFront();

            let internalState = panelController.getInternalState();
            let map = internalState.map.map;

            if (typeof thisRef.state.layers.bestRoofGroup !== "undefined") {
                hycon.debug(`${this.constructor.name} bringLayerToFront bestRoofGroup zIndex update`, {
                    props: this.props,
                    state: thisRef.state
                });
                map.removeLayer(thisRef.state.layers.bestRoofGroup);
                thisRef.state.layers.bestRoofGroup.addTo(map);
            }
        } catch (e) {
            hycon.warn(e);
        }
    }

    focusCoords(lat, lng) {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} focusCoords`, {props: this.props});
        let panelController = thisRef.props.panelController;
        panelController.flyTo(lat, lng);
    }

    getRoof(roofId) {
        let thisRef = this;
        let roofGeometriesEndpoint =
            `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/api/roof-geometries?id=${roofId}`;
        // `&klasse=[e]${classes[index]}&flaeche=[ge]${lastData.settings.roofs.filter.minRoofExtent}`;
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        let jwt = thisRef.props.reduxState.user.jwt;

        let roofGeometriesRequest = axios({
            method: 'get',
            url: roofGeometriesEndpoint,
            headers: {
                'Accept': "application/json",
                'Authorization': `Bearer ${jwt}`,
            },
            cancelToken: source.token
        })
            .then(function (response) {
                // handle success
                hycon.debug(`${thisRef.constructor.name} getRoof`, {response});
                return response;
            })
            .catch((e) => {
                hycon.debug(`${thisRef.constructor.name} getRoof`, {e});
                UserUtil.authenticationRedirect(e.response, thisRef);
            });
        return roofGeometriesRequest;
    }

    displayGeometries(internalState, geometriesObject) {
        let thisRef = this;
        hycon.debug(`${thisRef.constructor.name} displayGeometries`, {internalState, geometriesObject});
        let klasse = thisRef.props.renderingData.relevantRoof.klasse; // relevantRoof can be undefined
        let colors = [
            "#192f9f",
            "#00c5ff",
            "#ffaa01",
            "#ff5501",
            "#a80000", // dark red
            "#ffffff"
        ];

        const getStyle = (colorIndex, opacity = 0.9) => {
            let myStyle = {
                "color": colors[colorIndex],
                "weight": 4,
                "opacity": opacity
            };
            return myStyle;
        };

        let geometries = [];
        try {
            let geojsonFeature = geometriesObject;
            let geojson = internalState.map.Leaflet.geoJSON(geojsonFeature, {
                style: getStyle(klasse-1, 0.9)
            });
            geometries.push(geojson);
        } catch (e) {
            hycon.debug(`panel-controller: displayDistinctGeoJson error`, {e});
            hycon.error(e);
        }
        let bestRoofGroup = internalState.map.Leaflet.layerGroup([...geometries]);
        bestRoofGroup.addTo(internalState.map.map);
        return bestRoofGroup;
    }

    setOverviewRoofsDisplayed(isDisplayed, internalState){
        let thisRef = this;
        hycon.debug(`${thisRef.constructor.name} setOverviewRoofsDisplayed`, {isDisplayed, internalState, thisRef});
        let map = internalState.map.map;
        // let bestRoofGroup = thisRef.state.layers.bestRoofGroup;
        if(isDisplayed){
            let parentLayerGroupLayers = thisRef.state.layers.parentLayerGroup.getLayers();
            parentLayerGroupLayers.forEach((layer) => {
                try {
                    layer.addTo(map);
                }catch (e) {
                    hycon.warn(`${thisRef.constructor.name} setOverviewRoofsDisplayed - error`, {e, parentLayerGroupLayers, layer});
                }
            })
        } else if(!isDisplayed){
            let parentLayerGroupLayers = thisRef.state.layers.parentLayerGroup.getLayers();
            parentLayerGroupLayers.forEach((layer) => {
                try {
                    map.removeLayer(layer);
                }catch (e) {
                    hycon.warn(`${thisRef.constructor.name} setOverviewRoofsDisplayed - error`, {e, parentLayerGroupLayers, layer});
                }
            })
        } else {
            hycon.warn(`${thisRef.constructor.name} setOverviewRoofsDisplayed - no parentLayerGroup ?`, {isDisplayed, internalState});
        }
    }

    setBestRoofsDisplayed(isDisplayed, internalState){
        let thisRef = this;
        hycon.debug(`${thisRef.constructor.name} setBestRoofsDisplayed`, {isDisplayed, internalState, thisRef});
        let map = internalState.map.map;
        // let bestRoofGroup = thisRef.state.layers.bestRoofGroup;
        if(isDisplayed){
            let bestRoofGroupLayers = thisRef.state.layers.bestRoofGroup.getLayers();
            bestRoofGroupLayers.forEach((layer) => {
                try {
                    layer.addTo(map);
                }catch (e) {
                    hycon.warn(`${thisRef.constructor.name} setOverviewRoofsDisplayed - error`, {e, bestRoofGroupLayers, layer});
                }
            })
        } else if(!isDisplayed){
            let bestRoofGroupLayers = thisRef.state.layers.bestRoofGroup.getLayers();
            bestRoofGroupLayers.forEach((layer) => {
                try {
                    map.removeLayer(layer);
                }catch (e) {
                    hycon.warn(`${thisRef.constructor.name} setOverviewRoofsDisplayed - error`, {e, bestRoofGroupLayers, layer});
                }
            })
        } else {
            hycon.warn(`${thisRef.constructor.name} setOverviewRoofsDisplayed - no bestRoofGroup ?`, {isDisplayed, internalState});
        }
    }

    highlightRoofGeometries(internalState, geometriesObj) {
        let thisRef = this;
        hycon.debug(`${thisRef.constructor.name} highlightRoofGeometries`, {props: thisRef.props});
        let distinctLayers = panelController.getInternalState().distinctLayers;

        let parentLayerGroup = null;
        distinctLayers.forEach((layer) => {
            hycon.debug(`${thisRef.constructor.name} highlightRoofGeometries group layer`, {layer});
            parentLayerGroup = layer;

            let bestRoofGroup = thisRef.displayGeometries(internalState, geometriesObj);

            thisRef.setStateProperty("layers", {bestRoofGroup, parentLayerGroup}).then(() => {
                hycon.debug(`${thisRef.constructor.name} highlightRoofGeometries - done`, {layers: thisRef.state.layers});

                // remove the previous layer
                thisRef.setOverviewRoofsDisplayed(false, internalState);
            });
        });

        /*
        map.eachLayer((layer) => {
            hycon.debug(`${thisRef.constructor.name} highlightRoofGeometries map layer`, {layer});
        });
        */
    }

    focusRoof() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} focusRoof`, {props: this.props});
        try {
            let relevantRoof = thisRef.props.renderingData.relevantRoof; // relevantRoof can be undefined
            let roofId = relevantRoof.id; // relevantRoof can be undefined

            thisRef.getRoof(roofId).then((res) => {
                let geometries = res.data.geometries;
                let geometriesObject = JSON.parse(geometries);
                hycon.debug(`${thisRef.constructor.name} focusRoof geometries`, {geometriesObject});

                // let coords = geometriesObject.coordinates[0][0][0];

                let lowestCoords = getLowestVertex(geometriesObject);

                // coords[1] -> lat
                // coords[0] -> lng
                thisRef.focusCoords(lowestCoords.targetLat, lowestCoords.targetLng);

                let internalState = panelController.getInternalState();

                thisRef.highlightRoofGeometries(internalState, geometriesObject);
            });
        } catch (e) {
            hycon.warn(e);
        }
    }

    render() {
        let thisRef = this;
        // let relevantRoof = thisRef.props.renderingData.relevantRoof;
        let buildingIndex = thisRef.props.renderingData.buildingIndex;
        let component = thisRef.props.renderingData.component;
        return (
            <div className="RoofDetail">
                <div className={`relevantRoofDetail`}>
                    <div className={"controls"}>
                        <Button
                            icon
                            size={"large"}
                            onClick={() => {
                                let id = thisRef.props.id;
                                hycon.debug("Clicked card close " + id);
                                thisRef.props.panelController.dispatchSubdetailLeave(window);

                                // display the roofs that were hidden
                                let internalState = panelController.getInternalState();
                                thisRef.setOverviewRoofsDisplayed(true, internalState);

                                thisRef.props.onClose();
                            }}
                        >
                            <Icon name='close'/>
                        </Button>
                    </div>
                    <div className={`body`}>
                        <h2>{trans(thisRef, "panel_solar_electricity:label-best-roof-of-building")} {buildingIndex}</h2>
                        <div>
                            {component}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidUpdate() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} componentDidUpdate`, {props: this.props});

        // hide best roof if selection is cleared
        if(thisRef.props.reduxState.selectedBuildings.length === 0){
            let id = thisRef.props.id;
            hycon.debug("Simulated  card close " + id);
            thisRef.props.panelController.dispatchSubdetailLeave(window);

            // display the roofs that were hidden
            let internalState = panelController.getInternalState();
            thisRef.setOverviewRoofsDisplayed(true, internalState);

            thisRef.props.onClose();
        }
    }

    componentWillUnmount() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} componentWillUnmount`, {props: this.props, thisRef});
        thisRef.removeListeners(window, thisRef.state.listeners);

        let internalState = panelController.getInternalState();
        let map = internalState.map.map;

        // restore the all roofs layer from the parent
        // thisRef.state.layers.parentLayerGroup.addTo(map);

        map.removeLayer(thisRef.state.layers.bestRoofGroup);
        thisRef.resetMapToParentState();
        panelController.unsubscribe(0);
    }
}

export default withTranslation([
  "panel_solar_electricity"
])(RoofDetail);

