import env from '../../env/env'
import HyperConsole from '../hyper_console/hyper-console'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV;

let hycon = null;
if(REACT_APP_GI_ENV === "development"){
  hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
} else {
  hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
}

class GoogleAnalyticsClass {
  constructor () {
    this.constants = {}
  }

  addGTagScriptToHead () {
    // let thisRef = this;
    return new Promise((res) => {
      let script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://www.googletagmanager.com/gtag/js?id=${env.GOOGLE_ANALYTICS_ID}`
      script.onload = () => {
        hycon.log(`google analytics - addGTagScriptToHead - onload`)
        window.dataLayer = window.dataLayer || []

        function gtag () {
          window.dataLayer.push(arguments)
        }

        gtag('js', new Date())
        gtag('config', env.GOOGLE_ANALYTICS_ID)
        hycon.log(`google analytics - addGTagScriptToHead - config - done`)
        window.gtag = gtag

        let handle = null
        const isLoded = () => {
          let isLoaded = !!window.ga
          if (isLoaded) {
            hycon.debug(`google analytics is ready now ${new Date()}`)
            clearInterval(handle)
            res()
          } else {
            hycon.debug(`google analytics is not ready yet ${new Date()}`)
          }
        }
        handle = setInterval(isLoded, 1000)
      }
      document.getElementsByTagName('head')[0].appendChild(script)
      hycon.log(`google analytics - addGTagScriptToHead - adding script to head section`)
    })
  }

  sendOpenEvent () {
    if ('gtag' in window) {
      window.gtag('event', 'navigation', {
        'event_label': 'opens SEP',
        'event_category': 'platform navigation',
        'non_interaction': true
      })
      hycon.log(`google analytics - sendOpenEvent - ok`)
    } else {
      hycon.log(`google analytics - sendOpenEvent - error`)
    }
  }

  sendGenerigEvent (action, label, category, interactive, opts = {}) {
    if ('gtag' in window) {
      window.gtag('event', action, {
        'event_label': label,
        'event_category': category,
        'non_interaction': interactive,
        ...opts
      })
      hycon.log(`google analytics - sendGenerigEvent - ok`)
    } else {
      hycon.log(`google analytics - sendGenerigEvent - error`)
    }
  }

  init () {
    let thisRef = this
    return this.addGTagScriptToHead()
      .then(() => {
        thisRef.sendOpenEvent()
      })
  }
}

export const GoogleAnalytics = new GoogleAnalyticsClass()
