import { /*useState,*/ useEffect, /*useRef*/ } from 'react'
import HyperConsole from '../hyper_console/hyper-console'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import iziToast from 'izitoast'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
const LOCIZE_PANEL_NS = 'various'
let hycon = null
if (
  REACT_APP_GI_ENV === 'development'
) {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

export default function WriteLock (props) {
  const fnName = 'WriteLock'
  // let env = props.reduxState.env
  // const jwt = props.reduxState.user.jwt
  const { t } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false })

  useEffect(() => {
    hycon.debug(`${fnName} useEffect - write lock interceptor constructor`, { props })
    let interceptor = axios.interceptors.response.use(function (response) {
      hycon.debug(`${fnName} useEffect - intercepted`, { response })
      // Do something before response is handled
      return response
    }, function (error) {
      // Do something with request error
      hycon.debug(`${fnName} useEffect - intercepted error`, { error })
      if(error && error.response && error.response.status === 423){
        iziToast.show({
          theme: 'dark',
          id: 'loading-toast',
          color: '#ff6a00',
          zindex: 1001, // must be higher than the modal
          message: `${t(`${LOCIZE_PANEL_NS}:sep-is-in-locked-mode`)}`,
          position: 'topCenter',
          drag: false,
        })
      }
      return Promise.reject(error)
    })
    return () => {
      // cleanup
      hycon.debug(`${fnName} useEffect - write lock interceptor cleanup`, { props })
      try {
        axios.interceptors.response.eject(interceptor)
      } catch (e) {
        hycon.error(e)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}
