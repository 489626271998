import React, { useEffect } from 'react';
import { Icon, Pagination } from 'semantic-ui-react';
import { useFavoriteCtx } from '../../FavoriteProvider';
import { reducerActions, REACT_APP_GI_ENV } from '../../constants';
import HyperConsole from '../../../hyper_console/hyper-console';

import './FavoritePagination.css';

let hycon = null;
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
}

export default function FavoritePagination(props) {
  const hookName = 'FavoritePagination';
  const favoriteContext = useFavoriteCtx();
  const { maxPerPage } = props;
  useEffect(() => {
    hycon.debug(`${hookName} useEffect - constructor`, { props });
  }, []);
  useEffect(() => {
    hycon.debug(`${hookName} useEffect - selectedPage`, { props });
  }, [favoriteContext.selectedPage]);
  const updatePaginationFavorites = () => {
    hycon.debug(`${hookName} useEffect - updatePaginationFavorites`, { props });
    const newPaginationFavorites = (favoriteContext.filterFavorites || [])
      .concat([]).splice((favoriteContext.selectedPage - 1) * maxPerPage, maxPerPage);
    favoriteContext.dispatch({
      type: reducerActions.REPLACE,
      info: 'updatePaginationFavorites',
      payload: {
        newState: {
          paginationFavorites: newPaginationFavorites,
        },
      },
    });
  };
  useEffect(() => {
    hycon.debug(`${hookName} useEffect - favoriteContext.filterFavorites`, { props });
    updatePaginationFavorites();
  }, [favoriteContext.filterFavorites]);
  useEffect(() => {
    hycon.debug(`${hookName} useEffect - favoriteContext.paginationFavorites`, { props });
  }, [favoriteContext.paginationFavorites]);

  return (
    <div className={hookName}>
      <div className="container">
        <Pagination
          activePage={favoriteContext.selectedPage}
          siblingRange={1}
          ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
          firstItem={{ content: <Icon name="angle double left" />, icon: true }}
          lastItem={{ content: <Icon name="angle double right" />, icon: true }}
          prevItem={{ content: <Icon name="angle left" />, icon: true }}
          nextItem={{ content: <Icon name="angle right" />, icon: true }}
          totalPages={Math.ceil((favoriteContext.filterFavorites || []).length / maxPerPage)}
          onPageChange={async (event, data) => {
            hycon.debug(`${hookName} onPageChange`, { data });
            const newPaginationFavorites = (favoriteContext.filterFavorites || [])
              .concat([]).splice((data.activePage - 1) * maxPerPage, maxPerPage);
            hycon.debug(`${hookName} onPageChange`, { props, newPaginationFavorites });
            // reset the scroll
            try {
              document.querySelectorAll('.FavoriteList')[0].scrollTo(0, 0);
            } catch (e) {
              hycon.warn(`${hookName} onPageChange`, { e });
            }
            favoriteContext.dispatch({
              type: reducerActions.REPLACE,
              info: 'onPageChange',
              payload: {
                newState: {
                  selectedPage: data.activePage,
                  paginationFavorites: newPaginationFavorites,
                },
              },
            });
          }}
        />
      </div>
    </div>
  );
}
