import { useEffect } from 'react'
import HyperConsole from '../../hyper_console/hyper-console'
import './DeepLink.css'
import qs from 'qs'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

export default function DeepLink (props) {
  const hookName = 'DeepLink'

  const getParsedState = () => {
    try {
      if (
        props.history && props.history.location && props.history.location.search
      ) {
        let search = props.history.location.search
        search = search.substr(1, search.length)
        const parsed = qs.parse(search)
        if (parsed.state) {
          let parsedState = null
          try {
            hycon.debug(`${hookName} getParsedState - trying`, { parsed, props, state: parsed.state })
            parsedState = JSON.parse(decodeURIComponent(parsed.state))
          } catch (e) {
            hycon.error(`${hookName} getParsedState`, { e, parsed, props, state: parsed.state })
          }
          hycon.debug(`${hookName} getParsedState`, { parsedState, props, state: parsed.state })
          return parsedState
        } else {
          hycon.debug(`${hookName} getParsedState - no state in query string to parse`)
          return null
        }
      } else {
        hycon.debug(`${hookName} getParsedState - no props.history.location.searc`, { props })
        return null
      }
    } catch (e) {
      hycon.error(`${hookName} getParsedState`, { props, e })
      return null
    }
  }
  let parsedState = getParsedState()
  const switchMap = () => {
    let layerName = null
    try {
      layerName = parsedState.mapState.selectedBaseLayer.name
    } catch (e) {
      hycon.debug(`${hookName} restoreSepState - restore link with no layer selected`, { parsedState })
    }
    if (layerName === props.maps.Leaflet.layerNames.MAP_SWISSTOPO_GREY_URL) {
      props.onMapChange(props.maps.Leaflet)
      props.onLayerChange(props.maps.Leaflet.layers.grey)
    } else if (layerName === props.maps.Leaflet.layerNames.MAP_SWISSTOPO_COLOR_URL) {
      props.onMapChange(props.maps.Leaflet)
      props.onLayerChange(props.maps.Leaflet.layers.color)
    } else if (layerName === props.maps.Leaflet.layerNames.MAP_CADASTRAL_COLOR_URL) {
      props.onMapChange(props.maps.Leaflet)
      props.onLayerChange(props.maps.Leaflet.layers.cadastral)
    } else if (layerName === props.maps.Google.layerNames.MAP_GOOGLE_SATELLITE) {
      props.onMapChange(props.maps.Google)
      props.onLayerChange(props.maps.Google.layers.satellite)
    }
  }
  const fitMapBounds = () => {
    // https://github.com/Leaflet/Leaflet/issues/3249
    let boundsToRestore = parsedState.mapState.bounds;
    setTimeout(() => {
      props.leafletMap.fitBounds([
        [boundsToRestore._northEast.lat, boundsToRestore._northEast.lng], [boundsToRestore._southWest.lat, boundsToRestore._southWest.lng]
      ], {
        animate: false
      })
    }, 1000)
  }
  const restoreClicks = () => {
    let points = parsedState.mapState.selection.points
    let event = new CustomEvent('sep_deeplink:mapclick', { detail: JSON.stringify(points) })
    window.dispatchEvent(event)
    hycon.debug(`${hookName} useEffect - restoreClicks - fire event`, { event, parsedState })
  }

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - deeplink all props`, { props })
    let isReadyToRestore = () => {
      return !!(
        props.leafletMap &&
        props.history &&
        props.history.location &&
        props.history.location.search
      );
    }
    if (isReadyToRestore() === true) {
      hycon.debug(`${hookName} useEffect - deeplink - restore`, { parsedState })
      try {
        switchMap()
        fitMapBounds()
        restoreClicks()
      } catch (e) {
        hycon.error(`${hookName} useEffect - deeplink - restore`, { e })
      }
    } else {
      hycon.warn(`${hookName} useEffect - deeplink - restore not ready`, { props })
    }
    return () => {
      // hycon.debug(`${hookName} useEffect - cleanup`, { props })
    }
    // eslint-disable-next-line
  }, [props.leafletMap, props.history.location])

  return null
}

