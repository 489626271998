/* eslint-disable */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import iziToast from 'izitoast';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { Modal } from 'semantic-ui-react';
import { LOCIZE_PANEL_NS, reducerActions, REACT_APP_GI_ENV } from '../../constants';
import { useFavoriteCtx } from '../../FavoriteProvider';
import HyperConsole from '../../../hyper_console/hyper-console';

import './FavoriteModals.css';
import ModalFormNotification from './ModalFormNotification';
import ModalFormRecipient from './ModalFormRecipient';
import ModalFormGroup from './ModalFormGroup';
import FavoriteApi from '../../services/FavoriteApi';
import SEPContext from '../../../../contexts/sep-context/SEPContext';

let hycon = null;
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
}

export default function FavoriteModals() {
  const hookName = 'FavoriteModals';
  const favoriteContext = useFavoriteCtx();
  const { t } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false });
  const targetFavorites = favoriteContext.getTargetFavorites(favoriteContext);
  const { user: { jwt }, env } = useContext(SEPContext).SEPContext;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const deleteExistingEmailsForAllTargets = async (emailsToDelete) => {
    hycon.debug(`${hookName} deleteExistingEmailsForAllTargets`, { emailsToDelete });
    for (let i = 0; i < emailsToDelete.length; i += 1) {
      for (let j = 0; j < targetFavorites.length; j += 1) {
        const favorite = targetFavorites[j];
        hycon.debug(`${hookName} deleteExistingEmailsForAllTargets`, { emailsToDelete, favorite });
        try {
          await FavoriteApi.deleteRecipientForEventTypes(jwt, env, favorite);
        } catch (e) {
          hycon.error(`${hookName} deleteExistingEmailsForAllTargets - error - favoriteId:${favorite.id} for ${emailsToDelete}`, e);
          return null;
        }
      }
    }
  };

  const getAllTargetRecipients = () => {
    const allRecipients = [];
    targetFavorites.forEach((fav, i) => {
      fav.recipients.forEach((rec, i) => {
        allRecipients.push(rec);
      });
    });
    return allRecipients;
  };
  const getUniqueTargetRecipientsEmails = () => {
    const allEmails = getAllTargetRecipients().map((r) => r.email);
    return [...new Set(allEmails)];
  };

  const updateRecipients = async (newEmailsArray) => {
    const isGroupEditMode = favoriteContext.isGroupEditMode(favoriteContext);
    const emailsToDelete = getUniqueTargetRecipientsEmails();
    const createRecipients = async () => {
      for (let i = 0; i < newEmailsArray.length; i += 1) {
        const email = newEmailsArray[i];
        try {
          await FavoriteApi.postRecipient(jwt, env, email);
        } catch (e) {
          hycon.error(`${hookName} createRecipient - error`, { e });
          if (e.response.status === 404) {
            iziToast.show({
              theme: 'dark',
              id: 'loading-toast',
              color: 'var(--gi_oragne)',
              zindex: 1001, // must be higher than the modal
              message: `${email} ${t('favorites:not-allowed-email')}.`,
              position: 'topCenter',
              drag: false,
            });
          }
          return null;
        }
      }
    };

    const deleteRecipientOfAddressByGroup = async (targetGroup) => {
      for (let i = 0; i < emailsToDelete.length; i += 1) {
        const email = emailsToDelete[i];
        try {
          await FavoriteApi.deleteRecipientOfAddressByGroup(jwt, env, targetGroup, email);
        } catch (e) {
          hycon.error(`${hookName} deleteRecipientOfAddressByGroup - error`, { e });
          return null;
        }
      }
    };

    const addRecipientOfAddressByGroup = async (targetGroup) => {
      for (let i = 0; i < newEmailsArray.length; i += 1) {
        const email = newEmailsArray[i];
        try {
          await FavoriteApi.addRecipientOfAddressGroupByEmail(jwt, env, targetGroup, email);
        } catch (e) {
          hycon.error(`${hookName} addRecipientOfAddressByGroup - error`, { e });
          return null;
        }
      }
    };

    const deleteRecipientOfRegionByGroup = async (targetGroup) => {
      for (let i = 0; i < emailsToDelete.length; i += 1) {
        const email = emailsToDelete[i];
        try {
          await FavoriteApi.deleteRecipientOfRegionByGroup(jwt, env, targetGroup, email);
        } catch (e) {
          hycon.error(`${hookName} deleteRecipientOfRegionByGroup - error`, { e });
          return null;
        }
      }
    };
    const addRecipientOfRegionByGroup = async (targetGroup) => {
      for (let i = 0; i < newEmailsArray.length; i += 1) {
        const email = newEmailsArray[i];
        try {
          await FavoriteApi.addRecipientOfRegionByGroup(jwt, env, targetGroup, email);
        } catch (e) {
          hycon.error(`${hookName} addRecipientOfRegionByGroup - error`, { e });
          return null;
        }
      }
    };

    const deleteRecipientOfAddress = async (favoriteId) => {
      for (let i = 0; i < emailsToDelete.length; i += 1) {
        const email = emailsToDelete[i];
        try {
          await FavoriteApi.deleteRecipientOfAddressByEmail(jwt, env, favoriteId, email);
        } catch (e) {
          hycon.error(`${hookName} deleteRecipientOfAddress - error`, { e });
          return null;
        }
      }
    };
    const addRecipientOfAddress = async (favoriteId) => {
      for (let i = 0; i < newEmailsArray.length; i += 1) {
        const email = newEmailsArray[i];
        try {
          await FavoriteApi.addRecipientOfAddressByEmail(jwt, env, favoriteId, email);
        } catch (e) {
          hycon.error(`${hookName} addRecipientOfAddress - error`, { e });
          return null;
        }
      }
    };
    const deleteRecipientOfRegion = async (favoriteId) => {
      for (let i = 0; i < emailsToDelete.length; i += 1) {
        const email = emailsToDelete[i];
        try {
          await FavoriteApi.deleteRecipientOfRegionByEmail(jwt, env, favoriteId, email);
        } catch (e) {
          hycon.error(`${hookName} deleteRecipientOfRegion - error`, { e });
          return null;
        }
      }
    };
    const addRecipientOfRegion = async (favoriteId) => {
      for (let i = 0; i < newEmailsArray.length; i += 1) {
        const email = newEmailsArray[i];
        try {
          await FavoriteApi.addRecipientOfRegionByEmail(jwt, env, favoriteId, email);
        } catch (e) {
          hycon.error(`${hookName} addRecipientOfRegion - error`, { e });
          return null;
        }
      }
    };

    const targetAddressFavorites = targetFavorites
      .filter((f) => f.favoriteType === favoriteContext.favoriteTypes.ADDRESS);
    const targetRegionFavorites = targetFavorites
      .filter((f) => f.favoriteType === favoriteContext.favoriteTypes.REGION);

    if (isGroupEditMode) {
      const targetGroup = targetFavorites[0].group;
      hycon.debug(`${hookName} updateRecipients - group`, { targetGroup, targetFavorites, favoriteContext });
      await createRecipients();
      if (targetAddressFavorites.length > 0) {
        await deleteRecipientOfAddressByGroup(targetGroup);
        await addRecipientOfAddressByGroup(targetGroup);
      }
      if (targetRegionFavorites.length > 0) {
        await deleteRecipientOfRegionByGroup(targetGroup);
        await addRecipientOfRegionByGroup(targetGroup);
      }
    } else {
      await createRecipients();
      for (let i = 0; i < targetFavorites.length; i += 1) {
        const favorite = targetFavorites[i];
        hycon.debug(`${hookName} updateRecipients - single`, {
          favorite,
          newEmailsArray,
          emailsToDelete,
          targetFavorites,
          favoriteContext,
        });
        if (targetAddressFavorites.length > 0) {
          await deleteRecipientOfAddress(favorite.id);
          await addRecipientOfAddress(favorite.id);
        }
        if (targetRegionFavorites.length > 0) {
          await deleteRecipientOfRegion(favorite.id);
          await addRecipientOfRegion(favorite.id);
        }
      }
    }
  };
  const getAllUniqueActiveRecipients = () => {
    const allActiveRecipients = [];
    favoriteContext.favorites.forEach((f) => {
      f.recipients.forEach((r) => {
        if (r.isConfirmed) {
          allActiveRecipients.push(r.email);
        }
      });
    });
    const uniqueActiveRecipients = [...new Set(allActiveRecipients)];
    hycon.debug(`${hookName} getAllUniqueActiveRecipients`, { favoriteContext });
    return uniqueActiveRecipients;
  };
  const getAllUniqueRecipients = () => {
    const allRecipients = [];
    favoriteContext.favorites.forEach((f) => {
      f.recipients.forEach((r) => {
        allRecipients.push(r.email);
      });
    });
    const uniqueRecipients = [...new Set(allRecipients)];
    hycon.debug(`${hookName} getAllUniqueRecipients`, { favoriteContext });
    return uniqueRecipients;
  };
  const getAllUniqueGroups = () => {
    const allGroups = [];
    favoriteContext.favorites.forEach((f) => {
      allGroups.push(f.group);
    });
    const uniqueGroups = [...new Set(allGroups)];
    hycon.debug(`${hookName} getAllUniqueGroups`, { favoriteContext });
    return uniqueGroups;
  };
  const onModalClose = (event, data) => {
    hycon.debug(`${hookName} onModalClose`, { event, data });
    favoriteContext.dispatch({
      type: reducerActions.REPLACE,
      info: 'onModalClose',
      payload: {
        newState: {
          modals: [],
        },
      },
    });
  };

  const updateContext = async () => {
    hycon.debug(`${hookName} updateContext`);
    const {
      mergedFavorites,
      mergedGroups,
    } = await favoriteContext.getFavorites();
    hycon.debug(`${hookName} updateContext`, {
      mergedFavorites,
      mergedGroups,
    });
    favoriteContext.dispatch({
      type: reducerActions.REPLACE,
      info: 'updateContext',
      payload: {
        newState: {
          favorites: mergedFavorites,
          groups: mergedGroups,
        },
      },
    });
  };

  const getNotificationComponent = () => (
    <ModalFormNotification
      key="modal-notification-wrapper"
      targetFavorites={targetFavorites}
      updateContext={updateContext}
      onModalClose={onModalClose}
    />
  );
  const getRecipientComponent = () => (
    <ModalFormRecipient
      key="modal-recipient-wrapper"
      getAllUniqueActiveRecipients={getAllUniqueActiveRecipients}
      getAllUniqueRecipients={getAllUniqueRecipients}
      getUniqueTargetRecipientsEmails={getUniqueTargetRecipientsEmails}
      updateRecipients={updateRecipients}
      updateContext={updateContext}
      onModalClose={onModalClose}
    />
  );
  const getGroupComponent = () => (
    <ModalFormGroup
      key="modal-group-wrapper"
      targetFavorites={targetFavorites}
      getAllUniqueGroups={getAllUniqueGroups}
      updateContext={updateContext}
      onModalClose={onModalClose}
    />
  );
  const getContent = () => {
    const modals = favoriteContext.modals || [];
    const modal = modals[modals.length - 1];
    if (modal.type === 'recipient') {
      return getRecipientComponent();
    } if (modal.type === 'group') {
      return getGroupComponent();
    }
  };

  const getModal = () => {
    const modals = favoriteContext.modals || [];
    return modals.map((modal) => {
      if (modal.type === 'notification') {
        return (
          <Dialog
            key={`${modal.type}-modal`}
            open
            scroll="paper"
            maxWidth="sm"
            fullWidth
            fullScreen={fullScreen}
            onClose={(event, data) => {
              onModalClose(event, data);
            }}
          >
            {getNotificationComponent()}
          </Dialog>
        );
      }
      return (
        <Modal
          key={`${modal.type}-modal`}
          open
          closeOnDimmerClick
          closeOnDocumentClick
          onClose={(event, data) => {
            hycon.debug(`${hookName} modal - onClose`, { event, data });
            onModalClose(event, data);
          }}
          onActionClick={(event, data) => {
            hycon.debug(`${hookName} modal - onActionClick`, { event, data });
          }}
        >
          {getContent()}
        </Modal>
      );
    });
  };
  return getModal();
}
