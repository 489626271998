import React, {Component} from 'react';
 import HyperConsole from "../../hyper_console/hyper-console";
import "./SignUp.css";
import {Button, Checkbox, Form} from 'semantic-ui-react';
import axios from "axios/index";
import {TokenUtil} from "../util/token-util";
import { withTranslation } from 'react-i18next'
import SupportRequest from "../../reusable/SupportRequest";

const validator = require("email-validator");
const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV;
let hycon = null;
if (REACT_APP_GI_ENV === "development") {
	hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
} else {
	hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
}

const FLASH_MESSAGE_DURATION = 4000;

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isSuccess: false,
            messages: [],
            touched: {
                email: false,
                password: false,
                passwordRepeat: false,
                toc: false
            },
            passwordValidationRequests: [],
            passwordValidation: {
                errors: []
            },
            errors: {
                email: false,
                password: false,
                passwordRepeat: false,
                toc: false
            }
        };

        // hycon.debug(`${this.constructor.name} constructor`, {props: this.props});
    }

    componentDidMount() {
        let thisRef = this;
        // hycon.debug(`${this.constructor.name} componentDidMount`, {props: this.props});

        thisRef.loadRecaptchaScript();
    }


    static getDerivedStateFromProps(props, state) {
        // hycon.debug(`${"SignUp"} getDerivedStateFromProps`, {props, state});
        if (!state) {
            // hycon.debug(`${"SignUp"} getDerivedStateFromProps - only props have been updated`, {props, state});
        }
        return state;
    }

    shouldComponentUpdate() {

        // hycon.debug(`${this.constructor.name} shouldComponentUpdate`, {nextProps, props: this.props, nextState});
        return true;
    }

    checkPassword() {
        let thisRef = this;
        let env = thisRef.props.reduxState.env;
        let password = thisRef.state.password;
        if(!this.state.touched.password || password === ""){
            return thisRef.setStateProperty("passwordValidation", Object.assign({}, thisRef.state.passwordValidation, {errors: []})).then(() => {
                return Promise.resolve();
            });
        }
        return new Promise((res, rej) => {
            const endpoint = `${env.API_GATEWAY_BASE}/api/password-isvalid?part=${password}`;
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            let cancelTokenSavePromise = new Promise(() => {
                return thisRef.setStateProperty("passwordValidationRequests", thisRef.state.passwordValidationRequests.concat([source]));
            });

            let axiosPromise = axios(
                {
                    method: 'get',
                    url: endpoint,
                    cancelToken: source.token,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': "application/json",
                    }
                    /*
                    ,
                    data: JSON.stringify({})
                    */
                }
            )
                .then((response) => {
                    // hycon.info(`${this.constructor.name} checkPassword`, {state: thisRef.state});

                    if (response.data.success) {
                        thisRef.setStateProperty("passwordValidation", Object.assign({}, thisRef.state.passwordValidation, {errors: []}))
                    } else {
                        thisRef.setStateProperty("passwordValidation", Object.assign({}, thisRef.state.passwordValidation, {errors: [response.data.message]}))
                    }

                    return res(response);
                })
                .catch((error) => {
                    // hycon.warn(`${this.constructor.name} checkPassword - error`, {state: thisRef.state});
                    return rej(error);
                });
            return cancelTokenSavePromise.then(() => {
                return axiosPromise
            });
        });
    }

    cancelPendingRequests() {
        let thisRef = this;
        // hycon.debug(`${this.constructor.name} cancelPendingRequests`, {thisRef, props: this.props});

        thisRef.state.passwordValidationRequests.forEach((source) => {
            // hycon.debug(`${this.constructor.name} cancelPendingRequests - canceling request nr ${i}`, {source, i});
            try {
                source.cancel();
            } catch (e) {
                /* hycon.info(`${this.constructor.name} cancelPendingRequests - error - canceling request nr ${i}`, {
                    source,
                    i
                });
                */
            }
        })
    }

    signup() {
        let thisRef = this;
        let env = thisRef.props.reduxState.env;
        return new Promise((res, rej) => {
            const endpoint = `${env.API_GATEWAY_BASE}/api/register`;
            return axios(
                {
                    method: 'post',
                    url: endpoint,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': "application/json",
                    },
                    data: JSON.stringify({
                        email: thisRef.state.email,
                        password: thisRef.state.password,
                        passwordRepeat: thisRef.state.passwordRepeat,
                        toc: thisRef.state.toc,
                        googleToken: thisRef.state["recaptcha-token"]
                    })
                }
            )
                .then((response) => {
                    return TokenUtil.updateTokenInReduxAndCookie(response, thisRef).then(() => {
                        return response;
                    });
                })
                .then((response) => {
                    // hycon.info(`${this.constructor.name} submit`, {state: thisRef.state});
                    return res(response);
                })
                .catch((error) => {
                    // hycon.warn(`${this.constructor.name} submit`, {state: thisRef.state});
                    return rej(error);
                });
        });
    }

    submit() {
        let thisRef = this;
        // hycon.debug(`${this.constructor.name} submit`, {state: thisRef.state});
        thisRef.setStateProperty("messages", thisRef.state.messages.concat([
            thisRef.props.i18n.t("sign_up:message-elaborating")
        ])).then(() => {
            return thisRef.setStateProperty("isLoading", true);
        }).then(() => {
            return thisRef.signup()
                .then((response) => {
                    // hycon.debug(`${this.constructor.name} submit ok`);
                    if(thisRef.isRedirectToEmailResendNeeded(response)){
                        thisRef.redirectToEmailResend();
                        return Promise.resolve("redirected");
                    } else {
                        /* eventually redirect the user to the appropriate registration-resend form */
                        thisRef.setStateProperty("messages", thisRef.state.messages.concat([
                            thisRef.props.i18n.t("sign_up:message-elaborating-ok")
                        ]));
                        thisRef.setStateProperty("isLoading", false);
                        thisRef.setStateProperty("isSuccess", true);
                        thisRef.setStateProperty("messages", thisRef.state.messages.concat([
                            thisRef.props.i18n.t("sign_up:message-confirm-link")
                        ]));
                    }
                })
                .catch((e) => {
                    let response = e.response;
                    // hycon.warn(`${this.constructor.name} submit error`, {e, response: response});
                    if (response.status === 422) {
                        thisRef.setStateProperty("messages", thisRef.state.messages.concat([
                          thisRef.props.i18n.t("sign_up:message-check-form")
                        ]), {
                            reset: true,
                            resetValue: [],
                            delta: FLASH_MESSAGE_DURATION
                        });
                    } else {
                        thisRef.setStateProperty("messages", thisRef.state.messages.concat([`${e.message}`]), {
                            reset: true,
                            resetValue: [],
                            delta: FLASH_MESSAGE_DURATION
                        });
                    }
                    thisRef.setStateProperty("isLoading", false);
                });
        });


    }

    getFormValidElements() {
        let thisRef = this;
        let validElements = {
            email: !thisRef.state.errors.email,
            password: !thisRef.state.errors.password,
            passwordRepeat: !thisRef.state.errors.passwordRepeat,
            toc: !thisRef.state.errors.toc
        };
        validElements.isAllValid = (
            validElements.email &&
            validElements.password &&
            validElements.passwordRepeat &&
            validElements.toc
        );
        // hycon.debug(`${thisRef.constructor.name} getFormValidElements`, {validElements});
        return validElements;
    }

    isFormComplete() {
        let thisRef = this;
        // hycon.debug(`${thisRef.constructor.name} isFormComplete`, {});
        let isComplete = (
            (!thisRef.state.errors.email && thisRef.state.touched.email) &&
            (!thisRef.state.errors.password && thisRef.state.touched.password) &&
            (!thisRef.state.errors.passwordRepeat && thisRef.state.touched.passwordRepeat) &&
            (!thisRef.state.errors.toc && thisRef.state.touched.toc) &&
            (thisRef.state.passwordValidation.errors.length === 0)
        );
        return isComplete;
    }

    isAllTouched() {
        let thisRef = this;
        // hycon.debug(`${thisRef.constructor.name} isAllTouched`, {});
        let isAllTouched = (
            thisRef.state.email &&
            thisRef.state.password &&
            thisRef.state.passwordRepeat &&
            thisRef.state.toc
        );

        return isAllTouched;
    }

    validate(validation = {}) {
        let thisRef = this;
        let promises = [];

        if (validation.email) {
            let isValid = validator.validate(thisRef.state.email);
            if (!thisRef.state.email || !isValid) {
                promises.push(thisRef.setStateProperty("errors", Object.assign({}, thisRef.state.errors, {email: true})));
            } else {
                promises.push(thisRef.setStateProperty("errors", Object.assign({}, thisRef.state.errors, {email: false})));
            }
        }
        if (validation.password) {
            if (thisRef.state.password !== thisRef.state.passwordRepeat && validation.password) {
                promises.push(thisRef.setStateProperty("errors", Object.assign({}, thisRef.state.errors, {passwordRepeat: true})));
            } else {
                promises.push(thisRef.setStateProperty("errors", Object.assign({}, thisRef.state.errors, {passwordRepeat: false})));
            }
        }
        if (validation.toc) {
            if (!thisRef.state.toc && validation.toc) {
                promises.push(thisRef.setStateProperty("errors", Object.assign({}, thisRef.state.errors, {toc: true})));
            } else {
                promises.push(thisRef.setStateProperty("errors", Object.assign({}, thisRef.state.errors, {toc: false})));
            }
        }

        thisRef.checkPassword()
            .then(() => {
                // hycon.debug(`${this.constructor.name} validate - checkPassword - ok`, {newState: thisRef.state});
                promises.push(thisRef.setStateProperty("errors", Object.assign({}, thisRef.state.errors, {password: false})));
            })
            .catch(() => {
                // hycon.debug(`${this.constructor.name} validate - checkPassword - err`, {newState: thisRef.state});
                promises.push(thisRef.setStateProperty("errors", Object.assign({}, thisRef.state.errors, {password: true})));
            });

        return Promise.all(promises);
    }

    setStateProperty(propertyName, propertyValue, opts) {
        let thisRef = this;


        if (opts) {
            let isValid = (
                typeof opts.reset !== "undefined" &&
                typeof opts.resetValue !== "undefined" &&
                typeof opts.delta !== "undefined"
            );
            if (!isValid) {
                throw new Error('options object is not defined as expected. expeected something like {reset: <bool>, resetValue: <object>, delta: <number>}');
            }
        }

        let resetPromise = Promise.resolve();
        if (
            opts &&
            opts.reset === true
        ) {
            resetPromise = new Promise((res, rej) => {
                setTimeout(() => {
                    try {
                        thisRef.setStateProperty("messages", opts.resetValue);
                        res();
                    } catch (e) {
                        rej(e)
                    }
                }, opts.delta);
            });
        }

        let setPromise = new Promise((res) => {
            thisRef.setState((p) => {
                const obj = {};
                obj[propertyName] = propertyValue;
                return Object.assign({}, p, obj);
            }, () => {
                // hycon.debug(`${this.constructor.name} setStateProperty - newState`, {newState: thisRef.state});
                res();
            })
        });

        return Promise.all([setPromise, resetPromise]);
    }

    recaptchaExecute() {
        let thisRef = this;
        let key = thisRef.props.reduxState.env.GOOGLE_RECAPTCHA_SITE_KEY;
        return new Promise((res) => {
            let grecaptcha = window.grecaptcha;
            grecaptcha.ready(() => {
                grecaptcha.execute(key, {action: 'homepage'}).then((token) => {
                    hycon.info(`${thisRef.constructor.name} recaptchaExecute - google says you are not a bot`, {token});
                    return thisRef.setStateProperty("recaptcha-token", token).then(() => {
                        res();
                    });
                });
            });
        });
    }

    loadRecaptchaScript () {
        let thisRef = this
        hycon.debug(`${thisRef.constructor.name} loadRecaptchaScript`, {})

        let key = thisRef.props.reduxState.env.GOOGLE_RECAPTCHA_SITE_KEY

        let scriptId = "google-recaptcha-script";
        let script = document.createElement('script')
        script.id=scriptId;
        script.type = 'text/javascript'
        script.src = `https://www.google.com/recaptcha/api.js?render=${key}`

        let recaptchaScript = document.getElementById(scriptId);
        if(!recaptchaScript){
            document.getElementsByTagName('head')[0].appendChild(script)
        }
    }

    isRedirectToEmailResendNeeded(response){

        // hycon.debug(`${thisRef.constructor.name} isRedirectToEmailResendNeeded`, response);

        let msg = response.data.message;
        let success = response.data.success;

        if (
            response.status === 200 &&
            success === false &&
            msg.includes("is already taken")
        ) {
            return true;
        } else {
            return false;
        }
    }

    redirectToEmailResend() {
        let thisRef = this;
        // hycon.debug(`${thisRef.constructor.name} redurectToEmailResend`, response);

        // cancel all pending requests before unmounting
        thisRef.cancelPendingRequests();
        thisRef.props.history.push("/user/signup-resend/", {email: thisRef.state.email});
    }

    render() {
        let thisRef = this;
        return (
            <div className="SignUp">
                <div className={`signup-form`}>
                    <div className={"heading"}>
                        <img className={"image"}
                             alt={""}
                             src={`https://geoimpactstorage.blob.core.windows.net/public/logo/${thisRef.props.appStateServer.clients[0]}/Logo-SEP-web.png`}/>
                        <h1>{thisRef.props.i18n.t("sign_up:label-register")}</h1>
                    </div>
                    <Form loading={thisRef.state.isLoading} className={`isSuccess-${thisRef.state.isSuccess}`}>
                        <Form.Field error={thisRef.state.errors.email}>
                            <label>{thisRef.props.i18n.t("sign_up:label-email")}</label>
                            <input
                                placeholder={thisRef.props.i18n.t("sign_up:label-email")}
                                type={"email"}
                                autoComplete={`email`}
                                onChange={(event) => {
                                    let val = event.target.value;

                                    let props = thisRef.props;
                                    let state = thisRef.state;
                                    hycon.debug(`${thisRef.constructor.name} onChange`, {val, props, state});
                                    thisRef.setStateProperty("email", val).then(() => {
                                        return thisRef.setStateProperty("touched", Object.assign({}, thisRef.state.touched, {email: true}));
                                    }).then(() => {
                                        return thisRef.validate({email: true});
                                    });
                                }}
                                onBlur={() => {
                                    // hycon.debug(`${thisRef.constructor.name} onBlur`, {});
                                    thisRef.validate({email: true});
                                }}
                            />
                        </Form.Field>
                        <Form.Field error={thisRef.state.errors.password}>
                            <label>{thisRef.props.i18n.t("sign_up:label-password")}</label>
                            <input
                                placeholder={thisRef.props.i18n.t("sign_up:label-password")}
                                type={"password"}
                                autoComplete={`new-password`}
                                onChange={(event) => {
                                    let val = event.target.value;
                                    // hycon.debug(`${thisRef.constructor.name} onChange`, {event, value: val});
                                    thisRef.setStateProperty("password", val).then(() => {
                                        return thisRef.setStateProperty("touched", Object.assign({}, thisRef.state.touched, {password: true}));
                                    }).then(() => {
                                        thisRef.validate({password: true});
                                    });
                                }}
                                onBlur={() => {
                                    // hycon.debug(`${thisRef.constructor.name} onBlur`, {});
                                    thisRef.validate({password: true});
                                }}
                            />
                        </Form.Field>
                        <div className={"password-hints"}>
                            {(() => {
                                if (thisRef.state.passwordValidation.errors.length > 0) {
                                    let errorsString = thisRef.state.passwordValidation.errors[thisRef.state.passwordValidation.errors.length - 1];
                                    let errors = errorsString.split("|");
                                    let items = [];
                                    errors.forEach((e, i) => {
                                        items.push((
                                            <li key={i}>{e}</li>
                                        ));
                                    });
                                    return <ul>{items}</ul>
                                } else {
                                    return "";
                                }
                            })()}
                        </div>
                        <Form.Field error={thisRef.state.errors.passwordRepeat}>
                            <label>{thisRef.props.i18n.t("sign_up:label-password-repeat")}</label>
                            <input
                                placeholder={thisRef.props.i18n.t("sign_up:label-password-repeat")}
                                type={"password"}
                                onChange={(event) => {
                                    let val = event.target.value;
                                    // hycon.debug(`${thisRef.constructor.name} onChange`, {event, value: val});
                                    thisRef.setStateProperty("passwordRepeat", val)
                                        .then(() => {
                                            return thisRef.setStateProperty("touched", Object.assign({}, thisRef.state.touched, {passwordRepeat: true}));
                                        })
                                        .then(() => {
                                            thisRef.validate({password: true});
                                        });
                                }}
                                onBlur={() => {
                                    // hycon.debug(`${thisRef.constructor.name} onBlur`, {});
                                    thisRef.validate({password: true});
                                }}
                            />
                        </Form.Field>
                        <Form.Field
                            id={"terms-checkbox"}
                            error={thisRef.state.errors.toc}
                        >
                            <Checkbox
                                label={(() => {
                                    let link = <a href={"https://www.geoimpact.ch/agb"} target={"_blank"} rel="noopener noreferrer">
                                        {thisRef.props.i18n.t("sign_up:label-toc-2")}
                                    </a>;
                                    let agb = (
                                        <label>
                                            {thisRef.props.i18n.t("sign_up:label-toc-1")}&nbsp;
                                            {link}&nbsp;
                                            {thisRef.props.i18n.t("sign_up:label-toc-3")}
                                        </label>
                                    );
                                    return agb;
                                })()}
                                onChange={(event, data) => {
                                    // hycon.debug(`${thisRef.constructor.name} onChange`, {event, data});
                                    thisRef.setStateProperty("toc", data.checked)
                                        .then(() => {
                                            return thisRef.setStateProperty("touched", Object.assign({}, thisRef.state.touched, {toc: true}));
                                        })
                                        .then(() => {
                                            thisRef.validate({toc: true});
                                        });
                                }}
                            />
                        </Form.Field>
                        <div className={`column-flex resend-button`}>
                            <Button
                                primary
                                type='submit'
                                disabled={(() => {


                                    let isFormComplete = thisRef.isFormComplete();
                                    return !isFormComplete;
                                })()}
                                onClick={() => {
                                    thisRef.validate()
                                        .then(() => {
                                            let validElements = thisRef.getFormValidElements();
                                            let isAllValid = validElements.isAllValid;
                                            let isAllTouched = thisRef.isAllTouched();
                                            return isAllValid && isAllTouched;
                                        })
                                        .then((isAllValid) => {
                                            if (isAllValid) {
                                                // hycon.debug(`${thisRef.constructor.name} form looks good`, {});
                                                thisRef.recaptchaExecute().then(() => {
                                                    return thisRef.submit();
                                                });
                                            } else {
                                                // hycon.debug(`${thisRef.constructor.name} please check your form`, {});
                                            }
                                        });
                                }}
                            >
                                {thisRef.props.i18n.t("sign_up:label-register")}
                            </Button>
                        </div>
                    </Form>
                    <div className={"footer"}>
                        <h5>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: (
                                        () => {
                                            if (thisRef.state.messages.length > 0) {
                                                return thisRef.state.messages[thisRef.state.messages.length - 1]
                                            }
                                        }
                                    )()
                                }}
                            />
                        </h5>
                        <SupportRequest {...thisRef.props}/>
                    </div>
                </div>
            </div>
        );
    }

    componentDidUpdate() {

        // hycon.debug(`${this.constructor.name} componentDidUpdate`, {props: this.props});
    }

    componentWillUnmount() {

        // hycon.debug(`${this.constructor.name} componentWillUnmount`, {props: this.props});
    }
}

export default withTranslation()(SignUp);
