import axios from 'axios/index'
import HyperConsole from '../../hyper_console/hyper-console'
import { TokenUtil } from './token-util'
import { UserUtil } from './user-util'

let hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole

class UserSettingsClass {
  constructor () {
    this.constants = {}
  }

  init () {

  }

  getSettings (userEmail = null, ctx) {
    /*
        let settings = {data: "fake - get - settings"};
        return new Promise((res) => {
            setTimeout(() => {res(settings)}, 1000);
        }); */
    /**/
    let thisRef = this
    let env = ctx.props.reduxState.env
    let jwt = ctx.props.reduxState.user.jwt
    return new Promise((res, rej) => {
      const endpoint = `${env.API_GATEWAY_BASE}/api/userinfo-by-name/${userEmail}`
      return axios(
        {
          method: 'get',
          url: endpoint,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`
          }
        }
      )
        .then((response) => {
          hycon.info(`${this.constructor.name} getSettings`, { response })
          return TokenUtil.updateTokenInReduxAndCookie(response, ctx).then((tokenResponse) => {
            return response
          })
        })
        .then((response) => {
          hycon.info(`${this.constructor.name} getSettings - update redux ok`, {})
          return res(response)
        })
        .catch((error) => {
          hycon.warn(`${this.constructor.name} getSettings - error`, { error })
          UserUtil.authenticationRedirect(error.response, thisRef)
          return rej(error)
        })
    })
  };

  getRowVersion (userId, ctx) {
    // let thisRef = this;
    let env = ctx.props.reduxState.env
    let jwt = ctx.props.reduxState.user.jwt
    return new Promise((res, rej) => {
      const endpoint = `${env.API_GATEWAY_BASE}/api/userinfo/${userId}`
      return axios(
        {
          method: 'head',
          url: endpoint,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`
          }
        }
      )
        .then((response) => {
          hycon.info(`${this.constructor.name} getRowVersion`, { response })
          return TokenUtil.updateTokenInReduxAndCookie(response, ctx).then((tokenResponse) => {
            return response
          })
        })
        .then((response) => {
          hycon.info(`${this.constructor.name} getRowVersion - update redux ok`, {})
          let etag = response.headers.etag
          return res(etag)
        })
        .catch((error) => {
          hycon.warn(`${this.constructor.name} getRowVersion - error`, { error })
          UserUtil.authenticationRedirect(error.response, ctx)
          return rej(error)
        })
    })
  }

  validateSettings (settingsObj) {
    let thisRef = this
    // veryfy if the settings can be stringified and reparsed
    hycon.debug(`${thisRef.constructor.name} validateSettings`, { settingsObj })
    let stringifiedSettings = null
    try {
      stringifiedSettings = JSON.stringify(settingsObj)
    } catch (e) {
      console.error(e)
      console.error('Cannot stringify the settings object', settingsObj)
      throw new Error('Cannot stringify the settings object')
    }
    let parsedSettings = null
    try {
      parsedSettings = JSON.parse(stringifiedSettings)
    } catch (e) {
      console.error(e)
      console.error('Cannot stringify the settings object', { settingsObj, stringifiedSettings })
      throw new Error('Cannot parse the stringified settings object')
    }
    hycon.info(`${this.constructor.name} validateSettings - validation passed`, {
      settingsObj,
      stringifiedSettings,
      parsedSettings
    })
  }

  saveSettings (userId = null, settings, ctx) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} saveSettings`, { userId, settings, ctx })
    thisRef.validateSettings(settings)
    let env = ctx.props.reduxState.env
    let jwt = ctx.props.reduxState.user.jwt
    return thisRef.getRowVersion(userId, ctx).then((etag) => {
      hycon.debug(`${this.constructor.name} saveSettings - pre rowversionstring`, { etag })
      return new Promise((res, rej) => {
        const endpoint = `${env.API_GATEWAY_BASE}/api/userinfo/${userId}`

        let headers = null
        if (etag) {
          headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`,
            'If-Match': `${etag}`
          }
        } else {
          headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`,
            'If-Match': ``
          }
        }
        return axios(
          {
            method: 'patch',
            url: endpoint,
            headers: headers,
            data: JSON.stringify({
              appsettings: JSON.stringify(settings)
            })
          }
        )
          .then((response) => {
            hycon.info(`${this.constructor.name} saveSettings`, { response })
            return TokenUtil.updateTokenInReduxAndCookie(response, ctx).then((tokenResponse) => {
              return response
            })
          })
          .then((response) => {
            hycon.info(`${this.constructor.name} saveSettings - update redux ok`, {})
            return res(response)
          })
          .catch((error) => {
            hycon.warn(`${this.constructor.name} saveSettings - error`, { error })
            UserUtil.authenticationRedirect(error.response, ctx)
            return rej(error)
          })
      })
    })
  };

  test () {
    let thisRef = this
    hycon.info(`${thisRef.constructor.name} test`, {})
  }
}

export const UserSettings = new UserSettingsClass()
