import React, {
  createContext, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import HyperConsole from '../../components/hyper_console/hyper-console';

const { REACT_APP_GI_ENV } = process.env;
let hycon = null;
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
} else {
  // eslint-disable-next-line no-unused-vars
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
}

const LocationCtx = createContext(null);

export default function LocationProvider({ children }) {
  const [positions, setPositions] = useState([]);
  const [permission, setPermission] = useState(null);
  const watchId = useRef(null);

  // Find the latest timestamp and return it
  // eslint-disable-next-line max-len
  const getLocation = useCallback(async (onNewLocation = () => {}, options = {}) => new Promise((res, rej) => {
    navigator.geolocation.getCurrentPosition((result) => {
      res(result);
      onNewLocation(result);
      setPositions((prevPositions) => [...prevPositions, result]);
    }, (e) => {
      rej(e.message);
    }, options);
  }), [positions]);

  // eslint-disable-next-line max-len
  const startPositionWatcher = async (onNewLocation = () => {}, onError = () => {}) => {
    if (watchId.current) {
      hycon.warn('watcher already started');
      return watchId.current;
    }
    const newWatchId = navigator
      .geolocation
      .watchPosition((result) => {
        onNewLocation(result);
        setPositions((prevPositions) => [...prevPositions, result]);
      }, (e) => {
        onError(e);
      });
    watchId.current = newWatchId;
    return newWatchId;
  };

  const stopPositionWatcher = (myWatchId) => {
    navigator.geolocation.clearWatch(myWatchId);
  };

  const getPermission = async () => new Promise((res) => {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      res(result);
    });
  });

  const api = useMemo(() => ({
    positions,
    getLocation,
    startWatcher: startPositionWatcher,
    stopWatcher: stopPositionWatcher,
    permission,
  }), [positions, permission]);

  useEffect(() => {
    const initialize = async function initialize() {
      const pemission = await getPermission();
      setPermission(pemission);
    };
    initialize();
  }, []);

  return (
    <LocationCtx.Provider value={api}>{children}</LocationCtx.Provider>
  );
}

export const useLocationProvider = () => {
  const ctx = React.useContext(LocationCtx);
  if (!ctx) {
    throw new Error('useLocationProvider must be used inside a the LocationProvider');
  }
  return ctx;
};
