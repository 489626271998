import React from 'react'
import HyperConsole from '../../../../hyper_console/hyper-console'

import {
  Loader,
  Dimmer
} from 'semantic-ui-react'

import './ConstructionZone.css'

import ReactDOM from 'react-dom'

import MapHandler from './map-handler'
import RequestHandler from './request-handler'
import ConstructionZoneDetail from './detail/ConstructionZoneDetail'
import ConstructionZoneSettings from './settings/ConstructionZoneSettings'
import { UserSettings } from '../../../../user_management/util/user-settings'
import moment from 'moment'
import { GoogleAnalytics } from '../../../../google_analytics/GoogleAnalytics'
import { withTranslation } from 'react-i18next'
import {Button as MUIButton} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close'
import SettingsIcon from '@mui/icons-material/Settings';
import {WixUtil} from "../../../../../util/wix-util";

const requestHandler = new RequestHandler()

const RENDER_OVERVIEW = 'RENDER_OVERVIEW'
const RENDER_DETAIL = 'RENDER_DETAIL'
const RENDER_SETTINGS = 'RENDER_SETTINGS'
export const HIGH_ZOOM_LEVEL = 13
const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

class ConstructionZone extends React.Component {
  constructor (props) {
    super(props)
    let thisRef = this
    this.state = {
      mapHandler: null,
      appsettings: null,
      isMounted: false,
      isLoading: false,
      listeners: [],
      rendering: RENDER_OVERVIEW,
    }
    hycon.debug(`${thisRef.constructor.name} constructor pre`, { thisRef, props: thisRef.props })
    thisRef.setIsLoading = thisRef.setIsLoading.bind(thisRef)
    thisRef.setStateProperty = thisRef.setStateProperty.bind(thisRef)
    hycon.debug(`${thisRef.constructor.name} constructor post`, { thisRef, props: thisRef.props })
  }

  loadUserSettings () {
    let thisRef = this
    let appSettingsDefault = {
      profiles: {
        default: {
          panels: {
            construction_zone: {
              constructions: {
                settings: {
                  fromDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
                  options: [],
                  selectedOptionIndexes: [],
                  isSetByUser: false
                }
              },
            }
          }
        }
      }
    }
    return thisRef.setIsLoading(true)
    .then(() => {
      // fetch the user settings
      let email = null;
      try{
        email = thisRef.props.reduxState.session.user.userName
      }catch (e){
        email = thisRef.props.reduxState.user.user.userName
      }
      return UserSettings.getSettings(email, thisRef).then((response) => {
        hycon.debug(`${thisRef.constructor.name} loadUserSettings`, {
          response,
          thisRef,
          props: thisRef.props
        })
        return response
      })
    })
    .then((settingsResponse) => {
      hycon.debug(`${thisRef.constructor.name} loadUserSettings settingsResponse`, { settingsResponse })
      let appSettings = null
      // let appSettingsFromServer = null;

      try {
        appSettings = JSON.parse(settingsResponse.data.appsettings)
        // appSettingsFromServer = JSON.parse(settingsResponse.data.appsettings);
      } catch (e) {
        if (!settingsResponse.data.appsettings) {
          appSettings = null
        } else {
          console.error(e)
          throw e
        }
      }
      // merge the app settings
      if (
        !appSettings ||
        !appSettings.profiles ||
        !appSettings.profiles.default ||
        !appSettings.profiles.default.panels ||
        !appSettings.profiles.default.panels.construction_zone
      ) {
        hycon.debug(`${thisRef.constructor.name} loadUserSettings settingsResponse - no appsettings, using defaults.`, { settingsResponse })
        appSettings = Object.assign({}, appSettingsDefault)
      }

      hycon.debug(`${thisRef.constructor.name} loadUserSettings settingsResponse`, {
        appSettings: appSettings
      })
      return thisRef.setStateProperty('appsettings', appSettings)
      .then(() => {
        hycon.debug(`${thisRef.constructor.name} loadUserSettings settingsResponse - new state`, { state: thisRef.state })
        return thisRef.setIsLoading(false)
      })
    })
    .catch(e => {
      hycon.error(`${thisRef.constructor.name} loadUserSettings error`, e)
      thisRef.setIsLoading(false)
      return thisRef.setStateProperty('appsettings', null)
    })
  }

  updateUserSettings () {
    let thisRef = this
    let appsettings = thisRef.state.appsettings
    let userId = null;
    try{
      userId = thisRef.props.reduxState.session.user.id
    }catch (e){
      userId = thisRef.props.reduxState.user.user.id
    }

    let getUpdatedAppsettingsFromServer = () => {
      let email = null;
      try{
        email = thisRef.props.reduxState.session.user.userName
      }catch (e){
        email = thisRef.props.reduxState.user.user.userName
      }
      return UserSettings.getSettings(email, thisRef).then((response) => {
        hycon.debug(`${thisRef.constructor.name} loadUserSettings`, {
          response,
          thisRef,
          props: thisRef.props
        })
        try {
          return JSON.parse(response.data.appsettings)
        } catch (e) {
          console.error(e)
          return null
        }
      })
    }

    return thisRef.setIsLoading(true)
    .then(() => {
      return getUpdatedAppsettingsFromServer()
    })
    .then((latestAppsettings) => {
      if (
        latestAppsettings && appsettings
      ) {
        hycon.info(`${thisRef.constructor.name} updateUserSettings - merging updates from server`, {
          latestAppsettings, appsettings
        })
        appsettings.profiles.default.panels = {
          ...latestAppsettings.profiles.default.panels,
          ...appsettings.profiles.default.panels
        }
        hycon.info(`${thisRef.constructor.name} updateUserSettings - merged updates from server`, {
          latestAppsettings, appsettings
        })
      }
      return UserSettings.saveSettings(userId, appsettings, thisRef).then(() => {
        return thisRef.setIsLoading(false)
      })
    })
  }

  initFilter () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} componentDidMount initFilter, setting user filter to "all"`, {
      thisRef,
      props: this.props
    })
    if (!thisRef.state.appsettings.profiles.default.panels.construction_zone.constructions.settings.isSetByUser) {
      let indexes = []
      thisRef.state.appsettings.profiles.default.panels.construction_zone.constructions.settings.options.forEach((o, i) => {
        indexes.push(i)
      })
      let constructions = Object.assign({}, thisRef.state.appsettings.profiles.default.panels.construction_zone.constructions)
      constructions.settings.isSetByUser = true
      constructions.settings.selectedOptionIndexes = indexes
      let newAppSettings = Object.assign({}, thisRef.state.appsettings)
      newAppSettings.profiles.default.panels.construction_zone.constructions = constructions
      return thisRef.setStateProperty('appsettings', newAppSettings).then(() => {
        return thisRef.updateUserSettings()
      })
    }
  }

  componentDidMount () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} componentDidMount`, { thisRef, props: this.props })
    thisRef.loadUserSettings().then(() => {
      return thisRef.setStateProperty('isMounted', true)
      .then(() => {
        const mapHandler = new MapHandler()
        return thisRef.setStateProperty('mapHandler', mapHandler)
        .then(() => {
          // make a request as soon as you have a map reference
          let data = mapHandler.getCurrentData()
          let isHighZoom = requestHandler.isHighZoom(data.map)
          thisRef.state.mapHandler.updateIsHighZoom(isHighZoom)
          if (isHighZoom) {
            thisRef.fetchConstructions(isHighZoom, data.map)
          } else {
            let appsettings = Object.assign({}, thisRef.state.appsettings)
            appsettings.profiles.default.panels.construction_zone.constructions.response = { data: [] }
            appsettings.profiles.default.panels.construction_zone.constructions.isHighZoom = isHighZoom
            return thisRef.fetchConstructionCategories(data.map).then(() => {
              return thisRef.setStateProperty('appsettings', appsettings)
            })
          }
        })
        .then(() => {
          return thisRef.initListeners(window, () => {
            // on enter
            thisRef.state.mapHandler.setIsGraphicsDisplayed(true)
          }, () => {
            // on leave
            thisRef.state.mapHandler.setIsGraphicsDisplayed(false)
          })
        })
        .then(() => {
          thisRef.initFilter()
        })
        .catch((error) => {
          hycon.warn(`${this.constructor.name} componentDidMount - error`, { error })
        })
      })
    })
  }

  shouldComponentUpdate (nextProps, nextState) {
    // let thisRef = this;
    hycon.debug(`${this.constructor.name} shouldComponentUpdate`, { nextProps, props: this.props, nextState })
    return true
  }

  getOverview () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} getOverview`, { props: this.props, state: thisRef.state })
    const getIconStyle = (name) => {
      // let env = thisRef.props.reduxState.env;
      let base = `https://geoimpactstorage.blob.core.windows.net`
      let itemStyle = {
        backgroundImage: `url(${base}/halley/components/dashboard/tile-icons/${name})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '65px',
        width: '65px'
      }
      hycon.debug(`${this.constructor.name} getStyle`, { itemStyle })
      return itemStyle
    }
    let comp = null

    let nrOfConstructionZones = null
    try {
      if (
        thisRef.state.appsettings.profiles.default.panels.construction_zone.constructions.response &&
        thisRef.state.appsettings.profiles.default.panels.construction_zone.constructions.response.data
      ) {
        nrOfConstructionZones = thisRef.state.appsettings.profiles.default.panels.construction_zone.constructions.response.filteredData.length
      }
    } catch (e) {
      nrOfConstructionZones = 0
    }
    if (
      thisRef.state.appsettings &&
      thisRef.state.appsettings.profiles.default.panels.construction_zone.constructions.isHighZoom
    ) {
      comp = (
        <div className={`overview`}>
          <div>
            <div className={`icon`} style={getIconStyle('Baugesuche.png')}/>
          </div>
          <div>
            <h1>{nrOfConstructionZones}</h1>
            <h3>{thisRef.props.i18n.t('panel_construction_zone:label-number')}</h3>
          </div>
        </div>
      )
    } else {
      comp = (
        <div className={`overview`}>
          <div>
            <div className={`icon`} style={getIconStyle('Baugesuche.png')}/>
          </div>
          <div>
            <h1>{thisRef.props.i18n.t('panel_construction_zone:label-panel-overview-title')}</h1>
          </div>
        </div>
      )
    }
    return comp
  }

  getDetailView (detailComponent) {
    hycon.debug(`${this.constructor.name} getDetailView`)
    // let thisRef = this;
    return <div className={`detail`}>{detailComponent}</div>
  }

  setStateProperty (propertyName, propertyValue) {
    let thisRef = this
    let prevState = thisRef.state
    return new Promise((res) => {
      thisRef.setState((p) => {
        const obj = {}
        obj[propertyName] = propertyValue
        return Object.assign({}, p, obj)
      }, () => {
        hycon.debug(`${this.constructor.name} setStateProperty - newState`, {
          prevState: prevState,
          newState: thisRef.state
        })
        res()
      })
    })
  }

  setIsLoading (isLoading) {
    hycon.debug(`${this.constructor.name} setIsLoading`, { isLoading })
    let thisRef = this
    return thisRef.setStateProperty('isLoading', isLoading)
  }

  initListeners (listenerObject, onEnterFn, onLeaveFn) {
    let onEnter = function onEnter (e) {
      hycon.debug('panel-controller: processing panel event: ', { e })
      onEnterFn()
    }
    let onLeave = function onLeave (e) {
      hycon.debug('panel-controller: processing panel event: ', { e })
      onLeaveFn()
    }
    listenerObject.addEventListener('panel-constructions-detail-enter', onEnter)
    listenerObject.addEventListener('panel-constructions-detail-leave', onLeave)
    return this.setStateProperty('listeners', [onEnter, onLeave])
  };

  updateGraphicsVisbility (isVisible) {
    hycon.debug(`${this.constructor.name} updateGraphicsVisbility`, { isVisible })
    let thisRef = this
    try {
      if (thisRef.state.mapHandler && isVisible) {
        thisRef.state.mapHandler.dispatchEnter(window)
      } else if (thisRef.state.mapHandler && !isVisible) {
        thisRef.state.mapHandler.dispatchLeave(window)
      } else {
        hycon.debug(`${this.constructor.name} updateGraphicsVisbility - unknown state`, { thisRef, isVisible })
      }
    } catch (e) {
      hycon.warn(e)
    }
  }

  renderState () {
    let thisRef = this
    let rendering = thisRef.state.rendering
    let dashboardPortal = document.getElementById(`dashboard-portal`)
    if (!thisRef.state.isMounted) return null
    let lang = thisRef.props.i18n.language

    let getString = (i18nextKey) => {
      return thisRef.props.i18n.t(i18nextKey)
    }

    if (rendering === RENDER_OVERVIEW) {
      thisRef.updateGraphicsVisbility(false)
      return (
        <div className={`Card ConstructionZone`}>
          <div className={'controls'}>
            <h4 className={'title'}>{thisRef.props.title}</h4>
            <MUIButton
              onClick={() => {
                let id = thisRef.props.id
                hycon.debug('Clicked card info ' + id)
                window.open(`${WixUtil.getLangBaseUrl(lang.toLowerCase())}/bauvorhaben`, '_blank');
              }}
            >
              <InfoIcon/>
            </MUIButton>
            <MUIButton
              onClick={() => {
                let id = thisRef.props.id
                hycon.debug('Clicked card settings' + id)
                thisRef.setStateProperty('rendering', RENDER_SETTINGS)
                Promise.all([
                  thisRef.props.setParentState('isPortalVisible', true),
                  thisRef.props.setParentState('isTilesVisible', false)
                ])
              }}
            >
              <SettingsIcon/>
            </MUIButton>
          </div>
          <div
            className={'body'}
            onClick={() => {
              let id = thisRef.props.id
              hycon.debug('Clicked card detail' + id)
              GoogleAnalytics.sendGenerigEvent('panel-detail-bauvorhaben', 'sep-panel-detail', 'Panel Interaction', true)
              thisRef.setStateProperty('rendering', RENDER_DETAIL)
              Promise.all([
                thisRef.props.setParentState('isPortalVisible', true),
                thisRef.props.setParentState('isTilesVisible', false)
              ])
              const buildingSelectionEvent = new CustomEvent('kibana-event', { detail: {
                  type: "panel-detail",
                  eventInfo: {
                    info: "Building-Applications panel has been opened."
                  }
                }
              });
              window.dispatchEvent(buildingSelectionEvent);
            }}
          >
            {thisRef.getOverview()}
            {
              thisRef.state.isLoading ? (
                <Dimmer active>
                  <Loader size='massive'>Loading {thisRef.props.title}</Loader>
                </Dimmer>
              ) : null
            }
          </div>
        </div>
      )
    } else if (rendering === RENDER_DETAIL) {
      thisRef.updateGraphicsVisbility(true)
      let detail = (
        <div className={`Card ConstructionZone`}>
          <div className={'controls'}>
            <h4 className={'title'}>{getString('dashboard:toolbar-details-label')} {thisRef.props.title}</h4>
            <MUIButton
              onClick={() => {
                let id = thisRef.props.id
                hycon.debug('Clicked card settings ' + id)
                thisRef.setStateProperty('rendering', RENDER_SETTINGS)
                Promise.all([
                  thisRef.props.setParentState('isPortalVisible', true),
                  thisRef.props.setParentState('isTilesVisible', false)
                ])
              }}
            >
              <SettingsIcon/>
            </MUIButton>
            <MUIButton
              onClick={() => {
                let id = thisRef.props.id
                hycon.debug('Clicked card close ' + id)
                thisRef.setStateProperty('rendering', RENDER_OVERVIEW)
                Promise.all([
                  thisRef.props.setParentState('isPortalVisible', false),
                  thisRef.props.setParentState('isTilesVisible', true)
                ])
              }}
            >
              <CloseIcon/>
            </MUIButton>
          </div>
          <div
            className={'body'}
            onClick={() => {
              // let id = thisRef.props.id;
              // hycon.debug("Clicked card " + id);
            }}
          >
            <ConstructionZoneDetail
              {...thisRef.props}
              {...thisRef.state}
            />
            {
              thisRef.state.isLoading ? (
                <Dimmer active>
                  <Loader size='massive'>Loading {thisRef.props.title}</Loader>
                </Dimmer>
              ) : null
            }
          </div>
        </div>
      )
      return ReactDOM.createPortal(detail, dashboardPortal)
    } else if (rendering === RENDER_SETTINGS) {
      thisRef.updateGraphicsVisbility(true)
      let settings = (
        <div className={`Card ConstructionZone`}>
          <div className={'controls'}>
            <h4 className={'title'}>{getString('dashboard:toolbar-settings-label')} {thisRef.props.title}</h4>
            <MUIButton
              onClick={() => {
                let id = thisRef.props.id
                hycon.debug('Clicked card close ' + id)
                thisRef.setStateProperty('rendering', RENDER_OVERVIEW)
                thisRef.props.setParentState('isPortalVisible', false)
                thisRef.props.setParentState('isTilesVisible', true)
              }}
            >
              <CloseIcon/>
            </MUIButton>
          </div>
          <div
            className={'body'}
            onClick={() => {
              // let id = thisRef.props.id;
              // hycon.debug("Clicked card " + id);
            }}
          >
            <div className={'settings'}>
              <ConstructionZoneSettings
                {...thisRef.props}
                {...thisRef.state}
                setStateProperty={thisRef.setStateProperty}
                onSettingsChange={(newSettings) => {
                  hycon.debug(`${this.constructor.name} onSettingsChange`, { newSettings, thisRef })
                  let data = thisRef.state.mapHandler.getCurrentData()
                  let isHighZoom = requestHandler.isHighZoom(data.map)
                  thisRef.state.mapHandler.updateIsHighZoom(isHighZoom)

                  if (isHighZoom) {
                    // buildings to display update the user settings
                    let constructions = thisRef.state.appsettings.profiles.default.panels.construction_zone.constructions
                    constructions.isHighZoom = isHighZoom
                    let newAppSettings = Object.assign({}, thisRef.state.appsettings)
                    newAppSettings.profiles.default.panels.construction_zone.constructions = constructions
                    return thisRef.fetchConstructionCategories(data.map)
                    .then(() => {
                      return thisRef.setStateProperty('appsettings', newAppSettings)
                    })
                    .then(() => {
                      thisRef.updateUserSettings()
                    }).then(() => {
                      thisRef.fetchConstructions(isHighZoom, data.map)
                    })
                  } else {
                    // no buildings to display but update the user settings
                    let constructions = thisRef.state.appsettings.profiles.default.panels.construction_zone.constructions
                    constructions.response = { data: [] }
                    constructions.isHighZoom = isHighZoom
                    let newAppSettings = Object.assign({}, thisRef.state.appsettings)
                    newAppSettings.profiles.default.panels.construction_zone.constructions = constructions
                    return thisRef.fetchConstructionCategories(data.map)
                    .then(() => {
                      return thisRef.setStateProperty('appsettings', newAppSettings)
                    })
                    .then(() => {
                      thisRef.updateUserSettings()
                    }).then(() => {
                      thisRef.fetchConstructions(isHighZoom, data.map)
                    })
                  }
                }}
              />
            </div>
            {
              thisRef.state.isLoading ? (
                <Dimmer active>
                  <Loader size='massive'>Loading {thisRef.props.title}</Loader>
                </Dimmer>
              ) : null
            }
          </div>
        </div>
      )
      return ReactDOM.createPortal(settings, dashboardPortal)
    } else {
      throw new Error('unconsistent rendering state')
    }
  }

  render () {
    let thisRef = this
    // let isSelected = thisRef.props.selectedCardIds.includes(thisRef.props.id);
    return thisRef.renderState()
  }

  fetchConstructionCategories (map) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} fetchConstructionCategories`, { map, thisRef })
    thisRef.setStateProperty('isLoading', true)
    return requestHandler.getConstructionZonesCategories(thisRef, map).then((categories) => {
      hycon.debug(`${this.constructor.name} componentDidUpdate getConstructionZonesCategories categories`, {
        categories
      })
      let constructions = thisRef.state.appsettings.profiles.default.panels.construction_zone.constructions
      // update the dd selected indexes
      let settings = thisRef.state.appsettings.profiles.default.panels.construction_zone.constructions.settings
      settings.options = categories
      //inject them into the construction object
      constructions.settings = settings
      return thisRef.setStateProperty('constructions', constructions).then(() => {
        hycon.debug(`${thisRef.constructor.name} getConstructionZonesCategories update state done`, { constructions })
        return thisRef.setStateProperty('isLoading', false)
      })
    })
  }

  fetchConstructions (isHighZoom, map) {
    let thisRef = this
    hycon.debug(`${this.constructor.name} fetchConstructions `, { state: thisRef.state })
    return thisRef.setStateProperty('isLoading', true).then(() => {
      return thisRef.fetchConstructionCategories(map).then(() => {
        return requestHandler.getConstructionZones(thisRef, map)
        .then((response) => {
          hycon.debug(`${this.constructor.name} componentDidUpdate response `, {
            response,
            isHighZoom
          })
          let constructions = thisRef.state.appsettings.profiles.default.panels.construction_zone.constructions
          constructions.response = response
          constructions.isHighZoom = isHighZoom
          hycon.debug(`${this.constructor.name} onSettingsChange - new constructions`, { constructions })
          return thisRef.setStateProperty('constructions', constructions).then(() => {
            thisRef.state.mapHandler.updateConstructions(constructions)
          })
        })
      })
    }).then(() => {
      thisRef.setStateProperty('isLoading', false)
    }).catch(() => {
      thisRef.setStateProperty('isLoading', false)
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} componentDidUpdate`, {
      prevProps,
      newProps: this.props,
      prevState,
      snapshot
    })
    if (thisRef.state.mapHandler) {
      thisRef.state.mapHandler.updateReduxState(thisRef.props.reduxState, () => {
        thisRef.state.mapHandler.initMapListener((evt, data) => {
          hycon.debug(`${this.constructor.name} componentDidUpdate event `, { evt, ...data })
          let isHighZoom = requestHandler.isHighZoom(data.map)
          thisRef.state.mapHandler.updateIsHighZoom(isHighZoom)
          if (isHighZoom) {
            thisRef.fetchConstructions(isHighZoom, data.map)
          } else {
            try {
              let constructions = thisRef.state.appsettings.profiles.default.panels.construction_zone.constructions
              constructions.response = { data: [] }
              constructions.isHighZoom = isHighZoom
              let newAppSettings = Object.assign({}, thisRef.state.appsettings)
              newAppSettings.profiles.default.panels.construction_zone.constructions = constructions
              return thisRef.setStateProperty('appsettings', newAppSettings)
            }catch (e) {
              hycon.error(`${this.constructor.name} componentDidUpdate appsettings `, {e})
              let appSettingsDefault = {
                profiles: {
                  default: {
                    panels: {
                      construction_zone: {
                        constructions: {
                          settings: {
                            fromDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
                            options: [],
                            selectedOptionIndexes: [],
                            isSetByUser: false
                          }
                        },
                      }
                    }
                  }
                }
              }
              let constructions = appSettingsDefault.profiles.default.panels.construction_zone.constructions
              constructions.response = { data: [] }
              constructions.isHighZoom = isHighZoom
              let newAppSettings = Object.assign({}, appSettingsDefault)
              newAppSettings.profiles.default.panels.construction_zone.constructions = constructions
              return thisRef.setStateProperty('appsettings', newAppSettings)
            }
          }
        }, thisRef)
      })
    }
  }

  componentWillUnmount () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} componentWillUnmount`, { props: this.props })
    try {
      thisRef.state.mapHandler.removeListener()
    } catch (e) {
      hycon.warn(`${this.constructor.name} componentWillUnmount - listener error`, { props: this.props })
    }
  }

  componentDidCatch (error, errorInfo) {
    // let thisRef = this;
    hycon.debug(`${this.constructor.name} componentDidCatch`, { error, errorInfo })
  }
}

export default withTranslation([
  'panel_construction_zone'
])(ConstructionZone)

