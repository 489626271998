import React, { Component, /*useState*/ } from 'react'
// import HyperConsole from "../../../hyper_console/hyper-console";
import './UsageDetail.css'
import Slider from 'react-slick'
import HyperConsole from '../../../../hyper_console/hyper-console'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import { UserUtil } from '../../../../user_management/util/user-util'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

class UsageDetail extends Component {
  constructor (props) {
    super(props)
    this.state = {}
    // let thisRef = this;
    console.debug(`${this.constructor.name} constructor`, { props: this.props })
  }

  componentDidMount () {
    // let thisRef = this;
    console.debug(`${UsageDetail.name} componentDidMount`, { props: this.props })
  }

  static getDerivedStateFromProps (props, state) {
    console.debug(`${UsageDetail.name} getDerivedStateFromProps`, { props, state })
    if (!state) {
      console.debug(`${UsageDetail.name} getDerivedStateFromProps - only props have been updated`, {
        props,
        state
      })
    }
    return state
  }

  shouldComponentUpdate (nextProps, nextState) {
    // let thisRef = this;
    console.debug(`${this.constructor.name} shouldComponentUpdate`, { nextProps, props: this.props, nextState })
    return true
  }

  getDetail () {
    let thisRef = this
    let buildings = thisRef.props.reduxState.selectedBuildings
    let buildingsNr = buildings.length
    if (
      thisRef.props.panelState &&
      thisRef.props.panelState.map &&
      buildingsNr > 0
    ) {
      let components = []
      let sortedMap = thisRef.props.panelState.sortedMap
      let googlePlaces = thisRef.state.googlePlaces
      let index = 0
      if (!sortedMap) {
        return null
      }
      sortedMap.forEach((sortedMapElement) => {
        index = index + 1
        components.push((
          <div key={index} className={'building'}>
            <DetailItem
              {...thisRef.props}
              data={{
                index,
                element: sortedMapElement[1],
                buildingId: sortedMapElement[1].buildingSepId,
                googlePlaces
              }}
            />
          </div>
        ))
      })

      return <div className={'content'}>{components}</div>
    } else {
      return (
        <div className="DetailItem">
          <div className={`detail`}>
            <div className={'empty-selection'}>
              {thisRef.props.i18n.t('panel_usage:label-help-text')}
            </div>
          </div>
        </div>
      )
    }
  }

  render () {
    let thisRef = this
    return (
      <div className="UsageDetail detail">
        {thisRef.getDetail()}
      </div>
    )
  }

  componentDidUpdate () {
    // let thisRef = this;
    console.debug(`${this.constructor.name} componentDidUpdate`, { props: this.props })
  }

  componentWillUnmount () {
    // let thisRef = this;
    console.debug(`${this.constructor.name} componentWillUnmount`, { props: this.props })
  }

  componentDidCatch (error, errorInfo) {
    let thisRef = this
    console.debug(`${thisRef.constructor.name} componentDidCatch`, { error, errorInfo })
    throw error
  }
}

class DetailItem extends React.Component {
  constructor (props, state) {
    super(props, state)
    this.state = {
      isOpen: false,
      googlePlaces: [],
      ownershipData: null
    }
  }

  componentDidMount () {
    let thisRef = this
    console.debug(`${UsageDetail.name} componentDidMount`, { props: this.props })
    thisRef.getGooglePlaces().then(async (placesMap) => {
      await thisRef.setStateProperty('googlePlaces', placesMap)

      let data = await thisRef.getOwnershipData()
      console.debug(`${UsageDetail.name} componentDidMount - getOwnershipData`, { data })
      return await thisRef.setStateProperty('ownershipData', data)
    })
  }

  async getOwnershipData () {
    let thisRef = this

    let egids = []
    thisRef.props.data.element.gwr.forEach((gwrBuilding) => {
      let buildingEgid = gwrBuilding.egid
      hycon.debug(`${thisRef.constructor.name} getOwnershipData`, { gwrBuilding, buildingEgid })
      egids.push(buildingEgid)
    })

    const jwt = thisRef.props.reduxState.user.jwt
    let endpoint = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/api/ownerprediction/${egids.join(',')}`
    let req = axios({
      method: 'get',
      url: endpoint,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${jwt}`,
      }
    })
    .then(function (response) {
      hycon.debug(`${thisRef.constructor.name} getOwnershipData`, response)
      return response.data
    })
    .catch((e) => {
      hycon.error(`${thisRef.constructor.name} getOwnershipData`, e)
      UserUtil.authenticationRedirect(e.response, thisRef)
      return null
    })
    return await req
  }

  setStateProperty (propertyName, propertyValue) {
    let thisRef = this
    return new Promise((res) => {
      thisRef.setState((p) => {
        const obj = {}
        obj[propertyName] = propertyValue
        return Object.assign({}, p, obj)
      }, () => {
        hycon.debug(`${this.constructor.name} setStateProperty - newState`, { newState: thisRef.state })
        res(thisRef.state)
      })
    })
  }

  async getGooglePlaces () {
    let thisRef = this
    const selectedBuildings = thisRef.props.reduxState.selectedBuildings
    const jwt = thisRef.props.reduxState.user.jwt

    let requests = []

    selectedBuildings.forEach((selectedBuilding) => {
      let buildings = selectedBuilding.buildings
      buildings.forEach((building) => {
        let latitude = building.coordinates.latitude
        let longitude = building.coordinates.longitude
        let buildingId = building.id
        let endpoint = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/api/googleplaces-by-point?x=${longitude}&y=${latitude}&srid=4326&includePhotos=true`
        let req = axios({
          method: 'get',
          url: endpoint,
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`,
          }
        })
        .then(function (response) {
          hycon.debug(`${thisRef.constructor.name} getGooglePlaces`, response)
          let enhancedResponse = response
          enhancedResponse.data.buildingId = buildingId
          hycon.debug(`${thisRef.constructor.name} getGooglePlaces - enhanced response`, enhancedResponse)
          return enhancedResponse
        })
        .catch((e) => {
          hycon.error(`${thisRef.constructor.name} getGooglePlaces`, e)
          UserUtil.authenticationRedirect(e.response, thisRef)
        })
        requests.push(req)
      })
    })

    /* perform the requests */
    return await Promise.all(requests).then((values) => {
      hycon.debug(`${thisRef.constructor.name} getGooglePlaces response`, {
        values
      })
      let places = []
      let placesMap = {}
      values.forEach((value) => {
        if (!value) return
        let buildingId = value.data.buildingId
        value.data.forEach(place => {
          hycon.debug(`${thisRef.constructor.name} getGooglePlaces - mapping place`, {
            place, value
          })
          if (place) {
            places.push(place)
            if (!placesMap[buildingId]) {
              placesMap[buildingId] = [place]
            } else {
              placesMap[buildingId].push(place)
            }
          }
        })
      })
      return placesMap
    })
  }

  getCompanyComponent () {
    let thisRef = this
    let companies = thisRef.props.data.element.companies
    // let element = thisRef.props.data.element;
    let comps = []
    let allowedProps = [
      'uidOrganisationId',
      'legalform',
    ]
    let allowedPropsMap = [
      `${thisRef.props.i18n.t('panel_usage:label-uid')}`,
      `${thisRef.props.i18n.t('panel_usage:label-legal-form')}`,
    ]

    function sortAlphabetically (objs, objProperty) {
      if (
        !objs
      ) {
        return objs
      }

      function compare (a, b) {
        if (a[`${objProperty}`] < b[`${objProperty}`])
          return -1
        if (a[`${objProperty}`] > b[`${objProperty}`])
          return 1
        return 0
      }

      try {
        let sorted = objs.sort(compare)
        return sorted
      } catch (e) {
        console.warn(e)
        return objs
      }
    }

    sortAlphabetically(companies, 'organisationLegalName')

    companies.forEach((company, companyIndex) => {
      let companyProps = []
      let index = 0
      for (let p in company) {
        if (company.hasOwnProperty(p) && allowedProps.includes(p)) {
          index = index + 1
          companyProps.push(
            <div
              key={`${p}_${index}`}
              className={'company-property'}>
              <span className={'property-fat'}>{allowedPropsMap[allowedProps.indexOf(p)]}:</span>
              &nbsp;{company[p]}
            </div>
          )
        }
      }

      comps.push(
        <div key={`company_index_${companyIndex}`} className={'company'}>
          <h4>{company['organisationLegalName']}</h4>
          <div
            className={'companyProps'}
          >
            {companyProps}
            {(() => {
              if (
                company['status'] !== null &&
                typeof company['status'] !== 'undefined'
              ) {
                return (
                  <div
                    key={`status_${index}`}
                    className={'company-property long-txt'}>
                    <div className={'property-fat'}>{thisRef.props.i18n.t('panel_usage:label-status')}</div>
                    <div>
                      {thisRef.props.i18n.t(`panel_usage:label-status-${company['status']}`)}
                    </div>
                  </div>
                )
              }
            })()}
            {(() => {
              if (
                company['purpose'] !== null &&
                typeof company['purpose'] !== 'undefined'
              ) {
                // hycon.debug(`${UsageDetail.name} - display zefix link`, { thisRef, company })
                return (
                  <div
                    key={`purpose_${index}`}
                    className={'company-property long-txt'}>
                    <div className={'property-fat'}>{thisRef.props.i18n.t('panel_usage:label-purpose')}</div>
                    <div className={'scrollable'}>
                      {company['purpose']}
                    </div>
                    <div>
                      <a href={`https://www.zefix.ch/de/search/entity/list/firm/${company.id}`} target="_blank" rel="noopener noreferrer">
                        {thisRef.props.i18n.t('panel_usage:zefix-more-data')}
                      </a>
                    </div>
                  </div>
                )
              }
            })()}
          </div>
        </div>
      )
    })
    return comps.length > 0 ? comps :
      <div>{thisRef.props.i18n.t('panel_usage:label-no-zefix-data')}</div>
  }

  getBranches (hasGWR, building, gwr) {
    let thisRef = this

    try {
      let branches = []
      let trackedBranches = [
        'verkauf',
        'versammlungslokal',
        'verwaltung',
        'restaurant',
        'spital',
        'sportbau',
        'schule',
        'industrie',
        'lager',
        'hallenbad',
      ]

      /*hycon.debug(`${thisRef.constructor.name} getBranches`, {
        thisRef, building, companies: thisRef.props.panelState.panelState.companies, gwr
      })*/

      if (gwr && gwr.length) {
        let filteredGwr = gwr.filter(gwrEntry => {
          return gwrEntry.buildingIdSep === building.id
        })
        filteredGwr.forEach((gwrBuilding) => {
          let buildingEgid = gwrBuilding.egid
          thisRef.props.panelState.panelState.companies.forEach(company => {
            trackedBranches.forEach(trackedBranch => {
              let isBranchAlreadyIncluded = branches.includes(trackedBranch)
              let isBranchActive = company[`${trackedBranch}`]
              let matchesEgid = company.egid === buildingEgid
              /*hycon.debug(`${thisRef.constructor.name} getBranches - matching zefix`, {
                isBranchAlreadyIncluded, isBranchActive, matchesEgid, trackedBranch, company, buildingEgid
              });*/
              if (
                !isBranchAlreadyIncluded &&
                isBranchActive &&
                matchesEgid
              ) {
                branches.push(trackedBranch)
              }
            })
          })
        })
      }

      // get the branches from google
      if (
        thisRef.state.googlePlaces &&
        thisRef.state.googlePlaces[building.id] &&
        thisRef.state.googlePlaces[building.id].length > 0
      ) {
        let googlePlaces = thisRef.state.googlePlaces[building.id]
        /*hycon.debug(`${thisRef.constructor.name} getBranches - matching google`, {
          googlePlaces, googlePlacesMap: thisRef.state.map, building
        });*/
        googlePlaces.forEach(googlePlace => {
          trackedBranches.forEach(trackedBranch => {
            let isBranchAlreadyIncluded = branches.includes(trackedBranch)
            let isBranchActive = googlePlace[`${trackedBranch}`] === true
            /*hycon.debug(`${thisRef.constructor.name} getBranches - matching google`, {
              isBranchAlreadyIncluded, isBranchActive, trackedBranch, googlePlace, building
            });*/
            if (
              !isBranchAlreadyIncluded &&
              isBranchActive
            ) {
              branches.push(trackedBranch)
            }
          })
        })
      }

      let components = branches.map((branch, branchIndex) => <div
        key={branchIndex}>{thisRef.props.i18n.t(`panel_usage:branch-${branch}`)}</div>)
      hycon.debug(`${thisRef.constructor.name} getBranches`, { components, branches })
      return components
    } catch (e) {
      hycon.error(e)
      return <div>{e.message}</div>
    }
  }

  getBuildingUsages (building) {
    let thisRef = this

    let data = thisRef.props.data
    const getGwrUsage = () => {
      // panelState.gwrData[""0""].housingEgids.gebKategorie
      let usages = data.element.gwr.map((gwr) => {
        return thisRef.props.i18n.t(`dynamic_panel_marketsense_v4:value-gebKategorieShort-${gwr.housingEgids.gebKategorieShort}`);
      })
      if (usages.length > 1) {
        return thisRef.props.i18n.t('panel_usage:label-panel-various')
      } else if (usages.length === 1) {
        return usages[0]
      } else {
        return null
      }
    }
    let nutzungGWR = null
    let hasGWR = false

    hycon.debug(`${thisRef.constructor.name} getBuildingUsages - uniqueUsages`, { props: thisRef.props })

    let zones = []

    if (thisRef.props.i18n.language === 'de-CH') {
      // uniqueZones = [...new Set(thisRef.props.panelState.panelState.bauzonen.map(bz => bz.chBezD))]
      zones = thisRef.props.panelState.panelState.bauzonen.filter((bz) => {
        return bz.building.id === building.id
      }).map(zone => zone.chBezD)
    } else if (thisRef.props.i18n.language === 'fr-CH') {
      // uniqueZones = [...new Set(thisRef.props.panelState.panelState.bauzonen.map(bz => bz.chBezF))]
      zones = thisRef.props.panelState.panelState.bauzonen.filter((bz) => {
        return bz.building.id === building.id
      }).map(zone => zone.chBezF)
    } else {
      // uniqueZones = [...new Set(thisRef.props.panelState.panelState.bauzonen.map(bz => bz.chBezD))]
      zones = thisRef.props.panelState.panelState.bauzonen.filter((bz) => {
        return bz.building.id === building.id
      }).map(zone => zone.chBezD)
    }

    try {
      hycon.debug(`${thisRef.constructor.name} getBuildingUsages - nutzungGWR`, { props: thisRef.props })
      nutzungGWR = getGwrUsage()
      hasGWR = nutzungGWR !== null
      hycon.debug(`${thisRef.constructor.name} getBuildingUsages - nutzungGWR - debug`, {
        nutzungGWR,
        props: thisRef.props
      })
    } catch (e) {
      hycon.warn(`${thisRef.constructor.name} getBuildingUsages - nutzungGWR - warn`, {
        e,
        nutzungGWR,
        props: thisRef.props
      })
      console.warn('no gwr data for this building', data)
    }

    const getMainUsage = () => {
      let uniqueZones = zones
      let gwrUsage = getGwrUsage()
      hycon.debug(`${thisRef.constructor.name} getMainUsage`, { uniqueZones, gwrUsage, props: thisRef.props })
      if (uniqueZones.length === 0) {
        // if there is gwr info display it, otherwise use the zone info
        // if there is more than one gwr usage then display panel_usage:label-panel-various
        if (gwrUsage === null) {
          return thisRef.props.i18n.t('panel_usage:label-no-usage-data')
        } else {
          return gwrUsage
        }
      } else if (uniqueZones.length === 1) {
        return uniqueZones[0]
      } else if (uniqueZones.length > 1) {
        return thisRef.props.i18n.t('panel_usage:label-panel-various')
      } else {
        return thisRef.props.i18n.t('panel_usage:label-no-usage-data')
      }
    }

    let comp = (
      <div className={'building-usage'}>
        <div className={'company-property'}>
          <span
          className={'property-fat'}>
          {thisRef.props.i18n.t('panel_usage:label-main-usage')}
          </span>
          &nbsp;{getMainUsage()}
        </div>
        <div className={'multicategory'}>
        <span
          className={'property-fat'}>{thisRef.props.i18n.t('panel_usage:label-building-category')}
        </span>
          {(() => {
            if (nutzungGWR !== null) {
              let categories = []
              let getCategory = (gwr) => {
                try {
                  return thisRef.props.i18n.t(`dynamic_panel_marketsense_v4:value-gebKategorieShort-${gwr.housingEgids.gebKategorieShort}`);
                } catch (e) {
                  console.info(e.message)
                  return null
                }
              }
              data.element.gwr.forEach(gwr => {
                let category = getCategory(gwr)
                if (
                  category && categories.indexOf(category) === -1
                ) {
                  categories.push(category)
                }
              })
              return categories.map((category, index) => {
                return (<div key={`cat_map_index_${index}`}>{category}</div>)
              })
            } else {
              return null
            }
          })()}
        </div>
        <div className={'multiclasses'}>
        <span
          className={'property-fat'}>{thisRef.props.i18n.t('panel_usage:label-building-classes')}
        </span>
          {(() => {
            if (nutzungGWR !== null) {
              let classes = []
              let getCass = (gwr) => {
                try {
                  return gwr.housingEgids.gebKlasse
                } catch (e) {
                  console.info(e.message)
                  return null
                }
              }
              data.element.gwr.forEach(gwr => {
                let buildingClass = getCass(gwr)
                if (
                  buildingClass && classes.indexOf(buildingClass) === -1
                ) {
                  classes.push(buildingClass)
                }
              })
              return classes.map((buildingClass, index) => {
                return (<div key={`class_map_index_${index}`}>{thisRef.props.i18n.t(`panel_usage:label-building-classes-${buildingClass}`)}</div>)
              })
            } else {
              return null
            }
          })()}
        </div>
        <div>
          <span
            className={'property-fat'}
          >
            {thisRef.props.i18n.t('panel_usage:label-building-eigenuemerverhaeltnisse')}
           </span>
          <div className={'ownerprediction'}>
            {(() => {
              hycon.debug(`${thisRef.constructor.name} getOwnershipData`, { ownershipData: thisRef.state.ownershipData })
              let ownerShipData = thisRef.state.ownershipData || []
              const uniqueOwnerships = [...new Set(ownerShipData.map((ownership, i) => {
                if (
                  (
                    ownership.pEigenbedarf  > 0.5
                  )
                ) {
                  return thisRef.props.i18n.t('panel_usage:label-building-eigennutzung-eigennutzung')
                } else {
                  return thisRef.props.i18n.t('panel_usage:label-building-eigennutzung-noeigennutzung')
                }
              }))]
              if (uniqueOwnerships.length > 0) {
                return <div>{uniqueOwnerships.map((ownership, ownerShipIndex) => <React.Fragment key={ownerShipIndex}>{ownership}<br/></React.Fragment>)}</div>
              } else {
                return <div>{thisRef.props.i18n.t('panel_usage:not-available')}</div>
              }
            })()}
          </div>
        </div>
        <div>
          <span
            className={'property-fat'}
          >
            {thisRef.props.i18n.t('panel_usage:label-building-eigenuemerart')}
           </span>
          <div className={'ownerprediction'}>
            {(() => {
              hycon.debug(`${thisRef.constructor.name} getOwnershipData`, { ownershipData: thisRef.state.ownershipData })
              let ownerShipData = thisRef.state.ownershipData || []
              const uniqueOwnerships = [...new Set(ownerShipData.map((ownership, i) => {
                if (
                  (
                    ownership.pFirma > ownership.pPrivat && ownership.pFirma > ownership.pPublic
                  )
                ) {
                  return thisRef.props.i18n.t('panel_usage:label-building-eigenuemerart-unternehmen')
                } else if (
                  (
                    ownership.pPrivat > ownership.pFirma && ownership.pPrivat > ownership.pPublic
                  )
                ) {
                  return thisRef.props.i18n.t('panel_usage:label-building-eigenuemerart-privat')
                } else if (
                  (
                    ownership.pPublic > ownership.pPrivat && ownership.pPublic > ownership.pFirma
                  )
                ) {
                  return thisRef.props.i18n.t('panel_usage:label-building-eigenuemerart-oeffentlichehand')
                } else {
                  hycon.warn(`${thisRef.constructor.name} getOwnershipData - unknown ownership type`, { ownership })
                  return null
                }
              }))]
              if (uniqueOwnerships.length > 0) {
                return <div>{uniqueOwnerships.map((ownership, ownershipIndex) => <React.Fragment key={ownershipIndex}>{ownership}<br/></React.Fragment>)}</div>
              } else {
                return <div>{thisRef.props.i18n.t('panel_usage:not-available')}</div>
              }
            })()}
          </div>
        </div>
        <div className={'branches'}>
						<span
              className={'property-fat'}>{thisRef.props.i18n.t('panel_usage:label-building-branches')}:
						</span>
          <React.Fragment>
            {thisRef.getBranches(hasGWR, building, data.element.gwr)}
          </React.Fragment>
        </div>
      </div>
    )
    return comp
  }

  render () {
    let thisRef = this
    let data = thisRef.props.data
    let companies = thisRef.props.data.element.companies
    // let element = thisRef.props.data.element;
    return (
      <div className={'DetailItem'}>

        <div className={'heading'}>
          <h2>{thisRef.props.i18n.t('panel_usage:label-building')} {data.element.index + 1}</h2>
          {thisRef.getBuildingUsages(data.element.building)}
          <div className={'building-usage'}>
            <div
              className={'company-property accordion'}
              onClick={() =>
                thisRef.setState((p) => {
                  return Object.assign({ ...p, isOpen: !thisRef.state.isOpen })
                })
              }
            >
              <h3
                className={'property-fat'}>{thisRef.props.i18n.t('panel_usage:label-company-register')} {companies.length}
                {
                  thisRef.state.isOpen ?
                    <i className="fas fa-chevron-up"/> :
                    <i className="fas fa-chevron-down"/>
                }
              </h3>
            </div>
          </div>
          <div>
            {thisRef.state.isOpen ? (
              <div>
                {thisRef.getCompanyComponent()}
              </div>
            ) : null}
          </div>
          <GooglePlacesDetail
            googlePlaces={thisRef.state.googlePlaces}
            element={thisRef.props.data.element}
            reduxState={thisRef.props.reduxState}
            i18n={thisRef.props.i18n}
          />
        </div>
      </div>
    )
  }

  componentDidUpdate () {
    // let thisRef = this;
    console.debug(`${this.constructor.name} componentDidUpdate`, { props: this.props })
  }
}

class GooglePlacesSlideshow extends React.Component {
  constructor (props, state) {
    super(props, state)
    this.state = {
      isOpen: false
    }
  }

  componentDidMount () {
    // let thisRef = this;
    console.debug(`${UsageDetail.name} componentDidMount`, { props: this.props })
  }

  getPhotoSlider () {
    let thisRef = this
    let googlePlace = thisRef.props.googlePlace
    let photoReferences = googlePlace.photoReferences
    if (!photoReferences) {
      console.debug('no photos', { googlePlace })
      return null
    }
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true
    }
    const jwt = thisRef.props.reduxState.user.jwt
    return (
      <Slider {...settings}>
        {(() => {
          let images = []
          photoReferences.forEach((pr, index) => {
            let imageUrl = `url('${thisRef.props.reduxState.env.API_GATEWAY_BASE}/api/googleplaces-photos?photoreference=${pr}&jwt=${jwt}&sensor=false&maxheight=400&maxwidth=400')`
            console.debug(`${this.constructor.name} getPhotoSlider`, { imageUrl })
            images.push(
              <div key={index}>
                <div
                  style={{
                    backgroundImage: imageUrl,
                    backgroundSize: `cover`,
                    display: 'flex',
                    flex: 1,
                    minHeight: '200px',
                    maxHeight: '400px',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}
                />
              </div>
            )
          })
          return images
        })()}
      </Slider>
    )
  }

  render () {
    let thisRef = this
    // let element = thisRef.props.data.element;
    let googlePlace = thisRef.props.googlePlace
    let photoReferences = googlePlace.photoReferences
    if (!photoReferences || photoReferences.length === 0) {
      console.debug('no photoslideshow to display')
      return null
    }
    return (
      <div className={'GooglePlacesSlide'}>
        <div className={'heading'}>
          <div className={'building-usage'}>
            <div
              className={'company-property'}
              onClick={() =>
                thisRef.setState((p) => {
                  return Object.assign({ ...p, isOpen: !thisRef.state.isOpen })
                })
              }
            >
              <h4 className={'property-fat'}>Foto{photoReferences.length > 1 ? 's' : ''}
                {
                  thisRef.state.isOpen ?
                    <i className="fas fa-chevron-up"/> :
                    <i className="fas fa-chevron-down"/>
                }
              </h4>
            </div>
          </div>
          <div className={'slider-container'}>
            {(() => {
              if (thisRef.state.isOpen) {
                return thisRef.getPhotoSlider()
              }
            })()}
          </div>
        </div>
      </div>
    )
  }

  componentDidUpdate () {
    // let thisRef = this;
    console.debug(`${this.constructor.name} componentDidUpdate`, { props: this.props })
  }
}

class GooglePlacesDetail extends React.Component {
  constructor (props, state) {
    super(props, state)
    this.state = {
      isOpen: false,
    }
  }

  setStateProperty (propertyName, propertyValue) {
    let thisRef = this
    return new Promise((res) => {
      thisRef.setState((p) => {
        const obj = {}
        obj[propertyName] = propertyValue
        return Object.assign({}, p, obj)
      }, () => {
        hycon.debug(`${this.constructor.name} setStateProperty - newState`, { newState: thisRef.state })
        res(thisRef.state)
      })
    })
  }

  async componentDidMount () {
    console.debug(`${UsageDetail.name} componentDidMount`, { props: this.props })
  }

  sortAlphabetically (objs, objProperty) {
    if (
      !objs
    ) {
      return objs
    }

    function compare (a, b) {
      if (a[`${objProperty}`] < b[`${objProperty}`])
        return -1
      if (a[`${objProperty}`] > b[`${objProperty}`])
        return 1
      return 0
    }

    try {
      let sorted = objs.sort(compare)
      console.debug(`${UsageDetail.name} sortAlphabetically`, { pre: objs, post: sorted })
      return sorted
    } catch (e) {
      console.warn(e)
      return objs
    }
  }

  render () {
    let thisRef = this
    let data = thisRef.sortAlphabetically(thisRef.props.googlePlaces[thisRef.props.element.building.id], 'name')
    if (!data) {
      return null
    }
    return (
      <div className={'GooglePlacesDetail'}>

        <div className={'heading'}>
          <div className={'building-usage'}>
            <div
              className={'company-property accordion'}
              onClick={() =>
                thisRef.setState((p) => {
                  return Object.assign({ ...p, isOpen: !thisRef.state.isOpen })
                })
              }
            >
              <h3 className={'property-fat'}>{thisRef.props.i18n.t('panel_usage:label-google-places')} {(() => {
                if (data) {
                  return data.length
                }
              })()}
                {
                  thisRef.state.isOpen ?
                    <i className="fas fa-chevron-up"/> :
                    <i className="fas fa-chevron-down"/>
                }
              </h3>
            </div>
          </div>
          <div>
            {(() => {
              if (thisRef.state.isOpen) {
                let poiComponentArray = []
                if (
                  !data
                ) {
                  poiComponentArray.push(
                    <div className={'point-of-interest'} key={0}>
                      {thisRef.props.i18n.t('panel_usage:label-no-info-genric')}
                    </div>
                  )
                  return poiComponentArray
                }
                data.forEach((googlePlace, index) => {
                  if (googlePlace) {
                    try {
                      poiComponentArray.push(
                        <div className={'point-of-interest'} key={index}>
                          <div className={'poi'}>
                            <h4>{googlePlace.name}</h4>
                            {/*<div>{googlePlace.name}</div>*/}
                          </div>
                          <div className={'poi'}>
                            <h4>{thisRef.props.i18n.t('panel_usage:label-type')}&nbsp;</h4>
                            <div>{googlePlace.types.join(', ')}</div>
                          </div>
                          <GooglePlacesSlideshow
                            googlePlace={googlePlace}
                            reduxState={thisRef.props.reduxState}
                          />
                        </div>
                      )
                    } catch (e) {
                      poiComponentArray.push(
                        <div className={'point-of-interest'}>
                          Keine weitere Informationend vorhanden.
                        </div>
                      )
                    }
                  }
                })
                return poiComponentArray
              }
            })()}
          </div>
        </div>
      </div>
    )
  }

  componentDidUpdate () {
    // let thisRef = this;
    console.debug(`${this.constructor.name} componentDidUpdate`, { props: this.props })
  }
}

export default withTranslation([
  'panel_usage', 'dynamic_panel_marketsense_v4'
])(UsageDetail)
