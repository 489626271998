import RemoteLogger from "./RemoteLogger";

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV;

let isLogsDisabled = true;
if(REACT_APP_GI_ENV === "test"){
    isLogsDisabled = true;
}

class HyperConsole {
    static remoteLogger = null;
    constructor(opts={}) {
        let thisRef = this;
        this.myConsole = {};
        this.opts = opts;
        this.name = this.opts.name;

        // override
        this.opts.isRemoteDebuggerEnabled = false;

        if(!isLogsDisabled){
            console.info(`HyperConsole new instance - opts`, this.opts);
        }

        if(this.opts.isRemoteDebuggerEnabled){
            if(!HyperConsole.remoteLogger){
                HyperConsole.remoteLogger = new RemoteLogger();
            }
            HyperConsole.remoteLogger.emit([`HyperConsole (${this.name}) initialized`, {init: true}]);
        }

        if (this.opts.isEnabled) {
            for (let property in console) {
                if (console.hasOwnProperty(property)) {
                    this.myConsole[property] = function () {
                        // call the original function
                        console[property](...arguments);
                        if(thisRef.opts.isRemoteDebuggerEnabled){
                            HyperConsole.remoteLogger.emit(arguments);
                        }
                    };
                }
            }
            this.patchDebugFunction();
        } else {
            for (let property in console) {
                if (console.hasOwnProperty(property)) {
                    this.myConsole[property] = () => {
                        // do nothing
                    };
                }
            }
            this.patchDebugFunction(thisRef);
        }

        // assign other methods to the console
        this.myConsole["assignOpts"] = (...args) => {
            thisRef.assignOpts(args);
        };
    };

    assignOpts(opts){
        this.opts = Object.assign(this.opts, opts);
    }

    patchDebugFunction(ctx) {
        let thisRef = ctx;
        if (this.opts.isEnabled) {
            if (typeof this.myConsole.debug === "undefined") {
                let property = "debug";
                this.myConsole[property] = function () {
                    // call the original function
                    if(!isLogsDisabled){
                        console.info(...arguments);
                    }
                    if(thisRef.opts.isRemoteDebuggerEnabled){
                        HyperConsole.remoteLogger.emit(arguments);
                    }
                };
            }
        } else {
            if (typeof this.myConsole.debug === "undefined") {
                let property = "debug";
                this.myConsole[property] = () => {
                    // do nothing
                };
            }
        }
    }
}

export default HyperConsole;