import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import { LOCIZE_PANEL_NS, reducerActions, REACT_APP_GI_ENV } from '../../constants';
import SEPContext from '../../../../contexts/sep-context/SEPContext';
import { FavoriteContext } from '../../FavoriteProvider';
import FavoriteApi from '../../services/FavoriteApi';
import FavoriteUtils from '../../services/FavoriteUtils';
import HyperConsole from '../../../hyper_console/hyper-console';

let hycon = null;
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
}

export default function FavoriteSort() {
  const hookName = 'FavoritesSort';
  const { t } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false });
  const [sort, setSort] = useState('alphabetically');
  const { user: { jwt }, env } = useContext(SEPContext).SEPContext;
  const { dispatch, getFavorites } = useContext(FavoriteContext);
  const [currentSettingsId, setCurrentSettingsId] = useState(null);

  useEffect(() => {
    FavoriteApi.getUserFavoriteSortingMethod(jwt, env).then((settings) => {
      setSort(settings.sort);
      setCurrentSettingsId(settings.id);

      if (!settings.id) {
        hycon.debug('there are no saved sorting settings');
        FavoriteApi.postUserFavoriteSortingMethod(jwt, env).then((res) => {
          setCurrentSettingsId(res.data.id);
        });
      }
    });
  }, [jwt, env]);

  useEffect(() => {
    // Do not post any settings on initial render
    if (currentSettingsId !== null) {
      // When the user changes the sorting,
      // we also want to store the new sorting method in the backend
      try {
        FavoriteApi.updateUserFavoriteSortingMethod(jwt, env, currentSettingsId, sort);
      } catch (error) {
        // We are not able to save the settings in the backend.
        // Let user sort the entries in the client side
        hycon.debug(`${hookName} useEffect - unable to save the sorting method in the backend`, error);
      }
    }

    getFavorites().then((favorites) => {
      const sortedFavorites = favorites.mergedFavorites.sort(
        sort === 'alphabetically' ? FavoriteUtils.compareFavoritesByLocation : FavoriteUtils.compareFavoritesByDate,
      );
      dispatch({
        type: reducerActions.REPLACE,
        payload: {
          newState: {
            favorites: sortedFavorites,
          },
        },
      });
    });
  }, [sort, currentSettingsId, dispatch, getFavorites, jwt]);

  const handleChange = (event) => {
    setSort(event.target.value);
  };

  return (
    <div className={hookName}>
      <div className="favorites-sorting-container">
        <FormControl>
          <InputLabel id="favorite-sort-label">{t('sorting-placeholder', { ns: 'favorites' })}</InputLabel>
          <Select
            labelId="favorite-sort-label"
            value={sort}
            label={t('sorting-placeholder', { ns: 'favorites' })}
            onChange={handleChange}
          >
            <MenuItem value="newFirst">{t('sorting-by-date', { ns: 'favorites' })}</MenuItem>
            <MenuItem value="alphabetically">{t('sorting-by-alphabet', { ns: 'favorites' })}</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
