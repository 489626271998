import React, {Component} from 'react';
import HyperConsole from "../hyper_console/hyper-console";
import './Home.css';

import {Button} from 'semantic-ui-react';
import HyperMenu from "../hyper_menu/HyperMenu";
import Navbar from "../../components/sep_map/component_navbar/Navbar";
import {UserUtil} from "../user_management/util/user-util";
import { withTranslation } from 'react-i18next';

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV;
let hycon = null;
if (REACT_APP_GI_ENV === "development") {
  hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
} else {
  hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
}


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        hycon.debug(`${this.constructor.name} constructor`, {props: this.props});
    }

    componentWillMount() {
        hycon.debug(`${this.constructor.name} componentWillMount`, {props: this.props});
    }

    componentDidMount() {
        hycon.debug(`${this.constructor.name} componentDidMount`, {props: this.props});
    }

    componentWillReceiveProps() {
        hycon.debug(`${this.constructor.name} componentWillReceiveProps`, {props: this.props});
    }

    shouldComponentUpdate(nextProps, nextState) {
        hycon.debug(`${this.constructor.name} shouldComponentUpdate`, {nextProps, props: this.props, nextState});
        return true;
    }

    componentWillUpdate() {
        hycon.debug(`${this.constructor.name} componentWillUpdate`, {props: this.props});
    }


    setStateProperty(propertyName, propertyValue) {
        let thisRef = this;
        return new Promise((res) => {
            thisRef.setState((p) => {
                const obj = {};
                obj[propertyName] = propertyValue;
                return Object.assign({}, p, obj);
            }, () => {
                hycon.debug(`${this.constructor.name} setStateProperty - newState`, {newState: thisRef.state});
                res();
            })
        });
    }

    getWelcomeComponent(){
      let thisRef = this;
      return (
          <div>
              <h1>{thisRef.props.i18n.t("home:h1-welcome")}</h1>
              {/*<Trans i18nKey="home:h1-welcome" fa>
                weeeelcome
              </Trans>*/}
              <h4>
                  {thisRef.props.i18n.t("home:h4-welcome-long")}
              </h4>
          </div>
        );
    }

    getHomeComponent() {
        let thisRef = this;
        let isDefaultAnonymous = UserUtil.hasClientAndRole(thisRef, "default", "Anonymous");
        let isDefaultUser = UserUtil.hasClientAndRole(thisRef, "default", "User");

        if (isDefaultAnonymous) {
            return (
                <div className={"container"}>
                    <div className={"heading"}>
                        <img
                            alt={""}
                            className={"image"}
                            src={`https://geoimpactstorage.blob.core.windows.net/public/logo/${thisRef.props.appStateServer.clients[0]}/Logo-SEP-web.png`}
                        />
                        {thisRef.getWelcomeComponent()}
                        <div className={"button-container"}>
                            <Button size={"medium"} primary onClick={() => {
                                thisRef.props.history.push("/user/signup/");
                            }}>
                                {thisRef.props.i18n.t("home:button-registration")}
                            </Button>
                            <Button size={"medium"} primary onClick={() => {
                                thisRef.props.history.push("/user/signin/");
                            }}>
                                {thisRef.props.i18n.t("home:button-signin")}
                            </Button>
                        </div>
                    </div>
                </div>
            );
        } else if (
            isDefaultUser
        ) {
            return (
                <div className={"container"}>
                    <div className={"heading"}>
                        <img
                            className={"image"}
                            alt={""}
                            src={`https://geoimpactstorage.blob.core.windows.net/public/logo/${thisRef.props.appStateServer.clients[0]}/Logo-SEP-web.png`}
                        />
                        {thisRef.getWelcomeComponent()}
                        <div className={"button-container"}>
                            <Button size={"medium"} primary onClick={() => {
                                thisRef.props.history.push("/map/");
                            }}>
                                {thisRef.props.i18n.t("home:button-map")}
                            </Button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
              <div className={"container"}>
                  <div className={"heading"}>
                      <img
                        className={"image"}
                        alt={""}
                        src={`https://geoimpactstorage.blob.core.windows.net/public/logo/${thisRef.props.appStateServer.clients[0]}/Logo-SEP-web.png`}
                      />
                      {thisRef.getWelcomeComponent()}
                      <div className={"button-container"}>
                          <Button size={"medium"} primary onClick={() => {
                              thisRef.props.history.push("/map/");
                          }}>
                              {thisRef.props.i18n.t("home:button-map")}
                          </Button>
                      </div>
                  </div>
              </div>
            );
        }
    }

    render() {
      let thisRef = this;
      return (
            <div className="Home">
                <HyperMenu
                    {...thisRef.props}
                    ref={(element) => {
                      hycon.debug(`${thisRef.constructor.name} menu ref`, element);
                      thisRef.menu = element;
                    }}
                    content={(
                        <div className={"menu-content-wrapper"}>
                            <Navbar
                                {...this.props}
                                onMenuToggle={() => {
                                    hycon.debug(`${thisRef.constructor.name} menu-toggle`, {thisRef});
                                    thisRef.menu.setStateProperty("isOpen", !thisRef.menu.getState().isOpen);
                                }}
                                isSearchDisplayed={false}
                            />
                            {thisRef.getHomeComponent()}
                        </div>
                    )}
                />
            </div>
        );
    }

    componentDidUpdate() {
        hycon.debug(`${this.constructor.name} componentDidUpdate`, {props: this.props});
    }

    componentWillUnmount() {
        hycon.debug(`${this.constructor.name} componentWillUnmount`, {props: this.props});
    }
}

export default withTranslation()(Home);
