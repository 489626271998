import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';
import { useFavoriteCtx } from '../../FavoriteProvider';
import { LOCIZE_PANEL_NS, reducerActions, REACT_APP_GI_ENV } from '../../constants';
import HyperConsole from '../../../hyper_console/hyper-console';

import './FavoriteFilter.css';

let hycon = null;
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
}

export default function FavoriteFilter() {
  const hookName = 'FavoriteFilter';
  const favoriteContext = useFavoriteCtx();
  const { t } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false });
  const [filter, setFilter] = useState({
    global: null,
    address: null,
    group: null,
  });
  const updateFilterContext = () => {
    let filterFavorites = favoriteContext.favorites;
    if (filter.global !== null && filter.global.trim() !== '') {
      filterFavorites = filterFavorites.filter((favorite) => {
        let favoriteAddress = `${favorite.street} ${favorite.houseNumber}, ${favorite.swissZipCode} ${favorite.town}`;
        const favoriteGroup = `${favorite.group}`;
        let favoriteString = `${favoriteAddress} ${favoriteGroup}`;

        if (
          favorite.favoriteType === favoriteContext.favoriteTypes.REGION
                    && favorite.swissZipCode !== null
        ) {
          // normal region
          favoriteAddress = `${favorite.swissZipCode} ${favorite.town}`;
          favoriteString = `${favoriteAddress} ${favoriteGroup}`;
        } else if (
          favorite.favoriteType === favoriteContext.favoriteTypes.REGION
                    && favorite.swissZipCode === null
        ) {
          // switzerland
          favoriteAddress = `${favorite.name}`;
          favoriteString = `${favoriteAddress}`;
        }

        const globalFilter = filter.global;
        const isIncluded = favoriteString.toLowerCase().includes(globalFilter.toLowerCase());
        hycon.debug(`${hookName} useEffect - globalFilter`, {
          favorite, globalFilter, favoriteString, isIncluded,
        });
        return isIncluded;
      });
    }
    hycon.debug(`${hookName} useEffect - updateFilterContext`, { filter, filterFavorites });
    favoriteContext.dispatch({
      type: reducerActions.REPLACE,
      info: 'updateFilterContext',
      payload: {
        newState: {
          filterFavorites,
        },
      },
    });
  };
  useEffect(() => {
    hycon.debug(`${hookName} useEffect - favoriteContext.favorites, filter`, filter);
    updateFilterContext();
  }, [favoriteContext.favorites, filter]);

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - favoriteContext.paginationFavorites, filter`, filter);
    const filterFavorites = favoriteContext.filterFavorites ? favoriteContext.filterFavorites : [];
    const paginationFavorites = favoriteContext.paginationFavorites ? favoriteContext.paginationFavorites : [];
    if (
      paginationFavorites.length === 0
            && filterFavorites.length > 0
            && (filter && filter.global && filter.global.length > 0)
    ) {
      updateFilterContext();
      favoriteContext.dispatch({
        type: reducerActions.REPLACE,
        info: 'updateFilterContext',
        payload: {
          newState: {
            selectedPage: 1,
          },
        },
      });
    }
  }, [favoriteContext.paginationFavorites, favoriteContext.filterFavorites, filter]);

  return (
    <div className={hookName}>
      <div className="filter-container">
        <Input
          type="text"
          placeholder={t('favorites:label-filter-global')}
          value={filter.global || ''}
          fluid
          onChange={(e) => {
            setFilter({ ...filter, global: e.target.value });
          }}
        />
      </div>
    </div>
  );
}
