import React, { useState, useEffect, useRef } from 'react'
import HyperConsole from '../../../hyper_console/hyper-console'
import './RemoteController.css'
import { useTranslation } from 'react-i18next'
import * as signalR from '@aspnet/signalr/dist/browser/signalr'
import qs from 'qs'
import axios from 'axios'
import * as L from 'leaflet'
import iziToast from 'izitoast'
import 'izitoast/dist/css/iziToast.css'
// import env from "../../../../env/env";
import { useProperty } from '../../../util/hooks/hooks'
import { themes } from '../../../mui/themes'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
const LOCIZE_PANEL_NS = 'vgt_remote_control'
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}
function getIzitoastColor (props){
  const color = props.appStateServer.clients[0] === "sep" ? themes.geoimpact.palette.primary.main : themes.praedictio.palette.primary.main;
  return color;
}

function RemoteController (props) {
  const hookName = 'RemoteController'
  const [wsClientId, setWsClientId] = useState(null)
  const [settings, setSettings] = useState(null)
  const geometryGroup = useRef(null)
  const { t, i18n } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false })
  const requestSource = useRef(null)

  /*const freezeLeafletControls = () => {
    props.leafletMap.touchZoom.disable();
    props.leafletMap.doubleClickZoom.disable();
    props.leafletMap.scrollWheelZoom.disable();
    props.leafletMap.dragging.disable();

    // hide controls
    document.querySelectorAll(".control.zoomin")[0].style.display = "none";
    document.querySelectorAll(".control.zoomout")[0].style.display = "none";
  }*/

  const getMapAttribution = () => {
    let selectedLayer = props.selectedLayer
    let attriutionComponent = null
    if (selectedLayer === props.maps.Leaflet.layers.grey) {
      attriutionComponent = (
        <div>
          <a
            href={`https://www.swisstopo.admin.ch/`}
            target={`_blank`}>
            swisstopo
          </a>
        </div>)
    } else if (selectedLayer === props.maps.Leaflet.layers.color) {
      attriutionComponent = (
        <div>
          <a
            href={`https://www.swisstopo.admin.ch/`}
            target={`_blank`}>
            swisstopo
          </a>
        </div>)
    } else if (selectedLayer === props.maps.Leaflet.layers.cadastral) {
      attriutionComponent = (
        <div>
          <a
            href={`https://www.cadastre.ch/`}
            target={`_blank`}>
            cadastre
          </a>
        </div>)
    } else if (selectedLayer === props.maps.Google.layers.satellite) {
      let originalGoogleLogo = document.querySelectorAll('#sep-map-google > div > div > div:nth-child(3)')[0]
      /*let href = originalGoogleLogo.querySelectorAll("a").href;*/
      if (originalGoogleLogo) {
        originalGoogleLogo.style.display = 'none'
        attriutionComponent = (
          <div>
            <div dangerouslySetInnerHTML={{ __html: originalGoogleLogo.innerHTML }}/>
            {/*<a href={`https://www.google.com/maps`} target={`_blank`}>Google&nbsp;Maps</a>*/}
          </div>)
      } else {
        hycon.debug(`${hookName} getMapAttribution - google logo not found`)
      }
    }
    return (
      <div className={'remote-map-attribution'}>{attriutionComponent}</div>
    )
  }

  const getGeoimpactAttribution = () => {
    return (
      <div className={`remote-geoimpact-link`}>
        <a href={`https://www.swissenergyplanning.ch/`} target={`_blank`}>SEP</a> by <a
        href={`https://www.geoimpact.ch/`} target={`_blank`}>geoimpact</a>
      </div>
    )
  }

  const getComponentByMandant = () => {
    let components = null
    if (settings) {
      components = (
        <React.Fragment>
          {settings.mapAttribution ? getMapAttribution() : null}
          {settings.appAttribution ? getGeoimpactAttribution() : null}
        </React.Fragment>
      )
    } else {
      components = (
        <React.Fragment>
          {getMapAttribution()}
          {getGeoimpactAttribution()}
        </React.Fragment>
      )
    }
    return (
      <div className="RemoteController">
        {components}
      </div>
    )
  }

  useProperty(props, 'reduxState.map.map', () => {
    hycon.debug(`${hookName} useProperty - settings initial bounds`, { props, settings })
    if (settings && settings.initialBounds) {
      props.reduxState.map.map.fitBounds([
        [settings.initialBounds._southWest.lat, settings.initialBounds._southWest.lng],
        [settings.initialBounds._northEast.lat, settings.initialBounds._northEast.lng]
      ])
    }
  })
  /*  useEffect(() => {
      hycon.debug(`${hookName} useEffect - settings initial bounds`, { props, settings});
      props.reduxState.map.map.setBounds(settings.initialBounds)
    }, [props.reduxState.map.map])*/

  useEffect(() => {
    hycon.debug(`${hookName} useEffect`, { props, iziToast })
    const setParams = () => {
      try {
        if (
          props.location && props.location.search
        ) {
          let search = props.location.search
          search = search.substr(1, search.length)
          const parsed = qs.parse(search)
          const wsClientId = parsed.wsClientId

          /*
          let exampleSettings = {
            'mapAttribution': true,
            'appAttribution': true,
            initialBounds: {
              '_southWest': { 'lat': 47.05234678281785, 'lng': 7.134287513923644 },
              '_northEast': { 'lat': 47.22306533845898, 'lng': 7.350924171638487 }
            }
          }*/
          const settings = (() => {
            try {
              return JSON.parse(parsed.settings)
            } catch (e) {
              hycon.warn(`${hookName} could not parse remote map settings from query string`, { parsed, e })
              return null
            }
          })()

          const lang = parsed.lang
          hycon.debug(`${hookName} switchLanguage `, { targetLanguage: lang })
          i18n.changeLanguage(lang, () => {
            hycon.debug(`${hookName} switchLanguage - done`, { targetLanguage: lang, newLang: i18n.language })
          })

          hycon.debug(`${hookName} setParames`, { wsClientId, settings })
          setWsClientId(wsClientId)
          setSettings(settings)
        }
      } catch (e) {
        hycon.error(`${hookName} setParames`, { props, e })
      }
    }
    setParams()
    // eslint-disable-next-line
  }, [props.location])

  useEffect(() => {
    hycon.debug(`${hookName} useEffect`, props)
    const getGeometries = async (endpoint) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      requestSource.current = source
      return await axios(
        {
          method: 'get',
          url: endpoint,
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${props.SEPContext.user.publicJWT}`
          },
          cancelToken: source.token
        }
      )
      .then((response) => {
        hycon.debug(`${hookName} getPublicTokenInfo - response`, { response })
        return response.data
      })
      .catch((e) => {
        hycon.warn(`${hookName} getPublicTokenInfo - error`, { e })
        return null
      })
    }
    const refreshGraphics = (geometryDataArray, msgContent) => {
      // remove previous graphics
      if (geometryGroup.current) {
        props.reduxState.map.map.removeLayer(geometryGroup.current)
      }
      if (geometryDataArray === null) {
        hycon.debug(`${hookName} refreshGraphics - cleared all graphics`, { geometryDataArray, msgContent })
        return null
      }

      let colors = [
        '#192f9f',
        '#00c5ff',
        '#ffaa01',
        '#ff5501',
        '#a80000', // dark red
        '#ffffff'
      ]
      let roofLayers = []
      let markerLayers = []

      if (geometryDataArray.length === 1) {
        let geometryStyle = {
          'color': colors[2],
          'weight': 5,
          'opacity': 0.80,
          'fillOpacity': 0.5
        }
        let geojsonFeature = JSON.parse(geometryDataArray[0].geometries)
        let geojson = L.geoJSON(geojsonFeature, {
          style: geometryStyle
        })
        roofLayers.push(geojson)
      } else {
        geometryDataArray.forEach((geometryData, geometryIndex) => {
          let geometryStyle = {
            'color': colors[geometryIndex],
            'weight': 5,
            'opacity': 0.80,
            'fillOpacity': 0.5
          }
          let geojsonFeature = JSON.parse(geometryData.geometries)
          let geojson = L.geoJSON(geojsonFeature, {
            style: geometryStyle
          })
          roofLayers.push(geojson)
        })
      }

      let displayMarker = false
      let lat = null
      let lng = null
      if (msgContent) {
        lat = msgContent['Lat']
        lng = msgContent['Long']
        if (lat && lng) {
          displayMarker = true
        }
      }

      if (displayMarker) {
        const svgString = `
             <svg width="70" height="100" xmlns="http://www.w3.org/2000/svg">
          <metadata id="metadata2822">image/svg+xml</metadata>
          <g>
              <title>background</title>
              <rect x="-1" y="-1" width="72" height="102" id="canvas_background" fill="none" />
          </g>
          <g>
              <title>Layer 1</title>
              <path d="m34.96515,99.35066c-0.21626,-0.52746 -4.70929,-4.93912 -7.92679,-8.94788c-19.06234,-25.41221 -40.31534,-49.02389 -15.49846,-77.7181c10.98839,-10.65954 24.53781,-11.26764 38.46711,-6.29901c39.2213,22.788 10.8246,59.96254 -7.821,84.40073l-7.22086,8.56426l0.00001,0l-0.00001,0z" id="path4127" 
              fill="rgba(3, 169, 244, 0.82)" stroke="null"
               />
              <path stroke="null" id="svg_1" d="m52.204,55.34367l-9.65583,-10.0154c1.30081,-2.22486 2.06369,-4.8278 2.06504,-7.61907c-0.00136,-8.14191 -6.35772,-14.73639 -14.20325,-14.7392c-7.84688,0.00281 -14.20596,6.59729 -14.20596,14.73779c0,8.13629 6.35908,14.73077 14.20596,14.73077c2.69241,0 5.2019,-0.79128 7.34688,-2.14054l9.65854,10.01681l4.78862,-4.97116zm-30.5813,-17.63588c0.00813,-5.03441 3.9336,-9.10466 8.78591,-9.1159c4.84959,0.01124 8.77778,4.08149 8.78591,9.1159c-0.00949,5.03159 -3.93631,9.10185 -8.78591,9.11309c-4.8523,-0.01124 -8.77778,-4.08149 -8.78591,-9.11309z" fill-opacity="null" stroke-opacity="null" stroke-width="10" fill="#ffffff" />
          </g>
      </svg>
                `
        const iconUrl = `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`
        const blueIcon = L.icon({
          iconUrl,
          iconSize: [36, 52], // size of the icon
          iconAnchor: [18, 52], // point of the icon which will correspond to marker's location
        })
        const marker = L.marker([lat, lng], { icon: blueIcon })
        markerLayers.push(marker)
      }

      let newGeometryGroup = L.layerGroup(roofLayers.concat(markerLayers))
      props.reduxState.map.map.fitBounds(L.featureGroup(roofLayers).getBounds())

      geometryGroup.current = newGeometryGroup
      newGeometryGroup.addTo(props.reduxState.map.map)
    }
    const initSignalR = () => {
      let env = props.reduxState.env
      hycon.debug(`${hookName} initSignalR`, { signalR })
      let connectionURL = `${env.API_GATEWAY_BASE}/postgisapi/send?_userid=${wsClientId}`
      const connection = new signalR.HubConnectionBuilder()
      .withUrl(connectionURL)
      .configureLogging(signalR.LogLevel.Information)
      .build()

      // connection.start({ transport: 'longPolling' })
      connection.start({ transport: 'webSockets' })

      .then(() => {
        // alert(`connected user: ${wsClientId}`)
      })
      .catch(err => {
        console.error(err.toString())
        // alert(`could not connect to ${connectionURL}`)
      })

      connection.on('Send', async function (msg) {
        hycon.debug(`${hookName} got msg`, msg)

        try {
          // cancel the previous request
          if (requestSource.current) {
            requestSource.current.cancel('Operation canceled by the user.')
          }

          // filter out all messages you dont care about such as connection close, open etc...
          let parsedMessage = JSON.parse(msg)
          if (
            parsedMessage.MessageType !== 'CallbackFeedback'
          ) {
            hycon.debug(`${hookName} ignoring ws message`, parsedMessage)
            return null
          }

          if (
            parsedMessage.CallbackUri &&
            parsedMessage.CallbackUri.length > 0
          ) {
            try{
              iziToast.destroy();
            } catch (e) {
              hycon.warn("iziToast error", e);
            }
            iziToast.show({
              theme: 'dark',
              id: 'loading-toast',
              color: getIzitoastColor(props),
              zindex: 5,
              message: `${t('msg-potential-loading')}`,
              position: 'topCenter',
              drag: false,
            })
            let geometriesArray = parsedMessage.CallbackUri.map(uri => {
              return getGeometries(uri)
            })
            let geometryDataArray = await Promise.all(geometriesArray)
            let msgContent = JSON.parse(parsedMessage['Message'])
            hycon.debug(`${hookName} got msg (plz or address)`, { parsedMessage, geometryDataArray, msgContent })
            refreshGraphics(geometryDataArray, msgContent)
            let potential = parseInt(msgContent['Stromertrag']).toLocaleString('de-ch')
            try{
              iziToast.destroy();
            } catch (e) {
              hycon.warn("iziToast error", e);
            }
            iziToast.show({
              timeout: 3000,
              theme: 'dark',
              color: getIzitoastColor(props),
              zindex: 5,
              message: `${t('msg-potential-finish-loading')} ${potential} ${t('msg-potential-unit')}`,
              position: 'topCenter',
              drag: false,
            })
          } else {
            let msgContent = JSON.parse(parsedMessage['Message'])
            hycon.debug(`${hookName} got msg (total potential)`, { msg, msgContent })
            let potential = parseInt(msgContent['Stromertrag']).toLocaleString('de-ch')
            refreshGraphics(null, msgContent)
            try{
              iziToast.destroy();
            } catch (e) {
              hycon.warn("iziToast error", e);
            }
            iziToast.show({
              timeout: 3000,
              theme: 'dark',
              color: getIzitoastColor(props),
              zindex: 5,
              message: `${t('msg-total-potential-finish-loading')} ${potential} ${t('msg-potential-unit')}`,
              position: 'topCenter',
              drag: false,
            })
            const defaultPosition = [46.948484, 8.358491]
            props.reduxState.map.map.setView(defaultPosition, 7)
          }
        } catch (e) {
          hycon.error('parsing error', e)
        }
      })

    }
    if (props.leafletMap && wsClientId) {
      // freezeLeafletControls();
      initSignalR()
    }
    // eslint-disable-next-line
  }, [props.leafletMap, wsClientId])

  return getComponentByMandant()
}

export default RemoteController
