import { useState, useEffect } from 'react'
import HyperConsole from '../../hyper_console/hyper-console'
import './OpenController.css'
import { useTranslation } from 'react-i18next'
import qs from 'qs'
import iziToast from 'izitoast'
import 'izitoast/dist/css/iziToast.css'
import { useProperty } from '../../util/hooks/hooks'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
const LOCIZE_PANEL_NS = 'vgt_remote_control'
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

function OpenController (props) {
  const hookName = 'OpenController'
  const [settings, setSettings] = useState(null)
  const { /*t,*/ i18n } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false })

  useProperty(props, 'reduxState.map.map', () => {
    hycon.debug(`${hookName} useProperty - settings initial bounds`, { props, settings })
    if (settings && settings.initialBounds) {
      props.reduxState.map.map.fitBounds([
        [settings.initialBounds._southWest.lat, settings.initialBounds._southWest.lng],
        [settings.initialBounds._northEast.lat, settings.initialBounds._northEast.lng]
      ])
    }
  })
  /*  useEffect(() => {
      hycon.debug(`${hookName} useEffect - settings initial bounds`, { props, settings});
      props.reduxState.map.map.setBounds(settings.initialBounds)
    }, [props.reduxState.map.map])*/

  useEffect(() => {
    hycon.debug(`${hookName} useEffect`, { props, iziToast })
    const restoreMap = (myProps, mySettings) => {
      let layerName = null
      try {
        layerName = mySettings.selectedBaseLayer.name
        hycon.debug(`${hookName} restoreMap - restore link with no layer selected`, {myProps})
      } catch (e) {
        hycon.debug(`${hookName} restoreMap - error - restore link with no layer selected`, {e, myProps})
      }
      if (layerName === myProps.maps.Leaflet.layerNames.MAP_SWISSTOPO_GREY_URL) {
        myProps.onMapChange(myProps.maps.Leaflet)
        myProps.onLayerChange(myProps.maps.Leaflet.layers.grey)
      } else if (layerName === myProps.maps.Leaflet.layerNames.MAP_SWISSTOPO_COLOR_URL) {
        myProps.onMapChange(myProps.maps.Leaflet)
        myProps.onLayerChange(myProps.maps.Leaflet.layers.color)
      } else if (layerName === myProps.maps.Leaflet.layerNames.MAP_CADASTRAL_COLOR_URL) {
        myProps.onMapChange(myProps.maps.Leaflet)
        myProps.onLayerChange(myProps.maps.Leaflet.layers.cadastral)
      } else if (layerName === myProps.maps.Google.layerNames.MAP_GOOGLE_SATELLITE) {
        myProps.onMapChange(myProps.maps.Google)
        myProps.onLayerChange(myProps.maps.Google.layers.satellite)
      }
    }
    const setParams = () => {
      try {
        if (
          props.location && props.location.search
        ) {
          let search = props.location.search
          search = search.substr(1, search.length)
          const parsed = qs.parse(search)
          const settings = (() => {
            try {
              return JSON.parse(parsed.settings)
            } catch (e) {
              hycon.warn(`${hookName} could not parse remote map settings from query string`, { parsed, e })
              return null
            }
          })()

          const lang = parsed.lang
          hycon.debug(`${hookName} switchLanguage `, { targetLanguage: lang, props })
          i18n.changeLanguage(lang, () => {
            hycon.debug(`${hookName} switchLanguage - done`, { targetLanguage: lang, newLang: i18n.language })
          })

          hycon.debug(`${hookName} setParames`, { settings })
          setSettings(settings);
          restoreMap(props, settings);
        }
      } catch (e) {
        hycon.error(`${hookName} setParames`, { props, e })
      }
    }
    setParams()
    // eslint-disable-next-line
  }, [props.location])

  return null;
}

export default OpenController
