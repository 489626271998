import React, {
  createContext, useEffect, useMemo, useState,
} from 'react';
import HyperConsole from '../../components/hyper_console/hyper-console';

const { REACT_APP_GI_ENV } = process.env;
let hycon = null;
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
} else {
  // eslint-disable-next-line no-unused-vars
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
}

const MapContext = createContext(null);

export default function MapProvider({ children }) {
  const [maps, setMaps] = useState({

  });
  const api = useMemo(() => ({
    maps,
    setMaps: (newMaps) => {
      setMaps(newMaps);
    },
  }), [maps, setMaps]);

  useEffect(() => {

  }, []);

  return (
    <MapContext.Provider value={api}>{children}</MapContext.Provider>
  );
}

export const useMap = () => {
  const ctx = React.useContext(MapContext);
  if (!ctx) {
    throw new Error('useMap must be used inside a the MapContextProvider');
  }
  return ctx;
};
