import React, {Component} from 'react';
import HyperConsole from "../../../../../hyper_console/hyper-console";
import {Dropdown, Button} from 'semantic-ui-react'

import "./ConstructionZoneSettings.css";
import moment from "moment";
import { withTranslation } from 'react-i18next'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV;
let hycon = null;
if (REACT_APP_GI_ENV === "development") {
	hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
} else {
	hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
}

class ConstructionZoneSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tempDate: null
        };
        // let thisRef = this;
        hycon.debug(`${this.constructor.name} constructor`, {props: this.props});
    }

    componentDidMount() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} componentDidMount`, {props: this.props});

        if (!thisRef.getSettings().isSetByUser) {
            hycon.debug(`${this.constructor.name} componentDidMount setting user filter to "all"`, {props: this.props});
            let indexes = [];
            thisRef.getSettings().options.forEach((o, i) => {
                indexes.push(i);
            });

            // set the parent state
            thisRef.setSelectedIndexesSettings(indexes);
            thisRef.props.onSettingsChange();
        }
    }


    static getDerivedStateFromProps(props, state) {
        hycon.debug(`${"ConstructionZoneSettings"} getDerivedStateFromProps`, {props, state});
        if (!state) {
            hycon.debug(`${"ConstructionZoneSettings"} getDerivedStateFromProps - only props have been updated`, {
                props,
                state
            });
        }
        return state;
    }

    shouldComponentUpdate(nextProps, nextState) {
        // let thisRef = this;
        hycon.debug(`${this.constructor.name} shouldComponentUpdate`, {nextProps, props: this.props, nextState});
        return true;
    }

    getSettings() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} getSettings props`, {props: thisRef.props});
        let settings = thisRef.props.appsettings.profiles.default.panels.construction_zone.constructions.settings;
        hycon.debug(`${this.constructor.name} getSettings settings`, {settings});
        return settings;
    }

    getSelectedOptionsIndexes(data) {
        let thisRef = this;
        let options = data.options;
        let selectedIndexes = [];
        data.value.forEach((value) => {
            options.forEach((option, index) => {
                if (value === option.value) {
                    selectedIndexes.push(index);
                }
            });
        });
        hycon.debug(`${thisRef.constructor.name} getSelectedOptionsIndexes`, {data, selectedIndexes});
        return selectedIndexes;
    }


    onCategoriesChange(event, data) {
        let thisRef = this;
        hycon.debug(`${thisRef.constructor.name} onCategoriesChange`, {event, data});

        let selectedIndexes = thisRef.getSelectedOptionsIndexes(data);
        hycon.debug(`${thisRef.constructor.name} onCategoriesChange`, {selectedIndexes});

        thisRef.setSelectedIndexesSettings(selectedIndexes);
    }

    setSelectedIndexesSettings(selectedIndexes) {
        let thisRef = this;
        hycon.debug(`${thisRef.constructor.name} setSelectedIndexesSettings`, {});

        let appsettings = Object.assign({}, thisRef.props.appsettings);
        //inject them into the construction object
        appsettings.profiles.default.panels.construction_zone.constructions.settings.selectedOptionIndexes = selectedIndexes;

        thisRef.props.setParentState("appsettings", appsettings).then(() => {
            hycon.debug(`${thisRef.constructor.name} setSelectedIndexesSettings done`, {appsettings});
        });
    }

    getCurrentValue(options, selectedOptionIndexes) {
        let thisRef = this;
        let value = [];
        selectedOptionIndexes.forEach((element) => {
            value.push(options[element].value);
        });
        hycon.debug(`${thisRef.constructor.name} getCurrentValue`, {options, selectedOptionIndexes, value});
        return value;
    }


    render() {
        let thisRef = this;
        return (
            <div className="ConstructionZoneSettings">
                <h4>{thisRef.props.i18n.t("panel_construction_zone:label-datum-filter-1")}</h4>
                <div className={`datum-container`}>
                    <div className={"ui form datum-container"}>
                        <div className={`datum-wrapper`}>
                            <input
                                type={`date`}
                                id={"bauvorhaben-date"}
                                onChange={(event) => {
                                    try{
                                        event.persist();
                                        let val = event.target.value;
                                        thisRef.setState(p => {
                                            return {...p, tempDate: val}
                                        })
                                    }catch (e) {
                                        console.error(`${thisRef.constructor.name} date filter error onchange`, e);
                                    }
                                }}
                                max={moment().format("YYYY-MM-DD")}
                                min={moment().subtract(1, "month").format("YYYY-MM-DD")}
                                value={(() =>{
                                    let minDate = moment().subtract(1, "month");
                                    let savedDate = moment(thisRef.getSettings().fromDate, "YYYY-MM-DD");
                                    if(savedDate.isBefore(minDate)){
                                        console.warn("forced date", {minDate, savedDate, used: minDate});
                                        return thisRef.state.tempDate || minDate.format("YYYY-MM-DD");
                                    } else {
                                        return thisRef.state.tempDate || thisRef.getSettings().fromDate;
                                    }
                                })()}
                                /*value={"2006-06-06"}*/
                            />
                            <Button
                                primary
                                disabled={(() => {
                                    try {
                                        let val = thisRef.state.tempDate;
                                        let disabled = false;
                                        if (!val) {
                                            disabled = true;
                                            return disabled;
                                        }
                                        let maxDate = moment().format("YYYY-MM-DD");
                                        let minDate = moment()
                                            .subtract(1, "month")
                                            .subtract(1, 'day')
                                            .format("YYYY-MM-DD");
                                        if (
                                            !(
                                                moment(val).isAfter(minDate) &&
                                                moment(val).isBefore(maxDate)
                                            )
                                        ) {
                                            hycon.warn("date not in range", {maxDate, minDate, val});
                                            disabled = true;
                                            return disabled;
                                        }
                                        disabled = false;
                                        return disabled;
                                    } catch (e) {
                                        console.error(`${thisRef.constructor.name} date filter error enable`, e);
                                        let disabled = false;
                                        return disabled;
                                    }
                                })()}
                                onClick={() => {
                                    try {
                                        let val = document.getElementById("bauvorhaben-date").value;

                                        let maxDate = moment().format("YYYY-MM-DD");
                                        let minDate = moment()
                                            .subtract(1, "month")
                                            .subtract(1, 'day')
                                            .format("YYYY-MM-DD");
                                        if (
                                            !(
                                                moment(val).isAfter(minDate) &&
                                                moment(val).isBefore(maxDate)
                                            )
                                        ) {
                                            hycon.warn("date not in range", {maxDate, minDate, val});
                                            return;
                                        }

                                        hycon.debug(`${this.constructor.name} onClick `, {val});
                                        let appsettings = Object.assign({}, thisRef.props.appsettings);
                                        //inject them into the construction object
                                        appsettings.profiles.default.panels.construction_zone.constructions.settings.fromDate = val;


                                        thisRef.props.setStateProperty("appsettings", appsettings).then(() => {
                                            hycon.debug(`${thisRef.constructor.name} onClick - date - setStateProperty`, {appsettings});
                                        }).then(() => {
                                            thisRef.props.onSettingsChange();
                                        }).catch((e) => {
                                            console.error(`${thisRef.constructor.name} date filter error button - setparentstate`, e);
                                        });
                                    }catch (e) {
                                        console.error(`${thisRef.constructor.name} date filter error button`, e);
                                    }
                                }}>
                                {thisRef.props.i18n.t("panel_construction_zone:button-use")}
                            </Button>
                        </div>
                    </div>
                </div>
                <h4>{thisRef.props.i18n.t("panel_construction_zone:label-categories")}</h4>
                <div className={`settings-container`}>
                    <Dropdown
                        onChange={(event, data) => {
                            thisRef.onCategoriesChange(event, data);
                            thisRef.props.onSettingsChange();
                        }}
                        placeholder={thisRef.props.i18n.t("panel_construction_zone:label-categories")}
                        multiple
                        selection
                        upward={false}
                        options={thisRef.getSettings().options}
                        value={thisRef.getCurrentValue(thisRef.getSettings().options, thisRef.getSettings().selectedOptionIndexes)}
                    />
                    <div className={`button-container`}>
                        <Button primary onClick={() => {
                            let indexes = [];
                            thisRef.getSettings().options.forEach((o, i) => {
                                indexes.push(i);
                            });
                            thisRef.setSelectedIndexesSettings(indexes);
                            thisRef.props.onSettingsChange();
                        }}>{thisRef.props.i18n.t("panel_construction_zone:button-select-all")}</Button>
                        <Button primary onClick={() => {
                            thisRef.setSelectedIndexesSettings([]);
                            thisRef.props.onSettingsChange();
                        }}>{thisRef.props.i18n.t("panel_construction_zone:button-delete-all")}</Button>
                    </div>
                </div>
            </div>
        );
    }

    componentDidUpdate() {
        // let thisRef = this;
        hycon.debug(`${this.constructor.name} componentDidUpdate`, {props: this.props});
    }

    componentWillUnmount() {
        // let thisRef = this;
        hycon.debug(`${this.constructor.name} componentWillUnmount`, {props: this.props});
    }
}

export default withTranslation([
  "panel_construction_zone"
])(ConstructionZoneSettings);

