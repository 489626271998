import React, { Component } from 'react';
import HyperConsole from "../../hyper_console/hyper-console";

import "./WebPush.css";
import HyperMenu from "../../hyper_menu/HyperMenu";
import Navbar from "../../sep_map/component_navbar/Navbar";
import axios from "axios";
import env from "../../../env/env";
import { withTranslation } from 'react-i18next'

let hycon = new HyperConsole({isEnabled: false, name:__filename}).myConsole;

class WebPush extends Component {
    constructor(props) {
        super(props);
        hycon.debug(`${this.constructor.name} constructor`, {props: this.props});
    }

    componentWillMount() {

        hycon.debug(`${this.constructor.name} componentWillMount`, {props: this.props});
    }

    componentDidMount() {

        hycon.debug(`${this.constructor.name} componentDidMount`, {props: this.props});
    }

    componentWillReceiveProps() {

        hycon.debug(`${this.constructor.name} componentWillReceiveProps`, {props: this.props});
    }

    shouldComponentUpdate(nextProps, nextState) {

        hycon.debug(`${this.constructor.name} shouldComponentUpdate`, {nextProps, props: this.props, nextState});
        return true;
    }

    componentWillUpdate() {

        hycon.debug(`${this.constructor.name} componentWillUpdate`, {props: this.props});
    }

    render() {
        let thisRef = this;
        return (
            <div className={"WebPush"}>
                <HyperMenu
                    {...thisRef.props}
                    ref={(c) => thisRef.menu = c}
                    content={(
                        <div className={`menu-content-wrapper`}>
                            <Navbar
                                {...thisRef.props}
                                onMenuToggle={()=>{
                                    thisRef.menu.setStateProperty("isOpen", !thisRef.menu.getState().isOpen);
                                }}
                                isSearchDisplayed={false}
                            />
                            <div className={"webpush-admin-body"}>
                               <h1>webpush admin</h1>
                                <div>
                                    <label>title</label>
                                    <input id={"push-title"}></input>
                                </div>
                                <div>
                                    <label>body</label>
                                    <input id={"push-body"}></input>
                                </div>
                                <div>
                                    <label>password</label>
                                    <input id={"push-password"}></input>
                                </div>
                                <button
                                    onClick={() => {

                                        axios(
                                            {
                                                method: 'post',
                                                url: `${env.NOTIFICATION_SERVER_BASE_URL}/push`,
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Accept': "application/json"
                                                },
                                                data: JSON.stringify({
                                                    "pushSubscription": "all",
                                                    "notificationMessage": "really",
                                                    "pushData": {
                                                        "title": `${document.getElementById("push-title").value}`,
                                                        "body": `${document.getElementById("push-body").value}`,
                                                        "key": `${document.getElementById("push-password").value}`
                                                    }
                                                })
                                            }
                                        )
                                            .then((response) => {
                                                return response;
                                            })
                                            .catch((error) => {
                                                hycon.error(`${thisRef.constructor.name} webpush error`, error);
                                            });
                                    }}
                                >send to all</button>
                            </div>
                        </div>
                    )}
                />
            </div>
        );
    }

    componentDidUpdate() {

        hycon.debug(`${this.constructor.name} componentDidUpdate`, {props: this.props});
    }

    componentWillUnmount() {

        hycon.debug(`${this.constructor.name} componentWillUnmount`, {props: this.props});
    }
}

export default withTranslation()(WebPush);
