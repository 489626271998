import React, { useState } from 'react'
import HyperConsole from '../../../hyper_console/hyper-console'

import './PanelContainer.css'

import { useTranslation } from 'react-i18next'
import BuildingInfo from '../cards/building_info/BuildingInfo'
import env from '../../../../env/env'
import EnergyProject from '../cards/energy_project/EnergyProject'
import ConstructionZone from '../cards/construction_zone/ConstructionZone'
import Elcom from '../cards/elcom/Elcom'
import SolarElectricityCard from '../cards/solar_electricity/SolarElectricityCard'
import Usage from '../cards/usage/Usage'
import SBBCard from '../cards/geoimpact_demo/sbb/SBBCard'
import DynamicPanels from '../cards/dynamic_panels/DynamicPanels'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

// CLIENT UTILITY
export const getMandantInfo = (thisRef) => {
  let clientInfo = null
  let nullInfo = {
    client: null, publicName: null, logoUrl: null, role: null
  }
  if (!thisRef.props.reduxState.session.user) {
    return nullInfo
  }
  if (
    !thisRef.props.reduxState.session.clientInfo
  ) {
    return nullInfo
  } else {
    clientInfo = thisRef.props.reduxState.session.clientInfo
  }
  // let client = clientInfo[0].data.name
  let client = clientInfo[1].data.filter((e) => {
    return e.key === 'Mandant'
  })[0].value;
  let publicName = clientInfo[0].data.publicName
  let logoUrl = clientInfo[0].data.logoUrl
  let role = clientInfo[1].data.filter((e) => {
    return e.key === 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
  })[0].value
  return {
    client, publicName, logoUrl, role
  }
}

function PanelContainer(props){
  const { t } = useTranslation([
    'panel_building_info',
    'panel_coming_soon',
    'panel_construction_zone',
    'panel_electricity',
    'panel_demo',
    'panel_preview',
    'panel_solar_electricity',
    'panel_usage',
    'panel_zev',
    'panel_energy_project',
    'dashboard',
  ], { useSuspense: true })
  // eslint-disable-next-line no-unused-vars
  const [isPortalVisible, setIsPortalVisible] = useState(false);
  const [isTilesVisible, setIsTilesVisible] = useState(true);
  const fnName = "PanelContainer";

  const setParentState = (propertyName, propertyValue) => {
    if(propertyName === "isPortalVisible"){
      setIsPortalVisible(propertyValue)
    }
    if(propertyName === "isTilesVisible"){
      setIsTilesVisible(propertyValue)
    }
    return Promise.resolve();
  }
  const getCards = (baseId) => {
    let cards = []
    let id = baseId
    let key = baseId
    const getIconStyle = (name) => {
      let base = env.AZURE_STORAGE_BASE
      let itemStyle = {
        backgroundImage: `url(${base}/halley/components/dashboard/tile-icons/${name})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '65px',
        width: '65px'
      }
      hycon.debug(`${fnName} getStyle`, { itemStyle })
      return itemStyle
    }
    const currentUser = getMandantInfo({props})
    hycon.debug(`${fnName} panels - getMandantInfo`, {currentUser});

    if (
      currentUser.client === 'geoimpact' ||
      currentUser.client === 'innovenergy' ||
      currentUser.client === 'goldstroem' ||
      currentUser.client === 'edj' ||
      currentUser.client === 'swisscom' ||
      currentUser.client === 'clevergie' ||
      currentUser.client === 'ienergy' ||
      currentUser.client === 'newsol' ||
      currentUser.client === 'elektroottinger' ||
      currentUser.client === 'elentec' ||
      currentUser.client === 'elektrowaeger' ||
      currentUser.client === 'elektroboehlen' ||
      currentUser.client === 'geoimpact_public' ||
      currentUser.client === 'klik' ||
      currentUser.client === 'sep_public'
    ) {
      cards.push((
        <EnergyProject
          id={id++}
          key={++key}
          setParentState={setParentState}
          {...props}
        />
      ))
    }

    /*FOR ALL USERS EXCEPT GUEST USERS*/
    if(
      currentUser.role !== "Guest"
    ){
      cards.push((
        <BuildingInfo
          key={++key}
          title={t('panel_building_info:label-panel-name')}
          logic={{}}
          {...props}
          style={{}}
          setParentState={setParentState}
          content={{
            overview: {
              title: t('panel_building_info:label-panel-overview-title'),
              iconComponent: (
                <div>
                  <div className={`icon`} style={getIconStyle('Gebaeudeinfo.png')}/>
                </div>
              )
            },
            detail: {
              infoComponent: (
                <div>
                  <h3>TODO</h3>
                </div>
              )
            }
          }}
        />
      ))
      /*FOR ALL USERS*/
      cards.push((
        <ConstructionZone
          key={++key}
          id={id++}
          title={t('panel_construction_zone:label-panel-name')}
          {...props}
          setParentState={setParentState}
        />
      ))
      /*FOR ALL USERS*/
      cards.push((
        <Elcom
          key={++key}
          id={id++}
          title={t('panel_electricity:label-panel-name')}
          {...props}
          {...props}
          setParentState={setParentState}
        />
      ))
      /*FOR ALL USERS*/
      cards.push((
        <SolarElectricityCard
          key={++key}
          id={id++}
          title={t('panel_solar_electricity:label-panel-name')}
          logic={{}}
          {...props}
          style={{}}
          setParentState={setParentState}
        />
      ))
      /*FOR ALL USERS*/
      cards.push((
        <Usage
          key={++key}
          id={id++}
          title={t('panel_usage:label-panel-name')}
          {...props}
          setParentState={setParentState}
          content={{
            overview: {
              title: t('panel_usage:label-panel-overview-title'),
              iconComponent: (
                <div>
                  <div className={`icon`} style={getIconStyle('Gebaeudenutzung.png')}/>
                </div>
              )
            }
          }}
        />
      ))
    }

    if (
      currentUser.client === 'geoimpact' &&
      currentUser.role === 'Demo'
    ) {
      /*DEMO CARDS*/
      cards.push((
        <SBBCard
          key={++key}
          id={id++}
          title={'SBB-ZEV'}
          {...props}
          style={{}}
          setParentState={setParentState}
          content={{
            overview: {
              title: 'SBB-ZEV',
              iconComponent: (
                <div>
                  <div className={`icon`} style={getIconStyle('evg-planung.png')}/>
                </div>
              )
            }
          }}
        />
      ))
    }
    hycon.debug(`${fnName} panels - getMandantInfo - cards`, {currentUser, cards});
    return <React.Fragment>{cards}</React.Fragment>
  }
  const render = () => {
    const cards = getCards(0);
    if (
      props &&
      props.SEPContext &&
      props.SEPContext.user &&
      props.SEPContext.user.publicJWT
    ) {
      return null
    } else {
      return (
        <div className="PanelContainer">
          <div id={'dashboard-portal'} className={`${isTilesVisible ? 'invisible' : 'visible'}`}/>
          <div className={`PanelContainer-flex ${isTilesVisible ? 'visible' : 'invisible'}`}>
            <DynamicPanels
              {...props}
              setParentState={setParentState}
              isPortalVisible={isPortalVisible}
              isTilesVisible={isTilesVisible}
            />
            {cards}
          </div>
        </div>
      )
    }
  }
  return render()
}

export default PanelContainer

