import React, {Component, useContext} from 'react'
import HyperConsole from '../../hyper_console/hyper-console'
import './EditProfile.css'
import { Button, Form } from 'semantic-ui-react'
import {Alert, Switch} from '@mui/material';
import axios from 'axios/index'
import { TokenUtil } from '../util/token-util'
import { UserUtil } from '../util/user-util'
import {useTranslation, withTranslation} from 'react-i18next'
import SEPContext from '../../../contexts/sep-context/SEPContext';

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

function UserInfoDisplay(){
  const context = useContext(SEPContext).SEPContext;
  const {t} = useTranslation("profile", {useSuspense: true})
  return (
      <div className={"UserInfoDisplay"}>
        <div className={'heading'}>
          {(() => {
            if (!!context?.user?.mandant?.publicName) {
              return (
                  <div className={'logo-container'}>
                    <img className={'logo'} src={`${context?.user?.mandant?.logoUrl}`} alt={'client logo'}/>
                  </div>
              )
            }
          })()}
          <h1>{t('profile:label-edit-profile')}</h1>
        </div>
        <div className={'info-email'}>
          <h4>{t('profile:label-your-email')}</h4>
          <p>
            {context.user.jwtParsed["sub"]}
          </p>
        </div>
        <div className={'info-role'}>
          {(() => {
            return (
                <div>
                  <h4>{t('profile:label-your-permissions')}</h4>
                  <div
                      className={'client-role'}
                  >
                    {t('profile:label-client')} {context?.user?.mandant?.publicName}: &nbsp; {context.user.jwtParsed["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]}
                  </div>
                </div>
            )
          })()}
        </div>
      </div>
  )
}

class EditProfile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      isDisabled: false,
      touched: {
        oldPassword: false,
        password: false,
        passwordRepeat: false
      },
      passwordValidationRequests: [],
      passwordValidation: {
        errors: []
      },
      notifications: {
        monthlyStatistics: false,
        leadAssignment: false,
        id: null
      },
      messages: [],
      errors: {
        oldPassword: false,
        password: false,
        passwordRepeat: false
      }
    }

    hycon.debug(`${this.constructor.name} constructor`, { props: this.props })
  }

  componentDidMount () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} componentDidMount`, { props: this.props });

    thisRef.loadRecaptchaScript();

    this.getUserNotificationSettings().then((settings) => {
        thisRef.setStateProperty(
            'notifications',
            Object.assign({}, this.state.notifications, settings.notifications)
        );
    });
  }

  shouldComponentUpdate (nextProps, nextState) {

    hycon.debug(`${this.constructor.name} shouldComponentUpdate`, { nextProps, props: this.props, nextState })
    return true
  }

  cancelPendingRequests () {
    let thisRef = this
    // hycon.debug(`${this.constructor.name} cancelPendingRequests`, {thisRef, props: this.props});

    thisRef.state.passwordValidationRequests.forEach((source) => {
      // hycon.debug(`${this.constructor.name} cancelPendingRequests - canceling request nr ${i}`, {source, i});
      try {
        source.cancel()
      } catch (e) {
        /* hycon.info(`${this.constructor.name} cancelPendingRequests - error - canceling request nr ${i}`, {
          source,
          i
        });
        */
      }
    })
  }

  checkPassword () {
    let thisRef = this
    let env = thisRef.props.reduxState.env
    let password = thisRef.state.password
    if (!this.state.touched.password || password === '') {
      return thisRef.setStateProperty('passwordValidation', Object.assign({}, thisRef.state.passwordValidation, { errors: [] })).then(() => {
        return Promise.resolve()
      })
    }
    return new Promise((res, rej) => {
      const endpoint = `${env.API_GATEWAY_BASE}/api/password-isvalid?part=${password}`
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      let cancelTokenSavePromise = new Promise(() => {
        return thisRef.setStateProperty('passwordValidationRequests', thisRef.state.passwordValidationRequests.concat([source]))
      })

      let axiosPromise = axios(
        {
          method: 'get',
          url: endpoint,
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }
          /*
          ,
          data: JSON.stringify({})
          */
        }
      )
      .then((response) => {
        // hycon.info(`${this.constructor.name} checkPassword`, {state: thisRef.state});

        if (response.data.success) {
          thisRef.setStateProperty('passwordValidation', Object.assign({}, thisRef.state.passwordValidation, { errors: [] }))
        } else {
          thisRef.setStateProperty('passwordValidation', Object.assign({}, thisRef.state.passwordValidation, { errors: [response.data.message] }))
        }

        return res(response)
      })
      .catch((error) => {
        // hycon.warn(`${this.constructor.name} checkPassword - error`, {state: thisRef.state});
        return rej(error)
      })
      return cancelTokenSavePromise.then(() => {
        return axiosPromise
      })
    })
  }

  isFormComplete () {
    let thisRef = this
    // hycon.debug(`${thisRef.constructor.name} isFormComplete`, {});
    let isComplete = (
      (!thisRef.state.errors.oldPassword && thisRef.state.touched.oldPassword) &&
      (!thisRef.state.errors.password && thisRef.state.touched.password) &&
      (!thisRef.state.errors.passwordRepeat && thisRef.state.touched.passwordRepeat) &&
      (thisRef.state.passwordValidation.errors.length === 0)
    )
    return isComplete
  }

  isAllTouched () {
    let thisRef = this
    // hycon.debug(`${thisRef.constructor.name} isAllTouched`, {});
    let isAllTouched = (
      thisRef.state.oldPassword &&
      thisRef.state.password &&
      thisRef.state.passwordRepeat
    )

    return isAllTouched
  }

  resetFields () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} resetFields`, { thisRef })
    return Promise.all([
      thisRef.setStateProperty('oldPassword', ''),
      thisRef.setStateProperty('password', ''),
      thisRef.setStateProperty('passwordRepeat', '')
    ])
  }

  edit () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} edit`, { thisRef })

    let url = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/api/changePassword`
    let jwt = thisRef.props.reduxState.user.jwt
    return thisRef.setStateProperty('isLoading', true).then(() => {
      return axios(
        {
          method: 'post',
          url: url,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`
          },
          data: JSON.stringify({
            oldPassword: thisRef.state.oldPassword,
            newPassword: thisRef.state.password
          })
        }
      )
      .then((response) => {
        return TokenUtil.updateTokenInReduxAndCookie(response, thisRef).then(() => {
          return response
        })
      })
      .then((response) => {
        hycon.info(`${thisRef.constructor.name} edit - ok`, { response })
        if (response.data.success === true) {
          thisRef.setStateProperty('messages', thisRef.state.messages.concat([
            `Ihr Passwort wurde geändert.<br/>` +
            `Sie werden gleich zur Anmeldung weitergeleitet. Bitte melden Sie sich neu an.`
          ]), { reset: true, resetValue: [], delta: 4000 }).then(() => {
            // eventually do other cleanup
          })
          thisRef.resetFields()
          thisRef.setStateProperty('isLoading', false)
          document.getElementById('messages').scrollIntoView()
          UserUtil.logout(thisRef).then(() => {
            return UserUtil.deleteUserInReduxAndCookies(thisRef)
          }).then(() => {
            return TokenUtil.updateTokenInRedux(null, thisRef)
          }).then(() => {
            thisRef.props.history.push('/user/signin/')
          }).catch((error) => {
            // user had trouble to logout (invalid token?). so clear the cookies and redirect him
            return UserUtil.deleteUserInReduxAndCookies(thisRef).then(() => {
              return TokenUtil.updateTokenInRedux(null, thisRef)
            }).then(() => {
              setTimeout(() => {
                thisRef.props.history.push('/user/signin/')
              }, 4000)
            })
          })
        } else if (response.data.success === false) {
          thisRef.setStateProperty('messages', thisRef.state.messages.concat([
            `Ihr Passwort wurde nicht geändert.<br/>` +
            `Das alte Passwort wurde nicht korrekt eingegeben. Bitte überprüfen Sie Ihre Eingabe.`
          ]), { reset: true, resetValue: [], delta: 4000 })
          thisRef.setStateProperty('isLoading', false)
          document.getElementById('messages').scrollIntoView()
        } else {
          throw new Error('Unhandled response status for profile edit')
        }
        return response
      })
      .catch((e) => {
        hycon.error(`${thisRef.constructor.name} edit - error`, { e: e.response })
        UserUtil.authenticationRedirect(e.response, thisRef)
        thisRef.setStateProperty('messages', thisRef.state.messages.concat([
          `Ein Fehler ist aufgetreten beim Versuch ihr Passwort zu ändern.<br/>` +
          `${e.message}`
        ]), {
          reset: true,
          resetValue: [],
          delta: 4000
        })
        document.getElementById('messages').scrollIntoView()
        // thisRef.resetFields();
        thisRef.setStateProperty('isLoading', false)
      })
    })
  }

  sendEditRequest () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} sendEditRequest`, { state: thisRef.state })
    thisRef.edit()
    .then(() => {
      hycon.debug(`${this.constructor.name} sendEditRequest - ok`)
    })
    .catch((e) => {
      hycon.debug(`${this.constructor.name} sendEditRequest - error`, e)
    })
  }

  getFormValidElements () {
    let thisRef = this
    let validElements = {
      oldPassword: !thisRef.state.errors.oldPassword,
      password: !thisRef.state.errors.password,
      passwordRepeat: !thisRef.state.errors.passwordRepeat
    }
    validElements.isAllValid = (
      validElements.oldPassword &&
      validElements.password &&
      validElements.passwordRepeat
    )
    hycon.debug(`${thisRef.constructor.name} getFormValidElements`, { validElements })
    return validElements
  }

  validate (validation = {}) {
    let thisRef = this
    let promises = []

    if (validation.oldPassword) {
      if (validation.oldPassword.length > 0) {
        promises.push(thisRef.setStateProperty('errors', Object.assign({}, thisRef.state.errors, { oldPassword: true })))
      } else {
        promises.push(thisRef.setStateProperty('errors', Object.assign({}, thisRef.state.errors, { oldPassword: false })))
      }
    }

    if (validation.password) {
      if (thisRef.state.password !== thisRef.state.passwordRepeat && validation.password) {
        promises.push(thisRef.setStateProperty('errors', Object.assign({}, thisRef.state.errors, { passwordRepeat: true })))
      } else {
        promises.push(thisRef.setStateProperty('errors', Object.assign({}, thisRef.state.errors, { passwordRepeat: false })))
      }
    }

    thisRef.checkPassword()
    .then(() => {
      // hycon.debug(`${this.constructor.name} validate - checkPassword - ok`, {newState: thisRef.state});
      promises.push(thisRef.setStateProperty('errors', Object.assign({}, thisRef.state.errors, { password: false })))
    })
    .catch(() => {
      // hycon.debug(`${this.constructor.name} validate - checkPassword - err`, {newState: thisRef.state});
      promises.push(thisRef.setStateProperty('errors', Object.assign({}, thisRef.state.errors, { password: true })))
    })

    return Promise.all(promises)
  }

  setStateProperty (propertyName, propertyValue, opts) {
    let thisRef = this

    if (opts) {
      opts.onSetState = opts.onSetState || (() => {
      })
      let isValid = (
        typeof opts.reset !== 'undefined' &&
        typeof opts.resetValue !== 'undefined' &&
        typeof opts.delta !== 'undefined'
      )
      if (!isValid) {
        throw new Error('options object is not defined as expected. expeected something like {reset: <bool>, resetValue: <object>, delta: <number>}')
      }
    }

    let resetPromise = Promise.resolve()
    if (
      opts &&
      opts.reset === true
    ) {
      resetPromise = new Promise((res, rej) => {
        setTimeout(() => {
          try {
            thisRef.setStateProperty('messages', opts.resetValue)
            res()
          } catch (e) {
            hycon.error(`${this.constructor.name} setStateProperty - resetPromise - error`, { newState: thisRef.state })
            rej(e)
          }
        }, opts.delta)
      })
    }

    let setPromise = new Promise((res) => {
      hycon.debug(`${this.constructor.name} setStateProperty - setPromise - new promise`, {
        newState: thisRef.state,
        propertyName,
        propertyValue,
        opts
      })
      thisRef.setState((p) => {
        const obj = {}
        obj[propertyName] = propertyValue
        return Object.assign({}, p, obj)
      }, () => {
        if (opts && opts.onSetState) {
          opts.onSetState()
        }
        hycon.debug(`${this.constructor.name} setStateProperty - setPromise - ok`, { newState: thisRef.state })
        res()
      })
    })

    return Promise.all([resetPromise, setPromise])
  }

  recaptchaExecute () {
    let thisRef = this
    let key = thisRef.props.reduxState.env.GOOGLE_RECAPTCHA_SITE_KEY
    return new Promise((res) => {
      let grecaptcha = window.grecaptcha
      grecaptcha.ready(() => {
        grecaptcha.execute(key, { action: 'homepage' }).then((token) => {
          hycon.info(`${thisRef.constructor.name} recaptchaExecute - google says you are not a bot`, { token })
          return thisRef.setStateProperty('recaptcha-token', token).then(() => {
            res()
          })
        })
      })
    })
  }

  deleteAccount () {
    let thisRef = this
    hycon.info(`${thisRef.constructor.name} deleteAccount`)
    let endpoint = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/api/deleteUser`
    let jwt = thisRef.props.reduxState.user.jwt
    return axios({
      method: 'get',
      url: endpoint,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${jwt}`,
      }
    })
    .then(function (response) {
      hycon.debug(response)
      return response
    })
    .catch((e) => {
      hycon.error(e)
      UserUtil.authenticationRedirect(e.response, thisRef)
      throw e
    })
  }

  loadRecaptchaScript () {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} loadRecaptchaScript`, {})

    let key = thisRef.props.reduxState.env.GOOGLE_RECAPTCHA_SITE_KEY

    let scriptId = "google-recaptcha-script";
    let script = document.createElement('script')
    script.id=scriptId;
    script.type = 'text/javascript'
    script.src = `https://www.google.com/recaptcha/api.js?render=${key}`

    let recaptchaScript = document.getElementById(scriptId);
    if(!recaptchaScript){
      document.getElementsByTagName('head')[0].appendChild(script)
    }
  }

  setUserDefaultNotificationSettings() {
    const appSettingsEndpoint = `${this.props.reduxState.env.API_GATEWAY_BASE}/api/appsettings`;
    const jwt = this.props.reduxState.user.jwt;
    axios({
      method: 'post',
      url: appSettingsEndpoint,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${jwt}`
      },
      data: {
        settingsType: 'notifications',
        settings: JSON.stringify({
          monthlyStatistics: true,
          leadAssignment: true,
        })
      }
    }).then((response) => {
      this.setStateProperty('notifications', Object.assign({}, this.state.notifications, { id: response.data.id }));
    });
  }

  async getUserNotificationSettings () {
    const appSettingsEndpoint = `${this.props.reduxState.env.API_GATEWAY_BASE}/api/appsettings`;
    const jwt = this.props.reduxState.user.jwt;
    const response = await axios({
      url: appSettingsEndpoint,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwt}`,
      }
    });

    if (response.status !== 200) {
      return {
        notifications: {
          monthlyStatistics: true,
          leadAssignment: true,
          id: null
        }
      }
    }

    const notificationSettings = response.data.filter((settings) => {
        return settings.settingsType === 'notifications';
    })[0];

    if (!notificationSettings) {
      this.setUserDefaultNotificationSettings();
      return {
        notifications: {
          monthlyStatistics: true,
          leadAssignment: true,
          id: null
        }
      }
    }

    return {
        notifications: {
          ...JSON.parse(notificationSettings.settings),
          id: notificationSettings.id
        }
    }
  }

  updateRemoteNotificationSettings (settings, toggle) {
    const appSettingsEndpoint = `${this.props.reduxState.env.API_GATEWAY_BASE}/api/appsettings/${this.state.notifications.id}`;
    const jwt = this.props.reduxState.user.jwt;
    axios({
      method: 'put',
      url: appSettingsEndpoint,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${jwt}`
        },
        data: {
          settingsType: 'notifications',
          settings: JSON.stringify({
            ...this.state.notifications,
            [settings]: toggle
          })
        }
    }).catch(error => {
      // We are not able to save the settings in the backend.
      hycon.debug(`unable to save the notification settings in the backend`, error);
    });
  }

  render () {
    let thisRef = this
    return (
      <div className="EditProfile" style={{display: "flex", flexDirection: "column"}}>
        <UserInfoDisplay></UserInfoDisplay>
        <div className={`edit-form`}>
          <div className="email-notification-settings">
            <h4>{thisRef.props.i18n.t('profile:label-email-notification-settings')}</h4>
            <div className="email-notification-settings-container">
              <div className="notification-switch monthly-email-notification">
                <p>{thisRef.props.i18n.t('profile:label-monthly-email-notification')}</p>
                <Switch
                  checked={thisRef.state.notifications.monthlyStatistics}
                  onChange={(e) => {
                    thisRef.setStateProperty('notifications', Object.assign({}, this.state.notifications, { monthlyStatistics: e.target.checked }));
                    thisRef.updateRemoteNotificationSettings('monthlyStatistics', e.target.checked)
                  }}
                ></Switch>
              </div>
              <div className="notification-switch lead-assignment-notification">
                <p>{thisRef.props.i18n.t('profile:label-lead-assignment-notification')}</p>
                <Switch
                    checked={thisRef.state.notifications.leadAssignment}
                    onChange={(e) => {
                      thisRef.setStateProperty('notifications', Object.assign({}, this.state.notifications, { leadAssignment: e.target.checked }));
                      thisRef.updateRemoteNotificationSettings('leadAssignment', e.target.checked)
                    }}
                ></Switch>
              </div>
              <div className="notification-switch event-notification">
                <Alert severity="info" className="alert-root">
                  {/*profile:label-info-event-notification contains a link to the favorites page, that's why we use html.*/}
                  <span dangerouslySetInnerHTML={{
                    __html: thisRef.props.i18n.t('profile:label-info-event-notification')
                  }}></span>
                </Alert>
              </div>
            </div>
          </div>
          <Form loading={thisRef.state.isLoading}>
            <h4>{thisRef.props.i18n.t('profile:label-change-password')}</h4>
            <Form.Field>
              <input
                placeholder={thisRef.props.i18n.t('profile:label-old-password')}
                type={'password'}
                autoComplete={`new-password`}
                onChange={(event) => {
                  let val = event.target.value
                  hycon.debug(`${thisRef.constructor.name} onChange`, { event, value: val })
                  thisRef.setStateProperty('oldPassword', val)
                  .then(() => {
                    return thisRef.setStateProperty('touched', Object.assign({}, thisRef.state.touched, { oldPassword: true }))
                  })
                  .then(() => {
                    thisRef.validate({ oldPassword: true })
                  })
                }}
                onBlur={() => {
                  hycon.debug(`${thisRef.constructor.name} onBlur`, {})
                  thisRef.validate({ oldPassword: true })
                }}
                value={thisRef.state.oldPassword}
              />
            </Form.Field>
            <Form.Field>
              <input
                placeholder={thisRef.props.i18n.t('profile:label-new-password')}
                type={'password'}
                autoComplete={`new-password`}
                onChange={(event) => {
                  let val = event.target.value
                  hycon.debug(`${thisRef.constructor.name} onChange`, { event, value: val })
                  thisRef.setStateProperty('password', val)
                  .then(() => {
                    return thisRef.setStateProperty('touched', Object.assign({}, thisRef.state.touched, { password: true }))
                  })
                  .then(() => {
                    thisRef.validate({ password: true })
                  })
                }}
                onBlur={() => {
                  hycon.debug(`${thisRef.constructor.name} onBlur`, {})
                  thisRef.validate({ password: true })
                }}
                value={thisRef.state.password}

              />
            </Form.Field>
            <div className={'password-hints'}>
              {(() => {
                if (thisRef.state.passwordValidation.errors.length > 0) {
                  let errorsString = thisRef.state.passwordValidation.errors[thisRef.state.passwordValidation.errors.length - 1]
                  let errors = errorsString.split('|')
                  let items = []
                  errors.forEach((e, i) => {
                    items.push((
                      <li key={i}>{e}</li>
                    ))
                  })
                  return <ul>{items}</ul>
                } else {
                  return ''
                }
              })()}
            </div>
            <Form.Field error={thisRef.state.errors.passwordRepeat}>
              <input
                placeholder={thisRef.props.i18n.t('profile:label-new-password-repeat')}
                type={'password'}
                onChange={(event) => {
                  let val = event.target.value
                  hycon.debug(`${thisRef.constructor.name} onChange`, { event, value: val })
                  thisRef.setStateProperty('passwordRepeat', val)
                  .then(() => {
                    return thisRef.setStateProperty('touched', Object.assign({}, thisRef.state.touched, { passwordRepeat: true }))
                  })
                  .then(() => {
                    thisRef.validate({ password: true })
                  })
                }}
                onBlur={() => {
                  hycon.debug(`${thisRef.constructor.name} onBlur`, {})
                  thisRef.validate({ password: true })
                }}
                value={thisRef.state.passwordRepeat}
              />
            </Form.Field>
            <div className={`column-flex`}>
              <Button
                className={'big-button'}
                primary
                type='submit'
                disabled={(() => {
                  let isFormComplete = thisRef.isFormComplete()
                  return !isFormComplete
                })()}
                onClick={() => {
                  thisRef.validate()
                  .then(() => {
                    let validElements = thisRef.getFormValidElements()
                    let isAllValid = validElements.isAllValid
                    return isAllValid
                  })
                  .then((isAllValid) => {
                    if (isAllValid) {
                      hycon.debug(`${thisRef.constructor.name} form looks good`, {})
                      thisRef.recaptchaExecute().then(() => {
                        return thisRef.sendEditRequest()
                      })
                    } else {
                      hycon.debug(`${thisRef.constructor.name} please check your form`, {})
                    }
                  })
                }}
              >
                {thisRef.props.i18n.t('profile:button-set-password')}
              </Button>
            </div>
            <div className={`column-flex`}>
              <div className={`account-delete`}>
                <Button
                  className={'big-button'}
                  primary
                  type='submit'
                  color={'red'}
                  onClick={() => {
                    let namePrompt = prompt(thisRef.props.i18n.t('profile:delete-prompt'))
                    if (namePrompt !== thisRef.props.reduxState.session.user.userName) {
                      hycon.debug(`${thisRef.constructor.name} that was close.`, {})
                      thisRef.setStateProperty('messages', thisRef.state.messages.concat([
                        `${thisRef.props.i18n.t('profile:delete-account-not-deleted')}<br/>` +
                        `${thisRef.props.i18n.t('profile:delete-wrong-email')}<br/><br/><br/><br/><br/><br/>`
                      ]), {
                        reset: true, resetValue: [], delta: 4000, onSetState: () => {
                          document.getElementById('messages').scrollIntoView()
                        }
                      })
                    } else {

                      return thisRef.deleteAccount()
                      .then(() => {
                        return UserUtil.deleteUserInReduxAndCookies(thisRef)
                      })
                      .then(() => {
                        return TokenUtil.updateTokenInRedux(null, thisRef)
                      })
                      .then(() => {
                        thisRef.props.reduxDispatch("updateSession", {});
                      })
                      .then(() => {
                        thisRef.props.history.push('/user/signin/', { unauthorized: false })
                      })
                      .catch((e) => {
                        hycon.error("account delete error", e);
                        thisRef.setStateProperty('messages', thisRef.state.messages.concat([
                          `${thisRef.props.i18n.t('profile:delete-error')}<br/>` +
                          `${e.message}`
                        ]), {
                          reset: true,
                          resetValue: [],
                          delta: 4000
                        })
                      })

                    }
                  }}
                >
                  {thisRef.props.i18n.t('profile:button-delete-account')}
                </Button>
              </div>
            </div>
          </Form>
          <div className={'footer'} id={'messages'}>
            <h5>
              <div
                dangerouslySetInnerHTML={{
                  __html: (
                    () => {
                      if (thisRef.state.messages.length > 0) {
                        return `<div>${thisRef.state.messages[thisRef.state.messages.length - 1]}</div>`
                      }
                    }
                  )()
                }}
              />
            </h5>
          </div>
        </div>
      </div>
    )
  }

  componentDidUpdate () {
    // let thisRef = this;
    hycon.debug(`${this.constructor.name} componentDidUpdate`, { props: this.props })
  }

  componentWillUnmount () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} componentWillUnmount`, { props: this.props })
    thisRef.cancelPendingRequests()
  }
}

export default withTranslation(['profile'])(EditProfile)
