import React, { useState, useEffect, useReducer, useContext, useRef } from 'react'
import { saveAs } from 'file-saver'
import HyperConsole from '../../hyper_console/hyper-console'
import './PanelConfig.css'
import HyperMenu from '../../hyper_menu/HyperMenu'
import Navbar from '../../../components/sep_map/component_navbar/Navbar'
import { withTranslation } from 'react-i18next'

import CodeMirror from 'codemirror/lib/codemirror'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/addon/edit/matchbrackets'
import 'codemirror/addon/edit/closebrackets'
import 'codemirror/addon/fold/foldgutter'
import 'codemirror/addon/fold/indent-fold'
import 'codemirror/addon/fold/brace-fold'
import 'codemirror/addon/fold/foldcode'

import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/dracula.css'
import 'codemirror/addon/fold/foldgutter.css'
import axios from 'axios'
import { Button, Dimmer, Loader } from 'semantic-ui-react'
import qs from 'qs'
import Ajv from 'ajv'

import marketsenseSchemaJSON from './validator/marketsense.json'
import marketsenseV4SchemaJSON from './validator/marketsense_v4.json'
import energysimulationSchemaJSON from './validator/energysimulation.json'
import demoSchemaJSON from './validator/demo.json'
import heatSchemaJSON from './validator/heat.json'

const ajv = new Ajv({ schemas: [marketsenseSchemaJSON, marketsenseV4SchemaJSON, energysimulationSchemaJSON, demoSchemaJSON, heatSchemaJSON] })
const marketsenseSchema = ajv.getSchema('http://sep.energyapps.ch/panels.marketsense.schema.json')
const marketsenseV4Schema = ajv.getSchema('http://sep.energyapps.ch/panels.marketsense_v4.schema.json')
const energysimulationSchema = ajv.getSchema('http://sep.energyapps.ch/panels.energysimulation.schema.json')
const demoSchema = ajv.getSchema('http://sep.energyapps.ch/panels.demo.schema.json')
const heatSchema = ajv.getSchema('http://sep.energyapps.ch/panels.heat.schema.json')


const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

const initFormatting = () => {
  CodeMirror.extendMode('css', {
    commentStart: '/*',
    commentEnd: '*/',
    newlineAfterToken: function (type, content) {
      return /^[;{}]$/.test(content);
    }
  })

  CodeMirror.extendMode('javascript', {
    commentStart: '/*',
    commentEnd: '*/',
    // FIXME semicolons inside of for
    newlineAfterToken: function (type, content, textAfter, state) {
      if (this.jsonMode) {
        return /^[[,{]$/.test(content) || /^}/.test(textAfter);
      } else {
        if (content === ';' && state.lexical && state.lexical.type === ')') return false
        return /^[;{}]$/.test(content) && !/^;/.test(textAfter);
      }
    }
  })

  CodeMirror.extendMode('xml', {
    commentStart: '<!--',
    commentEnd: '-->',
    newlineAfterToken: function (type, content, textAfter) {
      // eslint-disable-next-line no-mixed-operators
      return type === 'tag' && />$/.test(content) || /^</.test(textAfter);
    }
  })

  // Comment/uncomment the specified range
  CodeMirror.defineExtension('commentRange', function (isComment, from, to) {
    var cm = this, curMode = CodeMirror.innerMode(cm.getMode(), cm.getTokenAt(from).state).mode
    cm.operation(function () {
      if (isComment) { // Comment range
        cm.replaceRange(curMode.commentEnd, to)
        cm.replaceRange(curMode.commentStart, from)
        if (from.line === to.line && from.ch === to.ch) // An empty comment inserted - put cursor inside
          cm.setCursor(from.line, from.ch + curMode.commentStart.length)
      } else { // Uncomment range
        var selText = cm.getRange(from, to)
        var startIndex = selText.indexOf(curMode.commentStart)
        var endIndex = selText.lastIndexOf(curMode.commentEnd)
        if (startIndex > -1 && endIndex > -1 && endIndex > startIndex) {
          // Take string till comment start
          selText = selText.substr(0, startIndex)
            // From comment start till comment end
            + selText.substring(startIndex + curMode.commentStart.length, endIndex)
            // From comment end till string end
            + selText.substr(endIndex + curMode.commentEnd.length)
        }
        cm.replaceRange(selText, from, to)
      }
    })
  })

  // Applies automatic mode-aware indentation to the specified range
  CodeMirror.defineExtension('autoIndentRange', function (from, to) {
    var cmInstance = this
    this.operation(function () {
      for (var i = from.line; i <= to.line; i++) {
        cmInstance.indentLine(i, 'smart')
      }
    })
  })

  // Applies automatic formatting to the specified range
  CodeMirror.defineExtension('autoFormatRange', function (from, to) {
    var cm = this
    var outer = cm.getMode(), text = cm.getRange(from, to).split('\n')
    var state = CodeMirror.copyState(outer, cm.getTokenAt(from).state)
    var tabSize = cm.getOption('tabSize')

    var out = '', lines = 0, atSol = from.ch === 0

    function newline () {
      out += '\n'
      atSol = true
      ++lines
    }

    for (var i = 0; i < text.length; ++i) {
      var stream = new CodeMirror.StringStream(text[i], tabSize)
      while (!stream.eol()) {
        var inner = CodeMirror.innerMode(outer, state)
        var style = outer.token(stream, state), cur = stream.current()
        stream.start = stream.pos
        if (!atSol || /\S/.test(cur)) {
          out += cur
          atSol = false
        }
        if (!atSol && inner.mode.newlineAfterToken &&
          inner.mode.newlineAfterToken(style, cur, stream.string.slice(stream.pos) || text[i + 1] || '', inner.state))
          newline()
      }
      if (!stream.pos && outer.blankLine) outer.blankLine(state)
      if (!atSol) newline()
    }

    cm.operation(function () {
      cm.replaceRange(out, from, to)
      for (var cur = from.line + 1, end = from.line + lines; cur <= end; ++cur)
        cm.indentLine(cur, 'smart')
      cm.setSelection(from, cm.getCursor(false))
    })
  })
}
initFormatting()

function Overview (props) {
  const hookName = 'Overview'
  const panelConfigContext = useContext(PanelConfigContext)
  const [sortedSettingsTree, setSortedSettingsTree] = useState([]);
  const [component, setComponent] = useState(null);
  useEffect(() => {
    let settingsTree = panelConfigContext.getSettingsTree(panelConfigContext.panelSettings) || []
    settingsTree.sort((a, b) => {
      let aString = JSON.stringify(a)
      let bString = JSON.stringify(b)
      if (aString < bString) {
        return -1
      } else if (aString > bString) {
        return 1
      } else if (aString === bString) {
        return 0
      }
      return 0
    });
    setSortedSettingsTree(settingsTree)
  }, [panelConfigContext, panelConfigContext.panelSettings])
  useEffect(() => {
    let header = [
      <div className={'config-item'} key={'header'}>
        <div className={`row`}>
          <h4>tenant</h4>
        </div>
        <div className={`row`}>
          <h4>panelType</h4>
        </div>
        <div className={`row`}>
          <h4>category</h4>
        </div>
        <div className={`row`}>
          <h4>actions</h4>
        </div>
        <div className={`row`}>
          <h4>status</h4>
        </div>
      </div>
    ]
    let items = sortedSettingsTree.map((listElement, i) => {
      return (
        <div className={'config-item'} key={i}>
          <div className={`row`}>
            {listElement.tenant}
          </div>
          <div className={`row`}>
            {listElement.panelType}
          </div>
          <div className={`row`}>
            {listElement.category}
          </div>
          <div className={`row actions`}>
            <Button
              onClick={() => {
                props.onUploadButtonClick()
              }}>
              update
            </Button>
            <Button
              primary
              onClick={() => {
                let setting = listElement.settingsByTenantAndPanelTypeAndCategory.filter((element => element.name === 'setting'))[0].value
                panelConfigContext.editor.setValue(setting)
                panelConfigContext.setState({
                  tenant: listElement.tenant,
                  panelType: listElement.panelType,
                  category: listElement.category
                })
              }}>
              load in editor
            </Button>
            <Button
              color={'orange'}
              onClick={async () => {
                let settingsByTenantAndPanelTypeAndCategory = listElement.settingsByTenantAndPanelTypeAndCategory
                for (let i = 0; i < settingsByTenantAndPanelTypeAndCategory.length; i++) {
                  let setting = settingsByTenantAndPanelTypeAndCategory[i]
                  hycon.debug(`${hookName} deleting`, { setting })
                  await panelConfigContext.deletePanelSettingsAsAdminById(panelConfigContext.jwt, setting.id)
                }
                await panelConfigContext.updatePanelSettings(panelConfigContext.jwt)
              }}
            >
              delete
            </Button>
          </div>
          <div className={`row status`}>
            {(() => {
              if (props.validatedConfigs.includes(listElement.panelType)) {
                const parsedConfig = JSON.parse(listElement.settingsByTenantAndPanelTypeAndCategory[0].value)
                let schema = null
                if (listElement.panelType === 'marketsense') {
                  schema = marketsenseSchema.schema
                } else if(listElement.panelType === "marketsense_v4"){
                  schema = marketsenseV4Schema.schema;
                } else if (listElement.panelType === 'energysimulation') {
                  schema = energysimulationSchema.schema
                } else if (listElement.panelType === 'demo') {
                  schema = demoSchema.schema
                } else if (listElement.panelType === 'heat'){
                  schema = heatSchema.schema
                }
                const valid = ajv.validate(schema, parsedConfig) // true
                return <b>{valid ? 'ok' : `${JSON.stringify(ajv.errors.map(e => `${e.message} at config${e.dataPath}`))}`}</b>
              } else {
                return <b>{'no validation implemented'}</b>
              }
            })()}
          </div>
        </div>
      )
    })
    setComponent([header, ...items])
  }, [panelConfigContext, props, sortedSettingsTree])
  return (
    <div className={hookName}>
      <div className={'relative-container'}>
        {component}
      </div>
    </div>
  )
}

const getJWTFromQueryString = () => {
  let search = window.location.search
  search = search.substr(1, search.length)
  const parsed = qs.parse(search)
  const jwt = parsed.jwt
  return jwt || ''
}
const defaultContext = {
  panelSettings: [],
  isLoading: false,
  jwt: getJWTFromQueryString(),
  tenant: null,
  user: '',
  panelType: null,
  category: null,
  dispatch: () => {},
  getPanelSettings: async () => {},
  updatePanelSettings: async () => {},
  postPanelSettingsAsAdmin: async () => {},
  deletePanelSettingsAsAdminById: async () => {},
  getSettingsTree: () => {},
  setState: () => {}
}
const PanelConfigContext = React.createContext(defaultContext)
const reducerActions = {
  REPLACE: 'REPLACE'
}

function reducer (state, action) {
  hycon.debug(`reducer`, { info: action.info, state, action })
  if (action.type === reducerActions.REPLACE) {
    return { ...state, ...action.payload.newState }
  } else {
    throw new Error('unsupported reducer action')
  }
}
const validatedConfigs = ['marketsense', 'marketsense_v4', 'energysimulation', "demo", "heat"]

export function PanelConfig (props) {
  const hookName = 'PanelConfig'
  const editorDomElementId = 'codemirror'
  let env = props.reduxState.env
  const menu = useRef(null)
  const [editorContent, setEditorContent] = useState('')
  const [editor, setEditor] = useState(null)
  const [state, dispatch] = useReducer(reducer, defaultContext)
  const [isLoading, setIsLoading] = useState(false)
  const [liveStatus, setLiveStatus] = useState({ panelType: state.panelType, isValid: true, errors: null })

  useEffect(() => {
    hycon.debug(`${hookName} - useEffect - editor content changed`, { editorContent })
    if (validatedConfigs.includes(state.panelType)) {
      try {
        let parsedContent = JSON.parse(editorContent)
        let schema = null
        if (state.panelType === 'marketsense') {
          schema = marketsenseSchema.schema
        } else if (state.panelType === 'marketsense_v4') {
          schema = marketsenseV4Schema.schema
        } else if (state.panelType === 'energysimulation') {
          schema = energysimulationSchema.schema
        } else if (state.panelType === 'demo') {
          schema = demoSchema.schema
        } else if (state.panelType === 'heat') {
          schema = heatSchema.schema
        }
        const valid = ajv.validate(schema, parsedContent) // true
        if (valid) {
          setLiveStatus({
              panelType: state.panelType,
              isValid: true,
              errors: null
            }
          )
        } else {
          setLiveStatus({
            panelType: state.panelType,
            isValid: false,
            errors: JSON.stringify(ajv.errors.map(e => `${e.message} at config${e.dataPath}`))
          })
        }
      } catch (e) {
        setLiveStatus({ panelType: state.panelType, isValid: false, errors: 'No valid JSON.' })
      }
    } else {
      setLiveStatus({ panelType: state.panelType, isValid: true, errors: null })
    }
    // https://github.com/facebook/react/issues/15426
  }, [editorContent, state.panelType])

  const initScriptEditor = async () => {
    hycon.debug(`${hookName} useEffect - initScriptEditor`, {})
    if(document.querySelector(".CodeMirror") === null){
      let myEditor = CodeMirror(document.getElementById(editorDomElementId), {
        value: editorContent,
        theme: 'dracula',
        matchBrackets: true,
        autoCloseBrackets: true,
        gutter: true,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
        foldGutter: true,
        extraKeys: { 'Ctrl-Q': 'toggleComment' },
        smartIndent: true,
        lineNumbers: true,
        lineWrapping: true,
        indentUnit: 4,
        mode: { name: 'javascript', json: true, fold: 'brace' }
      })
      setEditor(myEditor)
      myEditor.on('change', (codemirror, changeObj) => {
        let content = myEditor.getValue()
        hycon.debug(`${hookName} codemirror - change`, { content, codemirror, changeObj })
        setEditorContent(content)
      })
    }
  }

  let getPropertySet = (elements, property) => [...new Set(elements.map(el => el[property]))]
  let filterByProperty = (elements, property, propertyValue) => {
    return elements.filter(el => el[property] === propertyValue)
  }
  const getSettingsTree = (panelSettings) => {
    hycon.debug(`${hookName} - getSettingsTree`, { panelSettings })
    let allTenants = getPropertySet(panelSettings, 'mandant')
    let settingsTree = []
    allTenants.forEach(tenant => {
      let tenantSettings = filterByProperty(panelSettings, 'mandant', tenant)
      let panelTypesForTenant = getPropertySet(tenantSettings, 'panelType')
      panelTypesForTenant.forEach(panelType => {
        let settingsByTenantAndPanelType = filterByProperty(tenantSettings, 'panelType', panelType)
        let categoriesByTenantAndPanelType = getPropertySet(settingsByTenantAndPanelType, 'category')
        categoriesByTenantAndPanelType.forEach((category) => {
          let settingsByTenantAndPanelTypeAndCategory = filterByProperty(settingsByTenantAndPanelType, 'category', category)
          settingsTree.push({
            tenant,
            panelTypesForTenant,
            panelType,
            settingsByTenantAndPanelType,
            categoriesByTenantAndPanelType,
            category,
            settingsByTenantAndPanelTypeAndCategory
          })
        })
      })
    })
    hycon.debug(`${hookName} - getSettingsTree`, { panelSettings, allTenants, settingsTree })
    return settingsTree
  }
  const onFileChange = (event) => {
    try {
      let files = event.target.files
      if (!files.length) {
        alert('No file selected!')
        return
      }
      let file = files[0]
      let reader = new FileReader()
      reader.onload = (event) => {
        let content = event.target.result
        hycon.debug(`${hookName} - loaded json config`, { content })
        editor.setValue(content)
      }
      reader.readAsText(file)
    } catch (err) {
      hycon.error(`${hookName} - error loading json config`, err)
    }
  }
  const onTenantChange = (e) => {
    hycon.debug(`${hookName} onTenantChange`, { value: e.target.value })
    dispatch({
      type: reducerActions.REPLACE,
      info: 'tenant',
      payload: {
        newState: {
          tenant: e.target.value
        }
      }
    })
  }
  const onUserChange = (e) => {
    hycon.debug(`${hookName} onUserChange`, { value: e.target.value })
    dispatch({
      type: reducerActions.REPLACE,
      info: 'user',
      payload: {
        newState: {
          user: e.target.value
        }
      }
    })
  }
  const onJWTChange = async (e) => {
    hycon.debug(`${hookName} onJWTChange`, { value: e.target.value })
    dispatch({
      type: reducerActions.REPLACE,
      info: 'jwt',
      payload: {
        newState: {
          jwt: e.target.value
        }
      }
    })
  }
  const onPanelTypeChange = (e) => {
    hycon.debug(`${hookName} onPanelTypeChange`, { value: e.target.value })
    dispatch({
      type: reducerActions.REPLACE,
      info: 'panelType',
      payload: {
        newState: {
          panelType: e.target.value
        }
      }
    })
  }
  const onCategoryChange = (e) => {
    hycon.debug(`${hookName} onCategoryChange`, { value: e.target.value })
    dispatch({
      type: reducerActions.REPLACE,
      info: 'category',
      payload: {
        newState: {
          category: e.target.value
        }
      }
    })
  }
  const onFormat = () => {
    try {
      var jsonPretty = JSON.stringify(JSON.parse(editorContent), null, 2)
      editor.setValue(jsonPretty)
      hycon.debug(`${hookName} codemirror - beautify ok`, { editorContent })
    } catch (e) {
      hycon.warn(`${hookName} codemirror - beautify error`, { editorContent })
    }
  }
  const onUpload = async () => {
    try {
      let editorContent = editor.getValue()
      let parsedContent = JSON.parse(editorContent)
      hycon.debug(`${hookName} onUpload `, { parsedContent })

      // first delete all settings of that type
      await deleteSetting(state.tenant, state.panelType, state.category)
      await postPanelSettingsAsAdmin(state.jwt, {
        'category': state.category,
        'mandant': state.tenant,
        'name': 'setting',
        'panelType': state.panelType,
        'user': state.user,
        'value': JSON.stringify(parsedContent, null, 4)
      })
    } catch (e) {
      hycon.error(`${hookName} onUpload - error`, { e })
    }
  }
  const setState = (newState) => {
    dispatch({
      type: reducerActions.REPLACE,
      info: 'setState',
      payload: {
        newState
      }
    })
  }
  const deleteSetting = async (tenant, panelType, category) => {
    let panelSettingsResponse = await getPanelSettingsAsAdmin(state.jwt)
    let panelSettings = panelSettingsResponse.data
    hycon.debug(`${hookName} deleteSetting `, { panelSettings, tenant, panelType, category })
    for (let j = 0; j < panelSettings.length; j++) {
      let setting = panelSettings[j]
      if (
        setting.mandant === tenant
        && setting.panelType === panelType
        && setting.category === category
      ) {
        await deletePanelSettingsAsAdminById(state.jwt, setting.id)
      }
    }
  }
  const updatePanelSettings = async (jwt) => {
    hycon.debug(`${hookName} updatePanelSettings `, { jwt })
    let panelSettingsResponse = await getPanelSettingsAsAdmin(jwt).catch((e) => {
      hycon.error(`${hookName} updatePanelSettings - auth error`, { e, panelSettingsResponse })
      dispatch({
        type: reducerActions.REPLACE,
        info: 'updatePanelSettingsInContext',
        payload: {
          newState: {
            panelSettings: []
          }
        }
      })
      return null
    })
    if (panelSettingsResponse !== null) {
      let panelSettings = panelSettingsResponse.data
      hycon.debug(`${hookName} updatePanelSettings `, { panelSettingsResponse, panelSettings })
      dispatch({
        type: reducerActions.REPLACE,
        info: 'updatePanelSettingsInContext',
        payload: {
          newState: {
            panelSettings
          }
        }
      })
    }
  }
  // axios
  const getPanelSettingsAsAdmin = async (jwt) => {
    let endpoint = `${env.API_GATEWAY_BASE}/api/panelsettings-all`
    let data = {}
    return await axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      hycon.debug(`${hookName} getPanelSettingsAsAdmin - response`, { response })
      return response
    })
    .catch((error) => {
      hycon.error(`${hookName} getPanelSettingsAsAdmin`, { error })
      throw error
    })
  }
  const postPanelSettingsAsAdmin = async (jwt, data) => {
    hycon.debug(`${hookName} postPanelSettingsAsAdmin`, { jwt, data })
    let endpoint = `${env.API_GATEWAY_BASE}/api/panelsettings`
    return await axios(
      {
        method: 'post',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data
      }
    )
    .then((response) => {
      hycon.debug(`${hookName} postPanelSettingsAsAdmin - response`, { response })
      return response
    })
    .catch((error) => {
      hycon.error(`${hookName} postPanelSettingsAsAdmin`, { error })
      throw error
    })
  }
  const deletePanelSettingsAsAdminById = async (jwt, settingId) => {
    hycon.debug(`${hookName} deletePanelSettingsAsAdminById - deleting`, { settingId })
    let endpoint = `${env.API_GATEWAY_BASE}/api/panelsettings/${settingId}`
    return await axios(
      {
        method: 'delete',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      }
    )
    .then((response) => {
      hycon.debug(`${hookName} deletePanelSettingsAsAdminById - response`, { response })
      return response
    })
    .catch((error) => {
      hycon.error(`${hookName} deletePanelSettingsAsAdminById`, { error })
      throw error
    })
  }
  const isValidJSON = () => {
    try {
      let parsed = JSON.parse(editorContent)
      return !!parsed
    } catch (e) {
      return false
    }
  }
  const onUploadButtonClick = async () => {
    setIsLoading(true)
    await onUpload()
    await updatePanelSettings(state.jwt)
    setIsLoading(false)
  }

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - constructor`, {})
    initScriptEditor()
    dispatch({
      type: reducerActions.REPLACE,
      info: 'dispatcherInit',
      payload: {
        newState: {
          dispatch: dispatch,
          updatePanelSettings,
          postPanelSettingsAsAdmin,
          deletePanelSettingsAsAdminById,
          getSettingsTree,
          setState
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
    if(editor){
      editor.refresh();
    }
  }, [editor, editorContent])

  useEffect(() => {
    setIsLoading(true)
    updatePanelSettings(state.jwt).then(() => {
      setIsLoading(false)
    }).catch((e) => {
      hycon.error(`${hookName} updatePanelSettings - error`, { e })
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.jwt])
  useEffect(() => {
    hycon.debug(`${hookName} useEffect - editor`, { editor })
    dispatch({
      type: reducerActions.REPLACE,
      info: 'dispatcherInit',
      payload: {
        newState: {
          editor: editor
        }
      }
    })
  }, [editor])
  return (
    <div className={hookName}>
      <HyperMenu
        {...props}
        ref={(c) => {
          if(!menu || !menu.current){
            menu.current = c;
          }
        }}
        content={(
          <div className={`menu-content-wrapper`}>
            <Navbar
              {...props}
              onMenuToggle={() => {
                menu.current.setStateProperty('isOpen', !menu.current.getState().isOpen)
              }}
              isSearchDisplayed={false}
            />
            <PanelConfigContext.Provider value={state}>
              <div className={`${hookName}-container`}>
                <div className={`${hookName}-container-absolute`}>
                  <div className={'resizable'} style={{ height: '100px', minHeight: '100px' }}>
                    <div id={`${editorDomElementId}`} className={editorDomElementId}>

                    </div>
                  </div>
                  <div className={'dynamic-height'}>
                    <div className={`section`}>
                      <div className={'editor-action-row'}>
                        <label>load from file: &nbsp;</label>
                        <input
                          type="file"
                          id="avatar" name="avatar"
                          accept=".json"
                          onChange={(event) => {
                            onFileChange(event)
                          }}
                        />
                      </div>
                      <div className={`editor-action-row`}>
                        <button
                          onClick={() => {
                            onFormat()
                          }}>
                          format
                        </button>
                        <button
                          onClick={() => {
                            let content = editor.getValue()
                            var blob = new Blob([content], { type: 'text/plain;charset=utf-8' })
                            saveAs(blob, `config_${state.tenant}_${state.panelType}_${state.category}.json`)
                          }}
                        >
                          save to file
                        </button>
                      </div>
                    </div>
                    <div className={`section`}>
                      <div className={`editor-action-row`}>
                        <label>JWT:&nbsp;</label>
                        <input
                          type={'text'}
                          value={state.jwt || ''}
                          onChange={async (e) => {
                            await onJWTChange(e)
                          }}
                        />
                        <span> </span>
                      </div>
                      <div className={`editor-action-row`}>
                        <label>PanelType:&nbsp;</label>
                        <input
                          type={'text'}
                          value={state.panelType || ''}
                          onChange={onPanelTypeChange}
                        />
                      </div>
                      <div className={`editor-action-row`}>
                        <label>Category:&nbsp;</label>
                        <input
                          type={'text'}
                          value={state.category || ''}
                          onChange={onCategoryChange}
                        />
                      </div>
                      <div className={`editor-action-row`}>
                        <label>Mandant:&nbsp;</label>
                        <input type={`text`} value={state.tenant || ''} onChange={onTenantChange}/>
                        <span> </span>
                      </div>
                      <div className={`editor-action-row`}>
                        <label>User:&nbsp;</label>
                        <input type={`text`} value={state.user || ''} onChange={onUserChange}/>
                      </div>
                      <div className={`editor-action-row`}>
                        <label>Is a valid {liveStatus.panelType} config:&nbsp;</label>
                        {liveStatus.isValid.toString()} {liveStatus.errors !== null ? ' - ' + liveStatus.errors.toString() : ''}
                      </div>
                      <div className={`editor-action-row`}>
                        <button
                          disabled={isValidJSON() === false}
                          onClick={async ()=>{
                            await onUploadButtonClick()
                          }}>
                          upload
                        </button>
                        <span>&nbsp;</span>
                        <div>
                          {(() => {
                            if (isValidJSON() === false) {
                              return `please fix the config first`
                            }
                          })()}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`section`}
                      style={{"overflow": "auto", minHeight: "200px"}}
                    >
                      <div
                        className={`overview`}
                        style={{ position: 'relative' }}
                      >
                        <Dimmer active={isLoading}>
                          <Loader size={'small'}/>
                        </Dimmer>
                        <Overview
                          {...props}
                          setIsLoading={(isLoading) => {
                            setIsLoading(isLoading)
                          }}
                          editor={editor}
                          validatedConfigs={validatedConfigs}
                          onUploadButtonClick={onUploadButtonClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PanelConfigContext.Provider>
          </div>
        )}
      />
    </div>
  )
}

export default withTranslation(['favorites', 'events', 'panel_construction_zone', 'panel_usage'])(PanelConfig)

