import React, {Component} from 'react';
import HyperConsole from "../../hyper_console/hyper-console";

import "./Feedback.css";
import HyperMenu from "../../hyper_menu/HyperMenu";
import Navbar from "../../sep_map/component_navbar/Navbar";
import {Button, Form} from 'semantic-ui-react';
import Rating from "react-rating";
import axios from "axios/index";
import {UserUtil} from "../../user_management/util/user-util";
import { withTranslation } from 'react-i18next'

let hycon = new HyperConsole({isEnabled: false, name:__filename}).myConsole;

class Feedback extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '', surname: '', email: '', feedback: '', feedbackDetail: '', rating: null,
            isLoading: false,
            isSuccess: false
        };
        hycon.debug(`${this.constructor.name} constructor`, {props: this.props});
    }

    componentWillMount() {

        hycon.debug(`${this.constructor.name} componentWillMount`, {props: this.props});
    }

    componentDidMount() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} componentDidMount`, {props: this.props});
        thisRef.setStateProperty("email", thisRef.props.reduxState.user.user.userName);
    }

    componentWillReceiveProps() {

        hycon.debug(`${this.constructor.name} componentWillReceiveProps`, {props: this.props});
    }

    shouldComponentUpdate(nextProps, nextState) {

        hycon.debug(`${this.constructor.name} shouldComponentUpdate`, {nextProps, props: this.props, nextState});
        return true;
    }

    componentWillUpdate() {

        hycon.debug(`${this.constructor.name} componentWillUpdate`, {props: this.props});
    }

    sendSlackMessage() {
        // https://hooks.slack.com/services/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
        let thisRef = this;
        hycon.debug(`${this.constructor.name} sendSlackMessage`, {props: this.props});
        let env = thisRef.props.reduxState.env;

        const SLACK_ENDPOINT = `${env.API_GATEWAY_BASE}/slack/services/T8VA4F1GF/BC2GRP6LT/C8SvobpdiJoMBDgvjbjwuz6a`;

        return new Promise(() => {
            return axios(
                {
                    method: 'post',
                    url: SLACK_ENDPOINT,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': "application/json"
                    },
                    data: JSON.stringify(
                        {
                            text: `Feedback from ${thisRef.state.email} (${thisRef.state.name} ${thisRef.state.surname})\nBemerkungen: ${thisRef.state.feedbackDetail}\nAllgemeine Bemerkungen: ${thisRef.state.feedback}\nRating: ${thisRef.state.rating}`
                        }
                    )
                }
            );
        });
    };

    sendMail() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} sendMail`, {props: this.props});
        let env = thisRef.props.reduxState.env;
        let jwt = thisRef.props.reduxState.user.jwt;

        const SENDGRID_ENDPOINT = `${env.API_GATEWAY_BASE}/sendgrid/v3/mail/send`;

        thisRef.setStateProperty("isLoading", true).then(() => {
            return new Promise((res) => {
                return axios(
                    {
                        method: 'post',
                        url: SENDGRID_ENDPOINT,
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': "application/json",
                            'Authorization': `Bearer ${jwt}`
                        },
                        data: JSON.stringify(
                            {
                                personalizations: [
                                    {
                                        to: [
                                            {
                                                email: thisRef.props.reduxState.env.geoimpact.support_email,
                                                name: 'geoimpact Support'
                                            }
                                        ],
                                        subject: `SEP Feedback from ${thisRef.state.email}`
                                    }
                                ],
                                from: {
                                    email: `${thisRef.state.email}`,
                                    name: `${thisRef.state.name} ${thisRef.state.surname}`
                                },
                                content: [
                                    {
                                        type: "text/plain",
                                        value: `Feedback from ${thisRef.state.email} (${thisRef.state.name} ${thisRef.state.surname})\nBemerkungen: ${thisRef.state.feedbackDetail}\nAllgemeine Bemerkungen: ${thisRef.state.feedback}\nRating: ${thisRef.state.rating}`
                                    }
                                ]
                            }
                        )
                    }
                )
                    .then((response) => {
                        hycon.debug(`${this.constructor.name} sendMail`, {response});

                        return Promise.all([
                            thisRef.setStateProperty("isLoading", false),
                            thisRef.setStateProperty("isSuccess", true)
                        ]).then(() => {
                            res();
                        });
                    })
                    .catch((error) => {
                        hycon.debug(`${this.constructor.name} sendMail`, {error});
                        UserUtil.authenticationRedirect(error.response, thisRef);
                        return Promise.all([
                            thisRef.setStateProperty("isLoading", false),
                            thisRef.setStateProperty("isSuccess", false)
                        ]).then(() => {
                            res();
                        });
                    });
            });
        }).then(() => {
            try {
                return thisRef.sendSlackMessage();
            } catch (e) {
                hycon.warn(e);
                return Promise.reject(e);
            }
        });

    }

    setStateProperty(propertyName, propertyValue) {
        let thisRef = this;
        return new Promise((res) => {
            thisRef.setState((p) => {
                const obj = {};
                obj[propertyName] = propertyValue;
                return Object.assign({}, p, obj);
            }, () => {
                hycon.debug(`${thisRef.constructor.name} setStateProperty - newState`, {newState: thisRef.state});
                res();
            })
        });
    }

    render() {
        let thisRef = this;
        let getControl = (name, label, placeholder, type = "text") => {
            return (
                <Form.Field>
                    <label>{label}</label>
                    <Form.Input
                        id={`form-input-control-${name}`}
                        onChange={(event, data) => {
                            hycon.debug(`${thisRef.constructor.name} onChange`, {event, data});
                            return thisRef.setStateProperty(name, data.value);
                        }}
                        placeholder={placeholder}
                        type={type}
                    />
                </Form.Field>
            );
        };
        return (
            <div className="Feedback">

                <HyperMenu
                    {...thisRef.props}
                    ref={(c) => thisRef.menu = c}
                    content={(
                        <div className={`menu-content-wrapper`}>
                            <Navbar
                                {...thisRef.props}
                                onMenuToggle={() => {
                                    thisRef.menu.setStateProperty("isOpen", !thisRef.menu.getState().isOpen);
                                }}
                                isSearchDisplayed={false}
                            />
                            <div className={"container"}>
                                {(() => {
                                    if(thisRef.state.isSuccess){
                                        return (
                                          <div className={"form-container"}>
                                              <div className={`thanks-container`}>
                                                  <h4>{thisRef.props.i18n.t("feedback:label-thanks-message")}</h4>
                                                  <Button size={"medium"} primary onClick={() => {
                                                      thisRef.props.history.push("/map/");
                                                  }}>
                                                      {thisRef.props.i18n.t("feedback:button-map")}
                                                  </Button>
                                              </div>
                                          </div>
                                        );
                                    } else {
                                        return (
                                          <div className={"form-container"}>
                                              <Form
                                                success={thisRef.state.isSuccess}
                                                loading={thisRef.state.isLoading}
                                                onSubmit={() => {
                                                    hycon.debug(`${this.constructor.name} onSubmit`, {state: thisRef.state});
                                                    thisRef.sendMail();
                                                }}
                                              >
                                                  <Form.Field className={"centered"}>
                                                      <label><h3>
                                                          {thisRef.props.i18n.t("feedback:label-header")}
                                                      </h3></label>
                                                  </Form.Field>
                                                  {getControl("name", thisRef.props.i18n.t("feedback:label-name"), thisRef.props.i18n.t("feedback:label-name"))}
                                                  {getControl("surname", thisRef.props.i18n.t("feedback:label-surname"), thisRef.props.i18n.t("feedback:label-surname"))}
                                                  <Form.Field>
                                                      <label>{thisRef.props.i18n.t("feedback:label-email")}</label>
                                                      <Form.Input
                                                        disabled
                                                        id={`form-input-control-email`}
                                                        onChange={(event, data) => {
                                                            hycon.debug(`${thisRef.constructor.name} onChange`, {event, data});
                                                            return thisRef.setStateProperty("email", data.value);
                                                        }}
                                                        value={(() => {
                                                            try {
                                                                return thisRef.props.reduxState.user.user.userName;
                                                            }catch (e) {
                                                                return null;
                                                            }
                                                        })()}
                                                        placeholder={thisRef.props.i18n.t("feedback:label-name")}
                                                        type={"email"}
                                                      />
                                                  </Form.Field>
                                                  <label style={{fontWeight: "bold"}}>{thisRef.props.i18n.t("feedback:label-score")}</label>
                                                  <Form.Field className={"star"}>
                                                      <Rating
                                                        emptySymbol={"far fa-star"}
                                                        fullSymbol={"fas fa-star"}
                                                        stop={10}
                                                        initialRating={this.state.rating}
                                                        onChange={(rate) => {
                                                            hycon.debug(`${thisRef.constructor.name} onChange`, {rate});
                                                            return thisRef.setStateProperty("rating", rate);
                                                        }}
                                                      />
                                                      {/*<Rating
                                                        icon='star'
                                                        defaultRating={thisRef.state.rating}
                                                        maxRating={10}
                                                        onRate={(event, data) => {
                                                            hycon.debug(`${thisRef.constructor.name} onRate`, {event, data});
                                                            return thisRef.setStateProperty("rating", data.rating);
                                                        }}
                                                      />*/}
                                                  </Form.Field>
                                                  <Form.Field>
                                                      <label>{thisRef.props.i18n.t("feedback:label-what-panels")}</label>
                                                      <Form.TextArea
                                                        id={`form-input-control-${"feedbackDetail"}`}
                                                        onChange={(event, data) => {
                                                            hycon.debug(`${thisRef.constructor.name} onChange`, {
                                                                event,
                                                                data
                                                            });
                                                            return thisRef.setStateProperty("feedbackDetail", data.value);
                                                        }}
                                                        placeholder={thisRef.props.i18n.t("feedback:label-feedback")}
                                                      />
                                                  </Form.Field>
                                                  <Form.Field>
                                                      <label>{thisRef.props.i18n.t("feedback:label-generic-notes")}</label>
                                                      <Form.TextArea
                                                        id={`form-input-control-${"feedback"}`}
                                                        onChange={(event, data) => {
                                                            hycon.debug(`${thisRef.constructor.name} onChange`, {
                                                                event,
                                                                data
                                                            });
                                                            return thisRef.setStateProperty("feedback", data.value);
                                                        }}
                                                        placeholder={"Feedback"}
                                                      />
                                                  </Form.Field>
                                                  <Form.Field className={"submit-button"}>
                                                      <Button
                                                        primary
                                                        id='form-button-control-send'
                                                      >
                                                          {thisRef.props.i18n.t("feedback:button-send")}
                                                      </Button>
                                                  </Form.Field>
                                              </Form>
                                          </div>
                                        );
                                    }
                                })()}
                            </div>
                        </div>
                    )}
                />
            </div>
        );
    }

    componentDidUpdate() {

        hycon.debug(`${this.constructor.name} componentDidUpdate`, {props: this.props});
    }

    componentWillUnmount() {

        hycon.debug(`${this.constructor.name} componentWillUnmount`, {props: this.props});
    }
}

export default withTranslation([
  "feedback"
])(Feedback);

