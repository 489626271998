/*
 * action types
 */

export const UPDATE_REDUX_STATE = 'UPDATE_REDUX_STATE'
export const UPDATE_I18N = 'UPDATE_I18N'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_MAP_EVENTS = 'UPDATE_MAP_EVENTS'
export const UPDATE_USER_POSITION = 'UPDATE_USER_POSITION'
export const UPDATE_MAP_SELECTION = 'UPDATE_MAP_SELECTION'
export const UPDATE_SELECTED_BUILDINGS = 'UPDATE_SELECTED_BUILDINGS'
export const UPDATE_MULTI_MAP_EVENTS = 'UPDATE_MULTI_MAP_EVENTS'
export const UPDATE_MAP_OBJECTS = 'UPDATE_MAP_OBJECTS'
export const UPDATE_SESSION = 'UPDATE_SESSION'

/*
 * action creators
 */

export function updateSession (newState) {
  return { type: UPDATE_SESSION, newState }
}

export function updateReduxState (newState) {
  return { type: UPDATE_REDUX_STATE, newState }
}

export function updateI18N (newState) {
  return { type: UPDATE_I18N, newState }
}

export function updateUser (newState) {
  return { type: UPDATE_USER, newState }
}

export function updateMapEvents (newState) {
  return { type: UPDATE_MAP_EVENTS, newState }
}

export function updateUserPosition (newState) {
  return { type: UPDATE_USER_POSITION, newState }
}

export function updateMapSelection (newState) {
  return { type: UPDATE_MAP_SELECTION, newState }
}

export function updateSelectedBuildings (newState) {
  return { type: UPDATE_SELECTED_BUILDINGS, newState }
}

export function updateMultiMapEvents (newState) {
  return { type: UPDATE_MULTI_MAP_EVENTS, newState }
}

export function updateMapObjects (newState) {
  return { type: UPDATE_MAP_OBJECTS, newState }
}
