import axios from "axios";
import {HIGH_ZOOM_LEVEL} from "./ConstructionZone";
import HyperConsole from "../../../../hyper_console/hyper-console";
import {UserUtil} from "../../../../user_management/util/user-util";
import moment from "moment";
let hycon = new HyperConsole({isEnabled: false, name:__filename}).myConsole;
export default class RequestHandler {
    constructor() {
        this.FN_NAME = "RequestHandler"
    }

    isHighZoom(map) {
        let zoom = map.getZoom();
        // let bounds = map.getBounds();
        // let center = map.getCenter();
        return zoom >= HIGH_ZOOM_LEVEL;
    }

    getBoundsGeometry(map) {
        // let zoom = map.getZoom();
        let bounds = map.getBounds();
        // let center = map.getCenter();
        // let 46.000 lng
        // let 7 lat
        let ne_lat = bounds._northEast.lat;
        let ne_lng = bounds._northEast.lng;

        let sw_lat = bounds._southWest.lat;
        let sw_lng = bounds._southWest.lng;

        return [[ne_lng,ne_lat], [sw_lng, ne_lat], [sw_lng, sw_lat], [ne_lng, sw_lat], [ne_lng,ne_lat]];
    }

    filterByCategory(ctx, response){
        hycon.debug(`${this.FN_NAME} filterByCategory`, {ctx, response});
        let settings = ctx.state.constructions.settings;
        let selectedOptionIndexes = settings.selectedOptionIndexes;
        let options = settings.options;
        // let isSetByUser = settings.isSetByUser;

        let values = [];
        selectedOptionIndexes.forEach((element) => {
            values.push(options[element].value);
        });

        let filteredData = [];
        let original = [];
        response.data.forEach((construction) => {
            original.push(construction);

            if(values.indexOf(ctx.props.i18n.t(`panel_construction_zone:${construction.projectMaincategoryLocize}`)) !== -1){
                filteredData.push(construction);
            }
        });
        hycon.debug(`${this.FN_NAME} filterByCategory`, {original, filteredData});

        let enhancedResponse = Object.assign({}, response);
        enhancedResponse.data = filteredData;
        return enhancedResponse;
    }

    getConstructionZonesCategories(ctx, map) {
        // let url = `${ctx.props.reduxState.env.API_GATEWAY_BASE}/api/buildingapplications-by-polygon`;
        let url = `${ctx.props.reduxState.env.API_GATEWAY_BASE}/api/buildingapplication-categories-locize`;
        let data = {
            "geom": [this.getBoundsGeometry(map)],
            "srid": 4326
        };

        let jwt = ctx.props.reduxState.user.jwt;
        return axios(
            {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': "application/json",
                    'Authorization': `Bearer ${jwt}`
                },
                data: JSON.stringify(data)
            }
        )
            .then((response) => {
                hycon.debug(`${this.FN_NAME} getConstructionZonesCategories response `, {response});
                try {
                    let options = [];
                    //options.push({key: `error0`, text: `error0`, value: `error0`});
                    //options.push({key: `error1`, text: `error1`, value: `error1`});

                    response.data.forEach((category, index)=>{
                        options.push({
                            key: index,
                            text: ctx.props.i18n.t(`panel_construction_zone:${category.locizeId}`),/*ctx.props.i18n.t(`panel_construction_zone:${category.locizeId}`)*/
                            value: ctx.props.i18n.t(`panel_construction_zone:${category.locizeId}`)
                        });
                        // options.push({key: `key${index}`, text: `${category.name}`, value: `${category.name}`})
                    });

                    hycon.warn(`${this.FN_NAME} getConstructionZonesCategories error`, {options});
                    return options;
                } catch (e) {
                    let options = [];
                    options.push({key: `error00`, text: `error00`, value: `error00`});
                    options.push({key: `error11`, text: `error11`, value: `error11`});
                    hycon.warn(`${this.FN_NAME} getConstructionZonesCategories error`, {e, options});
                    return options;
                }
/*                let options = [
                    {key: 'angular', text: 'Angular', value: 'angular'},
                    {key: 'css', text: 'CSS', value: 'css'},
                    {key: 'design', text: 'Graphic Design', value: 'design'},
                    {key: 'ember', text: 'Ember', value: 'ember'},
                    {key: 'html', text: 'HTML', value: 'html'}
                ];
                return options;*/
            })
            .catch((error) => {
                UserUtil.authenticationRedirect(error.response, ctx);
                hycon.error(error);
            });
    }

    getConstructionZones = (ctx, map) => {
        let thisRef = this;
        let fromDate = ctx.state.appsettings.profiles.default.panels.construction_zone.constructions.settings.fromDate;
        let date = moment(fromDate, "YYYY-MM-DD");
        let formattedDate = date.format("YYYY,MM,DD");
        let url = `${ctx.props.reduxState.env.API_GATEWAY_BASE}/api/buildingapplications-by-polygon?precision=[gt]6&lastupdated=[ge]datetime(${formattedDate})`;
        let data = {
            "geom": [this.getBoundsGeometry(map)],
            "srid": 4326
        };
        let jwt = ctx.props.reduxState.user.jwt;
        return axios(
            {
                method: 'post',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': "application/json",
                    'Authorization': `Bearer ${jwt}`
                },
                data: JSON.stringify(data)
            }
        )
            .then((response) => {
                hycon.debug(`${this.FN_NAME} getConstructionZones response `, {response});
                let filteredResponse = thisRef.filterByCategory(ctx, response);
                let enhancedResponse = Object.assign({}, response);
                enhancedResponse.filteredData = filteredResponse.data;
                return enhancedResponse;
            })
            .catch((error) => {
                hycon.log(error);
                UserUtil.authenticationRedirect(error.response, ctx);
            });
    };
}


