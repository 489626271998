// eslint-disable-next-line no-unused-vars
import React, {  useContext, useEffect } from 'react'
import HyperConsole from '../hyper_console/hyper-console'
import axios from 'axios'


const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

// const LOCIZE_PANEL_NS = "kibana_events";
const requests = {
  postLoginEvent: async function postLoginEvent(jwt, env, eventInfo){
    return axios(
      {
        method: 'post',
        url: `${env.API_GATEWAY_BASE}/api/userevent/login?eventinfo=${encodeURIComponent(JSON.stringify(eventInfo || {}))}`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      }
    )
  },
  postBuildingSelectionEvent: async function postBuildingSelectionEvent(jwt, env, eventInfo){
    return axios(
      {
        method: 'post',
        url: `${env.API_GATEWAY_BASE}/api/userevent/selection?eventinfo=${encodeURIComponent(JSON.stringify(eventInfo || {}))}`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      }
    )
  },
  postPanelDetailEvent: async function postPanelDetailEvent(jwt, env, eventInfo){
    return axios(
      {
        method: 'post',
        url: `${env.API_GATEWAY_BASE}/api/userevent/paneldetail?eventinfo=${encodeURIComponent(JSON.stringify(eventInfo || {}))}`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      }
    )
  },
  postMaketSenseDetailEvent: async function postMaketSenseDetailEvent(jwt, env, eventInfo){
    return axios(
      {
        method: 'post',
        url: `${env.API_GATEWAY_BASE}/api/userevent/marketsensequery?eventinfo=${encodeURIComponent(JSON.stringify(eventInfo || {}))}`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      }
    )
  },
  postEnergySimulationEvent: async function postEnergySimulationEvent(jwt, env, eventInfo){
    return axios(
      {
        method: 'post',
        url: `${env.API_GATEWAY_BASE}/api/userevent/energysimulation?eventinfo=${encodeURIComponent(JSON.stringify(eventInfo || {}))}`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      }
    )
  },
  postKBDMQueryEvent: async function postKBDMQueryEvent(jwt, env, eventInfo){
    return axios(
      {
        method: 'post',
        url: `${env.API_GATEWAY_BASE}/api/userevent/kbdmquery?eventinfo=${encodeURIComponent(JSON.stringify(eventInfo || {}))}`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      }
    )
  }
}
export default function KibanaEvents(props){
  // const fnName = `KibanaEvents`
  // const context = useContext(MarketsenseContext)
  // const { t, i18n } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: true })
  const env = props.reduxState.env
  const jwt = props.reduxState.user.jwt
  useEffect(() => {
    hycon.debug("starting the kibana event listener", {
      jwt, env
    });
    async function onKibanaEvent(event){
      hycon.debug("got kibana event", event);
      async function postEvent(){
        if(event?.detail?.type === "app-opened"){
          requests.postLoginEvent(jwt, env, event?.detail?.eventInfo)
        } else if(event?.detail?.type === "building-selection"){
          requests.postBuildingSelectionEvent(jwt, env, event?.detail?.eventInfo)
        } else if(event?.detail?.type === "panel-detail"){
          requests.postPanelDetailEvent(jwt, env, event?.detail?.eventInfo)
        } else if(event?.detail?.type === "marketsense-detail"){
          requests.postMaketSenseDetailEvent(jwt, env, event?.detail?.eventInfo)
        } else if(event?.detail?.type === "energy-simulation"){
          requests.postEnergySimulationEvent(jwt, env, event?.detail?.eventInfo)
        } else if(event?.detail?.type === "kbdm-query"){
          requests.postKBDMQueryEvent(jwt, env, event?.detail?.eventInfo)
        }
      }
      await postEvent();
    }
    window.addEventListener("kibana-event", onKibanaEvent);

    if(env && jwt){
      const event = new CustomEvent('kibana-event', { detail: {
          type: "app-opened",
          eventInfo: {
            info: "User opened the app and logged in."
          }
        }
      });
      window.dispatchEvent(event);
    }

    return () => {
      hycon.debug("removing the kibana event listener");
      window.removeEventListener("kibana-event", onKibanaEvent);
    }
  }, [env, jwt])
  return null;
}
