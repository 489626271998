import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { LOCIZE_PANEL_NS, REACT_APP_GI_ENV, reducerActions } from '../../constants';
import { useFavoriteCtx } from '../../FavoriteProvider';
import HyperConsole from '../../../hyper_console/hyper-console';

import './FavoriteSelectionBox.css';

let hycon = null;
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
}

export default function FavoriteSelectionBox() {
  const hookName = 'FavoriteSelectionBox';
  const favoriteContext = useFavoriteCtx();
  const { t } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false });
  const [selectedFavorites, setSelectedFavorites] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('');
  const [selectionUniqueGroups, setSelectionUniqueGroups] = useState({
    uniqueAddressGroup: [], uniqueRegionGroup: [], mergedUniqueGroup: [],
  });
  const [allUniqueGroups, setAllUniqueGroups] = useState({
    uniqueAddressGroup: [], uniqueRegionGroup: [], mergedUniqueGroup: [],
  });

  useEffect(() => {
    const getUniqueGroups = (favoritesArray) => {
      // filter the recipients to an array containing only 1 recipient per favoriteType and group
      const uniqueAddressGroup = favoritesArray
        .filter((favorite) => favorite.favoriteType === favoriteContext.favoriteTypes.ADDRESS)
        .reduce((acc, current) => {
          if (!acc.includes(current.group)) {
            return [...acc, current.group];
          }
          return acc;
        }, []);
      const uniqueRegionGroup = favoritesArray
        .filter((favorite) => favorite.favoriteType === favoriteContext.favoriteTypes.REGION)
        .reduce((acc, current) => {
          if (!acc.includes(current.group)) {
            return [...acc, current.group];
          }
          return acc;
        }, []);
      const mergedUniqueGroup = [...uniqueAddressGroup, ...uniqueRegionGroup]
        .reduce((acc, current) => {
          if (!acc.includes(current)) {
            return [...acc, current];
          }
          return acc;
        }, []);
      hycon.debug(`${hookName} getUniqueGroups`, {
        uniqueRegionGroup,
        uniqueAddressGroup,
        mergedUniqueGroup,
      });
      return { uniqueAddressGroup, uniqueRegionGroup, mergedUniqueGroup };
    };
    const currentSelectedFavorites = (favoriteContext.favorites || [])
      .filter((favorite) => (favoriteContext.selection || []).includes(favorite.id));
    const currentUniqueGroups = getUniqueGroups(currentSelectedFavorites || []);
    hycon.debug(`${hookName} useEffect - favoriteContext`, {
      currentUniqueGroups,
      currentSelectedFavorites,
    });
    const currentAllUniqueGroups = getUniqueGroups((favoriteContext.favorites || []));
    setSelectionUniqueGroups(currentUniqueGroups);
    setSelectedFavorites(currentSelectedFavorites);
    setAllUniqueGroups(currentAllUniqueGroups);
    if (currentSelectedFavorites.length === 0) {
      setValue('');
    }
  }, [favoriteContext]);

  const getSelectionText = () => {
    hycon.debug(`${hookName} getSelectionInfoComponent`, {
      selectionUniqueGroups,
    });
    const hasSelection = selectedFavorites.length > 0;
    const isHomogen = selectionUniqueGroups.mergedUniqueGroup.length <= 1;
    const hasSelectedAllOfGroup = (groupname) => {
      const favoritesByGroup = (favoriteContext.favorites || [])
        .filter((favorite) => favorite.group === groupname);
      return selectedFavorites.length === favoritesByGroup.length && hasSelection;
    };
    let selectionText = '';
    if (selectedFavorites.length === 0) {
      selectionText = `${t('favorites:selectbox-none')}`;
    } else if (isHomogen && hasSelectedAllOfGroup(selectionUniqueGroups.mergedUniqueGroup[0])) {
      selectionText = `(${selectedFavorites.length}) ${t('favorites:selectbox-all-of-group')} ${selectionUniqueGroups.mergedUniqueGroup[0]}`;
    } else if (isHomogen && !hasSelectedAllOfGroup(selectionUniqueGroups.mergedUniqueGroup[0])) {
      selectionText = `(${selectedFavorites.length}) ${t('favorites:selectbox-some-of-group')} ${selectionUniqueGroups.mergedUniqueGroup[0]}`;
    } else {
      selectionText = `(${selectedFavorites.length}) ${t('favorites:selectbox-mixed')}`;
    }
    return selectionText;
  };

  const getGroupDropdownComponent = () => {
    const options = allUniqueGroups.mergedUniqueGroup.map((groupname, groupnameIndex) => ({
      key: groupnameIndex, text: groupname, value: groupname,
    }));
    return (
      <Dropdown
        value={value}
        searchInput={{ autoFocus: false, autoComplete: 'searchbox-group' }}
        options={[...options, ...[
          {
            key: 'all', text: t('favorites:selectbox-group-all'), value: 'all',
          },
          {
            key: 'none', text: t('favorites:selectbox-group-none'), value: 'none',
          },
          {
            key: 'filter', text: t('favorites:selectbox-filter'), value: 'filter',
          },
        ]]}
        placeholder={`${t('favorites:searchbox-placeholder')}`}
        search
        selection
        labeled
        text={getSelectionText()}
        onBlur={(event, data) => {
          hycon.debug(`${hookName} onBlur`, { event, data });
          setIsOpen(false);
        }}
        onChange={(event, data) => {
          const { value } = data;
          hycon.debug(`${hookName} onChange`, {
            value, event, data, isOpen,
          });
          if (value === 'all') {
            const newSelection = favoriteContext.favorites
              .map((favorite) => favorite.id);
            favoriteContext.dispatch({
              type: reducerActions.REPLACE,
              info: 'onChange',
              payload: {
                newState: {
                  selection: newSelection,
                },
              },
            });
          } else if (value === 'none') {
            favoriteContext.dispatch({
              type: reducerActions.REPLACE,
              info: 'onChange',
              payload: {
                newState: {
                  selection: [],
                },
              },
            });
          } else if (value === 'filter') {
            const newSelection = (favoriteContext.filterFavorites || [])
              .map((favorite) => favorite.id);
            favoriteContext.dispatch({
              type: reducerActions.REPLACE,
              info: 'onChange',
              payload: {
                newState: {
                  selection: newSelection,
                },
              },
            });
          } else {
            const newSelection = favoriteContext.favorites
              .filter((favorite) => favorite.group === value)
              .map((favorite) => favorite.id);
            favoriteContext.dispatch({
              type: reducerActions.REPLACE,
              info: 'onChange',
              payload: {
                newState: {
                  selection: newSelection,
                },
              },
            });
          }
          setValue(value);
          setIsOpen(false);
        }}
      />
    );
  };
  return (
    <div className={hookName}>
      <div className="group-select">
        {getGroupDropdownComponent()}
      </div>
    </div>
  );
}
