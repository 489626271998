import React from 'react';

export const FavoriteContext = React.createContext(null);

// TODO: export default function FavoriteProvider({children}) {}

export const useFavoriteCtx = () => {
  const ctx = React.useContext(FavoriteContext);

  if (!ctx) {
    throw new Error('useFavoriteProvider must be used within the FavoriteProvider');
  }

  return ctx;
};
