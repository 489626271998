import React, { useState, useEffect, useReducer, useContext } from 'react'
import HyperConsole from '../../../../../hyper_console/hyper-console'

import {
  Button,
  Loader,
  Icon,
} from 'semantic-ui-react'

import './PublicEnergyProject.css'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import env from '../../../../../../env/env'
import moment from 'moment'
import Leaflet from 'leaflet'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

const NAVIGATION = {
  OVERVIEW: { locationName: 'OVERVIEW', state: null },
  DETAIL: { locationName: 'DETAIL', state: null }
}

const defaultContext = {
  focusedProjects: []
}
const PublicEnergyProjectContext = React.createContext(defaultContext)

class ContextMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  componentDidMount () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} componentDidMount `, { props: thisRef.props, state: thisRef.state })
  }

  translate (label, ns = 'panel_energy_project') {
    let thisRef = this
    let translation = thisRef.props.i18n.t(`${ns}:${label}`)
    if (
      translation && translation !== label
    ) {
      return translation
    } else {
      return `${label}`
      // return `${ns}:${label}`;
    }
  };

  toggleIsOpen () {
    this.setState((state) => {
      return { ...state, isOpen: !state.isOpen }
    })
  }

  setIsOpen (isOpen) {
    this.setState((state) => {
      return { ...state, isOpen }
    })
  }

  getMenuItemsStyle () {
    let menuItemsStyle = {}
    let controlsComponent = document.querySelectorAll('.context-menu .controls')[0]
    if (controlsComponent) {
      let buttonHeight = controlsComponent.clientHeight
      menuItemsStyle = {
        position: 'absolute',
        top: `${buttonHeight}px`,
        right: 0,
        zIndex: 1
      }
    }
    return menuItemsStyle
  }

  getContextMenuItems () {
    let thisRef = this

    let buttons = []

    if (thisRef.props.locationName === NAVIGATION.OVERVIEW.locationName) {
      /*buttons.push(
        <Button
          key={1}
          size={'medium'}
          onClick={async () => {
            hycon.debug(`${thisRef.constructor.name} onClick`)
            thisRef.setIsOpen(false)
            await thisRef.props.onNavigation({ locationName: NAVIGATION.DETAIL.locationName, state: null })
          }}
        >
          {this.translate('detail')}
        </Button>
      )*/
    }

    let contextMenuItems = (
      <div className={'menu-items-wrapper'}>
        {buttons}
      </div>
    )
    return contextMenuItems
  }

  getContextItems () {
    let thisRef = this
    // hycon.debug(`${this.constructor.name} getContextItems`, {thisRef});
    let lastNavigationObject = thisRef.props.getLastNavigationObject()
    let displayControls = () => {
      if (thisRef.state.isOpen) {
        return (
          <div className={'items-container'} style={thisRef.getMenuItemsStyle()}>
            {thisRef.getContextMenuItems()}
          </div>
        )
      } else {
        return (
          <div className={'items-container'} style={thisRef.getMenuItemsStyle()}>

          </div>
        )
      }
    }
    let getControls = () => {
      let controls = null
      if (
        lastNavigationObject.locationName === NAVIGATION.OVERVIEW.locationName
      ) {
        controls = (
          <div className={'controls'}>
            {/*<Button
              className={'settings'}
              icon
              size={'large'}
              active={thisRef.state.isOpen}
              onClick={async () => {
                hycon.debug(`${thisRef.constructor.name} onClick`)
                thisRef.toggleIsOpen()
              }}
              onBlur={(event) => {
                event.persist()
                hycon.debug(`${thisRef.constructor.name} onBlur`, { event })
                if (
                  !document.querySelectorAll('.menu-items-wrapper')[0].contains(event.relatedTarget)
                ) {
                  thisRef.setIsOpen(false)
                } else {
                  event.preventDefault()
                }
              }}
            >
              <i className="fas fa-ellipsis-v"/>
            </Button>*/}
            {displayControls()}
          </div>
        )
      } else {
        controls = (
          <div className={'controls'}>
            {/*<Button
              className={'settings'}
              icon
              size={'large'}
              active={thisRef.state.isOpen}
              onClick={async () => {
                hycon.debug(`${thisRef.constructor.name} onClick`)
                thisRef.toggleIsOpen()
              }}
              onBlur={(event) => {
                event.persist()
                hycon.debug(`${thisRef.constructor.name} onBlur`, { event })
                if (
                  !document.querySelectorAll('.menu-items-wrapper')[0].contains(event.relatedTarget)
                ) {
                  thisRef.setIsOpen(false)
                } else {
                  event.preventDefault()
                }
              }}
            >
              <i className="fas fa-ellipsis-v"/>
            </Button>*/}
            <Button
              icon
              size={'large'}
              onClick={async () => {
                hycon.debug(`${thisRef.constructor.name} onClick`)
                await thisRef.props.onNavigation({ locationName: NAVIGATION.OVERVIEW.locationName, state: null })
              }}
            >
              <Icon name='close'/>
            </Button>
            {/*{displayControls()}*/}
          </div>
        )
      }
      return controls
    }
    let baseContextMenu = (
      <div className={'context-menu'}>
        <div className={'title'}>
          <h4>{thisRef.translate('panel-name')}</h4>
        </div>
        {getControls()}
      </div>
    )
    return baseContextMenu
  }

  render () {
    let thisRef = this
    let contextItems = thisRef.getContextItems()
    return contextItems
  }
}

function ProjectCard (props) {
  const context = useContext(PublicEnergyProjectContext)
  let fnName = 'ProjectCard'
  let project = props.project
  let map = props.reduxState.map.map
  let defaultImage = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Energieprojekt.png`

  let bildUrl = defaultImage
  if (project.bildUrl) {
    bildUrl = project.bildUrl + `?jwt=${props.SEPContext.user.publicJWT}`
  }

  useEffect(() => {
    hycon.debug(`${fnName} - useEffect - context`, {
      context, props
    })
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [context])

  // let [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={`public-card ${context.focusedProjects.filter((fp) => {return fp.id === project.id}).length > 0 ? 'focused' : ''}`
      + ` project-${project.id}`}
    >
      <div
        className={'image-container'}
        onClick={() => {
          let navigationObject = { locationName: NAVIGATION.DETAIL.locationName, state: { project } }
          hycon.debug(`${fnName} onClick`, { project, navigationObject })
          props.onCardDetailClick(navigationObject)
        }}
      >
        <div
          className={'image'}
          style={{
            backgroundImage: `url('${bildUrl}')`
          }}
        />
      </div>
      <div
        className={'controls'}
      >
        <div><b>{project.projektName}</b></div>
        <i
          className={'fas fa-crosshairs'}
          onClick={() => {
            hycon.debug(`${fnName} onClick`, { project, props })
            map.setView([project.lat, project.long], 18, {
              animate: true
            })
          }}
        />
        <i
          style={{
            fontSize: '1.2em'
          }}
          className={`fas fa-eye`}
          onClick={() => {
            hycon.debug(`${fnName} - onEyeClick`, { project, context })
            let newFocusedProjects
            if (context.focusedProjects.filter((fp) => {return fp.id === project.id}).length > 0) {
              newFocusedProjects = []
            } else {
              newFocusedProjects = [project]
            }
            context.dispatch(
              {
                type: context.actions.REPLACE,
                info: `${fnName} - dispatch fn injection`,
                payload: {
                  focusedProjects: newFocusedProjects
                }
              }
            )
          }}
        />
      </div>
    </div>
  )
}

function ProjectListComponent (props) {
  const fnName = 'ProjectListComponent'
  const context = useContext(PublicEnergyProjectContext)
  let projects = props.projects
  useEffect(() => {
    hycon.debug(`${fnName} - useEffect - context`, {
      context, props
    })
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [context])

  let projectComponents = projects.map((project, index) => {
    return (
      <ProjectCard
        {...props}
        key={index}
        project={project}
      />
    )
  })
  return (
    <div className={'card-container-absolute'}>
      <div className={'card-container'}>
        {projectComponents.length > 0 ? projectComponents : (
          <div className={'message'}>{props.translate('message-no-public-projects-visible')}</div>
        )}
        {props.renderLoader()}
      </div>
    </div>
  )
}

function PublicEnergyProject (props) {
  let fnName = 'PublicEnergyProject'
  let [isLoading, setIsLoading] = useState(false)
  let [navigationStack, setNavigationStack] = useState([])
  let [projects, setProjects] = useState([])
  let [projectMarkers, setProjectMarkers] = useState([])

  const actions = {
    REPLACE: 'REPLACE'
  }
  const reducer = (state, action) => {
    const fnName = 'reducer'
    // hycon.debug(`${fnName}`, { info: action.info, state, action })
    if (action.type === actions.REPLACE) {
      let newState = { ...state, ...action.payload }
      // hycon.debug(`${fnName}`, { newState, action })
      return newState
    } else {
      hycon.error(`${fnName} unsupported reducer action`, { state, action })
      throw new Error(`${fnName} unsupported reducer action`, { state, action })
    }
  }
  const [contextState, dispatch] = useReducer(reducer, defaultContext)

  useEffect(() => {
    // hycon.debug(`${fnName} - useEffect - constructor`, { props })
    dispatch(
      {
        type: actions.REPLACE,
        info: `${fnName} - dispatch fn injection`,
        payload: {
          actions,
          dispatch,
          focusedProjects: []
        }
      }
    )
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // hycon.debug(`${fnName} useEffect - language`, { lang: props.i18n.language, props })
    moment.locale(props.i18n.language.toLowerCase())
  }, [props.i18n.language])

  useEffect(() => {
    let newNavigationStack = navigationStack.concat([{
      locationName: NAVIGATION.OVERVIEW.locationName,
      state: null
    }])
    setNavigationStack(newNavigationStack)
    return () => {
      // cleanup
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      props.reduxState &&
      props.reduxState.map &&
      props.reduxState.map.map
    ) {
      const map = props.reduxState.map.map
      const onMoveZoom = (e) => {
        // hycon.debug(`${fnName} onMoveZoom`, { e })
        updateProjects()
      }
      map.on(`moveend zoomend`, onMoveZoom)
      updateProjects()
      return () => {
        // cleanup
        map.off(`moveend zoomend`, onMoveZoom)
      }
    }
    // eslint-disable-next-line
  }, [props.reduxState.map.map])

  useEffect(() => {
    if (
      props.reduxState &&
      props.reduxState.map &&
      props.reduxState.map.map
    ) {
      // hycon.debug(`${fnName} useEffect - projectMarkers - add to map`, { projectMarkers })
      projectMarkers.forEach((projectMarker) => {
        projectMarker.addTo(props.reduxState.map.map)
      })
    }
    return () => {
      // cleanup
      removeProjectMarkers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectMarkers])

  useEffect(() => {
    if (
      props.reduxState &&
      props.reduxState.map &&
      props.reduxState.map.map
    ) {
      // hycon.debug(`${fnName} useEffect - updateProjectMarkers`, { projectMarkers })
      updateProjectMarkers()
    }
    return () => {
      // cleanup
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, contextState.focusedProjects])

  const removeProjectMarkers = () => {
    let map = props.reduxState.map.map
    hycon.debug(`${fnName} removeProjectMarkersFromMap`, { projectMarkers })
    projectMarkers.forEach((projectMarker) => {
      hycon.debug(`${fnName} removeProjectMarkersFromMap - removing`, { projectMarker })
      map.removeLayer(projectMarker)
    })
    hycon.debug(`${fnName} removeProjectMarkersFromMap - removed all projectMarkers`, { map })
  }

  const updateProjectMarkers = async () => {
    hycon.debug(`${fnName} updateProjectMarkers`, {})
    let L = props.reduxState.map.Leaflet
    let scale = 0.7
    let defaultIcon = Leaflet.icon({
      iconUrl: `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/panel_energy_project/icons/Pin-Energieprojekte-gruen.png`,
      /*shadowUrl: iconShadowUrl,*/
      iconSize: [55, 78].map(v => v * scale), // size of the icon
      /*shadowSize: [50, 64],*/ // size of the shadow
      iconAnchor: [22.5, 78].map(v => v * scale), // point of the icon which will correspond to marker's location
    })
    let alternativeIcon = Leaflet.icon({
      iconUrl: `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/panel_energy_project/icons/Pin-Energieprojekte-blau.png`,
      /*shadowUrl: iconShadowUrl,*/
      iconSize: [55, 78].map(v => v * scale), // size of the icon
      /*shadowSize: [50, 64],*/ // size of the shadow
      iconAnchor: [22.5, 78].map(v => v * scale), // point of the icon which will correspond to marker's location
    })

    let projectMarkers = projects.map((project) => {
      let icon = contextState.focusedProjects.filter((fp) => {return fp.id === project.id}).length > 0 ? alternativeIcon : defaultIcon
      let marker = L.marker([project.lat, project.long], {
        icon,
        /*draggable: true,*/
        /*autoPan: true*/
      }).on('click', () => {
        let newFocusedProjects
        if (contextState.focusedProjects.filter((fp) => {return fp.id === project.id}).length > 0) {
          newFocusedProjects = []
        } else {
          newFocusedProjects = [project]
        }
        contextState.dispatch(
          {
            type: actions.REPLACE,
            info: `${fnName} - dispatch fn injection`,
            payload: {
              focusedProjects: newFocusedProjects
            }
          }
        )
        try {
          document.querySelectorAll(`.project-${project.id}`)[0].scrollIntoView()
        } catch (e) {
          hycon.warn(`${fnName} - error scrolling into view`, e)
        }
        let navigationObject = { locationName: NAVIGATION.DETAIL.locationName, state: { project } }
        onNavigation(navigationObject)
      })
      marker.data = { project }
      return marker
    })

    projectMarkers = projectMarkers.map((marker) => {
      let isFocused = contextState.focusedProjects.filter((fp) => {return fp.id === marker.data.project.id}).length > 0
      // let newHighestZIndex = highestMarkerZIndex = Math.max(marker._zIndex, highestMarkerZIndex)
      hycon.debug(`${fnName} - updateProjectMarkers`, {
        isFocused,
        contextState
      })
      marker.setZIndexOffset(isFocused ? 1000 : 0);
      return marker;
    })
    setProjectMarkers(projectMarkers)
  }

  const getMapBounds = () => {
    const map = props.reduxState.map.map
    let bounds = map.getBounds()
    let ne_lat = bounds._northEast.lat
    let ne_lng = bounds._northEast.lng
    let sw_lat = bounds._southWest.lat
    let sw_lng = bounds._southWest.lng
    return [[ne_lng, ne_lat], [sw_lng, ne_lat], [sw_lng, sw_lat], [ne_lng, sw_lat], [ne_lng, ne_lat]]
  }

  const getProjectsByPolygon = async () => {
    let env = props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/energyprojects-by-polygon`
    let data = {
      'geom': [getMapBounds()],
      'srid': 4326
    }
    return await axios(
      {
        method: 'post',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${props.SEPContext.user.publicJWT}`
        },
        data: JSON.stringify(data)
      }
    )
  }

  const updateProjects = async () => {
    setIsLoading(true)
    let projectsResponse = await getProjectsByPolygon()
    setIsLoading(false)
    let newProjects = projectsResponse.data
    hycon.debug(`${fnName} updateProjects`, { newProjects })
    if (newProjects) {
      setProjects(newProjects)
    } else {
      setProjects([])
    }
  }

  const getOverviewComponent = () => {
    let overviewComponent = (
      <ProjectListComponent
        {...props}
        projects={projects}
        translate={translate}
        onCardDetailClick={(navigationObject) => {
          hycon.debug(`${fnName} onCardDetailClick`, { navigationObject })
          onNavigation(navigationObject)
        }}
        renderLoader={renderLoader}
      />
    )
    return overviewComponent
  }

  const getProjectDetailsComponent = () => {

    let navigationObject = getLastNavigationObject()

    let overviewComponent = null

    const getPrincipalsListItems = (project) => {
      let comps = []
      project.projectPrincipals.forEach((projectPrincipal, i) => {
        comps.push(
          <li key={i}>
            <span>{projectPrincipal.name}&nbsp;</span>
          </li>
        )
      })
      return comps
    }
    const getContractorsListItems = (project) => {
      let comps = []
      project.projectContractors.forEach((projectContractor, i) => {
        comps.push(
          <li key={i}>
            <span>{projectContractor.name}&nbsp;</span>
          </li>
        )
      })
      return comps
    }

    const getDescription = (project) => {
      if (
        project.beschreibung
      ) {
        return (
          <div className={'row'}>
            <div className={'description'}>
              {/*<span className={'label'}>{translate('edit-label-prokekt-beschreibung')}:&nbsp;</span>*/}
              <span>{project.beschreibung}</span>
            </div>
          </div>
        )
      }
    }
    const getCategory = (project) => {
      if (
        project.kategorie
      ) {
        return (
          <div className={'row'}>
            <div>
              <span className={'label'}>{translate('edit-label-projekt-kategorie')}:&nbsp;</span>
              <span>{translate(project.kategorie)}</span>
            </div>
          </div>
        )
      }
    }
    const getType = (project) => {
      if (
        project.typ
      ) {
        return (
          <div className={'row'}>
            <div>
              <span className={'label'}>{translate('edit-label-projekt-typ')}:&nbsp;</span>
              <span>{translate(project.typ)}</span>
            </div>
          </div>
        )
      }
    }
    const getEnergyInfo = (project) => {
      if (
        project.energyInfo.length > 0
      ) {
        let comps = []
        project.energyInfo.forEach((energyInfo, i) => {
          comps.push((
            <div className={'row'}>
              <span className={'label'}>{translate(energyInfo.art).split('***')[0]}:&nbsp;</span>
              <span>{energyInfo.menge.toLocaleString('de-CH')}&nbsp;</span>
              <span>{translate(energyInfo.art).split('***')[parseInt(energyInfo.einheit)]}&nbsp;</span>
            </div>
          ))
        })
        return comps
      }
    }
    const getPrincipals = (project) => {
      if (
        project.projectPrincipals.length > 0
      ) {
        return (
          <div className={'row'}>
            <span className={'label'}>{translate('edit-label-projekt-entwickler')}:&nbsp;</span>
            <ul>
              {getPrincipalsListItems(project)}
            </ul>
          </div>
        )
      }
    }
    const getContractors = (project) => {
      if (
        project.projectContractors.length > 0
      ) {
        return (
          <div className={'row'}>
            <span className={'label'}>{translate('edit-label-projekt-beteiligte')}:&nbsp;</span>
            <ul>
              {getContractorsListItems(project)}
            </ul>
          </div>
        )
      }
    }
    const getProjectStart = (project) => {
      let d = moment(project.projektStart)
      if (
        d.isValid()
      ) {
        return (
          <div className={'row'}>
            <div>
              <span className={'label'}>{translate('edit-label-projekt-zeitraum-von')}:&nbsp;</span>
              <span>
                      {(() => {
                        let d = moment(project.projektStart)
                        return d.format('DD. MMMM YYYY')
                      })()}
                    </span>
            </div>
          </div>
        )
      }
    }
    const getProjectEnd = (project) => {
      let d = moment(project.projektEnde)
      if (
        d.isValid()
      ) {
        return (
          <div className={'row'}>
            <div>
              <span className={'label'}>{translate('edit-label-projekt-zeitraum-bis')}:&nbsp;</span>
              <span>
                      {(() => {
                        let d = moment(project.projektEnde)
                        return d.format('DD. MMMM YYYY')
                      })()}
                    </span>
            </div>
          </div>
        )
      }
    }
    const getStatus = (project) => {
      if (
        project.projektStatus
      ) {
        return (
          <div className={'row'}>
            <div>
              <span className={'label'}>{translate('edit-label-projekt-status')}:&nbsp;</span>
              <span>{translate(project.projektStatus)}</span>
            </div>
          </div>
        )
      }
    }
    const getDownload = (project) => {
      if (
        project.dokumentUrl
      ) {
        return (
          <div className={'row'}>
            <div>
              <span className={'label'}>{translate('edit-label-projekt-daten')}:&nbsp;</span>
              <span>
                <div className={'download-link'}>
                {project.dokumentName}
                  <i
                    className="fas fa-download"
                    onClick={() => {
                      let bildUrl = project.dokumentUrl + `?jwt=${props.SEPContext.user.publicJWT}`
                      window.open(bildUrl, '_blank')
                    }}
                  />
              </div>
             </span>
            </div>
          </div>
        )
      }
    }

    const getDetail = () => {

      if (
        navigationObject &&
        navigationObject.state &&
        navigationObject.state.project
      ) {
        let project = navigationObject.state.project
        let defaultImage = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Energieprojekt.png`
        let bildUrl = defaultImage
        if (project.bildUrl) {
          bildUrl = project.bildUrl
        }
        overviewComponent = (
          <div className={'card-container-absolute'}>
            <div className={'card-container'}>
              <div className={'detail-container'}>
                <div className={'detail-section'}>
                  <div className={'row'}>
                    <div className={'title'}>
                      <h2>{project.projektName}</h2>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div
                      className={'image-container'}
                    >
                      <div
                        className={'image'}
                        style={{
                          backgroundImage: `url('${bildUrl}?jwt=${props.SEPContext.user.publicJWT}')`
                        }}
                      />
                    </div>
                  </div>
                  {getDescription(project)}
                  {getCategory(project)}
                  {getType(project)}
                  {getEnergyInfo(project)}
                  {getPrincipals(project)}
                  {getContractors(project)}
                  {getProjectStart(project)}
                  {getProjectEnd(project)}
                  {getStatus(project)}
                  {getDownload(project)}
                </div>
              </div>
              {renderLoader()}
            </div>
          </div>
        )
        return overviewComponent
      }
      return overviewComponent
    }
    return getDetail()
  }

  const translate = (label, ns = 'panel_energy_project') => {
    let translation = props.i18n.t(`${ns}:${label}`)
    if (
      translation && translation !== label
    ) {
      return translation
    } else {
      return `${label}`
      // return `${ns}:${label}`;
    }
  }

  const renderLoader = () => {
    let cardBody = document.querySelectorAll('.PublicEnergyProject .card-body')[0]
    let lastNavigationObject = getLastNavigationObject()

    let style = {}
    if (
      cardBody && lastNavigationObject.locationName !== NAVIGATION.OVERVIEW.locationName
    ) {
      let scrollHeight = cardBody.scrollHeight
      style.height = `${scrollHeight}px`
    }
    let loader = (
      <div className={'custom-panel-loader'} style={style}>
        <Loader size={`massive`}>Loading {translate('panel-name')}</Loader>
      </div>
    )
    if (isLoading) {
      return loader
    }
    return null
  }

  const onNavigation = async (navigationObject) => {
    hycon.debug(`${fnName} onNavigation`, { navigationObject })
    // update the navigation stack
    let newNavigationStack = navigationStack.concat([navigationObject])
    setNavigationStack(newNavigationStack)

    // hide or display the legacy panels based on the navigation
    if (
      navigationObject.locationName === NAVIGATION.DETAIL.locationName
    ) {

    } else {

    }
    contextState.dispatch(
      {
        type: actions.REPLACE,
        info: `${fnName} - dispatch fn injection`,
        payload: {
          focusedProjects: []
        }
      }
    )
  }

  const getLastNavigationObject = () => {
    let lastNavigationObject = navigationStack.slice(-1).pop()
    return lastNavigationObject
  }

  const renderNavigationStack = () => {
    let detailComponent = getProjectDetailsComponent()
    let overviewComponent = getOverviewComponent()

    let lastNavigationObject = getLastNavigationObject()
    if (lastNavigationObject.locationName === NAVIGATION.OVERVIEW.locationName) {
      return { mainComponent: overviewComponent }
    }
    if (lastNavigationObject.locationName === NAVIGATION.DETAIL.locationName) {
      // this component will occupy the full dashboard
      return { mainComponent: detailComponent }
    }

    return { mainComponent: null }
  }

  /*const setLegacyPanelsVisibility = (isVisible) => {
    hycon.log('setLegacyPanelsVisibility', { isVisible })

    // hide or show the other panels
    let legacyDashboardPanels = document.querySelectorAll('#overlay-dashboard-panels .flexitem')
    legacyDashboardPanels.forEach((e) => {
      // hycon.debug("setLegacyPanelsVisibility", {e, isVisible});
      if (isVisible) {
        try {
          e.style.display = 'flex'
        } catch (e) {
          hycon.error('setLegacyPanelsVisibility', e)
        }
      } else {
        try {
          e.style.display = 'none'
        } catch (e) {
          hycon.error('setLegacyPanelsVisibility', e)
        }
      }
    })
  }*/

  const render = () => {
    let lastNavigationObject = getLastNavigationObject()

    if (!lastNavigationObject) {
      // navigation stack is empty
      return null
    } else if (
      lastNavigationObject.locationName === NAVIGATION.DETAIL.locationName
    ) {
      // detail will be rendered in portal
      let cardComponent = (
        <div className={'Card PublicEnergyProject in-portal'}>
          <ContextMenu
            {...props}
            {
              ...{
                onNavigation: onNavigation,
                getLastNavigationObject: getLastNavigationObject
              }
            }
            locationName={lastNavigationObject.locationName}
          />
          {renderNavigationStack().mainComponent}
          <div className={'card-body-portal'}/>
        </div>
      )
      // render the component in the portal
      return cardComponent
    } else {
      // the rest will be rendered in cards
      return (
        <div className={'Card PublicEnergyProject'}>
          <ContextMenu
            {...props}
            {
              ...{
                onNavigation: onNavigation,
                getLastNavigationObject: getLastNavigationObject
              }
            }
            locationName={lastNavigationObject.locationName}
          />
          {renderNavigationStack().mainComponent}
          <div className={'card-body-portal'}/>
        </div>
      )
    }
  }
  return (
    <PublicEnergyProjectContext.Provider value={contextState}>
      {render()}
    </PublicEnergyProjectContext.Provider>
  )
}

export default withTranslation('panel_energy_project')(PublicEnergyProject)

