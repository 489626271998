import React, {useEffect, useState, useRef} from 'react'
import HyperConsole from '../../../../hyper_console/hyper-console'
import {useTranslation} from 'react-i18next'
import 'leaflet.heat/dist/leaflet-heat'

import {jsx, css} from '@emotion/react'
import {Virtuoso} from "react-virtuoso";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import {AccordionContent} from "semantic-ui-react";
import TableCell from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";


const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (
    REACT_APP_GI_ENV === 'development'
) {
    hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole
} else {
    hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole
}

let LOCIZE_PANEL_NS = 'panel_building_info'

const TableCellStyle = {minWidth: '110px', display: 'flex', flex: 1, wordBreak: 'break-word'}
const TableCellStyleValue = {minWidth: '110px', display: 'flex', flex: 2, wordBreak: 'break-word'}
const TableRowStyle = {display: 'flex', flex: 1, flexDirection: 'row'}
/*const StyledTooltip = withStyles({
    tooltipPlacementTop: {
        margin: '0px',
    },
    tooltipPlacementBottom: {
        margin: '0px',
    },
})(Tooltip)*/
/*const StyledTooltip = Tooltip;*/

export default function ApartmentViewer(props) {
    const fnName = 'ApartmentViewer'
    const {t} = useTranslation(LOCIZE_PANEL_NS, {useSuspense: false})
    const virtuoso = useRef(null);
    const [isEwidOpen, setIsEwidOpen] = useState(false);
    const [clientWidth, setClientWidth] = useState(document.body.clientWidth);
    const [filteredEwids, setFilteredEwids] = useState([]);

    useEffect(() => {
        hycon.debug(`${fnName} - useEffect - [props]`, {
            ewids: props.ewids
        })
        const filterEwids = (ewids) => {
            return ewids.filter(ewid => {
                if([
                    "3001",
                    "3002",
                    "3003",
                    "3004"
                ].includes(ewid.wohnungStatus)){
                    return true;
                }
                return false;
            })
        }
        setFilteredEwids(filterEwids(props.ewids))
    }, [props.ewids])

    useEffect(() => {
        function onResize() {
            let width = document.body.clientWidth;
            setClientWidth(width);
        }

        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, [])

    return (()=>{
        if(filteredEwids.length > 0){
            return (
                <Accordion
                    style={{
                        margin: "2px",
                        borderRadius: '0px'
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography variant={"body1"}>
                            <b>
                                {t(`label-anzahl-wohnungen`, {ns: LOCIZE_PANEL_NS})} {filteredEwids.length}
                            </b>
                        </Typography>
                    </AccordionSummary>
                    <AccordionContent>
                        <Virtuoso
                            ref={virtuoso}
                            data={filteredEwids}
                            style={{minHeight: (()=>{
                                    if(filteredEwids.length >= 5 || isEwidOpen){
                                        return `250px`
                                    } else {
                                        return `${52 * filteredEwids.length}px`;
                                    }
                                })(), height: '100%'}}
                            itemContent={(index) => {
                                return (
                                    <Accordion
                                        TransitionProps={{unmountOnExit: true}}
                                        key={`ewid_${index}`}
                                        style={{
                                            margin: "2px",
                                            borderRadius: '0px'
                                        }}
                                        onChange={(e, expanded) => {
                                            setIsEwidOpen(expanded);
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="marketsense-element"
                                        >
                                            <Typography variant={'body1'} style={{
                                                display: 'flex',
                                                flex: 1,
                                                justifyContent: 'left',
                                                alignItems: 'center'
                                            }}>
                                                <b>{t(`label-ewid`, {ns: LOCIZE_PANEL_NS})} {filteredEwids[index].ewid}</b>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionContent key={`ac_${index}`} style={{
                                            minHeight: "300px"
                                        }}>
                                            <TableContainer>
                                                <Table>
                                                    <TableBody>
                                                        {
                                                            Object.keys(filteredEwids[index])
                                                                .filter(key => key !== "ewid" && key !== "abbruchjahr")
                                                                .filter(key => filteredEwids[index][key] !== null)
                                                                .sort((a, b) => {
                                                                    let priority = [
                                                                        "flaeche",
                                                                        "anzahlZimmer",
                                                                        "stockwerk",
                                                                        "lage",
                                                                        "kueche",
                                                                        "wohnungStatus",
                                                                        "baujahr"
                                                                    ]
                                                                    if(priority.indexOf(a) < priority.indexOf(b)){
                                                                        return -1;
                                                                    } else if(priority.indexOf(a) > priority.indexOf(b)){
                                                                        return 1
                                                                    } else {
                                                                        return 0;
                                                                    }
                                                                })
                                                                .map((key, keyIndex) => {
                                                                    return (
                                                                        <Tooltip
                                                                            title={t(`label-ewid-${key}-tooltip`, {ns: LOCIZE_PANEL_NS, defaultValue: ""})}
                                                                            placement={clientWidth > 1150 ? 'left' : 'top'}
                                                                            arrow
                                                                            interactive={"true"}
                                                                            key={`ac_${index}_tooltip_${keyIndex}_${key}`}
                                                                        >
                                                                            <TableRow key={`tablerow_${key}_${keyIndex}`} style={TableRowStyle}>
                                                                                <React.Fragment>
                                                                                    <TableCell style={TableCellStyle}>
                                                                                        <b>{t(`label-ewid-${key}`, {ns: LOCIZE_PANEL_NS})}</b>
                                                                                    </TableCell>
                                                                                    <TableCell style={TableCellStyleValue}>
                                                                                        {(() => {
                                                                                            if(key === "baujahr" && filteredEwids[index][key] === 1999){
                                                                                                return (
                                                                                                    <div>
                                                                                                        {t(`label-ewid-baujahr-exception`, {ns: LOCIZE_PANEL_NS})}
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                            else if (key === "kueche") {
                                                                                                return (
                                                                                                    <div>
                                                                                                        {t(`label-ewid-${key}-${filteredEwids[index][key]}`, {ns: LOCIZE_PANEL_NS})}
                                                                                                    </div>
                                                                                                )
                                                                                            } else {
                                                                                                if(key === "stockwerk"){
                                                                                                    let firstDigits = parseInt(String(filteredEwids[index][key]).slice(0, 2));
                                                                                                    let lastDigits = parseInt(String(filteredEwids[index][key]).slice(-2));
                                                                                                    if(filteredEwids[index][key].toString() === "3100"){
                                                                                                        return t(`label-ewid-${key}-${filteredEwids[index][key]}`, {ns: LOCIZE_PANEL_NS})
                                                                                                    } else if(firstDigits === 31){
                                                                                                        return `${lastDigits}. ${t(`label-ewid-${key}-31xx`, {ns: LOCIZE_PANEL_NS})}`
                                                                                                    } else if(firstDigits === 34){
                                                                                                        return `${lastDigits}. ${t(`label-ewid-${key}-34xx`, {ns: LOCIZE_PANEL_NS})}`
                                                                                                    }
                                                                                                } else if(key === "wohnungStatus"){
                                                                                                    return t(`label-ewid-${key}-${filteredEwids[index][key]}`, {ns: LOCIZE_PANEL_NS});
                                                                                                } else {
                                                                                                    return filteredEwids[index][key]
                                                                                                }
                                                                                            }
                                                                                        })()}
                                                                                    </TableCell>
                                                                                </React.Fragment>
                                                                            </TableRow>
                                                                        </Tooltip>
                                                                    )
                                                                })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionContent>
                                    </Accordion>
                                )
                            }}
                        />
                    </AccordionContent>
                </Accordion>
            )
        } else {
            return (
                <div>
                    <Typography variant={"body1"}>
                        <b>
                            {t(`label-anzahl-wohnungen`, {ns: LOCIZE_PANEL_NS})}
                        </b>
                        &nbsp;{filteredEwids.length}
                    </Typography>
                </div>
            )
        }
    })();
}
