import React from 'react'
import HyperConsole from '../../../../hyper_console/hyper-console'

import {
  Loader,
  Dimmer,
  Input,
  Form,
  Radio
} from 'semantic-ui-react'

import './SolarElectricityCard.css'

import axios from 'axios'
import ReactDOM from 'react-dom'
import { panelController } from './panel-controller.js'
import RoofDetail from './RoofDetail'
import { UserUtil } from '../../../../user_management/util/user-util'
import { UserSettings } from '../../../../user_management/util/user-settings'
import { GoogleAnalytics } from '../../../../google_analytics/GoogleAnalytics'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

import {Button as MUIButton} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close'
import SettingsIcon from '@mui/icons-material/Settings';
import {WixUtil} from "../../../../../util/wix-util";

const RENDER_OVERVIEW = 'RENDER_OVERVIEW'
const RENDER_DETAIL = 'RENDER_DETAIL'
const RENDER_BEST_ROOF_DETAIL = 'RENDER_BEST_ROOF_DETAIL'
const RENDER_SETTINGS = 'RENDER_SETTINGS'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

const round = (input) => {
  if (input === null) return null
  return Math.round((input * 1000) / 1000)
}

const trans = (ctx, key) => {
  return ctx.props.i18n.t(key)
}

class SolarElectricityCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      settings: {
        correction: 70,
        roofs: {
          filter: {
            minRoofExtent: 5,
            minRoofClass: 2
          }
        }
      },
      buildingRoofs: [],
      isMounted: false,
      isLoading: false,
      rendering: RENDER_OVERVIEW,
      renderingData: null,
    }
    this.setIsLoading = this.setIsLoading.bind(this)

    hycon.debug(`${this.constructor.name} constructor`, { props: this.props })
  }

  componentDidMount () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} componentDidMount`, { props: this.props })
    thisRef.loadUserSettings().then(() => {
      panelController.updateStateProps({
        map: thisRef.props.reduxState.map,
        axios: axios,
        ctx: thisRef
      })
      panelController.initListeners(window)
      thisRef.setStateProperty('isMounted', true)
    })
  }

  loadUserSettings () {
    hycon.debug(`${this.constructor.name} loadUserSettings`, { state: this.state, props: this.props })
    let thisRef = this
    // appSettings.profiles.default.panels.solar_electricity.roofs.settings
    let appSettingsDefault = {
      profiles: {
        default: {
          panels: {
            solar_electricity: {
              roofs: {
                settings: {
                  correction: 70,
                  roofs: {
                    filter: {
                      minRoofExtent: 5,
                      minRoofClass: 2
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return thisRef.setIsLoading(true)
    .then(() => {
      // fetch the user settings
      hycon.debug(`${thisRef.constructor.name} loadUserSettings - email`, {
        thisRef,
        props: thisRef.props
      })
      let email = null;
      try{
        email = thisRef.props.reduxState.session.user.userName
      }catch (e){
        email = thisRef.props.reduxState.user.user.userName
      }
      return UserSettings.getSettings(email, thisRef).then((response) => {
        hycon.debug(`${thisRef.constructor.name} loadUserSettings`, {
          response,
          thisRef,
          props: thisRef.props
        })
        return response
      })
    })
    .then((settingsResponse) => {
      hycon.debug(`${thisRef.constructor.name} loadUserSettings settingsResponse`, { settingsResponse })
      let appSettings = null
      let appSettingsFromServer = null
      try {
        appSettings = JSON.parse(settingsResponse.data.appsettings)
        appSettingsFromServer = JSON.parse(settingsResponse.data.appsettings)
      } catch (e) {
        if (!settingsResponse.data.appsettings) {
          appSettings = null
        } else {
          hycon.error(`${thisRef.constructor.name} loadUserSettings settingsResponse - error`, { settingsResponse, e })
          console.error(e)
          throw e
        }
      }
      // merge the app settings
      if (
        !appSettings ||
        !appSettings.profiles ||
        !appSettings.profiles.default ||
        !appSettings.profiles.default.panels ||
        !appSettings.profiles.default.panels.solar_electricity
      ) {
        hycon.debug(`${thisRef.constructor.name} loadUserSettings settingsResponse - no appsettings, using defaults.`, { settingsResponse })
        appSettings = Object.assign({}, appSettingsDefault)
        return thisRef.setStateProperty('appsettings', appSettings)
        .then(() => {
          hycon.debug(`${thisRef.constructor.name} loadUserSettings settingsResponse - new state`, { state: thisRef.state })
          return thisRef.setStateProperty('settings', appSettingsDefault.profiles.default.panels.solar_electricity.roofs.settings)
          .then(() => {
            return thisRef.setIsLoading(false)
          })
        })
      } else {
        hycon.debug(`${thisRef.constructor.name} loadUserSettings settingsResponse - user has alredy some settings for this panel`, {
          settingsResponse,
          appSettingsFromServer,
          appSettings
        })
        return thisRef.setStateProperty('appsettings', appSettings)
        .then(() => {
          hycon.debug(`${thisRef.constructor.name} loadUserSettings settingsResponse - new state`, { state: thisRef.state })
          return thisRef.setStateProperty('settings', appSettings.profiles.default.panels.solar_electricity.roofs.settings)
          .then(() => {
            return thisRef.setIsLoading(false)
          })
        })
      }
    }).catch(e => {
      hycon.error(`${thisRef.constructor.name} loadUserSettings error`, {e, props: thisRef.props})
      thisRef.setIsLoading(false);
    })
  }

  updateUserSettings () {
    let thisRef = this
    let appsettings = thisRef.state.appsettings
    let settings = thisRef.state.settings
    let userId = null;
    try{
      userId = thisRef.props.reduxState.session.user.id
    }catch (e){
      userId = thisRef.props.reduxState.user.user.id
    }
    let getUpdatedAppsettingsFromServer = () => {
      let email = null;
      try{
        email = thisRef.props.reduxState.session.user.userName
      }catch (e){
        email = thisRef.props.reduxState.user.user.userName
      }
      return UserSettings.getSettings(email, thisRef).then((response) => {
        hycon.debug(`${thisRef.constructor.name} loadUserSettings`, {
          response,
          thisRef,
          props: thisRef.props
        })
        try {
          return JSON.parse(response.data.appsettings)
        } catch (e) {
          console.error(e)
          return null
        }
      })
    }

    // appSettings.profiles.default.panels.solar_electricity.roofs.settings
    let newAppSettings = Object.assign({}, appsettings)
    if (!newAppSettings || newAppSettings === {}) {
      hycon.info(`${thisRef.constructor.name} updateUserSettings - no appsettings need to be updated`, {
        newAppSettings
      })
      return
    }
    newAppSettings.profiles.default.panels.solar_electricity.roofs.settings = settings
    hycon.info(`${thisRef.constructor.name} updateUserSettings - pre`, {
      appsettings, newAppSettings, settings
    })
    newAppSettings.profiles.default.panels.solar_electricity.roofs.settings = settings
    hycon.info(`${thisRef.constructor.name} updateUserSettings`, {
      appsettings, newAppSettings, settings
    })

    return thisRef.setIsLoading(true)
    .then(() => {
      return getUpdatedAppsettingsFromServer()
    })
    .then((latestAppsettings) => {
      if (
        latestAppsettings && newAppSettings
      ) {
        hycon.info(`${thisRef.constructor.name} updateUserSettings - merging updates from server`, {
          latestAppsettings, newAppSettings
        })
        newAppSettings.profiles.default.panels = {
          ...latestAppsettings.profiles.default.panels,
          ...newAppSettings.profiles.default.panels
        }
        hycon.info(`${thisRef.constructor.name} updateUserSettings - merged updates from server`, {
          latestAppsettings, newAppSettings
        })
      }
      return UserSettings.saveSettings(userId, newAppSettings, thisRef)
      .then(() => {
        return thisRef.setStateProperty('appsettings', newAppSettings)
      })
      .then(() => {
        hycon.info(`${thisRef.constructor.name} updateUserSettings update - ok`, {
          appsettings, newAppSettings, settings
        })
        return thisRef.setIsLoading(false)
      })
    })
  }

  static getDerivedStateFromProps (props, state) {
    hycon.debug(`Card getDerivedStateFromProps`, { props, state })
    if (!state) {
      hycon.debug(`Card getDerivedStateFromProps - only props have been updated`, { props, state })
    }
    return state
  }

  shouldComponentUpdate (nextProps, nextState) {

    hycon.debug(`${this.constructor.name} shouldComponentUpdate`, { nextProps, props: this.props, nextState })
    return true
  }

  getOverview () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} getOverview`, { props: this.props })
    let selectedBuildings = thisRef.props.reduxState.selectedBuildings
    let nrOfBuildings = selectedBuildings.length
    let correctionFactor = (thisRef.state.settings.correction / 100)
    const potential = thisRef.getOverviewPotential()
    // let env = thisRef.props.reduxState.env;
    const getIconStyle = (name) => {
      let base = `https://geoimpactstorage.blob.core.windows.net`
      let itemStyle = {
        backgroundImage: `url(${base}/halley/components/dashboard/tile-icons/${name})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '65px',
        width: '65px'
      }
      hycon.debug(`${this.constructor.name} getStyle`, { itemStyle })
      return itemStyle
    }
    const getOverview = () => {
      if (nrOfBuildings === 0) {
        return (
          <div>
            <div>
              <div className={`icon`} style={getIconStyle('Solarstrom.png')}/>
            </div>
            <h1>
              {thisRef.props.title}
            </h1>
            {/*<h3>kWh/Jahr</h3>*/}
          </div>
        )
      } else if (nrOfBuildings > 0) {
        return (
          <div>
            <div>
              <div className={`icon`} style={getIconStyle('Solarstrom.png')}/>
            </div>
            <h1>{round(correctionFactor * potential, 2).toLocaleString('de-CH')}</h1>
            <h3>{thisRef.props.i18n.t('panel_solar_electricity:overview-label-unit')}</h3>
          </div>
        )
      } else {
        throw new Error('Unhandled nr of buildings')
      }
    }
    const overview = (
      <div className={'overview'}>
        {getOverview()}
      </div>
    )
    return overview
  }

  filterRoofs (buildingRoofs) {
    let thisRef = this
    let filteredBuildingRoofs = []
    let excludedRoofs = []
    buildingRoofs.forEach((buildingRoof) => {
      let modBuildingRoofs = Object.assign({}, buildingRoof)
      let filteredRoofs = []
      buildingRoof.roofs.forEach((roof) => {
        if (
          roof.klasse >= thisRef.state.settings.roofs.filter.minRoofClass &&
          roof.flaeche >= thisRef.state.settings.roofs.filter.minRoofExtent
        ) {
          filteredRoofs.push(roof)
        } else {
          excludedRoofs.push(roof)
        }
      })

      modBuildingRoofs.filteredRoofs = filteredRoofs
      filteredBuildingRoofs.push(modBuildingRoofs)
    })
    hycon.debug(`${this.constructor.name} filterRoofs`, { buildingRoofs, filteredBuildingRoofs, excludedRoofs })
    return filteredBuildingRoofs
  }

  getOverviewPotential () {
    let thisRef = this
    let buildingRoofs = thisRef.filterRoofs(thisRef.state.buildingRoofs)
    let total = 0
    buildingRoofs.forEach((buildingRoof) => {
      buildingRoof.filteredRoofs.forEach((roof) => {
        total = total + parseInt(roof.stromertrag, 10)
      })
    })
    return total
  }

  sortRoofs (roofs, property) {
    if (typeof property === 'undefined') throw new Error('Undefined property to sort after')
    let sortedRoofs = [].concat(roofs)
    sortedRoofs.sort(function compare (a, b) {
      if (a[property] > b[property]) {
        return -1
      } else if (a[property] === b[property]) {
        return 0
      } else if (a[property] < b[property]) {
        return 1
      } else {
        throw new Error('Could not sort roofs')
      }
    })
    hycon.debug(`${this.constructor.name} sortRoofs`, { roofs, sortedRoofs })
    return sortedRoofs
  }

  getSolarPowerValueOfBuildingRoofs (obj) {
    let pvTarif = obj.pvTarif

    let energyValues = pvTarif.energyValues
    let p1, p2, p3, e1, e2, e3

    const parseVal = (value) => {
      try {
        if (value === null) {
          return null
        }
        return parseFloat(value)
      } catch (e) {
        return null
      }
    }

    p1 = parseVal(energyValues.power1)
    p2 = parseVal(energyValues.power2)
    p3 = parseVal(energyValues.power3)

    e1 = parseVal(energyValues.energy1)
    e2 = parseVal(energyValues.energy2)
    e3 = parseVal(energyValues.energy3)

    let powerMap = {
      1: p1, 2: p2, 3: p3
    }

    let energyMap = {
      1: e1, 2: e2, 3: e3
    }

    let pMaxNumber, pMaxValue
    if (Math.max(p1, p2, p3) === p3) {
      pMaxNumber = 3
      pMaxValue = p3
    }
    if (Math.max(p1, p2, p3) === p2) {
      pMaxNumber = 2
      pMaxValue = p2
    }
    if (Math.max(p1, p2, p3) === p1) {
      pMaxNumber = 1
      pMaxValue = p1
    }

    let finalEnergyNumber, finalEnergyValue
    let iterationIndex = pMaxNumber
    while (iterationIndex > 0) {
      if (energyMap[iterationIndex] !== null) {
        finalEnergyNumber = iterationIndex
        finalEnergyValue = energyMap[iterationIndex]
        break
      } else {
        iterationIndex -= 1
      }
    }
    return { finalEnergyValue, finalEnergyNumber, pMaxNumber, pMaxValue, powerMap, energyMap }
  }

  getDetailView () {
    hycon.debug(`${this.constructor.name} getDetailView`)
    let thisRef = this
    let nrOfBuildings = thisRef.state.buildingRoofs.length
    let correctionFactor = (thisRef.state.settings.correction / 100)

    let roofElements = []

    const getSum = (roofs, fieldName) => {
      let sum = 0
      roofs.forEach((roof) => {
        sum = sum + roof[fieldName]
      })
      return sum
    }

    let totalSolarElectricity = 0
    let totalValueSolarElectricity = 0
    let totalRoofExtents = 0
    let totalPowerPeak = 0
    let totalSolarRadiation = 0

    const getOperator = (buildingRoof, index = 0) => {
      let operator = null
      let link = null
      let comp = null
      hycon.debug(`${this.constructor.name} getOperator`, { buildingRoof })
      try {
        let pvtarif = thisRef.getTargetPVTarif(buildingRoof).pvtarif
        hycon.debug(`${this.constructor.name} getOperator`, { pvtarif })
        operator = pvtarif[0].energyValues['nomEw']
        link = pvtarif[0].energyValues['link']
        comp = (<div key={`link_${index}`}><a href={link} target={'_blank'} rel="noopener noreferrer">{operator}</a></div>)
      } catch (e) {
        hycon.warn(`${this.constructor.name} getOperator - error`, { e })
        let pvtarif = thisRef.getTargetPVTarif(buildingRoof).pvtarif
        hycon.warn(`${this.constructor.name} getOperator - error - pvtarif`, { pvtarif })
        if (
          pvtarif !== null &&
          pvtarif.length > 0
        ) {
          hycon.warn(`${this.constructor.name} getOperator - error - pvtarif`, { pvtarif })
          operator = pvtarif[0]['name']
          link = null
          comp = (<div key={`no_link_${index}`}><a href={link} target={'_blank'} rel="noopener noreferrer">{operator}</a></div>)
        } else {
          let elcom = thisRef.getTargetElcom(buildingRoof).elcom
          hycon.warn(`${this.constructor.name} getOperator - error - pvtarif - try elcom`, { elcom })
          if(elcom && elcom[0]){
            operator = elcom[0]['netzbetreiber']
            link = null
            comp = (<div key={`no_link_${index}`}>{operator}</div>)
          }
        }
      }
      return { comp, operator, link }
    }

    const getAllOperatorData = (buildingRoof) => {
      let all = []
      let pvtarif = thisRef.getTargetPVTarif(buildingRoof).pvtarif
      if (!pvtarif) return all
      pvtarif.forEach((t) => {
        let operator = null
        let link = null
        try {
          operator = t.energyValues['nomEw']
          link = t.energyValues['link']
          all.push({ link, operator })
        } catch (e) {
          operator = t['name']
          link = null
          all.push({ link, operator })
        }
      })
      return all
    }

    const getAllOperators = (buildingRoofs) => {
      let ops = []
      let comps = []
      hycon.debug(`${this.constructor.name} getAllOperators - try pvtarif`, { buildingRoofs })
      buildingRoofs.forEach((buildingRoof, i) => {
        let all = getAllOperatorData(buildingRoof)
        all.forEach(({ link, operator }, index) => {
          if (!ops.includes(operator)) {
            ops.push(operator)
            comps.push(<a key={`${i}_${index}`} href={link} target={'_blank'} rel="noopener noreferrer">{operator}</a>)
          }
        })
      })
      if (comps.length === 0) {
        // try with elcom
        buildingRoofs.forEach((buildingRoof, index) => {
          let elcom = thisRef.getTargetElcom(buildingRoof).elcom
          hycon.debug(`${this.constructor.name} getAllOperators - try elcom`, { elcom })
          let operator = null;
          let comp = null;
          if(elcom && elcom[0]){
            operator = elcom[0]['netzbetreiber']
            comp = (<div key={`no_link_${index}`}>{operator}</div>)
          } else {
            operator = trans(thisRef, 'panel_solar_electricity:label-not-available')
            comp = (<div key={`no_link_${index}`}>{operator}</div>)
          }
          if (!ops.includes(operator)) {
            ops.push(operator)
            comps.push(comp)
          }
        })
      }
      return comps.length === 0 ? 'Nicht vefügbar' : comps
    }

    const getTotalValueOfRoofs = (buildingRoof) => {
      let pvtarif = thisRef.getTargetPVTarif(buildingRoof).pvtarif
      let pvTarif = pvtarif
      let index = 0

      let roofs = buildingRoof.filteredRoofs
      let sortedRoofs = roofs

      let obj = {
        pvTarif: {
          energyValues: pvTarif[index].energyValues
        }
      }
      let finaleEnergyValues = thisRef.getSolarPowerValueOfBuildingRoofs(obj)
      let allRoofValues = 0
      sortedRoofs.forEach((roof) => {
        allRoofValues = allRoofValues + ((correctionFactor * roof.stromertrag * (finaleEnergyValues.finalEnergyValue)) / 100)
      })
      return allRoofValues
    }

    const getTotalValueOfRoofsInAllBuildings = (buildingRoofs) => {
      let total = 0
      buildingRoofs.forEach((buildingRoof) => {
        total = total + getTotalValueOfRoofs(buildingRoof)
      })
      return total
    }

    const getWorkPeakPerBuilding = (buildingRoof) => {
      return correctionFactor * 0.17 * getSum(buildingRoof.filteredRoofs, 'flaeche')
    }

    thisRef.state.buildingRoofs.forEach((buildingRoof, index) => {
      try {
        totalValueSolarElectricity = getTotalValueOfRoofsInAllBuildings(thisRef.state.buildingRoofs)
        if (isNaN(totalValueSolarElectricity)) {
          throw new Error('totalValueSolarElectricity: ' + totalSolarElectricity)
        }
        totalValueSolarElectricity = round(totalValueSolarElectricity, 2)
      } catch (e) {
        hycon.error('error - getDetailView', { e, buildingRoof })
        totalValueSolarElectricity = trans(thisRef, 'panel_solar_electricity:label-not-available')
      }

      try {
        totalSolarElectricity = totalSolarElectricity + getSum(buildingRoof.filteredRoofs, 'stromertrag')
        if (isNaN(totalSolarElectricity)) {
          throw new Error('totalSolarElectricity: ' + totalSolarElectricity)
        }
        totalSolarElectricity = round(totalSolarElectricity, 2)
      } catch (e) {
        hycon.error('error - getDetailView', { e, buildingRoof })
        totalSolarElectricity = trans(thisRef, 'panel_solar_electricity:label-not-available')
      }

      try {
        totalRoofExtents = totalRoofExtents + getSum(buildingRoof.filteredRoofs, 'flaeche')
        if (isNaN(totalRoofExtents)) {
          throw new Error('totalRoofExtents: ' + totalRoofExtents)
        }
      } catch (e) {
        hycon.error('error - getDetailView', { e, buildingRoof })
        totalRoofExtents = trans(thisRef, 'panel_solar_electricity:label-not-available')
      }

      try {
        totalPowerPeak = totalPowerPeak + (correctionFactor * 0.17 * getSum(buildingRoof.filteredRoofs, 'flaeche'))
        if (isNaN(totalPowerPeak)) {
          throw new Error('totalPowerPeak: ' + totalPowerPeak)
        }
        totalPowerPeak = round(totalPowerPeak, 2)
      } catch (e) {
        hycon.error('error - getDetailView', { e, buildingRoof })
        totalPowerPeak = trans(thisRef, 'panel_solar_electricity:label-not-available')
      }

      try {
        totalSolarRadiation = totalSolarRadiation + getSum(buildingRoof.filteredRoofs, 'gStrahlung')
        if (isNaN(totalSolarRadiation)) {
          throw new Error('totalSolarRadiation: ' + totalSolarRadiation)
        }
        totalSolarRadiation = round(totalSolarRadiation, 2)
      } catch (e) {
        hycon.error('error - getDetailView', { e, buildingRoof })
        totalSolarRadiation = trans(thisRef, 'panel_solar_electricity:label-not-available')
      }
    })

    roofElements.push((
      <div key={`roof_aggregation_all`} className={`roof buildings-aggregation`}>
        <h2>{trans(thisRef, 'panel_solar_electricity:label-all-buildings')} ({nrOfBuildings})</h2>
        <div className={`items`}>
          <div className={`item`}>
            <h4>{trans(thisRef, 'panel_solar_electricity:label-solarstrom')} &nbsp; </h4>
            <p>{(round(correctionFactor * totalSolarElectricity, 2)).toLocaleString('de-CH')}</p>
          </div>
          <div className={`item`}>
            <h4>{trans(thisRef, 'panel_solar_electricity:label-wert-solarstrom')} &nbsp; </h4>
            <p>{totalValueSolarElectricity.toLocaleString('de-CH')}</p>
          </div>
          <div className={`item`}>
            <h4>{trans(thisRef, 'panel_solar_electricity:label-dachfläche')} &nbsp; </h4>
            <p>{round(totalRoofExtents, 2).toLocaleString('de-CH')}</p>
          </div>
          <div className={`item`}>
            <h4>{trans(thisRef, 'panel_solar_electricity:label-dachfläche-belegt')} &nbsp; </h4>
            <p>{(round(correctionFactor * totalRoofExtents, 2)).toLocaleString('de-CH')}</p>
          </div>
          <div className={`item`}>
            <h4>{trans(thisRef, 'panel_solar_electricity:label-leistungsspitze')} &nbsp; </h4>
            <p>{totalPowerPeak.toLocaleString('de-CH')}</p>
          </div>
          <div className={`item`}>
            <h4>{trans(thisRef, 'panel_solar_electricity:label-einstrahlung')} &nbsp; </h4>
            <p>{(round(correctionFactor * totalSolarRadiation, 2)).toLocaleString('de-CH')}</p>
          </div>
          <div className={`item operators`}>
            <h4>{trans(thisRef, 'panel_solar_electricity:label-netzbetreiber')} &nbsp; </h4>
            <div className={'operators'}>{getAllOperators(thisRef.state.buildingRoofs)}</div>
          </div>
        </div>
      </div>
    ))
    // get roof aggregation

    let tryParsePvValue = (fn) => {
      try {
        let res = fn()
        return res
      } catch (e) {
        hycon.warn('tryParsePvValue', e)
        return trans(thisRef, 'panel_solar_electricity:label-not-available')
      }
    }

    thisRef.state.buildingRoofs.forEach((buildingRoof, buildingIndex) => {
      hycon.debug('DEBUG pv', { buildingRoof })
      let pvYear = thisRef.getTargetPVTarif(buildingRoof).year
      let pvtarif = thisRef.getTargetPVTarif(buildingRoof).pvtarif
      let pvTarif = pvtarif
      let index = 0
      let power1 = tryParsePvValue(() => {
        try {
          let value = parseFloat(pvTarif[index].energyValues.power1)
          if (!isNaN(value)) {
            return value
          } else {
            return null
          }
        } catch (e) {
          hycon.warn(e)
          return pvTarif[index].energyValues.power1
        }
      })
      let power2 = tryParsePvValue(() => {
        try {
          let value = parseFloat(pvTarif[index].energyValues.power2)
          if (!isNaN(value)) {
            return value
          } else {
            return null
          }
        } catch (e) {
          hycon.warn(e)
          return pvTarif[index].energyValues.power2
        }
      })
      let power3 = tryParsePvValue(() => {
        try {
          let value = parseFloat(pvTarif[index].energyValues.power3)
          if (!isNaN(value)) {
            return value
          } else {
            return null
          }
        } catch (e) {
          hycon.warn(e)
          return pvTarif[index].energyValues.power3
        }
      })

      let power4 = tryParsePvValue(() => {
        try {
          let value = parseFloat(pvTarif[index].energyValues.power4)
          if (!isNaN(value)) {
            return value
          } else {
            return null
          }
        } catch (e) {
          hycon.warn(e)
          return pvTarif[index].energyValues.power4
        }
      })

      let energy1 = tryParsePvValue(() => {
        try {
          return parseFloat(pvTarif[index].energyValues.energy1)
        } catch (e) {
          return pvTarif[index].energyValues.energy1
        }
      })
      let energy2 = tryParsePvValue(() => {
        try {
          return parseFloat(pvTarif[index].energyValues.energy2)
        } catch (e) {
          return pvTarif[index].energyValues.energy2
        }
      })
      let energy3 = tryParsePvValue(() => {
        try {
          return parseFloat(pvTarif[index].energyValues.energy3)
        } catch (e) {
          return pvTarif[index].energyValues.energy3
        }
      })
      let energy4 = tryParsePvValue(() => {
        try {
          return parseFloat(pvTarif[index].energyValues.energy4)
        } catch (e) {
          return pvTarif[index].energyValues.energy4
        }
      })

      let eco1 = tryParsePvValue(() => {
        try {
          return parseFloat(pvTarif[index].energyValues.eco1)
        } catch (e) {
          return pvTarif[index].energyValues.eco1
        }
      })
      let eco2 = tryParsePvValue(() => {
        try {
          return parseFloat(pvTarif[index].energyValues.eco2)
        } catch (e) {
          return pvTarif[index].energyValues.eco2
        }
      })
      let eco3 = tryParsePvValue(() => {
        try {
          return parseFloat(pvTarif[index].energyValues.eco3)
        } catch (e) {
          return pvTarif[index].energyValues.eco3
        }
      })
      let eco4 = tryParsePvValue(() => {
        try {
          return parseFloat(pvTarif[index].energyValues.eco4)
        } catch (e) {
          return pvTarif[index].energyValues.eco4
        }
      })

      let getLeistungsKlasse = () => {
        hycon.debug('summing up getLeistungsKlasse', {})
        let classes = []
        const myround = (input) => {
          try {
            return Math.round(input * 10) / 10
          } catch (e) {
            hycon.warn(e)
            return null
          }
        }
        let getRowByNr = (nr) => {
          try {
            let value_1 = parseFloat(pvTarif[index].energyValues[`energy${nr}`])
            let value_2 = parseFloat(pvTarif[index].energyValues[`eco${nr}`])
            hycon.debug(`summing up getLeistungsKlasse - nr:${nr}`, { value_1, value_2 })
            let sum = value_1 + value_2
            hycon.debug(`summing up getLeistungsKlasse - nr:${nr}`, { sum })
            if (isNaN(sum)) {
              hycon.warn(`summing up getLeistungsKlasse - no valid number - nr:${nr}`, {
                value_1,
                value_2,
                energyvalues: pvTarif[index].energyValues
              })
              return { eco: value_2, energy: value_1, sum: null }
            } else {
              return {
                eco: value_2,
                energy: value_1,
                sum: myround(sum),
                original: pvTarif[index].energyValues
              }
            }
          } catch (e) {
            hycon.debug('summing up getLeistungsKlasse error', e)
            hycon.info(e.message)
            return { eco: null, energy: null, sum: null }
          }
        }
        if (getRowByNr(1).sum !== null) {
          classes.push({
            nr: 1,
            all: getRowByNr(1),
            value: getRowByNr(1).sum
          })
        }
        if (getRowByNr(2).sum !== null) {
          classes.push({
            nr: 2,
            all: getRowByNr(2),
            value: getRowByNr(2).sum
          })
        }
        if (getRowByNr(3).sum !== null) {
          classes.push({
            nr: 3,
            all: getRowByNr(3),
            value: getRowByNr(3).sum
          })
        }
        if (getRowByNr(4).sum !== null) {
          classes.push({
            nr: 4,
            all: getRowByNr(4),
            value: getRowByNr(4).sum
          })
        }
        hycon.debug('summing up getLeistungsKlasse', { classes })
        return classes
      }

      let workPeakPerBuilding = tryParsePvValue(() => {
        return getWorkPeakPerBuilding(buildingRoof)
      })

      let total = tryParsePvValue(() => {
        return parseFloat((() => {
          const isAValidNumber = (input) => {
            try {
              let parsed = parseFloat(input)
              let isOk = !isNaN(parsed)
              return isOk
            } catch (e) {
              return false
            }
          }
          let energy
          if (
            isAValidNumber(energy1)
          ) {
            energy = parseFloat(energy1)
          } else if (
            isAValidNumber(energy2)
          ) {
            energy = parseFloat(energy2)
          } else if (
            isAValidNumber(energy3)
          ) {
            energy = parseFloat(energy3)
          } else if (
            isAValidNumber(energy4)
          ) {
            energy = parseFloat(energy4)
          }
          let eco
          if (
            isAValidNumber(eco1)
          ) {
            eco = parseFloat(eco1)
          } else if (
            isAValidNumber(eco2)
          ) {
            eco = parseFloat(eco2)
          } else if (
            isAValidNumber(eco3)
          ) {
            eco = parseFloat(eco3)
          } else if (
            isAValidNumber(eco4)
          ) {
            eco = parseFloat(eco4)
          }
          try {
            hycon.debug('summing up', {
              energy, eco, all: {
                eco1, eco2, eco3, eco4, energy1, energy2, energy3, energy4
              }
            })
            // let sum = energy + eco;git s
            let classes = getLeistungsKlasse()
            let sum
            try {
              let sumToConsider = 0
              let nrToConsider = 1
              let classDataIndex = 0
              classes.forEach((classData, ind) => {
                let power = parseFloat(classData.all.original[`power${classData.nr}`])
                if (workPeakPerBuilding >= power) {
                  sumToConsider = classData.value
                  nrToConsider = classData.nr
                  classDataIndex = ind
                }
              })
              hycon.debug('summing up', {
                buildingIndex: buildingIndex + 1,
                sumToConsider,
                nrToConsider,
                classDataIndex
              })
              sum = sumToConsider
            } catch (e) {
              console.warn(e)
              sum = null
            }
            hycon.debug('summing up', { sum, classes })
            if (isNaN(sum)) {
              throw new Error('sum (total) is not a number')
            } else {
              return sum
            }
          } catch (e) {
            hycon.warn(e)
            return null
          }
        })())
      })

      let getLeistungsKlassen = () => {
        try {
          let elements = []
          if (
            power2 !== null &&
            workPeakPerBuilding < power2
          ) {
            elements.push(
              <div className={`subitem`} key={power1}>
                <h5>{trans(thisRef, 'panel_solar_electricity:label-leistungsklasse')} &nbsp; </h5>
                <p>{`> ${power1}`}</p>
              </div>
            )
          } else if (
            (
              power2 !== null &&
              workPeakPerBuilding > power2
            ) &&
            (
              power3 === null ||
              workPeakPerBuilding <= power3
            )
          ) {
            elements.push(
              <div className={`subitem`} key={power2}>
                <h5>{trans(thisRef, 'panel_solar_electricity:label-leistungsklasse')} &nbsp; </h5>
                <p>{`> ${power2}`}</p>
              </div>
            )
          } else if (
            (
              power3 !== null &&
              workPeakPerBuilding > power3
            ) &&
            (
              power4 === null ||
              workPeakPerBuilding <= power4
            )
          ) {
            elements.push(
              <div className={`subitem`} key={power3}>
                <h5>{trans(thisRef, 'panel_solar_electricity:label-leistungsklasse')} &nbsp; </h5>
                <p>{`> ${power3}`}</p>
              </div>
            )
          } else if (
            power4 !== null &&
            workPeakPerBuilding > power4
          ) {
            elements.push(
              <div className={`subitem`} key={power4}>
                <h5>{trans(thisRef, 'panel_solar_electricity:label-leistungsklasse')} &nbsp; </h5>
                <p>{`> ${power4}`}</p>
              </div>
            )
          } else {
            hycon.warn('getLeistungsKlassen - Some strange happened.', {
              buildingRoof, workPeakPerBuilding, powers: {
                power1, power2, power3, power4
              }
            })
            elements.push(
              <div className={`subitem`} key={'special'}>
                <h5>{trans(thisRef, 'panel_solar_electricity:label-leistungsklasse')} &nbsp; </h5>
                <p>{`Nicht verfügbar`}</p>
              </div>
            )
          }
          return elements
        } catch (e) {
          let elements = []
          hycon.warn('getLeistungsKlassen - Something terrible happened', {
            buildingRoof, workPeakPerBuilding, powers: {
              power1, power2, power3, power4
            }
          })
          elements.push(
            <div className={`subitem`} key={'special'}>
              <h5>{trans(thisRef, 'panel_solar_electricity:label-leistungsklasse')} &nbsp; </h5>
              <p>{`Nicht verfügbar`}</p>
            </div>
          )
          return elements
        }
      }

      let displayValue = (fn) => {
        try {
          let res = fn()
          if (isNaN(res)) {
            throw new Error('Not a number')
          }
          return res
        } catch (e) {
          hycon.warn(e)
          return trans(thisRef, 'panel_solar_electricity:label-not-available')
        }
      }

      const hasBestRoof = () => {
        let roofs = buildingRoof.filteredRoofs
        let sortedRoofs = roofs
        let relevantRoof = sortedRoofs[0]
        return relevantRoof && typeof relevantRoof !== 'undefined'
      }

      roofElements.push((
        <div className={`roof roof-aggregation`} key={`roof_aggregation_${buildingIndex}`}>
          <h2>{trans(thisRef, 'panel_solar_electricity:label-building')} {buildingIndex + 1} </h2>
          <div className={`items`}>
            <div className={`item`}>
              <h4>{trans(thisRef, 'panel_solar_electricity:label-solarstrom')} &nbsp; </h4>
              <p>{displayValue(() => {
                return round(correctionFactor * getSum(buildingRoof.filteredRoofs, 'stromertrag'), 2)
              }).toLocaleString('de-CH')}</p>
            </div>
            <div className={`item`}>
              <h4>{trans(thisRef, 'panel_solar_electricity:label-wert-solarstrom')} &nbsp; </h4>
              <p>{displayValue(() => {
                return round(getTotalValueOfRoofs(buildingRoof), 2)
              }).toLocaleString('de-CH')}</p>
            </div>
            <div className={`item`}>
              <h4>{trans(thisRef, 'panel_solar_electricity:label-dachfläche')} &nbsp; </h4>
              <p>{displayValue(() => {
                return round(getSum(buildingRoof.filteredRoofs, 'flaeche'), 2)
              }).toLocaleString('de-CH')}</p>
            </div>
            <div className={`item`}>
              <h4>Dachfläche belegt [m²]: &nbsp; </h4>
              <p>{displayValue(() => {
                return round(correctionFactor * getSum(buildingRoof.filteredRoofs, 'flaeche'), 2)
              }).toLocaleString('de-CH')}</p>
            </div>
            <div className={`item`}>
              <h4>{trans(thisRef, 'panel_solar_electricity:label-leistungsspitze')} &nbsp; </h4>
              <p>{displayValue(() => {
                return round(getWorkPeakPerBuilding(buildingRoof), 2)
              }).toLocaleString('de-CH')}</p>
            </div>

            <div className={`item`}>
              <h4>{trans(thisRef, 'panel_solar_electricity:label-einstrahlung')} &nbsp; </h4>
              <p>{displayValue(() => {
                return round(correctionFactor * getSum(buildingRoof.filteredRoofs, 'gStrahlung'), 2)
              }).toLocaleString('de-CH')}</p>
            </div>
            <div className={`item`}>
              <h4>{trans(thisRef, 'panel_solar_electricity:label-netzbetreiber')} &nbsp; </h4>
              <div>{(() => {
                try {
                  return getOperator(buildingRoof).comp
                } catch (e) {
                  hycon.warn('could not get network operator')
                  return trans(thisRef, 'panel_solar_electricity:label-not-available')
                }
              })()}</div>
            </div>
            <div className={`item`}>
              <div className={`subitems`}>
                <h3 style={{ marginTop: '10px' }}>Vergütung Solarstrom {(() => {
                  if (pvYear !== null) {
                    return `(Tarifjahr ${pvYear})`
                  } else {
                    return `(Tarif nicht verfügbar)`
                  }
                })()} &nbsp; <br/></h3>
                {getLeistungsKlassen()}
                <div className={`subitem`}>
                  <h5>{trans(thisRef, 'panel_solar_electricity:label-energy')}  &nbsp; </h5>
                  <p>{displayValue(() => {
                    let classes = getLeistungsKlasse()
                    let sumToConsider = 0
                    let nrToConsider = 1
                    let classDataIndex = 0
                    classes.forEach((classData, ind) => {
                      let power = parseFloat(classData.all.original[`power${classData.nr}`])
                      if (workPeakPerBuilding >= power) {
                        sumToConsider = classData.value
                        nrToConsider = classData.nr
                        classDataIndex = ind
                      }
                    })
                    hycon.debug('summing up', {
                      buildingIndex: buildingIndex + 1,
                      sumToConsider,
                      nrToConsider,
                      classDataIndex
                    })
                    if (nrToConsider === 1) {
                      return energy1
                    }
                    if (nrToConsider === 2) {
                      return energy2
                    }
                    if (nrToConsider === 3) {
                      return energy3
                    }
                    if (nrToConsider === 4) {
                      return energy4
                    }
                  })}</p>
                </div>
                <div className={`subitem`}>
                  <h5>{trans(thisRef, 'panel_solar_electricity:label-hkn')}  &nbsp; </h5>
                  <p>{displayValue(() => {
                    let classes = getLeistungsKlasse()
                    let sumToConsider = 0
                    let nrToConsider = 1
                    let classDataIndex = 0
                    classes.forEach((classData, ind) => {
                      let power = parseFloat(classData.all.original[`power${classData.nr}`])
                      if (workPeakPerBuilding >= power) {
                        sumToConsider = classData.value
                        nrToConsider = classData.nr
                        classDataIndex = ind
                      }
                    })
                    hycon.debug('summing up', {
                      buildingIndex: buildingIndex + 1,
                      sumToConsider,
                      nrToConsider,
                      classDataIndex
                    })
                    return round((() => {
                      if (nrToConsider === 1) {
                        return eco1
                      }
                      if (nrToConsider === 2) {
                        return eco2
                      }
                      if (nrToConsider === 3) {
                        return eco3
                      }
                      if (nrToConsider === 4) {
                        return eco4
                      }
                    })(), 2)
                  })}</p>
                </div>
                <div className={`subitem`}>
                  <h5>{trans(thisRef, 'panel_solar_electricity:label-total')} &nbsp; </h5>
                  <p>{displayValue(() => {
                    return total
                  })}</p>
                </div>
              </div>
            </div>
            <div className={`item`}>
              {hasBestRoof() ? (
                <span className={'link-like'} onClick={
                  () => {
                    let roofs = buildingRoof.filteredRoofs
                    let sortedRoofs = roofs
                    let relevantRoof = sortedRoofs[0]

                    let getComponents = () => {
                      let comps = []
                      thisRef.state.buildingRoofs.forEach((buildingRoof, buildingIndex) => {
                        let roofs = buildingRoof.filteredRoofs
                        let sortedRoofs = roofs
                        let relevantRoof = sortedRoofs[0]
                        let pvtarif = thisRef.getTargetPVTarif(buildingRoof).pvtarif
                        let pvTarif = pvtarif
                        let index = 0
                        let energy1 = tryParsePvValue(() => {
                          return parseFloat(pvTarif[index].energyValues.energy1)
                        })
                        let eco1 = tryParsePvValue(() => {
                          return parseFloat(pvTarif[index].energyValues.eco1)
                        })
                        let calculatedValue = (() => {
                          try {
                            return energy1 + eco1
                          } catch (e) {
                            return trans(thisRef, 'panel_solar_electricity:label-not-available')
                          }
                        })()

                        if (sortedRoofs.length > 0) {
                          comps.push((
                            <div className={`roof roof-relevant`}
                                 key={`roof_relevant_b${buildingIndex}`}>
                              <div className={`items`}>
                                <div className={`item`}>
                                  <h4>{trans(thisRef, 'panel_solar_electricity:label-solarstrom')} &nbsp; </h4>
                                  <p>{displayNumValue(() => {
                                    return round(correctionFactor * relevantRoof.stromertrag, 2)
                                  }).toLocaleString('de-CH')}</p>
                                </div>
                                <div className={`item`}>
                                  <h4>{trans(thisRef, 'panel_solar_electricity:label-wert-solarstrom')} &nbsp; </h4>
                                  <p>{displayNumValue(() => {
                                    return round((correctionFactor * relevantRoof.stromertrag * calculatedValue) / 100, 2)
                                  }).toLocaleString('de-CH')}</p>
                                </div>
                                <div className={`item`}>
                                  <h4>{trans(thisRef, 'panel_solar_electricity:label-dachfläche')} &nbsp; </h4>
                                  <p>{displayNumValue(() => {
                                    return round(relevantRoof.flaeche, 2)
                                  }).toLocaleString('de-CH')}</p>
                                </div>
                                <div className={`item`}>
                                  <h4>{trans(thisRef, 'panel_solar_electricity:label-dachfläche-belegt')} &nbsp; </h4>
                                  <p>{displayNumValue(() => {
                                    return round(correctionFactor * relevantRoof.flaeche, 2)
                                  }).toLocaleString('de-CH')}</p>
                                </div>
                                <div className={`item`}>
                                  <h4>{trans(thisRef, 'panel_solar_electricity:label-dachneigung')} &nbsp; </h4>
                                  <p>{displayNumValue(() => {
                                    return round(relevantRoof.neigung, 2)
                                  }).toLocaleString('de-CH')}</p>
                                </div>
                                <div className={`item`}>
                                  <h4>{trans(thisRef, 'panel_solar_electricity:label-ausrichtung')} &nbsp; </h4>
                                  <p>{displayNumValue(() => {
                                    return round(relevantRoof.ausrichtung, 2)
                                  }).toLocaleString('de-CH')}</p>
                                </div>
                                <div className={`item`}>
                                  <h4>{trans(thisRef, 'panel_solar_electricity:label-leistungsspitze')} &nbsp; </h4>
                                  <p>{displayNumValue(() => {
                                    return round((correctionFactor * 0.17 * relevantRoof.flaeche), 2)
                                  }).toLocaleString('de-CH')}</p>
                                </div>
                                <div className={`item`}>
                                  <h4>{trans(thisRef, 'panel_solar_electricity:label-mittlere-einstrahlung')} &nbsp; </h4>
                                  <p>{displayNumValue(() => {
                                    return round(relevantRoof.mStrahlung, 2)
                                  }).toLocaleString('de-CH')}</p>
                                </div>
                                <div className={`item`}>
                                  <h4>{trans(thisRef, 'panel_solar_electricity:label-einstrahlung')} &nbsp; </h4>
                                  <p>{displayNumValue(() => {
                                    return round(correctionFactor * relevantRoof.gStrahlung, 2)
                                  }).toLocaleString('de-CH')}</p>
                                </div>
                              </div>
                            </div>
                          ))
                        } else {
                          hycon.debug(`${this.constructor.name} getDetailView - no sorted roofs`)
                        }
                      })
                      return comps
                    }
                    let components = getComponents()

                    return thisRef.setStateProperty(
                      'renderingData',
                      {
                        relevantRoof: relevantRoof,
                        buildingRoof,
                        buildingIndex: buildingIndex + 1,
                        component: components[buildingIndex]
                      }
                    )
                    .then(
                      () => {
                        return thisRef.setStateProperty('rendering', RENDER_BEST_ROOF_DETAIL)
                      }
                    )
                    .then(() => {
                      Promise.all([
                        thisRef.props.setParentState('isPortalVisible', true),
                        thisRef.props.setParentState('isTilesVisible', false)
                      ])
                    })
                  }
                }
                      style={{ cursor: 'pointer' }}
                >
                                    {trans(thisRef, 'panel_solar_electricity:label-best-roof')}
                                </span>
              ) : null}
            </div>
          </div>
        </div>
      ))
    })

    let displayNumValue = (fn) => {
      try {
        let res = fn()
        if (isNaN(res)) {
          throw new Error('Not a number')
        }
        return res
      } catch (e) {
        hycon.warn(e)
        return trans(thisRef, 'panel_solar_electricity:label-not-available')
      }
    }

    if (nrOfBuildings > 0) {
      return <div className={`detail`}>{roofElements}</div>
    } else {
      return (
        <div className={`detail`}>
          <div className={'empty-selection'}>
            {trans(thisRef, 'panel_solar_electricity:detail-help-text')}
          </div>
        </div>
      )
    }
  }

  setStateProperty (propertyName, propertyValue) {
    let thisRef = this
    return new Promise((res) => {
      thisRef.setState((p) => {
        const obj = {}
        obj[propertyName] = propertyValue
        return Object.assign({}, p, obj)
      }, () => {
        hycon.debug(`${this.constructor.name} setStateProperty - newState`, { newState: thisRef.state })
        if (propertyName === 'settings') {
          hycon.debug(`${this.constructor.name} setStateProperty - saving user settings on the server`, {
            propertyName,
            propertyValue,
            appsettings: thisRef.state.appsettings
          })
          thisRef.updateUserSettings().then(() => {
            res()
          })
        }
        res()
      })
    })
  }

  setIsLoading (isLoading) {
    hycon.debug(`${this.constructor.name} setIsLoading`, { isLoading })
    let thisRef = this
    return thisRef.setStateProperty('isLoading', isLoading)
  }

  renderState () {
    let thisRef = this
    let rendering = thisRef.state.rendering
    let dashboardPortal = document.getElementById(`dashboard-portal`)
    if (!thisRef.state.isMounted) return null
    let lang = thisRef.props.i18n.language

    let getString = (i18nextKey) => {
      return thisRef.props.i18n.t(i18nextKey)
    }

    if (rendering === RENDER_OVERVIEW) {
      return (
        <div className={`Card SolarElectricityCard`}>
          <div className={'controls'}>
            <h4 className={'title'}>{thisRef.props.title}</h4>

            <MUIButton
              onClick={() => {
                let id = thisRef.props.id
                hycon.debug('Clicked card info ' + id)
                window.open(`${WixUtil.getLangBaseUrl(lang.toLowerCase())}/solarstrom`, '_blank');
              }}
            >
              <InfoIcon/>
            </MUIButton>

            <MUIButton
              onClick={() => {
                let id = thisRef.props.id
                hycon.debug('Clicked card settings' + id)
                thisRef.setStateProperty('rendering', RENDER_SETTINGS)
                Promise.all([
                  thisRef.props.setParentState('isPortalVisible', true),
                  thisRef.props.setParentState('isTilesVisible', false)
                ])
                panelController.dispatchEnter(window)
              }}
            >
              <SettingsIcon/>
            </MUIButton>
          </div>
          <div
            className={'body'}
            onClick={() => {
              let id = thisRef.props.id
              hycon.debug('Clicked card detail' + id)
              GoogleAnalytics.sendGenerigEvent('panel-detail-solarstrom', 'sep-panel-detail', 'Panel Interaction', true)
              thisRef.setStateProperty('rendering', RENDER_DETAIL)
              Promise.all([
                thisRef.props.setParentState('isPortalVisible', true),
                thisRef.props.setParentState('isTilesVisible', false)
              ])
              const buildingSelectionEvent = new CustomEvent('kibana-event', { detail: {
                  type: "panel-detail",
                  eventInfo: {
                    info: "Solar-Electricity panel has been opened."
                  }
                }
              });
              window.dispatchEvent(buildingSelectionEvent);
              panelController.dispatchEnter(window)
            }}
          >
            {thisRef.getOverview()}
            {
              thisRef.state.isLoading ? (
                <Dimmer active>
                  <Loader size='massive'>Loading {thisRef.props.title}</Loader>
                </Dimmer>
              ) : null
            }
          </div>
        </div>
      )
    } else if (rendering === RENDER_DETAIL) {
      let detail = (
        <div className={`Card SolarElectricityCard`}>
          <div className={'controls'}>
            <h4 className={'title'}>{getString('dashboard:toolbar-details-label')} {thisRef.props.title}</h4>
            <MUIButton
              onClick={() => {
                let id = thisRef.props.id
                hycon.debug('Clicked card settings ' + id)
                thisRef.setStateProperty('rendering', RENDER_SETTINGS)
                Promise.all([
                  thisRef.props.setParentState('isPortalVisible', true),
                  thisRef.props.setParentState('isTilesVisible', false)
                ])
              }}
            >
              <SettingsIcon/>
            </MUIButton>
            <MUIButton
              onClick={() => {
                let id = thisRef.props.id
                hycon.debug('Clicked card close ' + id)
                thisRef.setStateProperty('rendering', RENDER_OVERVIEW)
                Promise.all([
                  thisRef.props.setParentState('isPortalVisible', false),
                  thisRef.props.setParentState('isTilesVisible', true)
                ])
                panelController.dispatchLeave(window)
              }}
            >
              <CloseIcon/>
            </MUIButton>
          </div>
          <div
            className={'body'}
            onClick={() => {
              // let id = thisRef.props.id;
              // hycon.debug("Clicked card " + id);
            }}
          >
            {thisRef.getDetailView()}
            {
              thisRef.state.isLoading ? (
                <Dimmer active>
                  <Loader size='massive'>Loading {thisRef.props.title}</Loader>
                </Dimmer>
              ) : null
            }
          </div>
        </div>
      )
      return ReactDOM.createPortal(detail, dashboardPortal)
    } else if (rendering === RENDER_BEST_ROOF_DETAIL) {
      let detail = (
        <div className={`Card SolarElectricityCard`}>
          <RoofDetail
            {...thisRef.props}
            panelController={panelController}
            parentState={thisRef.state}
            renderingData={thisRef.state.renderingData}
            onClose={() => {
              let id = thisRef.props.id
              hycon.debug(`Clicked best roof detail close ${id}`)
              thisRef.setStateProperty('rendering', RENDER_DETAIL)
              Promise.all([
                thisRef.props.setParentState('isPortalVisible', true),
                thisRef.props.setParentState('isTilesVisible', false)
              ])
            }}
          />
        </div>
      )
      return ReactDOM.createPortal(detail, dashboardPortal)
    } else if (rendering === RENDER_SETTINGS) {
      let colors = [
        '#192f9f',
        '#00c5ff',
        '#ffaa01',
        '#ff5501',
        '#a80000', // dark red
      ]
      let getColorLabel = (roofClass) => {
        return (
          <div className={'color-label'} style={{
            margin: 5, width: 20, height: 20, backgroundColor: colors[roofClass - 1]
          }}/>
        )
      }
      let settings = (
        <div className={`Card SolarElectricityCard`}>
          <div className={'controls'}>
            <h4 className={'title'}>{getString('dashboard:toolbar-settings-label')} {thisRef.props.title}</h4>
            <MUIButton
              onClick={() => {
                let id = thisRef.props.id
                hycon.debug('Clicked card close ' + id)
                thisRef.setStateProperty('rendering', RENDER_OVERVIEW)
                thisRef.props.setParentState('isPortalVisible', false)
                thisRef.props.setParentState('isTilesVisible', true)
                panelController.dispatchLeave(window)
              }}
            >
              <CloseIcon/>
            </MUIButton>
          </div>
          <div
            className={'body'}
            onClick={() => {
              // let id = thisRef.props.id;
              // hycon.debug("Clicked card " + id);
            }}
          >
            <div className={'settings'}>
              <Form>
                <h4>{trans(thisRef, 'panel_solar_electricity:settings-min-extension-roof')}&nbsp;</h4>
                <Form.Field>
                  <Input
                    type={'number'}
                    min={0}
                    max={500}
                    placeholder='15'
                    onChange={(e, val) => {
                      hycon.debug('onChange', { e, val })
                      let settings = Object.assign({}, thisRef.state.settings)
                      let nr = 0
                      let trimmedNr = 0
                      try {
                        trimmedNr = val.value.toString().replace(/^0+/, '')
                        hycon.debug('onChange', { e, val, trimmedNr })
                        nr = parseInt(trimmedNr, 10)
                        if (isNaN(nr)) {
                          throw new Error('Empty string not allowed')
                        }
                      } catch (err) {
                        hycon.debug('onChange - err', { err, evt: e, val })
                        nr = 0
                        trimmedNr = 0
                      }
                      if (nr > 500) {
                        settings.roofs.filter.minRoofExtent = 500
                        thisRef.setStateProperty('settings', settings)
                        let buildingRoofs = thisRef.state.buildingRoofs
                        thisRef.applyFilteringAndSorting(buildingRoofs)
                      } else if (nr >= 0 && nr <= 500) {
                        settings.roofs.filter.minRoofExtent = trimmedNr
                        thisRef.setStateProperty('settings', settings)
                        let buildingRoofs = thisRef.state.buildingRoofs
                        thisRef.applyFilteringAndSorting(buildingRoofs)
                      }
                    }}
                    value={thisRef.state.settings.roofs.filter.minRoofExtent}
                  />
                </Form.Field>
                <h4>{trans(thisRef, 'panel_solar_electricity:settings-roof-coverage')}</h4>
                <Form.Field>
                  <Input
                    type={'number'}
                    min={0}
                    max={100}
                    placeholder='70'
                    onChange={(e, val) => {
                      if (
                        val.value >= 0 && val.value <= 100
                      ) {
                        hycon.debug('onChange', { e, val })
                        let oldSettings = Object.assign({}, thisRef.state.settings)
                        oldSettings.correction = val.value
                        thisRef.setStateProperty('settings', oldSettings).then(() => {
                          hycon.debug('updated correction factor', { e, val })
                        })
                      }
                    }}
                    onBlur={(e, val) => {
                      hycon.debug('correction factor blurred', { e, val })
                      if (
                        !thisRef.state.settings.correction
                      ) {
                        let defaultVal = 70
                        hycon.debug('onChange', { e, val })
                        let oldSettings = Object.assign({}, thisRef.state.settings)
                        oldSettings.correction = defaultVal
                        thisRef.setStateProperty('settings', oldSettings).then(() => {
                          hycon.debug('updated correction factor (default)', { e, val })
                        })
                      }
                    }}
                    value={thisRef.state.settings.correction}
                  />
                </Form.Field>
                <h4>{trans(thisRef, 'panel_solar_electricity:settings-minimum-quality')}&nbsp; </h4>
                <Form.Field>
                  {getColorLabel(1)}
                  <Radio
                    label={trans(thisRef, 'panel_solar_electricity:radio-low')}
                    name='radioGroup'
                    value={1}
                    checked={thisRef.state.settings.roofs.filter.minRoofClass === 1}
                    onChange={(e, val) => {
                      hycon.debug('onChange', { e, val })
                      let settings = Object.assign({}, thisRef.state.settings)
                      settings.roofs.filter.minRoofClass = val.value
                      thisRef.setStateProperty('settings', settings)
                      let buildingRoofs = thisRef.state.buildingRoofs
                      thisRef.applyFilteringAndSorting(buildingRoofs)
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  {getColorLabel(2)}
                  <Radio
                    label={trans(thisRef, 'panel_solar_electricity:radio-average')}
                    name='radioGroup'
                    value={2}
                    checked={thisRef.state.settings.roofs.filter.minRoofClass === 2}
                    onChange={(e, val) => {
                      hycon.debug('onChange', { e, val })
                      let settings = Object.assign({}, thisRef.state.settings)
                      settings.roofs.filter.minRoofClass = val.value
                      thisRef.setStateProperty('settings', settings)
                      let buildingRoofs = thisRef.state.buildingRoofs
                      thisRef.applyFilteringAndSorting(buildingRoofs)
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  {getColorLabel(3)}
                  <Radio
                    label={trans(thisRef, 'panel_solar_electricity:radio-good')}
                    name='radioGroup'
                    value={3}
                    checked={thisRef.state.settings.roofs.filter.minRoofClass === 3}
                    onChange={(e, val) => {
                      hycon.debug('onChange', { e, val })
                      let settings = Object.assign({}, thisRef.state.settings)
                      settings.roofs.filter.minRoofClass = val.value
                      thisRef.setStateProperty('settings', settings)
                      let buildingRoofs = thisRef.state.buildingRoofs
                      thisRef.applyFilteringAndSorting(buildingRoofs)
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  {getColorLabel(4)}
                  <Radio
                    label={trans(thisRef, 'panel_solar_electricity:radio-very-good')}
                    name='radioGroup'
                    value={4}
                    checked={thisRef.state.settings.roofs.filter.minRoofClass === 4}
                    onChange={(e, val) => {
                      hycon.debug('onChange', { e, val })
                      let settings = Object.assign({}, thisRef.state.settings)
                      settings.roofs.filter.minRoofClass = val.value
                      thisRef.setStateProperty('settings', settings)
                      let buildingRoofs = thisRef.state.buildingRoofs
                      thisRef.applyFilteringAndSorting(buildingRoofs)
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  {getColorLabel(5)}
                  <Radio
                    label={trans(thisRef, 'panel_solar_electricity:radio-very-very-good')}
                    name='radioGroup'
                    value={5}
                    checked={thisRef.state.settings.roofs.filter.minRoofClass === 5}
                    onChange={(e, val) => {
                      hycon.debug('onChange', { e, val })
                      let settings = Object.assign({}, thisRef.state.settings)
                      settings.roofs.filter.minRoofClass = val.value
                      thisRef.setStateProperty('settings', settings)
                      let buildingRoofs = thisRef.state.buildingRoofs
                      thisRef.applyFilteringAndSorting(buildingRoofs)
                    }}
                  />
                </Form.Field>
                {/*
                                <Form.Field>
                                    <Button onCLick={() => {hycon.log("submit", {form: thisRef.state.form})}}/>
                                </Form.Field>
                                */}
              </Form>
            </div>
            {
              thisRef.state.isLoading ? (
                <Dimmer active>
                  <Loader size='massive'>Loading {thisRef.props.title}</Loader>
                </Dimmer>
              ) : null
            }
          </div>
        </div>
      )
      return ReactDOM.createPortal(settings, dashboardPortal)
    } else {
      throw new Error('unconsistent rendering state')
    }
  }

  render () {
    let thisRef = this

    /*
    let txt = thisRef.props.i18n.t("dashboard:toolbar-settings-label");
    hycon.debug("Card txt", {txt});
    */
    return thisRef.renderState()
  }

  getRoofs () {
    let thisRef = this
    const selectedBuildings = thisRef.props.reduxState.selectedBuildings
    const jwt = thisRef.props.reduxState.user.jwt

    let roofPromises = []

    let getRoofRequest = (building, selectedBuilding) => {
      // selectedBuildings[""0""].buildings[""0""].id
      let endpoint = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/api/buildings/${building.id}/roofs`
      return axios({
        method: 'get',
        url: endpoint,
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        }
      })
      .then(function (response) {
        hycon.debug(response)
        return {
          building,
          roofs: response.data,
          pvTarif: selectedBuilding.pvTarif,
          geoJSON: selectedBuilding.geoJSON
        }
      })
      .catch((e) => {
        UserUtil.authenticationRedirect(e.response, thisRef)
        hycon.error(e)
      })
    }

    selectedBuildings.forEach((selectedBuilding) => {
      let buildings = selectedBuilding.buildings
      buildings.forEach((building) => {
        let roofRequest = getRoofRequest(building, selectedBuilding)
        roofPromises.push(roofRequest)
      })
    })

    return Promise.all(roofPromises)
  }

  applyFilteringAndSorting (buildingRoofs) {
    let thisRef = this
    hycon.info(`${thisRef.constructor.name} applyFilteringAndSorting - got buildingRoofs`, { buildingRoofs })
    let filteredRoofs = thisRef.filterRoofs(buildingRoofs)
    let copyOfFilteredRoofs = Object.assign({}, filteredRoofs)

    filteredRoofs.forEach((filteredRoof, index) => {
      let unsorted = Object.assign({}, filteredRoof.filteredRoofs)
      let sortedFilteredRoofs = thisRef.sortRoofs(filteredRoof.filteredRoofs, 'stromertrag')
      copyOfFilteredRoofs[index].filteredRoofs = sortedFilteredRoofs
      hycon.info(`${thisRef.constructor.name} applyFilteringAndSorting - got filtered buildingRoofs - sorted`, {
        unsorted,
        sortedFilteredRoofs
      })
    })

    hycon.info(`${thisRef.constructor.name} applyFilteringAndSorting - got filtered buildingRoofs`, { copyOfFilteredRoofs })
    thisRef.setStateProperty('buildingRoofs', filteredRoofs)
    panelController.update({ filteredRoofs, settings: thisRef.state.settings }, axios, thisRef)
    return filteredRoofs
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} componentDidUpdate`, {
      prevProps,
      newProps: this.props,
      prevState,
      snapshot
    })

    panelController.updateStateProps({
      map: thisRef.props.reduxState.map,
      axios: axios,
      ctx: thisRef
    })

    const old_selectedBuildings = prevProps.reduxState.selectedBuildings
    const new_selectedBuildings = thisRef.props.reduxState.selectedBuildings
    if (JSON.stringify(old_selectedBuildings) !== JSON.stringify(new_selectedBuildings)) {
      hycon.debug(`${thisRef.constructor.name} componentDidUpdate - new selectedBuildings`, {
        old_selectedBuildings,
        new_selectedBuildings
      })

      thisRef.setIsLoading(true)
      thisRef.getRoofs()
      .then((buildings) => {
        return thisRef.getBuildingsData(buildings).then((values) => {
          hycon.info(`${thisRef.constructor.name} componentDidUpdate - got values`, { values, buildings })
          buildings.forEach((building) => {
            values.forEach((value) => {
              if (building.building.id === value.data.building.building.id) {
                building.allData = value.data
              }
            })
          })
          return buildings
        })
      })
      .then((values) => {
        thisRef.setIsLoading(false)
        hycon.info(`${thisRef.constructor.name} componentDidUpdate - got buildingRoofs`, { values })
        let buildingRoofs = values
        let buildings = thisRef.applyFilteringAndSorting(buildingRoofs)
        return buildings
      })
      .catch((e) => {
        hycon.info(`${thisRef.constructor.name} componentDidUpdate - error getting roofs`, e)
        thisRef.setIsLoading(false)
      })
    } else {
      hycon.debug(`${thisRef.constructor.name} componentDidUpdate - no new selectedBuildings`, {
        old_selectedBuildings,
        new_selectedBuildings
      })
    }
  }

  getDistrictByPoint = (lat, lng) => {
    let thisRef = this
    let srid = 4326 //WGS84 (SRID 4326)
    let jwt = thisRef.props.reduxState.user.jwt
    // http://staging.services.swissenergyplanning.ch/api/district-by-point?x=601252.144586&y=199745.011742&srid=21781
    let endpointDistrictByPoint = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/api/district-by-point?x=${lng}&y=${lat}&srid=${srid}`
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    let districtByPointPromise = axios({
      method: 'get',
      url: endpointDistrictByPoint,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${jwt}`,
      },
      cancelToken: source.token
    })
    .then(function (response) {
      // handle success
      hycon.debug('getDistrictByPoint', response)
      return response
    })
    .catch((e) => {
      hycon.error('getDistrictByPoint', e)
      // eventually kick out the user if he has logged in from another device
      UserUtil.authenticationRedirect(e.response, thisRef)
    })
    return districtByPointPromise
  }

  nu_getBuildingsData (buildings) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} getBuildingsData`, {
      buildings
    })

    let pvReq = (url) => {
      hycon.debug(`${thisRef.constructor.name} elcomReq - pvReq`, { url })
      let jwt = thisRef.props.reduxState.user.jwt
      return axios({
        method: 'get',
        url: url,
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        }
      })
      .then(function (response) {
        hycon.debug(`${thisRef.constructor.name} getBuildingsData - pvReq - ok`)
        return response
      })
      .catch((e) => {
        hycon.debug(`${thisRef.constructor.name} getBuildingsData - pvReq - error`, { e })
        let response = { data: {} }
        hycon.debug(`${thisRef.constructor.name} getBuildingsData - pvReq - forced ok`)
        return Promise.resolve(response)
      })
    }

    let elcomReq = (url) => {
      hycon.debug(`${thisRef.constructor.name} getBuildingsData - elcomReq`, { url })
      let jwt = thisRef.props.reduxState.user.jwt
      return axios({
        method: 'get',
        url: url,
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        }
      })
      .then(function (response) {
        hycon.debug(`${thisRef.constructor.name} getBuildingsData - elcomReq - ok`)
        return response
      })
      .catch((e) => {
        hycon.debug(`${thisRef.constructor.name} getBuildingsData - elcomReq - error`, { e })
        UserUtil.authenticationRedirect(e.response, thisRef)
        return Promise.reject('elcomReq rejection')
      })
    }

    let getDistricts = async (building) => {
      hycon.debug(`${thisRef.constructor.name} getDistricts`, { building })
      let gwr = await thisRef.getGWR(building.buildings[0].id)
      hycon.debug(`${thisRef.constructor.name} getDistricts - districts`, { gwr })
      return gwr.data.map((element) => {
        return element.housingEgids.gemNr
      })
    }

    let districtPromises = []
    buildings.forEach((building) => {
      hycon.debug(`${thisRef.constructor.name} getBuildingsData - building`, { building })
      let districtPromise = getDistricts(building).then((districts) => {
        let allPromises = []
        districts = districts.reduce((acc, curr) => {
          if (!acc.includes(curr)) {
            return acc.concat([curr])
          } else {
            return acc
          }
        }, [])
        hycon.debug(`${thisRef.constructor.name} getBuildingsData - building - districtReq`, { building, districts })
        districts.forEach((district) => {
          let bfsNummer = district
          let urlPv = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/ocelot-api/ews/${bfsNummer}`
          let urlElcom = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/ocelot-api/elcom/${bfsNummer}`
          let promisePv = pvReq(urlPv)
          let promiseElcom = elcomReq(urlElcom)
          let allPromise = Promise.all([
            promiseElcom,
            promisePv
          ]).then(values => {
            hycon.debug(`${thisRef.constructor.name} getBuildingsData - all data`, { values })
            // values[1].data.pvtarif2018
            let response = { data: {} }
            response.data.building = building
            values.forEach((value) => {
              let data = value.data
              for (let p in data) {
                if (
                  data.hasOwnProperty(p)
                ) {
                  response.data[`${p}`] = data[p]
                }
              }
            })
            hycon.debug(`${thisRef.constructor.name} getBuildingsData - all data - returning response`, { response })
            return response
          })
          allPromises.push(allPromise)
        })
        return Promise.all(allPromises).then((values) => {
          return values
        })
      })
      districtPromises.push(districtPromise)
    })
    return Promise.all(districtPromises).then((values) => {
      hycon.debug(`${thisRef.constructor.name} getBuildingsData - values`, {
        values
      })
      return values.reduce((acc, current) => {
        return acc.concat(current)
      }, [])
    }).catch((error) => {
      hycon.error(`${thisRef.constructor.name} getBuildingsData - error`, {
        error
      })
    })
  }

  async getGWR (buildingIdSep) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} getGWR`, buildingIdSep)
    const jwt = thisRef.props.reduxState.user.jwt

    let endpoint = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/api/gwrbuildings?buildingidsep=[in]${buildingIdSep}`
    return await axios({
      method: 'get',
      url: endpoint,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${jwt}`,
      }
    })
    .then(function (response) {
      hycon.debug(`${thisRef.constructor.name} getGWRRequest`, response)
      return response
    })
    .catch((e) => {
      hycon.error(`${thisRef.constructor.name} getGWRRequest`, e)
      UserUtil.authenticationRedirect(e.response, thisRef)
    })
  }

  getBuildingsData (buildings) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} getBuildingsData`, {
      buildings
    })
    let pvReq = (url) => {
      let jwt = thisRef.props.reduxState.user.jwt
      return axios({
        method: 'get',
        url: url,
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        }
      })
      .then(function (response) {
        return response
      })
      .catch((e) => {
        hycon.debug(`${thisRef.constructor.name} pvReq - error`, { e })
        // return Promise.reject("pvReq rejection");
        // resolve it anyway
        let response = { data: {} }
        return Promise.resolve(response)
      })
    }

    let elcomReq = (url) => {
      let jwt = thisRef.props.reduxState.user.jwt
      return axios({
        method: 'get',
        url: url,
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        }
      })
      .then(function (response) {
        return response
      })
      .catch((e) => {
        hycon.debug(`${thisRef.constructor.name} elcomReq - error`, { e })
        UserUtil.authenticationRedirect(e.response, thisRef)
        return Promise.reject('elcomReq rejection')
      })
    }

    let getDistricts = async (building) => {
      hycon.debug(`${thisRef.constructor.name} getDistricts`, { building })
      let gwr = await thisRef.getGWR(building.building.id)
      hycon.debug(`${thisRef.constructor.name} getDistricts - districts`, { gwr })
      return gwr.data.map((element) => {
        return element.housingEgids.gemNr
      })
    }

    let districtReq = (building) => {
      return getDistricts(building).then((districts) => {
        return {
          data: districts.map((district) => {
            return { bfsNummer: district }
          })
        }
      })
    }

    let getBFSNrByPoint = async (lat, lng) => {
      let jwt = thisRef.props.reduxState.user.jwt
      return await axios({
        method: 'get',
        url: `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/api/district-by-point?x=${lng}&y=${lat}&srid=4326`,
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        }
      })
      .then(function (response) {
        return response.data
      })
      .catch((e) => {
        hycon.debug(`${thisRef.constructor.name} getBFSNrByPoint - error`, { e })
        return null
      })
    }

    let reqs = []
    buildings.forEach((building) => {
      hycon.debug(`${thisRef.constructor.name} getBuildingsData - building`, { building })
      const getAllData = (bfsNummer) => {
        let urlPv = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/ocelot-api/ews/${bfsNummer}`
        let urlElcom = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/ocelot-api/elcom/${bfsNummer}`
        let promisePv = pvReq(urlPv)
        let promiseElcom = elcomReq(urlElcom)
        return Promise.all([
          promiseElcom,
          promisePv
        ]).then(values => {
          hycon.debug(`${thisRef.constructor.name} getBuildingsData - all data`, { values })
          // values[1].data.pvtarif2018
          let response = { data: {} }
          response.data.building = building
          values.forEach((value) => {
            let data = value.data
            for (let p in data) {
              if (
                data.hasOwnProperty(p)
              ) {
                response.data[`${p}`] = data[p]
              }
            }
          })
          hycon.debug(`${thisRef.constructor.name} getBuildingsData - all data - returning response`, { response })
          return response
        })
      }
      let req = districtReq(building).then((response) => {
        hycon.debug(`${thisRef.constructor.name} getBuildingsData - building - districtReq`, { response })
        let bfsNummer = response.data[0].bfsNummer
        return getAllData(bfsNummer);
      }).catch(async (error) => {
        hycon.warn(`${thisRef.constructor.name} getBuildingsData - all data - warn`, { error })
        hycon.info(`${thisRef.constructor.name} getBuildingsData - building - fallback districtReq`, { building })
        const lat = building.building.coordinates.latitude
        const lng = building.building.coordinates.longitude
        let bfsNr = await getBFSNrByPoint(lat, lng)
        hycon.info(`${thisRef.constructor.name} getBuildingsData - building - fallback bfsNr`, { bfsNr })
        let bfsNummer = bfsNr[0].bfsNummer;
        return getAllData(bfsNummer);
      })
      reqs.push(req)
    })
    return Promise.all(reqs).then((values) => {
      hycon.debug(`${thisRef.constructor.name} getBuildingsData - values`, {
        values
      })
      return values
    })
  }

  getTargetPVTarif (building) {
    hycon.debug(`${this.constructor.name} getTargetPVTarif`, { building })
    const startingYear = parseInt(moment().format('YYYY'), 10)
    let year = parseInt(moment().format('YYYY'), 10)
    let currentYearToTry = year
    let getTarif = (year) => {
      hycon.debug(`${this.constructor.name} getTargetPVTarif - checking year`, {
        year,
        currentYearToTry,
        building
      })
      let energyValues = building.allData[`pvtarif${year}`][0].energyValues
      const hasPowerValues = (energyValues) => {
        return (
          energyValues.power1 !== null ||
          energyValues.power2 !== null ||
          energyValues.power3 !== null ||
          energyValues.power4 !== null
        )
      }
      if (
        energyValues !== null &&
        energyValues &&
        hasPowerValues(energyValues) &&
        typeof energyValues === 'object'
      ) {
        let pvtarif = {
          pvtarif: building.allData[`pvtarif${year}`],
          year
        }
        hycon.debug(`${this.constructor.name} getTargetPVTarif - returning year`, {
          pvtarif,
          year,
          currentYearToTry,
          building
        })
        return pvtarif
      } else {
        hycon.warn(`no pv data for year ${year}, falling back to ${year - 1}`)
        currentYearToTry = currentYearToTry - 1
        let yearDelta = startingYear - currentYearToTry
        if (yearDelta <= 2) {
          return getTarif(currentYearToTry)
        } else {
          throw new Error(`no pv data for year ${currentYearToTry + 1}, can't fall back to previous year (yearDelta: ${yearDelta})`)
        }
      }
    }
    try {
      return getTarif(currentYearToTry)
    } catch (e) {
      console.warn(e.message)
      let pvtarif = {
        pvtarif: null,
        year: null
      }
      hycon.debug(`${this.constructor.name} getTargetPVTarif - returning null pvtarif data`, { pvtarif })
      return pvtarif
    }
  };

  getTargetElcom (building) {
    hycon.debug(`${this.constructor.name} getTargetElcom`, { building })
    const startingYear = parseInt(moment().format('YYYY'), 10)
    let year = parseInt(moment().format('YYYY'), 10)
    let currentYearToTry = year
    let getElcom = (year) => {
      hycon.debug(`${this.constructor.name} getTargetElcom - checking year`, { year, currentYearToTry, building })
      let elcom = building.allData[`elcom${year}`]
      hycon.debug(`${this.constructor.name} getTargetElcom - got elcom`, { elcom })
      if (
        elcom !== null &&
        elcom.length > 0
      ) {
        let data = {
          elcom: elcom,
          year
        }
        hycon.debug(`${this.constructor.name} getTargetElcom - returning year`, {
          data,
          year,
          currentYearToTry,
          building
        })
        return data
      } else {
        hycon.warn(`no pv data for year ${year}, falling back to ${year - 1}`)
        currentYearToTry = currentYearToTry - 1
        let yearDelta = startingYear - currentYearToTry
        if (yearDelta <= 2) {
          return getElcom(currentYearToTry)
        } else {
          throw new Error(`no pv data for year ${currentYearToTry + 1}, can't fall back to previous year (yearDelta: ${yearDelta})`)
        }
      }
    }
    try {
      return getElcom(currentYearToTry)
    } catch (e) {
      console.warn(e.message)
      let data = {
        elcom: null,
        year: null
      }
      hycon.debug(`${this.constructor.name} getTargetElcom - returning null elcom data`, { data })
      return data
    }
  };

  componentWillUnmount () {
    hycon.debug(`${this.constructor.name} componentWillUnmount`, { props: this.props })
    panelController.dispatchLeave(window)
  }
}

/*
export default {
  TestSolarElectricityCard: SolarElectricityCard,
  SolarElectricityCard: withTranslation(['panel_solar_electricity'])(SolarElectricityCard)
}*/

export const TestSolarElectricityCard = SolarElectricityCard

export default withTranslation(['panel_solar_electricity'])(SolarElectricityCard)

