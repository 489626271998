import { connect } from 'react-redux'
// import React from 'react';
import App from '../components/app/App'
import * as actions from '../actions/actions'

const mapStateToProps = (state, ownProps) => {
  return {
    reduxState: state,
    ...ownProps
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    reduxDispatch: (actionName, args) => {
      let action = actions[actionName]
      console.debug('dispatching', { actionName, args, action, ownProps })
      dispatch(action(args))
    },
    ...ownProps
  }
}

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

export default AppContainer
