import React, {Component} from 'react';
 import HyperConsole from "../hyper_console/hyper-console";
import "./UserManagement.css";
import SignUp from "./sign_up/SignUp";
import AccountConfirm from "./account_confirm/AccountConfirm";
import HyperMenu from "../hyper_menu/HyperMenu";
import Navbar from "../../components/sep_map/component_navbar/Navbar";

import SignIn from "./sign_in/SignIn";
import EditProfile from "./edit/EditProfile";
import Recover from "./recover/Recover";
import Resend from "./sign_up/resend/Resend";
import Reset from "./recover/reset/Reset";
import { withTranslation } from 'react-i18next'
import {Redirect} from "react-router-dom";
import {ProtectedRoute} from "../router/Router";

const PAGE_SIGNUP = "signup";
const PAGE_SIGNUP_RESEND = "signup-resend";
const PAGE_EDIT_USER = "edit";
const PAGE_RECOVER_ACCOUNT = "recover";
const ACCOUNT_CONFIRM = "account-confirm";
const ACCOUNT_PASSWORD_RESET = "recovery";
const SIGN_IN = "signin";

let hycon = new HyperConsole({isEnabled: false, name:__filename}).myConsole;

class UserManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: null,
            component: null
        };

        hycon.debug(`${this.constructor.name} constructor`, {props: this.props});
    }

    componentDidMount() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} componentDidMount`, {props: this.props});
        thisRef.updatePage();
    }

    setStateProperty(propertyName, propertyValue) {
        let thisRef = this;
        return new Promise((res) => {
            thisRef.setState((p) => {
                const obj = {};
                obj[propertyName] = propertyValue;
                return Object.assign({}, p, obj);
            }, () => {
                hycon.debug(`${this.constructor.name} setStateProperty - newState`, {newState: thisRef.state});
                res();
            })
        });
    }

    static getDerivedStateFromProps(props, state) {
        hycon.debug(`${"UserManagement"} getDerivedStateFromProps`, {props, state});
        if (!state) {
            hycon.debug(`${"UserManagement"} getDerivedStateFromProps - only props have been updated`, {
                props,
                state
            });
        }
        return state;
    }

    shouldComponentUpdate(nextProps, nextState) {

        hycon.debug(`${this.constructor.name} shouldComponentUpdate`, {nextProps, props: this.props, nextState});
        return true;
    }

    updatePage() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} updatePage`, {thisRef});


        let page = thisRef.props.match.params.page;

        // if page changes update
        if (thisRef.state.page !== page) {
            thisRef.setStateProperty("page", page).then(() => {
                hycon.debug(`${this.constructor.name} updatePage setStateProperty`, {page});
            });
        }
    }

    getComponent(page) {
        let thisRef = this;
        switch (page) {
            case PAGE_SIGNUP:
                return (
                    <SignUp {...thisRef.props}/>
                );
            case ACCOUNT_CONFIRM:
                return (
                    <AccountConfirm {...thisRef.props}/>
                );
            case SIGN_IN:
                return (
                    <SignIn {...thisRef.props}/>
                );
            case PAGE_EDIT_USER:
                return (
                    <ProtectedRoute
                        redirectComponent={<Redirect to="/user/signin/?continuePath=/user/edit/" />}
                    >
                        <EditProfile {...thisRef.props}/>
                    </ProtectedRoute>
                );
            case PAGE_RECOVER_ACCOUNT:
                return (
                    <Recover  {...thisRef.props}/>
                );
            case PAGE_SIGNUP_RESEND:
                return (
                    <Resend  {...thisRef.props}/>
                );
            case ACCOUNT_PASSWORD_RESET:
                return (
                    <Reset {...thisRef.props}/>
                );
            case null:
                return null;
            default:
                throw new Error("Unknown user management page.");
        }
    }

    render() {
        let thisRef = this;
        return (
            <div className="UserManagement">
                <HyperMenu
                    ref={(c)=>{thisRef.menu=c;}}
                    {...thisRef.props}
                    content={(
                        <div className={`menu-content-wrapper`}>
                            <Navbar
                                {...this.props}
                                onMenuToggle={() => {
                                    console.log("debug:onMenuToggle-usermanagement")
                                    thisRef.menu.setStateProperty("isOpen", !thisRef.menu.getState().isOpen);
                                }}
                                isSearchDisplayed={false}
                            />
                            {thisRef.getComponent(thisRef.state.page)}
                        </div>
                    )}
                />
            </div>
        );
    }

    componentDidUpdate() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} componentDidUpdate`, {props: this.props});
        thisRef.updatePage();
    }

    componentWillUnmount() {
        /*let thisRef = this;*/
        hycon.debug(`${this.constructor.name} componentWillUnmount`, {props: this.props});
    }
}

export default withTranslation()(UserManagement);
