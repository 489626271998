import geoimpact from './geoimpact/geoimpact'
const giEnv = process.env.REACT_APP_GI_ENV
let env = {}

if (giEnv === 'production') {
  env.HOST = '???'
  env.ENABLE_SENTRY = true
  env.ENABLE_HYCON_REMOTE_LOGGER = false
  env.REACT_APP_GI_ENV = giEnv
  env.API_GATEWAY_BASE = 'https://services.swissenergyplanning.ch'
  env.AZURE_MAP_PROXY = {
    BASE: "https://swisstopo-proxy.azurewebsites.net",
    PATHS: {
      WMTS: "wmts-geo-admin-ch",
      WMS: "wms-geo-admin-ch"
    }
  }
  env.GOOGLE_RECAPTCHA_SITE_KEY = '6LcYOnIUAAAAAAGEjk6W3GM50i2em0hAs5fe17WA'
  env.GOOGLE_ANALYTICS_ID = 'UA-104457739-3'
  env.NOTIFICATION_SERVER_BASE_URL = 'https://halley-notification.herokuapp.com'
  env.NOTIFICATION_SERVER_PUBLIC_KEY = 'BAcWUnWWr5S0yHtElPMC-zB84n_gIFjwZsKLq5y7UwUx1OPjFKk87nA02-hNsLND84aMq0S2TV8nLbNm6l8yYUY'
  env.AZURE_STORAGE_BASE = 'https://geoimpactstorage.blob.core.windows.net'
  env.LOCIZE_PROJECT_ID = 'f9cf0720-4091-4c6c-8140-90e77341d1f0'
  env.LOCIZE_API_KEY = '61308123-7b73-4f41-b022-b4f5884afd37'
  env.LOCIZE_PROJECT_VERSION = "latest"
  env.geoimpact = geoimpact
} else if (giEnv === 'development') {
  env.HOST = '???'
  env.ENABLE_SENTRY = true
  env.ENABLE_HYCON_REMOTE_LOGGER = true
  env.REACT_APP_GI_ENV = giEnv
  env.API_GATEWAY_BASE = 'https://development.services.swissenergyplanning.ch'
  env.AZURE_MAP_PROXY = {
    BASE: "https://swisstopo-proxy.azurewebsites.net",
    PATHS: {
      WMTS: "wmts-geo-admin-ch-development",
      WMS: "wms-geo-admin-ch"
    }
  }
  env.GOOGLE_RECAPTCHA_SITE_KEY = '6LcYOnIUAAAAAAGEjk6W3GM50i2em0hAs5fe17WA'
  env.GOOGLE_ANALYTICS_ID = 'UA-104457739-4'
  env.NOTIFICATION_SERVER_BASE_URL = 'https://halley-notification.herokuapp.com'
  env.NOTIFICATION_SERVER_PUBLIC_KEY = 'BAcWUnWWr5S0yHtElPMC-zB84n_gIFjwZsKLq5y7UwUx1OPjFKk87nA02-hNsLND84aMq0S2TV8nLbNm6l8yYUY'
  env.AZURE_STORAGE_BASE = 'https://geoimpactstorage.blob.core.windows.net'
  env.LOCIZE_PROJECT_ID = 'f9cf0720-4091-4c6c-8140-90e77341d1f0'
  env.LOCIZE_API_KEY = '4f55bf84-ae4d-4124-9dae-666d5c33340f'
  env.LOCIZE_PROJECT_VERSION = "development"
  env.geoimpact = geoimpact
} else if (giEnv === 'staging') {
  env.HOST = '???'
  env.ENABLE_SENTRY = false
  env.ENABLE_HYCON_REMOTE_LOGGER = false
  env.REACT_APP_GI_ENV = giEnv
  env.API_GATEWAY_BASE = 'https://staging.services.swissenergyplanning.ch'
  env.AZURE_MAP_PROXY = {
    BASE: "https://swisstopo-proxy.azurewebsites.net",
    PATHS: {
      WMTS: "wmts-geo-admin-ch-staging",
      WMS: "wms-geo-admin-ch"
    }
  }
  env.GOOGLE_RECAPTCHA_SITE_KEY = '6LcYOnIUAAAAAAGEjk6W3GM50i2em0hAs5fe17WA'
  env.GOOGLE_ANALYTICS_ID = 'UA-104457739-5'
  env.NOTIFICATION_SERVER_BASE_URL = 'https://halley-notification.herokuapp.com'
  env.NOTIFICATION_SERVER_PUBLIC_KEY = 'BAcWUnWWr5S0yHtElPMC-zB84n_gIFjwZsKLq5y7UwUx1OPjFKk87nA02-hNsLND84aMq0S2TV8nLbNm6l8yYUY'
  env.AZURE_STORAGE_BASE = 'https://geoimpactstorage.blob.core.windows.net'
  env.LOCIZE_PROJECT_ID = 'f9cf0720-4091-4c6c-8140-90e77341d1f0'
  env.LOCIZE_API_KEY = '335f22a7-c8a5-4506-a269-12785af8c2c7'
  env.LOCIZE_PROJECT_VERSION = "staging"
  env.geoimpact = geoimpact
} else if (giEnv === 'test') {
  env.HOST = '???'
  env.ENABLE_SENTRY = true
  env.ENABLE_HYCON_REMOTE_LOGGER = false
  env.REACT_APP_GI_ENV = giEnv
  env.API_GATEWAY_BASE = 'https://development.services.swissenergyplanning.ch'
  env.AZURE_MAP_PROXY = {
    BASE: "https://swisstopo-proxy.azurewebsites.net",
    PATHS: {
      WMTS: "wmts-geo-admin-ch-development",
      WMS: "wms-geo-admin-ch"
    }
  }
  env.GOOGLE_RECAPTCHA_SITE_KEY = '6LcYOnIUAAAAAAGEjk6W3GM50i2em0hAs5fe17WA'
  env.GOOGLE_ANALYTICS_ID = 'UA-130530458-1'
  env.NOTIFICATION_SERVER_BASE_URL = 'https://halley-notification.herokuapp.com'
  env.NOTIFICATION_SERVER_PUBLIC_KEY = 'BAcWUnWWr5S0yHtElPMC-zB84n_gIFjwZsKLq5y7UwUx1OPjFKk87nA02-hNsLND84aMq0S2TV8nLbNm6l8yYUY'
  env.AZURE_STORAGE_BASE = 'https://geoimpactstorage.blob.core.windows.net'
  env.LOCIZE_PROJECT_ID = 'f9cf0720-4091-4c6c-8140-90e77341d1f0'
  env.LOCIZE_API_KEY = '4f55bf84-ae4d-4124-9dae-666d5c33340f'
  env.LOCIZE_PROJECT_VERSION = "development"
  env.geoimpact = geoimpact
} else if (giEnv === 'experimental') {
  env.HOST = '???'
  env.ENABLE_SENTRY = true
  env.ENABLE_HYCON_REMOTE_LOGGER = true
  env.REACT_APP_GI_ENV = giEnv
  env.API_GATEWAY_BASE = 'https://services.swissenergyplanning.ch'
  env.AZURE_MAP_PROXY = {
    BASE: "https://swisstopo-proxy.azurewebsites.net",
    PATHS: {
      WMTS: "wmts-geo-admin-ch-development",
      WMS: "wms-geo-admin-ch"
    }
  }
  env.GOOGLE_RECAPTCHA_SITE_KEY = '6LcYOnIUAAAAAAGEjk6W3GM50i2em0hAs5fe17WA'
  env.GOOGLE_ANALYTICS_ID = 'UA-130530458-1'
  env.NOTIFICATION_SERVER_BASE_URL = 'https://halley-notification.herokuapp.com'
  env.NOTIFICATION_SERVER_PUBLIC_KEY = 'BAcWUnWWr5S0yHtElPMC-zB84n_gIFjwZsKLq5y7UwUx1OPjFKk87nA02-hNsLND84aMq0S2TV8nLbNm6l8yYUY'
  env.AZURE_STORAGE_BASE = 'https://geoimpactstorage.blob.core.windows.net'
  env.LOCIZE_PROJECT_ID = 'f9cf0720-4091-4c6c-8140-90e77341d1f0'
  env.LOCIZE_API_KEY = '4f55bf84-ae4d-4124-9dae-666d5c33340f'
  env.LOCIZE_PROJECT_VERSION = "development"
  env.geoimpact = geoimpact
} else {
  throw new Error(`Invalid REACT_APP_GI_ENV environment variable: ${giEnv}`)
}

export default env
