import React, { Component, Suspense } from 'react'
import HyperConsole from '../hyper_console/hyper-console'

import 'normalize.css/normalize.css'
import './App.css'
import './client_styles/Global.css'

import Router from '../router/Router'
import { Helmet } from 'react-helmet'
import 'fomantic-ui-css/semantic.min.css';

import CookiePolicy from '../cookie_policy/CookiePolicy'

import GenericError from '../error/GenericError'
import { UserUtil } from '../user_management/util/user-util'
import { Button } from 'semantic-ui-react'
import { CookieUtil } from '../user_management/cookies/cookie-util'
import { GoogleAnalytics } from '../google_analytics/GoogleAnalytics'
import { ServiceWorkerUtility } from '../service_worker_util/ServiceWorkerUtil'
import SuspenceLoader from '../suspence_loader/SuspenceLoader'
import { initLangFromCookie } from '../settings/language_switch/LanguageSwitch'
import { Translation } from 'react-i18next'
import { initLanguageHeaderInterceptor, removeLanguageHeaderInterceptor } from '../../i18n/language-request-interceptor'
import RecaptchaController from '../recaptcha_controller/RecaptchaController'
import { initAuthInterceptor, removeAuthInterceptor } from '../user_management/util/authentitication-inteceptors'
import WriteLock from '../write_lock/WriteLock'
import qs from 'qs'
import { TokenUtil } from '../user_management/util/token-util'
import { ThemeProvider, StyledEngineProvider } from '@mui/system';

import KibanaEvents from '../kibana_events/KibanaEvents'
import {themes} from "../mui/themes";


const SEPTheme = React.lazy(() => import('./client_styles/SEPTheme'))
const PraedictioTheme = React.lazy(() => import('./client_styles/PraedictioTheme'))

const bowser = require('bowser')
const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

const getBrowserInfo = () => {
  const browser = bowser.getParser(window.navigator.userAgent)
  const isValidBrowser = browser.satisfies({
    // declare browsers per OS
    windows: {
      'internet explorer': '>11',
    },

    // or in general
    edge: '>50',
    chrome: '>60',
    firefox: '>60',
    opera: '>50',
  })
  return { isValidBrowser, browser }
}

const browserCheck = (thisRef) => {
  try {
    let data = getBrowserInfo()
    hycon.info(`${this.constructor.name} componentWillMount browser`, { data })
  } catch (e) {
    // hycon.warn(`${this.constructor.name} componentWillMount browser detection error`, e);

    const isIE = () => {
      let ua = window.navigator.userAgent //Check the userAgent property of the window.navigator object
      let msie = ua.indexOf('MSIE ') // IE 10 or older
      let trident = ua.indexOf('Trident/') //IE 11
      return (msie > 0 || trident > 0)
    }

    if (isIE()) {
      thisRef.setStateProperty('error', new Error('Ihr Browser (Internet Explorer) wird nicht unterstützt. Wir empfehlen Chrome, Firefox, Opera, Safari oder Edge.'))
    }
  }
}

const preventPullDownRefresh = () => {
  document.addEventListener('touchstart', () => {
  }, { passive: false })
  document.addEventListener('touchmove', () => {
  }, { passive: false })
}

/*
* This function gets called after the app loading or the login.
* It retrieves user jwt from the cookies and tenant infos and
* finally injects the into the SEPContext
* */
async function updateSEPContextOnAutologin(user, thisRef, props){
  await UserUtil.injectUserinfoIntoRedux(user, thisRef);
  let mandant;
  if(
      user?.user?.jwt
      && props.SEPContext.env
  ){
    mandant = (await UserUtil.getMandant(user?.user?.jwt, props.SEPContext.env)).data;
  }
  const newSEPContext = {
    SEPContext: {
      ...props.SEPContext,
      user: {
        ...props.SEPContext.user,
        jwt: user?.user?.jwt,
        jwtParsed: user?.user?.jwt ? UserUtil.parseJWT(user?.user?.jwt) : user?.user?.jwt,
        mandant
      },
      themes,
      theme: thisRef.props.appStateServer.clients[0] === 'praedictio' ? themes.praedictio : themes.geoimpact
    }
  };
  props.updateSEPContext(newSEPContext)
  return thisRef.setStateProperty('isReady', true).then(() => {
    return user
  })
}
/*
* This function gets called when an error occurs during app loading or login.
* One of such errors could be an invalid token from the cookies.
* It injects an undefinied jwt into the SEPContext.
* */
async function onAutologinError(thisRef, props){
  const newSEPContext = {
    SEPContext: {
      ...props.SEPContext,
      user: {
        ...props.SEPContext.user,
        jwt: undefined,
        jwtParsed: undefined,
        mandant: undefined
      },
      themes,
      theme: thisRef.props.appStateServer.clients[0] === 'praedictio' ? themes.praedictio : themes.geoimpact
    }
  };
  props.updateSEPContext(newSEPContext)
  return thisRef.setStateProperty('isReady', true).then(() => {
    return null
  })
}

class App extends Component {
  constructor (props, state) {
    super(props, state)
    // let thisRef = this;

    hycon.assignOpts({ name: this.constructor.name })
    this.state = {
      isReady: false,
      error: null,
      pwaPrompt: null,
      languageIntergeptor: null,
      authInterceptor: null
    }
    this.setAppError = this.setAppError.bind(this)
    // eventually redirect the user to the https version
    // Redirect.httpsRedirect(env);
  }

  componentWillMount () {
    let thisRef = this
    browserCheck(thisRef)
    preventPullDownRefresh()
  }

  componentDidMount () {
    let thisRef = this
    let props = thisRef.props
    hycon.debug(`${this.constructor.name} componentDidMount`, { props: this.props })
    const getPublicAccessToken = () => {
      let props = thisRef.props
      try {
        let search = window.location.href
        search = search.substr(1, search.length)
        const parsed = qs.parse(search)
        const jwt = parsed.jwt
        return jwt
      } catch (e) {
        hycon.error(`${this.constructor.name} getPublicAccessToken`, { props, e })
        return null
      }
    }
    let publicAccessToken = getPublicAccessToken()
    if (!publicAccessToken) {
      initLangFromCookie(thisRef)
      let languageIntergeptor = initLanguageHeaderInterceptor(thisRef)
      thisRef.setStateProperty('languageIntergeptor', languageIntergeptor)

      let authInteceptor = initAuthInterceptor(thisRef)
      thisRef.setStateProperty('authInteceptor', authInteceptor)

      // install google analytics
      GoogleAnalytics.init().then(() => {
        try {
          let user = thisRef.props.reduxState.user.user
          hycon.debug(`${thisRef.constructor.name} componentDidMount - analytics`, { user: user })
          if (user && user.userName) {
            GoogleAnalytics.sendGenerigEvent(
              `access-from ${user.userName}`,
              'sep-access',
              'User Management',
              false
            )
          }
        } catch (e) {
          GoogleAnalytics.sendGenerigEvent(
            `access-from ???`,
            'sep-access',
            'User Management',
            false
          )
        }
      })

      ServiceWorkerUtility.initSEPSW()

      let installPrompt = () => {
        hycon.log('installPrompt..')
        window.addEventListener('beforeinstallprompt', (e) => {
          // Prevent Chrome 67 and earlier from automatically showing the prompt
          hycon.log('installPrompt.. beforeinstallprompt')
          e.preventDefault()
          // Stash the event so it can be triggered later.
          thisRef.setStateProperty('pwaPrompt', e)
        })
      }
      installPrompt()

      hycon.debug(`${this.constructor.name} componentDidMount - no public access`, { publicAccessToken })
      return UserUtil.getUserFromCookieAndUpdateRedux(thisRef)
      .then(async (user) => {
        hycon.debug(`${this.constructor.name} componentDidMount - about to injecting userinfo into redux and SEPContext`, user)
        return updateSEPContextOnAutologin(user, thisRef, props)
      }).catch((e) => {
        hycon.error(e);
        return onAutologinError(thisRef, props);
      })
    } else {
      hycon.debug(`${this.constructor.name} componentDidMount - public access`, { publicAccessToken })

      // delete the token from the cookies and redux
      hycon.debug(`${thisRef.constructor.name} silently logging out the old user`, { props })
      UserUtil.deleteUserInReduxAndCookies({ props })
      TokenUtil.updateTokenInRedux(null, { props })
      TokenUtil.updateUserInRedux(null, { props })
      props.reduxDispatch('updateSession', {})
      CookieUtil.deleteCookie(CookieUtil.constants.names.USER)
      hycon.debug(`${this.constructor.name} componentDidMount - public access - deleted user cookie`, { publicAccessToken })

      initLangFromCookie(thisRef)
      let languageIntergeptor = initLanguageHeaderInterceptor(thisRef)
      thisRef.setStateProperty('languageIntergeptor', languageIntergeptor)

      // install google analytics
      GoogleAnalytics.init().then(() => {
        try {
          let user = thisRef.props.reduxState.user.user
          hycon.debug(`${thisRef.constructor.name} componentDidMount - analytics`, { user: user })
          if (user && user.userName) {
            GoogleAnalytics.sendGenerigEvent(
              `access-from ${user.userName}`,
              'sep-access',
              'User Management',
              false
            )
          }
        } catch (e) {
          GoogleAnalytics.sendGenerigEvent(
            `access-from ???`,
            'sep-access',
            'User Management',
            false
          )
        }
      })

      ServiceWorkerUtility.initSEPSW()

      let installPrompt = () => {
        hycon.log('installPrompt..')
        window.addEventListener('beforeinstallprompt', (e) => {
          // Prevent Chrome 67 and earlier from automatically showing the prompt
          hycon.log('installPrompt.. beforeinstallprompt')
          e.preventDefault()
          // Stash the event so it can be triggered later.
          thisRef.setStateProperty('pwaPrompt', e)
        })
      }
      installPrompt()

      return UserUtil.getUserFromCookieAndUpdateRedux(thisRef)
      .then(async (user) => {
        hycon.debug(`${this.constructor.name} componentDidMount - about to injecting userinfo into redux and SEPContext`, user)
        return updateSEPContextOnAutologin(user, thisRef, props)
      }).catch((e) => {
        hycon.error(e);
        return onAutologinError(thisRef, props)
      })
    }
  }

  componentWillReceiveProps (newProps) {
    hycon.debug(`${this.constructor.name} componentWillReceiveProps`, { newProps, props: this.props })
  }

  shouldComponentUpdate (nextProps, nextState) {
    // hycon.debug(`${this.constructor.name} shouldComponentUpdate`, {nextProps, props: this.props, nextState});
    return true
  }

  componentWillUpdate () {
    // hycon.debug(`${this.constructor.name} componentWillUpdate`, {props: this.props});
  }

  setStateProperty (propertyName, propertyValue) {
    let thisRef = this
    return new Promise((res) => {
      thisRef.setState((p) => {
        const obj = {}
        obj[propertyName] = propertyValue
        return Object.assign({}, p, obj)
      }, () => {
        // hycon.debug(`${this.constructor.name} setStateProperty - newState`, {newState: thisRef.state});
        res()
      })
    })
  }

  setAppError (error) {
    let thisRef = this
    return thisRef.setStateProperty('error', error)
  }

  hasError () {
    let thisRef = this
    return !!thisRef.state.error
  }

  getErrorMarkup () {
    let thisRef = this
    let geoimpact = thisRef.props.reduxState.env.geoimpact
    return (
      <GenericError
        component={(
          <div>
            <h1>Ein Fehler ist aufgetreten.</h1>
            <h2>{thisRef.state.error.message}</h2>
            <h3>Bitte wenden Sie sich an unser Support-Team</h3>
            <p>E-Mail: <a href={`mailto:${geoimpact.support_email}`}>{geoimpact.support_email}</a></p>
          </div>
        )}
      />
    )
  }

  getHelmet () {
    // let thisRef = this;
    let helmet = (
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Open%20Sans"
        />
      </Helmet>
    )
    return helmet
  }

  render () {
    let thisRef = this
    let componentToRender = null
    hycon.debug(`${thisRef.constructor.name} render `, { state: thisRef.state })
    if (thisRef.hasError()) {
      // hycon.debug(`${thisRef.constructor.name} render - error`, {error: thisRef.state.error});
      componentToRender = thisRef.getErrorMarkup()
    } else {
      if (!thisRef.state.isReady) {
        // delay the rendering of the sub components if the cookie has not been read yet and redux has not been updated
        componentToRender = <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}></div>
      } else {
        componentToRender = (
          <div className="App">
            {
              (() => {
                hycon.log('importing global theme for client', thisRef.props)
                if (thisRef.props.appStateServer.clients[0] === 'praedictio') {
                  return <PraedictioTheme/>
                } else {
                  return <SEPTheme/>
                }
              })()
            }
            <div id={'react-version'} style={{ display: 'none' }}>Running React {React.version}</div>
            {thisRef.getHelmet()}
            <WriteLock {...this.props}/>
            <CookiePolicy
              {...this.props}
            />
            <KibanaEvents {...this.props}></KibanaEvents>
            <Router
              {...this.props}
              appState={thisRef.state}
              setAppError={thisRef.setAppError}
            />
            <RecaptchaController {...this.props}/>
            {(() => {
              let key = CookieUtil.constants.names.PWA
              let pwaCookieString = CookieUtil.getCookie(key)
              hycon.debug(`${thisRef.constructor.name} - ${key} cookie string found`, { pwaCookieString })
              let parsedPWACookie = null
              try {
                parsedPWACookie = JSON.parse(pwaCookieString)
              } catch (e) {
                hycon.warn(`${thisRef.constructor.name} - warning - could not parse ${key} ccookie`, { pwaCookieString })
              }
              if (parsedPWACookie && parsedPWACookie.doNotShow === true) {
                // hide the banner
                return null
              }
              if (thisRef.state.pwaPrompt) {
                return (
                  <div className={'pwa-banner'}>
                    <Button size={'medium'} primary onClick={() => {
                      let ctx = thisRef
                      thisRef.state.pwaPrompt.prompt()
                      thisRef.state.pwaPrompt.userChoice
                      .then((choiceResult) => {
                        if (choiceResult.outcome === 'accepted') {
                          console.log('User accepted the A2HS prompt')
                          CookieUtil.setCookie(CookieUtil.constants.names.PWA, JSON.stringify({ doNotShow: true }))
                          ctx.setStateProperty('pwaPrompt', null)
                          console.log('reloading the current window')
                          window.location.reload()
                        } else {
                          console.log('User dismissed the A2HS prompt')
                        }
                      })
                    }}>
                      <Translation ns={['various']}>
                        {
                          (t) => <p>{t('various:label-install-sep')}</p>
                        }
                      </Translation>
                    </Button>
                    <Button size={'medium'} onClick={() => {
                      let ctx = thisRef
                      ctx.setStateProperty('pwaPrompt', null)
                      CookieUtil.setCookie(CookieUtil.constants.names.PWA, JSON.stringify({ doNotShow: true }))
                    }}>
                      <Translation ns={['various']}>
                        {
                          (t) => <p>{t('various:label-install-sep-no-thanks')}</p>
                        }
                      </Translation>
                    </Button>
                  </div>
                )
              } else {
                return null
              }
            })()}
          </div>
        )
      }
    }
    return (
      <Suspense fallback={<SuspenceLoader {...thisRef.props}/>}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={
            (() => {
              hycon.log('importing global theme for client', thisRef.props)
              if (thisRef.props.appStateServer.clients[0] === 'praedictio') {
                return themes.praedictio
              } else {
                return themes.geoimpact
              }
            })()
          }>
            <React.StrictMode>
              {componentToRender}
            </React.StrictMode>
          </ThemeProvider>
        </StyledEngineProvider>
      </Suspense>
    );
  }

  componentDidUpdate () {
    // hycon.debug(`${this.constructor.name} componentDidUpdate`, {props: this.props});
  }

  componentWillUnmount () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} componentWillUnmount`, { props: this.props })
    removeLanguageHeaderInterceptor(thisRef.state.languageIntergeptor)
    removeAuthInterceptor(thisRef.state.authInterceptor)
  }

  componentDidCatch (error, errorInfo) {
    // let thisRef = this;
    console.error(`${this.constructor.name} componentDidCatch`, { error, errorInfo })
  }
}

export default App
