import React, { Component } from 'react';
import HyperConsole from "../hyper_console/hyper-console";

import {Button} from 'semantic-ui-react';

import "./CookiePolicy.css";
import {CookieUtil} from "../user_management/cookies/cookie-util";
import { withTranslation } from 'react-i18next';

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV;
let hycon = null;
if (REACT_APP_GI_ENV === "development") {
    hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
} else {
    hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
}

class CookiePolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDisplayed: false
        };
        hycon.debug(`${this.constructor.name} constructor`, {props: this.props});
    }

    componentWillMount() {
        // let thisRef = this;
        hycon.debug(`${this.constructor.name} componentWillMount`, {props: this.props});
    }

    componentDidMount() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} componentDidMount`, {props: this.props});

        let key = CookieUtil.constants.names.COOKIE;
        let cookieString = CookieUtil.getCookie(key);
        hycon.debug(`${thisRef.constructor.name} - ${key} cookie string found`, {cookieString});
        let parsedCookie = null;
        try {
            parsedCookie = JSON.parse(cookieString);
        }catch (e) {
            hycon.warn(`${thisRef.constructor.name} - warning - could not parse ${key} cookie`, {cookieString});
        }

        if(parsedCookie && parsedCookie.isAccepted === true){
            // hide the banner
            thisRef.setIsDisplayed(false);
        } else {
            // show the banner
            if(window.location.pathname !== "/open/remote/"){
                thisRef.setIsDisplayed(true);
            }
        }
    }

    setIsDisplayed(isDisplayed){
        return new Promise((res) => {
            this.setState((p) => {
                return {...p, isDisplayed}
            }, () => {
                res();
            });
        });
    }

    componentWillReceiveProps() {
        // let thisRef = this;
        hycon.debug(`${this.constructor.name} componentWillReceiveProps`, {props: this.props});
    }

    shouldComponentUpdate(nextProps, nextState) {
        // let thisRef = this;
        hycon.debug(`${this.constructor.name} shouldComponentUpdate`, {nextProps, props: this.props, nextState});
        return true;
    }

    componentWillUpdate() {
        // let thisRef = this;
        hycon.debug(`${this.constructor.name} componentWillUpdate`, {props: this.props});
    }

    render() {
        let thisRef = this;
        if(thisRef.state.isDisplayed){
            return (
              <div className="CookiePolicy">
                  <h5>{thisRef.props.i18n.t("cookies:label-title")}</h5>
                  <p>{thisRef.props.i18n.t("cookies:label-policy")}</p>
                  <div className={"buttons"}>
                      <Button
                        onClick={() => {
                            thisRef.setIsDisplayed(false).then(() => {
                                CookieUtil.setCookie(CookieUtil.constants.names.COOKIE, JSON.stringify({isAccepted: true}));
                            });
                        }}
                      >
                          {thisRef.props.i18n.t("cookies:label-understood")}
                      </Button>
                      <Button
                        onClick={() => {
                            window.open(`https://${thisRef.props.i18n.language.split('-')[0]}.swissenergyplanning.ch/datenschutz`, '_blank')
                        }}
                      >
                          {thisRef.props.i18n.t("cookies:label-more")}
                      </Button>
                  </div>
              </div>
            );
        } else {
            return null;
        }
    }

    componentDidUpdate() {
        hycon.debug(`${this.constructor.name} componentDidUpdate`, {props: this.props});
    }

    componentWillUnmount() {
        // let thisRef = this;
        hycon.debug(`${this.constructor.name} componentWillUnmount`, {props: this.props});
    }
}

export default withTranslation()(CookiePolicy);

