import React, { Component } from 'react';
import HyperConsole from "../hyper_console/hyper-console";
import "./SuspenceLoader.css";
import env from "../../env/env";

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV;
let hycon = null;
if(REACT_APP_GI_ENV === "development"){
  hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
} else {
  hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
}

class SuspenceLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // let thisRef = this;
    hycon.debug(`${this.constructor.name} constructor`, {props: this.props});
  }

  componentDidMount() {
    // let thisRef = this;
    hycon.debug(`${this.constructor.name} componentDidMount`, {props: this.props});
  }


  static getDerivedStateFromProps(props, state) {
    hycon.debug(`${"SuspenceLoader"} getDerivedStateFromProps`, {props, state});
    if(!state){
      hycon.debug(`${"SuspenceLoader"} getDerivedStateFromProps - only props have been updated`, {props, state});
    }
    return state;
  }

  shouldComponentUpdate(nextProps, nextState) {
    // let thisRef = this;
    hycon.debug(`${this.constructor.name} shouldComponentUpdate`, {nextProps, props: this.props, nextState});
    return true;
  }

  render() {
    let thisRef = this;
    let logoUrl = `${env.AZURE_STORAGE_BASE}/public/logo/${thisRef.props.appStateServer.clients[0]}/Logo-SEP-web.png`;
    return (
      <div className="SuspenceLoader">
        <img className={"logo"} src={logoUrl} alt={"client logo"}/>
        <h2>Loading...</h2>
      </div>
    );
  }

  componentDidUpdate() {
    // let thisRef = this;
    hycon.debug(`${this.constructor.name} componentDidUpdate`, {props: this.props});
  }

  componentWillUnmount() {
    // let thisRef = this;
    hycon.debug(`${this.constructor.name} componentWillUnmount`, {props: this.props});
  }

  componentDidCatch(error, errorInfo) {
    let thisRef = this;
    hycon.debug(`${thisRef.constructor.name} componentDidCatch`, {error, errorInfo});
    throw error;
  }
}

export default SuspenceLoader;
