import React, { useTranslation } from 'react-i18next';
import {
  Button, Dimmer, Dropdown, Loader, Modal,
} from 'semantic-ui-react';
import { useContext, useState } from 'react';
import { useFavoriteCtx } from '../../FavoriteProvider';
import { LOCIZE_PANEL_NS, REACT_APP_GI_ENV } from '../../constants';
import HyperConsole from '../../../hyper_console/hyper-console';
import FavoriteApi from '../../services/FavoriteApi';
import SEPContext from '../../../../contexts/sep-context/SEPContext';

let hycon = null;
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
}

export default function ModalFormGroup(props) {
  const {
    targetFavorites, getAllUniqueGroups, updateContext, onModalClose,
  } = props;
  const hookName = 'GroupForm';
  const favoriteContext = useFavoriteCtx();
  const { t } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false });
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user: { jwt }, env } = useContext(SEPContext).SEPContext;

  const getOptions = () => {
    const allUniqueGroups = getAllUniqueGroups();
    const allOptions = [...new Set([...options, ...allUniqueGroups])];
    return allOptions.map((option) => ({ text: option, value: option }));
  };
  const getValue = () => value;
  const updateGroups = async (targetGroup) => {
    hycon.debug(`${hookName} updateGroups`, { targetGroup });
    const patchPromiseArray = targetFavorites
      .map(async (favorite) => FavoriteApi
        .patchGenericFavorite(jwt, env, favorite, { group: targetGroup }));
    await Promise.all(patchPromiseArray);
  };
  const getUniqueTargetGroups = () => {
    const targetGroups = [];
    targetFavorites.forEach((fav) => {
      targetGroups.push(fav.group);
    });
    return [...new Set(targetGroups)];
  };
  return (
    <>
      <Modal.Header>
        {t('favorites:groupname-edit-mode')}
      </Modal.Header>
      <Modal.Content>
        <div className="active-email">
          <div>
            <h5>{t('favorites:groupname-edit-explaination-favorites-from-the-groups')}</h5>
            {
                getUniqueTargetGroups().join(', ')
            }
            {
                getUniqueTargetGroups().join(', ') ? '.' : null
            }
          </div>
        </div>
        <br />
        <Dimmer active={isLoading}>
          <Loader size="small" />
        </Dimmer>
        <Dropdown
          options={getOptions()}
          placeholder={`${t('favorites:select-target-group')}`}
          searchInput={{ autoComplete: 'favorite-group' }}
          search
          fluid
          selection
          allowAdditions
          value={getValue()}
          onAddItem={(event, data) => {
            // eslint-disable-next-line no-shadow
            const { value } = data;
            hycon.debug(`${hookName} onAddItem`, { value, event, data });
            setOptions([...options, value]);
          }}
          onChange={(event, data) => {
            hycon.debug(`${hookName} onChange`, { event, data });
            setValue(data.value);
          }}
        />
        <br />
        <div className="action">
          <Button
            primary
            size="medium"
            onClick={async () => {
              hycon.debug(`${hookName} onClick`, { props });

              const isGroupEditMode = favoriteContext.isGroupEditMode(favoriteContext);
              if (isGroupEditMode) {
                const alertText = `${t('favorites:override-all-groups-for-current-selection')}`;
                // eslint-disable-next-line no-alert
                const isOk = window.confirm(alertText);
                if (isOk) {
                  setIsLoading(true);
                  await updateGroups(value);
                  await updateContext();
                  setValue('');
                  setIsLoading(false);
                }
              } else {
                setIsLoading(true);
                await updateGroups(value);
                await updateContext();
                setValue('');
                setIsLoading(false);
              }
            }}
          >
            {t('favorites:button-modal-notification-update')}
          </Button>
          <Button
            size="medium"
            onClick={(event, data) => {
              hycon.debug(`${hookName} onClick`, { event, data });
              onModalClose(event, data);
            }}
          >
            {t('favorites:button-modal-cancel')}
          </Button>
        </div>
      </Modal.Content>
    </>
  );
}
