import React, {Component} from 'react';
import HyperConsole from "../../../../hyper_console/hyper-console";
import "./UsageSettings.css";
import { withTranslation } from 'react-i18next'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV;
let hycon = null;
if (REACT_APP_GI_ENV === "development") {
	hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
} else {
	hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
}

class UsageSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: this.props.settings
        };
        // let thisRef = this;
        console.debug(`${this.constructor.name} constructor`, {props: this.props});
    }


    static getDerivedStateFromProps(props, state) {
        console.debug(`${"UsageSettings"} getDerivedStateFromProps`, {props, state});
        if (!state) {
            console.debug(`${"UsageSettings"} getDerivedStateFromProps - only props have been updated`, {props, state});
        }
        return state;
    }

    shouldComponentUpdate(nextProps, nextState) {
        // let thisRef = this;
        console.debug(`${this.constructor.name} shouldComponentUpdate`, {nextProps, props: this.props, nextState});
        return true;
    }

    setStateProperty(propertyName, propertyValue) {
        let thisRef = this;
        return new Promise((res) => {
            thisRef.setState((p) => {
                const obj = {};
                obj[propertyName] = propertyValue;
                return Object.assign({}, p, obj);
            }, () => {
                hycon.debug(`${this.constructor.name} setStateProperty - newState`, {newState: thisRef.state});
                res(thisRef.state);
            })
        });
    }

    render() {
        let thisRef = this;
        // let categories = this.state.settings.categories;
        let colors = this.state.settings.colors;
        return (
            <div className="UsageSettings settings">
                <div className={"container"}>
                    <h4>{thisRef.props.i18n.t("panel_usage:label-legend")}</h4>
                    <div>
                        <div>
                            <div className={"color-element"}>
                                <div className="color-label" style={{
                                    margin: "5px", width: "20px", height: "20px", backgroundColor: colors[0]
                                }}/>
                                <div htmlFor="head"><span> </span>{thisRef.props.i18n.t("panel_usage:legend-wohnzonen")}</div>
                            </div>
                        </div>
                        <div>
                            <div className={"color-element"}>
                                <div className="color-label" style={{
                                    margin: "5px", width: "20px", height: "20px", backgroundColor: colors[1]
                                }}/>
                                <div htmlFor="head"><span> </span>{thisRef.props.i18n.t("panel_usage:legend-arbeitszonen")}</div>
                            </div>
                        </div>
                        <div>
                            <div className={"color-element"}>
                                <div className="color-label" style={{
                                    margin: "5px", width: "20px", height: "20px", backgroundColor: colors[2]
                                }}/>
                                <div htmlFor="head"><span> </span>{thisRef.props.i18n.t("panel_usage:legend-mischzonen")}</div>
                            </div>
                        </div>
                        <div>
                            <div className={"color-element"}>
                                <div className="color-label" style={{
                                    margin: "5px", width: "20px", height: "20px", backgroundColor: colors[3]
                                }}/>
                                <div htmlFor="head"><span> </span>{thisRef.props.i18n.t("panel_usage:legend-zentrumszonen")}</div>
                            </div>
                        </div>
                        <div>
                            <div className={"color-element"}>
                                <div className="color-label" style={{
                                    margin: "5px", width: "20px", height: "20px", backgroundColor: colors[4]
                                }}/>
                                <div htmlFor="head"><span> </span>{thisRef.props.i18n.t("panel_usage:legend-oeffentliche-nutzung")}</div>
                            </div>
                        </div>
                        <div>
                            <div className={"color-element"}>
                                <div className="color-label" style={{
                                    margin: "5px", width: "20px", height: "20px", backgroundColor: colors[5]
                                }}/>
                                <div htmlFor="head"><span> </span>{thisRef.props.i18n.t("panel_usage:legend-eingeschraenkte-bauzonen")}</div>
                            </div>
                        </div>
                        <div>
                            <div className={"color-element"}>
                                <div className="color-label" style={{
                                    margin: "5px", width: "20px", height: "20px", backgroundColor: colors[6]
                                }}/>
                                <div htmlFor="head"><span> </span>{thisRef.props.i18n.t("panel_usage:legend-tourismus")}</div>
                            </div>
                        </div>
                        <div>
                            <div className={"color-element"}>
                                <div className="color-label" style={{
                                    margin: "5px", width: "20px", height: "20px", backgroundColor: colors[7]
                                }}/>
                                <div htmlFor="head"><span> </span>{thisRef.props.i18n.t("panel_usage:legend-verkehr")}</div>
                            </div>
                        </div>
                        <div>
                            <div className={"color-element"}>
                                <div className="color-label" style={{
                                    margin: "5px", width: "20px", height: "20px", backgroundColor: colors[8]
                                }}/>
                                <div htmlFor="head"><span> </span>{thisRef.props.i18n.t("panel_usage:legend-weitere")}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidUpdate() {
        // let thisRef = this;
        console.debug(`${this.constructor.name} componentDidUpdate`, {props: this.props});
    }

    componentWillUnmount() {
        // let thisRef = this;
        console.debug(`${this.constructor.name} componentWillUnmount`, {props: this.props});
    }

    componentDidCatch(error, errorInfo) {
        let thisRef = this;
        console.debug(`${thisRef.constructor.name} componentDidCatch`, {error, errorInfo});
        throw error;
    }
}

export default withTranslation([
    "panel_usage"
])(UsageSettings);

