import React, { useState, useEffect, useReducer } from 'react'
import HyperConsole from '../../hyper_console/hyper-console'
import './OverlayDashboard.css'
import PublicPanelContainer from './panel_container/PublicPanelContainer'
import { Button, Icon } from 'semantic-ui-react'
import PanelContainer from './panel_container/PanelContainer'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

const dashboardContextActions = {
  REPLACE: "REPLACE",
}
const defaultDashboardContext = {
  actions: dashboardContextActions,
  openPanelRef: null
}
export const DashboardContext = React.createContext(defaultDashboardContext);
const dashboardContextReducer = (state, action) => {
  if(action.type === dashboardContextActions.REPLACE){
    return {
      ...state,
      ...action.payload
    }
  }
  throw new Error(`unknown action ${JSON.stringify(action)}`);
};

export function OverlayDashboard (props) {
  const fnName = 'OverlayDashboard'
  const HEIGHT_LOW = 18
  const HEIGHT_MID = 48
  const HEIGHT_HIGH = 100
  const initialState = {
    heights: [
      HEIGHT_LOW,
      HEIGHT_MID,
      HEIGHT_HIGH,
    ],
    percentage: HEIGHT_MID
  }
  const [state, setState] = useState(initialState);
  const [dashboardContext, dispatch] = useReducer(dashboardContextReducer, defaultDashboardContext);

  useEffect(() => {
    dispatch({
      type: dashboardContextActions.REPLACE,
      payload: {
        ...defaultDashboardContext,
        dispatch
      }
    })
  }, []);

  const getHeight = () => {
    if (state.percentage === HEIGHT_HIGH) {
      return 'high'
    }
    if (state.percentage === HEIGHT_MID) {
      return 'mid'
    }
    if (state.percentage === HEIGHT_LOW) {
      return 'low'
    }
  }

  const getOverlayDashboardHandle = () => {
    let heights = state.heights
    let percentage = state.percentage
    if (percentage === HEIGHT_LOW) {
      return (
        <h4>
          <Button
            className={'arrow'}
            icon
            onClick={async () => {
              setState({ ...state, percentage: heights[heights.indexOf(percentage) + 1] })
            }}>
            <Icon name='chevron up'/>
          </Button>
        </h4>
      )
    }
    if (percentage === HEIGHT_MID) {
      return (
        <h4>
          <Button
            className={'arrow'}
            icon
            onClick={async () => {
              setState({ ...state, percentage: heights[heights.indexOf(percentage) + 1] })
            }}>
            <Icon name='chevron up'/>
          </Button>
          <Button
            className={'arrow'}
            icon
            onClick={async () => {
              setState({ ...state, percentage: heights[heights.indexOf(percentage) - 1] })
            }}>
            <Icon name='chevron down'/>
          </Button>
        </h4>
      )
    }
    if (percentage === HEIGHT_HIGH) {
      return (
        <h4>
          <Button
            className={'arrow'}
            icon
            onClick={async () => {
              setState({ ...state, percentage: heights[heights.indexOf(percentage) - 1] })
            }}>
            <Icon name='chevron down'/>
          </Button>
        </h4>
      )
    }
  }

  useEffect(() => {
    hycon.debug(`${fnName} useEffect - mounted`)
    return () => {
      // clenup
      hycon.debug(`${fnName} useEffect - unmounted`)
    }
  }, [])

  const getMapAttribution = () => {
    let selectedLayer = props.selectedLayer

    let attriutionComponent = null
    if (selectedLayer === props.maps.Leaflet.layers.grey) {
      attriutionComponent = (
        <div>
          <a
            href={`https://www.swisstopo.admin.ch/`}
            target={`_blank`}>
            swisstopo
          </a>
        </div>)
    } else if (selectedLayer === props.maps.Leaflet.layers.color) {
      attriutionComponent = (
        <div>
          <a
            href={`https://www.swisstopo.admin.ch/`}
            target={`_blank`}>
            swisstopo
          </a>
        </div>)
    } else if (selectedLayer === props.maps.Leaflet.layers.cadastral) {
      attriutionComponent = (
        <div>
          <a
            href={`https://www.cadastre.ch/`}
            target={`_blank`}>
            cadastre
          </a>
        </div>)
    } else if (selectedLayer === props.maps.Google.layers.satellite) {
      let originalGoogleLogo = document.querySelectorAll('#sep-map-google > div > div > div:nth-child(3)')[0];
      /*let href = originalGoogleLogo.querySelectorAll("a").href;*/
      if(originalGoogleLogo){
        originalGoogleLogo.style.display = "none";
        attriutionComponent = (
          <div>
            <div dangerouslySetInnerHTML={{ __html: originalGoogleLogo.innerHTML }}/>
            {/*<a href={`https://www.google.com/maps`} target={`_blank`}>Google&nbsp;Maps</a>*/}
          </div>)
      } else {
        hycon.debug(`${fnName} getMapAttribution - google logo not found`);
      }
    }

    return (
      <div className={'map-attribution'}>{attriutionComponent}</div>
    )
  }
  return (
    <div className={`OverlayDashboard ${getHeight()}`}>
      <DashboardContext.Provider value={dashboardContext}>
        {getMapAttribution()}
        <div className={'handle'}>
          {getOverlayDashboardHandle()}
          <div className={`geoimpact-link`}>
            {(()=>{
              if(props.appStateServer.clients[0] === "sep"){
                return (
                  <React.Fragment>
                    SEP by <a href={`https://www.geoimpact.ch/`} target={`_blank`}>geoimpact</a>
                  </React.Fragment>
                )
              } else if(props.appStateServer.clients[0] === "praedictio"){
                return (
                  <React.Fragment>
                    <a href={`https://praedictio.app`} target={`_blank`}>Praedictio</a>
                  </React.Fragment>
                )
              }
            })()}
          </div>
        </div>
        <PublicPanelContainer {...props} />
        <PanelContainer {...props} useSuspense={true} fallback={<div>Loading...</div>}/>
      </DashboardContext.Provider>
    </div>
  )
}
