import React, { useContext } from 'react'
import HyperConsole from '../../../../hyper_console/hyper-console'

import {
  Dropdown,
  Input,
  Button,
  Loader,
  Radio, TextArea
} from 'semantic-ui-react'

import {Button as MUIButton, Menu} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import './EnergyProject.css'
import ReactDOM from 'react-dom'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import env from '../../../../../env/env'
import moment from 'moment'
import iziToast from 'izitoast'
import Leaflet from 'leaflet'
import { themes } from '../../../../mui/themes'
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const FILE_NAME = 'EnergyProject.js'
const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

const NAVIGATION = {
  OVERVIEW: { locationName: 'OVERVIEW', state: null },
  DETAIL: { locationName: 'DETAIL', state: null },
  SETTINGS: { locationName: 'SETTINGS', state: null },
  PROJECT_EDIT: { locationName: 'PROJECT_EDIT', state: null }
}
const PANEL_NAME = 'energy_project'
const SETTINGS_KEY = `settings_panel_${PANEL_NAME}`
const PIN_SCALE = 0.7

function getIzitoastColor (props){
  const color = props.appStateServer.clients[0] === "sep" ? themes.geoimpact.palette.primary.main : themes.praedictio.palette.primary.main;
  return color;
}
const scrollToTop = (selector) => {
  var div = document.querySelectorAll(selector)[0]
  div.scrollTop = 0
}

const defaultContext = {
  focusedProjects: []
}
const EnergyProjectContext = React.createContext(defaultContext)

class EditProjectComponent extends React.Component {

  constructor (props) {
    super(props)
    const modes = { NEW: 'NEW', EDIT: 'EDIT' }
    this.state = {
      modes: modes,
      mode: this.getModeFromNavigation(modes),
      form: this.getInitialForm(modes),
      graphics: {
        editMode: {
          project: this.getProjectFromNavigation()
        },
        newMode: {}
      }
    }
    this.onMarkerDrag = this.onMarkerDrag.bind(this)
  }

  componentDidMount () {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} componentDidMount`)
    thisRef.refreshGrapics()
    thisRef.initContextMenuItemListeners()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    /*Form editing*/
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} componentDidUpdate`, {
      props: thisRef.props,
      prevProps,
      prevState,
      snapshot
    })
    // detect navigation stack change
    if (
      prevProps.navigationStack.length !==
      thisRef.props.navigationStack.length
    ) {
      hycon.debug(`${thisRef.constructor.name} componentDidUpdate - navigation changed`, {
        prevProps,
        prevState,
        snapshot
      })
      let newMode = this.getModeFromNavigation(thisRef.state.modes)
      let project = this.getProjectFromNavigation()
      hycon.debug(`${thisRef.constructor.name} componentDidUpdate - navigation changed`, { newMode, project })
      if (newMode === thisRef.state.modes.NEW) {
        thisRef.clearGrapics()
        thisRef.setState(() => {
          return {
            mode: newMode,
            form: this.getNewForm(),
            graphics: {
              editMode: {
                project
              },
              newMode: {
                project
              }
            }
          }
        }, () => {
          thisRef.refreshGrapics()
        })
      } else if (newMode === thisRef.state.modes.EDIT) {
        thisRef.clearGrapics()
        thisRef.setState(() => {
          return {
            mode: newMode,
            form: this.getEditForm(),
            graphics: {
              editMode: {
                project
              },
              newMode: {
                project
              }
            }
          }
        }, () => {
          thisRef.refreshGrapics()
        })
      }
    } else {
      let newMode = thisRef.getMode(thisRef.state.modes)
      hycon.debug(`${thisRef.constructor.name} componentDidUpdate`, { newMode })
    }
  }

  refreshGrapics () {
    let thisRef = this
    let mode = thisRef.state.mode
    let modes = thisRef.state.modes
    // let map = thisRef.props.reduxState.map.map;
    // let L = thisRef.props.reduxState.map.Leaflet;
    if (mode === modes.NEW) {
      thisRef.cleanupNewGraphics()
      let fixMarkers = thisRef.addFixMarkersFromNewMode()
      let draggableMarker = thisRef.addDraggableMarker()
      this.setState((prevState) => {
        return {
          ...prevState,
          graphics: {
            newMode: {
              project: null,
              fixMarkers,
              draggableMarker
            },
            editMode: prevState.graphics.editMode
          }
        }
      })
    } else if (mode === modes.EDIT) {
      thisRef.cleanupEditGraphics()
      let fixMarkers = thisRef.addFixMarkersFromEditMode()
      let draggableMarker = thisRef.addDraggableMarker()
      this.setState((prevState) => {
        return {
          ...prevState,
          graphics: {
            editMode: {
              project: prevState.graphics.editMode.project,
              fixMarkers,
              draggableMarker
            },
            newMode: prevState.graphics.newMode
          }
        }
      })
    }
  }

  clearGrapics () {
    let thisRef = this
    let mode = thisRef.state.mode
    let modes = thisRef.state.modes
    // let map = thisRef.props.reduxState.map.map;
    // let L = thisRef.props.reduxState.map.Leaflet;
    if (mode === modes.NEW) {
      thisRef.cleanupNewGraphics()
    } else if (mode === modes.EDIT) {
      thisRef.cleanupEditGraphics()
    }
  }

  // listeners
  initContextMenuItemListeners () {
    let thisRef = this
    let eventName = 'context-menu-item-click-1'
    let element = document.querySelectorAll('.EnergyProject')[0]
    // Listen for the event.
    let onContextMenuClick = function (event) {
      hycon.debug(`${thisRef.constructor.name} initContextMenuItemListeners - event`, { event })

      // save the project
      thisRef.onSaveButtonClick()
    }
    element.addEventListener(eventName, onContextMenuClick, false)
    this.setState((prevState) => {
      return { ...prevState, onContextMenuClick }
    })
  }

  removeContextMenuItemListeners () {
    let thisRef = this
    let eventName = 'context-menu-item-click-1'
    try {
      let element = document.querySelectorAll('.EnergyProject')[0]
      element.removeEventListener(eventName, thisRef.state.onContextMenuClick)
    } catch (e) {
      hycon.warn(`${thisRef.constructor.name} removeContextMenuItemListeners - error`, { e })
    }
  }

  // markers
  addDraggableMarker () {
    let thisRef = this
    let map = thisRef.props.reduxState.map.map
    let L = thisRef.props.reduxState.map.Leaflet
    let project = thisRef.state.graphics.editMode.project
    let mode = thisRef.state.mode
    let modes = thisRef.state.modes
    let iconUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/panel_energy_project/icons/Pin-Energieprojekte-hellgruen.png`
    var icon = L.icon({
      iconUrl: iconUrl,
      /*shadowUrl: iconShadowUrl,*/
      iconSize: [55, 78].map(v => v * PIN_SCALE), // size of the icon
      /*shadowSize: [50, 64],*/ // size of the shadow
      iconAnchor: [22.5, 78].map(v => v * PIN_SCALE), // point of the icon which will correspond to marker's location
    })
    let draggableMarker
    if (mode === modes.NEW) {
      draggableMarker = L.marker(map.getCenter(), {
        icon,
        draggable: true,
        autoPan: true
      })
    } else if (mode === modes.EDIT) {
      draggableMarker = L.marker([project.lat, project.long], {
        icon,
        draggable: true,
        autoPan: true
      })
    }
    draggableMarker.on('drag', thisRef.onMarkerDrag)
    let highestZIndex = thisRef.props.ctx.getHighestMarkerZIndex()
    if (highestZIndex !== null) {
      draggableMarker.setZIndexOffset(highestZIndex + 1)
    }

    draggableMarker.addTo(map)
    return draggableMarker
  }

  addFixMarkersFromEditMode () {
    let thisRef = this
    let props = thisRef.props
    let map = thisRef.props.reduxState.map.map
    let L = thisRef.props.reduxState.map.Leaflet
    let project = thisRef.state.graphics.editMode.project
    let projects = props.ctx.state.projects

    let fixProjects = projects.filter((fixProject) => {
      let isFix = fixProject.id !== project.id
      return isFix
    })

    let fixMarkers = fixProjects.map((fixProject) => {
      let iconUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/panel_energy_project/icons/Pin-Energieprojekte-grau.png`
      var icon = L.icon({
        iconUrl: iconUrl,
        /*shadowUrl: iconShadowUrl,*/
        iconSize: [55, 78].map(v => v * PIN_SCALE), // size of the icon
        /*shadowSize: [50, 64],*/ // size of the shadow
        iconAnchor: [22.5, 78].map(v => v * PIN_SCALE), // point of the icon which will correspond to marker's location
      })
      let fixMarker = L.marker([fixProject.lat, fixProject.long], {
        icon,
        /*draggable: true,*/
        /*autoPan: true*/
      })
      fixMarker.data = { project: fixProject }
      return fixMarker
    })

    fixMarkers.forEach((fixMarker, markerIndex) => {
      hycon.debug(`${thisRef.constructor.name} addFixMarkersFromEditMode - adding fixMarker to map`, {
        fixMarker,
        markerIndex
      })
      fixMarker.addTo(map)
    })
    fixMarkers.forEach((fixMarker) => {
      fixMarker.on('click', async (event) => {
        await thisRef.onMarkerClick(event, fixMarker)
      })
    })
    hycon.debug(`${thisRef.constructor.name} addFixMarkersFromEditMode`, {
      fixMarkers,
      projects,
      project
    })
    return fixMarkers
  }

  addFixMarkersFromNewMode () {
    let thisRef = this
    let props = thisRef.props
    let map = thisRef.props.reduxState.map.map
    let L = thisRef.props.reduxState.map.Leaflet
    let projects = props.ctx.state.projects

    let fixMarkers = projects.map((project) => {
      let iconUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/panel_energy_project/icons/Pin-Energieprojekte-grau.png`
      var icon = L.icon({
        iconUrl: iconUrl,
        /*shadowUrl: iconShadowUrl,*/
        iconSize: [55, 78].map(v => v * PIN_SCALE), // size of the icon
        /*shadowSize: [50, 64],*/ // size of the shadow
        iconAnchor: [22.5, 78].map(v => v * PIN_SCALE), // point of the icon which will correspond to marker's location
      })
      let fixMarker = L.marker([project.lat, project.long], {
        icon,
        /*draggable: true,*/
        /*autoPan: true*/
      })
      fixMarker.data = { project: project }
      return fixMarker
    })

    fixMarkers.forEach((fixMarker, markerIndex) => {
      hycon.debug(`${thisRef.constructor.name} addFixMarkersFromNewMode - adding fixMarker to map`, {
        fixMarker,
        markerIndex
      })
      fixMarker.addTo(map)
    })
    fixMarkers.forEach((fixMarker) => {
      fixMarker.on('click', async (event) => {
        await thisRef.onMarkerClick(event, fixMarker)
      })
    })
    hycon.debug(`${thisRef.constructor.name} addFixMarkersFromNewMode`, {
      fixMarkers,
      projects
    })
    return fixMarkers
  }

  onMarkerDrag (event) {
    let thisRef = this
    let latLng = event.latlng
    hycon.debug(`${thisRef.constructor.name} onMarkerDrag`, { event, latLng })

    return new Promise((resolve) => {
      thisRef.setState((prevState) => {
        return {
          ...prevState,
          form: {
            ...prevState.form, lat: latLng.lat, lng: latLng.lng
          }
        }
      }, () => {
        resolve()
      })
    })
  }

  setPinLatLngInForm (lat, lng) {
    let thisRef = this
    return new Promise((resolve) => {
      thisRef.setState((prevState) => {
        return {
          ...prevState,
          form: {
            ...prevState.form, lat: lat, lng: lng
          }
        }
      }, () => {
        resolve()
      })
    })
  }

  async onMarkerClick (event, projektMarker) {
    /*Form editing*/
    let thisRef = this
    // let latLng = event.latlng
    let props = thisRef.props
    let projects = props.ctx.state.projects
    let project = projektMarker.data.project
    let modes = thisRef.state.modes
    let mode = thisRef.state.mode
    hycon.debug(`${thisRef.constructor.name} onMarkerClick`, { event, projects, mode, modes })

    // if the user is in new mode and clicks a marker, trigger the navigation for the edit mode
    if (mode === modes.NEW) {
      let index = null
      projects.forEach((p, projectIndex) => {
        if (p.id === project.id) {
          index = projectIndex
        }
      })
      return await thisRef.props.ctx.onMarkerClick(event, projektMarker, index)
    } else if (mode === modes.EDIT) {
      return await new Promise((resolve) => {
        this.setState((prevState) => {
          return {
            ...prevState,
            graphics: {
              editMode: {
                project,
                fixMarkers: prevState.graphics.editMode.fixMarkers,
                draggableMarker: prevState.graphics.editMode.draggableMarker
              }
            },
            form: {
              lat: project.lat,
              lng: project.long,
              projektName: project.projektName,
              kategorie: project.kategorie,
              typ: project.typ,
              beschreibung: project.beschreibung,

              bildName: project.bildName,
              bildUrl: project.bildUrl,

              dokumentName: project.dokumentName,
              dokumentUrl: project.dokumentUrl,

              projectPrincipals: project.projectPrincipals.map((element, index) => {
                return { key: element.id, text: element.name, value: element.name }
              }),
              projectPrincipalsOptions: project.projectPrincipals.map((element, index) => {
                return { key: element.id, text: element.name, value: element.name }
              }),
              projectPrincipalsOptionsOriginal: project.projectPrincipals.map((element, index) => {
                return { key: element.id, text: element.name, value: element.name }
              }),
              projectPrincipalsOptionsSuggested: [],

              projectContractors: project.projectContractors.map((element, index) => {
                return { key: element.id, text: element.name, value: element.name }
              }),
              projectContractorsOptions: project.projectContractors.map((element, index) => {
                return { key: element.id, text: element.name, value: element.name }
              }),
              projectContractorsOptionsOriginal: project.projectContractors.map((element, index) => {
                return { key: element.id, text: element.name, value: element.name }
              }),
              projectContractorsOptionsSuggested: [],

              projektStart: moment(project.projektStart).toDate(),
              projektEnde: moment(project.projektEnde).toDate(),

              projektStatus: project.projektStatus,
              energyInfo: project.energyInfo,
              bewilligt: project.bewilligt,
              isPublic: project.isPublic,

              konzession: project.konzession,
              konzessionOptions: [],
              konzessionOptionsOriginal: [],
              konzessionOptionsSuggested: [],
              konzessionStart: moment(project.konzessionStart).toDate(),
              konzessionEnde: moment(project.konzessionEnde).toDate(),

              internerLink: project.internerLink
            }
          }
        }, () => {
          thisRef.refreshGrapics()
          resolve()
        })
      })
    }
  }

  cleanupEditGraphics () {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} cleanupEditGraphics`, {})
    // let props = thisRef.props
    let map = thisRef.props.reduxState.map.map
    if (
      thisRef.state.graphics &&
      thisRef.state.graphics.editMode &&
      thisRef.state.graphics.editMode.fixMarkers &&
      thisRef.state.graphics.editMode.draggableMarker
    ) {
      let fixMarkers = thisRef.state.graphics.editMode.fixMarkers
      let draggableMarker = thisRef.state.graphics.editMode.draggableMarker
      fixMarkers.forEach((marker) => {
        map.removeLayer(marker)
      })
      map.removeLayer(draggableMarker)
    } else {
      console.info(`${thisRef.constructor.name} cleanupEditGraphics - no editMode graphics to clear`)
    }
  }

  cleanupNewGraphics () {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} cleanupNewGraphics`, {})
    // let props = thisRef.props
    let map = thisRef.props.reduxState.map.map
    if (
      thisRef.state.graphics &&
      thisRef.state.graphics.newMode &&
      thisRef.state.graphics.newMode.fixMarkers &&
      thisRef.state.graphics.newMode.draggableMarker
    ) {
      let fixMarkers = thisRef.state.graphics.newMode.fixMarkers
      let draggableMarker = thisRef.state.graphics.newMode.draggableMarker
      fixMarkers.forEach((marker) => {
        map.removeLayer(marker)
      })
      map.removeLayer(draggableMarker)
    } else {
      console.info(`${thisRef.constructor.name} cleanupNewGraphics - no editMode graphics to clear`)
    }
  }

  // form
  getInitialForm (modes) {
    // let map = this.props.reduxState.map.map
    // let L = this.props.reduxState.map.Leaflet
    let mode = this.getModeFromNavigation(modes)
    if (mode === modes.NEW) {
      return this.getNewForm()
    } else if (mode === modes.EDIT) {
      return this.getEditForm()
    }
  }

  getEditForm () {
    let project = this.getProjectFromNavigation()
    return {
      lat: project.lat,
      lng: project.long,
      projektName: project.projektName,
      kategorie: project.kategorie,
      typ: project.typ,
      beschreibung: project.beschreibung,

      bildName: project.bildName,
      bildUrl: project.bildUrl,

      dokumentName: project.dokumentName,
      dokumentUrl: project.dokumentUrl,

      projectPrincipals: project.projectPrincipals.map((element, index) => {
        return { key: element.id, text: element.name, value: element.name }
      }),
      projectPrincipalsOptions: project.projectPrincipals.map((element, index) => {
        return { key: element.id, text: element.name, value: element.name }
      }),
      projectPrincipalsOptionsOriginal: project.projectPrincipals.map((element, index) => {
        return { key: element.id, text: element.name, value: element.name }
      }),
      projectPrincipalsOptionsSuggested: [],

      projectContractors: project.projectContractors.map((element, index) => {
        return { key: element.id, text: element.name, value: element.name }
      }),
      projectContractorsOptions: project.projectContractors.map((element, index) => {
        return { key: element.id, text: element.name, value: element.name }
      }),
      projectContractorsOptionsOriginal: project.projectContractors.map((element, index) => {
        return { key: element.id, text: element.name, value: element.name }
      }),
      projectContractorsOptionsSuggested: [],

      projektStart: moment(project.projektStart).toDate(),
      projektEnde: moment(project.projektEnde).toDate(),

      projektStatus: project.projektStatus,
      energyInfo: project.energyInfo,
      bewilligt: project.bewilligt,
      isPublic: project.isPublic,

      konzession: project.konzession,
      konzessionOptions: [],
      konzessionOptionsOriginal: [],
      konzessionOptionsSuggested: [],
      konzessionStart: moment(project.konzessionStart).toDate(),
      konzessionEnde: moment(project.konzessionEnde).toDate(),

      internerLink: project.internerLink,

      principalsSuggestion: []
    }
  }

  getNewForm () {
    let map = this.props.reduxState.map.map
    return {
      lat: map.getCenter().lat,
      lng: map.getCenter().lng,
      projektName: '',
      kategorie: '',
      typ: '',
      beschreibung: '',

      bildName: '',
      bildUrl: '',

      dokumentName: '',
      dokumentUrl: '',

      projectPrincipals: [],
      projectPrincipalsOptions: [],
      projectPrincipalsOptionsOriginal: [],
      projectPrincipalsOptionsSuggested: [],

      projectContractors: [],
      projectContractorsOptions: [],
      projectContractorsOptionsOriginal: [],
      projectContractorsOptionsSuggested: [],

      projektStart: '',
      projektEnde: '',

      projektStatus: '',
      energyInfo: [],
      bewilligt: false,
      isPublic: false,

      konzession: '',
      konzessionOptions: [],
      konzessionOptionsOriginal: [],
      konzessionOptionsSuggested: [],
      konzessionStart: '',
      konzessionEnde: '',

      internerLink: '',

      principalsSuggestion: []
    }
  }

  async updateForm (formValuesToOverride) {
    let thisRef = this
    return await new Promise((resolve) => {
      thisRef.setState((prevState) => {
        return {
          ...prevState,
          form: {
            ...prevState.form,
            ...formValuesToOverride
          }
        }
      }, resolve)
    })
  }

  // modes
  getNavigationObject () {
    let thisRef = this
    let navigationObject = thisRef.props.navigationStack.slice(-1).pop()
    return navigationObject
  }

  getMode (modes) {
    let thisRef = this
    if (
      thisRef.state.graphics &&
      thisRef.state.graphics.editMode &&
      thisRef.state.graphics.editMode.project
    ) {
      return modes.EDIT
    } else {
      return modes.NEW
    }
  }

  getModeFromNavigation (modes) {
    let thisRef = this
    try {
      let project = thisRef.getProjectFromNavigation()
      if (project !== null) {
        return modes.EDIT
      } else {
        return modes.NEW
      }
    } catch (e) {
      hycon.info(`${thisRef.constructor.name} getModeFromNavigation - no project in getNavigationObject`)
      return modes.NEW
    }
  }

  getProjectFromNavigation () {
    let thisRef = this
    try {
      let project = thisRef.getNavigationObject().state.project
      return project
    } catch (e) {
      hycon.info(`${thisRef.constructor.name} getProjectFromNavigation - no project in getNavigationObject`)
      return null
    }
  }

  getEditModeComponent () {
    let thisRef = this
    let mode = thisRef.state.mode
    let modes = thisRef.state.modes
    if (mode === modes.EDIT) {
      let project = thisRef.state.graphics.editMode.project
      return (
        <label>{thisRef.props.ctx.translate('edit-label-edit-project')} "{project.projektName}"
          (ID: {project.id})</label>
      )
    } else if (mode === modes.NEW) {
      return (
        <label>{thisRef.props.ctx.translate('edit-label-new-project')}</label>
      )
    }
  }

  // upload
  uploadFile (file) {
    let thisRef = this
    try {
      const jwt = thisRef.props.reduxState.user.jwt
      let endpoint = `${env.API_GATEWAY_BASE}/api/files`
      const formData = new FormData()
      formData.append('file', file)
      formData.append('Title', file.name)
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME }
      }
      return axios.post(endpoint, formData, config)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async patchFile (projectConfig, fileId) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    try {
      let endpoint = `${env.API_GATEWAY_BASE}/api/files/${fileId}`
      let data = {
        isPublic: projectConfig.isPublic
      }
      hycon.debug(`${thisRef.constructor.name} patchFile`, {
        endpoint, data
      })
      return await axios({
        method: 'patch',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data)
      })
    } catch (e) {
      return await Promise.reject(e)
    }
  }

  async onSaveButtonClick () {
    // todo validate form
    let thisRef = this
    let props = thisRef.props
    let className = thisRef.constructor.name
    let form = thisRef.state.form
    let mode = thisRef.state.mode
    let modes = thisRef.state.modes

    hycon.debug(`${className} onSaveButtonClick `, { form, mode, props })

    let navigateToOverview = async () => {
      let navigationObject = { locationName: NAVIGATION.DETAIL.locationName }
      hycon.debug(`${className} onClick - save - navigation`, { navigationObject })
      await props.ctx.onNavigation(navigationObject)
    }

    // start the spinner
    await thisRef.props.ctx.setStateProperty('isLoading', true)

    // upload the files
    let uploadFiles = () => {
      let promises = [Promise.resolve(), Promise.resolve()]

      if (thisRef.state.form.projektDokumentUploadPromise) {
        promises[0] = thisRef.uploadFile(thisRef.state.form.projektDokumentUploadPromise)
      }

      if (thisRef.state.form.projektBildUploadPromise) {
        promises[1] = thisRef.uploadFile(thisRef.state.form.projektBildUploadPromise)
      }

      return Promise.all(promises).then((values) => {
        return {
          dokument: values[0],
          bild: values[1],
        }
      })
    }
    let uploads = await uploadFiles().catch((e) => {
      hycon.error(`${thisRef.constructor.name} uploadFiles - error`, e)
    })
    hycon.debug(`${thisRef.constructor.name} - save - uploaded`, uploads)

    // delete the energy infos for the project being edited
    if (mode === modes.EDIT) {
      let project = thisRef.state.graphics.editMode.project
      let deleteEnergyInfo = async () => {
        let energieInfo = project.energyInfo
        let deleteEnergyInfosPromises = []
        energieInfo.forEach((energyInfoElement) => {
          hycon.debug(`${thisRef.constructor.name} deleteEnergyInfo - promising to delete energyInfoElement`, energyInfoElement)
          deleteEnergyInfosPromises.push(props.ctx.deleteEnergyInfo(project, energyInfoElement))
        })
        return await Promise.all(deleteEnergyInfosPromises)
      }
      await deleteEnergyInfo()
    }
    let createEneryInfos = async (project) => {
      // create the energyInfos of the form
      let energyInfosPromises = []
      form.energyInfoTemp.forEach((energyInfoTemp) => {
        hycon.debug(`${thisRef.constructor.name} createEneryInfos - promising to create energyInfoElement`, energyInfoTemp)
        energyInfosPromises.push(props.ctx.createEnergyInfo(project, {
          'art': energyInfoTemp.art,
          'einheit': energyInfoTemp.einheit,
          'menge': parseFloat(energyInfoTemp.menge)
        }))
      })
      // execute all promises
      return await Promise.all(energyInfosPromises)
    }

    // delete the principals for the project being edited
    if (mode === modes.EDIT) {
      let project = thisRef.state.graphics.editMode.project
      let deletePrincipals = async () => {
        let projectPrincipals = project.projectPrincipals
        let deletePrincipalsPromises = []
        projectPrincipals.forEach((projectPrincipalsElement) => {
          hycon.debug(`${thisRef.constructor.name} deletePrincipals - promising to delete projectPrincipalsElement`, projectPrincipalsElement)
          deletePrincipalsPromises.push(props.ctx.deletePrincipal(project, projectPrincipalsElement))
        })
        return await Promise.all(deletePrincipalsPromises)
      }
      await deletePrincipals()
    }
    let createPrincipals = async (project) => {
      // create the energyInfos of the form
      let createPrincipalsPromise = []
      form.projectPrincipals.forEach((projectPrincipalsElement) => {
        hycon.debug(`${thisRef.constructor.name} createPrincipals - promising to create projectPrincipalsElement`, projectPrincipalsElement)
        createPrincipalsPromise.push(props.ctx.createPrincipal(project, { name: projectPrincipalsElement.text }))
      })
      // execute all promises
      return await Promise.all(createPrincipalsPromise)
    }

    // delete the contractors for the project being edited
    if (mode === modes.EDIT) {
      let project = thisRef.state.graphics.editMode.project
      let deleteContractors = async () => {
        let projectContractors = project.projectContractors
        let deleteContractorsPromises = []
        projectContractors.forEach((projectContractorsElement) => {
          hycon.debug(`${thisRef.constructor.name} deleteContractors - promising to delete projectContractorsElement`, projectContractorsElement)
          deleteContractorsPromises.push(props.ctx.deleteContractor(project, projectContractorsElement))
        })
        return await Promise.all(deleteContractorsPromises)
      }
      await deleteContractors()
    }
    let createContractors = async (project) => {
      // create the energyInfos of the form
      let createContractorsPromise = []
      form.projectContractors.forEach((projectContractorsElement) => {
        hycon.debug(`${thisRef.constructor.name} createContractors - promising to create projectContractorsElement`, projectContractorsElement)
        createContractorsPromise.push(props.ctx.createContractor(project, { name: projectContractorsElement.text }))
      })
      // execute all promises
      return await Promise.all(createContractorsPromise)
    }

    // patch the imageUrls into the project
    let patchImagePromise = async (project, uploadResponses) => {
      let config = {}
      if (uploadResponses.bild) {
        let bildUrl = uploadResponses.bild.data.fileUri
        let bildName = uploadResponses.bild.data.title
        config.bildName = bildName
        config.bildUrl = bildUrl
      }

      if (uploadResponses.dokument) {
        let dokumentUrl = uploadResponses.dokument.data.fileUri
        let dokumentName = uploadResponses.dokument.data.title
        config.dokumentName = dokumentName
        config.dokumentUrl = dokumentUrl
      }

      return await props.ctx.patchProjectPartial(project, config)
    }

    const updateProjectFields = async (projectResponse) => {
      let energyInfoResponse = await createEneryInfos(projectResponse.data)
      hycon.debug(`${thisRef.constructor.name} - save - energyInfoResponse`, energyInfoResponse)
      let createPrincipalsResponse = await createPrincipals(projectResponse.data)
      hycon.debug(`${thisRef.constructor.name} - save - createPrincipalsResponse`, createPrincipalsResponse)
      let createContractorsResponse = await createContractors(projectResponse.data)
      hycon.debug(`${thisRef.constructor.name} - save - createContractorsResponse`, createContractorsResponse)

      // the file upload is optional
      try {
        let uploadFilesResponse = await uploadFiles()
        hycon.debug(`${thisRef.constructor.name} - save - uploadFilesResponse`, uploadFilesResponse)
        let patchImagePromiseResponse = await patchImagePromise(projectResponse.data, uploadFilesResponse)
        hycon.debug(`${thisRef.constructor.name} - save - patchImagePromiseResponse`, patchImagePromiseResponse)
        return { uploadFilesResponse, patchImagePromiseResponse }
      } catch (e) {
        hycon.warn(`${thisRef.constructor.name} - save - image upload warning`, e)
        return null
      }
    }

    const patchFilePemissions = async (projectConfig, projectUpdateResponse, uploadFilesResponse) => {
      let thisRef = this
      hycon.debug(`${thisRef.constructor.name} - patchFilePemissions`, {
        projectConfig,
        projectUpdateResponse,
        uploadFilesResponse
      })

      if (
        projectUpdateResponse &&
        projectUpdateResponse.data &&
        projectUpdateResponse.data.dokumentUrl
      ) {
        let downloadUrl = projectUpdateResponse.data.dokumentUrl
        hycon.debug(`${thisRef.constructor.name} - patchDocumentPemissions - response`, { downloadUrl })
        let fileId = downloadUrl.split('download/')[1]
        hycon.debug(`${thisRef.constructor.name} - patchDocumentPemissions - response`, { fileId })
        let response = await thisRef.patchFile(projectConfig, fileId)
        hycon.debug(`${thisRef.constructor.name} - patchDocumentPemissions - response`, { response })
      }

      if (
        uploadFilesResponse &&
        uploadFilesResponse.bild &&
        uploadFilesResponse.bild.data
      ) {
        let fileUrl = uploadFilesResponse.bild.data.links.filter((link) => {
          return link['rel'] === 'self'
        })[0].uri
        hycon.debug(`${thisRef.constructor.name} - patchImagePemissions - response`, { uploadFilesResponse, fileUrl, })
        let fileId = fileUrl.split('files/')[1]
        hycon.debug(`${thisRef.constructor.name} - patchImagePemissions - response`, { fileId })
        let response = await thisRef.patchFile({ isPublic: true }, fileId)
        hycon.debug(`${thisRef.constructor.name} - patchImagePemissions - response`, { response })
      }

      if (
        uploadFilesResponse &&
        uploadFilesResponse.dokument &&
        uploadFilesResponse.dokument.data
      ) {
        // uploadFilesResponse.dokument.data.links[""0""].rel
        let fileUrl = uploadFilesResponse.dokument.data.links.filter((link) => {
          return link['rel'] === 'self'
        })[0].uri
        // hycon.debug(`${thisRef.constructor.name} - patchFilePemissions - response`, { uploadFilesResponse,  fileUrl, });
        let fileId = fileUrl.split('files/')[1]
        await thisRef.patchFile(projectConfig, fileId)
        // hycon.debug(`${thisRef.constructor.name} - patchFilePemissions - response`, { response });
      } else {
        hycon.debug(`${thisRef.constructor.name} - patchFilePemissions - no files to patch`)
      }
    }

    let config = {
      lat: form.lat,
      lng: form.lng,
      projektName: form.projektName,
      kategorie: form.kategorie,
      typ: form.typ,
      beschreibung: form.beschreibung,

      bildName: form.bildName,
      bildUrl: form.bildUrl,

      dokumentName: form.dokumentName,
      dokumentUrl: form.dokumentUrl,

      projektStart: moment(form.projektStart).format('YYYY-MM-DD'),
      projektEnde: moment(form.projektEnde).format('YYYY-MM-DD'),

      projektStatus: form.projektStatus,
      bewilligt: form.bewilligt,
      isPublic: form.isPublic,

      konzession: form.konzession,
      konzessionStart: moment(form.konzessionStart).format('YYYY-MM-DD'),
      konzessionEnde: moment(form.konzessionEnde).format('YYYY-MM-DD'),
      internerLink: form.internerLink
    }
    try {
      if (mode === modes.NEW) {
        let projectResponse = await props.ctx.createProject(config)
        hycon.debug(`${thisRef.constructor.name} - save - projectResponse`, projectResponse)
        let { uploadFilesResponse } = await updateProjectFields(projectResponse)
        await patchFilePemissions(config, projectResponse, uploadFilesResponse)
        // trigger the state update to get the new projects displayed
        // stop the spinner
        await thisRef.props.ctx.setStateProperty('isLoading', false)
        // trigger the state update to get the new projects displayed
        await props.ctx.onDataChanged()
        // navigate to the overview so that the user sees the saved projects
        await navigateToOverview()
      } else if (mode === modes.EDIT) {
        let project = thisRef.state.graphics.editMode.project
        let projectResponse = await props.ctx.patchProject(project, config)
        let { uploadFilesResponse } = await updateProjectFields(projectResponse)
        await patchFilePemissions(config, projectResponse, uploadFilesResponse)
        // stop the spinner
        await thisRef.props.ctx.setStateProperty('isLoading', false)
        // trigger the state update to get the new projects displayed
        await props.ctx.onDataChanged()
        // navigate to the overview so that the user sees the saved projects
        await navigateToOverview()
      }
    } catch (e) {
      // stop the spinner
      let missingFields = thisRef.getMissingFormFields()
      if (missingFields.length > 0) {
        iziToast.warning({
          theme: 'light',
          id: 'loading-toast',
          zindex: 5,
          message: `${missingFields.join(', ')} ${
            missingFields.length > 1 ? thisRef.props.ctx.translate('are-missing') : thisRef.props.ctx.translate('is-missing')
          }`,
          position: 'topCenter',
          drag: false,
        })
      } else {
        iziToast.warning({
          theme: 'light',
          id: 'loading-toast',
          zindex: 5,
          message: `${e.message}`,
          position: 'topCenter',
          drag: false,
        })
      }
      // stop the spinner
      await thisRef.props.ctx.setStateProperty('isLoading', false)
      await props.ctx.onDataChanged()
    }
  }

  /*async deleteProjectFiles (project) {
    let thisRef = this;
    let fileIds = [];
    if (project.bildUrl) {
      let split = project.bildUrl.split("/");
      let bildId = split[split.length - 1];
      fileIds.push(bildId)
    }
    if (project.dokumentUrl) {
      let split = project.dokumentUrl.split("/");
      let dokumentId = split[split.length - 1];
      fileIds.push(dokumentId)
    }

    let promises = fileIds.map(id => {
      return thisRef.props.ctx.deleteFile(id);
    })
    return await Promise.all(promises).then(v => v);
  }*/

  async onDeleteButtonClick () {
    let thisRef = this
    let props = thisRef.props
    // todo validate form
    let project = thisRef.state.graphics.editMode.project
    hycon.debug(`${thisRef.constructor.name} - onDeleteButtonClick - project`, project)
    let delProjectResponse = await props.ctx.deleteProjectById(project.id)
    // let delFilesResponse = await thisRef.deleteProjectFiles(project);
    hycon.debug(`${thisRef.constructor.name} - onDeleteButtonClick - ok`, { delProjectResponse })
    // trigger the state update to get the new projects displayed
    await props.ctx.onDataChanged()
    let navigationObject = { locationName: NAVIGATION.DETAIL.locationName }
    props.ctx.onNavigation(navigationObject)
  }

  getMissingFormFields () {
    let thisRef = this

    let form = thisRef.state.form
    hycon.debug(`${this.constructor.name} getMissingFormFields`, { form })

    let config = {
      lat: form.lat,
      lng: form.lng,
      projektName: form.projektName,
      kategorie: form.kategorie,
      typ: form.typ,
      beschreibung: form.beschreibung,

      bildName: form.bildName,
      bildUrl: form.bildUrl,

      dokumentName: form.dokumentName,
      dokumentUrl: form.dokumentUrl,

      projektStart: moment(form.projektStart).format('YYYY-MM-DD'),
      projektEnde: moment(form.projektEnde).format('YYYY-MM-DD'),

      projektStatus: form.projektStatus,
      bewilligt: form.bewilligt,
      isPublic: form.isPublic,

      konzession: form.konzession,
      konzessionStart: moment(form.konzessionStart).format('YYYY-MM-DD'),
      konzessionEnde: moment(form.konzessionEnde).format('YYYY-MM-DD'),
      internerLink: form.internerLink
    }
    hycon.debug(`${this.constructor.name} getMissingFormFields`, { form, config })

    let data = {}
    for (let property in config) {
      if (config.hasOwnProperty(property)) {
        // check strings
        if (
          property === 'beschreibung' ||
          property === 'bildName' ||
          property === 'bildUrl' ||
          property === 'dokumentName' ||
          property === 'dokumentUrl' ||
          property === 'internerLink' ||
          property === 'kategorie' ||
          property === 'typ' ||
          property === 'projektStatus' ||
          property === 'projektName' ||
          property === 'konzession'
        ) {
          if (config[property]) {
            data[property] = config[property]
          }
        }
        // check dates
        if (
          property === 'konzessionStart' ||
          property === 'konzessionEnde' ||
          property === 'projektStart' ||
          property === 'projektEnde'
        ) {
          if (moment(config[property]).isValid()) {
            data[property] = config[property]
          }
        }
        // check booleans
        if (property === 'bewilligt') {
          if (typeof config[property] === 'boolean') {
            data[`bewilligt`] = config[`bewilligt`]
          }
        }
        if (property === 'isPublic') {
          if (typeof config[property] === 'boolean') {
            data[`isPublic`] = config[`isPublic`]
          }
        }
        // check numbers
        if (
          property === 'lat' || property === 'lng'
        ) {
          if (!Number.isNaN(config[property])) {
            if (
              property === 'lat'
            ) {
              data[`lat`] = config[property]
            } else if (
              property === 'lng'
            ) {
              data[`long`] = config[property]
            }
          }
        }
      }
    }

    if (typeof data.projektName !== 'undefined') {
      return []
    } else {
      return [
        thisRef.props.ctx.translate('edit-label-projektname')
      ]
    }
  }

  getFormComponent () {
    let thisRef = this
    let props = thisRef.props
    let className = thisRef.constructor.name
    let form = thisRef.state.form
    let mode = thisRef.state.mode
    let modes = thisRef.state.modes
    let types = thisRef.props.ctx.getProjectTypes(form.kategorie)
    return (
      <div className={'form-container'}>
        <div className={'form-section'}>
          <div className={'section-row'}>
            {thisRef.getEditModeComponent()}
          </div>
          <div className={'section-row'}>
            <label>{props.ctx.translate('edit-label-projektort')}</label>
            <div
              className={'coordinate-selector'}
            >
              <span>
                lat: {Math.round(form.lat * 10000) / 10000}, lng: {Math.round(form.lng * 10000) / 10000}
              </span>
              <i
                className="fas fa-compress-arrows-alt"
                onClick={(event) => {
                  hycon.debug(`${className} onChange`, { event })
                  let modes = thisRef.state.modes
                  let mode = thisRef.state.mode
                  let map = thisRef.props.reduxState.map.map

                  let draggableMarker = null
                  if (mode === modes.NEW) {
                    draggableMarker = thisRef.state.graphics.newMode.draggableMarker
                    draggableMarker.setLatLng(map.getCenter())
                  } else if (mode === modes.EDIT) {
                    draggableMarker = thisRef.state.graphics.editMode.draggableMarker
                    draggableMarker.setLatLng(map.getCenter())
                  }
                  thisRef.setPinLatLngInForm(draggableMarker.getLatLng().lat, draggableMarker.getLatLng().lng)
                }}
              />
              <i
                className="fas fa-crosshairs"
                onClick={(event) => {
                  hycon.debug(`${className} onChange`, { event })
                  let modes = thisRef.state.modes
                  let mode = thisRef.state.mode
                  let map = thisRef.props.reduxState.map.map

                  if (mode === modes.NEW) {
                    let draggableMarker = thisRef.state.graphics.newMode.draggableMarker
                    map.flyTo(draggableMarker.getLatLng())
                  } else if (mode === modes.EDIT) {
                    let draggableMarker = thisRef.state.graphics.editMode.draggableMarker
                    map.flyTo(draggableMarker.getLatLng())
                  }
                }}
              />
            </div>
            <div style={{ display: 'none' }}>
              <Input fluid id={`project-lat`} value={form.lat} disabled={true} hidden={true}/>
              <Input fluid id={`project-lng`} value={form.lng} disabled={true} hidden={true}/>
            </div>
          </div>
          <div className={'section-row'}>
            <label>{props.ctx.translate('edit-label-projektname')}</label><br/>
            <Input
              fluid
              type={'text'}
              id={`project-name`}
              value={form.projektName || ''}
              onChange={async (event, data) => {
                hycon.debug(`${className} onChange `, { event, data })
                await this.updateForm({ projektName: data.value })
              }}
            />
          </div>
          <div className={'section-row'}>
            <label>{props.ctx.translate('edit-label-projekt-kategorie')}</label><br/>
            <Dropdown
              id={`project-category`}
              className={'dropdown'}
              upward={false}
              search
              selection
              fluid
              onChange={(event, data) => {
                hycon.debug(`${className} onChange`, { event, data })
                if (data.value !== 'form-category-energieproduktion') {
                  hycon.debug(`${className} onChange -> resetting type`, { event, data })
                  thisRef.updateForm({ typ: '', kategorie: data.value })
                } else {
                  thisRef.updateForm({ kategorie: data.value })
                }

              }}
              options={thisRef.props.ctx.getProjectCategories()}
              value={form.kategorie}
            />
          </div>
          {(() => {
            if (types.length > 0) {
              return (
                <div className={'section-row'}>
                  <label>{props.ctx.translate('edit-label-projekt-typ')}</label><br/>
                  <Dropdown
                    id={`project-type`}
                    className={'dropdown'}
                    upward={false}
                    search
                    selection
                    fluid
                    onChange={(event, data) => {
                      hycon.debug(`${className} onChange`, { event, data })
                      thisRef.updateForm({ typ: data.value })
                    }}
                    options={types}
                    value={form.typ}
                  />
                </div>
              )
            }
          })()}
        </div>
        <div className={'block-description'}>
          <div className={'description-form'}>
            <label>{props.ctx.translate('edit-label-prokekt-beschreibung')}</label><br/>
            <TextArea
              rows={3}
              id={`project-description`}
              value={form.beschreibung || ''}
              onChange={(event, data) => {
                thisRef.updateForm({ beschreibung: data.value })
              }}
            />
          </div>
        </div>
        <div className={'form-section'}>
          <div className={'section-row'}>
            <label>{props.ctx.translate('edit-label-projekt-bild')}</label><br/>
            <Input
              fluid
              type={'file'}
              id={`project-image`}
              onChange={(event, data) => {
                event.persist()
                hycon.debug(`${className} onChange`, { event, data })
                let file = event.target.files[0]
                hycon.debug(`${className} onChange`, { file })
                thisRef.updateForm({ projektBildUploadPromise: file })
              }}
            />
          </div>
          {(() => {
            if (form.bildName) {
              return (
                <div className={'section-row'}>
                  <div className={'download-link'}>
                    {form.bildName}
                    <i
                      className="fas fa-download"
                      onClick={() => {
                        let bildUrl = null
                        let jwt = props.ctx.props.reduxState.user.jwt
                        if (form.bildUrl) {
                          bildUrl = form.bildUrl + `?jwt=${jwt}`
                          window.open(bildUrl, '_blank')
                        } else {
                          iziToast.show({
                            theme: 'dark',
                            id: 'loading-toast',
                            color: getIzitoastColor(props),
                            zindex: 5,
                            message: `No data to download`,
                            position: 'topCenter',
                            drag: false,
                          })
                        }
                      }}
                    />
                  </div>
                </div>
              )
            }
          })()}
          <div className={'section-row'}>
            <label>{props.ctx.translate('edit-label-projekt-daten')}</label><br/>
            <Input
              fluid
              type={'file'}
              id={`project-data`}
              onChange={(event, data) => {
                event.persist()
                hycon.debug(`${className} onChange`, { event, data })
                let file = event.target.files[0]
                hycon.debug(`${className} onChange`, { file })
                thisRef.updateForm({ projektDokumentUploadPromise: file })
              }}
            />
          </div>
          {(() => {
            if (form.dokumentUrl) {
              return (
                <div className={'section-row'}>
                  <div className={'download-link'}>
                    {form.dokumentName}
                    <i
                      className="fas fa-download"
                      onClick={() => {
                        let bildUrl = null
                        let jwt = props.ctx.props.reduxState.user.jwt
                        if (form.dokumentUrl) {
                          bildUrl = form.dokumentUrl + `?jwt=${jwt}`
                          window.open(bildUrl, '_blank')
                        } else {
                          iziToast.show({
                            theme: 'dark',
                            id: 'loading-toast',
                            color: getIzitoastColor(props),
                            zindex: 5,
                            message: `No data to download`,
                            position: 'topCenter',
                            drag: false,
                          })
                        }
                      }}
                    />
                  </div>
                </div>
              )
            }
          })()}
        </div>
        <div className={'form-section'}>
          <div className={'section-row'}>
            <label>{props.ctx.translate('edit-label-projekt-entwickler')}</label><br/>
            <Dropdown
              search
              selection
              fluid
              multiple
              allowAdditions
              onAddItem={async (event, data) => {
                let oldPrincipals = form.projectPrincipals
                hycon.debug(`${className} onAddItem`, { event, data, oldPrincipals })
              }}
              onSearchChange={async (event, data) => {
                hycon.debug(`${className} onSearchChange`, { event, data })
                let searchQuery = data.searchQuery
                if (searchQuery.length > 0) {
                  let principalsSuggestionResponse = await props.ctx.getPrincipals(searchQuery)
                  hycon.debug(`${className} onSearchChange - principalsSuggestionResponse`, { principalsSuggestionResponse })
                  let options = principalsSuggestionResponse.data.map((suggestion, index) => {
                    return {
                      key: suggestion.id,
                      text: suggestion.name,
                      value: suggestion.name
                    }
                  })
                  hycon.debug(`${className} onSearchChange - options`, { options })
                  await this.updateForm({ projectPrincipalsOptionsSuggested: options })
                } else {
                  await this.updateForm({ projectPrincipalsOptionsSuggested: [] })
                }
              }}
              onChange={async (event, data) => {
                hycon.debug(`${className} onChange`, { event, data })
                let principals = data.value.map((element, index) => {
                  return {
                    key: index,
                    text: element,
                    value: element
                  }
                })
                hycon.debug(`${className} onChange`, { principals })
                await this.updateForm({ projectPrincipals: principals })
              }}
              options={(() => {
                let projectPrincipals = form.projectPrincipals
                let projectPrincipalsOptionsSuggested = form.projectPrincipalsOptionsSuggested
                let projectPrincipalsOptionsOriginal = form.projectPrincipalsOptionsOriginal
                let allOptions = [...projectPrincipals, ...projectPrincipalsOptionsSuggested, ...projectPrincipalsOptionsOriginal]
                let uniqueOptions = []
                allOptions.forEach((option) => {
                  uniqueOptions.push(option)
                })
                // hycon.debug(`${className} onChangeOptions`, { uniqueOptions })
                return uniqueOptions
              })()}
              value={form.projectPrincipals.map(e => e.text)}
            />
          </div>
          <div className={'section-row'}>
            <label>{props.ctx.translate('edit-label-projekt-beteiligte')}</label><br/>
            <Dropdown
              search
              selection
              fluid
              multiple
              allowAdditions
              onAddItem={async (event, data) => {
                let oldPrincipals = form.projectContractors
                hycon.debug(`${className} onAddItem`, { event, data, oldPrincipals })
              }}
              onSearchChange={async (event, data) => {
                hycon.debug(`${className} onSearchChange`, { event, data })
                let searchQuery = data.searchQuery
                if (searchQuery.length > 0) {
                  let principalsSuggestionResponse = await props.ctx.getPrincipals(searchQuery)
                  hycon.debug(`${className} onSearchChange - principalsSuggestionResponse`, { principalsSuggestionResponse })
                  let options = principalsSuggestionResponse.data.map((suggestion, index) => {
                    return {
                      key: suggestion.id,
                      text: suggestion.name,
                      value: suggestion.name
                    }
                  })
                  hycon.debug(`${className} onSearchChange - options`, { options })
                  await this.updateForm({ projectContractorsOptionsSuggested: options })
                } else {
                  await this.updateForm({ projectContractorsOptionsSuggested: [] })
                }
              }}
              onChange={async (event, data) => {
                hycon.debug(`${className} onChange`, { event, data })
                let principals = data.value.map((element, index) => {
                  return {
                    key: index,
                    text: element,
                    value: element
                  }
                })
                hycon.debug(`${className} onChange`, { principals })
                await this.updateForm({ projectContractors: principals })
              }}
              options={(() => {
                let projectContractors = form.projectContractors
                let projectContractorsOptionsSuggested = form.projectContractorsOptionsSuggested
                let projectContractorsOptionsOriginal = form.projectContractorsOptionsOriginal
                let allOptions = [...projectContractors, ...projectContractorsOptionsSuggested, ...projectContractorsOptionsOriginal]
                let uniqueOptions = []
                allOptions.forEach((option) => {
                  uniqueOptions.push(option)
                })
                // hycon.debug(`${className} onChangeOptions`, { uniqueOptions })
                return uniqueOptions
              })()}
              value={form.projectContractors.map(e => e.text)}
            />
          </div>
          <div className={'section-row'}>
            <label>{props.ctx.translate('edit-label-projekt-zeitraum-von')}</label><br/>
            <Input
              fluid
              type={'date'}
              id={'date-zeitraum-from'}
              onChange={async (event, data) => {
                hycon.debug(`${className} onChange`, { event, data })
                await this.updateForm({ projektStart: data.value })
              }}
              value={moment(form.projektStart).format('YYYY-MM-DD')}
            />
          </div>
          <div className={'section-row'}>
            <label>{props.ctx.translate('edit-label-projekt-zeitraum-bis')}</label><br/>
            <Input
              fluid
              type={'date'}
              id={'date-zeitraum-to'}
              onChange={async (event, data) => {
                hycon.debug(`${className} onChange`, { event, data })
                await this.updateForm({ projektEnde: data.value })
              }}
              value={moment(form.projektEnde).format('YYYY-MM-DD')}
            />
          </div>
        </div>
        <div className={'form-section'}>
          <div className={'section-row'}>
            <label>{props.ctx.translate('edit-label-projekt-status')}</label><br/>
            <Dropdown
              className={'dropdown'}
              upward={false}
              clearable={true}
              fluid
              search
              selection
              closeOnChange
              onChange={async (event, data) => {
                hycon.debug(`${className} onChange`, { event, data })
                await thisRef.updateForm({ projektStatus: data.value })
              }}
              options={props.ctx.getProjectStatusOptions()}
              value={form.projektStatus}
              placeholder={props.ctx.translate('edit-label-projekt-status-placeholder')}
            />
          </div>
          <div className={'section-row'}>
            <label>{props.ctx.translate('edit-label-projekt-bewilligt')}</label><br/>
            <div className={'toggle-container'}>
              <Radio
                toggle
                checked={form.bewilligt}
                onChange={async (event, data) => {
                  hycon.debug(`${className} onChange `, { event, data })
                  await thisRef.updateForm({ bewilligt: data.checked })
                }}
              />
            </div>
          </div>
          <div className={'section-row'}>
            <label>{props.ctx.translate('edit-label-projekt-public')}</label><br/>
            <div className={'toggle-container'}>
              <Radio
                toggle
                checked={form.isPublic}
                onChange={async (event, data) => {
                  hycon.debug(`${className} onChange `, { event, data })
                  await thisRef.updateForm({ isPublic: data.checked })
                }}
              />
            </div>
          </div>
          <div className={'section-row'}>
            <label>{props.ctx.translate('edit-label-projekt-energie-info')}</label><br/>
            <EnergieInfo
              {...props}
              onChange={async (energyInfoTemp) => {
                hycon.debug(`${className} onChange`, { energyInfoTemp, thisRef, props })
                await thisRef.updateForm({ energyInfoTemp: energyInfoTemp })
              }}
              mode={thisRef.state.mode}
              modes={thisRef.state.modes}
              graphics={thisRef.state.graphics}
              translate={(label) => {
                return props.ctx.translate(label)
              }}
            />
          </div>
        </div>
        <div className={'form-section'}>
          <div className={'section-row'}>
            <label>{props.ctx.translate('edit-label-projekt-konzession')}</label><br/>
            <Dropdown
              search
              selection
              fluid
              allowAdditions
              onAddItem={async (event, data) => {
                hycon.debug(`${className} onAddItem`, { event, data })
              }}
              onSearchChange={async (event, data) => {
                hycon.debug(`${className} onSearchChange`, { event, data })
                let searchQuery = data.searchQuery
                if (searchQuery.length > 0) {
                  let konzessionenSuggestionResponse = await props.ctx.getKonzessionen(searchQuery)
                  hycon.debug(`${className} onSearchChange - konzessionenSuggestionResponse`, { konzessionenSuggestionResponse })
                  await this.updateForm({ konzessionOptionsSuggested: konzessionenSuggestionResponse.data })
                } else {
                  await this.updateForm({ konzessionOptionsSuggested: [] })
                }
              }}
              onChange={async (event, data) => {
                hycon.debug(`${className} onChange`, { event, data })
                await this.updateForm({ konzession: data.value })
              }}
              options={(() => {
                let konzession = form.konzession || ''
                let konzessionOptionsSuggested = form.konzessionOptionsSuggested
                let allOptions = [konzession, ...konzessionOptionsSuggested]
                let uniqueOptions = []
                allOptions.forEach((option) => {
                  uniqueOptions.push(option)
                })
                hycon.debug(`${className} onChangeOptions`, { uniqueOptions })
                return uniqueOptions.map((option, index) => {
                  return {
                    key: index, text: option, value: option
                  }
                })
              })()}
              value={form.konzession || ''}
              /*placeholder={props.ctx.translate('edit-label-projekt-konzession-placeholder')}*/
            />
          </div>
          <div className={'section-row'}>
            <label>{props.ctx.translate('edit-label-projekt-konzession-dauer-von')}</label><br/>
            <Input
              fluid
              type={'date'}
              id={'date-konzession-from'}
              onChange={async (event, data) => {
                hycon.debug(`${className} onChange`, { event, data })
                await this.updateForm({ konzessionStart: data.value })
              }}
              value={moment(form.konzessionStart).format('YYYY-MM-DD')}
            />
          </div>
          <div className={'section-row'}>
            <label>{props.ctx.translate('edit-label-projekt-konzession-dauer-bis')}</label><br/>
            <Input
              fluid
              type={'date'}
              id={'date-konzession-to'}
              onChange={async (event, data) => {
                hycon.debug(`${className} onChange`, { event, data })
                await this.updateForm({ konzessionEnde: data.value })
              }}
              value={moment(form.konzessionEnde).format('YYYY-MM-DD')}
            />
          </div>
          <div className={'section-row'}>
            <label>{props.ctx.translate('edit-label-interner-link')}</label><br/>
            <Input
              fluid
              type={'text'}
              id={'internal-link'}
              value={form.internerLink || ''}
              onChange={async (event, data) => {
                hycon.debug(`${className} onChange `, { event, data })
                await this.updateForm({ internerLink: data.value })
              }}
            />
          </div>
        </div>
        <div className={'form-section actions'}>
          <div className={'section-row'}>
            <Button
              fluid
              primary
              /*disabled={!thisRef.isFormValid(form)}*/
              onClick={async (event) => {
                thisRef.onSaveButtonClick()
              }}
            >
              {props.ctx.translate('edit-action-save')}
            </Button>
            {(() => {
              if (mode === modes.EDIT) {
                return (
                  <Button
                    fluid
                    color={'red'}
                    onClick={async (event) => {
                      try {
                        hycon.info(`${className} onDeleteButtonClick`, { event, props, thisRef })
                        thisRef.onDeleteButtonClick()
                      } catch (e) {
                        hycon.error(`${className} onDeleteButtonClick`, { e })
                        // stop the spinner
                        await thisRef.props.ctx.setStateProperty('isLoading', false)
                        iziToast.show({
                          theme: 'dark',
                          id: 'loading-toast',
                          color: getIzitoastColor(props),
                          zindex: 5,
                          message: `${e.message}`,
                          position: 'topCenter',
                          drag: false,
                        })
                      }
                    }}>
                    {props.ctx.translate('edit-action-delete')}
                  </Button>
                )
              }
            })()}
          </div>
        </div>
      </div>
    )
  }

  componentWillUnmount () {
    let thisRef = this
    thisRef.clearGrapics()
    thisRef.removeContextMenuItemListeners()
  }

  render () {
    let thisRef = this
    return (
      <div className={'edit-project'}>
        {thisRef.getFormComponent()}
      </div>
    )
  }
}

class EnergieInfo extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      items: [],
      form: {
        art: null,
        quantity: 0,
        unit: null
      }
    }
  }

  componentDidMount () {
    let thisRef = this
    let props = thisRef.props
    let mode = props.mode
    let modes = props.modes
    if (mode === modes.EDIT) {
      let formItems = this.getFormStateFromProps()
      this.setState((prevState) => {
        return { ...prevState, items: formItems }
      })
      thisRef.props.onChange(formItems)
    } else if (mode === modes.NEW) {
      this.setState((prevState) => {
        return { ...prevState, items: [] }
      })
      thisRef.props.onChange([])
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    let thisRef = this
    let props = thisRef.props
    let mode = props.mode
    let modes = props.modes
    // let modes = props.modes;
    let graphics = thisRef.props.graphics
    hycon.debug(`${thisRef.constructor.name} componentDidUpdate`, {
      mode,
      graphics,
      prevProps,
      props,
      /*prevState,*/
      /*snapshot*/
    })

    let isProjectChanged = () => {
      let graphics = props.graphics
      let newProject = graphics.editMode.project

      let oldGraphics = prevProps.graphics
      let oldProject = oldGraphics.editMode.project

      hycon.debug(`${thisRef.constructor.name} componentDidUpdate`, {
        oldProject,
        newProject
      })

      if (
        (oldProject == null && newProject) ||
        (newProject == null && oldProject) ||
        oldProject.id !== newProject.id
      ) {
        return true
      } else {
        return false
      }
    }

    let isModeSwitch = () => {
      let newMode = props.mode
      let oldMode = prevProps.mode
      hycon.debug(`${thisRef.constructor.name} componentDidUpdate`, {
        newMode,
        oldMode
      })
      return (newMode !== oldMode)
    }

    if (
      mode === modes.EDIT &&
      isProjectChanged()
    ) {
      let formItems = this.getFormStateFromProps()
      this.setState((prevState) => {
        return { ...prevState, items: formItems }
      })
      thisRef.props.onChange(formItems)
    } else if (
      isModeSwitch() && mode === modes.NEW
    ) {
      this.setState((prevState) => {
        return { ...prevState, items: [] }
      })
      thisRef.props.onChange([])
    }
  }

  getFormStateFromProps () {
    let fnName = this.constructor.name
    let thisRef = this
    let props = thisRef.props
    let mode = props.mode
    let modes = props.modes
    let graphics = props.graphics
    hycon.debug(`${fnName} getFormStateFromProps`, { props, graphics })
    if (mode === modes.EDIT) {
      let project = graphics.editMode.project
      hycon.debug(`${fnName} getFormStateFromProps - edit mode`, { props, project })
      let energyInfo = project.energyInfo
      let items = []
      energyInfo.forEach((energyInfoElement, index) => {
        let item = {
          art: energyInfoElement.art,
          menge: energyInfoElement.menge,
          einheit: energyInfoElement.einheit,
          index: index,
          original: energyInfoElement
        }
        items.push(item)
      })
      return items
    } else if (mode === modes.NEW) {
      hycon.debug(`${fnName} getFormStateFromProps - new mode`, { props })
      let items = []
      return items
    }
  }

  getTable () {
    let thisRef = this
    let table = {
      arts: [
        {
          name: thisRef.props.translate('form-project-energie-info-leistung'),
          locizeKey: 'form-project-energie-info-leistung',
          units: [
            {
              name: thisRef.props.translate('form-project-energie-info-leistung'),
              locizeKey: 'form-project-energie-info-leistung',
            },
            {
              name: thisRef.props.translate('form-project-energie-info-leistung'),
              locizeKey: 'form-project-energie-info-leistung',
            }
          ]
        },
        {
          name: thisRef.props.translate('form-project-energie-info-produktion-strom'),
          locizeKey: 'form-project-energie-info-produktion-strom',
          units: [
            {
              name: thisRef.props.translate('form-project-energie-info-produktion-strom'),
              locizeKey: 'form-project-energie-info-produktion-strom',
            },
            {
              name: thisRef.props.translate('form-project-energie-info-produktion-strom'),
              locizeKey: 'form-project-energie-info-produktion-strom',
            }
          ]
        },
        {
          name: thisRef.props.translate('form-project-energie-info-produktion-waerme'),
          locizeKey: 'form-project-energie-info-produktion-waerme',
          units: [
            {
              name: thisRef.props.translate('form-project-energie-info-produktion-waerme'),
              locizeKey: 'form-project-energie-info-produktion-waerme',
            }
          ]
        },
        {
          name: thisRef.props.translate('form-project-energie-info-produktion-kaelte'),
          locizeKey: 'form-project-energie-info-produktion-kaelte',
          units: [
            {
              name: thisRef.props.translate('form-project-energie-info-produktion-kaelte'),
              locizeKey: 'form-project-energie-info-produktion-kaelte',
            }
          ]
        },
        {
          name: thisRef.props.translate('form-project-energie-info-durchflussbiogas'),
          locizeKey: 'form-project-energie-info-durchflussbiogas',
          units: [
            {
              name: thisRef.props.translate('form-project-energie-info-durchflussbiogas'),
              locizeKey: 'form-project-energie-info-durchflussbiogas',
            }
          ]
        },
        {
          name: thisRef.props.translate('form-project-energie-info-biomethan'),
          locizeKey: 'form-project-energie-info-biomethan',
          units: [
            {
              name: thisRef.props.translate('form-project-energie-info-biomethan'),
              locizeKey: 'form-project-energie-info-biomethan',
            }
          ]
        },
        {
          name: thisRef.props.translate('form-project-energie-info-hoehe'),
          locizeKey: 'form-project-energie-info-hoehe',
          units: [
            {
              name: thisRef.props.translate('form-project-energie-info-hoehe'),
              locizeKey: 'form-project-energie-info-hoehe',
            }
          ]
        },
        {
          name: thisRef.props.translate('form-project-energie-info-bedarfgas'),
          locizeKey: 'form-project-energie-info-bedarfgas',
          units: [
            {
              name: thisRef.props.translate('form-project-energie-info-bedarfgas'),
              locizeKey: 'form-project-energie-info-bedarfgas',
            }
          ]
        },
        {
          name: thisRef.props.translate('form-project-energie-info-bedarfwaerme'),
          locizeKey: 'form-project-energie-info-bedarfwaerme',
          units: [
            {
              name: thisRef.props.translate('form-project-energie-info-bedarfwaerme'),
              locizeKey: 'form-project-energie-info-bedarfwaerme',
            },
            {
              name: thisRef.props.translate('form-project-energie-info-bedarfwaerme'),
              locizeKey: 'form-project-energie-info-bedarfwaerme',
            }
          ]
        },
        {
          name: thisRef.props.translate('form-project-energie-info-bedarfstrom'),
          locizeKey: 'form-project-energie-info-bedarfstrom',
          units: [
            {
              name: thisRef.props.translate('form-project-energie-info-bedarfstrom'),
              locizeKey: 'form-project-energie-info-bedarfstrom',
            },
            {
              name: thisRef.props.translate('form-project-energie-info-bedarfstrom'),
              locizeKey: 'form-project-energie-info-bedarfstrom',
            }
          ]
        },
        {
          name: thisRef.props.translate('form-project-energie-info-speicherkapazitaetstrom'),
          locizeKey: 'form-project-energie-info-speicherkapazitaetstrom',
          units: [
            {
              name: thisRef.props.translate('form-project-energie-info-speicherkapazitaetstrom'),
              locizeKey: 'form-project-energie-info-speicherkapazitaetstrom',
            }
          ]
        },
      ]
    }
    return table
  }

  getEnergyInfos (table) {
    let infos = {
      arts: table.arts.map((art, index) => {
        return {
          'key': index,
          'text': art.name.split('***')[0],
          'value': art.locizeKey,
        }
      })
    }
    return infos
  }

  getUnitsForArt (table, artNameLocize) {
    let fnName = this.constructor.name
    // let thisRef = this;
    hycon.debug(`${fnName} getUnitsForArt`, { table, artNameLocize })
    if (artNameLocize) {
      let units = []
      table.arts.forEach((artElement) => {
        if (artElement.locizeKey === artNameLocize) {
          let unit = artElement.units[0]
          unit.name.split('***').forEach((unit, unitIndex) => {
            if (unitIndex > 0) {
              units.push({
                'key': unitIndex,
                'text': unit,
                'value': `${unitIndex}`,
              })
            }
          })
        }
      })
      return {
        units: units
      }
    } else {
      return {
        units: []
      }
    }
  }

  onComponentRemove (index) {
    let fnName = this.constructor.name
    let thisRef = this
    let props = thisRef.props

    let items = thisRef.state.items
    hycon.debug(`${fnName} onComponentRemove`, { index })
    let newItems = []

    items.forEach((item) => {
      if (item.index !== index) {
        newItems.push(item)
      }
    })
    hycon.debug(`${fnName} onComponentRemove`, { items, newItems })

    this.setState((prevState) => {
      return { ...prevState, items: newItems }
    })
    props.onChange(newItems)
  }

  onComponentAdd () {
    let fnName = this.constructor.name
    let thisRef = this
    let props = thisRef.props
    let form = thisRef.state.form

    hycon.debug(`${fnName} onComponentAdd`, { thisRef })

    let items = thisRef.state.items
    hycon.debug(`${fnName} onComponentRemove`, { thisRef })
    let item = {
      art: form.art,
      menge: form.quantity,
      einheit: form.unit,
      index: items.length + 1,
      original: null
    }
    let newItems = items.concat(item)

    this.setState((prevState) => {
      return { ...prevState, items: newItems }
    })
    props.onChange(newItems)
  }

  getItemComponents () {
    let thisRef = this
    let items = thisRef.state.items
    let components = []
    items.forEach((item) => {
      let component = (
        <div key={item.index} className={'item'}>
          {thisRef.props.translate(item.art).split('***')[0]}: {item.menge.toLocaleString('de-CH')} {thisRef.props.translate(item.art).split('***')[parseInt(item.einheit)]}
          <i className="fas fa-trash"
             onClick={() => {thisRef.onComponentRemove(item.index)}}/>
        </div>
      )
      components.push(component)
    })
    return components
  }

  canAddInfo () {
    let thisRef = this
    let form = thisRef.state.form
    return (
      form.art &&
      !isNaN(parseFloat(form.quantity)) &&
      form.unit
    )
  }

  render () {
    // let fnName = this.constructor.name;
    let thisRef = this
    let props = thisRef.props
    let form = thisRef.state.form

    let setForm = (form) => {
      thisRef.setState((prevState) => {
        return { ...prevState, form }
      })
    }
    return (
      <div className={'energy-info'}>
        <div>
          <div className={'entries'}>
            {thisRef.getItemComponents()}
          </div>
          <label className={'sub-label'}>
            {props.translate('edit-label-projekt-energie-info-1-label')}
          </label>
          <Dropdown
            className={'dropdown'}
            fluid
            upward={false}
            clearable={true}
            search
            selection
            closeOnChange
            options={thisRef.getEnergyInfos(thisRef.getTable()).arts}
            value={form.art}
            onChange={(event, data) => {
              let art = data.value
              let units = thisRef.getUnitsForArt(thisRef.getTable(), art).units
              hycon.debug(`${thisRef.constructor.name} onChange`, { art, unit: units[0].text })
              setForm(Object.assign({}, form, { art, unit: units[0].text }))
            }}
            placeholder={props.translate('edit-label-projekt-energie-info-1-label')}
          />
          <label className={'sub-label'}>
            {props.translate('edit-label-projekt-energie-info-2-label')}
          </label>
          <div className={`input-group`}>
            <Input
              type={'number'}
              options={thisRef.getUnitsForArt(thisRef.getTable(), form.art).units}
              value={form.quantity || 0}
              onChange={(event, data) => {
                let parsed = parseFloat(data.value)
                let value = isNaN(parsed) ? 'undefined' : parsed
                setForm(Object.assign({}, form, { quantity: value }))
              }}
              placeholder={props.translate('edit-label-projekt-energie-info-2-label')}
            />
            <Dropdown
              className={'dropdown'}
              upward={false}
              clearable={true}
              search
              selection
              closeOnChange
              options={thisRef.getUnitsForArt(thisRef.getTable(), form.art).units}
              value={form.unit}
              onChange={(event, data) => {
                setForm(Object.assign({}, form, { unit: data.value }))
              }}
              placeholder={props.translate('edit-label-projekt-energie-info-3-label')}
            />
            <Button
              primary
              disabled={thisRef.canAddInfo() ? false : true}
              onClick={(event) => {
                thisRef.onComponentAdd(event)
              }}
            >
              <i className="fas fa-plus"/>
            </Button>
          </div>
          <br/>
        </div>
      </div>
    )
  }
}

// PROJECT DETAIL
function ProjectDetailsComponent (props) {
  return ProjectListComponent(props)
}

function ProjectCard (props) {
  let fnName = 'ProjectCard'
  let project = props.project
  let map = props.ctx.props.reduxState.map.map
  let defaultImage = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Energieprojekt.png`
  let jwt = props.ctx.props.reduxState.user.jwt
  const context = useContext(EnergyProjectContext)

  let bildUrl = defaultImage
  if (project.bildUrl) {
    bildUrl = project.bildUrl + `?jwt=${jwt}`
  }

  // let [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={`${fnName} card name-${project.projektName} ${context.focusedProjects.filter((fp) => {return fp.id === project.id}).length > 0 ? 'focused' : ''}`
      + ` project-${project.id}`}
    >
      <div
        className={'image-container'}
        onClick={() => {
          let navigationObject = { locationName: NAVIGATION.PROJECT_EDIT.locationName, state: { project } }
          hycon.debug(`${fnName} onClick`, { project, navigationObject })
          props.ctx.onNavigation(navigationObject)
        }}
      >
        <div
          className={'image'}
          style={{
            backgroundImage: `url('${bildUrl}')`
          }}
        />
      </div>
      <div
        className={'controls'}
      >
        <div><b>{project.projektName}</b></div>
        <i
          className={'fas fa-crosshairs'}
          onClick={() => {
            hycon.debug(`${fnName} onClick`, { project, props })
            map.setView([project.lat, project.long], 18)
          }}
        />
        <i
          style={{
            fontSize: '1.2em'
          }}
          className={`fas fa-eye`}
          onClick={() => {
            hycon.debug(`${fnName} - onEyeClick`, { project, props, context })
            let newFocusedProjects
            if (context.focusedProjects.filter((fp) => {return fp.id === project.id}).length > 0) {
              newFocusedProjects = []
            } else {
              newFocusedProjects = [project]
            }
            context.dispatch(
              {
                type: context.actions.REPLACE,
                info: `${fnName} - dispatch fn injection`,
                payload: {
                  focusedProjects: newFocusedProjects
                }
              }
            )
          }}
        />
      </div>
      {/*<div
        className={"extra"}
      >
        {(() => {
          if(
            isOpen &&
            project.beschreibung &&
            project.beschreibung.length > 0
          ){
            return(
              <div>{project.beschreibung}</div>
            )
          }
        })()}
      </div>*/}
    </div>
  )
}

function ProjectListComponent (props) {
  let projects = props.ctx.state.projects
  let projectComponents = projects.map((project, index) => {
    return (
      <ProjectCard
        key={index}
        project={project}
        ctx={props.ctx}
      />
    )
  })
  return (
    <div className={'card-container-absolute'}>
      <div className={'card-container'}>
        {projectComponents.length > 0 ? projectComponents : (
          <div className={'message'}>{props.ctx.translate('message-no-projects-visible')}</div>
        )}
      </div>
    </div>
  )
}

class ContextMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      anchorElement: null,
      isOpen: false,
    }
  }

  componentDidMount () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} componentDidMount `, { props: thisRef.props, state: thisRef.state })
  }

  translate (label, ns = 'panel_energy_project') {
    let thisRef = this
    let translation = thisRef.props.i18n.t(`${ns}:${label}`)
    if (
      translation && translation !== label
    ) {
      return translation
    } else {
      return `${label}`
      // return `${ns}:${label}`;
    }
  };

  toggleIsOpen () {
    this.setState((state) => {
      return { ...state, isOpen: !state.isOpen }
    })
  }

  setIsOpen (isOpen) {
    this.setState((state) => {
      return { ...state, isOpen }
    })
  }

  getMenuItemsStyle () {
    let menuItemsStyle = {}
    let controlsComponent = document.querySelectorAll('.context-menu .controls')[0]
    if (controlsComponent) {
      let buttonHeight = controlsComponent.clientHeight
      menuItemsStyle = {
        position: 'absolute',
        top: `${buttonHeight}px`,
        right: 0,
        zIndex: 1
      }
    }
    return menuItemsStyle
  }

  getContextMenuItems () {
    let thisRef = this

    let menuItems = []

    if (thisRef.props.locationName === NAVIGATION.OVERVIEW.locationName) {
      menuItems.push(
        <MenuItem
          onClick={async () => {
            hycon.debug(`${thisRef.constructor.name} onClick`)
            thisRef.setIsOpen(false)
            await thisRef.props.onNavigation({ locationName: NAVIGATION.DETAIL.locationName, state: null })
          }}
        >
          {this.translate('detail')}
        </MenuItem>
      )
      menuItems.push(
        <MenuItem
          onClick={async () => {
            hycon.debug(`${thisRef.constructor.name} onClick`)
            thisRef.setIsOpen(false)
            await thisRef.props.onNavigation({ locationName: NAVIGATION.PROJECT_EDIT.locationName, state: null })
          }}
        >
          {this.translate('edit-project')}
        </MenuItem>
      )
      menuItems.push(
        <MenuItem
          onClick={async () => {
            hycon.debug(`${thisRef.constructor.name} onClick`)
            thisRef.setIsOpen(false)
            await thisRef.props.onNavigation({ locationName: NAVIGATION.SETTINGS.locationName, state: null })
          }}
        >
          {this.translate('settings')}
        </MenuItem>
      )
    } else if (thisRef.props.locationName === NAVIGATION.DETAIL.locationName) {
      menuItems.push(
        <MenuItem
          onClick={async () => {
            hycon.debug(`${thisRef.constructor.name} onClick`)
            thisRef.setIsOpen(false)
            await thisRef.props.onNavigation({ locationName: NAVIGATION.PROJECT_EDIT.locationName, state: null })
          }}
        >
          {this.translate('edit-project')}
        </MenuItem>
      )
      menuItems.push(
        <MenuItem
          key={4}
          size={'medium'}
          onClick={async () => {
            hycon.debug(`${thisRef.constructor.name} onClick`)
            thisRef.setIsOpen(false)
            await thisRef.props.onNavigation({ locationName: NAVIGATION.SETTINGS.locationName, state: null })
          }}
        >
          {this.translate('settings')}
        </MenuItem>
      )
    } else if (thisRef.props.locationName === NAVIGATION.SETTINGS.locationName) {
      menuItems.push(
        <MenuItem
          onClick={async () => {
            hycon.debug(`${thisRef.constructor.name} onClick`)
            thisRef.setIsOpen(false)
            await thisRef.props.onNavigation({ locationName: NAVIGATION.DETAIL.locationName, state: null })
          }}
        >
          {this.translate('detail')}
        </MenuItem>
      )
      menuItems.push(
        <MenuItem
          onClick={async () => {
            hycon.debug(`${thisRef.constructor.name} onClick`)
            thisRef.setIsOpen(false)
            await thisRef.props.onNavigation({ locationName: NAVIGATION.PROJECT_EDIT.locationName, state: null })
          }}
        >
          {this.translate('edit-project')}
        </MenuItem>
      )
    } else if (thisRef.props.locationName === NAVIGATION.PROJECT_EDIT.locationName) {
      menuItems.push(
        <MenuItem
          onClick={async () => {
            hycon.debug(`${thisRef.constructor.name} onClick`)
            let eventName = 'context-menu-item-click-1'
            var event = new Event(eventName)
            let element = document.querySelectorAll('.EnergyProject')[0]
            // Dispatch the event.
            element.dispatchEvent(event)
            thisRef.setIsOpen(false)
          }}
        >
          {thisRef.translate('edit-action-save')}
        </MenuItem>
      )
      menuItems.push(
        <MenuItem
          onClick={async () => {
            hycon.debug(`${thisRef.constructor.name} onClick`)
            thisRef.setIsOpen(false)
            await thisRef.props.onNavigation({ locationName: NAVIGATION.DETAIL.locationName, state: null })
          }}
        >
          {this.translate('detail')}
        </MenuItem>
      )
      menuItems.push(
        <MenuItem
          onClick={async () => {
            hycon.debug(`${thisRef.constructor.name} onClick`)
            thisRef.setIsOpen(false)
            await thisRef.props.onNavigation({ locationName: NAVIGATION.PROJECT_EDIT.locationName, state: null })
          }}
        >
          {this.translate('edit-project')}
        </MenuItem>
      )
      menuItems.push(
        <MenuItem
          onClick={async () => {
            hycon.debug(`${thisRef.constructor.name} onClick`)
            thisRef.setIsOpen(false)
            await thisRef.props.onNavigation({ locationName: NAVIGATION.SETTINGS.locationName, state: null })
          }}
        >
          {this.translate('settings')}
        </MenuItem>
      )
    } else {
      menuItems.push(
        <MenuItem
          onClick={async () => {
            hycon.debug(`${thisRef.constructor.name} onClick`)
            thisRef.setIsOpen(false)
            await thisRef.props.onNavigation({ locationName: NAVIGATION.DETAIL.locationName, state: null })
          }}
        >
          {this.translate('detail')}
        </MenuItem>
      )
      menuItems.push(
        <MenuItem
          onClick={async () => {
            hycon.debug(`${thisRef.constructor.name} onClick`)
            thisRef.setIsOpen(false)
            await thisRef.props.onNavigation({ locationName: NAVIGATION.OVERVIEW.locationName, state: null })
          }}
        >
          {this.translate('overview')}
        </MenuItem>
      )
      menuItems.push(
        <MenuItem
          onClick={async () => {
            hycon.debug(`${thisRef.constructor.name} onClick`)
            thisRef.setIsOpen(false)
            await thisRef.props.onNavigation({ locationName: NAVIGATION.PROJECT_EDIT.locationName, state: null })
          }}
        >
          {this.translate('edit-project')}
        </MenuItem>
      )
      menuItems.push(
        <MenuItem
          onClick={async () => {
            hycon.debug(`${thisRef.constructor.name} onClick`)
            thisRef.setIsOpen(false)
            await thisRef.props.onNavigation({ locationName: NAVIGATION.SETTINGS.locationName, state: null })
          }}
        >
          {this.translate('settings')}
        </MenuItem>
      )
    }

    return menuItems.map((item, itemIndex) => {
      // item.props.key = itemIndex;
      return {...item, key: itemIndex};
    })
  }

  onMenuClick(e){
    let al = e.currentTarget;
    this.setState((state) => {
      return { ...state, isOpen: true, anchorElement: al };
    });
  }

  getContextItems () {
    let thisRef = this
    // hycon.debug(`${this.constructor.name} getContextItems`, {thisRef});
    let lastNavigationObject = thisRef.props.getLastNavigationObject()
    let getControls = () => {
      let controls = [];
      if (
        lastNavigationObject.locationName === NAVIGATION.OVERVIEW.locationName
      ) {
        controls = (
            <MUIButton
                onClick={(e) => {this.onMenuClick(e)}}
            >
              <MoreVertIcon/>
            </MUIButton>
        )
      } else {
        controls = (
          <React.Fragment>
            <MUIButton
                onClick={(e) => {this.onMenuClick(e)}}
                active={thisRef.state.isOpen}
            >
              <MoreVertIcon/>
            </MUIButton>
            <MUIButton
              onClick={async () => {
                hycon.debug(`${thisRef.constructor.name} onClick`)
                await thisRef.props.onNavigation({ locationName: NAVIGATION.OVERVIEW.locationName, state: null })
              }}
            >
              <CloseIcon/>
            </MUIButton>
          </React.Fragment>
        )
      }
      return (
          <div style={{position: "relative"}}>
            <Menu
                anchorEl={this.state.anchorElement}
                open={this.state.isOpen}
                onClose={(e) => {
                  this.setState((state) => {
                    return { ...state, isOpen: false }
                  })
                }}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
            >
              {thisRef.getContextMenuItems()}
            </Menu>
            {controls}
          </div>
      )
    }
    let baseContextMenu = (
      <div className={'context-menu'}>
        <div className={'title'}>
          <h4>{thisRef.translate('panel-name')}</h4>
        </div>
        {getControls()}
      </div>
    )
    return baseContextMenu
  }

  render () {
    let thisRef = this
    let contextItems = thisRef.getContextItems()
    return contextItems
  }
}

class EnergyProject extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      isMounted: false,
      navigationStack: [],
      isMapListenersInitialized: false,
      axiosInterceptors: null,
      projects: [],
      projectMarkers: [],
      panelSetting: null,
      contextState: defaultContext
    }
    this.onNavigation = this.onNavigation.bind(this)
    this.onMapMoveZoom = this.onMapMoveZoom.bind(this)
    this.getLastNavigationObject = this.getLastNavigationObject.bind(this)
    this.onMarkerClick = this.onMarkerClick.bind(this)
    this.setPanelSettingsInState = this.setPanelSettingsInState.bind(this)
    this.refreshMapGraphics = this.refreshMapGraphics.bind(this)
    this.updateProjectsInState = this.updateProjectsInState.bind(this)
    this.actions = {
      REPLACE: 'REPLACE'
    }
    // this.onMarkerClick = this.onMarkerClick.bind(this);
    hycon.debug(`${this.constructor.name} constructor`, { props: this.props })
  }

  static getDerivedStateFromProps (props, state) {
    hycon.debug(`Card getDerivedStateFromProps`, { props, state })
    if (!state) {
      hycon.debug(`Card getDerivedStateFromProps - only props have been updated`, { props, state })
    }
    return state
  }

  contextStateReducer = (state, action) => {
    let thisRef = this
    const fnName = 'reducer'
    hycon.debug(`${fnName}`, { info: action.info, state, action })
    if (action.type === this.actions.REPLACE) {
      let newState = {
        ...state,
        contextState: {
          focusedProjects: [],
          ...state.contextState,
          ...action.payload
        }
      }
      // hycon.debug(`${fnName}`, { newState, action })
      thisRef.refreshMapGraphics()
      return newState
    } else {
      hycon.error(`${fnName} unsupported reducer action`, { state, action })
      throw new Error(`${fnName} unsupported reducer action`, { state, action })
    }
  }

  componentDidMount () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} componentDidMount`, { props: this.props })

    let newNavigationStack = thisRef.state.navigationStack.concat([{
      locationName: NAVIGATION.OVERVIEW.locationName,
      state: null
    }])
    thisRef.setStateProperty('navigationStack', newNavigationStack)
    thisRef.setStateProperty('isMounted', true)
    // thisRef.initAxiosInterceptors(thisRef)
    thisRef.setState((p) => ({
      ...p,
      contextState: {
        ...p.contextState,
        actions: this.actions,
        dispatch: (action) => {
          thisRef.setState((p) => {
            return thisRef.contextStateReducer(p, action)
          })
        }
      }
    }))
  }

  // PANEL SETTINGS

  async getAppSettings () {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/appsettings`
    hycon.debug(`${this.constructor.name} getAppSettings`)
    return await axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true },
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} getAppSettings`, { error })
    })
  }

  getPanelSettings (appSettings) {
    let panelSettingsEntry = appSettings.data.filter((appSetting) => {return appSetting.settingsType === SETTINGS_KEY})[0]
    if (panelSettingsEntry) {
      let panelSetting = JSON.parse(panelSettingsEntry.settings)
      return panelSetting
    } else {
      return null
    }
  }

  getPanelSettingsEntries (appSettings) {
    let panelSettingsEntry = appSettings.data.filter((appSetting) => {return appSetting.settingsType === SETTINGS_KEY})
    return panelSettingsEntry
  }

  // MAP DRAWING

  getHighestMarkerZIndex () {
    let thisRef = this
    try {
      let highest = 0
      document.querySelectorAll('.leaflet-marker-icon').forEach((element) => {
        let zIndex = element.style.zIndex
        if (zIndex > highest) {
          highest = zIndex
        }
      })
      hycon.debug(`${thisRef.constructor.name} getHighestMarkerZIndex`, { highest })
      return highest
    } catch (e) {
      hycon.warn(`${thisRef.constructor.name} getHighestMarkerZIndex - error`)
      return null
    }
  }

  attachProjectMarkerListeners () {
    let thisRef = this
    thisRef.state.projectMarkers.forEach((projektMarker, index) => {
      projektMarker.on('click', async (event) => {
        await thisRef.onMarkerClick(event, projektMarker, index)
      })
    })
  }

  async onMarkerClick (event, marker, markerIndex) {
    let thisRef = this
    let project = thisRef.state.projects[markerIndex]
    hycon.debug(`${thisRef.constructor.name} onMarkerClick`, { event, marker, markerIndex, project })

    // if the user is in edit mode (projekt selektion) redirect it to the corresponding page
    let navigationObject = { locationName: NAVIGATION.PROJECT_EDIT.locationName, state: { project } }
    hycon.debug(`${thisRef.constructor.name} onMarkerClick - navigation`, { project, navigationObject })
    await thisRef.onNavigation(navigationObject)
  }

  async updateProjectMarkersInState () {
    let thisRef = this
    let L = thisRef.props.reduxState.map.Leaflet
    let projects = thisRef.state.projects
    let scale = 0.7
    let projectMarkers = projects.map((project) => {
      let defaultIcon = Leaflet.icon({
        iconUrl: `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/panel_energy_project/icons/Pin-Energieprojekte-gruen.png`,
        /*shadowUrl: iconShadowUrl,*/
        iconSize: [55, 78].map(v => v * scale), // size of the icon
        /*shadowSize: [50, 64],*/ // size of the shadow
        iconAnchor: [22.5, 78].map(v => v * scale), // point of the icon which will correspond to marker's location
      })
      let alternativeIcon = Leaflet.icon({
        iconUrl: `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/panel_energy_project/icons/Pin-Energieprojekte-blau.png`,
        /*shadowUrl: iconShadowUrl,*/
        iconSize: [55, 78].map(v => v * scale), // size of the icon
        /*shadowSize: [50, 64],*/ // size of the shadow
        iconAnchor: [22.5, 78].map(v => v * scale), // point of the icon which will correspond to marker's location
      })
      let icon = thisRef.state.contextState.focusedProjects.filter((fp) => {return fp.id === project.id}).length > 0 ? alternativeIcon : defaultIcon
      let marker = L.marker([project.lat, project.long], {
        icon,
        /*draggable: true,*/
        /*autoPan: true*/
      })
      let isFocused = thisRef.state.contextState.focusedProjects.filter((fp) => {return fp.id === project.id}).length > 0
      // let newHighestZIndex = highestMarkerZIndex = Math.max(marker._zIndex, highestMarkerZIndex)
      hycon.debug(`${thisRef.constructor.name} - updateProjectMarkers`, {
        isFocused,
        contextState: thisRef.state.contextState
      })
      marker.setZIndexOffset(isFocused ? 1000 : 0)
      marker.data = { project }
      return marker
    })
    await thisRef.setStateProperty('projectMarkers', projectMarkers)
    .then(() => {
      hycon.debug(`${this.constructor.name} updateProjectMarkersInState - state has been updated with the new project markers`, { projectMarkers })
    })
  }

  removeProjectMarkersInState () {
    let thisRef = this
    let projectMarkers = []
    hycon.debug(`${this.constructor.name} updateProjectMarkersInState`, { projectMarkers })
    return thisRef.setStateProperty('projectMarkers', projectMarkers)
  }

  async removeProjectMarkersFromMap () {
    let thisRef = this
    let projectMarkers = thisRef.state.projectMarkers
    let map = thisRef.props.reduxState.map.map
    hycon.debug(`${this.constructor.name} removeProjectMarkersFromMap`, { projectMarkers })
    projectMarkers.forEach((projectMarker) => {
      hycon.debug(`${this.constructor.name} removeProjectMarkersFromMap - removing`, { projectMarker })
      map.removeLayer(projectMarker)
    })
    hycon.debug(`${this.constructor.name} removeProjectMarkersFromMap - removed all projectMarkers`, { map })
  }

  addProjectMarkersToMap () {
    let thisRef = this
    let projectMarkers = thisRef.state.projectMarkers
    let map = thisRef.props.reduxState.map.map
    hycon.debug(`${this.constructor.name} addProjectMarkersToMap`, { projectMarkers })
    projectMarkers.forEach((projectMarker) => {
      projectMarker.addTo(map)
    })
  }

  async refreshMapGraphics () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} refreshMapGraphics`, { thisRef, props: this.props })

    let isMapReady = (thisRef.props.reduxState.map && thisRef.props.reduxState.map.map)
    if (!isMapReady) {
      hycon.info(`${this.constructor.name} refreshMapGraphics - map is not ready yet`, { thisRef, props: this.props })
      return null
    }

    // if the user is in the overview section hide all map graphics
    let lastNavigationObject = thisRef.getLastNavigationObject()
    if (
      lastNavigationObject.locationName === NAVIGATION.OVERVIEW.locationName
    ) {
      await thisRef.removeProjectMarkersFromMap()
      await thisRef.updateProjectMarkersInState()
    } else if (
      lastNavigationObject.locationName === NAVIGATION.DETAIL.locationName ||
      lastNavigationObject.locationName === NAVIGATION.SETTINGS.locationName
    ) {
      await thisRef.removeProjectMarkersFromMap()
      await thisRef.updateProjectMarkersInState()
      await thisRef.addProjectMarkersToMap()
      thisRef.attachProjectMarkerListeners()
    } else {
      await thisRef.removeProjectMarkersFromMap()
      await thisRef.updateProjectMarkersInState()
    }
  }

  // MAP UTIL

  getMapBounds () {
    let thisRef = this
    const map = thisRef.props.reduxState.map.map
    let bounds = map.getBounds()
    let ne_lat = bounds._northEast.lat
    let ne_lng = bounds._northEast.lng
    let sw_lat = bounds._southWest.lat
    let sw_lng = bounds._southWest.lng
    return [[ne_lng, ne_lat], [sw_lng, ne_lat], [sw_lng, sw_lat], [ne_lng, sw_lat], [ne_lng, ne_lat]]
  }

  // REQUESTS UTIL
  getDataFromConfig (config) {
    let data = {}
    for (let property in config) {
      if (config.hasOwnProperty(property)) {
        // check strings
        if (
          property === 'beschreibung' ||
          property === 'bildName' ||
          property === 'bildUrl' ||
          property === 'dokumentName' ||
          property === 'dokumentUrl' ||
          property === 'internerLink' ||
          property === 'kategorie' ||
          property === 'projektStatus' ||
          property === 'projektName' ||
          property === 'konzession'
        ) {
          if (config[property]) {
            data[property] = config[property]
          }
        }
        // check dates
        if (
          property === 'konzessionStart' ||
          property === 'konzessionEnde' ||
          property === 'projektStart' ||
          property === 'projektEnde'
        ) {
          if (moment(config[property]).isValid()) {
            data[property] = config[property]
          }
        }
        // check booleans
        if (property === 'bewilligt') {
          if (typeof config[property] === 'boolean') {
            data[`bewilligt`] = config[`bewilligt`]
          }
        }
        if (property === 'isPublic') {
          if (typeof config[property] === 'boolean') {
            data[`isPublic`] = config[`isPublic`]
          }
        }
        if (property === 'typ') {
          data[`typ`] = config[`typ`]
        }
        // check numbers
        if (
          property === 'lat' || property === 'lng'
        ) {
          if (!Number.isNaN(property)) {
            if (
              property === 'lat'
            ) {
              data[`lat`] = config[property]
            } else if (
              property === 'lng'
            ) {
              data[`long`] = config[property]
            }
          }
        }
      }
    }
    return data
  }

  // REQUESTS

  getKonzessionen (name) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/energyprojects-find-konzession/${name}`
    hycon.debug(`${this.constructor.name} getKonzessionen`, { name })
    return axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true },
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} getKonzessionen`, { name, error })
    })
  }

  getPrincipals (name) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/energyprojects-find-principals/${name}`
    hycon.debug(`${this.constructor.name} getPrincipals`, { name })
    return axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true }
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} getPrincipals`, { name, error })
    })
  }

  createPrincipal (project, principal) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/energyprojects/${project.id}/principals`
    let data = {
      'name': principal.name
    }
    hycon.debug(`${this.constructor.name} createPrincipal`, { project, principal })
    return axios(
      {
        method: 'post',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} createPrincipal`, { project, principal, error })
    })
  }

  deletePrincipal (project, principal) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/energyprojects/${project.id}/principals/${principal.id}`
    hycon.debug(`${this.constructor.name} deletePrincipal`, { project, principal })
    return axios(
      {
        method: 'delete',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true },
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} deletePrincipal`, { project, principal, error })
    })
  }

  getContractors (name) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/energyprojects-find-contractors/${name}`
    hycon.debug(`${this.constructor.name} getContractors`, { name })
    return axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME },
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} getContractors`, { name, error })
    })
  }

  createContractor (project, contractor) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/energyprojects/${project.id}/contractors`
    let data = {
      'name': contractor.name
    }
    hycon.debug(`${this.constructor.name} createContractor`, { project, contractor })
    return axios(
      {
        method: 'post',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} createContractor`, { project, contractor, error })
    })
  }

  deleteContractor (project, contractor) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/energyprojects/${project.id}/contractors/${contractor.id}`
    hycon.debug(`${this.constructor.name} deleteContractor`, { project, contractor })
    return axios(
      {
        method: 'delete',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true },
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} deleteContractor`, { project, contractor, error })
    })
  }

  getEnergyInfo (project, energyInfo) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/energyprojects/${project.id}`
    hycon.debug(`${this.constructor.name} getEnergyInfo`, { project, energyInfo })
    return axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true },
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} createEnergyInfo`, { project, energyInfo, error })
    })
  }

  createEnergyInfo (project, energyInfo) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/energyprojects/${project.id}/infos`
    let data = {
      'art': energyInfo.art,
      'einheit': energyInfo.einheit,
      'menge': energyInfo.menge
    }
    hycon.debug(`${this.constructor.name} createEnergyInfo`, { project, energyInfo })
    return axios(
      {
        method: 'post',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} createEnergyInfo`, { project, energyInfo, error })
    })
  }

  deleteEnergyInfo (project, energyInfo) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/energyprojects/${project.id}/infos/${energyInfo.id}`
    hycon.debug(`${this.constructor.name} deleteEnergyInfo`, { project, energyInfo })
    return axios(
      {
        method: 'delete',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true },
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} deleteEnergyInfo`, { project, energyInfo, error })
    })
  }

  deleteProjectById (id) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/energyprojects/${id}`
    let data = {}
    hycon.debug(`${this.constructor.name} deleteProjectById`, { id })
    return axios(
      {
        method: 'delete',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} deleteProjectById`, { error, id })
    })
  }

  /*deleteFile(id) {
    let thisRef = this
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/files/${id}`
    let data = {}
    hycon.debug(`${this.constructor.name} deleteFile`, { id })
    return axios(
      {
        method: 'delete',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "Authorization": `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} deleteFile`, { error, id })
    })
  }*/

  createProject (config = {}) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/energyprojects`

    let data = thisRef.getDataFromConfig(config)

    hycon.debug(`${this.constructor.name} createProject`, { config })
    return axios(
      {
        method: 'post',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} createProject`, { error, config })
    })
  }

  async patchProjectPartial (project, config = {}) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt

    let getResponse = await thisRef.getProject(project)

    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/energyprojects/${project.id}`

    let data = {}
    for (let property in config) {
      if (config.hasOwnProperty(property)) {
        if (property === 'bildName') {
          data[`bildName`] = config[`bildName`]
        }
        if (property === 'bildUrl') {
          data[`bildUrl`] = config[`bildUrl`]
        }
        if (property === 'dokumentName') {
          data[`dokumentName`] = config[`dokumentName`]
        }
        if (property === 'dokumentUrl') {
          data[`dokumentUrl`] = config[`dokumentUrl`]
        }
      }
    }
    let rowVersionString = getResponse.data.rowVersionString
    hycon.debug(`${this.constructor.name} patchProjectPartial`, { config, getResponse })
    return axios(
      {
        method: 'patch',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'If-Match': rowVersionString,
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} patchProjectPartial`, { error, config })
    })
  }

  async patchProject (project, config = {}) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt

    let getResponse = await thisRef.getProject(project)

    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/energyprojects/${project.id}`

    let data = thisRef.getDataFromConfig(config)
    let rowVersionString = getResponse.data.rowVersionString
    hycon.debug(`${this.constructor.name} patchProject`, { config, getResponse })
    return axios(
      {
        method: 'patch',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'If-Match': rowVersionString,
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} patchProject`, { error, config })
    })
  }

  getProject (project) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/energyprojects/${project.id}`
    hycon.debug(`${this.constructor.name} getProject`, { project })
    return axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true },
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} getProject`, { error, project })
    })
  }

  async getProjectsByPolygon () {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/energyprojects-by-polygon`
    let data = {
      'geom': [thisRef.getMapBounds()],
      'srid': 4326
    }
    return await axios(
      {
        method: 'post',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME }, // this needs to pass through the interceptor so that the spinner shows up automatically
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} getProjectsByPolygon`, { error })
    })
  }

  // AXIOS

  /*  initAxiosInterceptors (ctx) {
      let authorizationInterceptor = axios.interceptors.request.use(function (config) {
        // Do something before request is sent
        try {
          let jwt = ctx.props.reduxState.user.jwt
          if(!config.headers['Authorization']){
            config.headers['Authorization'] = `Bearer ${jwt}`
          }
        } catch (e) {
          hycon.warn(`${ctx.constructor.name} initAxiosInterceptors - intercept error`, { config, ctx })
        }
        return config
      }, function (error) {
        // Do something with request error
        return Promise.reject(error)
      })

      /!*
      let loadingRequestInterceptor = axios.interceptors.request.use(function (config) {
        // Do something before request is sent
        hycon.debug(`${ctx.constructor.name} loadingRequestInterceptor - interceptor bypass`, { config })

        if (
          !config.extra ||
          config.extra.requestedBy !== FILE_NAME
        ) {
          // ignore the interceptor
          hycon.debug(`${ctx.constructor.name} loadingRequestInterceptor - interceptor bypass (requestedBy)`, { config })
          return config
        }

        if (
          config.extra &&
          config.extra.bypassInterceptor === true
        ) {
          hycon.debug(`${ctx.constructor.name} loadingRequestInterceptor - interceptor bypass`, { config })
          return config
        }

        ctx.setStateProperty('isLoading', true)
        return config
      }, function (error) {
        // Do something with request error
        return Promise.reject(error)
      })

      let loadingResponseInterceptor = axios.interceptors.response.use(function (response) {
        // Do something before request is sent
        hycon.debug(`${ctx.constructor.name} loadingResponseInterceptor - interceptor bypass`, { response })

        if (
          !response.config.extra ||
          response.config.extra.requestedBy !== FILE_NAME
        ) {
          // ignore the interceptor
          hycon.debug(`${ctx.constructor.name} loadingResponseInterceptor - interceptor bypass (requestedBy)`, { response })
          return response
        }

        if (
          response.config.extra &&
          response.config.extra.bypassInterceptor === true
        ) {
          hycon.debug(`${ctx.constructor.name} loadingRequestInterceptor - interceptor bypass`, { response })
          return response
        }

        ctx.setStateProperty('isLoading', false)
        return response
      }, function (error) {
        // Do something with request error
        return Promise.reject(error)
      })
      *!/

      let responseInterceptor = axios.interceptors.response.use(function (response) {
        // Do something with response data
        return response
      }, function (error) {
        // Do something with response error
        return Promise.reject(error)
      })
      return this.setStateProperty('axiosInterceptors', {
        authorizationInterceptor,
        responseInterceptor,
        /!*loadingRequestInterceptor,
        loadingResponseInterceptor*!/
      })
    }

    removeAxiosInterceptors () {
      try {
        axios.interceptors.request.eject(this.state.axiosInterceptors.authorizationInterceptor)
        axios.interceptors.request.eject(this.state.axiosInterceptors.responseInterceptor)
        /!*axios.interceptors.request.eject(this.state.axiosInterceptors.loadingResponseInterceptor)
        axios.interceptors.response.eject(this.state.axiosInterceptors.loadingResponseInterceptor)*!/
      } catch (e) {
        hycon.warn(`${this.constructor.name} removeAxiosInterceptors - intercept error`, { e })
      }
    }*/

  // SUBCOMPONENTS UTIL

  getProjectStatusOptions () {
    let thisRef = this
    return [
      {
        'key': '1',
        'text': thisRef.translate('form-project-status-projektidee'),
        'value': 'form-project-status-projektidee',
        // 'locizeKey': 'form-project-status-projektidee'
      },
      {
        'key': '2',
        'text': thisRef.translate('form-project-status-machbarkeitsstudie'),
        'value': 'form-project-status-machbarkeitsstudie',
        // 'locizeKey': 'form-project-status-machbarkeitsstudie'
      },
      {
        'key': '3',
        'text': thisRef.translate('form-project-status-planung'),
        'value': 'form-project-status-planung',
        // 'locizeKey': 'form-project-status-planung'
      },
      {
        'key': '4',
        'text': thisRef.translate('form-project-status-bau'),
        'value': 'form-project-status-bau',
        // 'locizeKey': 'form-project-status-bau'
      },
      {
        'key': '5',
        'text': thisRef.translate('form-project-status-betrieb'),
        'value': 'form-project-status-betrieb',
        // 'locizeKey': 'form-project-status-betrieb'
      },
      {
        'key': '6',
        'text': thisRef.translate('form-project-status-nicht-realisiert'),
        'value': 'form-project-status-nicht-realisiert',
        // 'locizeKey': 'form-project-status-nicht-realisiert'
      },
      {
        'key': '7',
        'text': thisRef.translate('form-project-status-archiviert'),
        'value': 'form-project-status-archiviert',
        // 'locizeKey': 'form-project-status-archiviert'
      }
    ]
  }

  getCategoryTable () {
    let thisRef = this
    let table = {
      categories: [
        {
          name: thisRef.translate('form-category-energieproduktion'),
          locizeKey: 'form-category-energieproduktion',
          types: [
            {
              name: thisRef.translate('form-category-type-pv'),
              locizeKey: 'form-category-type-pv'
            },
            {
              name: thisRef.translate('form-category-type-biomasse'),
              locizeKey: 'form-category-type-biomasse',
            },
            {
              name: thisRef.translate('form-category-type-waermekaelte'),
              locizeKey: 'form-category-type-waermekaelte',
            },
            {
              name: thisRef.translate('form-category-type-geothermie'),
              locizeKey: 'form-category-type-geothermie',
            },
            {
              name: thisRef.translate('form-category-type-wasser'),
              locizeKey: 'form-category-type-wasser',
            },
            {
              name: thisRef.translate('form-category-type-wind'),
              locizeKey: 'form-category-type-wind',
            },
            {
              name: thisRef.translate('form-category-type-wasserstoff'),
              locizeKey: 'form-category-type-wasserstoff',
            },
          ]
        },
        {
          name: thisRef.translate('form-category-energiespeicher'),
          locizeKey: 'form-category-energiespeicher',
          types: []
        },
        {
          name: thisRef.translate('form-category-energieeffizienz'),
          locizeKey: 'form-category-energieeffizienz',
          types: []
        },
        {
          name: thisRef.translate('form-category-gebaeude'),
          locizeKey: 'form-category-gebaeude',
          types: []
        },
        {
          name: thisRef.translate('form-category-mobilitaet'),
          locizeKey: 'form-category-mobilitaet',
          types: []
        },
        {
          name: thisRef.translate('form-category-weitere'),
          locizeKey: 'form-category-weitere',
          types: []
        },
      ]
    }
    return table
  }

  getProjectCategories () {
    let table = this.getCategoryTable()
    let categories = []
    table.categories.forEach((category, categoryIndex) => {
      categories.push({
        'key': categoryIndex,
        'text': category.name,
        'value': category.locizeKey,
        // 'locizeKey': category.locizeKey
      })
    })
    return categories
  }

  getProjectTypes (categoryStringLocize) {
    let thisRef = this
    let table = thisRef.getCategoryTable()
    let types = []
    table.categories.forEach((category) => {
      if (category.locizeKey === categoryStringLocize) {
        category.types.forEach((type, typeIndex) => {
          types.push({
            'key': typeIndex,
            'text': type.name,
            'value': type.locizeKey,
            // 'locizeKey': type.locizeKey
          })
        })
      }
    })
    return types
  }

  // SUBCOMPONENTS

  getEditProjectsComponent () {
    let thisRef = this
    let editProjectComponent = (
      <EditProjectComponent
        {...thisRef.props}
        ctx={thisRef}
        navigationStack={thisRef.state.navigationStack}
        getProjectStatusOptions={thisRef.getProjectStatusOptions}
        getProjectCategories={thisRef.getProjectCategories}
      />)
    let component = (
      <div className={'sub-component'}>
        {editProjectComponent}
      </div>
    )
    return component
  }

  getProjectDetailsComponent () {
    let thisRef = this
    let projectDetailsComponent = (
      <ProjectDetailsComponent
        {...thisRef.props}
        ctx={thisRef}
      />)
    let component = (
      <div className={'sub-component'}>
        {projectDetailsComponent}
      </div>
    )
    return component
  }

  getSettingsComponent () {
    let thisRef = this
    let component = (
      <SettingsComponent
        {...thisRef.props}
        ctx={thisRef}
        projectStatusOptions={thisRef.getProjectStatusOptions()}
        projectCategoriesOptions={thisRef.getProjectCategories()}
        onDataChanged={() => {
          thisRef.onDataChanged()
        }}
      />
    )
    return component
  }

  getOverviewComponent () {
    let thisRef = this
    const getIconStyle = (name) => {
      // let env = thisRef.props.reduxState.env;
      let url = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/${name}`
      let itemStyle = {
        backgroundImage: `url(${url})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '65px',
        width: '65px'
      }
      hycon.debug(`${this.constructor.name} getStyle`, { itemStyle })
      return itemStyle
    }
    let overviewComponent = (
      <div
        className={'overview-component'}
        onClick={() => {
          hycon.log(`${thisRef.constructor.name} renderNavigationStack - overviewComponent - onClick`)
          thisRef.onNavigation({ locationName: NAVIGATION.DETAIL.locationName, state: null })
        }}
      >
        <div className={'overview-container'}>
          <div className={`icon`} style={getIconStyle('Energieprojekt.png')}/>
          <h1>{thisRef.state.projects.length}</h1>
          <h3>{thisRef.translate('overview-unit')}</h3>
        </div>
      </div>
    )
    return overviewComponent
  }

  // NAVIGATION

  shouldComponentUpdate (nextProps, nextState) {
    hycon.debug(`${this.constructor.name} shouldComponentUpdate`, { nextProps, props: this.props, nextState })
    return true
  }

  setStateProperty (propertyName, propertyValue) {
    let thisRef = this
    return new Promise((res) => {
      thisRef.setState((p) => {
        const obj = {}
        obj[propertyName] = propertyValue
        return Object.assign({}, p, obj)
      }, () => {
        hycon.debug(`${this.constructor.name} setStateProperty - newState`, { newState: thisRef.state })
        res()
      })
    })
  }

  translate (label, ns = 'panel_energy_project') {
    let thisRef = this
    let translation = thisRef.props.i18n.t(`${ns}:${label}`)
    if (
      translation && translation !== label
    ) {
      return translation
    } else {
      return `${label}`
      // return `${ns}:${label}`;
    }
  };

  renderLoader () {
    let thisRef = this
    let cardBody = document.querySelectorAll('.EnergyProject .card-body')[0]
    let lastNavigationObject = thisRef.getLastNavigationObject()

    let style = {}
    if (
      cardBody && lastNavigationObject.locationName !== NAVIGATION.OVERVIEW.locationName
    ) {
      let scrollHeight = cardBody.scrollHeight
      style.height = `${scrollHeight}px`
    }
    let loader = (
      <div className={'custom-panel-loader'} style={style}>
        <Loader size={`massive`}>Loading {thisRef.translate('panel-name')}</Loader>
      </div>
    )
    if (thisRef.state.isLoading) {
      return loader
    }
    return null
  }

  async onNavigation (navigationObject) {
    let thisRef = this
    hycon.debug('onNavigation', { navigationObject })
    // update the navigation stack
    let newNavigationStack = thisRef.state.navigationStack.concat([navigationObject])
    thisRef.setStateProperty('navigationStack', newNavigationStack)

    // hide or display the legacy panels based on the navigation
    if (
      navigationObject.locationName === NAVIGATION.DETAIL.locationName ||
      navigationObject.locationName === NAVIGATION.SETTINGS.locationName ||
      navigationObject.locationName === NAVIGATION.PROJECT_EDIT.locationName
    ) {
      // hide the other panels
      // thisRef.setLegacyPanelsVisibility(false)

      Promise.all([
        thisRef.props.setParentState('isPortalVisible', true),
        thisRef.props.setParentState('isTilesVisible', false)
      ])
      if(navigationObject.locationName === NAVIGATION.DETAIL.locationName){
        const buildingSelectionEvent = new CustomEvent('kibana-event', { detail: {
            type: "panel-detail",
            eventInfo: {
              info: "Energy-Project panel has been opened."
            }
          }
        });
        window.dispatchEvent(buildingSelectionEvent);
      }
    } else {
      // hide the other panels
      // thisRef.setLegacyPanelsVisibility(true)
      Promise.all([
        thisRef.props.setParentState('isPortalVisible', false),
        thisRef.props.setParentState('isTilesVisible', true)
      ])
    }

    // scroll on top
    scrollToTop('.card-body')

    // refresh the panel state
    await thisRef.onDataChanged()
  }

  getLastNavigationObject () {
    let thisRef = this
    let lastNavigationObject = thisRef.state.navigationStack.slice(-1).pop()
    return lastNavigationObject
  }

  renderNavigationStack () {
    let thisRef = this
    let editProjectComponent = thisRef.getEditProjectsComponent()
    let detailComponent = thisRef.getProjectDetailsComponent()
    let settingsComponent = thisRef.getSettingsComponent()
    let overviewComponent = thisRef.getOverviewComponent()

    let lastNavigationObject = thisRef.getLastNavigationObject()
    if (lastNavigationObject.locationName === NAVIGATION.OVERVIEW.locationName) {
      return { mainComponent: overviewComponent }
    }
    if (lastNavigationObject.locationName === NAVIGATION.DETAIL.locationName) {
      // this component will occupy the full dashboard
      return { mainComponent: detailComponent }
    }
    if (lastNavigationObject.locationName === NAVIGATION.SETTINGS.locationName) {
      return { mainComponent: settingsComponent }
    }
    if (lastNavigationObject.locationName === NAVIGATION.PROJECT_EDIT.locationName) {
      return { mainComponent: editProjectComponent }
    }
    return { mainComponent: null }
  }

  setLegacyPanelsVisibility (isVisible) {
    hycon.log('setLegacyPanelsVisibility', { isVisible })

    // hide or show the other panels
    let legacyDashboardPanels = document.querySelectorAll('#dashboard-tiles .flexitem')
    legacyDashboardPanels.forEach((e) => {
      // hycon.debug("setLegacyPanelsVisibility", {e, isVisible});
      if (isVisible) {
        try {
          e.style.display = 'flex'
        } catch (e) {
          hycon.error('setLegacyPanelsVisibility', e)
        }
      } else {
        try {
          e.style.display = 'none'
        } catch (e) {
          hycon.error('setLegacyPanelsVisibility', e)
        }
      }
    })
  }

  // OTHER

  async setPanelSettingsInState () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} setPanelSettingsInState`)
    try {
      let appSettings = await thisRef.getAppSettings()
      let panelSettings = thisRef.getPanelSettings(appSettings)
      hycon.debug(`${this.constructor.name} setPanelSettingsInState`, { panelSettings })
      await thisRef.setStateProperty('panelSetting', panelSettings)
    } catch (e) {
      hycon.error(`${this.constructor.name} setPanelSettingsInState`, e)
    }
  }

  async onDataChanged () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} onDataChanged`)
    await thisRef.setStateProperty('isLoading', true)
    await thisRef.setPanelSettingsInState()
    await thisRef.updateProjectsInState()
    await thisRef.refreshMapGraphics()
    await thisRef.setStateProperty('isLoading', false)
  }

  filterProjects (projects) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} filterProjects`)

    let panelSetting = thisRef.state.panelSetting

    let filteredProjects = []

    let passesStatusFilter = (project, panelSetting) => {
      hycon.debug(`${this.constructor.name} filterProjects - passesStatusFilter`, { projects, panelSetting })
      if (
        panelSetting.projektStatus.length > 0
      ) {
        let passes = false
        panelSetting.projektStatus.forEach((settingStatus) => {
          if (settingStatus === project.projektStatus) {
            hycon.debug(`${this.constructor.name} filterProjects - settingStatus`, {
              settingStatus,
              projektStatus: project.projektStatus
            })
            passes = true
          }
        })
        return passes
      } else {
        // passes the filter because it has not been set
        return true
      }
    }

    let passesCategoryFilter = (project, panelSetting) => {
      hycon.debug(`${this.constructor.name} filterProjects - passesCategoryFilter`, { projects, panelSetting })
      if (
        panelSetting.kategorie.length > 0
      ) {
        let passes = false
        panelSetting.kategorie.forEach((settingsKategorie) => {
          if (settingsKategorie === project.kategorie) {
            hycon.debug(`${this.constructor.name} filterProjects - settingsKategorie`, {
              settingsKategorie,
              kategorie: project.kategorie
            })
            passes = true
          }
        })
        return passes
      } else {
        // passes the filter because it has not been set
        return true
      }
    }

    if (panelSetting !== null) {
      hycon.debug(`${this.constructor.name} filterProjects - applying filter`, { projects, panelSetting })

      projects.forEach((project) => {
        let passesFilter = true
        if (panelSetting.projektStart) {
          if (
            moment(panelSetting.projektStart).isValid() &&
            moment(project.projektStart).isSameOrAfter(moment(panelSetting.projektStart, 'YYYY-MM-DD'), 'day')
          ) {
            hycon.debug(`${this.constructor.name} filterProjects - pass projektStart`, { projects, panelSetting })
          } else {
            passesFilter = false
            return passesFilter
          }
        }
        if (panelSetting.projektEnde) {
          if (
            moment(panelSetting.projektEnde).isValid() &&
            moment(project.projektEnde).isSameOrBefore(moment(panelSetting.projektEnde, 'YYYY-MM-DD'), 'day')
          ) {
            hycon.debug(`${this.constructor.name} filterProjects - pass projektEnde`, { projects, panelSetting })
          } else {
            passesFilter = false
            return passesFilter
          }
        }
        if (panelSetting.projektStatus) {
          if (passesStatusFilter(project, panelSetting)) {
            hycon.debug(`${this.constructor.name} filterProjects - pass projektStatus`, { projects, panelSetting })
          } else {
            passesFilter = false
            return passesFilter
          }
        }
        if (panelSetting.kategorie) {
          if (passesCategoryFilter(project, panelSetting)) {
            hycon.debug(`${this.constructor.name} filterProjects - pass kategorie`, { projects, panelSetting })
          } else {
            passesFilter = false
            return passesFilter
          }
        }
        if (passesFilter) {
          filteredProjects.push(project)
        } else {
          hycon.debug(`${this.constructor.name} filterProjects - rejected`, { project, panelSetting })
        }
      })
      return filteredProjects
    } else {
      filteredProjects = projects
      hycon.debug(`${this.constructor.name} filterProjects - no filter applied`, { filteredProjects })
      return filteredProjects
    }
  }

  sortProjects (projects) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} sortProjects`)
    let map = thisRef.props.reduxState.map.map
    let sortedProjects = [].concat(projects)

    let compareDistance = (projectA, projectB) => {
      let mapCenter = map.getCenter()

      let projectA_LatLng = [projectA.lat, projectA.long]
      let projectB_LatLng = [projectB.lat, projectB.long]

      let distance_A = map.distance(projectA_LatLng, mapCenter)
      let distance_B = map.distance(projectB_LatLng, mapCenter)

      projectA.distanceFromCenter = distance_A
      projectB.distanceFromCenter = distance_B

      hycon.debug(`${thisRef.constructor.name} sortProjects - comparing`, {
        distance_A,
        distance_B,
        mapCenter,
        projectA,
        projectB
      })

      if (distance_B <= distance_A) {
        return 1
      } else if (distance_B > distance_A) {
        return -1
      } else {
        return 0
      }
    }
    sortedProjects.sort(compareDistance)
    hycon.debug(`${thisRef.constructor.name} sortProjects`, sortedProjects)
    return sortedProjects
  }

  updateProjectsInState () {
    let thisRef = this
    // save the projects to state
    return thisRef.getProjectsByPolygon()
    .then((response) => {
      let projects = response.data
      let filterProjects = thisRef.filterProjects(projects)
      let sortedProjects = thisRef.sortProjects(filterProjects)
      thisRef.setStateProperty('projects', sortedProjects)
    })
    .catch((e) => {
      hycon.error(e)
    })
  }

  async onMapMoveZoom (event) {
    let thisRef = this
    hycon.log(`${thisRef.constructor.name} onMapMoveZoom`, { event })
    await thisRef.setStateProperty('isLoading', true)
    await thisRef.updateProjectsInState()
    await thisRef.refreshMapGraphics()
    await thisRef.setStateProperty('isLoading', false)
  }

  initMapListeners () {
    let thisRef = this
    hycon.log(`${this.constructor.name} initMapListeners`, { thisRef })
    thisRef.props.reduxState.map.map.on('moveend zoomend', thisRef.onMapMoveZoom)
    // trigger the state update to get the new projects displayed
    thisRef.onDataChanged()
  }

  removeMapListeners () {
    let thisRef = this
    hycon.log(`${this.constructor.name} removeMapListeners`, { thisRef })
    thisRef.props.reduxState.map.map.off('moveend zoomend', thisRef.onMapMoveZoom)
  }

  render () {
    let thisRef = this
    let portal = document.getElementById('dashboard-portal')

    // decide based on the navigation where that the component should be rendered
    let lastNavigationObject = thisRef.getLastNavigationObject()

    const renderAll = () => {
      if (!lastNavigationObject) {
        // navigation stack is empty
        return null
      } else if (
        lastNavigationObject.locationName === NAVIGATION.DETAIL.locationName ||
        lastNavigationObject.locationName === NAVIGATION.PROJECT_EDIT.locationName ||
        lastNavigationObject.locationName === NAVIGATION.SETTINGS.locationName
      ) {
        // detail will be rendered in portal
        let cardComponent = (
          <div className={'Card EnergyProject in-portal'}>
            <ContextMenu
              {...thisRef.props}
              {
                ...{
                  onNavigation: thisRef.onNavigation,
                  getLastNavigationObject: thisRef.getLastNavigationObject
                }
              }
              parentState={thisRef.state}
              locationName={lastNavigationObject.locationName}
            />
            <div className={'card-body'}>
              {thisRef.renderNavigationStack().mainComponent}
              {thisRef.renderLoader()}
              <div className={'card-body-portal'}/>
            </div>
          </div>
        )
        // render the component in the portal
        return ReactDOM.createPortal(cardComponent, portal)
      } else {
        // the rest will be rendered in cards
        return (
          <div className={'Card EnergyProject'}>
            <ContextMenu
              {...thisRef.props}
              {
                ...{
                  onNavigation: thisRef.onNavigation,
                  getLastNavigationObject: thisRef.getLastNavigationObject
                }
              }
              parentState={thisRef.state}
              locationName={lastNavigationObject.locationName}
            />
            <div className={'card-body'}>
              {thisRef.renderNavigationStack().mainComponent}
              {thisRef.renderLoader()}
              <div className={'card-body-portal'}/>
            </div>
          </div>
        )
      }
    }
    return (
      <EnergyProjectContext.Provider value={this.state.contextState}>
        {renderAll()}
      </EnergyProjectContext.Provider>
    )
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} componentDidUpdate`, {
      prevProps,
      newProps: this.props,
      prevState,
      snapshot
    })

    if (
      prevState.isMounted &&
      prevProps.reduxState.map.map &&
      prevState.isMapListenersInitialized === false
    ) {
      thisRef.setStateProperty('isMapListenersInitialized', true).then(() => {
        thisRef.initMapListeners()
        return thisRef.onDataChanged()
      })
    }
  }

  componentWillUnmount () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} componentWillUnmount`, { props: thisRef.props })

    thisRef.setStateProperty('isMapListenersInitialized', false).then(() => {
      thisRef.removeMapListeners()
    })
  }

  componentDidCatch (error, errorInfo) {
    hycon.debug(`${this.constructor.name} componentDidCatch`, { error, errorInfo })
  }
}

class SettingsComponent extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      form: this.getNewForm(),
      publicExtentLink: ''
    }
  }

  setStateProperty (propertyName, propertyValue) {
    let thisRef = this
    return new Promise((res) => {
      thisRef.setState((p) => {
        const obj = {}
        obj[propertyName] = propertyValue
        return Object.assign({}, p, obj)
      }, () => {
        hycon.debug(`${this.constructor.name} setStateProperty - newState`, { newState: thisRef.state })
        res()
      })
    })
  }

  async getAppSettings () {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/appsettings`
    hycon.debug(`${this.constructor.name} getAppSettings`)
    return await axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true },
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} getAppSettings`, { error })
    })
  }

  getPanelSettings (appSettings) {
    let panelSettingsEntry = appSettings.data.filter((appSetting) => {return appSetting.settingsType === SETTINGS_KEY})[0]
    if (panelSettingsEntry) {
      let panelSetting = JSON.parse(panelSettingsEntry.settings)
      return panelSetting
    } else {
      return null
    }
  }

  async postAppSettings () {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let form = thisRef.state.form
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/appsettings`

    hycon.debug(`${this.constructor.name} getAppSettings`, {})
    let data = {
      'settingsType': SETTINGS_KEY,
      'settings': JSON.stringify(form, null, 4)
    }
    return await axios(
      {
        method: 'post',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data),
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true },
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} getAppSettings`, { error })
    })
  }

  async putAppSettings (id) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let form = thisRef.state.form
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/appsettings/${id}`

    hycon.debug(`${this.constructor.name} putAppSettings`, {})
    let data = {
      'settingsType': SETTINGS_KEY,
      'settings': JSON.stringify(form, null, 4)
    }
    return await axios(
      {
        method: 'put',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data),
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true },
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} putAppSettings`, { error })
    })
  }

  async deleteAppSettings (id) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let env = thisRef.props.reduxState.env
    let endpoint = `${env.API_GATEWAY_BASE}/api/appsettings/${id}`

    hycon.debug(`${this.constructor.name} deleteAppSettings`, {})
    return await axios(
      {
        method: 'delete',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        extra: { requestedBy: FILE_NAME, bypassInterceptor: true },
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${this.constructor.name} deleteAppSettings`, { error })
    })
  }

  getPanelSettingsEntries (appSettings) {
    let panelSettingsEntry = appSettings.data.filter((appSetting) => {return appSetting.settingsType === SETTINGS_KEY})
    return panelSettingsEntry
  }

  async deleteAllPanelSettings () {
    let thisRef = this
    let appSettings = await thisRef.getAppSettings()
    let panelSettingsEntries = thisRef.getPanelSettingsEntries(appSettings)
    hycon.debug(`${thisRef.constructor.name} deleteAllPanelSettings - 1`, { panelSettingsEntries })
    let deletePromises = []
    panelSettingsEntries.forEach((panelSettingsEntry) => {
      hycon.debug(`${thisRef.constructor.name} deleteAllPanelSettings - 2`, { id: panelSettingsEntry.id })
      deletePromises.push(thisRef.deleteAppSettings(panelSettingsEntry.id))
    })
    return await Promise.all(deletePromises)
  }

  async updateAppSettings () {
    let thisRef = this

    let appSettings = await thisRef.getAppSettings()
    let panelSettings = thisRef.getPanelSettings(appSettings)

    hycon.debug(`${this.constructor.name} updateAppSettings`, { appSettings, panelSettings })

    // delete all panel settings before upating
    let deleteAllPanelSettingsPromise = await thisRef.deleteAllPanelSettings()
    hycon.debug(`${this.constructor.name} deleteAllPanelSettingsPromise`, { deleteAllPanelSettingsPromise })

    return await thisRef.postAppSettings()
  }

  async getSavedForm () {
    let thisRef = this
    let appSettings = await thisRef.getAppSettings()
    let panelSettings = thisRef.getPanelSettings(appSettings)
    try {
      hycon.debug(`${this.constructor.name} getSavedForm`, { panelSettings })
      let restoredForm = panelSettings
      hycon.debug(`${this.constructor.name} getSavedForm`, { restoredForm })
      let form = {
        projektStart: restoredForm.projektStart,
        projektEnde: restoredForm.projektEnde,
        projektStatus: restoredForm.projektStatus,
        kategorie: restoredForm.kategorie,
      }
      hycon.debug(`${this.constructor.name} getSavedForm`, { form })
      return form
    } catch (e) {
      hycon.error(`${this.constructor.name} getSavedForm - error`, e)
      return this.getNewForm()
    }
  }

  getNewForm () {
    return {
      projektStart: null,
      projektEnde: null,
      projektStatus: [],
      kategorie: [],
    }
  }

  async updateForm (formValuesToOverride) {
    let thisRef = this
    return await new Promise((resolve) => {
      thisRef.setState((prevState) => {
        return {
          ...prevState,
          form: {
            ...prevState.form,
            ...formValuesToOverride
          }
        }
      }, resolve)
    })
  }

  async componentDidMount () {
    let thisRef = this
    let props = thisRef.props
    let appSettings = await thisRef.getAppSettings()
    let panelSettings = thisRef.getPanelSettings(appSettings)
    hycon.debug(`${this.constructor.name} componentDidMount`, { props, appSettings, panelSettings })
    if (panelSettings !== null) {
      let restoredForm = await thisRef.getSavedForm()
      hycon.debug(`${this.constructor.name} componentDidMount`, { props, appSettings, panelSettings, restoredForm })
      return await thisRef.setStateProperty('form', restoredForm)
    }
  }

  async onSaveButtonPress () {
    let thisRef = this
    let props = thisRef.props
    try {
      hycon.debug(`${this.constructor.name} onSaveButtonPress`, { props })
      let updateSettingsResponse = await thisRef.updateAppSettings()
      hycon.debug(`${this.constructor.name} onSaveButtonPress`, { updateSettingsResponse })
      return updateSettingsResponse
    } catch (e) {
      hycon.debug(`${this.constructor.name} onSaveButtonPress - error`, { e })
    }
  }

  render () {
    let thisRef = this
    let props = thisRef.props
    let form = thisRef.state.form
    return (
      <div className={`settings`}>
        <div className={'flex-wrapper'}>
          <div className={'form'}>
            <div className={'form-section'}>
              <div className={'section-row'}>
                <label>{props.ctx.translate('edit-label-projekt-zeitraum-von')}</label><br/>
                <div className={'inline-input date'}>
                  <Input
                    fluid
                    type={'date'}
                    id={'date-zeitraum-from'}
                    onChange={async (event, data) => {
                      hycon.debug(`${thisRef.constructor.name} onChange`, { event, data })
                      await this.updateForm({ projektStart: data.value })
                    }}
                    value={form.projektStart || ''}
                  />
                  <i className="fas fa-trash" onClick={async () => {
                    await this.updateForm({ projektStart: '' })
                  }}/>
                </div>
              </div>
              <div className={'section-row'}>
                <label>{props.ctx.translate('edit-label-projekt-zeitraum-bis')}</label><br/>
                <div className={'inline-input date'}>
                  <Input
                    fluid
                    type={'date'}
                    id={'date-zeitraum-to'}
                    onChange={async (event, data) => {
                      hycon.debug(`${thisRef.constructor.name} onChange`, { event, data })
                      await this.updateForm({ projektEnde: data.value })
                    }}
                    value={form.projektEnde || ''}
                  />
                  <i className="fas fa-trash" onClick={async () => {
                    await this.updateForm({ projektEnde: '' })
                  }}/>
                </div>
              </div>
              <div className={'section-row'}>
                <label>{props.ctx.translate('edit-label-projekt-status')}</label><br/>
                <div className={'inline-input'}>
                  <Dropdown
                    className={'dropdown'}
                    upward={false}
                    clearable={true}
                    fluid
                    search
                    selection
                    multiple
                    closeOnChange
                    onChange={async (event, data) => {
                      hycon.debug(`${thisRef.constructor.name} onChange`, { event, data })
                      await thisRef.updateForm({ projektStatus: data.value })
                    }}
                    options={props.projectStatusOptions}
                    value={form.projektStatus || []}
                  />
                  <i className="fas fa-trash" onClick={async () => {
                    await this.updateForm({ projektStatus: [] })
                  }}/>
                </div>
              </div>
              <div className={'section-row'}>
                <label>{props.ctx.translate('edit-label-projekt-kategorie')}</label><br/>
                <div className={'inline-input'}>
                  <Dropdown
                    id={`project-category`}
                    className={'dropdown'}
                    upward={false}
                    search
                    selection
                    multiple
                    fluid
                    onChange={async (event, data) => {
                      hycon.debug(`${thisRef.constructor.name} onChange`, { event, data })
                      await thisRef.updateForm({ kategorie: data.value })
                    }}
                    options={props.projectCategoriesOptions}
                    value={form.kategorie || []}
                  />
                  <i className="fas fa-trash" onClick={async () => {
                    await this.updateForm({ kategorie: [] })
                  }}/>
                </div>
              </div>
              <div className={'section-row link'}>
                <Button onClick={async () => {
                  const getLayerByName = (name) => {
                    if (name === 'grey') {
                      return props.maps.Leaflet.layerNames.MAP_SWISSTOPO_GREY_URL
                    }
                    if (name === 'color') {
                      return props.maps.Leaflet.layerNames.MAP_SWISSTOPO_COLOR_URL
                    }
                    if (name === 'cadastral') {
                      return props.maps.Leaflet.layerNames.MAP_CADASTRAL_COLOR_URL
                    }
                  }
                  const getPublicLink = () => {
                    try {
                      let map = thisRef.props.reduxState.map.map
                      let bounds = map.getBounds()
                      hycon.debug(`${this.constructor.name} getPublicLink`, { bounds, props: thisRef.props })
                      let vars = {
                        LANG: thisRef.props.i18n.language,
                        SEP_WEB_BASE_URL: window.location.origin,
                        SETTINGS: {
                          selectedBaseLayer: {
                            name: getLayerByName(thisRef.props.selectedLayer)
                          },
                          initialBounds: bounds
                        }
                      }
                      let link = `${vars.SEP_WEB_BASE_URL}/open/map/?settings=${encodeURIComponent(JSON.stringify(vars.SETTINGS))}&jwt=REPLACE_THIS&lang=${vars.LANG}`
                      hycon.debug(`${this.constructor.name} getPublicLink`, { link })
                      return link
                    } catch (e) {
                      hycon.error(`${this.constructor.name} getPublicLink - error`, { e })
                      return null
                    }
                  }
                  let link = getPublicLink()
                  hycon.debug(`${this.constructor.name} getRestoreLink - settings state`, { thisRef, link })

                  thisRef.setState((prevState) => {
                    return {
                      publicExtentLink: link,
                      form: prevState.form
                    }
                  }, () => {
                    try {
                      let area = document.getElementById('public-link-textarea')
                      area.select()
                      document.execCommand('copy')
                      alert(props.ctx.translate('button-get-public-link-coped-to-clipboard'))
                    } catch (e) {
                      hycon.warn(`${this.constructor.name} getRestoreLink - clipboard error`, { thisRef, e })
                    }
                  })

                }}>{props.ctx.translate('button-get-public-link')}</Button>
                <textarea id={'public-link-textarea'} value={thisRef.state.publicExtentLink} cols={5} rows={1}/>
              </div>
              <div className={'section-row button-contianer'}>
                <Button
                  fluid
                  primary
                  onClick={async (event) => {
                    hycon.debug(`${thisRef.constructor.name} onChange`, { event })
                    await props.ctx.setStateProperty('isLoading', true)
                    let saveResponse = await thisRef.onSaveButtonPress()
                    hycon.debug(`${thisRef.constructor.name} onChange - saveResponse`, { saveResponse })
                    await thisRef.props.onDataChanged()
                    await props.ctx.setStateProperty('isLoading', true)
                  }}
                >
                  {props.ctx.translate('settings-save')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation('panel_energy_project')(EnergyProject)

