import React, {Component} from 'react';
 import HyperConsole from "../../hyper_console/hyper-console";

import {Button} from 'semantic-ui-react';
import "./Navbar.css";
import { withTranslation } from 'react-i18next'
import Search from '../component_search/Search'

let hycon = new HyperConsole({isEnabled: false, name:__filename}).myConsole;

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
        hycon.debug(`${this.constructor.name} constructor`, {props: this.props});
    }

    setStateProperty(propertyName, propertyValue) {
        let thisRef = this;
        return new Promise((res) => {
            thisRef.setState((p) => {
                const obj = {};
                obj[propertyName] = propertyValue;
                return Object.assign({}, p, obj);
            }, () => {
                hycon.debug(`${this.constructor.name} setStateProperty - newState`, {newState: thisRef.state});
                res();
            })
        });
    }

    componentWillMount() {
        hycon.debug(`${this.constructor.name} componentWillMount`, {props: this.props});
    }

    componentDidMount() {
        hycon.debug(`${this.constructor.name} componentDidMount`, {props: this.props});
    }

    componentWillReceiveProps() {
        hycon.debug(`${this.constructor.name} componentWillReceiveProps`, {props: this.props});
    }

    shouldComponentUpdate(nextProps, nextState) {
        hycon.debug(`${this.constructor.name} shouldComponentUpdate`, {nextProps, props: this.props, nextState});
        return true;
    }

    componentWillUpdate() {
        hycon.debug(`${this.constructor.name} componentWillUpdate`, {props: this.props});
    }

    getComponents(){
        let thisRef = this;
        if(
          thisRef.props.isSearchDisplayed
        ){
            return (
              <div className={"input-wrapper"}>
                  <Search {...this.props}/>
              </div>
            )
        }
    }

    render() {
        let thisRef = this;
        return (
            <div className="Navbar">
                <div className="navbar-container">
                    <div className={`element isOpen-${thisRef.state.isOpen}`}>
                        <Button
                            primary
                            active={thisRef.state.isOpen}
                            icon='bars'
                            size={"big"}
                            style={{display: "block"}}
                            onClick={() => {
                                thisRef.setStateProperty("isOpen", !thisRef.state.isOpen)
                                    .then(() => {
                                        this.props.onMenuToggle();
                                    })
                            }}
                        />
                    </div>
                    {thisRef.getComponents()}
                </div>
            </div>
        );
    }

    componentDidUpdate() {
        hycon.debug(`${this.constructor.name} componentDidUpdate`, {props: this.props});
    }

    componentWillUnmount() {
        hycon.debug(`${this.constructor.name} componentWillUnmount`, {props: this.props});
    }
}

export default withTranslation()(Navbar);

