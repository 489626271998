import env from '../../env/env'
import HyperConsole from '../hyper_console/hyper-console'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV;

let hycon = null;
if(REACT_APP_GI_ENV === "development"){
  hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
} else {
  hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
}

const NOTIFICATION_SERVER_BASE_URL = env.NOTIFICATION_SERVER_BASE_URL
const applicationServerPublicKey = env.NOTIFICATION_SERVER_PUBLIC_KEY
function urlB64ToUint8Array (base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding)
  // eslint-disable-next-line
  .replace(/\-/g, "+")
  .replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

class SWUClass {
  constructor () {
    this.constants = {}
  }

  test () {
    let thisRef = this
    hycon.info(`${thisRef.constructor.name} test`, {})
  }

  askNotificationPermission (registration) {
    // let thisRef = this;
    return new Promise((res, rej) => {
      Notification.requestPermission().then(function (result) {
        if (result === 'denied') {
          hycon.log('Permission wasn\'t granted. Allow a retry.')
          rej(registration)
        }
        if (result === 'default') {
          hycon.log('The permission request was dismissed.')
          rej(registration)
        }
        res(registration)
        // Do something with the granted permission.
      })
    })
  }

  subscribeUser (registration) {
    let thisRef = this
    const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey)
    return registration.pushManager
    .subscribe({
      userVisibleOnly: true,
      applicationServerKey
    })
    .then(subscription => {
      hycon.log('Browser is subscribed:', subscription)
      thisRef.updateSubscriptionOnServer(subscription)
    })
  }

  updateSubscriptionOnServer (subscription) {
    // Here's where you would send the subscription to the application server
    if (subscription) {
      const jsonSubscription = JSON.stringify(subscription)
      const xhr = new XMLHttpRequest()
      xhr.open('POST', `${NOTIFICATION_SERVER_BASE_URL}/subscribe`, true)
      xhr.setRequestHeader('Content-type', 'application/json')
      xhr.onload = function () {
        // do something to response
        try {
          hycon.log(this.response)
        } catch (e) {
          hycon.error(e)
        }
      }
      xhr.send(jsonSubscription)
      hycon.log('updateSubscriptionOnServer - sent sub', { subscription, jsonSubscription })
    } else {
      hycon.log('updateSubscriptionOnServer - did not send sub', { subscription })
    }
  }

  register () {
    hycon.info('ServiceWorkerUtil - registering sep-sw.js')
    let thisRef = this
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sep-sw.js', {
        scope: '.'
      })
      .then(function (registration) {
        hycon.log('ServiceWorkerUtil - Service worker registration succeeded:', registration)
        registration.addEventListener('install', function () {
          hycon.log('ServiceWorkerUtil - install callback')
        })
        registration.addEventListener('fetch', function (event) {
          hycon.log('ServiceWorkerUtil - fetch callback')
          event.respondWith(
            caches.match(event.request)
          )
        })
        registration.onupdatefound = () => {
          hycon.info('ServiceWorkerUtil - onupdatefound')
          const installingWorker = registration.installing
          installingWorker.onstatechange = () => {
            if (
              installingWorker.state === 'installed' &&
              navigator.serviceWorker &&
              navigator.serviceWorker.controller
            ) {
              // Preferably, display a message asking the user to reload...
              hycon.info('ServiceWorkerUtil - onupdatefound - onstatechange - state:installed - updating.')
              return registration.update()
            } else {
              hycon.info("ServiceWorkerUtil - onupdatefound - onstatechange - doesn't need to be updated.", { installingWorker, navigator })
              return registration.update()
            }
          }
          hycon.info('ServiceWorkerUtil - onupdatefound - updating.')
          return registration.update()
        }
        return registration
      })
      .then((registration) => {
        return thisRef.askNotificationPermission(registration)
      })
      .then((registration) => {
        // greet the user
        /*let data = {}
        let title = data.title || 'Willkommen auf SEP'
        let message = data.message || 'Wir wünschen Ihnen eine gute Energieplanung'
        let icon = 'images/icons/icon-512x512.png'
        const options = {
          body: message,
          icon: icon,
          vibrate: [100, 50, 100],
          data: {
            dateOfArrival: Date.now(),
            primaryKey: 1
          }
        }
        return registration.showNotification(title, options)
          .then(() => {
            return registration
          })*/
        return registration;
      })
      .then((registration) => {
        // update the sw to the latest version
        return registration.update().then(() => {
          hycon.info('ServiceWorkerUtil - updated again.')
          return registration
        })
      })
      .then((registration) => {
        // get the subscription status;
        // only subscrbe if not prod
        if (env.REACT_APP_GI_ENV === 'production') {
          hycon.info('ServiceWorkerUtil - skipping push notification server subscription (production app)')
          return Promise.resolve()
        }
        hycon.info('ServiceWorkerUtil - checking push notification server subscription')
        return registration.pushManager.getSubscription()
        .then(subscription => {
          let isSubscribed = subscription !== null
          if (isSubscribed) {
            hycon.info('ServiceWorkerUtil - Browser is already subscribed.')
            // just update the subscription on the server
            thisRef.updateSubscriptionOnServer(subscription)
          } else {
            hycon.info('ServiceWorkerUtil - Browser is not subscribed.')
            // subscribe and update the subscription on the server
            thisRef.subscribeUser(registration)
          }
        })
      })
      .catch(e => {
        hycon.error(e)
      })
    }
  }

  initSEPSW () {
    if(window.location.pathname !== "/open/remote/"){
      let thisRef = this
      thisRef.register()
    }
  }
}

export const ServiceWorkerUtility = new SWUClass()
// const ServiceWorkerUtility = new SWUClass();
