import React, { Component } from 'react'
import HyperConsole from '../../../hyper_console/hyper-console'

import './PublicPanelContainer.css'

import { withTranslation } from 'react-i18next'
import PublicEnergyProject from './../cards/energy_project/public/PublicEnergyProject'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

class PublicPanelContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPortalVisible: false,
      isTilesVisible: true,
    }
    this.setParentState = this.setParentState.bind(this)
    hycon.debug(`${this.constructor.name} constructor`, { props: this.props })
  }

  componentWillMount () {
    hycon.debug(`${this.constructor.name} componentWillMount`, { props: this.props })
  }

  componentDidMount () {
    hycon.debug(`${this.constructor.name} componentDidMount`, { props: this.props })
  }

  componentWillReceiveProps () {
    hycon.debug(`${this.constructor.name} componentWillReceiveProps`, { props: this.props })
  }

  shouldComponentUpdate (nextProps, nextState) {
    hycon.debug(`${this.constructor.name} shouldComponentUpdate`, { nextProps, props: this.props, nextState })
    return true
  }

  componentWillUpdate () {
    hycon.debug(`${this.constructor.name} componentWillUpdate`, { props: this.props })
  }

  setStateProperty (propertyName, propertyValue) {
    let thisRef = this
    return new Promise((res) => {
      thisRef.setState((p) => {
        const obj = {}
        obj[propertyName] = propertyValue
        return Object.assign({}, p, obj)
      }, () => {
        hycon.debug(`${this.constructor.name} setStateProperty - newState`, { newState: thisRef.state })
        res()
      })
    })
  }

  setParentState (stateProperyName, value) {
    let thisRef = this
    return thisRef.setStateProperty(stateProperyName, value)
  }

  getCards (baseId) {
    let thisRef = this
    let cards = []
    let i = baseId
    cards.push((
      <PublicEnergyProject
        key={`card_item_${i++}`}
        {...thisRef.props}
      />
    ))
    return cards
  };

  getDashboardByMandantAndRole () {
    // todo check if public access

  }

  getPublicDashboard () {

  }

  render () {
    let thisRef = this
    let isTilesVisible = thisRef.state.isTilesVisible
    let props = thisRef.props
    if (
      props.SEPContext &&
      props.SEPContext.user &&
      props.SEPContext.user.publicJWT
    ) {
      return (
        <div className="PublicPanelContainer">
          <div className="PublicPanelContainer-flex">
            <div id={`overlay-dashboard-panels`} className={`${isTilesVisible ? 'visible' : 'invisible'}`}>
              {thisRef.getCards(0)}
            </div>
          </div>
        </div>
      )
    } else {
      hycon.info(`${thisRef.constructor.name} - render - no user and  publicJWT in SEPContext (props.SEPContext.user.publicJWT)`, { props })
      return null;
    }
  }

  componentDidUpdate () {
    hycon.debug(`${this.constructor.name} componentDidUpdate`, { props: this.props })
  }

  componentDidCatch (error, errorInfo) {
    hycon.debug(`${this.constructor.name} componentDidCatch`, { error, errorInfo })
    if (error.message === 'Unauthorized') {
      throw error
    }
  }

  componentWillUnmount () {
    hycon.debug(`${this.constructor.name} componentWillUnmount`, { props: this.props })
  }
}

export default withTranslation([
  'panel_building_info',
  'panel_coming_soon',
  'panel_construction_zone',
  'panel_electricity',
  'panel_demo',
  'panel_preview',
  'panel_solar_electricity',
  'panel_usage',
  'panel_zev',
  'panel_energy_project',
  'dashboard',
])(PublicPanelContainer)

