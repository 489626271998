import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useFavoriteCtx } from '../../FavoriteProvider';
import HyperConsole from '../../../hyper_console/hyper-console';
import { LOCIZE_PANEL_NS, reducerActions, REACT_APP_GI_ENV } from '../../constants';
import FavoriteItem from '../favorite-item/FavoriteItem';

import './FavoriteList.css';

let hycon = null;
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
}

export default function FavoriteList(props) {
  const { history, isLoading } = props;
  const hookName = 'FavoriteList';
  const favoriteContext = useFavoriteCtx();
  const { t } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false });

  useEffect(() => {
    const deselectAll = async () => {
      hycon.debug(`${hookName} addEventListener - keydown - deselectAll`, {});
      favoriteContext.dispatch({
        type: reducerActions.REPLACE,
        info: 'onChange',
        payload: {
          newState: {
            selection: [],
          },
        },
      });
    };
    const onKeyDown = async (event) => {
      hycon.debug(`${hookName} addEventListener - keydown - onKeyDown`, { event, favoriteContext });
      let isEscape = false;
      if ('key' in event) {
        isEscape = (event.key === 'Escape' || event.key === 'Esc');
      } else {
        isEscape = (event.keyCode === 27);
      }
      if (
        isEscape
      ) {
        await deselectAll();
      }
    };
    hycon.debug(`${hookName} addEventListener - keydown`, {});
    // let target = document.body;
    const target = document.getElementById('root');
    target.addEventListener('keydown', onKeyDown);
    return () => {
      hycon.debug(`${hookName} removeEventListener - keydown`, {});
      target.removeEventListener('keydown', onKeyDown);
    };
  }, [favoriteContext]);
  return (
    <div className={hookName}>
      <div className="list-container">
        <Dimmer active={isLoading}>
          <Loader size="small" />
        </Dimmer>
        {(() => {
          if (favoriteContext.favorites.length === 0 && !isLoading) {
            return (
              <div className="message-no-favorites">
                {t('favorites:table-message-no-favorites')}
              </div>
            );
          }
        })()}
        {(favoriteContext.paginationFavorites || []).map((favorite, favoriteIndex) => (
          <FavoriteItem
            key={favoriteIndex}
            history={history}
            favorite={favorite}
          />
        ))}
      </div>
    </div>
  );
}
