/* eslint-env browser */

import React, { Component, useEffect } from 'react'
import HyperConsole from '../../hyper_console/hyper-console'
import './LanguageSwitch.css'
import { CookieUtil } from '../../user_management/cookies/cookie-util'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

export function initLangFromCookie (ctx) {
  let key = CookieUtil.constants.names.LANGUAGE
  let cookieString = CookieUtil.getCookie(key)
  hycon.debug(`${'LanguageSwitch'} - ${key} cookie string found`, { cookieString })
  let parsedCookie = null
  try {
    parsedCookie = JSON.parse(cookieString)
    return new Promise((resolve, reject) => {
      try {
        let i18n = ctx.props.i18n
        let cookieLanguage = parsedCookie.targetLanguage;
        hycon.debug(`${'LanguageSwitch'} switchLanguage `, { targetLanguage: cookieLanguage })
        i18n.changeLanguage(cookieLanguage, () => {
          hycon.debug(`${'LanguageSwitch'} switchLanguage - done`, { targetLanguage: cookieLanguage })
          resolve()
        })
      } catch (e) {
        let i18n = ctx.props.i18n
        let browserLanguage = window.navigator.language;
        hycon.debug(`${'LanguageSwitch'} switchLanguage - no language from cookie`, { browserLanguage })
        let defaultLanguage = 'de-CH'
        if (
          browserLanguage.startsWith('de')
        ) {
          defaultLanguage = 'de-CH'
        } else if (
          browserLanguage.startsWith('fr')
        ) {
          defaultLanguage = 'fr-CH'
        } else if (
          browserLanguage.startsWith('it')
        ) {
          defaultLanguage = 'it-CH'
        }
        hycon.debug(`${'LanguageSwitch'} switchLanguage - applying default language`, { targetLanguage: defaultLanguage })
        i18n.changeLanguage(defaultLanguage, () => {
          hycon.debug(`${'LanguageSwitch'} switchLanguage - done`, { targetLanguage: defaultLanguage })
          try {
            CookieUtil.setCookie(CookieUtil.constants.names.LANGUAGE, JSON.stringify({ targetLanguage: defaultLanguage }))
          } catch (e) {
            hycon.warn("LanguageSwitch - switchLanguage - warning (cookie)", e);
            reject(e)
          }
        })
        resolve()
      }
    })
  } catch (e) {
    hycon.warn(`${'LanguageSwitch'} - warning - could not parse ${key} cookie`, { cookieString })
  }
}

class LanguageSwitch extends Component {
  constructor (props) {
    super(props)
    this.state = {
      languageCookie: {
        targetLanguage: null
      }
    }
    // let thisRef = this;
    hycon.debug(`${this.constructor.name} constructor`, { props: this.props })
  }

  componentDidMount () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} componentDidMount`, { props: this.props })
    thisRef.getFromCookie()
  }

  getFromCookie () {
    let thisRef = this
    let key = CookieUtil.constants.names.LANGUAGE
    let cookieString = CookieUtil.getCookie(key)
    hycon.debug(`${thisRef.constructor.name} - ${key} cookie string found`, { cookieString })
    let parsedCookie = null
    try {
      parsedCookie = JSON.parse(cookieString)
      thisRef.setStateProperty('languageCookie', parsedCookie)
    } catch (e) {
      hycon.warn(`${thisRef.constructor.name} - warning - could not parse ${key} cookie`, { cookieString })
      // fallback to default language
      thisRef.setStateProperty('languageCookie', {
        targetLanguage: 'de-CH'
      })
    }
  }

  static getDerivedStateFromProps (props, state) {
    hycon.debug(`${'LanguageSwitch'} getDerivedStateFromProps`, { props, state })
    if (!state) {
      hycon.debug(`${'LanguageSwitch'} getDerivedStateFromProps - only props have been updated`, { props, state })
    }
    return state
  }

  shouldComponentUpdate (nextProps, nextState) {
    // let thisRef = this;
    hycon.debug(`${this.constructor.name} shouldComponentUpdate`, { nextProps, props: this.props, nextState })
    return true
  }

  setCookie (targetLanguage) {
    CookieUtil.setCookie(CookieUtil.constants.names.LANGUAGE, JSON.stringify({ targetLanguage: targetLanguage }))
  }

  switchLanguage (myTargetLanguage) {
    let thisRef = this
    let targetLanguage = myTargetLanguage;
    return new Promise((resolve, reject) => {
      try {
        let i18n = thisRef.props.i18n
        hycon.debug(`${'LanguageSwitch'} switchLanguage`, { targetLanguage, i18n })
        i18n.changeLanguage(targetLanguage, () => {
          hycon.debug(`${'LanguageSwitch'} switchLanguage - done`, { targetLanguage, i18n })
          thisRef.setCookie(targetLanguage)
          thisRef.getFromCookie()

          // update moment so that it uses the new language as locale
          moment.locale(targetLanguage.toLowerCase())
          hycon.debug(`${this.constructor.name} switchLanguage - moment locale update`, {
            currentLocale: moment.locale(),
            locales: moment.locales()
          })

          resolve()
        })
      } catch (e) {
        hycon.error(e)
        reject(e.message)
      }
    })
  }

  render () {
    let thisRef = this
    return (
      <div className="LanguageSwitch">
        <URLLanguage {...this.props} onLangChange={() => {
          thisRef.setStateProperty('languageCookie', {
            targetLanguage: thisRef.props.i18n.language
          })
        }}/>
        <div className={'button-container'}>
          <button
            className={`de-CH ${
              thisRef.state.languageCookie && thisRef.state.languageCookie.targetLanguage === 'de-CH' ? 'selected' : null
            }`}
            onClick={() => {
              thisRef.switchLanguage('de-CH')
            }}
          >
            {thisRef.props.i18n.t('settings:label-de-CH')}
          </button>
          <span> | </span>
          <button
            className={`it-CH ${
              thisRef.state.languageCookie && thisRef.state.languageCookie.targetLanguage === 'it-CH' ? 'selected' : null
            }`}
            onClick={() => {
              thisRef.switchLanguage('it-CH')
            }}
          >
            {thisRef.props.i18n.t('settings:label-it-CH')}
          </button>
          <span> | </span>
          <button
            className={`fr-CH ${
              thisRef.state.languageCookie && thisRef.state.languageCookie.targetLanguage === 'fr-CH' ? 'selected' : null
            }`}
            onClick={() => {
              thisRef.switchLanguage('fr-CH')
            }}
          >
            {thisRef.props.i18n.t('settings:label-fr-CH')}
          </button>
        </div>
      </div>
    )
  }

  componentDidUpdate () {
    // let thisRef = this;
    hycon.debug(`${this.constructor.name} componentDidUpdate`, { props: this.props })
  }

  componentWillUnmount () {
    // let thisRef = this;
    hycon.debug(`${this.constructor.name} componentWillUnmount`, { props: this.props })
  }

  componentDidCatch (error, errorInfo) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} componentDidCatch`, { error, errorInfo })
    throw error
  }

  setStateProperty (propertyName, propertyValue) {
    let thisRef = this
    return new Promise((res) => {
      thisRef.setState((p) => {
        const obj = {}
        obj[propertyName] = propertyValue
        return Object.assign({}, p, obj)
      }, () => {
        hycon.debug(`${this.constructor.name} setStateProperty - newState`, { newState: thisRef.state })
        res()
      })
    })
  }
}

function URLLanguage(props){
  // const fnName = `URLLanguage`;
  useEffect(() => {
    // hycon.debug(`${fnName} URLLanguage - useEffect`, props)
    props.onLangChange()
    // eslint-disable-next-line
  }, [props.i18n.language])
  return null;
}

export default withTranslation(['settings'])(LanguageSwitch)
