import React, {
  useState, useEffect,
} from 'react';
import axios from 'axios';
import 'izitoast/dist/css/iziToast.css';
import { Box } from '@mui/system';
import { MenuItem, Stack } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import AddFavorite from '../../reusable/AddFavorite';
import HyperConsole from '../../hyper_console/hyper-console';
import { themes } from '../../mui/themes';

const { REACT_APP_GI_ENV } = process.env;
let hycon = null;
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
} else {
  // eslint-disable-next-line no-unused-vars
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
}
const LOCIZE_PANEL_NS = 'search_bar';

const getGooglePlace = async (placeId, jwt, env) => {
  const response = await axios(
    {
      method: 'get',
      url: `${env.API_GATEWAY_BASE}/google-places/details/json?place_id=${encodeURIComponent(placeId)}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return response;
};
export default function SearchOption(props) {
  const { t } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false });
  const {
    option, types, onOptionClick, sepContext,
  } = props;
  const flexStyle = { alignItems: 'center', justifyContent: 'center' };
  const [extraData, setExtraData] = useState(null);
  const getOptionsLabel = (myOption) => {
    const isGoogleLabel = myOption.chipLabel.startsWith('GOOGLE');
    if (isGoogleLabel && extraData) {
      const isAddress = (
        extraData.result.types.includes('street_address')
          || extraData.result.types.includes('route')
          || extraData.result.types.includes('town_square')
          || extraData.result.types.includes('street_number')
          || extraData.result.types.includes('locality')
          || extraData.result.types.includes('premise')
      );
      if (isAddress) {
        return `${extraData.result.formatted_address}`;
      }
      return `${extraData.result.name} (${extraData.result.formatted_address})`;
    }
    return myOption.label;
  };
  const renderOptionTitle = (myOption) => (
    <div style={{ display: 'flex', flex: 1 }}>
      {getOptionsLabel(myOption)}
    </div>
  );
  const renderChip = (myOption) => {
    /*
              * reference material ui palette
              * <resources>
                    <color name="primaryColor">#689f38</color>
                    <color name="primaryLightColor">#99d066</color>
                    <color name="primaryDarkColor">#387002</color>
                    <color name="secondaryColor">#0e72b5</color>
                    <color name="secondaryLightColor">#59a0e8</color>
                    <color name="secondaryDarkColor">#004785</color>
                    <color name="primaryTextColor">#000000</color>
                    <color name="secondaryTextColor">#ffffff</color>
                  </resources>
              * */
    const chipColors = {
      REGION_TOWN: '#689f38',
      REGION_PLZ: '#689f38',
      ADDRESS: '#99d066',
      PARCEL: '#0e72b5',
      GOOGLE: '#e0e0e0',
      GOOGLE_AUTOCOMPLETE: '#e0e0e0',
    };
    const bgColorHEX = chipColors[myOption.type];
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    return (
      <Stack direction="row" spacing={1}>
        {
                    (() => {
                      const isGoogleLabel = myOption.chipLabel.startsWith('GOOGLE');
                      if (isGoogleLabel) {
                        return (
                          <Chip
                            size="small"
                            variant="outlined"
                            label={(
                              <Box sx={{ color: 'blue' }}>
                                <span style={{ color: '#4285F4' }}>G</span>
                                <span style={{ color: '#DB4437' }}>o</span>
                                <span style={{ color: '#F4B400' }}>o</span>
                                <span style={{ color: '#4285F4' }}>g</span>
                                <span style={{ color: '#0F9D58' }}>l</span>
                                <span style={{ color: '#DB4437' }}>e</span>
                              </Box>
                                    )}
                            style={{ backgroundColor: '#ffffff' }}
                          />
                        );
                      }
                      return (
                        <Chip
                          size="small"
                          variant="outlined"
                          label={t(`${LOCIZE_PANEL_NS}:option-chip-${myOption.chipLabel}`)}
                          style={{
                            backgroundColor: bgColorHEX,
                            color: themes.geoimpact.palette.getContrastText(bgColorHEX),
                          }}
                        />
                      );
                    })()
                }
      </Stack>
    );
    /* eslint-enable jsx-a11y/click-events-have-key-events */
  };
  const renderFavoriteButton = (myOption) => {
    const isRegion = [types.REGION_PLZ.NAME, types.REGION_TOWN.NAME].includes(myOption.type);
    const isAddress = [types.ADDRESS.NAME].includes(myOption.type);
    if (!isAddress && !isRegion) return null;

    return (
      <AddFavorite
        type={isAddress ? 'address' : 'region'}
        result={{
          payload: myOption.row,
        }}
      />
    );
  };
  useEffect(() => {
    async function fetchExtraData() {
      if (
        [types.GOOGLE.NAME, types.GOOGLE_AUTOCOMPLETE.NAME].includes(option.type)
      ) {
        const placeId = option.row.place_id;
        const googlePlaceDetailResponse = await getGooglePlace(
          placeId,
          sepContext.user.jwt,
          sepContext.env,
        );
        setExtraData(googlePlaceDetailResponse?.data || null);
      }
    }
    fetchExtraData();
  }, [option]);
  // eslint-disable-next-line max-len
  /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
  return (
    <div>
      <MenuItem>
        <div style={{
          display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap',
        }}
        >
          <div
            style={{
              display: 'flex', flex: 1, justifyContent: 'start', alignItems: 'center',
            }}
            onClick={(event) => {
              onOptionClick(event, { type: 'title', option: { ...option, label: getOptionsLabel(option) } });
            }}
          >
            {renderOptionTitle(option)}
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'end', marginLeft: 'auto' }}
          >
            <div style={{ display: 'flex', ...flexStyle, paddingRight: '4px' }}>
              {renderFavoriteButton(option)}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {renderChip(option)}
            </div>
          </div>
        </div>
      </MenuItem>
    </div>
  );
}
