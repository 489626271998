import React, { Component } from 'react';
 import HyperConsole from "../hyper_console/hyper-console";
import "./GenericError.css";
import { withTranslation } from 'react-i18next'
let hycon = new HyperConsole({isEnabled: false, name:__filename}).myConsole;

class GenericError extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        // let thisRef = this;
        hycon.debug(`${this.constructor.name} constructor`, {props: this.props});
    }

    componentDidMount() {
        // let thisRef = this;
        hycon.debug(`${this.constructor.name} componentDidMount`, {props: this.props});
    }


    static getDerivedStateFromProps(props, state) {
        hycon.debug(`${"GenericError"} getDerivedStateFromProps`, {props, state});
        if(!state){
            hycon.debug(`${"GenericError"} getDerivedStateFromProps - only props have been updated`, {props, state});
        }
        return state;
    }

    shouldComponentUpdate(nextProps, nextState) {
        // let thisRef = this;
        hycon.debug(`${this.constructor.name} shouldComponentUpdate`, {nextProps, props: this.props, nextState});
        return true;
    }

    render() {
        let thisRef = this;
        return (
            <div className="GenericError">
                {thisRef.props.component}
            </div>
        );
    }

    componentDidUpdate() {
        // let thisRef = this;
        hycon.debug(`${this.constructor.name} componentDidUpdate`, {props: this.props});
    }

    componentWillUnmount() {
        // let thisRef = this;
        hycon.debug(`${this.constructor.name} componentWillUnmount`, {props: this.props});
    }
}

export default withTranslation()(GenericError);

