import React, {Component, useContext, useEffect, useState} from 'react'
import HyperConsole from '../hyper_console/hyper-console'

import {
  BrowserRouter,
  Route,
  Redirect
} from 'react-router-dom'

import './Router.css'

import Home from '../home/Home'
import Feedback from '../info/feedback/Feedback'
// import * as changelog from "./../../external/changelog";
// import * as packageJson from "../../external/package-lite";
import UserManagement from '../user_management/UserManagement'
import WebPush from '../admin/webpush/WebPush'
// import Settings from '../settings/Settings'
import { withTranslation } from 'react-i18next'
import Favorites from '../favorites/Favorites'
import Events from '../events/Events'
import SepMapRoute from '../sep_map/component_route/SepMapRoute'
import SEPContext from '../../contexts/sep-context/SEPContext';
import Remote from '../sep_map/component_remote/Remote'
import FavoritesConfirmation from '../favorites/confirmation/Confirmation';
import { PanelConfig } from '../admin/panels/PanelConfig'
import {CircularProgress} from "@mui/material";
import {UserUtil} from "../user_management/util/user-util";

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}
/*
* Checks if a user is logged in with a valid jwt token. Depens on env for the base path of the api.
* */
export function ProtectedRoute(props) {
  const context = useContext(SEPContext);
  // check if the user authenticated
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const logoUrl = 'https://geoimpactstorage.blob.core.windows.net/public/logo/sep/Logo-SEP-web.png';
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const update = async () => {
      const isLoggedIn = await UserUtil.checkLoginStatus(context.SEPContext.user.jwt, context.SEPContext.env);
      const hasMandantInfo = context.SEPContext?.user?.mandant !== undefined;
      if(isLoggedIn === false){
        alert("loggin in with invalid token " + context.SEPContext?.user?.jwt)
      }
      setIsAuthenticated(isLoggedIn && hasMandantInfo);
      setIsLoading(false);
    }
    if(
        context.SEPContext?.user?.jwt !== undefined
        && context.SEPContext?.env !== undefined
    ){
      update();
    } else {
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  }, [
      context.SEPContext?.user?.jwt,
      context.SEPContext?.user?.mandant
  ])

  if(isLoading){
    return (
        <div className="SuspenceLoader">
          <img className={"logo"} src={logoUrl} alt={"client logo"}/>
          <CircularProgress />
        </div>
    )
  } else if(isAuthenticated === true){
    return props.children;
  } else if(isAuthenticated === false){
    return props.redirectComponent;
  }
}
export function ProtectedComponent(...args){
  return ProtectedRoute(...args)
}


class Router extends Component {
  constructor (props) {
    super(props)
    hycon.debug(`${this.constructor.name} constructor`, { props: this.props })
  }

  componentWillMount () {
    hycon.debug(`${this.constructor.name} componentWillMount`, { props: this.props })
  }

  componentDidMount () {
    hycon.debug(`${this.constructor.name} componentDidMount`, { props: this.props })
  }

  componentWillReceiveProps () {
    hycon.debug(`${this.constructor.name} componentWillReceiveProps`, { props: this.props })
  }

  shouldComponentUpdate (nextProps, nextState) {
    hycon.debug(`${this.constructor.name} shouldComponentUpdate`, { nextProps, props: this.props, nextState })
    return true
  }

  componentWillUpdate () {
    hycon.debug(`${this.constructor.name} componentWillUpdate`, { props: this.props })
  }

  render () {

    return (
      <div className="Router">
        <BrowserRouter className="router">
          <div className="routes">
            <Route exact path="/index.html" render={props => (
              <Home {...props} {...this.props}/>
            )}/>
            <Route exact path="/" render={props => (
              <Home {...props} {...this.props}/>
            )}/>
            <Route exact={false} strict={false} path="/user/:page/*" render={(props) => (
                <SEPContext.Consumer>
                  {(context) => {
                    return (
                        <UserManagement
                            {...props}
                            {...this.props}
                        />
                    )
                  }}
                </SEPContext.Consumer>
            )}/>
            <Route exact={false} path="/map/" render={props => (
              <SEPContext.Consumer>
                {(context) => (
                  <React.Fragment>
                    <SepMapRoute {...props} {...this.props} {...context}/>
                  </React.Fragment>
                )}
              </SEPContext.Consumer>
            )}/>
            <Route exact={false} path="/open/map/" render={props => (
              <SEPContext.Consumer>
                {(context) => (
                  <React.Fragment>
                    <SepMapRoute {...props} {...this.props} {...context}/>
                  </React.Fragment>
                )}
              </SEPContext.Consumer>
            )}/>
            <Route exact={false} path="/open/remote/" render={props => (
              <SEPContext.Consumer>
                {(context) => (
                  <React.Fragment>
                    <Remote {...props} {...this.props} {...context}/>
                  </React.Fragment>
                )}
              </SEPContext.Consumer>
            )}/>
            <Route exact={true} path="/favorites/" render={props => (
              <SEPContext.Consumer>
                {(context) => {
                  // protect this route from unauthenticated access
                  hycon.debug(`${this.constructor.name} rendering route: /favorites/`, { context, props: {...props, ...this.props} })
                  if(context.SEPContext.user.jwt === null){
                    let continuePath = `${props.history.location.pathname}${props.history.location.search}`;
                    hycon.debug(`${this.constructor.name} authenticationRedirect - continuePath`, { continuePath, location: props.history.location })
                    return <Redirect to={`/user/signin/?continuePath=${encodeURIComponent(continuePath)}`} />
                  } else {
                    return (
                      <Favorites {...props} {...this.props} {...context}/>
                    )
                  }
                }}
              </SEPContext.Consumer>
            )}/>
            <Route exact={false} path="/favorites/confirmation/" render={props => (
              <FavoritesConfirmation {...props} {...this.props} />
            )}/>
            <Route exact={false} path="/events/" render={props => (
              <SEPContext.Consumer>
                {(context) => {
                  // protect this route from unauthenticated access
                  hycon.debug(`${this.constructor.name} rendering route: /events/`, { context, props: {...props, ...this.props} })
                  if(context.SEPContext.user.jwt === null){
                    let continuePath = `${props.history.location.pathname}${props.history.location.search}`;
                    hycon.debug(`${this.constructor.name} authenticationRedirect - continuePath`, { continuePath, location: props.history.location })
                    return <Redirect to={`/user/signin/?continuePath=${encodeURIComponent(continuePath)}`} />
                  } else {
                    return (
                      <Events {...props} {...this.props} {...context}/>
                    )
                  }
                }}
              </SEPContext.Consumer>
            )}/>
            <Route exact path="/feedback/" render={props => (
              <Feedback
                {...props}
                {...this.props}
              />
            )}/>
            <Route exact path="/admin/webpush/" render={props => (
              <WebPush
                {...props}
                {...this.props}
              />
            )}/>
            <Route exact path="/admin/panels/" render={props => (
              <PanelConfig
                {...props}
                {...this.props}
              />
            )}/>
          </div>
        </BrowserRouter>
      </div>
    )
  }

  componentDidUpdate () {
    hycon.debug(`${this.constructor.name} componentDidUpdate`, { props: this.props })
  }

  componentWillUnmount () {
    hycon.debug(`${this.constructor.name} componentWillUnmount`, { props: this.props })
  }

  componentDidCatch (error, errorInfo) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} componentDidCatch`, { error, errorInfo })
    throw error
  }
}

export default withTranslation(['various'])(Router)
