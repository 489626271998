import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { point as tPoint, buffer } from '@turf/turf';
import { geoJSON as leafletGeoJSON } from 'leaflet';
import {
  Checkbox, Dimmer, Dropdown, Loader,
} from 'semantic-ui-react';
import { useFavoriteCtx } from '../../FavoriteProvider';
import { LOCIZE_PANEL_NS, reducerActions, REACT_APP_GI_ENV } from '../../constants';
import HyperConsole from '../../../hyper_console/hyper-console';

import './FavoriteItem.css';
import FavoriteUtils from '../../services/FavoriteUtils';
import FavoriteApi from '../../services/FavoriteApi';
import SEPContext from '../../../../contexts/sep-context/SEPContext';

let hycon = null;
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
}

const getTargetFavorites = (ctx) => {
  const selectedFavorites = (ctx.favorites || []).filter((favorite) => (ctx.selection || []).includes(favorite.id));
  const modals = ctx.modals || [];
  const modal = modals[modals.length - 1];
  let clickedFavorites = [];
  if (modal) {
    clickedFavorites = ctx.favorites.filter((f) => modal.favoriteIdArray.includes(f.id));
  }
  if (clickedFavorites.length > 0) {
    return clickedFavorites;
  }
  return selectedFavorites;
};

export default function FavoriteItem(props) {
  const { favorite, history } = props;
  const hookName = 'FavoriteItem';
  const favoriteContext = useFavoriteCtx();
  const { t } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false });
  const targetFavorites = getTargetFavorites(favoriteContext);
  const { user: { jwt }, env } = useContext(SEPContext).SEPContext;

  const getMapLink = (fav) => {
    try {
      const points = [];
      const point = {
        lat: fav.lat,
        lng: fav.long,
      };
      points.push(point);

      const selectionPoint = tPoint([point.lng, point.lat]);
      const bufferedSelectionPoint = buffer(selectionPoint, 0.200, { units: 'kilometers' });
      const geoJSON = leafletGeoJSON(bufferedSelectionPoint);

      const state = {
        mapState: {
          selectedBaseLayer: {
            name: 'MAP_SWISSTOPO_GREY_URL',
          },
          selection: {
            points,
          },
          bounds: {
            _southWest: geoJSON.getBounds()._southWest,
            _northEast: geoJSON.getBounds()._northEast,
          },
        },
      };
      return `/map/?state=${JSON.stringify(state)}`;
    } catch (e) {
      hycon.warn(`${hookName} - getMapLink - could not generate the link`, { favorite, e });
      throw new Error(`Can't generate link. Reason: ${e.message}`);
    }
  };
  const getFavoriteInfo = () => {
    const onClick = (fav) => {
      hycon.debug(`${hookName} onClick`, { fav });
    };
    if (favorite.favoriteType === favoriteContext.favoriteTypes.ADDRESS) {
      return (
        <div className="info" onClick={onClick}>
          {`${favorite.street} ${favorite.houseNumber}, ${favorite.swissZipCode} ${favorite.town}`}
          {' '}
          (
          {t(`events:event-type-${favorite.favoriteType.toLowerCase()}`)}
          )
        </div>
      );
    }
    if (favorite.favoriteType === favoriteContext.favoriteTypes.REGION) {
      return (
        <div className="info" onClick={onClick}>
          {`${favorite.name}`}
          {' '}
          (
          {t(`events:event-type-${favorite.favoriteType.toLowerCase()}`)}
          )
        </div>
      );
    }
  };
  const getMapLinkComponent = () => {
    try {
      const link = getMapLink(favorite);
      return (
        <i
          className="fas fa-map-marker-alt"
          onClick={() => {
            history.push(link);
          }}
        />
      );
    } catch (e) {
      return (
        <i
          className="fas fa-map-marker-alt disabled"
        />
      );
    }
  };
  const onGroupDropdownChange = (event, data) => {
    hycon.debug(`${hookName} onChange - onGroupDropdownChange`, { event, data });
    const { value } = data;
    favoriteContext.dispatch({
      type: reducerActions.ADD_LOADING_FAVORITES,
      info: 'onGroupDropdownChange - add loadingFavorites',
      payload: {
        favoriteIdArray: [favorite.id],
      },
    });
    FavoriteApi.patchGenericFavorite(jwt, env, favorite, { group: value })
      .then((patchResponse) => {
        hycon.debug(`${hookName} onChange - onGroupDropdownChange - patchResponse`, { patchResponse });
        return favoriteContext.getFavorites();
      })
      .then(({ mergedFavorites, mergedGroups }) => {
        favoriteContext.dispatch({
          type: reducerActions.REMOVE_LOADING_FAVORITES,
          info: 'onGroupDropdownChange - remove loadingFavorites',
          payload: {
            favoriteIdArray: [favorite.id],
          },
        });
        favoriteContext.dispatch({
          type: reducerActions.REPLACE,
          info: 'onGroupDropdownChange',
          payload: {
            newState: {
              favorites: mergedFavorites,
              groups: mergedGroups,
            },
          },
        });
      })
      .catch((error) => {
        hycon.error(`${hookName} onChange - onGroupDropdownChange - patchResponse`, { error });
        return null;
      });
  };
  const onFavoriteDelete = () => {
    const newLoadingFavorites = [...favoriteContext.loadingFavorites, favorite.id];
    hycon.debug(`${hookName} onFavoriteDelete - delete`, {
      newLoadingFavorites,
      favoriteContext,
      favorite,
    });
    favoriteContext.dispatch({
      type: reducerActions.REPLACE,
      info: 'onFavoriteDelete - increment loadingFavorites',
      payload: {
        newState: {
          loadingFavorites: newLoadingFavorites,
        },
      },
    });
    return FavoriteApi.deleteGenericFavorite(jwt, env, favorite)
      .then((deleteFavoriteResponse) => {
        hycon.debug(`${hookName} onFavoriteDelete - delete - deleteFavoriteResponse`, {
          favoriteContext,
          deleteFavoriteResponse,
        });
        FavoriteUtils.updateFavoriteCounter();
        return favoriteContext.getFavorites();
      })
      .then(({ mergedFavorites, mergedGroups }) => {
        hycon.debug(`${hookName} onFavoriteDelete - new favorites`, { favoriteContext, mergedFavorites, mergedGroups });
        favoriteContext.dispatch({
          type: reducerActions.REPLACE,
          info: 'onFavoriteDelete - decrement loadingFavorites',
          payload: {
            newState: {
              favorites: mergedFavorites,
              groups: mergedGroups,
              selection: favoriteContext.selection.filter(
                (favoriteId) => favoriteId !== favorite.id,
              ),
              loadingFavorites: favoriteContext.loadingFavorites.filter(
                (favoriteId) => favoriteId !== favorite.id,
              ),
            },
          },
        });
      });
  };
  const isFavoriteSelected = (fav) => (favoriteContext.selection || []).includes(fav.id);
  const getActionButtons = () => {
    if (targetFavorites.length === 0) {
      return (
        <>
          <i
            className="fas fa-bell"
            onClick={() => {
              favoriteContext.dispatch({
                type: reducerActions.REPLACE,
                info: 'modal',
                payload: {
                  newState: {
                    modals: [
                      { type: 'notification', favoriteIdArray: [favorite.id] },
                    ],
                  },
                },
              });
            }}
          />
          <i
            className="fas fa-users"
            onClick={() => {
              favoriteContext.dispatch({
                type: reducerActions.REPLACE,
                info: 'modal',
                payload: {
                  newState: {
                    modals: [
                      { type: 'recipient', favoriteIdArray: [favorite.id] },
                    ],
                  },
                },
              });
            }}
          />
          <i
            className="fas fa-trash"
            onClick={async () => {
              const res = await onFavoriteDelete().then(() => {
                hycon.debug(`${hookName} onClick - onFavoriteDelete - done`, { props });
              }).catch(() => {
                if (!res) {
                  favoriteContext.dispatch({
                    type: reducerActions.REMOVE_LOADING_FAVORITES,
                    info: 'onGroupDropdownChange - error',
                    payload: {
                      ...favoriteContext,
                      favoriteIdArray: favoriteContext.favorites.map((f) => f.id),
                    },
                  });
                }
              });
            }}
          />
        </>
      );
    }
  };

  const getOptions = [...favoriteContext.groups].reduce((acc, curr) => {
    if (acc.includes(curr)) {
      return acc;
    }
    acc.push(curr);
    return acc;
  }, []).map(
    (group) => ({ key: group, text: group, value: group }),
  );

  return (
    <div
      className={`${hookName} ${isFavoriteSelected(favorite) ? 'selected' : ''} ${favorite.favoriteType.toLowerCase()}`}
    >
      <Dimmer active={favoriteContext.loadingFavorites.includes(favorite.id)}>
        <Loader size="small" />
      </Dimmer>
      <div className="container">
        <Checkbox
          checked={(favoriteContext.selection || []).includes(favorite.id)}
          onChange={(event, data) => {
            hycon.debug(`${hookName} onChange`, { event, data });
            // remove the favorite from the selection
            const newSelection = (
              favoriteContext.selection || []).filter((favoriteId) => favoriteId !== favorite.id);
            // add it to the selection
            if (data.checked) {
              newSelection.push(favorite.id);
            }
            favoriteContext.dispatch({
              type: reducerActions.REPLACE,
              info: 'checkbox',
              payload: {
                newState: {
                  selection: newSelection,
                },
              },
            });
          }}
        />
        {getFavoriteInfo()}
        <div className="group-dropdown" style={{ wordBreak: 'break-all' }}>
          <Dropdown
            options={getOptions}
            placeholder={`${t('favorites:select-target-group')}`}
            searchInput={{ autoComplete: 'favorite-group' }}
            search
            fluid
            selection
            allowAdditions
            value={favorite.group}
            onAddItem={(event, data) => {
              const { value } = data;
              hycon.debug(`${hookName} onAddItem`, { value, event, data });
            }}
            onChange={async (event, data) => {
              const res = await onGroupDropdownChange(event, data);
              if (!res) {
                favoriteContext.dispatch({
                  type: reducerActions.REMOVE_LOADING_FAVORITES,
                  info: 'onGroupDropdownChange - error',
                  payload: {
                    ...favoriteContext,
                    favoriteIdArray: favoriteContext.favorites.map((f) => f.id),
                  },
                });
              }
            }}
          />
        </div>
        <div className="actions">
          {getMapLinkComponent()}
          {getActionButtons()}
        </div>
      </div>
    </div>
  );
}
