import axios from 'axios'
import HyperConsole from '../../../components/hyper_console/hyper-console'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

const initAuthInterceptor = (ctx) => {
  hycon.info('authentication-interceptor: initAuthInterceptor')
  // Add a request interceptor
  let interceptor = axios.interceptors.response.use(function (response) {
    hycon.info('authentication-interceptor: authorized')
    return response
  }, function (error) {
    hycon.debug('authentication-interceptor: rejection', {error, ctx});
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      let pathName = window.location.pathname
      hycon.debug('authentication-interceptor: rejection (with error 401)', {error, ctx, pathName});
      if(
        pathName.startsWith("/user/") === false
        &&  pathName.startsWith("/admin/panels/") === false
        &&  pathName.startsWith("/favorites/confirmation") === false
      )
      {
        hycon.warn('authentication-interceptor: unauthorized (redirect to signing)' + error.response.status, {pathName});
        let continuePath = `${window.location.pathname}${window.location.search}`;
        hycon.debug(`${ctx.constructor.name} authentication-interceptor: continuePath`, { continuePath })
        window.history.pushState({}, "", `/user/signin/?continuePath=${encodeURIComponent(continuePath)}`)
        window.history.go();
      } else {
        hycon.warn('authentication-interceptor: unauthorized', {error});
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error)
    }
  })
  return interceptor
}

const removeAuthInterceptor = (interceptor) => {
  hycon.info('authentication-interceptor: removeAuthInterceptor')
  // Remove a request interceptor
  try {
    axios.interceptors.response.eject(interceptor)
  } catch (e) {
    hycon.error(e)
  }
}

export {
  initAuthInterceptor,
  removeAuthInterceptor
}
