import React, { useState, useEffect, useCallback, useContext } from 'react'
import HyperConsole from '../../hyper_console/hyper-console'
import * as L from 'leaflet'
import axios from 'axios'
import iziToast from 'izitoast'
import 'izitoast/dist/css/iziToast.css'
import { useTranslation } from 'react-i18next'
import { GlobalContext } from '../../../index'
import "./Selection.css";
import _ from "lodash"
import { themes } from '../../mui/themes.js'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
const LOCIZE_PANEL_NS = 'notification_map'
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}
function getIzitoastColor (props){
  const color = props.appStateServer.clients[0] === "sep" ? themes.geoimpact.palette.primary.main : themes.praedictio.palette.primary.main;
  return color;
}

export default function Selection (props) {
  const hookName = 'Selection'
  const jwt = props.reduxState.user.jwt
  const [buildingMarkerGroup, setBuildingMarkerGroup] = useState(null)
  const [buildingGeometryGroup, setBuildingGeometryGroup] = useState(null)
  const [selectionPromises, setSelectionPromises] = useState([])
  const [selection, setSelection] = useState([])
  const [displayedData, setDisplayedData] = useState([])
  const [mapClickEvents, setMapClickEvents] = useState([])
  const [map, setMap] = useState(null);
  const { t, /*i18n*/ } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false })
  const globalContext = useContext(GlobalContext)

  const transformClickEventToGeoJSON = (event) => {
    let coordsArray = [event.latlng.lng, event.latlng.lat]
    let geoJSON = {
      'type': 'FeatureCollection',
      'features': [
        {
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'Point',
            'coordinates': coordsArray
          }
        }
      ]
    }
    return geoJSON
  }
  const getBuildingByPoint = (geoJSON) => {
    let x = null
    let y = null
    let srid = 4326 //WGS84 (SRID 4326)
    let jwt = props.reduxState.user.jwt
    try {
      x = geoJSON.features[0].geometry.coordinates[0]
      y = geoJSON.features[0].geometry.coordinates[1]
    } catch (e) {
      hycon.warn(e)
      return Promise.reject('Could not read point coordinates from geoJSON')
    }

    let endpoint = `${props.reduxState.env.API_GATEWAY_BASE}/api/building-by-point?x=${x}&y=${y}&srid=${srid}`
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    let request = axios({
      method: 'get',
      url: endpoint,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${jwt}`,
      },
      cancelToken: source.token
    })
    .then((response) => {
      // handle success
      // inject the click coordinates of the request
      if (response.data.length > 0) {
        response.data.forEach((building, index) => {
          response.data[index].coordinates = { latitude: y, longitude: x }
        })
        hycon.debug(`${hookName} getBuildingByPoint`, { response })
        try {
          response.buildingId = response.data[0].id
        } catch (e) {
          response.buildingId = null
        }
        response.type = 'info'
        return response
      } else {
        return null
      }
    }).catch(e => {
      hycon.error(`${hookName} getBuildingByPoint`, { e })
      return null
    })
    return { request, cancel: { CancelToken, source, request } }
  }
  const getBuildingGeometry = (buildingInfoResponse) => {
    hycon.debug(`${hookName} getBuildingGeometry`, { buildingInfoResponse })
    try {
      let endpoint = `${props.reduxState.env.API_GATEWAY_BASE}/api/building-geometries?id=[in]${[buildingInfoResponse.data[0].id].join(',')}`
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      let request = axios({
        method: 'get',
        url: endpoint,
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        cancelToken: source.token
      }).then(response => {
        try {
          response.buildingId = buildingInfoResponse.data[0].id
        } catch (e) {
          response.buildingId = null
        }
        response.type = 'geometry'
        return response
      })
      .catch(e => {
        hycon.error(`${hookName} getBuildingGeometry`, { e })
      })
      return { request, cancel: { CancelToken, source, request } }
    } catch (e) {
      return { request: Promise.resolve(null), cancel: null }
    }
  }
  const getBuildingGeometryData = async (clickEvent) => {
    let buildingInfoResponse = await getBuildingInfoData(clickEvent)
    let { request: buildingGeometryRequest, /*cancel: buildingGeometryCancel*/ } = getBuildingGeometry(buildingInfoResponse)
    return buildingGeometryRequest
  }
  const getBuildingInfoData = (clickEvent) => {
    let clickGeoJSON = transformClickEventToGeoJSON(clickEvent)
    let { request: buildingInfoRequest, /*cancel: buildingInfoCancel*/ } = getBuildingByPoint(clickGeoJSON)
    return buildingInfoRequest
  }
  const getSelectionData = async (clickEvent, eventIndex) => {
    try {
      let buildingInfo = await getBuildingInfoData(clickEvent)
      buildingInfo.eventIndex = eventIndex
      let buildingGeometry = await getBuildingGeometryData(clickEvent, buildingInfo)
      buildingGeometry.eventIndex = eventIndex
      hycon.debug(`${hookName} useEffect - mapClickEvents - updateSelection`, {
        mapClickEvents,
        buildingInfo,
        buildingGeometry
      })
      return { buildingInfo, buildingGeometry, eventIndex }
    } catch (e) {
      hycon.warn(`${hookName} useEffect - mapClickEvents - updateSelection - warn`, { mapClickEvents })
      return { buildingInfo: null, buildingGeometry: null, eventIndex }
    }
  }

  // temp fix for https://github.com/Leaflet/Leaflet/issues/7255
  const debouncedOnMapClick = _.debounce((event) => {
    hycon.debug(`${hookName} useEffect - onMapClick - event`, { event, mapClickEvents })
    let newClicks = mapClickEvents.concat([event])
    hycon.debug(`${hookName} useEffect - onMapClick - event - sorted by timestamp`, { newClicks })
    iziToast.show({
      timeout: 3000,
      theme: 'dark',
      class: 'loading',
      color: getIzitoastColor(props),
      zindex: 5,
      message: `${t(`${LOCIZE_PANEL_NS}:building-selection-loading`)}`,
      position: 'topCenter',
      drag: false,
    })
    const buildingSelectionEvent = new CustomEvent('kibana-event', { detail: {
        type: "building-selection",
        eventInfo: {
          info: "Building has been selected."
        }
      }
    });
    window.dispatchEvent(buildingSelectionEvent);
    setMapClickEvents(newClicks)
  }, 150)
  const onMapClick = useCallback(debouncedOnMapClick, [mapClickEvents, t])

  const onMapClickSimulation = useCallback((customEvent) => {
    let points = JSON.parse(customEvent.detail)
    let simulatedEvents = []
    points.forEach(point => {
      let simulatedEvent = {
        latlng: { lat: point.lat, lng: point.lng }
      }
      simulatedEvents.push(simulatedEvent)
    })
    /*hycon.debug(`${hookName} useEffect - deepLink - restore - onMapClickSimulation`, {
      globalContext,
      customEvent,
      simulatedEvents
    })*/
    setMapClickEvents(simulatedEvents)
  }, [])
  const removeSelection = () => {
    hycon.debug(`${hookName} useEffect - onMapClick - update - delete - selection`, { mapClickEvents })
    setMapClickEvents([])
  }

  useEffect(() => {
    // hycon.debug(`${hookName} useEffect - mount - deepLink - restore`, { mapClickEvents })
    window.addEventListener('sep_deeplink:mapclick', onMapClickSimulation)
    return () => {
      // clanup
      // hycon.debug(`${hookName} useEffect - unmount - deepLink - restore (cleanup)`, { mapClickEvents })
      window.removeEventListener('sep_deeplink:mapclick', onMapClickSimulation)
    }
  }, [onMapClickSimulation])

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - saving map reference`)
    let myMap = props.reduxState.map && props.reduxState.map.map
    if(myMap){
      setMap(myMap)
    }
    // eslint-disable-next-line
  }, [props.reduxState.map.map])

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - displayedData - geometry`, { displayedData })
    const geojsonColor = getComputedStyle(document.documentElement).getPropertyValue('--gi_color_dark_primary_color');
    let geometryStyle = {
      'color': `${geojsonColor}`,
      'weight': 5,
      'opacity': 0.62
    }
    if (
      props.reduxState.map.map
    ) {
      if (buildingGeometryGroup) {
        props.reduxState.map.map.removeLayer(buildingGeometryGroup)
      }
      let layers = []
      Object.keys(displayedData).forEach(key => {
        let geometryData = displayedData[key].geometry.data
        let geojsonFeature = JSON.parse(geometryData.geometries)
        let geojson = L.geoJSON(geojsonFeature, {
          style: geometryStyle
        })
        layers.push(geojson)
      })
      let newBuildingGeometryGroup = L.layerGroup(layers)
      setBuildingGeometryGroup(newBuildingGeometryGroup)
      newBuildingGeometryGroup.addTo(props.reduxState.map.map)
    }
    // eslint-disable-next-line
  }, [displayedData])

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - displayedData - pin`, { displayedData })
    if (
      props.reduxState &&
      props.reduxState.map &&
      props.reduxState.map.map
    ) {
      if (buildingMarkerGroup) {
        props.reduxState.map.map.removeLayer(buildingMarkerGroup)
      }
      let data = []
      Object.keys(displayedData).forEach((key) => {
        data.push(
          displayedData[key]
        )
      })

      let markers = []

      // sort the data by click order
      data.sort((a, b) => {
        if (a.info.eventIndex > b.info.eventIndex) {
          return 1
        } else if (a.info.eventIndex < b.info.eventIndex) {
          return -1
        } else {
          return 0
        }
      })

      data.forEach((dataElement, index) => {
        let coordinates = dataElement.info.data[0].coordinates
        let lat = coordinates.latitude
        let lng = coordinates.longitude

        let pinNumber = index + 1
        let svgXPosition = 0
        if (pinNumber >= 1 && pinNumber <= 9) {
          svgXPosition = 24
        }
        if (pinNumber >= 10 && pinNumber <= 99) {
          svgXPosition = 15
        }
        if (pinNumber >= 100 && pinNumber <= 999) {
          svgXPosition = 5
        }

        const color = props.appStateServer.clients[0] === "sep" ? themes.geoimpact.palette.primary.main : themes.praedictio.palette.primary.main;
        const svgString = `<svg width="70" height="100" xmlns="http://www.w3.org/2000/svg">
                 <metadata id="metadata-building-marker">image/svg+xml</metadata>
                 <g>
                  <title>background</title>
                  <rect fill="none" id="canvas_background" height="102" width="72" y="-1" x="-1"/>
                 </g>
                 <g>
                  <title>Layer 1</title>
                  <path fill-opacity="0.8" stroke="null" fill="${color}" id="path4127" d="m34.96515,99.35066c-0.21626,-0.52746 -4.70929,-4.93912 -7.92679,-8.94788c-19.06234,-25.41221 -40.31534,-49.02389 -15.49846,-77.7181c10.98839,-10.65954 24.53781,-11.26764 38.46711,-6.29901c39.2213,22.788 10.8246,59.96254 -7.821,84.40073l-7.22086,8.56426l0.00001,0l-0.00001,0z"/>
                  <text xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="35" id="svg_1" y="52.21932" x="${svgXPosition}" stroke-opacity="null" stroke-width="0" stroke="null" fill="#ffffff">${pinNumber}</text>
                 </g>
                </svg>`
        const svg = `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`
        const iconUrl = svg
        hycon.debug(`${hookName} updateLayer`, {
          iconUrl
        })
        const greenIcon = L.icon({
          iconUrl,
          iconSize: [36, 52], // size of the icon
          iconAnchor: [18, 52], // point of the icon which will correspond to marker's location
          className: "sep-building-selection-marker"
        })
        let marker = L.marker([lat, lng], { icon: greenIcon })
        markers.push(marker)
      })

      let newBuildingMarkerGroup = L.layerGroup(markers)
      setBuildingMarkerGroup(newBuildingMarkerGroup)
      newBuildingMarkerGroup.addTo(props.reduxState.map.map)

      // push the selection to redux
      let reduxSelection = []
      data.forEach((dataElement, index) => {
        let coordinates = dataElement.info.data[0].coordinates
        let geoJSON = {
          'type': 'FeatureCollection',
          'features': [
            {
              'type': 'Feature',
              'properties': {},
              'geometry': {
                'type': 'Point',
                'coordinates': [coordinates.latitude, coordinates.longitude]
              }
            }
          ]
        }
        reduxSelection.push({
          buildings: dataElement.info.data,
          geoJSON
        })
      })

      let oldSelectedBuildings = props.reduxState.selectedBuildings
      try {
        let toasts = document.querySelectorAll('.loading');
        toasts.forEach((toast) => {
          iziToast.hide({}, toast);
        })
      } catch (e) {
        hycon.warn('iziToast error', e)
      }
      if (mapClickEvents.length > 0 && reduxSelection.length > oldSelectedBuildings.length) {
        iziToast.show({
          timeout: 3000,
          theme: 'dark',
          class: 'loading',
          color: getIzitoastColor(props),
          zindex: 5,
          message: `${t(`${LOCIZE_PANEL_NS}:message-building-selection-add`)}`,
          position: 'topCenter',
          drag: false,
        })
      } else if (mapClickEvents.length > 0 && reduxSelection.length < oldSelectedBuildings.length) {
        iziToast.show({
          timeout: 3000,
          theme: 'dark',
          class: 'loading',
          color: getIzitoastColor(props),
          zindex: 5,
          message: `${t(`${LOCIZE_PANEL_NS}:message-building-selection-remove`)}`,
          position: 'topCenter',
          drag: false,
        })
      } else if (
        mapClickEvents.length === Object.keys(displayedData).length
        && reduxSelection.length === oldSelectedBuildings.length
        && mapClickEvents.length > 0
      ) {
        iziToast.show({
          timeout: 3000,
          theme: 'dark',
          class: 'loading',
          color: getIzitoastColor(props),
          zindex: 5,
          message: `${t(`${LOCIZE_PANEL_NS}:message-building-selection-nothing-selected`)}`,
          position: 'topCenter',
          drag: false,
        })
      }

      props.reduxDispatch('updateSelectedBuildings', reduxSelection)
    }
    // eslint-disable-next-line
  }, [displayedData])

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - selectionPromises - update`, { selectionPromises })
    const getAllData = async () => {
      let data = await Promise.all(selectionPromises).then(values => values)
      hycon.debug(`${hookName} useEffect - selectionPromises - update - resolved`, { selectionPromises, data })
      setSelection(data)
    }
    getAllData()
    return () => {
      // cleanup
    }
  }, [selectionPromises])

  useEffect(() => {
    // hycon.debug(`${hookName} useEffect - selection - update`, { selection, globalContext })
    let selectionMix = []
    selection.forEach((selectionElement) => {
      selectionMix.push(selectionElement.buildingInfo)
      selectionMix.push(selectionElement.buildingGeometry)
    })

    hycon.debug(`${hookName} useEffect - selection - update - mix`, { selectionMix })

    // filter out invalid data
    let validSelection = selectionMix.filter((selectionElement) => {
      return selectionElement !== null && selectionElement.buildingInfo !== null && selectionElement.buildingGeometry !== null
    })
    // hycon.debug(`${hookName} useEffect - selection - valid`, { selection, validSelection })

    // filter out the deselected buildings
    let infos = []
    let geometries = []
    validSelection.forEach((selectionElement) => {
      if (
        selectionElement.type === 'info'
      ) {
        infos.push(selectionElement)
      } else if (
        selectionElement.type === 'geometry'
      ) {
        geometries.push(selectionElement)
      }
    })
    hycon.debug(`${hookName} useEffect - selection - split`, { geometries, infos })
    // map the occurrence count
    let mappedGeometries = geometries.map((geometry) => {
      return {
        ...geometry,
        count: geometries.filter(geometryTemp => geometryTemp.buildingId === geometry.buildingId).length
      }
    })
    let mappedInfos = infos.map((info) => {
      return {
        ...info,
        count: infos.filter(infoTemp => infoTemp.buildingId === info.buildingId).length
      }
    })

    hycon.debug(`${hookName} useEffect - selection - count`, { mappedGeometries, mappedInfos })

    let uniqueGeometries = []
    let uniqueInfos = []
    let geomMem = []
    let infoMem = []
    let displayedDataMap = {}
    mappedGeometries.forEach((geometry) => {
      hycon.debug(`${hookName} useEffect - selection - count - mapping (geometry)`, { geometry })
      if (geometry.count % 2 === 1 && geomMem.includes(geometry.buildingId) === false) {
        // count is odd, add it
        uniqueGeometries.push(geometry)
        geomMem.push(geometry.buildingId)
        displayedDataMap[geometry.buildingId] = displayedDataMap[geometry.buildingId] || {}
        displayedDataMap[geometry.buildingId].geometry = geometry
      }
    })
    mappedInfos.forEach((info) => {
      hycon.debug(`${hookName} useEffect - selection - count - mapping (info)`, { info })
      if (info.count % 2 === 1 && infoMem.includes(info.buildingId) === false) {
        // count is odd, add it
        uniqueInfos.push(info)
        infoMem.push(info.buildingId)
        displayedDataMap[info.buildingId] = displayedDataMap[info.buildingId] || {}
        displayedDataMap[info.buildingId].info = info
      }
    })

    hycon.debug(`${hookName} useEffect - selection - final`, { uniqueInfos, uniqueGeometries, displayedDataMap })
    setDisplayedData(displayedDataMap)

    return () => {
      // cleanup
    }
  }, [selection])

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - mapClickEvents - event / restore`, { mapClickEvents })
    if (mapClickEvents.length === 0) {
      setSelectionPromises([])
    } else {
      let allData = []
      mapClickEvents.forEach((event, index) => {
        let eventIndex = index + 1
        hycon.debug(`${hookName} useEffect - mapClickEvents - event`, { event })
        allData.push(getSelectionData(event, eventIndex))
      })
      hycon.debug(`${hookName} useEffect - mapClickEvents - updateSelection - promises`, { mapClickEvents, allData })
      setSelectionPromises(allData)
    }
    // eslint-disable-next-line
  }, [mapClickEvents])

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - onMapClick - update`, { map })
    if(map && globalContext.selection.isLocked === false){
      hycon.debug(`${hookName} useEffect - onMapClick - setting listener`, { map })
      map.on('click', onMapClick)
    }
    return () => {
      if(map){
        hycon.debug(`${hookName} useEffect - onMapClick - removing listener`, { map })
        map.off('click', onMapClick)
      }
    }
    // eslint-disable-next-line
  }, [map, onMapClick, globalContext.selection.isLocked])

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - setting up trash event handlers`, { mapClickEvents })
    window.addEventListener('sep_map-overlay_controls:trash-selection', removeSelection)
    return () => {
      // clanup
      hycon.debug(`${hookName} useEffect - removing trash event handlers (cleanup)`, { mapClickEvents })
      window.removeEventListener('sep_map-overlay_controls:trash-selection', removeSelection)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="Selection">

    </div>
  )
}
