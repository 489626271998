export class WixUtil {
    static getLangBaseUrl(lang) {
        const options = {
            baseUrl: `https://www.swissenergyplanning.ch`,
            languages: ["de-ch", "fr-ch", "it-ch"]
        };
        if (options.languages.includes(lang) === false) {
            throw new Error(`language not supported`);
        } else {
            switch (lang.toLowerCase()) {
                case "de-ch":
                    return `https://www.swissenergyplanning.ch`;
                case "fr-ch":
                    return `https://fr.swissenergyplanning.ch`;
                case "it-ch":
                    return `https://it.swissenergyplanning.ch`;
                default:
                    throw new Error("language not supported");
            }
        }
    }
}