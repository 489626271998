import axios from "axios";
import HyperConsole from '../components/hyper_console/hyper-console'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV;
let hycon = null;
if(REACT_APP_GI_ENV === "development"){
  hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
} else {
  hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
}


const initLanguageHeaderInterceptor = (ctx) => {
  hycon.info("language-request-interceptor: initLanguageHeaderInterceptor");
  // Add a request interceptor
  let interceptor = axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    // hycon.info("language-request-interceptor: intercept", config);

    // inject the language propery
    try{
      config.headers["Accept-Language"] = ctx.props.i18n.language;
    }catch (e) {
      // hycon.warn("language-request-interceptor: intercept error", {config, ctx});
    }

    // hycon.info("language-request-interceptor: intercept (modded header)", config);

    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

  return interceptor;
}

const removeLanguageHeaderInterceptor = (interceptor) => {
  hycon.info("language-request-interceptor: initLanguageHeaderInterceptor");
  // Add a request interceptor
  try{
    axios.interceptors.request.eject(interceptor);
  }catch (e) {
    hycon.error(e);
  }
}

export {
  initLanguageHeaderInterceptor,
  removeLanguageHeaderInterceptor
}