import HyperConsole from '../../hyper_console/hyper-console'
let hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole

function setCookie (cname, cvalue, exdays, path) {
  var d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  var expires = 'expires=' + d.toUTCString()

  let domain = window.location.hostname
  if (domain === 'localhost') {
    // omit the domain otherwise microsoft browsers wont set the damn cookie
    window.document.cookie = cname + '=' + cvalue + ';' + expires + ';path=' + path
  } else {
    window.document.cookie = cname + '=' + cvalue + ';' + expires + ';path=' + path + ';domain=' + window.location.hostname
  }
}
function getCookie (cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(window.document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return null
}

function deleteCookie (name) {
  let domain = window.location.hostname
  if (domain === 'localhost') {
    // omit the domain otherwise microsoft browsers wont set the damn cookie
    window.document.cookie = name + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
  } else {
    window.document.cookie = name + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`
  }
}

class CookieUtilClass {
  constructor () {
    this.constants = {
      COOKIE_ERROR: 'Could not set/read cookies',
      names: {
        USER: 'USER',
        PWA: 'PWA',
        COOKIE: 'COOKIE',
        TOUR: 'TOUR',
        LANGUAGE: "LANGUAGE"

      },
      settings: {
        COOKIE_EXPIRY_DAYS: 365
      }
    }
  }

  setCookie (name, valueString) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} setCookie`, { name, valueString })
    setCookie(name, valueString, thisRef.constants.settings.COOKIE_EXPIRY_DAYS, '/')
    let newCookie = getCookie(name)
    if (valueString !== newCookie) {
      console.error('The saved cookie does not match the content you originally wanted to save!', { valueString, newCookie })
      throw new Error('The saved cookie does not match the content you originally wanted to save!')
    }
    hycon.debug(`${thisRef.constructor.name} setCookie ok - (this is what has been stored ad new cookie)`, { newCookie })
  }

  getCookie (name) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} getCookie ${name}`, { name })
    let cookieString = null
    try {
      cookieString = getCookie(name)
      hycon.debug(`${thisRef.constructor.name} getCookie - got ${name} cookieString`, { cookieString })
    } catch (e) {
      hycon.error(`${thisRef.constructor.name} getCookie - got ${name} cookieString - error`, { cookieString, e })
    }
    return cookieString
  }

  deleteCookie (name) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} deleteCookie`, { name })
    deleteCookie(name)
  }

  deleteAllCookies(){
    let thisRef = this;
    hycon.debug(`${thisRef.constructor.name} deleteCookie`, { thisRef })
    for(let name in thisRef.constants.names){
      if(thisRef.constants.names.hasOwnProperty(name)){
        try {
          thisRef.deleteCookie(name);
        }catch (e) {
          console.warn(e);
        }
      }
    }
  }

  test () {
    let thisRef = this
    hycon.info(`${thisRef.constructor.name} test`, {})
  }
}

export const CookieUtil = new CookieUtilClass()
