import React, { Component, useState, useEffect, useRef } from 'react'
import HyperConsole from '../hyper_console/hyper-console'
import './HyperMenu.css'
import packageJson from '../../../package.json'

import { UserUtil } from '../user_management/util/user-util'
import { TokenUtil } from '../user_management/util/token-util'

import { Button } from 'semantic-ui-react'
import env from './../../env/env'
import { Translation } from 'react-i18next'
import LanguageSwitch from '../settings/language_switch/LanguageSwitch'
import axios from 'axios'
import SupportRequest from "../reusable/SupportRequest";

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

function MyFavorites (props) {
  const updateEeventName = 'favorites-update'
  const hookName = 'MyFavorites'
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const cancelTokensRef = useRef([])

  const cancelRequests = () => {
    hycon.debug(`${hookName} cancelRequests`, {
      cancelTokensRef
    })
    cancelTokensRef.current.forEach((cToken) => {
      try {
        hycon.warn(`${hookName} cancelRequests - cancelling`, { cToken })
        cToken.source.cancel('Operation canceled by the user.')
      } catch (e) {
        hycon.error(`${hookName} cancelRequests - error`, { cToken })
      }
    })
    cancelTokensRef.current = []
  }

  // axios
  const getFavoriteRegionsCount = async () => {
    let env = props.reduxState.env
    const jwt = props.reduxState.user.jwt
    let endpoint = `${env.API_GATEWAY_BASE}/api/favoriteregions-count`
    hycon.debug(`${hookName} getFavoriteRegions`)
    return await axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.error(`${hookName} getFavoriteRegionsCount`, { error })
      throw error
    })
  }

  const getFavoriteAddressesCount = async () => {
    let env = props.reduxState.env
    const jwt = props.reduxState.user.jwt
    let endpoint = `${env.API_GATEWAY_BASE}/api/favoriteaddresses-count`
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    let data = {}
    hycon.debug(`${hookName} addFavorites`, {})
    let request = axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data),
        cancelToken: source.token
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${hookName} getFavoriteAddresses`, { error })
    })
    cancelTokensRef.current.push({ request, source, CancelToken })
    return await request
  }

  /*
  Passing an empty array ensures that the effect is called only when the component is mounted
  and not on every subsequent prop change.
  */
  useEffect(() => {
    hycon.debug(`${hookName} useEffect - fetching favorites`, {})
    getFavorites()
    return () => {
      // clenaup
      cancelRequests()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onUpdate = async (event) => {
    hycon.debug(`${hookName} useEffect - onUpdate`, { event })
    await getFavorites()
  }

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - event listener setup`, {})
    document.body.addEventListener(updateEeventName, onUpdate)
    return () => {
      // clenaup
      document.body.removeEventListener(updateEeventName, onUpdate)
      cancelRequests()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // util
  async function getFavorites () {
    setIsLoading(true)
    let getFavoriteAddressesCountResponse = await getFavoriteAddressesCount()
    .catch((e) => {
      hycon.error(`${hookName} - getFavoriteAddressesCount - error`, e)
    })
    let getFavoriteRegionsCountResponse = await getFavoriteRegionsCount()
    .catch((e) => {
      hycon.error(`${hookName} - getFavoriteRegionsCount - error`, e)
    })
    setIsLoading(false)
    if (getFavoriteAddressesCountResponse) {
      let count = getFavoriteAddressesCountResponse.data.count + getFavoriteRegionsCountResponse.data.count
      setCount(count)
    }
  }

  const getCounter = () => {
    if (count > 0) {
      return `(${count})`
    } else return null
  }

  return (
    <div className={'my-favorites'}>
      <div
        className={'item'}
      >
        <Button
          loading={isLoading}
          onClick={props.onClick}
          className={'menu-button'}
          primary
          active={props.active}
        >
          {props.t('button-my-favorites')} {getCounter()}
        </Button>
      </div>
    </div>
  )
}

function MyEvents (props) {
  const updateEeventName = 'events-update'
  const favoritesUpdateEventName = 'favorites-update'
  const hookName = 'MyEvents'
  const [isLoading, setIsLoading] = useState(false)
  const [eventsCount, setEventsCount] = useState([])
  const cancelTokensRef = useRef([])

  const cancelRequests = () => {
    hycon.debug(`${hookName} cancelRequests`, {
      cancelTokensRef
    })
    cancelTokensRef.current.forEach((cToken) => {
      try {
        hycon.warn(`${hookName} cancelRequests - cancelling`, { cToken })
        cToken.source.cancel('Operation canceled by the user.')
      } catch (e) {
        hycon.error(`${hookName} cancelRequests - error`, { cToken })
      }
    })
    cancelTokensRef.current = []
  }

  useEffect(() => {
    fetchEvents()
    return () => {
      cancelRequests()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - event listener setup`, {})
    const onUpdate = (event) => {
      hycon.debug(`${hookName} useEffect - onUpdate`, { event })
      fetchEvents()
    }
    document.body.addEventListener(updateEeventName, onUpdate)
    return () => {
      // clenaup
      document.body.removeEventListener(updateEeventName, onUpdate)
      cancelRequests()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    hycon.debug(`${hookName} useEffect - event listener setup`, {})
    const onUpdate = (event) => {
      hycon.debug(`${hookName} useEffect - onUpdate`, { event })
      fetchEvents()
    }
    document.body.addEventListener(favoritesUpdateEventName, onUpdate)
    return () => {
      // clenaup
      document.body.removeEventListener(favoritesUpdateEventName, onUpdate)
      cancelRequests()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // axios

  const fetchEvents = async () => {
    setIsLoading(true)
    let addressResponse = await getAddressEventsCount()
    let regionResponse = await getRegionEventsCount()
    setIsLoading(false)
    if (regionResponse && addressResponse) {
      const totalCount = addressResponse.data.count + regionResponse.data.count
      setEventsCount(totalCount)
    }
  }

  const getAddressEventsCount = async () => {
    let env = props.reduxState.env
    const jwt = props.reduxState.user.jwt
    let endpoint = `${env.API_GATEWAY_BASE}/api/eventsforaddresses-count`
    /*let data = {}*/
    hycon.debug(`${hookName} getAddressEventsCount`, {})
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    let request = axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        cancelToken: source.token
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${hookName} getAddressEventsCount`, { error })
    })
    cancelTokensRef.current.push({ request, source, CancelToken })
    return await request
  }

  const getRegionEventsCount = async () => {
    let env = props.reduxState.env
    const jwt = props.reduxState.user.jwt
    let endpoint = `${env.API_GATEWAY_BASE}/api/eventsforregions-count`
    /*let data = {}*/
    hycon.debug(`${hookName} getRegionEventsCount`, {})
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    let request = axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        cancelToken: source.token
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${hookName} getRegionEventsCount`, { error })
    })
    cancelTokensRef.current.push({ request, source, CancelToken })
    return await request
  }

  const getCounter = () => {
    if (eventsCount > 0) {
      return `(${eventsCount})`
    } else return null
  }

  return (
    <div className={'my-favorites'}>
      <div
        className={'item'}
      >
        <Button
          loading={isLoading}
          onClick={props.onClick}
          className={'menu-button'}
          primary
          active={props.active}
        >
          {props.t('events:button-my-events')} {getCounter()}
        </Button>
      </div>
    </div>
  )
}

class HyperMenu extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.menuRef = React.createRef()
    hycon.debug(`${this.constructor.name} constructor`, { props: this.props })
  }

  setStateProperty (propertyName, propertyValue) {
    let thisRef = this
    return new Promise((res) => {
      thisRef.setState((p) => {
        const obj = {}
        obj[propertyName] = propertyValue
        return Object.assign({}, p, obj)
      }, () => {
        hycon.debug(`${this.constructor.name} setStateProperty - newState`, { newState: thisRef.state })
        res()
      })
    })
  }

  getState () {
    return this.state
  }

  componentDidMount () {

    hycon.debug(`${this.constructor.name} componentDidMount`, { props: this.props })
  }

  static getDerivedStateFromProps (props, state) {
    hycon.debug(`${'HyperMenu'} getDerivedStateFromProps`, { props, state })
    if (!state) {
      hycon.debug(`${'HyperMenu'} getDerivedStateFromProps - only props have been updated`, { props, state })
    }
    return state
  }

  shouldComponentUpdate (nextProps, nextState) {

    hycon.debug(`${this.constructor.name} shouldComponentUpdate`, { nextProps, props: this.props, nextState })
    return true
  }

  isActiveLink (path) {
    let isActive = this.props.history.location.pathname === path
    return isActive
  }

  getProfileMenuItem (t) {
    let thisRef = this
    if (
      /*thisRef.props.reduxState.session &&
      thisRef.props.reduxState.session.user &&
      thisRef.props.reduxState.session.user.userName*/
        !!thisRef.props?.SEPContext?.user?.jwt === true
        && !!thisRef.props?.SEPContext?.user?.mandant === true
    ) {
      return (
        <div
          className={'item profile'}
        >
          <Button
            onClick={() => {
              thisRef.setStateProperty('isOpen', false).then(() => {
                thisRef.props.history.push('/user/edit/')
              })
            }}
            className={'menu-button'}
            primary
            active={!!thisRef.isActiveLink('/user/edit/')}
          >
            <h3>
              {t('button-profile')}
            </h3>
            <div>
              {thisRef.props?.SEPContext?.user?.jwtParsed["sub"]}
            </div>
          </Button>
        </div>
      )
    } else {
      hycon.warn(`${this.constructor.name} getMenuItems - getProfileMenuItem - not ready to display user data from redux`)
      return null
    }
  }

  getMenuItems () {
    hycon.debug(`${this.constructor.name} getMenuItems`, { props: this.props })
    let thisRef = this

    let isDefaultGuest = UserUtil.hasClientAndRole(thisRef, 'default', 'Guest')
    let isDefaultAnonymous = UserUtil.hasClientAndRole(thisRef, 'default', 'Anonymous')
    let isPublicUser = (
      thisRef.props.SEPContext &&
      thisRef.props.SEPContext.user &&
      thisRef.props.SEPContext.user.publicJWT
    )

    hycon.debug(`${this.constructor.name} getMenuItems`, { thisRef })

    const getMenuComponent = (t) => {
      let menuComponent = null;
      if (isDefaultAnonymous && !isPublicUser) {
        menuComponent = (
          <div className="item-container">
            <div className={"scrollable-items"}>
              <div
                className={'item nav-home'}
              >
                <Button
                  onClick={() => {
                    thisRef.setStateProperty('isOpen', false).then(() => {
                      thisRef.props.history.push('/')
                    })
                  }}
                  className={'menu-button'}
                  primary
                  active={!!thisRef.isActiveLink('/')}
                >
                  {t('button-home')}
                </Button>
              </div>
              <div
                className={'item nav-sign-in'}
              >
                <Button
                  onClick={() => {
                    thisRef.setStateProperty('isOpen', false).then(() => {
                      thisRef.props.history.push('/user/signin/', { unauthorized: false })
                    })
                  }}
                  className={'menu-button'}
                  primary
                  active={!!thisRef.isActiveLink('/user/signin/')}
                >
                  {t('button-signin')}
                </Button>
              </div>
              <div
                className={'item nav-sign-out'}
              >
                <Button
                  onClick={() => {
                    thisRef.setStateProperty('isOpen', false).then(() => {
                      thisRef.props.history.push('/user/signup/')
                    })
                  }}
                  className={'menu-button'}
                  primary
                  active={!!thisRef.isActiveLink('/user/signup/')}
                >
                  {t('button-signup')}
                </Button>
              </div>
              <div
                className={'item nav-about-sep'}
              >
                <Button
                  onClick={() => {
                    thisRef.setStateProperty('isOpen', false).then(() => {
                      window.open('https://www.swissenergyplanning.ch/', '_blank')
                    })
                  }}
                  className={'menu-button'}
                  primary
                  active={!!thisRef.isActiveLink('/what-is-sep/')}
                >
                  {t('button-what-is-sep')}
                </Button>
              </div>
              <div
                className={'item nav-tutorials'}
              >
                <Button
                  onClick={() => {
                    thisRef.setStateProperty('isOpen', false).then(() => {
                      window.open(`https://www.youtube.com/channel/UCz36vttIW5WahMgiphnrGog`, '_blank')
                    })
                  }}
                  className={'menu-button'}
                  primary
                  active={false}
                >
                  {t('button-tutorials')}
                </Button>
              </div>
              <div
                className={'item nav-impress'}
              >
                <Button
                  onClick={() => {
                    thisRef.setStateProperty('isOpen', false).then(() => {
                      window.open(`https://${thisRef.props.i18n.language.split('-')[0]}.swissenergyplanning.ch/impressum`, '_blank')
                    });
                  }}
                  className={'menu-button'}
                  primary
                  active={!!thisRef.isActiveLink('/impressum/')}
                >
                  {t('button-impressum')}
                </Button>
              </div>
              <div
                className={'item nav-privacy-policy'}
              >
                <Button
                  onClick={() => {
                    thisRef.setStateProperty('isOpen', false).then(() => {
                      window.open(`https://${thisRef.props.i18n.language.split('-')[0]}.swissenergyplanning.ch/datenschutz`, '_blank')
                    });
                  }}
                  className={'menu-button'}
                  primary
                  active={!!thisRef.isActiveLink('/privacy/')}
                >
                  {t('button-privacy')}
                </Button>
              </div>
            </div>
            {thisRef.getLanguageSwitch()}
            <SupportRequest {...thisRef.props}/>
            {thisRef.getFooter()}
          </div>
        )
      } else if (isDefaultGuest) {
        menuComponent = (
          <div className="item-container">
            {thisRef.getProfileMenuItem(t)}
            <div className={"scrollable-items"}>
              <div
                className={'item nav-home'}
              >
                <Button
                  onClick={() => {
                    thisRef.setStateProperty('isOpen', false).then(() => {
                      thisRef.props.history.push('/')
                    })
                  }}
                  className={'menu-button'}
                  primary
                  active={!!thisRef.isActiveLink('/')}
                >
                  {t('button-home')}
                </Button>
              </div>
              <div
                className={'item nav-map'}
              >
                <Button
                  onClick={() => {
                    thisRef.setStateProperty('isOpen', false).then(() => {
                      thisRef.props.history.push('/map/')
                    })
                  }}
                  className={'menu-button'}
                  primary
                  active={!!thisRef.isActiveLink('/map/')}
                >
                  {t('button-map')}
                </Button>
              </div>
              <div
                className={'item nav-sign-out'}
              >
                <Button
                  onClick={() => {
                    // logout, delete cookie
                    UserUtil.logout(thisRef).then(() => {
                      return UserUtil.deleteUserInReduxAndCookies(thisRef)
                    }).then(() => {
                      return TokenUtil.updateTokenInRedux(null, thisRef)
                    }).then(() => {
                      thisRef.props.reduxDispatch('updateSession', {})
                      return true
                    }).then(() => {
                      thisRef.props.history.push('/user/signin/')
                    }).catch((error) => {
                      // user had trouble to logout (invalid token?). so clear the cookies and redirect him
                      return UserUtil.deleteUserInReduxAndCookies(thisRef).then(() => {
                        return TokenUtil.updateTokenInRedux(null, thisRef)
                      }).then(() => {
                        thisRef.props.history.push('/user/signin/', { unauthorized: false })
                      })
                    })
                  }}
                  className={'menu-button'}
                  primary
                  active={false}
                >
                  {t('button-signout')}
                </Button>
              </div>
              <div
                className={'item nav-about-sep'}
              >
                <Button
                  onClick={() => {
                    thisRef.setStateProperty('isOpen', false).then(() => {
                      window.open('https://www.swissenergyplanning.ch/', '_blank')
                    })
                  }}
                  className={'menu-button'}
                  primary
                  active={!!thisRef.isActiveLink('/what-is-sep/')}
                >
                  {t('button-what-is-sep')}
                </Button>
              </div>
              <div
                className={'item nav-tutorials'}
              >
                <Button
                  onClick={() => {
                    thisRef.setStateProperty('isOpen', false).then(() => {
                      window.open(`https://www.youtube.com/channel/UCz36vttIW5WahMgiphnrGog`, '_blank')
                    })
                  }}
                  className={'menu-button'}
                  primary
                  active={false}
                >
                  {t('button-tutorials')}
                </Button>
              </div>
              <div
                className={'item nav-impress'}
              >
                <Button
                  onClick={() => {
                    thisRef.setStateProperty('isOpen', false).then(() => {
                      window.open(`https://${thisRef.props.i18n.language.split('-')[0]}.swissenergyplanning.ch/impressum`, '_blank')
                    });
                  }}
                  className={'menu-button'}
                  primary
                  active={!!thisRef.isActiveLink('/impressum/')}
                >
                  {t('button-impressum')}
                </Button>
              </div>
              <div
                className={'item nav-feedback'}
              >
                <Button
                  onClick={() => {
                    thisRef.setStateProperty('isOpen', false).then(() => {
                      thisRef.props.history.push('/feedback/')
                    })
                  }}
                  className={'menu-button'}
                  primary
                  active={!!thisRef.isActiveLink('/feedback/')}
                >
                  {t('button-feedback')}
                </Button>
              </div>
              <div
                className={'item nav-privacy-policy'}
              >
                <Button
                  onClick={() => {
                    thisRef.setStateProperty('isOpen', false).then(() => {
                      window.open(`https://${thisRef.props.i18n.language.split('-')[0]}.swissenergyplanning.ch/datenschutz`, '_blank')
                    });
                  }}
                  className={'menu-button'}
                  primary
                  active={!!thisRef.isActiveLink('/privacy/')}
                >
                  {t('button-privacy')}
                </Button>
              </div>
            </div>
            {thisRef.getLanguageSwitch()}
            <SupportRequest {...thisRef.props}/>
            {thisRef.getFooter()}
          </div>
        )
      } else {
        if (isPublicUser) {
          menuComponent = (
            <div className="item-container">
              <div className={"scrollable-items"}>
                <div
                  className={'item nav-about-sep'}
                >
                  <Button
                    onClick={() => {
                      thisRef.setStateProperty('isOpen', false).then(() => {
                        window.open('https://www.swissenergyplanning.ch/', '_blank')
                      })
                    }}
                    className={'menu-button'}
                    primary
                    active={!!thisRef.isActiveLink('/what-is-sep/')}
                  >
                    {t('button-what-is-sep')}
                  </Button>
                </div>
                <div
                  className={'item nav-impress'}
                >
                  <Button
                    onClick={() => {
                      thisRef.setStateProperty('isOpen', false).then(() => {
                        window.open(`https://${thisRef.props.i18n.language.split('-')[0]}.swissenergyplanning.ch/impressum`, '_blank')
                      });
                    }}
                    className={'menu-button'}
                    primary
                    active={!!thisRef.isActiveLink('/impressum/')}
                  >
                    {t('button-impressum')}
                  </Button>
                </div>
                {/*<div
                className={'item'}
              >
                <Button
                  onClick={() => {
                    thisRef.setStateProperty('isOpen', false).then(() => {
                      thisRef.props.history.push('/feedback/')
                    })
                  }}
                  className={'menu-button'}
                  primary
                  active={!!thisRef.isActiveLink('/feedback/')}
                >
                  {t('button-feedback')}
                </Button>
              </div>*/}
                <div
                  className={'item nav-privacy-policy'}
                >
                  <Button
                    onClick={() => {
                      thisRef.setStateProperty('isOpen', false).then(() => {
                        window.open(`https://${thisRef.props.i18n.language.split('-')[0]}.swissenergyplanning.ch/datenschutz`, '_blank')
                      });
                    }}
                    className={'menu-button'}
                    primary
                    active={!!thisRef.isActiveLink('/privacy/')}
                  >
                    {t('button-privacy')}
                  </Button>
                </div>
              </div>
              {thisRef.getLanguageSwitch()}
              <SupportRequest {...thisRef.props}/>
              {thisRef.getFooter()}
            </div>
          )
        } else {
          menuComponent = (
            <div className="item-container">
              {thisRef.getProfileMenuItem(t)}
              <div className={'scrollable-items'}>
                <div
                  className={'item nav-home'}
                >
                  <Button
                    onClick={() => {
                      thisRef.setStateProperty('isOpen', false).then(() => {
                        thisRef.props.history.push('/')
                      })
                    }}
                    className={'menu-button'}
                    primary
                    active={!!thisRef.isActiveLink('/')}
                  >
                    {t('button-home')}
                  </Button>
                </div>
                <div
                  className={'item nav-map'}
                >
                  <Button
                    onClick={() => {
                      thisRef.setStateProperty('isOpen', false).then(() => {
                        thisRef.props.history.push('/map/')
                      })
                    }}
                    className={'menu-button'}
                    primary
                    active={!!thisRef.isActiveLink('/map/')}
                  >
                    {t('button-map')}
                  </Button>
                </div>
                <MyFavorites
                  {...thisRef.props}
                  active={!!thisRef.isActiveLink('/favorites/')}
                  onClick={() => {
                    thisRef.setStateProperty('isOpen', false).then(() => {
                      thisRef.props.history.push('/favorites/')
                    })
                  }}
                  t={t}
                />
                <MyEvents
                  {...thisRef.props}
                  active={!!thisRef.isActiveLink('/events/')}
                  onClick={() => {
                    thisRef.setStateProperty('isOpen', false).then(() => {
                      thisRef.props.history.push('/events/')
                    })
                  }}
                  t={t}
                />
                <div
                  className={'item nav-sign-out'}
                >
                  <Button
                    onClick={() => {
                      // logout, delete cookie
                      UserUtil.logout(thisRef).then(() => {
                        return UserUtil.deleteUserInReduxAndCookies(thisRef)
                      }).then(() => {
                        return TokenUtil.updateTokenInRedux(null, thisRef)
                      }).then(() => {
                        thisRef.props.reduxDispatch('updateSession', {})
                        return true
                      }).then(() => {
                        thisRef.props.history.push('/user/signin/')
                      }).catch((error) => {
                        // user had trouble to logout (invalid token?). so clear the cookies and redirect him
                        return UserUtil.deleteUserInReduxAndCookies(thisRef).then(() => {
                          return TokenUtil.updateTokenInRedux(null, thisRef)
                        }).then(() => {
                          thisRef.props.history.push('/user/signin/', { unauthorized: false })
                        })
                      })
                    }}
                    className={'menu-button'}
                    primary
                    active={false}
                  >
                    {t('button-signout')}
                  </Button>
                </div>
                <div
                  className={'item nav-about-sep'}
                >
                  <Button
                    onClick={() => {
                      thisRef.setStateProperty('isOpen', false).then(() => {
                        window.open('https://www.swissenergyplanning.ch/', '_blank')
                      })
                    }}
                    className={'menu-button'}
                    primary
                    active={!!thisRef.isActiveLink('/what-is-sep/')}
                  >
                    {t('button-what-is-sep')}
                  </Button>
                </div>
                <div
                  className={'item nav-tutorials'}
                >
                  <Button
                    onClick={() => {
                      thisRef.setStateProperty('isOpen', false).then(() => {
                        window.open(`https://www.youtube.com/channel/UCz36vttIW5WahMgiphnrGog`, '_blank')
                      })
                    }}
                    className={'menu-button'}
                    primary
                    active={false}
                  >
                    {t('button-tutorials')}
                  </Button>
                </div>
                <div
                  className={'item nav-impress'}
                >
                  <Button
                    onClick={() => {
                      thisRef.setStateProperty('isOpen', false).then(() => {
                        window.open(`https://${thisRef.props.i18n.language.split('-')[0]}.swissenergyplanning.ch/impressum`, '_blank')
                      });
                    }}
                    className={'menu-button'}
                    primary
                    active={!!thisRef.isActiveLink('/impressum/')}
                  >
                    {t('button-impressum')}
                  </Button>
                </div>
                <div
                  className={'item nav-feedback'}
                >
                  <Button
                    onClick={() => {
                      thisRef.setStateProperty('isOpen', false).then(() => {
                        thisRef.props.history.push('/feedback/')
                      })
                    }}
                    className={'menu-button'}
                    primary
                    active={!!thisRef.isActiveLink('/feedback/')}
                  >
                    {t('button-feedback')}
                  </Button>
                </div>
                <div
                  className={'item nav-privacy-policy'}
                >
                  <Button
                    onClick={() => {
                      thisRef.setStateProperty('isOpen', false).then(() => {
                        window.open(`https://${thisRef.props.i18n.language.split('-')[0]}.swissenergyplanning.ch/datenschutz`, '_blank')
                      });
                    }}
                    className={'menu-button'}
                    primary
                    active={!!thisRef.isActiveLink('/privacy/')}
                  >
                    {t('button-privacy')}
                  </Button>
                </div>
              </div>
              {thisRef.getLanguageSwitch()}
              <SupportRequest {...thisRef.props}/>
              {thisRef.getFooter()}
            </div>
          )
        }
      }
      return menuComponent
    }
    return (
      <Translation ns={['menu', 'events']}>
        {(t) => {
          return getMenuComponent(t)
        }}
      </Translation>
    )
  }

  getLanguageSwitch () {
    let thisRef = this
    return (
      <div className={'language-switch'} style={{
        marginBottom: '34px'
      }}>
        <LanguageSwitch {...thisRef.props}/>
      </div>
    )
  }

  getFooter () {
    let thisRef = this;
    return (
      <div className={'item centered-all'}>
        <div>
          <div
            className={'version'}
            onClick={() => {
              window.open(`https://www.swissenergyplanning.ch`, '_blank')
            }}
          >
            <img className={'image'}
                 src={`${env.AZURE_STORAGE_BASE}/public/logo/${thisRef.props.appStateServer.clients[0]}/logo-sep-bw-transparent-bg.png`}
                 alt={'logo of swiss energy planning.'}
            />
            <div
              /*className={"version"}>halley {packageJson.version}-{process.env.REACT_APP_GI_ENV.substr(0, 1).toLocaleUpperCase()}{packageJson.buildNumber}</div>*/
              className={'version'}>v.{packageJson.version}
            </div>
          </div>
        </div>
      </div>
    )
  }

  getMenuHeightOffset () {
    let navbar = document.querySelectorAll('.navbar-container')[0]
    if (navbar) {
      return navbar.clientHeight
    } else {
      return 0
    }
  }

  render () {
    let thisRef = this
    return (
      <div className="HyperMenu">
        <div
          className={`menu menu-${this.state.isOpen ? 'visible' : 'invisible'}`}
          style={(() => {
            if (this.state.isOpen) {
              return {
                'top': `${thisRef.getMenuHeightOffset()}px`,
                'left': '0px',
                'WebkitTransform': `none`,
                'transform': `none`,
                'transition': 'transform 1000ms ease-out',
                'willChange': 'transform'
              }
            } else {
              let val = Math.min(320, window.screen.width)
              return {
                'top': `${thisRef.getMenuHeightOffset()}px`,
                'left': '0px',
                'WebkitTransform': `translateX(${-val}px)`,
                'transform': `translateX(${-val}px)`,
                'transition': 'transform 1000ms ease-in',
                'willChange': 'transform'
              }
            }
          })()}
        >
          {thisRef.getMenuItems()}
        </div>
        <div className={`menu-content`}>
          {thisRef.props.content}
        </div>
      </div>
    )
  }

  componentDidUpdate () {
    hycon.debug(`${this.constructor.name} componentDidUpdate`, { props: this.props })
  }

  componentWillUnmount () {
    hycon.debug(`${this.constructor.name} componentWillUnmount`, { props: this.props })
  }

  componentDidCatch (error, errorInfo) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} componentDidCatch`, { error, errorInfo })
    throw error
  }
}

export default HyperMenu
