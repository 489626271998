import React, { Suspense, useEffect, useState } from 'react';
import axios from 'axios';
import HyperConsole from '../../../../hyper_console/hyper-console';
import env from '../../../../../env/env';
import './DynamicPanels.css';

import CircularProgress from '@mui/material/CircularProgress';
import { getMandantInfo } from '../../panel_container/PanelContainer';

let Heat = null;
const Marketsense = React.lazy(() => import('./marketsense/Marketsense'));
const MarketsenseV4 = React.lazy(() => import('./marketsense_v4/Marketsense'));

const Demo = React.lazy(() => import('./demo/Demo'));
const EnergySimulation = React.lazy(() => import('./energysimulation/EnergySimulation'));

const { REACT_APP_GI_ENV } = process.env;
let hycon = null;
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('Error in dynamic panel', { error });
    this.props.onError();
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="error-boundary">
          <h5>Something went wrong. Please check your configuration for this panel or contact the support.</h5>
          <div className="message">
            <h5>settings:</h5>
            {JSON.stringify(this.props.setting)}
            <br />
            <h5>error message:</h5>
            {this.state.error.message}
            <h5>error stack:</h5>
            {this.state.error.stack}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

function LoadingPanel() {
  return (
    <div style={{
      display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center',
    }}
    >
      <CircularProgress />
    </div>
  );
}

function DynamicPanel(props) {
  // const fnName = "DynamicPanel";
  const [supportedPanelTypesFromSettings] = useState([
    'marketsense',
    'marketsense_v4',
    'energysimulation',
    'demo',
  ]);
  const currentUser = getMandantInfo({ props });
  const getPanelShell = (innerComponent) => (
    <div className="dynamic-panel">
      <ErrorBoundary
        {...props}
        onError={() => {
          // go to the dashboard if error occurs
          Promise.all([
            props.setParentState('isPortalVisible', false),
            props.setParentState('isTilesVisible', true),
          ]);
        }}
      >
        {innerComponent}
      </ErrorBoundary>
    </div>
  );
  if (supportedPanelTypesFromSettings.includes(props.setting.panelType) && props.setting.panelType === 'marketsense') {
    return getPanelShell(
      <Suspense fallback={<LoadingPanel />}>
        <Marketsense {...props} />
      </Suspense>,
    );
  } if (
    supportedPanelTypesFromSettings.includes(props.setting.panelType) && props.setting.panelType === 'marketsense_v4'
    // to debug a specific panel, enable the following line
    // && props.setting.category === 'MarketSense Parzelle'
  ) {
    return getPanelShell(
      <Suspense fallback={<LoadingPanel />}>
        <MarketsenseV4
          {...props}
          panelConfig={props.setting}
          isPortalVisible={props.isPortalVisible}
          isTilesVisible={props.isTilesVisible}
        />
      </Suspense>,
    );
  } if (supportedPanelTypesFromSettings.includes(props.setting.panelType) && props.setting.panelType === 'energysimulation') {
    return getPanelShell(
      <Suspense fallback={<LoadingPanel />}>
        <EnergySimulation {...props} />
      </Suspense>,
    );
  } if (supportedPanelTypesFromSettings.includes(props.setting.panelType) && props.setting.panelType === 'demo') {
    if (
      currentUser.role === 'Guest'
    ) {
      return getPanelShell(
        <Suspense fallback={<LoadingPanel />}>
          <Demo {...props} />
        </Suspense>,
      );
    }
    return null;
  } if (props.setting.panelType === 'heat' && props.comesFromSettings === false) {
    Heat = Heat === null ? React.lazy(() => import('./heat/Heat')) : Heat;
    if (Heat) {
      return getPanelShell(
        <Suspense fallback={<LoadingPanel />}>
          <Heat {...props} />
        </Suspense>,
      );
    }
    return null;
  }
  if (props.comesFromSettings && !supportedPanelTypesFromSettings.includes(props.setting.panelType)) {
    hycon.error(`panelType ${props.setting.panelType} is not supported`, props);
  }
  return null;
}

function DynamicPanels(props) {
  const { jwt } = props.reduxState.user;
  const hookName = 'DynamicPanels';
  const [panelSettings, setPanelSettings] = useState([]);

  useEffect(() => {
    hycon.debug(`${hookName} - initializing dynamic panels`, props);
  }, [hookName, props]);

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getParsedSettings = () => {
    const settings = [];
    const settingsTree = getSettingsTree(panelSettings);
    settingsTree.forEach((listElement) => {
      hycon.debug(`${hookName} getParsedSettings - listElement`, { settings, settingsTree, listElement });
      const settingsJS = {};
      listElement.settingsByTenantAndPanelTypeAndCategory.forEach((s) => {
        settingsJS.category = listElement.category;
        settingsJS.panelType = listElement.panelType;
        settingsJS[s.name] = s.value;
      });
      settings.push(settingsJS);
    });
    hycon.debug(`${hookName} getParsedSettings`, { settings, settingsTree });
    return settings;
  };
  const getPanelSettings = async () => {
    const endpoint = `${env.API_GATEWAY_BASE}/api/panelsettings`;
    const data = {};
    return await axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        data: JSON.stringify(data),
      },
    )
      .then((response) => {
        hycon.debug(`${hookName} getPanelSettings - response`, { response });
        return response;
      })
      .catch((error) => {
        hycon.error(`${hookName} getPanelSettings`, { error });
      // throw error
      });
  };
  const getSettings = async () => {
    const response = await getPanelSettings();
    if (response && response.data) {
      setPanelSettings(response.data);
    }
  };
  const getPropertySet = (elements, property) => [...new Set(elements.map((el) => el[property]))];
  const filterByProperty = (elements, property, propertyValue) => elements.filter((el) => el[property] === propertyValue);
  const getSettingsTree = (panelSettings) => {
    hycon.debug(`${hookName} - getSettingsTree`, { panelSettings });
    const allTenants = getPropertySet(panelSettings, 'mandant');
    const settingsTree = [];
    allTenants.forEach((tenant) => {
      const tenantSettings = filterByProperty(panelSettings, 'mandant', tenant);
      const panelTypesForTenant = getPropertySet(tenantSettings, 'panelType');
      panelTypesForTenant.forEach((panelType) => {
        const settingsByTenantAndPanelType = filterByProperty(tenantSettings, 'panelType', panelType);
        const categoriesByTenantAndPanelType = getPropertySet(settingsByTenantAndPanelType, 'category');
        categoriesByTenantAndPanelType.forEach((category) => {
          const settingsByTenantAndPanelTypeAndCategory = filterByProperty(settingsByTenantAndPanelType, 'category', category);
          settingsTree.push({
            tenant,
            panelTypesForTenant,
            panelType,
            settingsByTenantAndPanelType,
            categoriesByTenantAndPanelType,
            category,
            settingsByTenantAndPanelTypeAndCategory,
          });
        });
      });
    });
    hycon.debug(`${hookName} - getSettingsTree`, { panelSettings, allTenants, settingsTree });
    return settingsTree;
  };
  const getPanels = () => {
    const dynamicPanels = [];
    const settings = getParsedSettings();
    const settingsTree = getSettingsTree(panelSettings);
    const currentUser = getMandantInfo({ props });
    hycon.debug(`${hookName} getPanels - settingsTree`, { settingsTree, settings });
    settings.forEach((setting, i) => {
      if (JSON.parse(setting.setting).active) {
        dynamicPanels.push((<DynamicPanel key={i} {...props} setting={setting} comesFromSettings />));
      }
    });

    if (
      currentUser.role !== 'Guest'
    ) {
      const heatSetting = {
        category: 'Heat',
        panelType: 'heat',
        setting: JSON.stringify({
          class: 'heat',
          live: true,
          active: true,
          version: 1,
          localized: {
            'de-ch': {
            },
            'fr-ch': {
            },
            'it-ch': {
            },
          },
          overviewIconURL: 'https://geoimpactstorage.blob.core.windows.net/halley/components/dashboard/tile-icons/Waerme.png',
        }),
      };
      dynamicPanels.push((<DynamicPanel key={settings.length + 1} {...props} setting={heatSetting} comesFromSettings={false} />));
    }
    return dynamicPanels;
  };

  return getPanels();
}

export default DynamicPanels;
