import {HIGH_ZOOM_LEVEL} from "./ConstructionZone";
import HyperConsole from "../../../../hyper_console/hyper-console";
import {GoogleAnalytics} from "../../../../google_analytics/GoogleAnalytics";
let hycon = new HyperConsole({isEnabled: false, name:__filename}).myConsole;
export default class MapHandler {
    constructor() {
        hycon.debug(`${this.FN_NAME} constructor`);
        this.FN_NAME = "MapHandler";
        this.state = {
            reduxState: null,
            constructions: [],
            isHighZoom: false,
            isGraphicsDisplayed: false,
            constructionMarkersGroups: []
        };
    }

    setIsGraphicsDisplayed(isGraphicsDisplayed){
        this.state = {
            ...this.state, isGraphicsDisplayed
        };
        this.updateLayer();
    }

    dispatchEnter(dipatcherObject, data){
        // create and dispatch the event
        let event = new CustomEvent("panel-constructions-detail-enter", {
            detail: {
                info: "info",
                data
            }
        });
        dipatcherObject.dispatchEvent(event);
    };

    dispatchLeave(dipatcherObject){
        // create and dispatch the event
        let event = new CustomEvent("panel-constructions-detail-leave", {
            detail: {
                info: "info"
            }
        });
        dipatcherObject.dispatchEvent(event);
    };

    isHighZoom() {
        let thisRef = this;

        const map = thisRef.state.reduxState.map.map;
        let zoom = map.getZoom();
        return zoom >= HIGH_ZOOM_LEVEL;
    }

    showLayer() {
        try {
            let thisRef = this;
            hycon.debug(`${this.FN_NAME} showLayer`, {constructions: thisRef.state.constructions, state: this.state});
            const L = thisRef.state.reduxState.map.Leaflet;
            const map = thisRef.state.reduxState.map.map;

            const getMarker = (lat, lng) => {
                const svgString = `<svg width="141.7" height="141.7" xmlns="http://www.w3.org/2000/svg">
                                    <style type="text/css">.st0{fill:#689F38;}
                                        .st1{fill:#FFFFFF;}</style>
                                
                                    <g>
                                        <title>background</title>
                                        <rect fill="none" id="canvas_background" height="402" width="582" y="-1" x="-1"/>
                                    </g>
                                    <g>
                                        <title>Layer 1</title>
                                        <circle id="svg_1" r="68" cy="70.9" cx="70.9"/>
                                        <circle id="svg_2" r="68" cy="70.9" cx="70.9" class="st0"/>
                                        <path id="svg_3" d="m109.1,112l-76.5,0c-3.1,0 -5.7,-2.5 -5.7,-5.7l0,-1.4c0,-3.1 2.5,-5.7 5.7,-5.7l76.5,0c3.1,0 5.7,2.5 5.7,5.7l0,1.4c0,3.2 -2.5,5.7 -5.7,5.7z" class="st1"/>
                                        <g id="svg_4">
                                            <path id="svg_5" d="m78.6,41.5l-3,-7.3c-2.4,-5.9 -9.3,-5.9 -11.6,-0.1l-2.9,7.4l17.5,0z" class="st1"/>
                                            <polygon id="svg_6" points="50.5,69 89.7,69 84.3,55.7 55.7,55.7  " class="st1"/>
                                            <polygon id="svg_7" points="45,83.2 38.9,100.2 102.8,99.9 95.4,83.2  " class="st1"/>
                                        </g>
                                    </g>
                                </svg>`;
                const svg = `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`;
                const iconUrl = svg;
                const blueIcon = L.icon({
                    iconUrl,
                    iconSize: [52, 52], // size of the icon
                    iconAnchor: [26, 26], // point of the icon which will correspond to marker's location
                });
                const marker = L.marker([lat, lng], {icon: blueIcon});
                return marker;
            };

            let markers = [];
/*            thisRef.state.constructions.response.data.forEach((construction) => {
                let lat = construction.lat;
                let long = construction.long;
                markers.push(getMarker(lat, long));
            });*/
            thisRef.state.constructions.response.filteredData.forEach((construction) => {
                let lat = construction.lat;
                let long = construction.long;
                markers.push(getMarker(lat, long));
            });

            let constructionMarkersGroup = L.layerGroup([...markers]);
            this.state = {
                ...this.state, constructionMarkersGroups: [constructionMarkersGroup]
            };
            constructionMarkersGroup.addTo(map);
        }catch (e) {
            hycon.warn(e);
        }
    }

    removeLayer() {
        let thisRef = this;

        const map = thisRef.state.reduxState.map.map;
        // clear the old markers
        thisRef.state.constructionMarkersGroups.forEach((group) => {
            hycon.debug(`${this.FN_NAME} removeLayer - removing group`, {group});
            map.removeLayer(group);
        });
        this.state = {
            ...this.state, constructionMarkersGroups: []
        };
    }

    updateLayer() {
        let thisRef = this;

        const map = thisRef.state.reduxState.map.map;
        hycon.debug(`${this.FN_NAME} updateLayer`, {map, constructions: thisRef.state.constructions, state: thisRef.state});
        let isHighZoom = thisRef.isHighZoom(map);
        if (thisRef.isHighZoom(map) && thisRef.state.isGraphicsDisplayed) {
            hycon.debug(`${this.FN_NAME} updateLayer`, {isHighZoom, map});
            thisRef.removeLayer();
            thisRef.showLayer();
        } else {
            hycon.debug(`${this.FN_NAME} updateLayer`, {isHighZoom, map});
            thisRef.removeLayer();
        }
    }

    flyTo(lat, lng) {
        hycon.debug(`${this.FN_NAME} flyTo`, {lat, lng});
        let thisRef = this;

        const map = thisRef.state.reduxState.map.map;
        map.flyTo([lat, lng]);
    }

    updateReduxState(reduxState, done) {
        hycon.debug(`${this.FN_NAME} updateReduxState`, {reduxState});
        if (
            !this.state.reduxState ||
            !this.state.reduxState.map ||
            !this.state.reduxState.map.map
        ) {
            hycon.debug(`${this.FN_NAME} updateReduxState map object is now ready in redux`, {reduxState});
            this.state = {
                ...this.state,
                reduxState: reduxState
            };
            done();
        }
    }

    updateConstructions(constructions) {
        hycon.debug(`${this.FN_NAME} updateConstructions`, {constructions});
        this.state = {
            ...this.state,
            constructions
        };

        this.updateLayer();
    }

    updateIsHighZoom(isHighZoom) {
        hycon.debug(`${this.FN_NAME} updateIsHighZoom`, {isHighZoom});
        this.state = {
            ...this.state,
            isHighZoom
        };
        this.updateLayer();
    }

    getCurrentData() {



        return {
            map: this.state.reduxState.map.map
        };
    }

    initMapListener = (cb, ctx) => {
        hycon.debug(`${this.FN_NAME} constructor`, {state: this.state});
        try {
            const onMoveend = (e) => {
                hycon.debug(`${this.FN_NAME} initMapListener onMoveend`, e);

                let userName = null;
                try {
                    let user = ctx.props.reduxState.session.user;
                    userName = user.userName;
                } catch (e) {
                    userName = "Anonymous";
                }

                GoogleAnalytics.sendGenerigEvent(
                    `map-move by ${userName}`,
                    "map-interaction",
                    "Map Interaction",
                    false
                );

                cb("moveend", {
                    map: this.state.reduxState.map.map
                });
            };
            this.state.onMoveend = onMoveend;
            this.state.reduxState.map.map.on("moveend", onMoveend);
            hycon.debug(`${this.FN_NAME} initMapListener saved onMoveend listener to this.state`);
        } catch (e) {
            hycon.warn(`${this.FN_NAME} initMapListener moveend`, e);
        }
    };
    removeListener = () => {
        hycon.debug(`${this.FN_NAME} removeListener`);
        if(
          this.state.reduxState.map &&
          this.state.reduxState.map.map
        ){
            this.state.reduxState.map.map.off("moveend", this.state.onMoveend);
            hycon.debug(`${this.FN_NAME} removeListener done`, {state: this.state});
        }
    };
}


