import React, {Component} from 'react';
import HyperConsole from "../../../../../hyper_console/hyper-console";
import {Button, Icon} from 'semantic-ui-react'
import "./ConstructionZoneDetail.css";
import moment from "moment";
import { withTranslation } from 'react-i18next'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV;
let hycon = null;
if (REACT_APP_GI_ENV === "development") {
    hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
} else {
    hycon = new HyperConsole({isEnabled: false, name: __filename}).myConsole;
}

class ConstructionZoneDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        hycon.debug(`${this.constructor.name} constructor`, {props: this.props});
    }

    componentDidMount() {
        // let thisRef = this;
        hycon.debug(`${this.constructor.name} componentDidMount`, {props: this.props});

        // scroll the panel to the top
        document.querySelectorAll(".OverlayDashboard")[0].focus();
        document.querySelectorAll(".OverlayDashboard")[0].scrollTop = 0;
        hycon.debug(`${this.constructor.name} componentDidMount scrolling to top`);
    }


    static getDerivedStateFromProps(props, state) {
        hycon.debug(`${"ConstructionZoneDetail"} getDerivedStateFromProps`, {props, state});
        if (!state) {
            hycon.debug(`${"ConstructionZoneDetail"} getDerivedStateFromProps - only props have been updated`, {
                props,
                state
            });
        }
        return state;
    }

    shouldComponentUpdate(nextProps, nextState) {

        hycon.debug(`${this.constructor.name} shouldComponentUpdate`, {nextProps, props: this.props, nextState});
        return true;
    }

    getConstructionsComponent() {
        let thisRef = this;
        // let data = thisRef.props.constructions.response.data;
        let data = thisRef.props.constructions.response.filteredData;

        /*
        documediaUri
        id
        lastUpdated
        links
        projectDescription
        projectMaincategory
        projectName
        projectPlanstageDate
        projectPostcode
        projectStreet
        projectTown
        rowVersionString
        */
        let comps = [];
        data.forEach((construction, index) => {
            let comp = (
                <div className={`construction`} key={index}>
                    <div className={`construction-heading`}>
                        <div className={`title`}>{thisRef.props.i18n.t("panel_construction_zone:label-panel-overview-title")} {index + 1}</div>
                        <Button
                            icon
                            size='huge'
                            onClick={() => {
                                let construction = thisRef.props.constructions.response.data[index];
                                hycon.debug(`${this.constructor.name} onFocus`, {index: index, construction});
                                thisRef.props.mapHandler.flyTo(construction.lat, construction.long);
                            }}>
                            <i className="fas fa-crosshairs"/>
                        </Button>
                        <Button
                            icon
                            size='huge'
                            onClick={() => {
                                /*
                                let construction = thisRef.props.constructions.response.data[index];
                                hycon.debug(`${this.constructor.name} onLink`, {index: index, construction});
                                */
                                let dokumediaLink = "https://www.baublatt.ch/bauauftraege/";
                                window.open(`${dokumediaLink}`, "_blank");
                            }}>
                            <Icon name='external'/>
                        </Button>
                    </div>
                    <div>
                        <div className={"line"}>
                            <div><span className={"line-title"}>{thisRef.props.i18n.t("panel_construction_zone:label-detail-title")+" "}</span>{construction.projectName}</div>
                        </div>
                        <div className={"line"}>
                            <div>
                                <span className={"line-title"}>{thisRef.props.i18n.t("panel_construction_zone:label-detail-category")+" "}</span>{thisRef.props.i18n.t(`panel_construction_zone:${construction.projectMaincategoryLocize}`)}
                            </div>
                        </div>
                        <div className={"line"}>
                            <div><span
                                className={"line-title"}>{thisRef.props.i18n.t("panel_construction_zone:label-detail-address")+" "}</span>{(() => {
                                    let street = null;
                                    let code = null;
                                    let town = null;
                                    let hasStreet = false;
                                    let hasPostcodeAndTown = false;
                                    if(construction.projectStreet){
                                        street = `${construction.projectStreet}`;
                                        hasStreet = true;
                                    }
                                    if(
                                        construction.projectPostcode&&
                                        construction.projectTown
                                    ){
                                        code = `${construction.projectPostcode}`;
                                        town = `${construction.projectTown}`;
                                        hasPostcodeAndTown = true;
                                    }
                                    return `${street === null ? "" : street}${hasStreet && hasPostcodeAndTown ? ", ": ""}${code === null ? "" : code} ${town === null ? "" : town}`;
                            })()}
                            </div>
                        </div>
                        <div className={"line"}>
                            <div><span className={"line-title"}>{thisRef.props.i18n.t("panel_construction_zone:label-detail-last-modify")+" "}</span>{(
                                () => {
                                    try {
                                        let d = moment(construction.lastUpdated);
                                        return d.format('DD. MMMM YYYY, hh:mm:ss');
                                    } catch (e) {
                                        hycon.warn(e);
                                        return "Error";
                                    }
                                }
                            )()}</div>
                        </div>
                        <div className={"line"}>
                            <div><span className={"line-title"}>{thisRef.props.i18n.t("panel_construction_zone:label-detail-status")+" "}</span>{(() => {
                                if(construction.projectState){
                                    return construction.projectState;
                                }
                            })()}</div>
                        </div>
                    </div>
                </div>
            );
            comps.push(comp);
        });
        return comps;
    }

    render() {
        let thisRef = this;
        let comp = null;
        if (
          thisRef.props.constructions &&
          thisRef.props.constructions.isHighZoom &&
            thisRef.props.constructions.response &&
            thisRef.props.constructions.response.data
        ) {
            comp = (
                <div>{thisRef.getConstructionsComponent()}</div>
            );
        } else if (
            thisRef.props.constructions &&
            !thisRef.props.constructions.isHighZoom &&
            thisRef.props.constructions.response &&
            thisRef.props.constructions.response.data
        ) {
            comp = (
                <div>{thisRef.props.i18n.t("panel_construction_zone:help-text")}</div>
            );
        }
        return (
            <div className="ConstructionZoneDetail detail">
                {comp}
                <div className={`logo-container`}>
                    <div>powered by</div>
                    <div style={{
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        height: "60px",
                        width: "120px",
                        backgroundImage: `url('https://geoimpactstorage.blob.core.windows.net/public/logo/partners/dokumedia-logo.png')`
                    }}/>
                </div>
            </div>
        );
    }

    componentDidUpdate() {

        hycon.debug(`${this.constructor.name} componentDidUpdate`, {props: this.props});
    }

    componentWillUnmount() {

        hycon.debug(`${this.constructor.name} componentWillUnmount`, {props: this.props});
    }
}

export default withTranslation([
  "panel_construction_zone"
])(ConstructionZoneDetail);

