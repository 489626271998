import {Stack, Typography, Divider, IconButton, Link} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

export default function SupportRequest(props) {
    return (
        <Stack alignItems="center" className='SupportRequest'>
            <Typography variant="h6" sx={{ fontSize: '1rem' }}>
                {props.i18n.t("various:label-support-request")}
            </Typography>
            <Stack direction="column">
                <Stack direction="row" sx={{
                    alignItems: 'center'
                }}>
                    <IconButton aria-label="delete" href={`mailto:${props.i18n.t('various:support-request-email')}`}>
                        <EmailIcon />
                    </IconButton>
                    <Link color="inherit" href={`mailto:${props.i18n.t('various:support-request-email')}`}>
                        {props.i18n.t('various:support-request-email')}
                    </Link>
                </Stack>
                <Stack direction="row" sx={{
                    alignItems: 'center'
                }}>
                    <IconButton aria-label="delete" href={`tel:${props.i18n.t('various:support-request-phone')}`}>
                        <PhoneIcon />
                    </IconButton>
                    <Link color="inherit" href={`tel:${props.i18n.t('various:support-request-phone')}`}>
                        {props.i18n.t('various:support-request-phone')}
                    </Link>
                </Stack>
            </Stack>
        </Stack>
    )
}