// import env from "../../env/env";

const logger = {
  setLevels: (env) => {
    console.log(`Setting custom logger for env "${env.REACT_APP_GI_ENV}"`)
    if (env.REACT_APP_GI_ENV === 'production') {
      /* console.debug = () => {
            };
            console.info = () => {
            };
            console.log = () => {
            }; */
      // leave just warnings and errors on the console
      // console.warn = () => {};
      // console.error = () => {};
    } else if (env.REACT_APP_GI_ENV === 'development') {
      // console.debug = () => {};
      // console.info = () => {};
      // console.log = () => {};
      // console.warn = () => {};
      // console.error = () => {};
    } else if (env.REACT_APP_GI_ENV === 'test') {
      // console.debug = () => {};
      // console.info = () => {};
      // console.log = () => {};
      // console.warn = () => {};
      // console.error = () => {};
    } else if (env.REACT_APP_GI_ENV === 'experimental') {
      // console.debug = () => {};
      // console.info = () => {};
      // console.log = () => {};
      // console.warn = () => {};
      // console.error = () => {};
    }
  }
}

export { logger }
