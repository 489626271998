import React, {useEffect, useRef} from 'react'
import HyperConsole from '../../../../hyper_console/hyper-console'

import { Loader, Dimmer } from 'semantic-ui-react'
import {Button as MUIButton} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close'
import SettingsIcon from '@mui/icons-material/Settings';

import './Usage.css'
import ReactDOM from 'react-dom'
import { UserUtil } from '../../../../user_management/util/user-util'
import axios from 'axios/index'
import UsageDetail from './UsageDetail'
import UsageSettings from './UsageSettings'
import { GoogleAnalytics } from '../../../../google_analytics/GoogleAnalytics'
import { withTranslation } from 'react-i18next'
import {WixUtil} from "../../../../../util/wix-util";
import {useMap} from "../../../../../contexts/map/map-context";

const RENDER_OVERVIEW = 'RENDER_OVERVIEW'
const RENDER_DETAIL = 'RENDER_DETAIL'
const RENDER_SETTINGS = 'RENDER_SETTINGS'

const REACT_APP_GI_ENV = process.env.REACT_APP_GI_ENV
let hycon = null
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
}

let colors = [
  '#ffa600',
  '#cd99ff',
  '#f6a49f',
  '#d9b299',
  '#8c8c8c',
  '#80ff33',
  '#ff99ff',
  '#cccccc',
  '#e0d4ff',
]
let categories = [
  'Wohnzonen',
  'Arbeitszonen',
  'Mischzonen',
  'Zentrumszonen',
  'Zonen für öffentliche Nutzungen',
  'eingeschränkte Bauzonen',
  'Tourismus- und Freizeitzonen',
  'Verkehrszonen innerhalb der Bauzonen',
  'weitere Bauzonen',
]

function LeafletLayers(){
  const { maps, setMaps } = useMap();
  const map = maps?.main?.map;

  const geomsArray = useRef([])
  useEffect(() => {
    if(!!map){
      function onDraw(e){
        hycon.debug("got LeafletLayers:onDraw event", e);
        geomsArray.current.push(e.detail.geometriesGroup);
        map.addLayer(e.detail.geometriesGroup);
      }
      function onClear(e){
        hycon.debug("got LeafletLayers:onClear event", e);
        geomsArray.current.forEach(geomElement => {
          map.removeLayer(geomElement);
        })
        geomsArray.current = [];
      }
      window.addEventListener("usage:draw", onDraw);
      window.addEventListener("usage:clear", onClear);
      return () => {
        window.removeEventListener("usage:draw", onDraw);
        window.removeEventListener("usage:clear", onClear);
      }
    }
  }, [maps?.main?.map])
  return null;
}

class Usage extends React.Component {
  constructor (props) {
    super(props)
    let thisRef = this
    this.state = {
      roofs: null,
      isMounted: false,
      isLoading: false,
      rendering: RENDER_OVERVIEW,
      panelState: null,
      panelStateMap: null,
      panelStateMapSorted: null,
      settings: { colors, categories },
      callbacks: {
        onDetailEnter: () => {
          hycon.debug(`${this.constructor.name} callbacks - onDetailEnter`, {
            state: this.state,
            props: this.props
          })
          thisRef.drawGraphics()
        },
        onDetailLeave: () => {
          hycon.debug(`${this.constructor.name} callbacks - onDetailLeave`, {
            state: this.state,
            props: this.props
          })
          thisRef.clearAllGraphics()
        },
        onSettingsEnter: () => {
          hycon.debug(`${this.constructor.name} callbacks - onSettingsEnter`, {
            state: this.state,
            props: this.props
          })

        },
        onSettingsLeave: () => {
          hycon.debug(`${this.constructor.name} callbacks - onSettingsLeave`, {
            state: this.state,
            props: this.props
          })
          thisRef.clearAllGraphics()
        }
      }
    }
    this.setIsLoading = this.setIsLoading.bind(this)
    this.clearAllGraphics = this.clearAllGraphics.bind(this);
    this.displayGeometries = this.displayGeometries.bind(this);
    this.layersRef = React.createRef();
    this.mapRef = React.createRef(props?.reduxState?.map?.map)
    hycon.debug(`${this.constructor.name} constructor`, { props: this.props })
  }

  componentDidMount () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} componentDidMount`, { props: this.props })
    thisRef.setStateProperty('isMounted', true);
    thisRef.mapRef.current = thisRef?.props?.reduxState?.map?.map;
  }

  displayGeometries (geometry) {
    hycon.debug(`${this.constructor.name} displayGeometries`, { geometry, thisRef: this })
    let thisRef = this
    let geometriesObject = geometry.geometries
    let category = geometry.category
    let categoryIndex = thisRef.state.settings.categories.indexOf(category)
    hycon.debug(`${this.constructor.name} displayGeometries`, { geometry, category, categoryIndex })
    let map = thisRef.mapRef.current;
    let leaflet = thisRef.props.reduxState.map.Leaflet
    hycon.debug(`${thisRef.constructor.name} displayGeometries`, { geometriesObject, map, leaflet, thisRef })
    let colors = thisRef.state.settings.colors
    const getStyle = (colorIndex, opacity = 0.9) => {
      let myStyle = {
        // "color": colors[colorIndex],
        'fillColor': colors[colorIndex],
        'weight': 0,
        // "opacity": opacity,
        'fillOpacity': opacity
      }
      return myStyle
    }

    let geometriesGroup = new leaflet.FeatureGroup();
    try {
      let geojsonFeature = JSON.parse(geometriesObject)
      let geojson = leaflet.geoJSON(geojsonFeature, {
        style: getStyle(categoryIndex, 0.42)
      })
      geometriesGroup.addLayer(geojson)
    } catch (e) {
      hycon.debug(`displayGeometries - error`, e)
      hycon.error(e)
    }

    thisRef.layersRef.current = geometriesGroup;
    window.dispatchEvent(new CustomEvent("usage:draw", {detail: {
      geometriesGroup
    }}))
    hycon.debug(`addLayer`, {
      layer: thisRef.layersRef.current
    })
  }

  clearAllGraphics () {
    window.dispatchEvent(new CustomEvent("usage:clear", {detail: {}}))
  }

  drawGraphics () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} drawGraphics`)
    if (
      thisRef.state.rendering === RENDER_DETAIL ||
      thisRef.state.rendering === RENDER_SETTINGS
    ) {
      hycon.debug(`${thisRef.constructor.name} update - drawGraphics`, {
        panelStateMap: thisRef.state.panelStateMap,
        panelState: thisRef.state.panelState
      })
      if (thisRef.state.panelState && thisRef.state.panelState.districtGeometries) {
        for (let i = 0; i < thisRef.state.panelState.districtGeometries.length; i++) {
          let districtGeometry = thisRef.state.panelState.districtGeometries[i]
          // NOTE: display the graphics only once (otherwise they would overlap)
          thisRef.displayGeometries(districtGeometry)
        }
      } else {
        hycon.debug(`${thisRef.constructor.name} update - drawGraphics - displayGeometries - no geometries`, {
          panelState: thisRef.state.panelState
        })
      }
    }
  }

  getOverview () {
    let thisRef = this
    hycon.debug(`${this.constructor.name} getOverview`, { props: this.props, thisRef: this })
    let selectedBuildings = thisRef.props.reduxState.selectedBuildings
    let nrOfBuildings = selectedBuildings.length
    const getGwrUsage = () => {
      // panelState.gwrData[""0""].housingEgids.gebKategorie
      let usages = thisRef.state.panelState.gwrData.map((gwr) => {
        return thisRef.props.i18n.t(`dynamic_panel_marketsense_v4:value-gebKategorieShort-${gwr.housingEgids.gebKategorieShort}`);
      });
      if(usages.length > 1){
        return thisRef.props.i18n.t('panel_usage:label-panel-various');
      } else if(usages.length === 1){
        return usages[0];
      } else {
        return null;
      }
    }
    const getBuildingUsages = () => {
      let thisRef = this
      if (!thisRef.state.panelState) {
        return null
      }
      // thisRef.state.panelState.bauzonen[""0""].chBezD

      let uniqueZones
      if (thisRef.props.i18n.language === 'de-CH') {
        uniqueZones = [...new Set(thisRef.state.panelState.bauzonen.map(bz => bz.chBezD))]
        hycon.debug(`${thisRef.constructor.name} getBuildingUsages - uniqueUsages`, {
          uniqueZones,
          panelState: thisRef.state.panelState
        })
      } else if (thisRef.props.i18n.language === 'fr-CH') {
        uniqueZones = [...new Set(thisRef.state.panelState.bauzonen.map(bz => bz.chBezF))]
        hycon.debug(`${thisRef.constructor.name} getBuildingUsages - uniqueUsages`, {
          uniqueZones,
          panelState: thisRef.state.panelState
        })
      } else {
        hycon.warn(`${thisRef.constructor.name} getBuildingUsages - uniqueUsages - zones are not available in this language, default de-ch will be used`,
          { panelState: thisRef.state.panelState })
        uniqueZones = [...new Set(thisRef.state.panelState.bauzonen.map(bz => bz.chBezD))]
        hycon.debug(`${thisRef.constructor.name} getBuildingUsages - uniqueUsages`, {
          uniqueZones,
          panelState: thisRef.state.panelState
        })
      }

      if (uniqueZones.length === 0) {
        // if there is gwr info display it, otherwise use the zone info
        // if there is more than one gwr usage then display panel_usage:label-panel-various
        let gwrUsage = getGwrUsage();
        if(gwrUsage === null){
          return thisRef.props.i18n.t('panel_usage:label-no-usage-data')
        } else {
          return gwrUsage;
        }
      } else if (uniqueZones.length === 1) {
        return uniqueZones[0]
      } else if (uniqueZones.length > 1) {
        return thisRef.props.i18n.t('panel_usage:label-panel-various')
      } else {
        return thisRef.props.i18n.t('panel_usage:label-no-usage-data')
      }
    }
    const getOverview = () => {
      let usage = getBuildingUsages()
      if (nrOfBuildings === 0) {
        return (
          <div className={`overview`}>
            {
              thisRef.props.content.overview.iconComponent ? (
                <div>
                  {thisRef.props.content.overview.iconComponent}
                </div>
              ) : null
            }
            <div>
              <h1>{thisRef.props.i18n.t('panel_usage:label-panel-name')}</h1>
            </div>
          </div>
        )
      } else if (nrOfBuildings === 1) {
        return (
          <div className={`overview`}>
            {
              thisRef.props.content.overview.iconComponent ? (
                <div>
                  {thisRef.props.content.overview.iconComponent}
                </div>
              ) : null
            }
            <div>
              <h1>{usage}</h1>
              <h3>{thisRef.props.i18n.t('panel_usage:label-panel-name')}</h3>
            </div>
          </div>
        )
      } else if (nrOfBuildings > 1) {
        return (
          <div className={`overview`}>
            {
              thisRef.props.content.overview.iconComponent ? (
                <div>
                  {thisRef.props.content.overview.iconComponent}
                </div>
              ) : null
            }
            <div>
              <h1>{usage}</h1>
              <h3>{thisRef.props.i18n.t('panel_usage:label-panel-name')}</h3>
            </div>
          </div>
        )
      } else {
        throw new Error('Unhandled nr of buildings')
      }
    }
    return getOverview()
  }

  setStateProperty (propertyName, propertyValue) {
    let thisRef = this
    return new Promise((res) => {
      thisRef.setState((p) => {
        const obj = {}
        obj[propertyName] = propertyValue
        return Object.assign({}, p, obj)
      }, () => {
        hycon.debug(`${this.constructor.name} setStateProperty - newState`, { newState: thisRef.state })
        res(thisRef.state)
      })
    })
  }

  setIsLoading (isLoading) {
    hycon.debug(`${this.constructor.name} setIsLoading`, { isLoading })
    let thisRef = this
    return thisRef.setStateProperty('isLoading', isLoading)
  }

  getOverviewComponent () {
    let thisRef = this
    let lang = thisRef.props.i18n.language
    return (
      <div className={`Card Usage`}>
        <div className={'controls'}>
          <h4 className={'title'}>{thisRef.props.title}</h4>
          <MUIButton
            onClick={() => {
              let id = thisRef.props.id
              hycon.debug('Clicked card info ' + id)
              window.open(`${WixUtil.getLangBaseUrl(lang.toLowerCase())}/nutzung`, '_blank');
            }}
          >
            <InfoIcon/>
          </MUIButton>
          <MUIButton
            onClick={() => {
              let id = thisRef.props.id
              hycon.debug('Clicked card settings' + id)
              thisRef.setStateProperty('rendering', RENDER_SETTINGS)
              Promise.all([
                thisRef.props.setParentState('isPortalVisible', true),
                thisRef.props.setParentState('isTilesVisible', false)
              ]).then(() => {
                thisRef.state.callbacks.onSettingsEnter()
              })
            }}
          >
            <SettingsIcon/>
          </MUIButton>
        </div>
        <div
          className={'body'}
          onClick={() => {
            let id = thisRef.props.id
            hycon.debug('Clicked card detail' + id)
            GoogleAnalytics.sendGenerigEvent('panel-detail-usage', 'sep-panel-detail', 'Panel Interaction', true)
            thisRef.setStateProperty('rendering', RENDER_DETAIL)
            Promise.all([
              thisRef.props.setParentState('isPortalVisible', true),
              thisRef.props.setParentState('isTilesVisible', false)
            ]).then(() => {
              const buildingSelectionEvent = new CustomEvent('kibana-event', { detail: {
                  type: "panel-detail",
                  eventInfo: {
                    info: "Usage panel has been opened."
                  }
                }
              });
              window.dispatchEvent(buildingSelectionEvent);
              thisRef.state.callbacks.onDetailEnter()
            })
          }}
        >
          {thisRef.getOverview()}
          {
            thisRef.state.isLoading ? (
              <Dimmer active>
                <Loader size='massive'>Loading {thisRef.props.title}</Loader>
              </Dimmer>
            ) : null
          }
        </div>
      </div>
    )
  }

  getDetailComponent () {
    let thisRef = this
    let dashboardPortal = document.getElementById(`dashboard-portal`)
    let getString = (i18nextKey) => {
      return thisRef.props.i18n.t(i18nextKey)
    }
    let detail = (
      <div className={`Usage Card`}>
        <div className={'controls'}>
          <h4 className={'title'}>{getString('dashboard:toolbar-details-label')} {thisRef.props.title}</h4>
          <MUIButton
            onClick={() => {
              let id = thisRef.props.id
              hycon.debug('Clicked card settings ' + id)
              thisRef.setStateProperty('rendering', RENDER_SETTINGS)
              Promise.all([
                thisRef.props.setParentState('isPortalVisible', true),
                thisRef.props.setParentState('isTilesVisible', false)
              ]).then(() => {
                thisRef.state.callbacks.onSettingsEnter()
              })
            }}
          >
            <SettingsIcon/>
          </MUIButton>
          <MUIButton
            onClick={() => {
              let id = thisRef.props.id
              hycon.debug('Clicked card close ' + id)
              thisRef.setStateProperty('rendering', RENDER_OVERVIEW)
              Promise.all([
                thisRef.props.setParentState('isPortalVisible', false),
                thisRef.props.setParentState('isTilesVisible', true)
              ]).then(() => {
                thisRef.state.callbacks.onDetailLeave()
              })
            }}
          >
            <CloseIcon/>
          </MUIButton>
        </div>
        <div
          className={'body'}
          onClick={() => {
            // let id = thisRef.props.id;
            // hycon.debug("Clicked card " + id);
          }}
        >
          {
            thisRef.state.isLoading ? (
              <Dimmer active>
                <Loader size='massive'>Loading {thisRef.props.title}</Loader>
              </Dimmer>
            ) : null
          }
          <UsageDetail {...thisRef.props}
                       panelState={{
                         panelState: thisRef.state.panelState,
                         map: thisRef.state.panelStateMap,
                         sortedMap: thisRef.state.panelStateMapSorted
                       }}/>
        </div>
      </div>
    )
    return ReactDOM.createPortal(detail, dashboardPortal)
  }

  getSettingsComponent () {
    let thisRef = this
    let dashboardPortal = document.getElementById(`dashboard-portal`)
    let getString = (i18nextKey) => {
      return thisRef.props.i18n.t(i18nextKey)
    }
    let settings = (
      <div className={`Usage Card`}>
        <div className={'controls'}>
          <h4 className={'title'}>{getString('dashboard:toolbar-settings-label')} {thisRef.props.title}</h4>
          <MUIButton
            onClick={() => {
              let id = thisRef.props.id
              hycon.debug('Clicked card close ' + id)
              thisRef.setStateProperty('rendering', RENDER_OVERVIEW)

              Promise.all([
                thisRef.props.setParentState('isPortalVisible', false),
                thisRef.props.setParentState('isTilesVisible', true)
              ]).then(() => {
                thisRef.state.callbacks.onSettingsLeave()
              })
            }}
          >
            <CloseIcon/>
          </MUIButton>
        </div>
        <div
          className={'body'}
          onClick={() => {
            // let id = thisRef.props.id;
            // hycon.debug("Clicked card " + id);
          }}
        >
          <UsageSettings {...thisRef.props} callbacks={thisRef.state.callbacks}
                         settings={thisRef.state.settings}/>
          {
            thisRef.state.isLoading ? (
              <Dimmer active>
                <Loader size='massive'>Loading {thisRef.props.title}</Loader>
              </Dimmer>
            ) : null
          }
        </div>
      </div>
    )
    return ReactDOM.createPortal(settings, dashboardPortal)
  }

  renderState () {
    let thisRef = this
    let rendering = thisRef.state.rendering
    switch (rendering) {
      case RENDER_OVERVIEW:
        return thisRef.getOverviewComponent()
      case RENDER_DETAIL:
        return thisRef.getDetailComponent()
      case RENDER_SETTINGS:
        return thisRef.getSettingsComponent()
      default:
        throw new Error('Unconsistent rendering state')
    }
  }

  async getSingleGWR (buildingId) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt
    let endpoint = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/api/gwrbuildings?buildingidsep=[in]${buildingId}`
    let response = await axios({
      method: 'get',
      url: endpoint,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${jwt}`,
      }
    })
    .then(function (response) {
      hycon.debug(`${thisRef.constructor.name} getSingleGWR`, response)
      return response
    })
    .catch((e) => {
      hycon.error(`${thisRef.constructor.name} getSingleGWR`, e)
      UserUtil.authenticationRedirect(e.response, thisRef)
    })
    return response
  }

  getGWR () {
    let thisRef = this
    const selectedBuildings = thisRef.props.reduxState.selectedBuildings
    const jwt = thisRef.props.reduxState.user.jwt

    let buildingIds = []
    selectedBuildings.forEach((selectedBuilding) => {
      let buildings = selectedBuilding.buildings
      buildings.forEach((building) => {
        buildingIds.push(building.id)
      })
    })
    /* push the requests in an array */
    let requests = []
    buildingIds.forEach((id) => {
      let endpoint = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/api/gwrbuildings?buildingidsep=[in]${id}`
      let req = axios({
        method: 'get',
        url: endpoint,
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        }
      })
      .then(function (response) {
        hycon.debug(`${thisRef.constructor.name} getGWRRequest`, response)
        return response
      })
      .catch((e) => {
        hycon.error(`${thisRef.constructor.name} getGWRRequest`, e)
        UserUtil.authenticationRedirect(e.response, thisRef)
      })
      requests.push(req)
    })

    /* perform the requests */
    return Promise.all(requests).then((values) => {
      let gwrData = []
      values.forEach((val) => {
        gwrData.push(val.data)
      })
      hycon.debug(`${thisRef.constructor.name} getGWR response`, {
        gwrData, values
      })
      return gwrData
    }).catch(error => {
      hycon.error(`${thisRef.constructor.name} getGWR - error`, {
        error
      })
      UserUtil.authenticationRedirect(error.response, thisRef)
    })
  }

  getEGIDSFromGwr (gwrData) {
    // let thisRef = this;
    let egids = []
    gwrData.forEach(gwr => {
      gwr.forEach(subGwr => {
        egids.push(subGwr.egid)
      })
    })
    return egids
  }

  async getCompaniesDataFromEGIDS (egids) {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt

    if (egids.length === 0) {
      return []
    }

    let endpoint = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/api/zefix/?egid=[in]${egids}`
    let zefixRequest = axios({
      method: 'get',
      url: endpoint,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${jwt}`,
      }
    })
    .then(function (response) {
      hycon.debug(`${thisRef.constructor.name} getCompaniesDataFromEGIDS - response`, response)
      // filter out
      return response.data.filter((zefixElement) => {
        return zefixElement.status !== 'GELOESCHT'
      })
    })
    .catch((e) => {
      hycon.error(`${thisRef.constructor.name} getCompaniesDataFromEGIDS - error`, e)
      UserUtil.authenticationRedirect(e.response, thisRef)
    })

    let values = await zefixRequest
    return values
  }

  async getBauzonen (gwrData) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} getBauzonen `, {
      gwrData
    })
    let zones = []
    for (let i = 0; i < gwrData.length; i++) {
      let gwr = gwrData[i]
      gwr.forEach((gwrElement) => {
        zones.push({
          buildingIdSep: gwrElement.buildingIdSep,
          zone: gwrElement.housingEgids.zone
        })
      })
    }
    return zones
  }

  getDistricts (gwrData) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} getDistricts `, {
      gwrData
    })
    let districts = []
    gwrData.forEach((gwr) => {
      gwr.forEach((gwrElement) => {
        districts.push({ bfsNummer: gwrElement.housingEgids.gemNr })
      })
    })
    return districts
  }

  transformPanelState (panelState) {
    // let thisRef = this;
    let map = {}
    const getEGIDSById = (id) => {
      // panelState.gwrData[""0""][""0""].buildingIdSep
      let egids = []
      panelState.gwrData.forEach(gwr => {
        let buildingIdSep = gwr.buildingIdSep
        if (id === buildingIdSep) {
          egids.push(gwr.egid)
        }
      })
      return egids
    }

    const getGWRById = (id) => {
      // panelState.gwrData[""0""][""0""].buildingIdSep
      let gwrArray = []
      panelState.gwrData.forEach(gwr => {
        let buildingIdSep = gwr.buildingIdSep
        if (id === buildingIdSep) {
          gwrArray.push(gwr)
        }
      })
      return gwrArray
    }

    const getCompaniesByBuildingId = (id) => {
      // panelState.companies[11][""0""].egid
      let buildingEgids = getEGIDSById(id)
      let companies = []
      buildingEgids.forEach(egid => {
        panelState.companies.forEach(company => {
          if (egid === company.egid) {
            companies.push(company)
          }
        })
      })
      return companies
    }

    const getConstructionZones = (id) => {
      // newState.panelState.panelState.bauzonen[""0""].building.id
      let constructionZones = []
      panelState.bauzonen.forEach((constructionZone) => {
        let buildingId = constructionZone.buildingIdSep
        if (buildingId === id) {
          constructionZones.push(constructionZone)
        }
      })
      return constructionZones
    }

    // panelState.selectedBuildings[""0""].buildings[""0""].id
    panelState.selectedBuildings.forEach((buildings, index) => {
      buildings.buildings.forEach(building => {
        let id = building.id
        map[id] = {
          index: index,
          gwr: getGWRById(id),
          egids: getEGIDSById(id),
          companies: getCompaniesByBuildingId(id),
          building,
          constructions: getConstructionZones(id)
        }
      })
    })

    return map
  }

  getSortedMap (myMap) {
    let thisRef = this
    const sort = (myMap) => {
      let sortable = []
      for (var idProperty in myMap) {
        if (myMap.hasOwnProperty(idProperty)) {
          sortable.push([myMap[idProperty].index, myMap[idProperty]])
        }
      }
      sortable.sort(function (a, b) {
        return a[0] - b[0]
      })
      return sortable
    }

    let sorted = sort(myMap)
    hycon.debug(`${thisRef.constructor.name} sortedMap -`, {
      myMap, sorted
    })
    return sorted
  }

  async getBauzonenFallback (lng, lat) {
    let thisRef = this
    let props = thisRef.props
    let hookName = thisRef.constructor.name
    let env = props.reduxState.env
    const jwt = props.reduxState.user.jwt
    let endpoint = `${env.API_GATEWAY_BASE}/api/zone-by-point?x=${lng}&y=${lat}&srid=4326`
    let data = {}
    hycon.debug(`${hookName} getBauzonenFallback`, { lat, lng })
    return await axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      hycon.debug(`${hookName} getBauzonenFallback`, { response })
      return response
    })
    .catch((error) => {
      hycon.debug(`${hookName} getBauzonenFallback`, { error })
    })
  }

  async getDistrictByPointFallback (lng, lat) {
    let thisRef = this
    let props = thisRef.props
    let hookName = thisRef.constructor.name
    let env = props.reduxState.env
    const jwt = props.reduxState.user.jwt
    let endpoint = `${env.API_GATEWAY_BASE}/api/district-by-point?x=${lng}&y=${lat}&srid=4326`
    let data = {}
    hycon.debug(`${hookName} getDistrictByPointFallback`, {})
    return await axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      hycon.debug(`${hookName} getDistrictByPointFallback`, { error })
    })
  }

  async updateAll () {
    let thisRef = this
    const jwt = thisRef.props.reduxState.user.jwt

    let allEgids = []
    let allGwr = []
    let allDistricts = []
    let allCompanies = []
    let allBauzonen = []
    let allDistrictGeometries = []

    const selectedBuildings = thisRef.props.reduxState.selectedBuildings
    for (let i = 0; i < selectedBuildings.length; i++) {
      let selectedBuilding = selectedBuildings[i]
      let buildings = selectedBuilding.buildings
      for (let j = 0; j < buildings.length; j++) {
        let building = buildings[j]
        let gwrResponse = await thisRef.getSingleGWR(building.id).catch((error) => {
          hycon.error(`${thisRef.constructor.name} getSingleGWR - error`, { error })
          return {
            data: []
          }
        })
        let gwr = gwrResponse.data

        let egids = []
        gwr.forEach(subGwr => {
          egids.push(subGwr.egid)
        })

        let districtResponse = await thisRef.getDistrictByPointFallback(
          building.coordinates.longitude,
          building.coordinates.latitude
        )
        let districts = districtResponse.data

        let bauzonen = []
        hycon.debug(`${thisRef.constructor.name} updateAll - debug`, {
          gwr
        })

        if (false/*gwr.length > 0*/) {
          gwr.forEach((gwrElement) => {
            bauzonen.push({
              buildingIdSep: gwrElement.buildingIdSep,
              zone: gwrElement.housingEgids.zone
            })
          })
        } else {
          let bauzonenResponse = await thisRef.getBauzonenFallback(
            building.coordinates.longitude,
            building.coordinates.latitude
          )
          // inject the reference to the building
          // bauzonenResponse.data.ref = "asdfasdf"
          bauzonen = bauzonenResponse.data.map((bz) => {
            return {
              ...bz, building: building
            }
          })
        }

        let companies = await thisRef.getCompaniesDataFromEGIDS(egids)
        // assume the district is the first (99.99% of the time a building belongs only to one district)
        let district = districts[0]
        let districtGeometries = []
        for (let i = 0; i < thisRef.state.settings.categories.length; i++) {
          let category = thisRef.state.settings.categories[i]
          let endpoint = `${thisRef.props.reduxState.env.API_GATEWAY_BASE}/api/zone-geometries?bfsno=${district.bfsNummer}&chbezd=${encodeURIComponent(category)}`
          let geomCategoryResponse = await axios({
            method: 'get',
            url: endpoint,
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${jwt}`,
            }
          })
          .then(function (response) {
            hycon.debug(`${thisRef.constructor.name} getBauzonenGeometriesFallback`, response)
            response.data.category = category
            return response
          })
          .catch((e) => {
            hycon.error(`${thisRef.constructor.name} getBauzonenGeometriesFallback`, e)
            UserUtil.authenticationRedirect(e.response, thisRef)
          })
          districtGeometries.push(geomCategoryResponse.data)
        }

        hycon.debug(`${thisRef.constructor.name} updateAll`, {
          building,
          gwr,
          districts,
          allDistrictGeometries,
          companies,
          bauzonen
        })
        allEgids = [...allEgids, ...egids]
        allGwr = [...allGwr, ...gwr]
        allDistricts = [...allDistricts, ...districts]
        allCompanies = [...allCompanies, ...companies]
        allBauzonen = [...allBauzonen, ...bauzonen]
        allDistrictGeometries = districtGeometries
      }
    }
    hycon.debug(`${thisRef.constructor.name} updateAll - all`, {
      allEgids,
      allGwr,
      allDistricts,
      allCompanies,
      allBauzonen
    })
    return {
      allEgids,
      allGwr,
      allDistricts,
      allCompanies,
      allBauzonen,
      allDistrictGeometries
    }
  }

  async update () {
    let thisRef = this
    let selectedBuildings = thisRef.props.reduxState.selectedBuildings
    let panelState = {
      selectedBuildings
    }
    await thisRef.setStateProperty('isLoading', true)
    let allData = await thisRef.updateAll().catch((error) => {
      hycon.error(`${thisRef.constructor.name} allData`, {
        error
      })
      return null;
    })
    hycon.debug(`${thisRef.constructor.name} updateAll - allData`, {
      allData
    })
    if(allData !== null){
      panelState.gwrData = allData.allGwr
      panelState.districts = allData.allDistricts
      panelState.bauzonen = allData.allBauzonen
      panelState.companies = allData.allCompanies
      panelState.districtGeometries = allData.allDistrictGeometries

      let map = thisRef.transformPanelState(panelState)
      let sortedMap = thisRef.getSortedMap(map)
      hycon.debug(`${thisRef.constructor.name} updateAll - transformPanelState`, {
        map
      })
      await Promise.all([
        thisRef.setStateProperty('panelState', panelState).then((state) => {
          hycon.debug(`${thisRef.constructor.name} updateAll - setStateProperty -`, {
            panelState: thisRef.state.panelState
          })
          return state
        }),
        thisRef.setStateProperty('panelStateMap', map).then((state) => {
          hycon.debug(`${thisRef.constructor.name} updateAll - setStateProperty -`, {
            panelStateMap: thisRef.state.panelStateMap
          })
          return state
        }),
        thisRef.setStateProperty('panelStateMapSorted', sortedMap).then((state) => {
          hycon.debug(`${thisRef.constructor.name} updateAll - setStateProperty -`, {
            panelStateMapSorted: thisRef.state.panelStateMapSorted
          })
          return state
        })
      ]).then((values) => {
        hycon.debug(`${thisRef.constructor.name} updateAll - setStateProperty`, {
          thisRef
        })
        return values
      })
      await thisRef.drawGraphics()
    }
    return await thisRef.setStateProperty('isLoading', false)
  }

  render () {
    let thisRef = this
    return (
        <>
          <LeafletLayers />
          {thisRef.renderState()}
        </>
    )
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    let thisRef = this
    hycon.debug(`${thisRef.constructor.name} componentDidUpdate`, {
      prevProps,
      newProps: this.props,
      prevState,
      snapshot
    })
    thisRef.mapRef.current = thisRef?.props?.reduxState?.map?.map;
    const old_selectedBuildings = prevProps.reduxState.selectedBuildings
    const new_selectedBuildings = thisRef.props.reduxState.selectedBuildings
    if (JSON.stringify(old_selectedBuildings) !== JSON.stringify(new_selectedBuildings)) {
      hycon.debug(`${thisRef.constructor.name} componentDidUpdate - new selectedBuildings`, {
        old_selectedBuildings,
        new_selectedBuildings
      })
      // implement logic
      window.dispatchEvent(new CustomEvent("usage:clear", {detail: {}}))
      thisRef.update()
    } else {
      hycon.debug(`${thisRef.constructor.name} componentDidUpdate - no new selectedBuildings`, {
        old_selectedBuildings,
        new_selectedBuildings
      })
    }
  }

  componentWillUnmount () {
    hycon.debug(`${this.constructor.name} componentWillUnmount`, { props: this.props })
  }

  componentDidCatch (error, errorInfo) {
    hycon.debug(`${this.constructor.name} componentDidCatch`, { error, errorInfo })
  }
}

export default withTranslation([
  'panel_usage', 'dynamic_panel_marketsense_v4'
])(Usage)

