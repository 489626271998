import { UserUtil } from '../../../../user_management/util/user-util'

import HyperConsole from '../../../../hyper_console/hyper-console'
let hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole
const PanelController = (function () {
  let state = {
    isGraphicsDisplayed: false,
    layers: [],
    distinctLayers: [],
    updateData: [],
    geometryResponses: [],
    distinctGeometryResponses: [],
    subscribers: []
  }

  const subscribe = (id, subscriber) => {
    hycon.debug('panel-controller: subscribe: ', { id, subscriber })
    try {
      let newSubs = state.subscribers.concat([])
      newSubs[id] = subscriber
      updateStateProps({ subscribers: newSubs })
      hycon.debug('panel-controller: subscribe: ok', { subscribers: state.subscribers })
    } catch (e) {
      hycon.warn(e)
    }
  }
  const unsubscribe = (id) => {
    hycon.debug('panel-controller: unsubscribe: ', { id })
    try {
      let newSubs = state.subscribers.concat([])
      newSubs[id] = null
      updateStateProps({ subscribers: newSubs })
      hycon.debug('panel-controller: unsubscribe: ok', { subscribers: state.subscribers })
    } catch (e) {
      hycon.warn(e)
    }
  }
  const notifyAll = (eventName) => {
    hycon.debug('panel-controller: notifyAll: ', { eventName })
    state.subscribers.forEach((subscriber) => {
      subscriber.notify(eventName)
    })
  }

  const getInternalState = () => {
    try {
      return state
    } catch (e) {
      hycon.warn(e)
    }
  }

  const flyTo = (lat, lng) => {
    try {
      state.map.map.flyTo({ lat, lng }, 18)
    } catch (e) {
      hycon.warn(e)
    }
  }

  const updateStateProps = (props) => {
    let newState = Object.assign({}, state)
    state = Object.assign({}, state, { ...props })
    hycon.debug('panel-controller: updateStateProps: ', { props, oldState: newState, newState: state })
  }

  const dispatchEnter = (dipatcherObject) => {
    // create and dispatch the event
    let event = new CustomEvent('panel-detail-enter', {
      detail: {
        info: 'info'
      }
    })
    dipatcherObject.dispatchEvent(event)
  }

  const dispatchLeave = (dipatcherObject, data) => {
    // create and dispatch the event
    let event = new CustomEvent('panel-detail-leave', {
      detail: {
        info: 'info',
        data
      }
    })
    dipatcherObject.dispatchEvent(event)
  }

  const dispatchSubdetailEnter = (dipatcherObject, data) => {
    // create and dispatch the event
    let event = new CustomEvent('panel-subdetail-enter', {
      detail: {
        info: 'info',
        data
      }
    })
    dipatcherObject.dispatchEvent(event)
  }

  const dispatchSubdetailLeave = (dipatcherObject) => {
    // create and dispatch the event
    let event = new CustomEvent('panel-subdetail-leave', {
      detail: {
        info: 'info'
      }
    })
    dipatcherObject.dispatchEvent(event)
  }

  const initListeners = (listenerObject) => {
    listenerObject.addEventListener('panel-detail-enter', function (e) {
      hycon.debug('panel-controller: processing panel event: ', { e })
      updateStateProps({ isGraphicsDisplayed: true })
      refreshGraphics()
    })
    listenerObject.addEventListener('panel-detail-leave', function (e) {
      hycon.debug('panel-controller: processing panel event: ', { e })
      updateStateProps({ isGraphicsDisplayed: false })
      refreshGraphics()
    })
  }

  const refreshGraphics = () => {
    if (state.isGraphicsDisplayed) {
      hycon.debug('panel-controller: getRoofGeoimetries graphics should be displayed')
      displayLatestGraphics()
    } else {
      hycon.debug('panel-controller: getRoofGeoimetries graphics should be hidden')
      clearDistinctLayers()
    }
  }

  const displayLatestGraphics = () => {
    if (state.updateData.length <= 0) {
      hycon.debug('panel-controller: getRoofGeoimetries nothing to display')
      return
    }
    let lastData = state.updateData[state.updateData.length - 1]

    let ids = []
    lastData.filteredRoofs.forEach((element) => {
      ids.push(element.building.id)
    })

    if (state.isGraphicsDisplayed) {
      getDistinctRoofGeoimetries().then((res) => {
        hycon.debug('panel-controller: displayLatestGraphics', { res })
        // distinctGeometryResponses
        state = { ...state, distinctGeometryResponses: state.distinctGeometryResponses.concat([res]) }
        displayDistinctGeoJson()
      }).catch((e) => {
        hycon.debug('panel-controller: displayLatestGraphics err', { e })
        clearLayer()
      })
    } else {
      hycon.debug('panel-controller: displayLatestGraphics graphics should be hidden')
      clearLayer()
    }
  }

  const update = (data) => {
    state = { ...state, updateData: state.updateData.concat([data]) }
    hycon.debug('panel-controller: update', { data, state })
    displayLatestGraphics()
  }

  const getDistinctRoofGeoimetries = () => {
    // https://staging.services.swissenergyplanning.ch/api/roof-geometries?buildingidsep=[in]31031327,31031270,31031252,31031340,31031276&apikey=E7wL45Ckk87FqwCzeRhEEpqj9Wh1YaVg&klasse=4&flaeche=[ge]5

    let lastData = state.updateData[state.updateData.length - 1]
    let axios = state.axios

    hycon.debug('panel-controller: getRoofGeoimetries', { state })

    let ids = []
    lastData.filteredRoofs.forEach((element) => {
      ids.push(element.building.id)
    })

    let requests = []
    let classes = [1, 2, 3, 4, 5] // 1 = gering, 5 = hervorragend
    classes.forEach((roofClass, index) => {
      let roofGeometreisEndpoint =
                `${state.ctx.props.reduxState.env.API_GATEWAY_BASE}/api/roof-geometries?buildingidsep=[in]${ids.join(',')}` +
                `&klasse=[e]${classes[index]}&flaeche=[ge]${lastData.settings.roofs.filter.minRoofExtent}`
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      let jwt = state.ctx.props.reduxState.user.jwt

      if (ids.length === 0) {
        // if do not call the api if there is no building, otherwise status 400 comes
        return Promise.reject('No buildings selected')
      }

      let roofGeometriesPromise = axios({
        method: 'get',
        url: roofGeometreisEndpoint,
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        cancelToken: source.token
      })
        .then(function (response) {
          // handle success
          hycon.debug('panel-controller: pv-tarif getRoofGeoimetries', response)
          return Promise.resolve(response)
        })
        .catch((e) => {
          hycon.error('panel-controller: pv-tarif getRoofGeoimetries', e)
          UserUtil.authenticationRedirect(e.response, state.ctx)
          return Promise.reject('pv-tarif getRoofGeoimetries rejection', e)
        })
      requests.push(roofGeometriesPromise)
    })
    return Promise.all(requests)
  }

  const clearLayer = () => {
    // clear the old markers
    state.layers.forEach((layer) => {
      hycon.debug(`panel-controller: removing layer`, { layer })
      state.map.map.removeLayer(layer)
    })
  }

  const clearDistinctLayers = () => {
    // clear the old markers
    state.distinctLayers.forEach((layer) => {
      hycon.debug(`panel-controller: removing distinct layer`, { layer })
      state.map.map.removeLayer(layer)
    })
  }

  const displayDistinctGeoJson = () => {
    hycon.debug(`panel-controller: displayDistinctGeoJson`, { state })

    let colors = [
      '#192f9f',
      '#00c5ff',
      '#ffaa01',
      '#ff5501',
      '#a80000' // dark red
    ]

    const getStyle = (roofClass) => {
      if (roofClass < 1 || roofClass > 5) {
        throw new Error(`color index is out of range: mist be between 1 and 5. provided: ${roofClass}`)
      }
      let myStyle = {
        'color': colors[roofClass - 1],
        'weight': 3,
        'opacity': 0.8
      }
      return myStyle
    }

    // response for the different roofs/colors
    if (state.distinctGeometryResponses.length <= 0) {
      hycon.debug(`panel-controller: displayDistinctGeoJson nothing to display`, { state })
      return
    }
    let lastDistinctResponse = state.distinctGeometryResponses[state.distinctGeometryResponses.length - 1]
    let lastData = state.updateData[state.updateData.length - 1]

    let geometries = []
    lastDistinctResponse.forEach((res, index) => {
      try {
        let roofClass = index + 1
        let geoJsonString = res.data.geometries
        let geojsonFeature = JSON.parse(geoJsonString)
        let geojson = state.map.Leaflet.geoJSON(geojsonFeature, {
          style: getStyle(roofClass)
        })

        if (roofClass >= lastData.settings.roofs.filter.minRoofClass) {
          geometries.push(geojson)
          hycon.debug(`panel-controller: displayDistinctGeoJson adding roofCLass ${roofClass}`)
        } else {
          hycon.debug(`panel-controller: displayDistinctGeoJson skipping roofCLass ${roofClass}`)
        }
      } catch (e) {
        hycon.debug(`panel-controller: displayDistinctGeoJson error`, { state })
        hycon.error(e)
      }
    })

    let roofsGroup = state.map.Leaflet.layerGroup([...geometries])

    clearDistinctLayers()

    state = {
      ...state, distinctLayers: state.distinctLayers = [roofsGroup]
    }
    roofsGroup.addTo(state.map.map)
    notifyAll('graphics-update')
    hycon.debug(`panel-controller: displayDistinctGeoJson ok`, { state, colors })
  }

  return {
    updateStateProps,
    initListeners,
    dispatchEnter,
    dispatchLeave,
    dispatchSubdetailEnter,
    dispatchSubdetailLeave,
    update,
    flyTo,
    getInternalState,
    subscribe,
    unsubscribe
  }
})()

export const panelController = PanelController
