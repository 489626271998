import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Dimmer, Dropdown, Loader, Modal,
} from 'semantic-ui-react';
import { useFavoriteCtx } from '../../FavoriteProvider';
import { LOCIZE_PANEL_NS, REACT_APP_GI_ENV } from '../../constants';
import HyperConsole from '../../../hyper_console/hyper-console';

let hycon = null;
if (REACT_APP_GI_ENV === 'development') {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
} else {
  hycon = new HyperConsole({ isEnabled: false, name: __filename }).myConsole;
}

export default function ModalFormRecipient(props) {
  const {
    getAllUniqueActiveRecipients,
    getAllUniqueRecipients,
    getUniqueTargetRecipientsEmails,
    updateRecipients,
    updateContext,
    onModalClose,
  } = props;
  const hookName = 'RecipientForm';
  const favoriteContext = useFavoriteCtx();
  const { t } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false });
  const [values, setValues] = useState([]);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const isEmailActive = (email) => getAllUniqueActiveRecipients().includes(email);
  const getOptions = () => {
    const allUniqueRecipients = getAllUniqueRecipients();
    const allOptions = [...new Set([...options, ...allUniqueRecipients])];
    return allOptions.map((option) => ({
      text: option,
      value: option,
      description: `${isEmailActive(option) ? t('favorites:email-confirmation-confirmed') : t('favorites:email-confirmation-pending')}`,
    }));
  };
  const getValues = () => values;
  const getInfoComponent = () => {
    const isGroupEditMode = favoriteContext.isGroupEditMode(favoriteContext);
    if (isGroupEditMode === false || favoriteContext.selection.length <= 1) {
      return (
        <div>
          <h5>{t('favorites:recipients-included-in-selection')}</h5>
          <div className="active-email">
            {getUniqueTargetRecipientsEmails()
              .map((email) => `${email} (${isEmailActive(email) ? t('favorites:email-confirmation-confirmed') : t('favorites:email-confirmation-pending')})`)
              .join(', ')}
            {getUniqueTargetRecipientsEmails()
              .map((email) => `${email} (${isEmailActive(email) ? t('favorites:email-confirmation-confirmed') : t('favorites:email-confirmation-pending')})`)
              .join(', ') ? '.' : null}
          </div>
        </div>
      );
    }
    return null;
  };
  return (
    <>
      <Modal.Header>
        {t('favorites:recipients-settings')}
      </Modal.Header>
      <Modal.Content>
        {getInfoComponent()}
        <br />
        <Dimmer active={isLoading}>
          <Loader size="small" />
        </Dimmer>
        <Dropdown
          value={getValues()}
          placeholder={`${t('favorites:select-target-recipients')}`}
          fluid
          multiple
          search
          selection
          allowAdditions
          onAddItem={(event, data) => {
            const { value } = data;
            hycon.debug(`${hookName} onAddItem`, { value, event, data });
            setOptions([...options, value]);
          }}
          options={getOptions()}
          onChange={(event, data) => {
            const { value } = data;
            hycon.debug(`${hookName} onChange`, { value, event, data });
            setValues(value);
          }}
        />
        <br />
        <div className="action">
          <Button
            primary
            size="medium"
            onClick={async () => {
              const isGroupEditMode = favoriteContext.isGroupEditMode(favoriteContext);
              if (isGroupEditMode) {
                const alertText = `${t('favorites:override-all-recipients-for-current-selection')}`;
                // eslint-disable-next-line no-alert
                const isOk = window.confirm(alertText);
                if (isOk) {
                  setIsLoading(true);
                  hycon.debug(`${hookName} onClick`, { props });
                  await updateRecipients(values);
                  await updateContext();
                  setValues([]);
                  setIsLoading(false);
                }
              } else {
                setIsLoading(true);
                hycon.debug(`${hookName} onClick`, { props });
                await updateRecipients(values);
                await updateContext();
                setValues([]);
                setIsLoading(false);
              }
            }}
          >
            {t('favorites:button-modal-notification-update')}
          </Button>
          <Button
            size="medium"
            onClick={(event, data) => {
              hycon.debug(`${hookName} onClick`, { event, data });
              onModalClose(event, data);
            }}
          >
            {t('favorites:button-modal-cancel')}
          </Button>
        </div>
      </Modal.Content>
    </>
  );
}
