import React, {Component} from 'react';
 import HyperConsole from "../../../hyper_console/hyper-console";
import "./Resend.css";
import {Button, Form} from 'semantic-ui-react';
import axios from "axios/index";
import { withTranslation } from 'react-i18next'
import SupportRequest from "../../../reusable/SupportRequest";

let hycon = new HyperConsole({isEnabled: false, name:__filename}).myConsole;

class Resend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isSuccess: false,
            messages: [],
            email: this.props.location.state.email,
            errors: {
                email: false
            }
        };

        hycon.debug(`${this.constructor.name} constructor`, {props: this.props});
    }

    componentDidMount() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} componentDidMount`, {props: this.props});

        thisRef.loadRecaptchaScript();

        // setting the default email is done in constructor
        // thisRef.setStateProperty("email", thisRef.props.location.state.email);
    }


    static getDerivedStateFromProps(props, state) {
        hycon.debug(`${"Resend"} getDerivedStateFromProps`, {props, state});
        if (!state) {
            hycon.debug(`${"Resend"} getDerivedStateFromProps - only props have been updated`, {props, state});
        }
        return state;
    }

    shouldComponentUpdate(nextProps, nextState) {

        hycon.debug(`${this.constructor.name} shouldComponentUpdate`, {nextProps, props: this.props, nextState});
        return true;
    }


    resendSignup() {
        let thisRef = this;
        let env = thisRef.props.reduxState.env;
        return new Promise((res, rej) => {
            const endpoint = `${env.API_GATEWAY_BASE}/api/resend-email`;
            return axios(
                {
                    method: 'post',
                    url: endpoint,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': "application/json",
                    },
                    data: JSON.stringify({
                        email: thisRef.state.email,
                        googleToken: thisRef.state["recaptcha-token"]
                    })
                }
            )
                .then((response) => {
                    hycon.info(`${this.constructor.name} submit`, {state: thisRef.state});
                    return res(response);
                })
                .catch((error) => {
                    hycon.warn(`${this.constructor.name} submit`, {state: thisRef.state});
                    return rej(error);
                });
        });
    }

    submit() {
        let thisRef = this;
        hycon.debug(`${this.constructor.name} submit`, {state: thisRef.state});
        thisRef.setStateProperty("messages", thisRef.state.messages.concat([`Bearbeitung..`]));
        thisRef.setStateProperty("isLoading", true);
        thisRef.resendSignup().then(() => {
            hycon.debug(`${this.constructor.name} submit ok`);
            thisRef.setStateProperty("messages", thisRef.state.messages.concat([`Bearbeitung..ok.`]));
            thisRef.setStateProperty("isLoading", false);
            thisRef.setStateProperty("isSuccess", true);
            thisRef.setStateProperty("messages", thisRef.state.messages.concat([
                thisRef.props.i18n.t("sign_up:message-confirm-link")
            ]));
        }).catch((e) => {
            hycon.debug(`${this.constructor.name} submit error`, e);
            let response = e.response;
            if(response.status === 422){
                let msg = thisRef.props.i18n.t("sign_up:message-check-form");
                thisRef.setStateProperty("messages", thisRef.state.messages.concat([`${msg}`]), {reset:true, resetValue: [], delta: 2000});
            } else {
                thisRef.setStateProperty("messages", thisRef.state.messages.concat([`${e.message}`]), {reset:true, resetValue: [], delta: 2000});
            }
            thisRef.validate().then(() => {
                return thisRef.setStateProperty("isLoading", false);
            });
        });
    }

    getFormValidElements() {
        let thisRef = this;
        let validElements = {
            email: !thisRef.state.errors.email
        };
        validElements.isAllValid = (
            validElements.email
        );
        hycon.debug(`${thisRef.constructor.name} getFormValidElements`, {validElements});
        return validElements;
    }

    validate(validation = {}) {
        let thisRef = this;
        let promises = [];

        if (validation.email) {
            if (!thisRef.state.email) {
                promises.push(thisRef.setStateProperty("errors", Object.assign({}, thisRef.state.errors, {email: true})));
            } else {
                promises.push(thisRef.setStateProperty("errors", Object.assign({}, thisRef.state.errors, {email: false})));
            }
        }

        return Promise.all(promises);
    }

    setStateProperty(propertyName, propertyValue, opts) {
        let thisRef = this;


        if(opts){
            let isValid = (
                typeof opts.reset !== "undefined" &&
                typeof opts.resetValue !== "undefined" &&
                typeof opts.delta !== "undefined"
            );
            if(!isValid){
                throw new Error('options object is not defined as expected. expeected something like {reset: <bool>, resetValue: <object>, delta: <number>}');
            }
        }

        let resetPromise = Promise.resolve();
        if(
            opts &&
            opts.reset === true
        ){
            resetPromise = new Promise((res, rej) => {
                setTimeout(() => {
                    try {
                        thisRef.setStateProperty("messages", opts.resetValue);
                        res();
                    }catch (e) {
                        rej(e)
                    }
                }, opts.delta);
            });
        }

        let setPromise = new Promise((res) => {
            thisRef.setState((p) => {
                const obj = {};
                obj[propertyName] = propertyValue;
                return Object.assign({}, p, obj);
            }, () => {
                hycon.debug(`${this.constructor.name} setStateProperty - newState`, {newState: thisRef.state});
                res();
            })
        });

        return Promise.all([setPromise, resetPromise]);
    }

    recaptchaExecute() {
        let thisRef = this;
        let key = thisRef.props.reduxState.env.GOOGLE_RECAPTCHA_SITE_KEY;
        return new Promise((res) => {
            let grecaptcha = window.grecaptcha;
            grecaptcha.ready(() => {
                grecaptcha.execute(key, {action: 'homepage'}).then((token) => {
                    hycon.info(`${thisRef.constructor.name} recaptchaExecute - google says you are not a bot`, {token});
                    return thisRef.setStateProperty("recaptcha-token", token).then(() => {
                        res();
                    });
                });
            });
        });
    }

    loadRecaptchaScript () {
        let thisRef = this
        hycon.debug(`${thisRef.constructor.name} loadRecaptchaScript`, {})

        let key = thisRef.props.reduxState.env.GOOGLE_RECAPTCHA_SITE_KEY

        let scriptId = "google-recaptcha-script";
        let script = document.createElement('script')
        script.id=scriptId;
        script.type = 'text/javascript'
        script.src = `https://www.google.com/recaptcha/api.js?render=${key}`

        let recaptchaScript = document.getElementById(scriptId);
        if(!recaptchaScript){
            document.getElementsByTagName('head')[0].appendChild(script)
        }
    }

    getInfoComponent(){
        let thisRef = this;
        hycon.debug(`${thisRef.constructor.name} getResendComponent`, {});
        let component = (
            <div className={`column-flex`}>
                <div className={"info"}>
                    {thisRef.props.i18n.t("sign_up:label-account-already-exists")}
                </div>
            </div>
        );
        return component;
    }

    render() {
        let thisRef = this;
        return (
            <div className="Resend">
                <div className={`resend-form`}>
                    <div className={"heading"}>
                        <img className={"image"}
                             alt={""}
                             src={`https://geoimpactstorage.blob.core.windows.net/public/logo/${thisRef.props.appStateServer.clients[0]}/Logo-SEP-web.png`}/>
                        <h1>{thisRef.props.i18n.t("sign_up:label-resend-again")}</h1>
                    </div>
                    <Form loading={thisRef.state.isLoading} className={`isSuccess-${thisRef.state.isSuccess}`}>
                        {thisRef.getInfoComponent()}
                        <Form.Field error={thisRef.state.errors.email}>
                            <label>{thisRef.props.i18n.t("sign_up:label-email")}</label>
                            <input
                                placeholder={thisRef.props.i18n.t("sign_up:label-email")}
                                type={"email"}
                                autoComplete={`email`}
                                onChange={(event) => {
                                    let val = event.target.value;
                                    hycon.debug(`${thisRef.constructor.name} onChange`, {event, value: val});
                                    thisRef.setStateProperty("email", val).then(() => {
                                        thisRef.validate({email: true});
                                    });
                                }}
                                onBlur={() => {
                                    hycon.debug(`${thisRef.constructor.name} onBlur`, {});
                                    thisRef.validate({email: true});
                                }}
                                value={thisRef.state.email}
                            />
                        </Form.Field>
                        <div className={`column-flex`}>
                            <Button
                                primary
                                type='submit'
                                disabled={(()=>{
                                    let validElements = thisRef.getFormValidElements();
                                    let isAllValid = validElements.isAllValid;
                                    return !isAllValid;
                                })()}
                                onClick={() => {
                                    thisRef.validate()
                                        .then(() => {
                                            let validElements = thisRef.getFormValidElements();
                                            let isAllValid = validElements.isAllValid;
                                            return isAllValid;
                                        })
                                        .then((isAllValid) => {
                                            if (isAllValid) {
                                                hycon.debug(`${thisRef.constructor.name} form looks good`, {});
                                                thisRef.recaptchaExecute().then(() => {
                                                    return thisRef.submit();
                                                });
                                            } else {
                                                hycon.debug(`${thisRef.constructor.name} please check your form`, {});
                                            }
                                        });
                                }}
                            >
                                {thisRef.props.i18n.t("sign_up:label-resend")}
                            </Button>
                        </div>
                    </Form>
                    <div className={"footer"}>
                        <h5>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: (
                                        () => {
                                            if (thisRef.state.messages.length > 1) {
                                                return thisRef.state.messages[thisRef.state.messages.length - 1]
                                            }
                                        }
                                    )()
                                }}
                            />
                        </h5>
                        <SupportRequest {...thisRef.props}/>
                    </div>
                </div>
            </div>
        );
    }

    componentDidUpdate() {

        hycon.debug(`${this.constructor.name} componentDidUpdate`, {props: this.props});
    }

    componentWillUnmount() {

        hycon.debug(`${this.constructor.name} componentWillUnmount`, {props: this.props});
    }
}

export default withTranslation()(Resend);

