import { useEffect, useState } from 'react'

function useProperty (props, path, callback) {
  const [appeared, setAppeared] = useState(false)
  useEffect(() => {
    let propChain = path.split('.')
    const getProperty = (propChain) => {
      try {
        const getDeepValue = (obj, propChain, index) => {
          // console.debug(`${hookName} getDeepValue `, { obj, propChain, index, appeared })
          if (index === propChain.length - 1) {
            return obj[propChain[index]]
          } else if (typeof obj[propChain[index]] !== 'undefined') {
            return getDeepValue(obj[propChain[index]], propChain, index + 1)
          } else {
            return obj[propChain]
          }
        }
        return getDeepValue(props, propChain, 0)
      } catch (e) {
        return undefined
      }
    }
    let property = getProperty(propChain)
    // console.debug(`${hookName} useEffect `, { props, property, appeared })
    if (appeared === false && typeof property !== 'undefined') {
      setAppeared(true)
      callback()
    }
  }, [props, appeared, callback, path] )
}
export {
  useProperty
}
